
import moment from 'moment'
import { defaultEditorLandscapeHeight, defaultEditorLandscapeWidth, defaultEditorPortraitHeight, defaultEditorPortraitWidth } from '../editor/editorModels'
import { Media } from '../media/mediaModels'
import { 
    // Position, 
    Template 
} from '../template/templateModels'
import { CarbonDate } from '../group/groupModels'
import { createAsyncThunk } from '@reduxjs/toolkit'

export interface InitialState {

    selectedContentIds: string[],

    currContent: Content,
    prevContent: Content,
    currPlaylist: Content,
    currMedia: Media | null,
    
    unselectedSectionIds: string[],

    currSectionId: string,
    currLayerId: string,
    currLayerOrder: number,

    hoverSectionId: string,
    hoverLayerId: string,
    hoverLayerOrder: number,
    hoverLayerSourceId: string,
    
    errStatus: boolean,
    contentIDInUse: AssociativeArray[],
    mediaIDInUse: AssociativeArray[],
    checkSum: number,   //by default = [1,...,10000];   if = 0, then it's for a special action like add new text


    // status: "loading" | "idle" | "completed" | "error";       
    // error: string | null;   // `error` will contain an error message.
    // list: Todo[];
}


export interface Props {
    className?: string
}

// For content
//--------------------
export interface Content{    
    user_template_id?   : string,
    user_template_name  : string,
    duration            : number,    
    layer?              : number,
    type?               : string | null,
    require_schedule?   : number,
    modified_time?      : string | null,
    // template?        : Template,
    emergency?          : number,   //0: normal, 1: emergency standby, 2: active emergency
    elements            : Element[],
    user_id?            : string,
    template_id?        : string,
    width               : number,
    height              : number,
    thumbs?             : string[],
}

// for element, position, template
//---------------------------------------

export interface Element{
    element_id  : string,
    duration    : number,
    alias       : string,
    element_setting : ElementSetting | null,
    element_type: string,
    position_id : string,
    sources     : Source[],
    type        : string,  
    
    user_template_id    : string,
    x: number,
    y: number,
    w: number,
    h: number,    
}

export interface ElementSetting{
    transition      : string,    //"fade", "slide right", "slide left", "slide up", "slide down"
    transition_timer: number,
    // alignment ?     : string,   // bottom_right, bottom_left, top_right, top_left
    // fill_to?       : string,   //"height", "width", "original"
    bg_color?: string,
}


export interface EleInput {
    element_id  : string,
    position_id : string,
    alias       : string,
    element_setting : string,
    type        : string,  
    source_ids  : any,
}

export interface ContentInput {
    contentId   :  string,  
    user_template:{
      user_template_name  : string,
      type?               : string | null
      template_id         : string,
      duration            : number,  
      thumbs?      : string[],        
    },
    elements    : EleInput[],
    emergency?  : number,
    
}

export interface ContentPlaylistInput {
    content_id      : string,
    play_duration   : number,
    mode: string,
    num_loops: number
}

export type PlaylistInput = {  
    name: string,
    orientation: string,
    contents: ContentPlaylistInput[]
}

export interface FontList{
    id: string;
    font_name: string,
}

// move to media source
//------------------------------

export interface Source {
    duration_override   : number,
    element_id          : string,    
    order               : number,
    param_source        : ParamSource[],
    source?             : Media | null
    source_id           : string,
    source_list_id      : string,  
    type?               : string,
    duration?           : number,  
}

export interface SourceInput extends Source{
    dur?    : number,
    url?    : string,
}

export interface ParamSource{

// export interface ParamSourceImage extends ParamSource {  
    delay?   : number, 
    duration?: number,
    
    loop?           : number,
    volume?         : number | null, 

    alignment ?     : string,   // bottom_right, bottom_left, top_right, top_left, top, bottom, left, right, center
    fill_to?        : string,   //"", "fit", "original"
    bg_color?       : string,

    source_id?      : string,
    campaign_id?    : string,
    campaign_name?  : string,
    thumbs?         : string [],
    
    // for webElement
    // ------------------------
    type?        : string,
    url?         : string,
    view_mode?   : string,

// }

// export interface ParamSourceText extends ParamSource {    
    weblocal?    : boolean,
    webbody?     : string,


    background?      : string | null,
    backgroundcolor? : string | null,
    bgtransparant?   : string,
    direction?       : DirectionTYPE,
    font?            : string,
    fontalign?       : string,
    fontcolor?       : string,    
    fontitalic?      : string,
    fontstyle?       : string,
    fontweight?      : string,  //normal, w100: thin, w200: extralight, w300: light, w400: normal, w500, w600: semi-bold, w700: Bold, w800: extra-bold, w900 
    fontdecoration?  : string,  //none, underline, overline, lineThrough
    fontdecorationcolor? : string,  //color
    fontdecorationstyle? : string,  //solid, double, dotted, dashed, wavy

    
    hasshadow?       : boolean,    
    shadows?         : ShadowProp[],
    
    size?            : number,

    marquee?         : boolean,
    speed?           : number,
    remove_new_line? : boolean,

    speed_x?         : number,
    speed_y?         : number,


    txtId?           : string,
    text?            : string,
    title?           : string,
    html?            : boolean,
    // textcolor?       : number,
    // fontcolor?       : number,

    //for Web-local text
    asset?          : string[],
    asset_ids?      : string[],    
    inputMode?      : string,
}

export type DirectionTYPE = "left" | "right" | "up" | "down"

export interface ShadowProp {
    "dx": number,
    "dy": number,
    "radius": number,
    "color": string
}

export interface AssociativeArray {
    [key: string]: any
}



// -------------Testing AsyncThunk------------
// -------------------------------------------
// export type TodoId = string;

// export type Todo = {
//   id: TodoId;
//   title: string;
//   completed: boolean;
// };
// // This type describes the error object structure:
// type FetchTodosError = {
//     message: string;
// };
  
// // The third type-parameter is an object with:
// // `{dispatch?, state?, extra?, rejectValue?}`` fields.
// //
// // `extra` is useful when we need to pass 
// // some static data to the request function,
// // like jwt-token or HTTP-headers.
// //
// // `rejectValue` is useful when we need to type 
// // possible errors.
// export const fetchTodos = createAsyncThunk<
//     Todo[], // Content,    
//     number,
//     { rejectValue: FetchTodosError }
// >(
//     "todos/fetch", 
//     // The second argument, `thunkApi`, is an object
//     // that contains all those fields
//     // and the `rejectWithValue` function:
//     async (limit: number, thunkApi) => {
//     // async (thunkApi) => {
//         // const response = await fetch(/*...*/);
        
//         // // Check if status is not okay:
//         // if (response.status !== 200) {
//         //     // Return the error message:
//             // return thunkApi.rejectWithValue({ 
//             //     message: "Failed to fetch todos." 
//             // });
//         // }
//         const todos: Todo[] =   [
//                                     {                                        
//                                         "id": "1",
//                                         "title": "this is title",
//                                         "completed": false,
//                                     },
//                                     {                                        
//                                         "id": "2",
//                                         "title": "this is title 2",
//                                         "completed": true,
//                                     }
//                                 ]
//         return thunkApi.fulfillWithValue(todos);        
//     }
// );

// export const fetchSetContentNew = createAsyncThunk<
//     Content,        
//     Content,
//     { rejectValue: FetchTodosError }
// >(
//     "setContentNew/fetch", 
//     // The second argument, `thunkApi`, is an object
//     // that contains all those fields
//     // and the `rejectWithValue` function:
//     async (content: Content, thunkApi) => {
//     // async (thunkApi) => {
//         // const response = await fetch(/*...*/);
        
//         // // Check if status is not okay:
//         // if (response.status !== 200) {
//         //     // Return the error message:
//             // return thunkApi.rejectWithValue({ 
//             //     message: "Failed to fetch todos." 
//             // });
//         // }
//         // const todos: Todo[] =   [
//         //                             {                                        
//         //                                 "id": "1",
//         //                                 "title": "this is title",
//         //                                 "completed": false,
//         //                             },
//         //                             {                                        
//         //                                 "id": "2",
//         //                                 "title": "this is title 2",
//         //                                 "completed": true,
//         //                             }
//         //                         ]
//         // return thunkApi.fulfillWithValue(todos);       
//         return thunkApi.fulfillWithValue(content)
       
//     }
// );




// Data Pre-assignment
// ----------------------------------------------
export const DEFAULT_ZOOM_PERCENTAGE = 0.65
export const DEFAULT_TXT_FONT_COLOR = "#000000"

export const templatePlaylistID =   [   "myv83QOrBZ", 
                                        "mK18Vj8yeA"
                                    ]

export const TransitionList:  any =  {   
                                none        : ["none", "bi bi-slash-circle-fill"],
                                fade        : ["fade", "bi bi-subtract"],
                                // random      : ["random", "bi bi-shuffle"],
                                slideUp     : ["slide up", "bi bi-box-arrow-up"],
                                slideDown   : ["slide down", "bi bi-box-arrow-down"],
                                slideLeft   : ["slide left", "bi bi-box-arrow-left"],
                                slideRight  : ["slide right", "bi bi-box-arrow-right"],
                            }

export const DimensionsList:  any =  {   
                                full        : ["bi bi-arrows-fullscreen" , "Full screen", "(width=100%, height=100%)"],
                                fit         : ["bi bi-fullscreen"   , "Fit", "(width=fit, height=fit)"],                                
                                original    : ["bi bi-image"        , "Original", "(width=original, height=original)"],
                                // width    : ["bi bi-align-middle" , "width=fixed, height=100%"],
                                // height   : ["bi bi-align-center" , "width=100%, height=fixed"],                                
                            }
//Do not change order of this elements
export const AlignmentList:  any =  {   
                                "none"          : ["fa fa-ban"                    ,  "none"],   //topLeft = default
                                "top left"      : ["bi bi-box-arrow-in-up-left"   ,  "top left"],
                                "top"           : ["bi bi-box-arrow-in-up"        ,  "top"],
                                "top right"     : ["bi bi-box-arrow-in-up-right"  ,  "top right"],
                                "left"          : ["bi bi-box-arrow-in-left"      ,  "left"],
                                "center"        : ["bi bi-arrows-move"            ,  "center"],
                                "right"         : ["bi bi-box-arrow-in-right"     ,  "right"],
                                "bottom left"   : ["bi bi-box-arrow-in-down-left" ,  "bottom left"],
                                "bottom"        : ["bi bi-box-arrow-in-down"      ,  "bottom"],
                                "bottom right"  : ["bi bi-box-arrow-in-down-right",  "bottom right"],
                            }

// export const defaultContentThumbnail = "/media/stock/900x600/79.jpg"
// export const emptyContentThumbnail = "/media/stock/900x600/79.jpg"
export const defaultContentThumbnail    = "/media/_content/blank-content-04.jpg"
export const emptyContentThumbnail      = "/media/_content/blank-content-02.jpg"
export const defaultContentBackgrounds  = [
                                            "/media/_content/blank-content-web-01.jpg",
                                            "/media/_content/blank-content-text-01.jpg"
                                        ]

export const VERYRECENTPERIOD : number = 1  //14 days
export const RECENTPERIOD : number = 7  //14 days
export const IMAGEDURATION = 10000   // 10 sec            

export const templateColorPallets = [
                                        // "#7aceffaa", //image
                                        "#B5B5C3", //image
                                        "#ff6767aa", //video
                                        "#9C258F88", //web
                                        "#9c9ca7aa", //text
                                        "#7aceffaa", //content                                        
                                        "#eff2f5aa", //other
                                    ]
export const emptyContent: Content = {
        user_template_id    : "",
        user_template_name  : "",
        elements            : [],
        duration            : 0,    
        layer               : 0,
        require_schedule    : 0,        
        // modified_time       : "",
        // user_id             : "",
        template_id         : "",
        width               : defaultEditorLandscapeWidth,                
        height              : defaultEditorLandscapeHeight,
}
export const emptyContentPortrait: Content = {
    user_template_id    : "",
    user_template_name  : "",
    elements            : [],
    duration            : 0,    
    layer               : 0,
    require_schedule    : 0,        
    // modified_time       : "",
    // user_id             : "",
    template_id         : "",
    width               : defaultEditorPortraitWidth,                
    height              : defaultEditorPortraitHeight,
}

export const defaultContentLandscape: Content = {
    user_template_id    : "",
    user_template_name  : "",    
    elements            : [{
                            alias       : "1",
                            duration    : 0,
                            // element_id  : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                            // element_id  : Math.random().toString(9).slice(0,5).replace('0.', '').toUpperCase(),
                            element_id  : '01',
                            element_setting : {
                                transition: 'fade', 
                                transition_timer: 2000,
                                bg_color: "#000000"
                            },
                            element_type    : "video",
                            w               : 1280,
                            h               : 720,
                            position_id     : "6q2MjY8PGx",
                            sources         : [],
                            type            : "image",
                            user_template_id: "",                            
                            x               : 0,
                            y               : 0,        
    }],
    duration            : 0,
    layer               : 0,
    require_schedule    : 1,
    template_id : "6q2MjY8PGx",    
    width       : 1280,
    height      : 720,    
}

export const defaultContentPortrait: Content = {
    user_template_id    : "",
    user_template_name  : "",    
    elements            : [{
                            alias       : "1",
                            duration    : 0,
                            // element_id  : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                            element_id  : '01',
                            element_setting : {
                                transition: 'fade', 
                                transition_timer: 2000
                            },
                            element_type    : "video",
                            w               : 720,
                            h               : 1280,
                            position_id     : "6q2MjY8PGx",
                            sources         : [],
                            type            : "image",
                            user_template_id: "",                            
                            x               : 0,
                            y               : 0,        
    }],
    duration            : 0,
    layer               : 0,
    require_schedule    : 1,
    template_id : "6q2MjY8PGx",    
    width       : 720,
    height      : 1280,    
}

export const emptyElement: Element = {
    element_id  : "",
    duration    : 0,
    alias       : "",
    element_setting : {
        transition : "fade",
        transition_timer  : 2000
    },
    element_type: "",   
    position_id : "",
    sources     : [],
    type        : "",  
    
    user_template_id    : "",
    x: 0,
    y: 0,
    w: 0,
    h: 0,   
}

export const DEFAULT_FONT_COLOR = "#000000"

export const emptyTextSrc: Source = {
    source_list_id: "",
    element_id    : "",
    source_id: '0',
    order:  0,
    duration_override: -1,    
    type: "text",
    source:  null,
    param_source : [{
        duration        : IMAGEDURATION,
        delay           : 0,
        bg_color        : "#00000000",

        text            : " ",
        txtId           : "",
        fontcolor       : DEFAULT_FONT_COLOR,

        html            : false,        
        backgroundcolor : null,

        marquee         : false,
        direction       : "left",
        speed           : 50,
        speed_x         : 50,
        speed_y         : 0,
        
        fill_to         : "full",
        alignment       : "center",
        
        font            : "",
        fontitalic      : "",
        fontstyle       : "",
        fontweight      : "",
        fontdecoration  : "",
        hasshadow       : false,

        size            : 36,


    }]
}

                                    
                                    

export const contentPreviewPath : string  = process.env.REACT_APP_PREVIEW_PATH ? process.env.REACT_APP_PREVIEW_PATH+"?url=" + process.env.REACT_APP_API_URL + "/data/content/" : ""



        
var tmpSTR001  = '<p style="width: 80%; margin: 0 auto; max-width: 600px;">'
    tmpSTR001 += '<p>Use Promo Code: <span style="background: #ccc; padding: 3px;">BOH232</span></p>'
    tmpSTR001 += '<p style="color: red;">Expires: Jan 03, 2021</p>'
    tmpSTR001 += '</p>'

export const TEXTSTYLE_LIST : ParamSource[] = [
    {
        "txtId": "txt001",
        "title": "Simple Text",
        "text": "Normal text",

        "fontcolor" : DEFAULT_TXT_FONT_COLOR,
        "font"      : 'Roboto',

        "fill_to"   : "fit",
        "alignment" : "center",
        // "backgroundcolor"  : "#00ff00",
        // "bg_color"  : "",    //not working for text
    },
    {
        "txtId": "txt002",
        "title": "Marquee Text",
        "marquee": true,
        "direction": "left",
        "speed_x": 50,
        "speed_y": 0,
        "loop": 0,
       

        "fill_to": "fit",
        "alignment": "center",

        "duration": 0,
        "delay": 0,
        "text": "Text marquees provide a scrolling area of text to your digital content that engage readers with useful snippets of content. This is the sample text for Flutter TextScroll widget RTL សូមពិនិត្យមើលខ្ញុំចេញ។ ហេ! ខ្ញុំជាអត្ថបទ RTL សូមពិនិត្យមើលខ្ញុំចេញ។ ចុចខ្ញុំដើម្បីចាប់ផ្តើមរមូរ។ |",
        "html": false,

        "font"      : 'Roboto',
        "size"      : 36,
        "fontcolor" : DEFAULT_TXT_FONT_COLOR,
        "fontweight": "bold",
        "fontitalic": "italic",
        "backgroundcolor": "#ff0000",

        // "shadows": [
        //     {
        //         "dx": 3,
        //         "dy": 3,
        //         "radius": 3,
        //         "color": "#770F99FF"
        //     },
        //     {
        //         "dx": 2,
        //         "dy": 2,
        //         "radius": 2,
        //         "color": "#77FF0000"
        //     }
        // ],        
    },
    // {
    //     "txtId": "txt003",
    //     "title": "HTML Text",
    //     "text": "&lt;p&gt;The <strong>HTML</strong> text.&lt;/p&gt;",
    //     "html": true,

    //     "fontcolor" : DEFAULT_TXT_FONT_COLOR,
    //     "font"      : 'Roboto',

    //     "fill_to": "fit",
    //     "alignment": "center",
    // },
    // {
    //     "txtId": "txt004",
    //     "title": "50% OFF",
    //     "text" : '<div style="border: 2px solid #C61D24; padding: 10px 20px; text-align: center"><strong>50% OFF</strong></div>',
    //     "html" : true,

    //     "font"      : 'Roboto',
    //     "fontcolor" : "#C61D24",
    //     "size"      : 50,
        
    //     "fill_to"  : "fit",
    //     "alignment": "center",
    // },
    // {
    //     "txtId": "txt005",
    //     "title": "HTML Text",
    //     "text" : tmpSTR001,
    //     "html" : true,

    //     "font"      : 'Roboto',
    //     "size"      : 30,
    //     "fontcolor" : DEFAULT_TXT_FONT_COLOR,
    //     "fontweight": "bold",
    //     "fontitalic": "italic",

    //     "fill_to"  : "fit",
    //     "alignment": "center",
    // },
    
]

















// ----------------------------------
// List of functions
// ----------------------------------
export const toCapitalize = (str: string) => {
    
    if (str && str.length > 0){
        return  str[0].toUpperCase() + str.substring(1,)
    }else{
        return str
    }
}

export const toDataURL = (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

export const getContentIconPath = (cont?: Content) => {
    // const iconPath = '/media/icons/duotune/general/gen006.svg'
    const iconPath = '/media/icons/duotune/abstract/abs027.svg'
    return  iconPath
}

export const getContentThumbnail = (cont?: Content) => {
    var thumnailUrl = ""
    if (cont){
        thumnailUrl =   cont.thumbs && cont.thumbs.length > 0
                        ?   cont.thumbs[0]
                        :   cont.elements && cont.elements[0].sources.length>0
                                ?   cont.elements[0].sources[0].source
                                    ?   cont.elements[0].sources[0].source.url_thumb
                                    :   ""                                                                                                        
                                :""
    }
    return  thumnailUrl
}

export const getContentOrientation = (cont: Content) => {
    return  cont.width >= cont.height ? "landscape":"portrait"
}

export const getBgTemplateSize = (w: number, h: number) => {
    return  (
                // (w >= h*2)  ? "center/contain"
                //     : (h >= w*2) ? "center/40%"
                //         : (w >= h*1.5) && (h >= w*0.5) ? "center/35%" 
                //         //   (w >= h) && (h >= w*0.75)  ? "center/35%" 
                //             : (w > h)  ? "center/contain" : "center/40%"
            (w >= h*2)  ? "center/contain"
                : (h >= w*2) ? "center/40%"                
                    :(w <= h*1)||(h <= w*1)    ? "center/40%"
                        :(w < h*1.5)||(h < w*1.5)    ? "center/contain"
                            : (w < h*2)||(h < w*2)    ? "center/35%"
                                : (w < h*2.5)||(h < w*2.5)  ? "center/25%"
                                    : (w < h*3)||(h < w*3)      ? "center/15%" : "center/contain" 

                    // (w >= h*2.5)||(h >= w*2.5)    ? "center/contain"
                    //         : (w >= h*1.5)||(h >= w*1.5)   ? "center/25%" 
                    //                     : (w >= h*1.3)||(h >= w*1.3)   ? "center/35%" : "center/45%"

            )
}

export const secondsToHHmmss = (duration: number) =>{
    if (duration > 1000*60*60){
        return moment.utc(moment.duration(duration, 'milliseconds').asMilliseconds()).format('hh:mm:ss')        
    }else{
        return moment.utc(moment.duration(duration, 'milliseconds').asMilliseconds()).format('m:ss')        
    }
}


export const formatBytes = (bytes: number, decimals:number = 0) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}


export const convertBytesToKB = (bytes: string) => {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    let l = 0, n = parseInt(bytes, 10) || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return `${n.toFixed(n >0 && l > 0 ? 2 : 0)} ${units[l]}`
}

export const getRibbonType = (content: Content) => {
    if (content.emergency === 0){ 
        return ((moment().diff(moment.utc(content.modified_time).local())/(1000*60*60*24)) <= RECENTPERIOD ? "recent": "") 
    }else if (content.emergency === 1){ 
        return "emergency"
    }else if (content.emergency === 2){ 
        return "activeEmergency"
    }

}

export const extractMediaInUse = (allConts : Content[]) => {
    var extractedList: any[] = []
    // --------------------------------    

    allConts?.forEach((cont, indCont)=>{
        cont.elements?.forEach((ele, indEle)=>{
            ele.sources?.forEach((src, indSrc)=>{                
                extractedList.push({
                                    source_id: src.source?src.source.source_id:"",
                                    content: cont,
                                })                
            })
        })
    })

    // Grouping by source_id and content_id
    const extractedListGroupBy = extractedList.reduce(function (r, a) {
        r[a.source_id] = r[a.source_id] || [];
        r[a.source_id][a.content.user_template_id] = r[a.source_id][a.content.user_template_id] || [];
        r[a.source_id][a.content.user_template_id] = a.content
        return r;
    }, Object.create(null));

    return (extractedListGroupBy)
}

// Test if a content is empty
// ----------------------------
export const isEmptyContent = (cont : Content) => {
    let flag = true
    // ------------------
    cont.elements?.forEach((ele, indEle)=>{
        if (ele.sources && ele.sources.length > 0){
            flag= false
        }
    })
    return flag
}


export const isEmptyElements = (eleList : any[]) => {
    let flag = true
    // ---------------------
    eleList?.forEach((ele, indEle)=>{
        if (ele.source_ids && ele.source_ids !== "[]"){
            flag = false
        }
    })
    return flag
}

export const validateURL = (url: string) => {
    // return (/^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(url))
    // return (/^((https?):\/\/)(www.)?[a-zA-Z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/g.test(url))
    return (/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g.test(url))
    
}


// export const isSimilarContent = (newWatchList : any[], cont: Content) => {
//     let flag = true
//     // ---------------------    
//     if (newWatchList.length === 3){
//         if (cont.elements.length === newWatchList[2].length){            
//             cont.elements?.forEach((ele, indEle)=>{                
//                 if (JSON.stringify(ele.sources) !== newWatchList[2][indEle].source_ids){
//                     flag = false
//                 }
//             })
//         }
//     }
//     return flag
// }

export const isSimilarContent = (newCont: Content, prevCont: Content) => {
    let flag = true
    // ---------------------
    if (JSON.stringify(newCont) !== JSON.stringify(prevCont)){
        flag = false
    }
    
    return flag
}
