import { FC, useState } from "react"
import * as yup from 'yup'
import { useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Hidden, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { requestTicketPasscode, submitSupportTicket } from "../../auth/core/_requests";
import { TicketPartial } from "../../../redux/slices/ticket/ticketModel";
import { Footer } from "../../landing/layouts/partials/Footer";
import { Header } from "../../landing/layouts/partials/Header";
import { Recaptcha } from "../../../commons/widgets/ReCaptcha";


type Props = {  
    className?: string
}

type Ticket = {
    id: "",
    passcode: "",
    description: ""
}
const maxChar = 2000;

const schema = yup.object().shape({
    description: yup.string().trim()
        .min(5, 'Minimum 5 characters')
        .max(maxChar, 'Maximum 1000 characters')
        .required('Description is required'), 
    passcode: yup.string().trim()
        .length(6, '6 characters')
        .required('Description is required')
})

const Index: FC<Props> = ({className}) => {  
    
    const [charLeft, setCharLeft] = useState(maxChar);
    const [searchParams, setSearchParams] = useSearchParams();
    let reqId = searchParams.get('_req');

    const resendPasscode = async () => {
        try{
            const result = await requestTicketPasscode(reqId);
            if(result?.data.code == 200)
            {
                Swal.fire(
                    'Passcode sent',
                    'Please check your account email for passcode and enter in the text box below.',
                    'success'
                )
            }else{
                Swal.fire(
                    'Error',
                    'We are not able to send passcode.',
                    'error'
                )
            }
                
        }catch(error)
        {
            Swal.fire(
                'Error',
                'We are not able to send passcode.',
                'error'
            )
        }
    }

    const { 
        register, 
        handleSubmit,        
        formState:{
            errors,            
            isSubmitting,
            
        }
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues:{            
            id  : reqId,            
            description: "",
        }
    })
      
    const formSubmitHandler: SubmitHandler<Ticket> = async (data:TicketPartial) => {    
        try{
            const result = await submitSupportTicket(data);
            if(result.data.code == 201 || result.data.code == 200)
            {
                Swal.fire(
                    'Compelte',
                    'The request has been sent. The support team will get back to you shortly.',
                    'success'
                  )
            }else{
                Swal.fire(
                    'Error',
                    result.data.msg,
                    'error'
                )
            }

        }catch(error){
            Swal.fire(
              'Error ',
              'There is something wrong during the process!',
              'error'
            )                      
        }       
    }
    
    // -----------------------------------------------------
    return (<>
        <div id="kt_body" data-bs-spy="scroll" data-bs-target="#kt_landing_menu" className="bg-white position-relative app-blank ">
            <div className="d-flex flex-column flex-root" id="kt_app_root">
                <Header />

                <div className="mb-0 mb-lg-5 z-index-2 mt-18" id="kt_contact_us">
                    <div className="container w-100 gy-10 mb-md-0">
                        <div className="row text-center">
                            <div className="d-none d-md-none d-lg-inline col-6 px-15">
                                <img src={'../media/_support/support-01.png'} className='w-75'/>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="text-start">
                                    <form id="frm-contact-us" className='form'  
                                        onSubmit={handleSubmit(formSubmitHandler)}
                                    >                                                                                  
                                        <div className="mb-4">
                                            <input type="hidden" {...register("id")} />
                                            <TextField
                                                {...register("description")} 
                                                label="Issue Details" 
                                                // required
                                                fullWidth 
                                                margin='dense'
                                                variant='outlined' 
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="Please describe the issue of your device here."
                                                multiline
                                                rows={13}
                                                error={!! errors.description}
                                                onChange={e => setCharLeft(maxChar - e.target.value.length)}
                                            />                            
                                            {charLeft}<span> characters left.</span>      
                                        </div>
                                        <div className="">
                                        <TextField 
                                            {...register("passcode")} 
                                            label="Passcode" 
                                            placeholder="Enter Passcode"
                                            fullWidth 
                                            margin='dense'
                                            variant='outlined' 
                                            error={!! errors.passcode}
                                            
                                        />
                                        </div>
                                        <div className='text-gray-500 text-start fw-semibold fs-6'>
                                            
                                            {/* <a href="#" className='link-primary' onClick={()=>{ resendPasscode() }}> Click here to resend.</a> */}
                                        </div> 
                                        <div className='text-gray-500 text-start fw-semibold fs-6'>
                                               
                                                <a href="#" className='link-primary' onClick={()=>{ resendPasscode() }}> Click here to send passcode.</a>
                                        </div> 
                                        <div>
                                        <Recaptcha pageName="support" />
                                        
                                        </div>
                                        <div className="pt-8 text-end">                                    
                                            <button type="submit" className="btn btn-primary" 
                                                disabled={isSubmitting}
                                            >
                                                <i className="fa fa-paper-plane me-1 mt-n1"></i>
                                                Send
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer pageName="supportTicket" />
            </div>

        </div>

        {/*  */}
    </>)
};
export default Index