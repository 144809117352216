import React, { Component, useState, useEffect } from 'react'
// import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { render } from "react-dom";
import { 
  EditorState, 
  ContentState, 
  convertToRaw,
  ContentBlock,
  AtomicBlockUtils,
  RawDraftEntity,
  convertFromRaw
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// import { debounce } from 'throttle-debounce'
import { decode as decodeBase64, encode as encodeBase64 } from 'base-64'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { watch } from 'fs';
import { Source, ParamSource, TEXTSTYLE_LIST } from '../../../../redux/slices/content/contentModels';
import { DraggableText } from '../EditorComponents';
import { useInView } from 'react-intersection-observer';
import { setTextEditorCnt } from '../../../../redux/slices/editor/editorSlice';

//----------------------------------------

type Props = {
  className: string,
}

type TextProps = {
  htmlContent: string,
}


const schema = yup.object().shape({
  // folderId: yup.string().required(),
  noFile: yup.number()
       .min(1)
       .required('At least one file selected.')
})


const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};


interface ShowHtmlButtonProps {
  onChange: (newEditorState: EditorState) => void;
  onToggleHtmlView: (isHtmlView: boolean) => void;
  editorState: EditorState;
}

interface ShowHtmlButtonState {
  showHtml: boolean;
  originalContentState: ContentState | null;
}

const INITIAL_HTML_INPUT =
  '<p><a href="https://elev.io/" class="reference external">Elev.io</a>&nbsp;is an in-app help center that allows your customers to browse help articles on any page of your website and contact support.<br></p>\n<p>By connecting Elev.io to Gorgias:</p>\n<ul><li>Your customers can start chat conversations from Elev.io</li><li>Your customers can email you. You&#8217;ll receive their emails in Gorgias.</li></ul>\n<figure><img src="https://files.helpdocs.io/eQ5bRgGfSN/articles/Z9aXhSOZAb/1514929429182/elevio.png" data-image="1514929429182"></figure>\n<p></p>\n<p>To connect Elev.io to Gorgias:</p>\n<ol><li>In the&nbsp;<a href="https://app.elev.io/" class="reference external">elev.io dashboard</a>, navigate to "Modules"</li><li>Under the "Trigger" module, click "Install"</li><li>Type in &#8220;Chat with us&#8221; as the Display Title. Then, select &#8220;Open Smooch chat window&#8221; as an action, pick an icon, and click "Add Module"</li><li>Go back to "Modules." Under the "Link" module, click "Install"</li><li>Type in &#8220;Email us&#8221; as the Display Title. Then, select &#8220;<a href="mailto:yoursupportemail%40company.com" class="reference external">mailto:yoursupportemail@company.com</a>&#8221; as the URL, pick the mail icon, and click "Add Module"</li></ol>\n<p>Nice Job! Now your customers can email you or start a chat conversation from the Elev.io help center.</p>';

// The Entity that'll represent a CTA element in the content
// in DraftJS. It's identified by its type.
// @see https://draftjs.org/docs/advanced-topics-entities/#internaldocs-banner
const CTA_ENTITY_TYPE = "CTA";

type AtomicBlockProps = {
  block: ContentBlock;
  contentState: ContentState;
};
// This component will be rendered in the Editor when DraftJS
// encounter an 'atomic' block.
// I think 'atomic' block is just a fancy name for "custom block".
// There, if the block contains a CTA entity we render a component that'll represent
// it in the editor: for demo, we just do something simple.
const AtomicBlock = ({ block, contentState }: AtomicBlockProps) => {
  const entity = contentState.getEntity(block.getEntityAt(0));
  if (entity.getType() === CTA_ENTITY_TYPE) {
    return (
      <button disabled style={{ border: "1px solid #f00" }}>
        I'll be a CTA
      </button>
    );
  }
  return null;
};

// To be given to draftjs to render our custom "atomic" block (see above).
function myBlockRenderer(contentBlock: ContentBlock) {
  const type = contentBlock.getType();
  if (type === "atomic") {
    return {
      component: AtomicBlock,
      editable: false
    };
  }
}

type CTAToolbarOptionProps = {
  editorState: EditorState;
  onChange: (state: EditorState) => void;
};

// A component to render a button in the toolbar to insert a CTA
const CTAToolbarOption = ({ editorState, onChange }: CTAToolbarOptionProps) => {
  // This is where we insert the CTA via its Entity
  // I think we need to insert the Entity before and then
  // reference it in the block
  const addCTA = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      CTA_ENTITY_TYPE,
      "MUTABLE"
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    onChange(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
    );
  };

  return (
    <div style={{ color: "red" }} onClick={addCTA}>
      CTA
    </div>
  );
};

// Will be used by Draftjs->HTML to convert CTA entities into
// HTML.
// Note we add the `data-cta` so that:
// - it can be understood by the front and be rendered with
//   an appropriate widget
// - it can be understood by the HTML->Draftjs and thus be edited
//   (back-and-forth edition). See `customChunkRenderer`
const customEntityTransform = (entity: RawDraftEntity) => {
  if (entity.type === CTA_ENTITY_TYPE) {
    return "<button data-cta />";
  }
  return;
};

// Will be used by HTML->DraftJS to convert the HTML made by
// `customEntityTransform` back into DraftJS contentState: it
// will look for nodes with `data-cta` and transform them into
// CTA entities.
const customChunkRenderer = (
  nodeName: string,
  node: HTMLElement
): RawDraftEntity | undefined => {
  if (nodeName === "button" && node.dataset.cta !== undefined) {
    return {
      type: CTA_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {}
    };
  }
};


//----------------------------------------
const TextEditor: React.FC<Props> = ({ className,  }) => {
  const dispatch = useAppDispatch()
  // ---------------------------------------------------

  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)
  const currSectId = useAppSelector(state => state.content.currSectionId)  

  const txtEditorCnt = useAppSelector(state => state.editor.textEditorCnt)  
  // console.log("txtEditorCnt:", txtEditorCnt)  
  const [currTxtParamSrc, setCurrTxtParamSrc] = useState<ParamSource>();
  console.log("currTxtParamSrc:", currTxtParamSrc)  


  // -------------------------------------
  
  const [isHtmlView, setIsHtmlView] = useState(false);
      

  // const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';
  const [htmlTxt, setHtmlTxt] = useState<string>('<p>Hey this <strong>editor</strong> rocks 😀</p>')
  // const [contentBlock, setContentBlock] = useState<ContentBlock[]>(htmlToDraft(htmlTxt))
  const [contentState, setContentState] = useState<ContentState>(ContentState.createFromBlockArray(htmlToDraft(htmlTxt).contentBlocks));
  const [editorState, setEditorState]   = useState<EditorState>(EditorState.createWithContent(contentState));

  // const [editorState, setEditorState]   = useState<EditorState>(EditorState.createWithContent(convertFromRaw(JSON.parse(htmlToDraft(htmlTxt)))))


  // const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  // const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(
  //   ContentState.createFromText("abcde")
  // ));

  // console.log("htmlTxt:", htmlTxt)
  
  // console.log("editorState:", editorState)
  // console.log("contentState:", contentState)
  // console.log("contentState.getPlainText() :", contentState.getPlainText())

//   const editorJSON = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
// const itemText= EditorState.createWithContent(convertFromRaw(JSON.parse(item.content)));

  

  // const [contentState, setContentState] = useState<ContentState>(
  // const [contentState, setContentState] = useState<ContentState>(ContentState.createFromBlockArray(contentBlock.contentBlocks));

  // const contentBlock = htmlToDraft(INITIAL_HTML_INPUT, customChunkRenderer);
  // const contentState = ContentState.createFromBlockArray(
  //   contentBlock.contentBlocks
  // );
  // return EditorState.createWithContent(contentState);


  // -----------------------------------------------
  const onEditorStateChange = (newEditorState: EditorState): void => {
    setEditorState(newEditorState);
    // console.log("getContent: ", newEditorState.getCurrentContent())
    // console.log("getPlainText: ", newEditorState.getCurrentContent().getPlainText())

    // var startKey = newEditorState.getSelection().getStartKey();
    // var selectedBlockType = newEditorState.getCurrentContent().getBlockForKey(startKey).getType();
    // console.log("selectedBlockType: ", selectedBlockType)

    const rawHtmlContent = draftToHtml(
            convertToRaw(newEditorState.getCurrentContent()),
            undefined,
            undefined,
            customEntityTransform
          )
    // console.log("rawHtmlContent : convertToRaw: ", rawHtmlContent)

    // const blocksFromHtml = htmlToDraft(newEditorState.getCurrentContent());
    // console.log("blocksFromHtml:", blocksFromHtml)

    dispatch(setTextEditorCnt(rawHtmlContent))

  }

  // const onContentStateChange = (newContentState: ContentState): void => {
  //   setContentState(newContentState);
  // }
  

  // function focusEditor() {
  //   editor.current.focus();
  // }

  // useEffect(() => {    

  //   var selectionState = editorState.getSelection();
  //   const isCollapsed = editorState.getSelection().isCollapsed();
  //   // console.log("isCollapsed: ", isCollapsed)

  //   if (!editorState.getSelection().getHasFocus()) {
  //     // toggleLayerProps.close();
  //     return;
  //   }

  //   if (isCollapsed) {
  //     // toggleLayerProps.close();
  //   } else {
  //     if (currTxtParamSrc && !currTxtParamSrc.html){        
  //       // toggleLayerProps.open({
  //       //   clientRect: () => getVisibleSelectionRect(window),
  //       //   target: document.body
  //       // });

  //       // var anchorKey = selectionState.getAnchorKey();
  //       var currentContent = editorState.getCurrentContent();
  //       // // console.log("currentContent:", currentContent)

  //       // var currentContentBlock = currentContent.getBlockForKey(anchorKey);
  //       // var start = selectionState.getStartOffset();
  //       // var end = selectionState.getEndOffset();
  //       // var selectedText = currentContentBlock.getText().slice(start, end);
  //       // console.log("selectedText:", selectedText)
        

        
  //       //---Select All the text in the editor
  //       // -----------------------------------------
  //       currentContent = editorState.getCurrentContent();

  //       selectionState = editorState.getSelection().merge({
  //         anchorKey: currentContent.getFirstBlock().getKey(),
  //         anchorOffset: 0,    //start from index 0
    
  //         focusOffset: currentContent.getLastBlock().getText().length,    //until the end of text
  //         focusKey: currentContent.getLastBlock().getKey(),
  //       })  
  //       setEditorState(EditorState.forceSelection(editorState, selectionState));
  //       // setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
    
  //       // console.log("After selection all...........:")

  //       // anchorKey = selectionState.getAnchorKey();
  //       // currentContent = editorState.getCurrentContent();
  //       // // console.log("currentContent:", currentContent)
    
  //       // currentContentBlock = currentContent.getBlockForKey(anchorKey);
  //       // start = selectionState.getStartOffset();
  //       // end = selectionState.getEndOffset();
  //       // selectedText = currentContentBlock.getText().slice(start, end);
  //       // console.log("selectedText:", selectedText)
  //     }
  
  //   }

  // }, [
  //   // editorState.getSelection().isCollapsed(),
  //   // editorState.getSelection().getHasFocus()
  //   editorState.getSelection()
  // ]);


  // ---------------------------------
  useEffect(() => { 
    // console.log("currSectId changed :", currSectId)

    const selectedSect = currPersistContent.elements.find((sect, ind)=> sect.element_id === currSectId)

    if (selectedSect && selectedSect.sources.length > 0){
      setCurrTxtParamSrc(selectedSect.sources[0].param_source[0])

      const tmpTxt = selectedSect.sources[0].param_source[0].text
      if (tmpTxt){
        setHtmlTxt(tmpTxt)
      }
    }

  }, [ currPersistContent, currSectId])


  // -----------------------
  useEffect(() => { 
    const tmpContState = ContentState.createFromBlockArray(htmlToDraft(htmlTxt).contentBlocks)
    setContentState(tmpContState)
    setEditorState(EditorState.createWithContent(tmpContState))

    dispatch(setTextEditorCnt(htmlTxt))

  }, [htmlTxt])


  // -----------------------------------------
  // useEffect(() => { 
  //   console.log("currLayerId changed :", currLayerId)

    // const selectedSect = currPersistContent.elements.find((sect, ind)=> sect.element_id === currSectId)
    // setMaxOrder(selectedSect? selectedSect.sources.length : 0)

    
    // const foundSect = currPersistContent.elements.find((sect)=>sect.element_id === currSectId)?.sources.find((layer)=>layer.source_list_id === currLayerId)
    // // console.log('foundSect in useEffect:',foundSect)

    // if (currPersistContent && foundSect){
    //     const dur = foundSect? (foundSect.param_source[0].duration? foundSect.param_source[0].duration/1000:0):0
        
    //     setSelectedLayer(foundSect)
    //     // setDuration(dur)

    //     reset({
    //         ...foundSect,
    //         order: foundSect?.order,
    //         dur: (foundSect? (foundSect.param_source[0].duration && foundSect.param_source[0].duration >0 ? foundSect.param_source[0].duration / 1000: 0): 0),
    //         url: (foundSect? (foundSect.param_source[0].url? foundSect.param_source[0].url:"http://a.com"):"http://a.com"),
    //         param_source: [{
    //             ...foundSect?.param_source[0],
    //             url: foundSect?.param_source[0].url
    //         }]
    //     })
    // }

  // }, [ currLayerId])

  

  //-----------------------------------------
  return (
    <div className={` ${className} `}      
    >         
        <div>
          {currTxtParamSrc &&           
            (currTxtParamSrc.html && !currTxtParamSrc.marquee) 
            ? <Editor        
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}

                // initialContentState={contentState}
                // contentState={contentState}
                // onContentStateChange={onContentStateChange}
                
                wrapperClassName="wrapper-class d-flex flex-column flex-row-auto w-100 max-h-400px"
                toolbarClassName="toolbar-class d-flex flex-column-auto h-50px border bordor-bottom rounded px-3"

                editorClassName={`editor-class mx-5 d-flex flex-column-fluid overflow-auto ${isHtmlView ? "d-none":""}`}
                // editorClassName="editor-class px-3 max-h-100px overflow-auto bg-primary"
                
                // toolbarHidden
                // wrapperStyle={<wrapperStyleObject>}
                // editorStyle={<editorStyleObject>}
                // toolbarStyle={<toolbarStyleObject>}
                // toolbar={{
                  // history: { inDropdown: true },
                  // inline: { inDropdown: false },
                  // list: { inDropdown: true },
                  // link: { showOpenOptionOnHover: true },
                  // textAlign: { inDropdown: true },
                  // image: {
                  //   uploadCallback: this.imageUploadCallBack,
                  //   defaultSize: { height: "auto", width: "50%" }
                  // }
                // }}
                toolbar={{
                  options: [
                    // 'inline', 
                    'blockType', 
                    // 'fontFamily', 
                    'fontSize', 
                    // 'list', 
                    // 'textAlign', 
                    'colorPicker', 
                    // 'link', 
                    // 'embedded', 
                    // 'emoji', 
                    // 'image', 
                    // 'remove', 
                    // 'history'
                  ],
                  // inline: {
                  //   inDropdown: false,
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  //   // options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                  //   options: ['bold', 'italic', 'underline', 'strikethrough'],
                  //   bold: { icon: "bold", className: undefined },
                  //   italic: { icon: "italic", className: undefined },
                  //   underline: { icon: "underline", className: undefined },
                  //   strikethrough: { icon: "strikethrough", className: undefined },
                  //   // monospace: { icon: "monospace", className: undefined },
                  //   // superscript: { icon: "superscript", className: undefined },
                  //   // subscript: { icon: "subscript", className: undefined },
                  // },
                  // blockType: {
                  //   inDropdown: true,
                  //   options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  // },
                  // fontSize: {
                  //   icon: "fontSize",
                  //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  // },
                  fontFamily: {
                    options: ['Roboto', 'Sans Serif', 'Open Sans'],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  // list: {
                  //   inDropdown: false,
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  //   options: ['unordered', 'ordered', 'indent', 'outdent'],
                  //   unordered: { icon: "unordered", className: undefined },
                  //   ordered: { icon: "ordered", className: undefined },
                  //   indent: { icon: "indent", className: undefined },
                  //   outdent: { icon: "outdent", className: undefined },
                  // },
                  // textAlign: {
                  //   inDropdown: false,
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  //   options: ['left', 'center', 'right', 'justify'],
                  //   left: { icon: "left", className: undefined },
                  //   center: { icon: "center", className: undefined },
                  //   right: { icon: "right", className: undefined },
                  //   justify: { icon: "justify", className: undefined },
                  // },
                  // colorPicker: {
                  //   icon: "color",
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                  //     'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                  //     'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                  //     'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                  //     'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                  //     'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                  // },
                  // link: {
                  //   inDropdown: false,
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   dropdownClassName: undefined,
                  //   showOpenOptionOnHover: true,
                  //   defaultTargetOption: '_self',
                  //   options: ['link', 'unlink'],
                  //   link: { icon: "link", className: undefined },
                  //   unlink: { icon: "unlink", className: undefined },
                  //   linkCallback: undefined
                  // },
                  // emoji: {
                  //   icon: emoji,
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   emojis: [
                  //     '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                  //     '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                  //     '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                  //     '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                  //     '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                  //     '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                  //     '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                  //     '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                  //     '✅', '❎', '💯',
                  //   ],
                  // },
                  // embedded: {
                  //   icon: "embedded",
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   embedCallback: undefined,
                  //   defaultSize: {
                  //     height: 'auto',
                  //     width: 'auto',
                  //   },
                  // },
                  // image: {
                  //   icon: "image",
                  //   className: undefined,
                  //   component: undefined,
                  //   popupClassName: undefined,
                  //   urlEnabled: true,
                  //   uploadEnabled: true,
                  //   alignmentEnabled: true,
                  //   uploadCallback: undefined,
                  //   previewImage: false,
                  //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  //   alt: { present: false, mandatory: false },
                  //   defaultSize: {
                  //     height: 'auto',
                  //     width: 'auto',
                  //   },
                  // },
                  // remove: { icon: "eraser", className: undefined, component: undefined },
                  // history: {
                  //   inDropdown: false,
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  //   options: ['undo', 'redo'],
                  //   undo: { icon: "undo", className: undefined },
                  //   redo: { icon: "redo", className: undefined },
                  // },
                }}
                toolbarCustomButtons= {
                  (currTxtParamSrc && currTxtParamSrc.html) 
                    ? [                
                        <div
                          className="rdw-option-wrapper border border-0 "
                          role="button"
                          style={{marginTop: "8px"}}
                          title={isHtmlView ? "Hide HTML" : "Show HTML"}
                          onClick={()=> setIsHtmlView(! isHtmlView)}
                        >
                          <i className='fa fa-code'></i>
                        </div>
                      ]
                    : []
                }
              />
            :
              <Editor        
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}

                // initialContentState={contentState}
                // contentState={contentState}
                // onContentStateChange={onContentStateChange}
                
                wrapperClassName="wrapper-class d-flex flex-column flex-row-auto w-100 max-h-400px"
                toolbarClassName="toolbar-class d-flex flex-column-auto h-50px border bordor-bottom rounded px-3"

                editorClassName={`editor-class mx-5 d-flex flex-column-fluid overflow-auto ${isHtmlView ? "d-none":""}`}
                // editorClassName="editor-class px-3 max-h-100px overflow-auto bg-primary"
                
                // toolbarHidden
                // wrapperStyle={<wrapperStyleObject>}
                // editorStyle={<editorStyleObject>}
                // toolbarStyle={<toolbarStyleObject>}
                // toolbar={{
                  // history: { inDropdown: true },
                  // inline: { inDropdown: false },
                  // list: { inDropdown: true },
                  // link: { showOpenOptionOnHover: true },
                  // textAlign: { inDropdown: true },
                  // image: {
                  //   uploadCallback: this.imageUploadCallBack,
                  //   defaultSize: { height: "auto", width: "50%" }
                  // }
                // }}
                toolbar={{
                  options: [
                    // 'inline', 
                    'blockType', 
                    // 'fontFamily', 
                    'fontSize', 
                    // 'list', 
                    // 'textAlign', 
                    'colorPicker', 
                    // 'link', 
                    // 'embedded', 
                    // 'emoji', 
                    // 'image', 
                    // 'remove', 
                    // 'history'
                  ],
                  // inline: {
                  //   inDropdown: false,
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  //   // options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                  //   options: ['bold', 'italic', 'underline', 'strikethrough'],
                  //   bold: { icon: "bold", className: undefined },
                  //   italic: { icon: "italic", className: undefined },
                  //   underline: { icon: "underline", className: undefined },
                  //   strikethrough: { icon: "strikethrough", className: undefined },
                  //   // monospace: { icon: "monospace", className: undefined },
                  //   // superscript: { icon: "superscript", className: undefined },
                  //   // subscript: { icon: "subscript", className: undefined },
                  // },
                  // blockType: {
                  //   inDropdown: true,
                  //   options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  // },
                  // fontSize: {
                  //   icon: "fontSize",
                  //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  // },
                  fontFamily: {
                    options: ['Roboto', 'Sans Serif', 'Open Sans'],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  
                }}                
              />
          }
          
          <div className=''>            
            {isHtmlView &&
              <textarea
                // {...register("htmlContent")} 
                className='position-absolute w-100 max-h-500px border border-0 px-5 py-4'
                // style={{marginBottom: "55px"}}
                // disabled
                value={draftToHtml(
                  convertToRaw(editorState.getCurrentContent()),
                  undefined,
                  undefined,
                  customEntityTransform
                )}
                onChange={(e)=>{
                  // console.log("e.target.value:", e.target.value)
                //   const blocksFromHtml = htmlToDraft(e.target.value);
                //   console.log("blocksFromHtml:", blocksFromHtml)
                  
                //   const { contentBlocks, entityMap } = blocksFromHtml;
                //   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                //   const editorState = EditorState.createWithContent(contentState);

                  setHtmlTxt(e.target.value)
          
                  // const tmpContState = ContentState.createFromBlockArray(htmlToDraft(tmpTxt).contentBlocks)
                  // setContentState(tmpContState)
                  // setEditorState(EditorState.createWithContent(tmpContState))
                }}
              />
            }
          </div>
        </div>

        {/* <div className='me-5 mb-5'>
          <button type='button' className='btn btn-secondary pt-2 pb-3 me-3'
            data-bs-dismiss="modal"
          >
            <i className='fa fa-times me-1'></i>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary pt-2 pb-3'
            data-bs-dismiss="modal"
            aria-label="Close"
            // disabled={isSubmitting || !isValid || (errors.group_name? true:false)}
            // disabled={isSubmitting || !isValid || (customErrs !== null)}
          >
            <i className='fa fa-save me-1'></i>
            Save
          </button>
        </div> */}
      
    </div>
  )
}

export { TextEditor }
