import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitialState, Sched } from './scheduleModels';


const initialState: InitialState = {
    
    isHeightLimited: false,

    showNumberOfDevices: false,
    showWarningMsg: false,

    showActiveSched: true,
    showExpiredSched: true,    //hide the schedules expired
    showComingSched: true,
    enableClearAll: false,   //clear all schedules in a group
    
    editMode: false,
    currSchedId: "",
    allScheds: [],
    
    crudSchedStatus: {
                        updateSchedMode: false,
                        schedMode: "standard"
                    },

    currActiveSchedIds: []
                
}

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers:{
        setHeightLimited: (state, action) =>{
            state.isHeightLimited = action.payload
        },
        setShowNumberOfDevices: (state, action) =>{
            state.showNumberOfDevices = action.payload
        },
        setShowWarningMsg: (state, action) =>{
            state.showWarningMsg = action.payload
        },
        setShowActiveSchedule: (state, action) =>{
            state.showActiveSched = action.payload
        },
        setShowExpiredSchedule: (state, action) =>{
            state.showExpiredSched = action.payload
        },
        setShowComingSchedule: (state, action) =>{
            state.showComingSched = action.payload
        },
        setEditableMode: (state, action) =>{
            state.editMode = action.payload
        },
        setCrudSchedStatus: (state, action) =>{
            state.crudSchedStatus = action.payload
        },
        setEnableClearAll: (state, action) =>{
            state.enableClearAll = action.payload
        },
        setCurrScheduleId: (state, action: PayloadAction<string>) => {
            state.currSchedId = action.payload
        },
        setAllScheds: (state, action: PayloadAction<Sched[]>) => {
            state.allScheds = action.payload
        },
        setCurrActiveSchedIds: (state, action) =>{
            state.currActiveSchedIds = action.payload
        },
    }
})

export default scheduleSlice.reducer
export const {
    setHeightLimited,
    
    setShowNumberOfDevices,
    setShowWarningMsg,

    setShowActiveSchedule,
    setShowExpiredSchedule,
    setShowComingSchedule,
    setEditableMode,
    setCrudSchedStatus,
    setEnableClearAll,
    setCurrScheduleId,
    setAllScheds,

    setCurrActiveSchedIds,

}  = scheduleSlice.actions
