import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssociativeArray, Content, defaultContentLandscape, defaultContentPortrait, Element, ElementSetting, IMAGEDURATION, InitialState, Source, 
    // Todo ,
    // fetchTodos,
    // fetchSetContentNew, 
} from './contentModels';
import { Template } from '../template/templateModels';
import { Media } from '../media/mediaModels';
import { RootState } from '../../store';

const initialState: InitialState = {
    selectedContentIds: [],

    currContent     : defaultContentLandscape,
    prevContent     : defaultContentLandscape,
    currPlaylist    : defaultContentLandscape,
    currMedia       : null,
    unselectedSectionIds   : [],
    currSectionId   : "",
    currLayerId     : "",
    currLayerOrder  : -1,
    hoverSectionId  : "",
    hoverLayerId    : "",
    hoverLayerOrder : -1,
    hoverLayerSourceId    : "",    

    errStatus       : false,
    contentIDInUse  : [],
    mediaIDInUse    : [],
    checkSum        : 1,    

    // list: [],
    // error: null,
    // status: "idle",
}

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers:{    
        setSelectedContentIds(state, action: PayloadAction<string[]>){ 
            const arrIds: string[] = action.payload           
            state.selectedContentIds = arrIds
        },
        toggleContentId(state, action: PayloadAction<string>){ 
            const contId: string = action.payload            
            var tmpArrIds: string[] = state.selectedContentIds ? state.selectedContentIds.map(id => id) : []
            //-------------------
            if (tmpArrIds.includes(contId)){                
                state.selectedContentIds = tmpArrIds.filter(id => id != contId)
            }else{
                state.selectedContentIds = [...tmpArrIds, contId]                
            }                        
        },
        setErrStatusContent: (state, action) => {
            state.errStatus = action.payload
        },       
        setUnselectedSectionIds(state, action: PayloadAction<string[]>){ 
            const newSectIds: string[] = action.payload           
            state.unselectedSectionIds = newSectIds
        },
        setSectionId(state, action: PayloadAction<string>){ 
            const newSectId: string = action.payload           
            state.currSectionId = newSectId
        },
        setLayerId(state, action: PayloadAction<string>){ 
            const newLayerId: string = action.payload           
            state.currLayerId = newLayerId            
        }, 
        setLayerOrder(state, action: PayloadAction<number>){ 
            const newLayerOrder: number = action.payload           
            state.currLayerOrder = newLayerOrder            
        },       
        setHoverSectionId(state, action: PayloadAction<string>){ 
            const newSectId: string = action.payload           
            state.hoverSectionId = newSectId
        },
        setHoverLayerId(state, action: PayloadAction<string>){ 
            const newLayerId: string = action.payload           
            state.hoverLayerId = newLayerId            
        },
        setHoverLayerOrder(state, action: PayloadAction<number>){ 
            const order: number = action.payload           
            state.hoverLayerOrder = order
        },
        setHoverLayerSourceId(state, action: PayloadAction<string>){ 
            const newLayerSourceId: string = action.payload           
            state.hoverLayerSourceId = newLayerSourceId            
        },        
        setContentIDInUse(state, action: PayloadAction<AssociativeArray[]>){            
            state.contentIDInUse = action.payload
        },
        setMediaIDInUse(state, action: PayloadAction<AssociativeArray[]>){            
            state.mediaIDInUse = action.payload
        },
        setPrevContent(state, action: PayloadAction<Content>){            
            const content = action.payload
            state.prevContent = content
        },
        setContent(state, action: PayloadAction<Content>) {            
            const content = action.payload
            state.currContent = content
        },
        setPlaylist(state, action: PayloadAction<Content>){
            const playlist = action.payload
            state.currPlaylist = playlist
        },
        setMedia(state, action: PayloadAction<Media>){
            const medi = action.payload
            state.currMedia = medi
        },
        setContentFromTemplate(state, action: PayloadAction<Template>){
            const template = action.payload            

            var newEleList : Element[] = []                    
            template.positions?.            
            slice().sort((a,b)=> a.layer > b.layer ? 1: -1 )
            .map((pos, ind)=>{
                newEleList = [...newEleList, {
                    // element_id  : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                    // element_id  : Math.random().toString(9).slice(0,5).replace('0.', '').toUpperCase(),
                    element_id  : (ind+1).toString().padStart(2, '0'),
                    position_id : pos.position_id,
                    duration    : 0,
                    alias       : "1",
                    element_setting : {
                        transition : "fade",
                        transition_timer  : 2000,
                        bg_color: "#00000000"   //transparent
                    },
                    element_type: pos.element_type,
                    
                    sources     : [],
                    type        : pos.element_type,
                    user_template_id    : template.template_id,
                    x: pos.x,
                    y: pos.y,
                    w: pos.w,
                    h: pos.h }
                ]
            })

            const newContent: Content = {
                user_template_id    : state.currContent.user_template_id,
                user_template_name  : state.currContent.user_template_name,
                elements            : newEleList,
                duration            : IMAGEDURATION,    
                layer               : 0,
                
                require_schedule    : 1,        
                template_id         : template.template_id,
                width               : template.width,                
                height              : template.height,
            }
            state.currContent = newContent
        },
        setContentSource(state, action: PayloadAction<Source[]>){        
            const srcList = action.payload
            state.currContent.elements.map((sect, ind)=> {
                if (sect.element_id === state.currSectionId){
                    state.currContent.elements[ind].sources= srcList   //add not replace--still error                    
                }
            })
        },
        setContentSectionSettings(state, action: PayloadAction<ElementSetting>){        
            const sectSetting = action.payload            
            state.currContent.elements.map((sect, ind)=> {
                if (sect.element_id === state.currSectionId){
                    state.currContent.elements[ind].element_setting = sectSetting   //add not replace--still error                    
                }
            })
        },
        setContentSourceLayer(state, action: PayloadAction<Source>){        
            // console.log("currSectId", state.currSectionId)
            // console.log("currLayerId", state.currLayerId)
            // console.log("action.payload", action.payload)

            const source = action.payload
            // console.log("action.payload, source: ", source)
            
            state.currContent.elements.forEach((sect, ind)=> {
                if (sect.element_id === state.currSectionId){
                    state.currContent.elements[ind].sources.forEach((src, indLayer) => {
                        if (src.source_list_id === state.currLayerId){
                            state.currContent.elements[ind].sources[indLayer] = source
                            // state.currContent.elements[ind].sources[indLayer].param_source[0].duration = parseInt((source.param_source? (source.param_source[0].duration?source.param_source[0].duration:0):0).toString())
                        }
                    })
                }
            })
        },
        setContentName(state, action: PayloadAction<string>){
            state.currContent.user_template_name = action.payload
        },

        setCheckSum(state, action: PayloadAction<number>){
            state.checkSum = (action.payload > 10000? 1 : action.payload)
        },
        

        // pushNewContentSource(state, action: PayloadAction<Source>){ 
        //     console.log("currSectId", state.currSectionId)
        //     console.log("action.payload", action.payload)

        //     const newSrc = action.payload          
        //     state.currContent.elements.map((s, ind)=> {
        //         console.log(s)
        //         console.log(s.element_id, state.currSectionId, s.element_id === state.currSectionId)
        //         if (s.element_id === state.currSectionId){
        //             // state.currContent.elements[ind].sources.push(newSrc)
        //             state.currContent.elements[ind].sources.push(state.currContent.elements[ind].sources[0])
        //         }
        //     })
        // },
        resetContentLandscape(state){
            state.currContent = defaultContentLandscape
            state.prevContent = defaultContentLandscape
        },
        resetContentPortrait(state){      
            state.currContent = defaultContentPortrait
            state.prevContent = defaultContentPortrait
        },
    },
    // In `extraReducers` we declare 
    // all the actions:
    // extraReducers: (builder) => {

    //     // When we send a request,
    //     // `fetchTodos.pending` is being fired:
    //     builder.addCase(fetchTodos.pending, (state) => {
    //         // At that moment,
    //         // we change status to `loading` 
    //         // and clear all the previous errors:
    //         state.status = "loading";
    //         state.error = null;
    //     });

    //     // When a server responses with the data,
    //     // `fetchTodos.fulfilled` is fired:
    //     builder.addCase(fetchTodos.fulfilled, (state, { payload }) => {
    //     // builder.addCase(fetchTodos.fulfilled, (state, action: PayloadAction<Content>) => {
    //         // , action: PayloadAction<Content>
    //         // We add all the new todos into the state
    //         // and change `status` back to `idle`:

    //         // const content = action.payload
    //         // state.currContent = content

    //         console.log("state: ", state)
    //         console.log("payload: ", payload)
    //         // state.list.push(...payload);
    //         state.status = "completed";
    //     });        

    //     // When a server responses with an error:
    //     builder.addCase(fetchTodos.rejected, (state, { payload }) => {        
    //         // We show the error message
    //         // and change `status` back to `idle` again.
    //         if (payload) state.error = payload.message;            
    //         state.status = "error";
    //     });


    //     builder.addCase(fetchSetContentNew.pending, (state) => {            
    //         state.status = "loading";
    //         state.error = null;
    //     });
    //     builder.addCase(fetchSetContentNew.fulfilled, (state, action: PayloadAction<Content>) => {                
    //             // console.log("state: ", state)
    //             // console.log("action: ", action)
    //             const content = action.payload
    //             state.currContent = content   
    //             // setTimeout(() => {
    //             state.status = "completed";    
    //             // }, 1500);                             
    //     });   
    //     builder.addCase(fetchSetContentNew.rejected, (state, { payload }) => {        
    //         // We show the error message
    //         // and change `status` back to `idle` again.
    //         if (payload) state.error = payload.message;            
    //         state.status = "error";
    //     });
    
    // },

    
})


export default contentSlice.reducer
export const {
    setSelectedContentIds,
    toggleContentId,
    setErrStatusContent,    
    setUnselectedSectionIds,
    setSectionId,
    setLayerId,
    setLayerOrder,
    setHoverSectionId,
    setHoverLayerId,
    setHoverLayerOrder,
    setHoverLayerSourceId,
    setContentIDInUse,
    setMediaIDInUse,
    setPrevContent,
    setContent, 
    setPlaylist,
    setMedia,
    setContentFromTemplate,
    setContentSource,
    setContentSectionSettings,
    setContentSourceLayer,
    setContentName,
    setCheckSum,
    // pushNewContentSource,
    resetContentLandscape,
    resetContentPortrait,

    
}  = contentSlice.actions
