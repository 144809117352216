/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Component, MouseEventHandler, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../../../../../app/commons/css/customGlobal.css"
import { useAppSelector, useAppDispatch } from "../../../../../../../redux/hook";


import { contentPreviewPath } from '../../../../../../../redux/slices/content/contentModels';
import { closeFullscreen, openFullscreen } from '../../../../../../../redux/slices/editor/editorModels';

//----------------------------------------

type Props = {
  id: string,
  className: string,
}


//----------------------------------------
const ModalPreviewVideo: React.FC<Props> = ({ id, className}) => {

  const dispatch = useAppDispatch()
  //-----------------------------------------
  const currMediaPersistor = useAppSelector(state => state.persistor._content.currMedia)
  const [fullScreenMode, setFullScreenMode] = useState(false)
  const [isMuted, setIsMuted] = useState(true)

  // console.log("currMediaPersistor : ",currMediaPersistor)
  // console.log("isMuted : ",isMuted? "true":"false")

  const ratioW = 0.6
  const ratioH = 0.9

  
  const BORDERSIZE = 6  //6px
  const [previewScreenW, previewScreenH] = currMediaPersistor && currMediaPersistor.width >= currMediaPersistor.height 
                                                ? [Math.round(ratioW  * window.innerWidth), Math.round((9/16) * ratioW * window.innerWidth)]
                                                : [Math.round((9/16) * ratioH * window.innerHeight), Math.round(ratioH * window.innerHeight)]  
  
  useEffect(()=>{
    setIsMuted(true)
  },[currMediaPersistor])

  //-----------------------------------------
  return (
    <div className={`modal fade ${className}`} tabIndex={-1} id={id} style={{zIndex: 2051}}>
      <div className={`modal-dialog ${fullScreenMode === false ? "modal-xl":"modal-fullscreen"} modal-dialog-centered`}      
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width:  fullScreenMode === false 
                                            ? (previewScreenW+BORDERSIZE)+"px" 
                                            : (currMediaPersistor && (currMediaPersistor.width >= currMediaPersistor.height)? "100vw": "calc(100vh - 300px)"), 
          height: fullScreenMode === false  ? (previewScreenH+BORDERSIZE)+"px" 
                                            : "100%",                  
        }}
      >      
        <div className={`modal-content rounded-0 shadow-lg`}
          style={{
            height: fullScreenMode === false ? previewScreenH+6+"px" : "100%", 
            padding: fullScreenMode === false ? "3px" : "0px",
            background: "repeating-linear-gradient(-45deg, #150232, #150232, #030008)",
          }}
        >          
          <div className={`modal-body p-0 ${fullScreenMode === false ? "shadow-lg":""}` }
            onMouseOver={()=> {
              document.getElementById('preview-content-name')?.classList.remove('d-none')
            }}
            onMouseOut={()=> {
              document.getElementById('preview-content-name')?.classList.add('d-none')
              setIsMuted(false)
              setTimeout(() => {
                setIsMuted(true)                
              }, 100);
            }}
          >
            <div className='w-100 h-100 text-white' style={{marginTop: "-2px"}}>
              <video id="videoMedia" key={currMediaPersistor?.url} autoPlay={isMuted} controls muted={isMuted} className='w-100 h-100'>
                <source src={currMediaPersistor?.url} type="video/mp4" />
              </video>
            </div>
            <div id="preview-content-name" className='d-none position-absolute top-0 start-0 d-flex justify-content-end py-2 px-2 '>
              <span className="fs-5 text-capitalize text-gray-600">{currMediaPersistor?.name}</span>
            </div>
            <div className='position-absolute top-0 end-0 d-flex justify-content-end py-2 px-2 '>
              <button 
                className='btn btn-icon btn-sm btn-transparent tooltip-bottom'
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=> {                  
                  closeFullscreen()
                  setFullScreenMode(false)
                }}
              >
                <i className={`fa fa-times text-hover-primary fs-2`}></i>
              </button>              
            </div>
          </div>          
        </div>
      </div>
    </div>
  )
}

export { ModalPreviewVideo }
