import {FC, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {useThemeMode} from '../../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Logout, AuthPage, useAuth} from '../../auth'
import {KTSVG} from '../../../../_metronic/helpers'
import useContentful from '../components/useContentful'
import Slider from 'react-slick'
import "../../../commons/css/customHome.css"
import { Footer } from './partials/Footer'
import { Header } from './partials/Header'


// const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

const HomeLayout = () => {
  const {mode} = useThemeMode()
  useEffect(() => {
    // BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    // document.body.style.backgroundImage =
    //   mode === 'dark'
    //     ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
    //     : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`

    // return () => {
    //   BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
    //   document.body.style.backgroundImage = 'none'
    // }
  }, [mode])

  const {currentUser} = useAuth()    
  // -------------------------------------

  const [sections, setSections] = useState([])
  const { getBanners,getSections } = useContentful()

  
  useEffect(()=>{
      // --Access to contentful api
      // console.log("getSections")
      // console.log(getSections)
      getSections().then(
          (response) => {
            //console.log(response)
          }
      )

      getBanners().then(
          (response) => {
            //console.log(response)
          }
      )
  })    

  

  // ---------------------------------------------
  return (
    
    <div id="kt_body" data-bs-spy="scroll" data-bs-target="#kt_landing_menu" className="bg-white position-relative app-blank">
        {/* <script>var defaultThemeMode = "light"; var themeMode; if ( document.documentElement ) { if ( document.documentElement.hasAttribute("data-theme-mode")) { themeMode = document.documentElement.getAttribute("data-theme-mode"); } else { if ( localStorage.getItem("data-theme") !== null ) { themeMode = localStorage.getItem("data-theme"); } else { themeMode = defaultThemeMode; } } if (themeMode === "system") { themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"; } document.documentElement.setAttribute("data-theme", themeMode); }</script> */}
        <div className="d-flex flex-column flex-root" id="kt_app_root">            
            <Header />
            <Outlet />            
            <Footer />
        </div>




        {/* <script>var hostUrl = {toAbsoluteUrl("assets/")};</script>
        <script src={toAbsoluteUrl("/assets/plugins/global/plugins.bundle.js")}></script>
        <script src={toAbsoluteUrl("/assets/js/scripts.bundle.js")}></script>
        <script src={toAbsoluteUrl("/assets/plugins/custom/fslightbox/fslightbox.bundle.js")}></script>
        <script src={toAbsoluteUrl("/assets/plugins/custom/typedjs/typedjs.bundle.js")}></script>
        <script src={toAbsoluteUrl("/assets/js/custom/landing.js")}></script>
        <script src={toAbsoluteUrl("/assets/js/custom/pages/pricing/general.js")}></script> */}

    </div>    
  )
}

export {HomeLayout}
