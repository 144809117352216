import {FC} from 'react'

type Props = {  
    className?:string
  }
  
const SubAccountHeader: FC<Props> = ({className}) => {
    return (
        <div className={`${className} d-flex`}>
        </div>
        
    )
}

export {SubAccountHeader}