import {FC} from 'react'
import { useAppDispatch } from '../../redux/hook'
import {  StatisticsWidget5, StatisticsWidget7} from '../../../_metronic/partials/widgets'
import { devicesApi } from '../../redux/slices/device/apiDeviceSlice';
import { setSelectedContentIds } from '../../redux/slices/content/contentSlice';

type Props = { 
  className? : string 
}


const PlaylistHeader: FC<Props> = ({ className=""}) => {

    const dispatch = useAppDispatch()    
    // --------------------------------------------------



    // --------------------------------------------------
    return (
      <div className={`${className} d-flex`}>
        <div className="w-100"> 
            <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
              <button 
                className={`btn text-hover-primary tooltip-bottom`}                

                data-bs-toggle="offcanvas"
                aria-controls="staticBackdrop"
                data-bs-target="#kt_drawer_add_playlist"
                
                
                onClick={() => { dispatch(setSelectedContentIds([])); }}
              >
                <i className='bi bi-plus-circle text-gray-900 text-hover-primary fs-5 mt-n1 me-1'></i>
                <span className='fs-6'>Create new playlist</span>
              </button>
            </div>            
        </div>
      </div>
    )
}

export {PlaylistHeader}
