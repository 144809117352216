/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import { setZoomPercentage } from '../../../../redux/slices/editor/editorSlice';
//----------------------------------------

type Props = {
  className: string,
}

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },  
  {
    value: 100,
    label: '100%',
  },
];

function valuetext(value: number) {
  return `${value}%`;
}

// function valueLabelFormat(value: number) {
//   return marks.findIndex((mark) => mark.value === value) + 1;
// }


//----------------------------------------
const PanelBottom: React.FC<Props> = ({className}) => { 
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const currEditorZoom = useAppSelector(state => state.editor.zoomPercentage)
  // console.log("currEditorZoom:", currEditorZoom)
  //-----------------------------------------
  return (
    <>
      <div className={`${className} d-flex`}>
        {/* Bottom-Left Toolbar */}
        <div className="w-25">
          {/* <button 
            className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-3'
            data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
            // onClick={() => handleEditDevice(dev)}
            title='New Section' 
          >
            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3'/>
          </button>           */}
        </div>
        
        {/* Bottom-Middle */}
        <div className="w-50 d-flex justify-content-center">
            <Box sx={{ width: 200, textAlign: "center"}}>
              <Slider
                aria-label="Restricted values"
                value={Math.round(currEditorZoom * 100)}
                // valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                min={20}
                max={125}
                step={5}              
                // marks={marks}
                // marks  
                onChange={(e) => {dispatch(setZoomPercentage(parseInt((e.target as HTMLInputElement).value)*1.0/100))}
                }            
              />            
            </Box>    
        </div>

        {/* Bottom-Right Toolbar */}
        <div className="w-25 text-end">
          {/* <button 
              className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm ms-3'
              data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
              // onClick={() => handleEditDevice(dev)}
              title='New Section' 
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3'/>
            </button> */}
        </div>
      </div>
    </>
  )
}

export {PanelBottom}
