import { FC, useState, useEffect } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { 
    FormControl,
    InputLabel, MenuItem, 
    Select,
    FormHelperText
} from '@mui/material'


import { useGetAllRootFoldersQuery, useUploadFilesMutation } from "../../../../redux/slices/media/apiMediaSlice";
import FileUpload from "../../../../commons/uploadFiles/FileUpload";



const schema = yup.object().shape({
    folderId: yup.string().required(),
    noFile: yup.number()
         .min(1)
         .required('At least one file selected.')
})


type Props = {  
    className?: string
}


const DrawerUploadMedia: FC<Props> = ({className}) => {
    const [uploadMedia] = useUploadFilesMutation();
    // -----------------------------------------
    const [noFile, setNoFile] = useState('');
    const [selectedFolder, setSelectedFolder] = useState({
                                                    folderId: "",
                                                    sources: []
                                                })


    const { data: allFolders } = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })      
    

    
    
    const updateSources = (files: any) => {
        setSelectedFolder({ ...selectedFolder, sources: files})
    }

    const updateFolder = (folderId: string) => {
        setSelectedFolder({ ...selectedFolder, folderId: folderId})
    }

    // -----------------------
    const formSubmitHandler: SubmitHandler<any> = async (data) => {
        const formData = new FormData();
        // -----------------------------

        /* do manual validation if at least one valid file is selected for upload */
        if(selectedFolder.sources.length < 1){
            setNoFile("At least one file is selected")
        }        

        formData.append("source_group_id", data.folderId)
        formData.append("is_remote", "0")

        selectedFolder.sources.forEach(source => {
            formData.append("src_filepath[]", source)
            formData.append("i_url[]", "")
            formData.append("is_remote[]", "0")
            formData.append("url[]", "")
            formData.append("durationList[]", "")
            formData.append("widthList[]", "")
            formData.append("heightList[]", "")
        })
        
        await uploadMedia(formData);
    }

    const { 
        register, 
        handleSubmit,
       
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful,
            
        }
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues:{            
            noFile: 0
        },
        values:{
            noFile: selectedFolder.sources.length
        }
    }) 

    // -----------------------
    return (       
        <div
            id="kt_drawer_upload_media"
            data-kt-drawer-toggle={`#kt_drawer_upload_media_button`}
            data-kt-drawer-close= {`#kt_drawer_upload_media_close`}
            tabIndex={-2}
            className={`offcanvas offcanvas-end bg-white ${className}`}
            data-kt-drawer-activate="true"
            aria-labelledby="staticBackdropLabel"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        >
            <div className="offcanvas-header bg-light-primary">
                <h5 className="offcanvas-title text-primary" id="staticBackdropLabel">
                    <KTSVG
                        path={`/media/icons/duotune/files/fil013.svg`}
                        className={`svg-icon-2x svg-icon-primary me-2 mt-n2 d-inline`}
                    />
                    Upload Media Files
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                ></button>                        
            </div>
            <div className="offcanvas-body text-start">
                <form id="frm-add-device" className='form' onSubmit={handleSubmit(formSubmitHandler)}>
                    <div className="mb-4">                                
                        <FormControl fullWidth>
                            <InputLabel id="select-layout-label">Select Folder</InputLabel>
                            <Select 
                                {...register("folderId")} 
                                labelId="select-layout-label"                                        
                                label="Select Folder"
                                value={selectedFolder.folderId}
                                onChange={(e) => updateFolder(e.target.value)}
                                error={!! errors.groupId}    
                                MenuProps={{ disablePortal: true }}                                    
                            >               
                                {allFolders?.data.map((folder) => {
                                    return (<MenuItem key={folder.source_group_id} value={folder.source_group_id}>{folder.group_name}</MenuItem>)
                                })}  
                                                                    
                            </Select>
                            <FormHelperText>{`${errors.folderId?.message ?? ''}`}</FormHelperText>
                        </FormControl>                                                                
                    </div>
                    <div className="mb-4">
                        <input type="hidden"  {...register("noFile")}  />
                        {/*<input type="file" {...register("file")} multiple/>  */}
                        {/* <FileUpload 
                            accept='.jpg, .png, .jpeg, .mp4, .mp3'
                            label=""
                            multiple
                            updateFilesCb={updateSources}
                        /> */}
                    </div>
                    <div className="pt-2 text-end">                                    
                        <button type="submit" className="btn btn-primary" 
                            // data-bs-toggle="offcanvas"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            disabled={isSubmitting || !isValid}
                        >
                            <i className="fa fa-upload me-1 mt-n1"></i>
                            Upload
                        </button>
                    </div>

                </form>
            </div>
        </div>        
    )
}

export {DrawerUploadMedia}
