/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {getMaster, useAuth} from '../../../auth'
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { addCartItem, emptyCart, substractCartItem } from '../../../../redux/slices/shopping/shoppingSlice'
import moment from 'moment'
import { useGetAllDevicesQuery } from '../../../../redux/slices/device/apiDeviceSlice'
import { CartItem } from '../../../../redux/slices/shopping/shoppingModel'
import { setDevice } from '../../../../redux/slices/device/deviceSlice'
import { emptyDevice } from '../../../../redux/slices/device/deviceModels'


const HeaderShoppingCart: FC = () => {
  // ------------------------------------------------------
  const {currentUser, setCurrentUser, saveAuth, logout} = useAuth()
  const master = getMaster() 
  const dispatch = useAppDispatch()  
  // ------------------------------------------------------
  const [total, setTotal] = useState(0)

  const currCart = useAppSelector(state => state.persistor._shopping.cart)  
  // console.log("currCart in HeaderShoping", currCart)
  const flagCartShow = useAppSelector(state => state.shopping.statusCartShow)  

  const [currCartGroupbyPackage, setCurrCartGroupbyPackage] = useState<any>({})
  // console.log("currCartGroupbyPackage in HeaderShoping", currCartGroupbyPackage)

  const { data: allDevices, isLoading: isLoadingDevice, isFetching: isFetchingDevice, isError: isErrorDevice, isSuccess: isSuccessDevice } = useGetAllDevicesQuery(5, { refetchOnMountOrArgChange: true })


  // ====================================
  useEffect(() =>{
    setTotal(currCart.reduce(
      (accumulator, currItem) => accumulator + (currItem.licensePackage.price * currItem.qty),
      0,
    ))

    //---------------
    let tmpArrs: any = {}
    if (currCart && currCart.length > 0){
      currCart
      .slice().sort((a,b)=> (a.licensePackage.id > b.licensePackage.id) 
                                                                        ? (a.deviceId || b.deviceId) ? 1 : -1
                                                                        :-1
      )
      .forEach((cartItm)=>{ 
        // console.log("cartItm: ", cartItm)
        if (! Object.keys(tmpArrs).includes(cartItm.licensePackage.id + `${cartItm.deviceId? ",dev":""}`)){
          tmpArrs[cartItm.licensePackage.id + `${cartItm.deviceId? ",dev":""}`] = [{...cartItm}]
        }else{
          tmpArrs[cartItm.licensePackage.id + `${cartItm.deviceId? ",dev":""}`] = [...tmpArrs[cartItm.licensePackage.id + `${cartItm.deviceId? ",dev":""}`], {...cartItm}]
        }        
      })
    }
    setCurrCartGroupbyPackage(tmpArrs)
  }, [currCart])

  
  // ------------------------------------------------------
  return (
    <div
      className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px
        ${flagCartShow ? "show":""}
        shopping-cart-popup overflow-auto
      `}
      style={flagCartShow ? {zIndex: 2050, position: "fixed", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-136px, 70px)" } : {}}
      data-kt-menu='true'
    >
      <div className='menu-item pb-1 px-3'>
        <div className='menu-content d-flex align-items-center justify-content-between px-3'>
          <div className='symbol symbol-25px me-5'>
            <KTSVG path={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm001.svg')} className="svg-icon-2x" />
            <span className='ms-2 fs-5'>Cart</span>
          </div>
          <div className='m-0'>
            <Link to="/license" className='ms-0'>
              <button className={`btn btn-sm p-3 ${(!currCart || currCart.length <=0)? "btn-primary":"btn-secondary"}`}
                onClick={()=> dispatch(setDevice(emptyDevice))}
              >
                  <i className='bi bi-cart4 text-hover-primary fs-4 mt-n1 p-0'></i>
                  {(!currCart || currCart.length <=0) &&
                    <span className='fs-6 ms-2'>Purchase license</span>
                  }
              </button>
            </Link>
            {(currCart && currCart.length >0) &&
              <button className={`btn btn-secondary btn-sm px-3 m-0 ms-2`}
                onClick={()=> dispatch(emptyCart())}
              >
                  <i className='bi bi-trash text-hover-primary fs-4 mt-n1'></i>
                  <span className='fs-6'>Clear all</span>
              </button>
            }
          </div>
        </div>
      </div>

      { Object.keys(currCartGroupbyPackage)
        .map((currPkgGrpKey: string, grpInd: number) => {                      
          return (
            <Fragment key={`cartGrp-${grpInd}`}>
              { currCartGroupbyPackage[currPkgGrpKey]
                .map((currItem: CartItem, ind: number) => {   
                  const foundDev = (allDevices && currItem.deviceId)? (allDevices.find((dev)=> dev.id === currItem.deviceId)): undefined
                  const devName = foundDev? foundDev.deviceName : ""
                  let cartItem: CartItem = {
                                              license: currItem.license,
                                              licensePackage: currItem.licensePackage,
                                              qty: 1,
                                              expiredDate: moment().add(currItem.licensePackage.duration_month,"months").format("DD/MM/YYYY")
                                            }
                  cartItem = currItem.deviceId  ? {...cartItem, deviceId: currItem.deviceId} 
                                                : {...cartItem}
                  const groupQty = currCartGroupbyPackage[currPkgGrpKey].length
                  // ---------------------------------------------------------------
                  return (
                    <Fragment key={`cart${ind}-${currItem.licensePackage.id}`}>                      
                        { ind === 0 
                          ? <>
                              <div className='separator mt-3 mb-3'></div>
                              <div className='menu-item px-5'>
                                <div className="d-flex align-items-top justify-content-between py-1 pt-2 me-3">
                                  <div className="d-flex align-items-top justify-content-start">                            
                                    <div  className="symbol symbol-45 symbol-success mr-4 flex-shrink-0 me-2">
                                      <div className="symbol-label bg-light-primary text-primary fw-bold">
                                        {currItem.licensePackage.duration_month}M
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column mx-2">
                                      <div className="font-weight-bold font-size-lg text-gray-800 ">
                                        {currItem.licensePackage.name}
                                      </div>                   
                                      <div className="d-flex align-items-center flex-start mt-2">
                                          <span className="font-weight-bold mx-1 text-dark-75 font-size-lg">
                                            ${(currItem.licensePackage.price * 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                          </span>
                                          <span className="text-muted mx-1"> x </span>
                                          <span className="font-weight-bold mx-1 text-dark-75 font-size-lg">{currItem.qty * groupQty}</span>
                                          {devName === "" && <>
                                            <a href="#" className="badge badge-circle badge-secondary p-0 mx-1"
                                              onClick={()=>dispatch(substractCartItem(cartItem))}
                                            >
                                              <i className={`bi bi-dash`}></i>
                                            </a>                          
                                            <a href="#" className="badge badge-circle badge-secondary p-0"
                                              onClick={()=>dispatch(addCartItem(cartItem))}
                                            >
                                              <i className="bi bi-plus"></i>
                                            </a>
                                          </>}
                                      </div>
                                      {devName !== "" &&
                                        <div className='d-flex flex-column'>
                                          <div className='fw-normal fst-italic small mt-2'>Upgrade license{groupQty>1 ? "s":""} for device{groupQty>1 ? "s":""}</div>
                                          <div className='d-flex justify-content-start text-muted fst-italic fs-7 mt-2'>
                                            <div className='badge badge-secondary py-1'>
                                              <Link to="/license" className="text-gray-700 text-hover-primary ps-3 pe-1 tooltip-bottom"
                                                data-tooltip-content='Change license type'
                                                onClick={() => {
                                                  if (foundDev){
                                                    dispatch(setDevice(foundDev))  
                                                  }                              
                                                }}
                                              >                              
                                                <span>{devName}</span>
                                                <i className={`bi bi-arrow-left-right ms-3`}></i>
                                              </Link>
                                              
                                              <a href="#" className="badge badge-circle badge-secondary text-hover-primary p-0 ms-0 tooltip-bottom"
                                                data-tooltip-content='Remove'
                                                onClick={()=>{
                                                  dispatch(substractCartItem(cartItem))
                                                  dispatch(setDevice(emptyDevice))
                                                }}
                                              >
                                                <i className={`bi bi-trash`}></i>
                                              </a> 
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div className='d-flex text-gray-600 justify-content-end'>
                                    ${(currItem.licensePackage.price * currItem.qty * groupQty).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                  </div>                  
                                </div>
                              </div>
                            </>                            
                          : <div className='menu-item px-5'>
                              <div className="d-flex align-items-top justify-content-between me-3">
                                <div className="d-flex align-items-top justify-content-start ps-4">
                                  {/* <div className='w-45px'>&nbsp;</div> */}
                                  {/* <a href="#"  className="symbol symbol-45 symbol-success mr-4 flex-shrink-0 me-2">
                                    <div className="symbol-label bg-light-primary text-primary fw-bold">
                                      {currItem.licensePackage.duration_month}M
                                    </div>
                                  </a> */}
                                  <div className="d-flex flex-column mx-2">
                                    {devName !== "" &&
                                      <div className='d-flex justify-content-start text-muted fst-italic fs-7'>
                                        <div className='badge badge-secondary'>
                                          <span>{devName}</span>
                                          <a href="#" className="badge badge-circle badge-secondary p-0 mx-1"
                                            onClick={()=>dispatch(substractCartItem(cartItem))}
                                          >
                                            <i className={`bi bi-trash`}></i>
                                          </a> 
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>                                                 
                              </div>
                            </div>
                        }
                    </Fragment>
                  )}
                )

              }
            </Fragment>
          )
        }
      )}
      
      <div className='separator separator-dashed mt-8 mb-2'></div>
      <div className='menu-item px-3'>
        <div className="px-5 d-flex justify-content-end">
          <div className='menu-text justify-content-end px-8'>
            Total <span className='text-gray-500 fs-7'>(Incl. GST)</span>:
            {/* <span className='badge badge-light-success fs-8 px-2 py-1 ms-2'>Pro</span>            */}
          </div>
          <div className='menu-text justify-content-end'>
            ${(total).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </div>
        </div>
      </div>      

      <div className='separator separator-dashed my-2'></div>
      {currCart.length > 0 &&
      <div className='menu-item px-5 my-2'>
        <Link to="/shopping/checkout" 
          className={`btn btn-primary px-5 d-flex flex-center `}
          data-kt-menu-dismiss="true"
        >
          Checkout ({currCart.length}) items
        </Link>
      </div> 
      }

      <div className='menu-item px-5'>
        <Link to="/shopping/cart" 
          className={`btn btn-secondary px-5 d-flex flex-center `}
          data-kt-menu-dismiss="true"
        >
          View Cart
        </Link>
      </div>      
    </div>
  )
}

export {HeaderShoppingCart}
