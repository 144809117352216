import {FC} from 'react'
import Slider from 'react-slick'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'


const HeaderBackgroundHome: FC<{clsName?:string}> = ({clsName}) => {
    
    //--------------------------------
    return (
      <Slider {...{
        fade: true,                                        
        dots: false,
        // dotPosition: "top",
        arrows: false,      
        autoplay: true,
        infinite: true,      
        slidesToShow:   1,      
        slidesToScroll: 1,                                        
        speed: 5000,                                        
        pauseOnHover: false
        
        }}
      >                                 
        
        <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
            <div 
                className='w-100 vh-100'
                style={{background:`no-repeat top center/cover #eff2f5cc url(${toAbsoluteUrl('/media/_background/bg-004.jpg')})`}}
            >
                &nbsp;
            </div>                            
        </div>
        <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
            <div 
                className='w-100 vh-100'
                style={{background:`no-repeat top center/cover #eff2f5cc url(${toAbsoluteUrl('/media/_background/bg-008.jpg')})`}}
            >
                &nbsp;
            </div>                            
        </div>
        <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
            <div 
                className='w-100 vh-100'
                style={{background:`no-repeat top center/cover #eff2f5cc url(${toAbsoluteUrl('/media/_background/bg-002.png')})`}}
            >
                &nbsp;
            </div>                            
        </div>
        <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
            <div 
                className='w-100 vh-100'
                style={{background:`no-repeat top center/cover #eff2f5cc url(${toAbsoluteUrl('/media/_background/bg-001.jpg')})`}}
            >
                &nbsp;
            </div>                            
        </div>
        <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
            <div 
                className='w-100 vh-100'
                style={{background:`no-repeat top center/cover #eff2f5cc url(${toAbsoluteUrl('/media/_background/bg-002.jpg')})`}}
            >
                &nbsp;
            </div>                            
        </div>
        <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
            <div 
                className='w-100 vh-100'
                style={{background:`no-repeat top center/cover #eff2f5cc url(${toAbsoluteUrl('/media/_background/bg-006.jpg')})`}}
            >
                &nbsp;
            </div>                            
        </div>
      </Slider>
  )
}

const HeaderBannerHome: FC = () => {
    
  //--------------------------------
  return (
    <div
      id='banner'
      className='w-100' 
      // className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px min-h-xl-900px px-9"
      // className="position-absolute top-50 start-50 translate-middle "                            
    >
        <Slider {...{
                    fade: false,                  
                    dots: false,
                    arrows: false,      
                    autoplay: true,
                    infinite: true,      
                    slidesToShow:   1,      
                    slidesToScroll: 1,                  
                    speed: 5000,
                    pauseOnHover: false
                }}
            >
            
            <div className="d-flex flex-center justify-content-center align-content-center w-100 vh-100 ms-0 me-0" >
                <div 
                    className="text-center w-75 mb-lg-10 py-10 py-lg-20"
                    style={{marginTop:"-100px"}}
                >
                    <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-10">                                                    
                        Digital Signage Simplified                                                    
                        <br/> 
                        <span style={{background: "linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>
                            <span id="kt_landing_hero_text">  Upload 
                            <i className='fa fa-long-arrow-right fs-1 text-white ms-3 me-3'></i> Schedule
                            <i className='fa fa-long-arrow-right fs-1 text-white ms-3 me-3'></i> Publish
                            </span>
                        </span>                                                    
                    </h1>                                                
                    <div className="w-100 d-flex flex-column justify-content-center flex-center">
                        <img src={toAbsoluteUrl("/media/_landing_banners/banner-01.png")} className='w-100 w-lg-75'/>
                    </div>                                                
                </div>                                            
            </div>
            
            <div className="d-flex flex-center justify-content-center align-content-center w-100 vh-100 ms-0 me-0" >
                <div 
                    className="text-center w-75 mb-lg-10 py-10 py-lg-20"
                    style={{marginTop:"-100px"}}
                >
                    <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-0">                                                    
                        Digital Signage Solution <br className='d-xs-inline d-sm-inline d-md-inline d-xl-inline d-xxl-none'/>
                        Empowers Your Business                                                    
                    </h1>
                    <div className='fs-1 mb-5' style={{background: "linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>
                        <span id="kt_landing_hero_text">
                            Captivate Your Target Audience,  <br className='d-xs-inline d-sm-inline d-md-inline d-xl-inline d-xxl-none'/>
                            Enhance Communications,
                            Grow Business & Revenues.
                        </span>
                    </div>                                                 
                    <div className="w-100 d-flex flex-column justify-content-center flex-center">
                        <div className="w-100 d-flex flex-center mb-5 mb-lg-5">
                            <ul className="nav border-transparent flex-center fs-5 fw-bold">                                        
                                <li className="nav-item">
                                    <Link  className="nav-link fs-2 text-gray-500 text-active-light px-3 px-lg-6 active" to="/industries" 
                                        ><i className='fa fa-caret-right fs-3 me-3'></i>Restaurant, Retail & Commerce, Health Services</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="menu-link nav-link fs-2 text-gray-500 py-3 px-4 px-xxl-6" href="#industries" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">
                                        <i className='fa fa-caret-right fs-3 me-3'></i>Others
                                    </a>
                                </li>                                                            
                            </ul>                                            
                        </div>
                        <div className="w-100 tab-content">
                            <div className="tab-pane fade show active" id="banner_landing_restaurant">
                                <div className="row g-5 g-xl-10">
                                    <div className="col-4 col-lg-4">
                                        <a className="d-block card-rounded overlay h-lg-75 mb-5" data-fslightbox="lightbox-projects" href="/media/_landing_industries/restaurant/restaurant-01.gif" target='_blank'>
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-150px min-h-lg-350px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/restaurant/restaurant-01.gif')+")"}}></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                        <div className="row g-5 d-none d-xl-flex d-xxl-flex">                                                                        
                                            <div className="col-7">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/restaurant/restaurant-03.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/restaurant/restaurant-03.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-5">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/restaurant/restaurant-04.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/restaurant/restaurant-04.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>                                                                    
                                    </div>                                                                
                                    <div className="col-4 col-lg-4">
                                        <a className="d-block card-rounded overlay h-lg-75 mb-5" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-09.jpeg" target='_blank'>
                                            {/* <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-350px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-09.jpeg')+")"}}></div> */}
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-150px min-h-lg-350px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-09.jpeg')+")"}}></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                        <div className="row g-5 d-none d-xl-flex d-xxl-flex">
                                            <div className="col-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-03.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-03.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>                                                                        
                                            <div className="col-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-05.webp" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-05.webp')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>                                                                    
                                    </div>                                                                
                                    <div className="col-4 col-lg-4">
                                        <a className="d-block card-rounded overlay h-lg-75 mb-5" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-reception-2.jpg" target='_blank'>
                                            {/* <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-350px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-reception-2.jpg')+")"}}></div> */}
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-150px min-h-lg-350px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-reception-2.jpg')+")"}}></div>                                            
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                        <div className="row g-5 d-none d-xl-flex d-xxl-flex">
                                            <div className="col-5">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-schedule.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-schedule.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-7">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-waiting-list.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-waiting-list.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>                                                                    
                                    </div>                                                                                                                                
                                </div>
                            </div>
                            <div className="tab-pane fade" id="banner_landing_retail_commerce">
                                <div className="row g-10">                                            
                                    <div className="col-lg-6">                                                
                                        <a className="d-block card-rounded overlay mb-10" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-03.jpg" target='_blank'>
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-03.jpg')+")"}}></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                        <div className="row g-10">
                                            <div className="col-lg-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-01.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-01.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-lg-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-02.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-02.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">                                                                                                
                                        <div className="row g-10 mb-10">
                                            <div className="col-lg-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-08.avif" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-08.avif')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-lg-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-09.jpeg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-09.jpeg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/retail/retail-05.webp" target='_blank'>
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/retail/retail-05.webp')+")"}}></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>                                                        
                            <div className="tab-pane fade" id="banner_landing_health_services">
                                <div className="row g-10">
                                    <div className="col-lg-6">
                                        <a className="d-block card-rounded overlay h-lg-100" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-reception-2.jpg" target='_blank'>
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-reception-2.jpg')+")"}}></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row g-10 mb-10">
                                            <div className="col-lg-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-waiting-list.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-waiting-list.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-lg-6">
                                                <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-staff-room.jpg" target='_blank'>
                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-staff-room.jpg')+")"}}></div>
                                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                        <i className="bi bi-eye-fill fs-3x text-white"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects" href="/media/_landing_industries/health_services/hospital-schedule.jpg" target='_blank'>
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"  style={{backgroundImage: "url("+toAbsoluteUrl('/media/_landing_industries/health_services/hospital-schedule.jpg')+")"}}></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i className="bi bi-eye-fill fs-3x text-white"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>                                    
                        </div>
                    </div>
                </div>                                            
            </div>
        </Slider>                                    
    </div>
  )
}

export {HeaderBackgroundHome, HeaderBannerHome}
