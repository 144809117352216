/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { useDeleteScheduleMutation, useGetAllSchedulesQuery, useUpdateScheduleMutation } from '../../../../redux/slices/schedule/apiScheduleSlice';
import moment from 'moment';
import Swal from 'sweetalert2';
import { setAllScheds, setCurrScheduleId, setCrudSchedStatus, setCurrActiveSchedIds } from '../../../../redux/slices/schedule/scheduleSlice';
import { AssignedContent, Sched, ScheduleInput, extractCurrActiveSchedules, extractRepeatModeFromSchedule, extractStartEndDatesFromSched } from '../../../../redux/slices/schedule/scheduleModels';

import { Gantt, Task, ViewMode } from 'gantt-task-react';
// import "gantt-task-react/dist/index.css";
import "../ganttChart/customGantt.css";
import { 
    TooltipContent,
    TaskListHeader,
    TaskListTable,
    ViewSwitcher,
} from '../ScheduleComponents';
import { DrawerComponent } from '../../../../../_metronic/assets/ts/components';
import { useGetAllContentsQuery } from '../../../../redux/slices/content/apiContentSlice';
import { Content } from '../../../../redux/slices/content/contentModels';


type Props = {
  className: string,
  inView: boolean,
}




// --------------------------------------------
// Main Component
// --------------------------------------------
const GanttSchedules: React.FC<Props> = ({className, inView}) => {        
    const dispatch = useAppDispatch()  
    // ----------------------------------------
    const [updateSchedule] = useUpdateScheduleMutation();
    const [deleteSchedule] = useDeleteScheduleMutation();

    // ------------------------------------
    const isHeightLimited   = useAppSelector(state => state.persistor._schedule.isHeightLimited)
    const showActiveSch     = useAppSelector(state => state.persistor._schedule.showActiveSched)
    const showExpiredSch    = useAppSelector(state => state.persistor._schedule.showExpiredSched)
    const showComingSch     = useAppSelector(state => state.persistor._schedule.showComingSched)  
    const editableMode      = useAppSelector(state => state.persistor._schedule.editMode)

    const allScheds = useAppSelector(state => state.persistor._schedule.allScheds) // for just pure a list of all sched
    // //-----------------------------------------    
    const [today, setToday] = useState(moment());
    const [currentDate, setCurrentDate] = useState(new Date()); 
    const currActiveSchedIds = useAppSelector(state => state.persistor._schedule.currActiveSchedIds) // for just pure a list of all sched

    // const [currActiveSchedIds  , setCurrActiveSchedIds] = useState<string[]>([]);  
    //-----------------------------------------
    const [isChecked, setIsChecked] = useState(true);    
    const [view, setView] = useState<ViewMode>(ViewMode.Day);
    const [tasks, setTasks] = useState<Task[]>([
        {
            start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
            name: "Init Group",
            id: "Init Group 1",
            progress: 25,
            type: "project",
            hideChildren: false,
            displayOrder: 1
        }
    ]);

    // console.log("currActiveSchedIds: ", currActiveSchedIds)
    // -------------------------------------------------------
    const { data: allContents, isLoading, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
    const [emergContent, setEmergContent] = useState<Content>()
    useEffect(()=>{
        if (allContents){
            setEmergContent(allContents.find((cont)=> cont.emergency === 2))
        }
    },[allContents])

    
    let { data: allSchedules, isLoading:isLoadingSchedules, isFetching: isFetchingSchedules} = useGetAllSchedulesQuery(5, { refetchOnMountOrArgChange: true })  
    // console.log("allSchedules: ", allSchedules)
    // --------------------------------
    let columnWidth = 65;
    if (view === ViewMode.Year) {
        columnWidth = 350;
    } else if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }
    
    // --------------------------------
    const getStartEndDateForProject = (tasks: Task[], projectId: string) => {
        const projectTasks = tasks.filter(t => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;
      
        for (let i = 0; i < projectTasks.length; i++) {
          const task = projectTasks[i];
          if (start.getTime() > task.start.getTime()) {
            start = task.start;
          }
          if (end.getTime() < task.end.getTime()) {
            end = task.end;
          }
        }
        return [start, end];
    }

    // --------------------------------
    const handleAddTask = (task: Task) => {
      
        const currentDate = new Date();
    
        const newTasks: Task = {
          name: "new task",
          type: "task",
          progress: 100,
          id: new Date().getMilliseconds().toString(),
          start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
          end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
          project: task.id
        };
        setTasks((e) => [...e, newTasks]);
    }

    // --------------------------------
    const handleTaskChange = (task: Task) => {
        
        // console.log("On date change Id:" + task.id);

        let newTasks = tasks.map(t => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
            if (project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()) {

                const changedProject = { ...project, start, end };
                newTasks = newTasks.map(t =>
                    t.id === task.project ? changedProject : t
                ); 
            }
        }
        setTasks(newTasks);

        // Update sched thru api
        // -----------------------------------
        const selectSched:Sched[] = allScheds.filter((sch )=> {return sch.id === task.id})
        // console.log("selectSched", selectSched)

        if (selectSched.length>0){
            let contList : AssignedContent[] = []
            selectSched[0].user_templates.map((cont)=>{
                contList = [...contList, {
                        content_id      : cont.user_template_id?cont.user_template_id:"",
                        play_duration   : cont.duration, 
                        mode            : '',
                        num_loops       : 0,                 
                    }]
            })

            let newSche : ScheduleInput = {
                id      : selectSched[0].id,
                start_time  : moment(task.start).format("YYYY-MM-DD HH:mm:ss"),
                end_time    : moment(task.end).format("YYYY-MM-DD HH:mm:ss"),
                // repeat: "once",
                deviceGroupIds: selectSched[0].group_id,
                contents: contList,
            }
            
            if (selectSched[0].type_id === 2 && selectSched[0].cron !== undefined){
                newSche =   { ...newSche,
                                type: selectSched[0].type_id? selectSched[0].type_id+"": "",
                                cron: [selectSched[0].cron]
                            }
            }
            
            try{
                updateSchedule(newSche)
            }catch(error){
                console.log("There is error", error)
            }
        }
    };

    // Done --------------------------------
    const handleTaskDelete = (task: Task) => {

        const taskNam = task.name && task.name[0] !== "."? task.name: task.name.substring(1,)

        Swal.fire({
            title: 'Are you sure you want to delete this schedule?<h1 style="color:#331168; margin: 15px 0;">'+ taskNam +'</h1>',
            /* text: "You won't be able to revert this!",*/
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, delete it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
              // denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {                
                try{                       
                    deleteSchedule(task.id) // delete from real data api
                    setTasks(tasks.filter(t => t.id !== task.id))  // remove from gantt svg chart
                    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        // timerProgressBar: true
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Schedule:<span class="text-primary fw-semibold ms-3 text-capitalize">'+ taskNam + '</span><br/>has been succesfully deleted.',
                    })
                }catch(error){
                    Swal.fire(
                        'Schedule: '+ task.name,
                        'There is something wrong during the deletion!',
                        'error'
                    )                                    
                }                
            }
            return result.isConfirmed
        })
        return false        
    }

    // Done --------------------------------
    const handleDeleteAllTasksInSchedule = (task: Task) => {
             
        const grpTasks = tasks.filter(t => t.type==="task" && t.project === task.id)
        // console.log("grpTasks: ", grpTasks)        

        const taskNam = task.name && task.name[0] !== "."? task.name: task.name.substring(1,)

        Swal.fire({
            title: 'Do you really want to clear all the schedules in this group?<h1 style="color:#331168; margin: 15px 0;">'+ taskNam +'</h1>',
            /* text: "You won't be able to revert this!",*/
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, clear all!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
              // denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {                
                try{   
                    grpTasks.forEach((tsk, ind) => {
                        deleteSchedule(tsk.id) // delete from real data api
                        setTasks(tasks.filter(t => t.id !== tsk.id))  // remove from gantt svg chart
                    })                 
                    
                    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        // timerProgressBar: true
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'All schedules in group:<span class="text-primary fw-semibold ms-3 text-capitalize">'+ taskNam + '</span><br/>has been all cleared.',
                    })
                }catch(error){
                    Swal.fire(
                        'Schedule: '+ task.name,
                        'There is something wrong during the deletion!',
                        'error'
                    )                                    
                }                
            }
            return result.isConfirmed
        })
        return false        
    }

    // --------------------------------
    const handleMoveScheduleUp = (task: Task) => {  
        console.log("task:", task)

        let newTasks = tasks.map(t => (t.id === task.id ? task : t));
        console.log("newTasks:", newTasks)

        const taskNam = task.name && task.name[0] !== "."? task.name: task.name.substring(1,)
        console.log("taskNam:", taskNam)



        // if (task.project) {
        //     const [start, end] = getStartEndDateForProject(newTasks, task.project);
        //     const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
        //     if (project.start.getTime() !== start.getTime() ||
        //         project.end.getTime() !== end.getTime()) {

        //         const changedProject = { ...project, start, end };
        //         newTasks = newTasks.map(t =>
        //             t.id === task.project ? changedProject : t
        //         ); 
        //     }
        // }
        // setTasks(newTasks);

        // // Update sched thru api
        // // -----------------------------------
        // const selectSched:Sched[] = allScheds.filter((sch)=> {return sch.id === task.id})
        // // console.log("selectSched", selectSched)

        // if (selectSched.length>0){
        //     let contList : AssignedContent[] = []
        //     selectSched[0].user_templates.map((cont)=>{
        //         contList = [...contList, {
        //                 content_id      : cont.user_template_id?cont.user_template_id:"",
        //                 play_duration   : cont.duration, 
        //                 mode            : '',
        //                 num_loops       : 0,                 
        //             }]
        //     })

        //     let newSche : ScheduleInput = {
        //         id      : selectSched[0].id,
        //         start_time  : moment(task.start).format("YYYY-MM-DD HH:mm:ss"),
        //         end_time    : moment(task.end).format("YYYY-MM-DD HH:mm:ss"),
        //         // repeat: "once",
        //         deviceGroupIds: selectSched[0].group_id,
        //         contents: contList,
        //     }
            
        //     if (selectSched[0].type_id === 2 && selectSched[0].cron !== undefined){
        //         newSche =   { ...newSche,
        //                         type: selectSched[0].type_id? selectSched[0].type_id+"": "",
        //                         cron: [selectSched[0].cron]
        //                     }
        //     }
            
        //     try{
        //         updateSchedule(newSche)
        //     }catch(error){
        //         console.log("There is error", error)
        //     }
        // } 
    }

    // -------------------------------------
    const handleMoveScheduleDown = (task: Task) => {    
        console.log("Move schedule down... is in progress")
    }

    // -------------------------------------
    const handleViewGroupSchedules = async (task: Task) => {
        dispatch(setCurrScheduleId(task.id))
    }

    // --------------------------------
    // const handleProgressChange = async (task: Task) => {
    //     setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    //     console.log("On progress change Id:" + task.id);
    // };

    // --------------------------------
    const handleDblClick = (task: Task) => {
        dispatch(setCrudSchedStatus({updateSchedMode: true, schedMode: ""}))  //change addSchedule to edit mode

        dispatch(setCurrScheduleId(task.id))        
        
        // var drawer = DrawerComponent.getInstance("kt_drawer_update_schedule");        
        DrawerComponent.getInstance("kt_drawer_crud_schedule")?.toggle();        
        
               
    };
    
    // --------------------------------
    const handleClick = (task: Task) => {
        // dispatch(setCurrScheduleId(task.id))
        // console.log("On Click event Id:" + task.id);        
    }

    // --------------------------------
    const handleSelect = (task: Task, isSelected: boolean) => {
        
        if(isSelected){
           // console.log("More analytical data of each schedule will be displayed.")
        }
    }

    // --------------------------------
    const handleExpanderClick = (task: Task) => {        
        setTasks(tasks.map(t => (            
            t.id === task.id ? task : t
        )))
       // console.log("On expander click Id:" + task.id);
    }    

    


    
    

    // --------------------------------
    const handleChartHeight = (usedHeight:number) =>{
        let windowInnerHeight = window.innerHeight;
        return windowInnerHeight - usedHeight;
    }

    
    //--------------------------------------------------
    //---Loading the schedules from all groups
    //--------------------------------------------------    
    useEffect(() => {        
        if (inView){
            setToday(moment())
            setCurrentDate(new Date())
            // ----------------------------------
            let listScheds: Sched[] = []
            let allTasks : Task[] = []

            // console.log("allSchedules in useEffects", allSchedules)

            if (allSchedules && allSchedules.length > 0){

                allSchedules
                .slice().sort((a, b) => { 
                    return (a.group_name.toLowerCase() >= b.group_name.toLowerCase() ? 1 : -1) 
                })
                .map((gr, indGr) => {

                    // filter only the scheds without groups
                    listScheds = [...listScheds, ...gr.schedules]                
                    dispatch(setAllScheds(listScheds))

                    // These endDate is for setting the project progress in transparent background and white text
                    let endDateSchedules = currentDate
                    endDateSchedules.setDate(endDateSchedules.getDate() + 5)                

                    allTasks = [    ...allTasks, 
                                    {
                                        name    : `${gr.name}`,
                                        start   : new Date(),
                                        end     : endDateSchedules,                                        
                                        id      : `${gr.id}`,
                                        progress: 100,                                    
                                        type    : "project",
                                        isDisabled  : true, 
                                        hideChildren : false,
                                        displayOrder : indGr+1, //display order is started from 1, not 0
                                        styles  : { 
                                            backgroundColor : "#009ef700",   //transparent to hide it
                                            progressColor   : '#009ef700',
                                            progressSelectedColor: '#009ef700',
                                            backgroundSelectedColor: '#009ef700',
                                        },                                    
                                    }
                                ]
                    
                    gr.schedules.slice().sort((a, b) => a.sche_layer < b.sche_layer ? 1 : -1)
                    .map((sch, ind) => {
                        // console.log("sch: ",sch)
                        // console.log("sch.endtime : ",sch.end_time)
                        
                        const [startDate, endDate] = extractStartEndDatesFromSched(sch)                    

                        const progress    = 100*(startDate.diff(today)) / (startDate.diff(endDate))          

                        // determine the color ranges to represent schedules in the Gantt chart
                        // ----------------------------------------------------------------------------
                        let progColor = "#B5B5C3"   //gray
                        let backgrColor = "B5B5C3"  //gray
                        const progSelectedColor = '#ffc107'     //green
                        const backgrSelectedColor = '#ffc10788' 
                        // --------------------------

                        if (currActiveSchedIds && currActiveSchedIds.includes(sch.schedule_id)){  //for currently active schedules
                            progColor   = '#168168'
                            backgrColor = '#16816888'
                        }else{
                            progColor = sch.cron    ? (progress<100 ? '#9C258F66':'#B5B5C3')    //for cron schedules
                                                                    //primary //dark    
                                                    : (progress<100 ? '#009ef788':'#B5B5C3')
                                                                    //blue    //dark    
                            backgrColor = sch.cron  ? (progress>0   ? '#9C258F88': '#9C258F30') //for cron schedules
                                                    : (progress>0   ? "#009ef788": "#009ef750") 
                        }

                        sch.user_templates.map((content, indCont) => {
                            
                                

                            if (    ((showActiveSch)    || (!showActiveSch && ((progress<=0) || (progress>=100)))) &&
                                    ((showExpiredSch)   || (!showExpiredSch && (progress<100))) &&
                                    ((showComingSch)    || (!showComingSch && (progress>0)))
                            ){

                                allTasks = [    ...allTasks, 
                                    {
                                        start   : new Date(Date.parse(startDate.format('YYYY-MM-DD HH:mm:ss'))),
                                        // start   : new Date(Date.parse(sch.start_time?sch.start_time:"")),
                                        // end     : new Date(Date.parse(sch.end_time?sch.end_time:"")),  
                                        end     : new Date(Date.parse(endDate.format('YYYY-MM-DD HH:mm:ss'))),

                                        // name    : `${sch.user_templates.length === 0
                                        //             ? `${content.type === "playlist" ? ".":""}` + `${sch.type_id === 2 ? "*":""}` + sch.user_templates[indCont].user_template_name 
                                        //             // : sch.id+" - "+sch.user_templates[indCont].user_template_name    // if more than one contents per schedule
                                        //             : `${content.type === "playlist" ? ".":""}` + `${sch.type_id === 2 ? "*":""}` + sch.user_templates[indCont].user_template_name     // if more than one contents per schedule
                                        // }`,
                                        name    :   `${content.type === "playlist" ? ".":""}` + 
                                                    `${sch.type_id === 2 ? "*"+extractRepeatModeFromSchedule([sch])+" - ":""}` + 
                                                    sch.user_templates[indCont].user_template_name,

                                        id      : `${sch.schedule_id}`,
                                        project : `${gr.id}`,
                                        // dependencies?: string[];
                                        progress: progress<0 ? 0 : (progress>100? 100: progress),                            
                                        type    : "task",
                                        hideChildren: false,
                                        displayOrder: indGr+1,
                                        isDisabled  : !editableMode,                                    
                                        
                                        styles  : { 
                                            progressColor:      progColor,
                                            backgroundColor:    backgrColor, 
                                            

                                            // progressColor: progress<100 ? '#4FA34B':'#B5B5C3', 
                                            // backgroundColor: progress>0 ? "#4FA34B88": "#33116888", 
                                            
                                            progressSelectedColor:   progSelectedColor,
                                            backgroundSelectedColor: backgrSelectedColor,
                                            
                                        }
                                    }
                                ]
                            }
                        })
                    })

                })
            }
            // console.log("allTasks", allTasks.map(tsk => tsk.name))

            setTasks(allTasks)  
        }      
    }, [ allSchedules, currActiveSchedIds,
        showActiveSch, showExpiredSch, showComingSch, editableMode, 
        inView
    ])

    // ------------------------
    useEffect(()=>{
        if (inView){
            // console.log("allSchedules : ", allSchedules)
            // setInterval(function () {            
                // setToday(moment())
                // setCurrentDate(new Date())

                if (allSchedules){
                    dispatch(setCurrActiveSchedIds(extractCurrActiveSchedules(allSchedules, moment())))
                }

            // }, 10000);    //5mn
        
        }

    },[allSchedules, inView])
   


    // const scheduleData = [
    //     {
    //         TaskID: 1,
    //         TaskName: 'Group 1',
    //         StartDate: new Date('04/04/2023'),
    //         EndDate: new Date('04/24/2023'),
    //         subtasks: [
    //             { TaskID: 2, TaskName: 'Content 001', StartDate: new Date('04/06/2023'), EndDate: new Date('24/09/2023'), Progress: 50 },
    //             { TaskID: 3, TaskName: 'Content 002', StartDate: new Date('04/10/2023'), Duration: 4, Progress: 50 },
    //             { TaskID: 4, TaskName: 'Content 003', StartDate: new Date('04/12/2023'), Duration: 4, Progress: 50 },
    //         ]
    //     },
    //     {
    //         TaskID: 5,
    //         TaskName: 'Group 2',
    //         StartDate: new Date('04/02/2023'),
    //         EndDate: new Date('04/21/2023'),
    //         subtasks: [
    //             { TaskID: 6, TaskName: 'Develop floor plan for estimation', StartDate: new Date('04/03/2023'), Duration: 3, Progress: 50 },
    //             { TaskID: 7, TaskName: 'List materials', StartDate: new Date('04/04/2023'), Duration: 3, Progress: 50 },
    //             { TaskID: 8, TaskName: 'Estimation approval', StartDate: new Date('04/08/2023'), Duration: 3, Progress: 50 }
    //         ]
    //     },
    //     {
    //         TaskID: 9,
    //         TaskName: 'Group 3',
    //         StartDate: new Date('04/02/2023'),
    //         EndDate: new Date('04/21/2023'),
    //         subtasks: [
    //             { TaskID: 9, TaskName: 'Identify Site location', StartDate: new Date('04/04/2023'), Duration: 4, Progress: 50,
    //               Segments: [
    //                 { StartDate: new Date("04/02/2019"), Duration: 2 },
    //                 { StartDate: new Date("04/04/2019"), Duration: 2 }
    //               ] 
    //             },
    //             { TaskID: 10, TaskName: 'Perform Soil test', StartDate: new Date('04/04/2023'), Duration: 4, Progress: 50  },
    //             { TaskID: 11, TaskName: 'Soil test approval', StartDate: new Date('04/04/2023'), Duration: 4 , Progress: 50 },
    //         ]
    //     },
    // ]

    // const taskValues: TaskFieldsModel = {
    //     id: "TaskID",
    //     name: "TaskName",
    //     startDate: "StartDate",
    //     endDate: "EndDate",
    //     duration: "Duration",
    //     progress: "Progress",
    //     child: "subtasks",
    //     segments: 'Segments',
    //     // dependency: "Predeceesor"
    // }
    //-----------------------------------------



    return (<>
        {inView &&
            <div className={`card ${className}`}>
                <div className='py-8 d-flex justify-content-end text-end'>
                    <ViewSwitcher
                        onViewModeChange={viewMode => setView(viewMode)}
                        onViewListChange={setIsChecked}
                        isChecked={isChecked}
                    />
                </div>
                <div className='card-body pt-0 pb-8'>
                    {tasks && tasks.length > 0 && 
                        <Gantt
                            // ------------------------
                            // DisplayOption 
                            // ------------------------
                            viewMode={view}
                            viewDate={new Date()}
                            // viewDate={new Date(Date.now() - 86400000)}
                            // preStepsCount?: number;                        
                            // locale?: string;
                            // rtl?: boolean;

                            // ------------------------
                            // TaskGanttContentProps
                            // ------------------------
                            tasks={tasks}
                            // dates: Date[];
                            // ganttEvent: GanttEvent;
                            // selectedTask: BarTask | undefined;
                            rowHeight={30}
                            columnWidth={columnWidth}
                            timeStep={10}
                            fontSize= "0.85rem"
                            fontFamily={"Inter, Helvetica, 'sans-serif'"}
                            // rtl: boolean;
                            // svg?: React.RefObject<SVGSVGElement>;
                            // svgWidth={250}
                            // taskHeight={30}
                            // arrowColor= "#331168"
                            arrowColor= "#009ef7"                        
                            // arrowIndent= {0}                        
                            // setGanttEvent: (value: GanttEvent) => void;
                            // setFailedTask: (value: BarTask | null) => void;
                            // setSelectedTask: (taskId: string) => void;
                            
                            // ------------------------
                            // EventOption
                            // ------------------------
                            // timeStep?: number;
                            // onSelect?: (task: Task, isSelected: boolean) => void;
                            onSelect={handleSelect}
                            // onSelect={handleDblClick}
                            // onDoubleClick?: (task: Task) => void;
                            onDoubleClick={editableMode? handleDblClick: handleClick}
                            // onClick?: (task: Task) => void;
                            // onClick={handleClick}
                            onClick={editableMode? handleClick: handleDblClick}
                            // onDateChange?: (task: Task, children: Task[]) => void | boolean | Promise<void> | Promise<boolean>;
                            onDateChange={handleTaskChange}
                            // onProgressChange?: (task: Task, children: Task[]) => void | boolean | Promise<void> | Promise<boolean>;
                            // onProgressChange={handleProgressChange}
                            // onDelete?: (task: Task) => void | boolean | Promise<void> | Promise<boolean>;
                            onDelete={handleTaskDelete}
                            // onExpanderClick?: (task: Task) => void;
                            onExpanderClick={handleExpanderClick}


                            // ------------------------
                            // StylingOption
                            // ------------------------
                            // headerHeight?: number;
                            // columnWidth?: number;
                            listCellWidth={isChecked ? "155px" : ""}
                            // rowHeight?: number;
                            // ganttHeight={300}
                            ganttHeight={isHeightLimited? handleChartHeight(448): 0} 
                            barCornerRadius={10}
                            // handleWidth?: number;
                            // fontFamily?: string;
                            // fontSize?: string;
                            
                            // barFill?: number;
                            // barProgressColor?: string;
                            // barProgressSelectedColor?: string;
                            // barBackgroundColor?: string;
                            // barBackgroundSelectedColor?: string;
                            // projectProgressColor?: string;
                            // projectProgressSelectedColor?: string;
                            // projectBackgroundColor?: string;
                            // projectBackgroundSelectedColor?: string;
                            // milestoneBackgroundColor?: string;
                            // milestoneBackgroundSelectedColor?: string;
                            // arrowColor?: string;
                            // arrowIndent= {5}
                            // todayColor="#ffdc2826"
                            // todayColor="#009ef726"
                            todayColor="#16816855"
                            // TooltipContent?: React.FC<{
                            //     task: Task;
                            //     fontSize: string;
                            //     fontFamily: string;
                            // }>;
                            TooltipContent={(props) => (
                                <TooltipContent {...props} allScheds={allScheds} currActiveSchedIds={currActiveSchedIds}/>                            
                            )}                    
                            TaskListHeader={(props) => (
                                <TaskListHeader {...props}/>                            
                            )}                        
                            TaskListTable={(props) => (
                                <TaskListTable {...props} currActiveSchedIds={currActiveSchedIds}
                                    handleAddTask={handleAddTask} 
                                    handleDeleteTask={handleTaskDelete}
                                    handleUpdateTask={handleDblClick}
                                    handleClearAllTasks={handleDeleteAllTasksInSchedule}
                                    handleViewGroup={handleViewGroupSchedules}
                                    handleMoveUp={handleMoveScheduleUp}
                                    handleMoveDown={handleMoveScheduleDown}
                                />
                            )}
                        />   
                    }

                    {emergContent &&
                        <div 
                            className='position-absolute top-0 ms-n9 w-100 h-100 bg-danger text-center opacity-50 rounded'
                            style={{marginTop: "0px",}}
                        >
                            <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white'>
                                <div className=''>
                                    <i className='bi bi-exclamation-triangle-fill fs-5x me-3'></i>
                                    <div className='ms-n2' style={{marginTop: "-80px"}}>
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            <span className='spinner-border spinner-border-sm align-middle text-white w-100px h-100px'></span>
                                        </span>
                                    </div>
                                </div>
                                <div className='fs-5x opacity-75'>Emergency</div>                            
                                {/* <div className='fs-5x opacity-75'><Link to="/content/" className='text-capitalize text-light text-hover-warning'>"{emergContent.user_template_name}"</Link> is running</div>                             */}
                                <div className='fs-5x opacity-75'><span className='text-capitalize'>"{emergContent.user_template_name}"</span> is running</div>                            
                            </div>
                        </div>  
                    }       
                </div>
            </div>
        }
        
    </>)
}

export {GanttSchedules}

