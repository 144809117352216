/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useAddScheduleMutation, useDeleteScheduleMutation, useGetScheduleQuery, useUpdateScheduleMutation } from '../../../../redux/slices/schedule/apiScheduleSlice'
import { Group, OptionProps, layoutOptions, categoryOptions } from '../../../../redux/slices/group/groupModels'
import { 
    FormControl,
    Checkbox, 
    InputLabel, MenuItem, 
    Select,
    TextField, 
    FormHelperText,
    ListItemIcon
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider, useFieldArray } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'

import { useAddContentMutation, useGetAllContentsQuery, useGetAllPlaylistsQuery, useUpdateContentMutation } from '../../../../redux/slices/content/apiContentSlice'
import { useGetAllGroupsQuery } from '../../../../redux/slices/group/apiGroupSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import moment from 'moment'
import { DrawerComponent } from '../../../../../_metronic/assets/ts/components'
import { Content, ContentInput, ContentPlaylistInput, EleInput, PlaylistInput, RECENTPERIOD, Source, VERYRECENTPERIOD, emptyContent, getContentOrientation, getContentThumbnail, secondsToHHmmss, templatePlaylistID } from '../../../../redux/slices/content/contentModels'
import { emptyAssignedContent } from '../../../../redux/slices/schedule/scheduleModels'
import { useGetAllPlaylistTemplatesQuery, useGetTemplateQuery } from '../../../../redux/slices/template/apiTemplateSlice'
import { setContent } from '../../../../redux/slices/content/contentSlice'






type Props = {  
    className?: string
}


const schema = yup.object().shape({
    name: yup.string().min(1, 'Minimum 1 symbols').required('Playlist name is required'),
    orientation: yup.string().min(1, 'Minimum 1 symbols').required('Orientation is required'),
    contents: yup.mixed().required('Content is required'),
})

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerEditPlaylist: FC<Props> = ({className}) => {
    const dispatch = useAppDispatch()  
    //-----------------------------------------
    const currPlaylist = useAppSelector(state => state.content.currPlaylist)
    // console.log("currPlaylist in Edit PL: ", currPlaylist)

    const { data: listContents, isLoading: isLoadingContents, isFetching: isFetchingContents} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true }) 
    // console.log("listContents", listContents)

    const [contentIDs, setContentIDs] = useState<string []>([""]);
    const [orientation, setOrientation] = useState(getContentOrientation(currPlaylist));
    
    const templateLandscapePlaylist = useGetTemplateQuery(templatePlaylistID[0])
    // console.log("templateLandscapePlaylist: ", templateLandscapePlaylist)
    const templatePortraitPlaylist = useGetTemplateQuery(templatePlaylistID[1])
    // console.log("templatePortraitPlaylist: ", templatePortraitPlaylist)
    // const allTemplatePlaylists = useGetAllPlaylistTemplatesQuery()
    // console.log("allTemplatePlaylists: ", templatePortraitPlaylist)
  
    // const [addContent, resultAddContent] = useAddContentMutation();  
    const [updateContent] = useUpdateContentMutation();  
    // const navigate = useNavigate();   
    
    const {data:allPlaylist} = useGetAllPlaylistsQuery();
    // console.log('Playlist', [allPlaylist]);
    // var currPlayList:Content = (allPlaylist && allPlaylist.length > 0) ? allPlaylist[allPlaylist.length -1] : emptyContent
    // console.log("last playlist", [currPlayList]);
    //---------------------------------------------------- 
    const { 
        register, 
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        setError,
        formState:{
            errors,
            isDirty,            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful,            
        }
    } = useForm<PlaylistInput>({
        resolver: yupResolver(schema),
        defaultValues:{                  
            name: currPlaylist.user_template_name,
            orientation: getContentOrientation(currPlaylist),
            contents: [{...emptyAssignedContent, content_id: "0"}],
        }
    }) 

    const { fields, append, prepend, update, remove } = useFieldArray({
        name: "contents",
        control,
        rules: {
          required: "Please append at least 1 content"
        }
    });

    // console.log("fields", fields)
    // console.log("contentIDs", contentIDs)

    //---------------------------------------------------- 

    const updateFieldChanged = (index: number, value: string) => {
        let newArr = [...contentIDs];        
        newArr[index] = value;      
        setContentIDs(newArr);
        update(index, {...emptyAssignedContent, content_id: value})
        // console.log("index, value: " ,index, value)
    } 

    const addField = () =>{
        setContentIDs([...contentIDs, ""])
        append(emptyAssignedContent)
    }
    
    const resetField = () => {
        remove()
        setContentIDs([""])
        append(emptyAssignedContent)
    } 

    // -------------------------------------
    const formSubmitHandler: SubmitHandler<any> = async (playlist: PlaylistInput) => {        

        let playlistDuration = 0;
        const templatePlaylist = (playlist.orientation === "landscape"? templateLandscapePlaylist: templatePortraitPlaylist)
        const newEleList : EleInput[] = []
        const playListSource : Source[] = []
        
        playlist?.contents.map((content, i) => {
            const foundContent = listContents?.find((cont, ind) => cont.user_template_id === content.content_id)
            if (foundContent){ 
                playlistDuration += foundContent.duration

                playListSource.push({
                    duration_override   : foundContent.duration,
                    element_id: "",
                    source_list_id: "",
                    order: 1,
                    source: null,
                    source_id: "",
                    
                    type: "campaign",
                    
                    param_source: [{
                        source_id: "",
                        type: "campaign",
                        campaign_id     : foundContent.user_template_id,
                        campaign_name   : foundContent.user_template_name,
                        duration        : foundContent.duration,
                        loop: 1
                    }]
                })
            }
        })

        // console.log("playlistDuration: ", playlistDuration)
        // console.log("playListSource: ", playListSource)
        
        try{            
            
            newEleList.push({
                element_id  : currPlaylist? (currPlaylist.elements?currPlaylist.elements[0].element_id:""):"",
                position_id : templatePlaylist?.data?.positions? templatePlaylist.data?.positions[0].position_id : "",
                alias       : templatePlaylist?.data?.positions? templatePlaylist.data?.positions[0].alias : "12345",                          
                element_setting : '{"transition_timer":null}',
                type            : templatePlaylist?.data?.positions? templatePlaylist.data?.positions[0].element_type : "campaign",
                source_ids      : JSON.stringify(playListSource),
            })
            const newPlaylist :ContentInput = {
                contentId: currPlaylist?(currPlaylist.user_template_id?currPlaylist.user_template_id:""):"",
                user_template: {
                    template_id : templatePlaylist? (templatePlaylist.data?templatePlaylist.data.template_id:""):"",
                    user_template_name: playlist.name, 
                    type: "playlist",   
                    duration: playlistDuration,
                    thumbs: []
                },                    
                elements: newEleList
            }
            // console.log("currPlaylist in submit : ", currPlaylist)
            // console.log("newPlaylist in submit  : ", newPlaylist)

            const res: any = await updateContent(newPlaylist)

            
            Swal.fire({
                // position: 'bottom-end',
                
                icon: 'success',
                title:  'Your playlist has been successfully updated.<br/><br/>', 
                showConfirmButton: false,                
                timer: 1000,
            })
        }catch(error){
            Swal.fire({
                icon: 'error',
                title:  'Unable to edit your playlist.<br/>', 
                showConfirmButton: false,                
                timer: 1000                
            })
        }

        remove()    //remove from fields
        setContentIDs([""]) //remove from state ContentIDs

        reset()
    }
    
    // -------------------------------------
    // useEffect(() => {
    //     if (resultAddContent.status === "fulfilled"){
    //     dispatch(setContent(resultAddContent.data));
    //     }
    // }, [resultAddContent])

    // -------------------------------------
    useEffect(() => {
        // console.log("currPlaylist in useEffect: ", currPlaylist)
        
        var contIdList: string[] = []
        var contList: ContentPlaylistInput[] = []
        currPlaylist.elements[0].sources.map((src: any, index: number) => {
            contList.push({
                content_id      : src.param_source[0].campaign_id??"",
                play_duration   : src.param_source[0].duration??0,
                mode: '',
                num_loops: src.param_source[0].loop??0             
            })
            contIdList.push(src.param_source[0].campaign_id??"")
        })
        // console.log("contList in useEffect", contList)

        reset({
            name: currPlaylist.user_template_name,
            orientation: getContentOrientation(currPlaylist),
            contents: contList,
        })
        setContentIDs(contIdList.length <= 0 ? [""]: contIdList)
        setOrientation(getContentOrientation(currPlaylist))
    }, [currPlaylist])


    useEffect(() => {
        reset({
            name : currPlaylist.user_template_name,
            orientation: getContentOrientation(currPlaylist),            
            contents: [{...emptyAssignedContent, content_id: "0"}]
        })
        remove()
    }, [reset])    

    //----------------------------------------------------
    return (
        <>  
            <div
                id="kt_drawer_edit_playlist"
                data-kt-drawer-toggle={`#kt_drawer_edit_playlist_button`}
                data-kt-drawer-close= {`#kt_drawer_edit_playlist_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className?className:""}`}
                data-kt-drawer-activate="true"
                // data-kt-drawer-width="500px"                
                // data-bs-backdrop="static" 
                aria-labelledby="staticBackdropLabel"


                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-gradient-primary">
                    <h5 className="offcanvas-title text-white" id="staticBackdropLabel">
                        <KTSVG
                            path={`/media/icons/duotune/text/txt001.svg`}
                            className={`svg-icon-2x svg-icon-white me-2 mt-n2 d-inline`}
                        />
                        Edit Playlist
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                    // onClick={handleActivityClose}
                    ></button>                        
                </div>
                <div className="offcanvas-body text-start">
                    <form id="frm-update-playlist" className='form'  
                        onSubmit={handleSubmit(formSubmitHandler)}
                    >              
                        <div className="mb-4">
                            <TextField 
                                {...register("name")} 
                                label="Playlist title" 
                                fullWidth 
                                margin='dense'
                                variant='outlined' 
                                error={!! errors.name}
                                helperText={`${errors.name?.message ?? ''}`}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>                                                
                        
                        <div className="mb-4">
                            <div className="d-flex mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="select-orientation-label">Orientation</InputLabel>
                                    <Select 
                                        {...register("orientation")} 
                                        labelId="select-orientation-label"                                        
                                        label="Orientation"
                                        value={orientation}
                                        onChange={(e) => {
                                            setOrientation(e.target.value);
                                            resetField();                                            
                                        }}
                                        error={!! errors.contents}
                                        MenuProps={{ disablePortal: true }}
                                    >        
                                        <MenuItem key={0} value={0} disabled>Select Orientation</MenuItem>
                                        <MenuItem key={1} value={"landscape"} className='w-100'>Landscape</MenuItem>
                                        <MenuItem key={1} value={"portrait"} className='w-100'>Portrait</MenuItem>                                                                               
                                    </Select>
                                    <FormHelperText>{`${errors.contents?.message ?? ''}`}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>
                        
                        {/* Select multiple contents */}
                        {listContents &&
                        <div className="mb-4">
                            {fields.map((field, index) => {                                
                                // ----------------------------------------
                                return (
                                    <div className="d-flex mb-4" key={field.id}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-content-label">Campaign</InputLabel>
                                            <Select 
                                                {...register(`contents.${index}.content_id`)} 
                                                labelId="select-content-label"                                        
                                                label="Content"
                                                value={`${contentIDs[index]}`}
                                                // defaultValue=""
                                                onChange={(e) => {
                                                    updateFieldChanged(index, e.target.value)
                                                }}
                                                error={!! errors.contents}
                                                MenuProps={{ disablePortal: true }}
                                            >        
                                                <MenuItem key={0} value={0} disabled>Select campaign</MenuItem>                                
                                                {listContents
                                                ?.filter((content) => {
                                                    return (((content.width >= content.height) && orientation === "landscape") || ((content.width < content.height) && orientation === "portrait"))
                                                            &&
                                                            (content.emergency === 0) 
                                                })
                                                ?.slice().sort((a,b)=> a.user_template_name.toLowerCase() > b.user_template_name.toLowerCase() ? 1:-1)
                                                // ?.sort((a, b) => {
                                                //     if (a.user_template_name > b.user_template_name) {return -1 }
                                                //     if (a.user_template_name < b.user_template_name) {return 1 }
                                                //     return 0;
                                                // })
                                                .map((content) => {
                                                    const durationFromNow = moment().diff(moment.utc(content.modified_time).local())/(1000*60*60*24)
                                                    // -------------------------------------------------
                                                    return (
                                                        <MenuItem key={content.user_template_id} value={content.user_template_id}>
                                                            <div className='w-100 d-flex justify-content-between'>
                                                                <div className={`d-flex justify-content-start w-175px text-capitalize `}>
                                                                    <div className="symbol symbol-20px symbol-2by3 me-2">
                                                                        <div className="symbol-label rounded-0 bg-transparent"
                                                                            style={{ 
                                                                                backgroundImage: `url('${getContentThumbnail(content)}')`,
                                                                            }}
                                                                        >       
                                                                            <a  href="#" 
                                                                                className="btn btn-link hover-scale"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#modal-preview-content"
                                                                                onClick={() => { dispatch(setContent(content)); }}
                                                                            >
                                                                                <i className="bi bi-play-circle-fill fs-6 text-white text-hover-gradient-primary opacity-75"></i>                                    
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-wrap text-start'>{content.user_template_name}</div>
                                                                </div>       
                                                                <div className='d-flex'>
                                                                    <div className='text-end ms-1'>
                                                                        <span className={`badge 
                                                                            ${durationFromNow <= VERYRECENTPERIOD ? "badge-warning": (durationFromNow <= RECENTPERIOD ? "badge-light-warning": "badge-light")} 
                                                                            ms-2`}
                                                                        >
                                                                            {moment.utc(content.modified_time).local().fromNow()}
                                                                        </span>
                                                                    </div>
                                                                    <div className='text-end ms-1' style={{width: `${content.duration >= 1000*60*60?"75px":"65px"}`}}>
                                                                        <span className="badge badge-light">
                                                                            <i className='bi bi-clock-history fs-8 me-1'></i>
                                                                            {secondsToHHmmss(content.duration)}s
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>                                                        
                                                        </MenuItem>
                                                    )
                                                })}                                                 
                                                                                    
                                            </Select>
                                            <FormHelperText>{`${errors.contents?.message ?? ''}`}</FormHelperText>
                                        </FormControl>
                                        
                                        {fields.length > 1 &&
                                        <button type="button"                                             
                                            className='btn btn-icon btn-bg-light btn-active-color-primary mt-1 ms-3'
                                            onClick={() => {                                                
                                                remove(index)

                                                let newArr = [...contentIDs]
                                                newArr.splice(index, 1)
                                                setContentIDs(newArr)
                                            }}
                                        >                                            
                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon svg-icon-2x' />
                                        </button>
                                        }
                                    </div>
                                );
                            })}
                            <div className="d-flex flex-end">
                                <button type="button"                                             
                                    className='btn btn-sm btn-bg-light btn-active-color-primary mt-2 tooltip-bottom'
                                    data-tooltip-content='Add another campaign'
                                    onClick={() => {
                                        setContentIDs([...contentIDs, ""])
                                        append(emptyAssignedContent)
                                    }}
                                >                                            
                                    <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon svg-icon-5' />
                                    Add campaign
                                </button>
                            </div>
                        </div>  
                        } 

                        <div className="pt-8 text-end">                                
                            {/* <button type="button" className="btn btn-light me-3" 
                                onClick={()=>{ reset() }}
                            >
                                <i className="fa fa-refresh me-1 mt-n1"></i>
                                Reset
                            </button> */}
                            <button type="submit" className="btn btn-primary w-100" 
                                // data-bs-toggle="offcanvas"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                // disabled={isSubmitting || !isValid}
                                disabled={(isSubmitting || !(isValid && fields.length>=1 && contentIDs.length>=1 && contentIDs[0].length>0))}
                            >
                                {/* <i className="bi bi-lightning-fill fs-3 me-1 mt-n1"></i>
                                Superbolt Now */}
                                <i className="fa fa-save fs-3 me-1 mt-n1"></i>
                                Save
                            </button>
                        </div>
                        <button type="button" 
                            className="btn btn-icon btn-light position-absolute bottom-0 end-0 me-7 mb-8" 
                            data-bs-dismiss="offcanvas"
                            data-kt-drawer-dismiss="true"
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr001.svg'
                                className='svg-icon svg-icon-1x'
                            />
                        </button>                       
                    </form>
                </div>
            </div>
        </>
    )
}

export {DrawerEditPlaylist}