import { 
  combineReducers,
  configureStore, 
  // getDefaultMiddleware 
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import cartReducer from './slices/cart/cartSlice'
import contentReducer from './slices/content/contentSlice'
import deviceReducer from './slices/device/deviceSlice'
import editorReducer from './slices/editor/editorSlice'
import groupReducer from './slices/group/groupSlice'
import licenseReducer from './slices/license/licenseSlice'
import scheduleReducer from './slices/schedule/scheduleSlice'
import shoppingReducer from './slices/shopping/shoppingSlice'
import templateReducer from './slices/template/templateSlice'
import staffReducer from './slices/staff/staffSlice'
import resellerRucer from './slices/reseller/resellerSlice'
import ticketReducer from './slices/ticket/ticketSlice'
import pexelsReducer from './slices/application/pexels/pexelsSlice'

import { cartsApi } from './slices/cart/apiCartSlice'
import { contentsApi } from './slices/content/apiContentSlice'
import { devicesApi } from './slices/device/apiDeviceSlice'
import { emailsApi } from './slices/email/apiEmailSlice'
import { groupsApi } from './slices/group/apiGroupSlice'
import { mediasApi } from './slices/media/apiMediaSlice'
import { licensesApi } from './slices/license/apiLicenseSlice'
import { invoiceApi } from './slices/invoice/apiInvoiceSlice'
import { schedulesApi } from './slices/schedule/apiScheduleSlice'
import { templatesApi } from './slices/template/apiTemplateSlice'
import { ticketsApi } from './slices/ticket/apiTicketSlice'
import { accountApi } from './slices/account/apiAccountSlice'
import { staffApi } from './slices/staff/apiStaffSlice'
import { pexelsApi } from './slices/application/pexels/apiPexelsSlice'

import storage from 'redux-persist/lib/storage';
import { 
  persistReducer, 
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { resellerApi } from './slices/reseller/apiResellerSlice'
import { staffApi3 } from './slices/staff/api3StaffSlice'


const rootPersistConfig = {
  key: 'root',
  storage,
  // storage: storageSession,
}
// const persistedReducer = persistReducer(rootPersistConfig, contentReducer)

// const userPersistConfig = {
//   key: 'user',
//   storage: "storageSession",
// }




const rootReducer = combineReducers({
  // cont: persistReducer(rootPersistConfig, contentReducer),
  // shopping: persistReducer(rootPersistConfig, shoppingReducer), 
  _device: deviceReducer, 
  _content: contentReducer,
  _shopping: shoppingReducer,  
  _schedule: scheduleReducer,  
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)


//// const userPersistConfig = {
////   key: 'user',
////   storage: storageSession,
//// }



// const rootReducer = combineReducers({
//   // user: persistReducer(userPersistConfig, userReducer),
//   // notes: notesReducer
//   content: contentReducer,
//   cart: shoppingReducer
// })
// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: {
    
    cart: cartReducer,

    content: contentReducer,    
    device: deviceReducer,
    group: groupReducer,
    staff: staffReducer,
    reseller: resellerRucer,
    ticket: ticketReducer,
    editor: editorReducer,
    // media: mediaReducer,    
    // playlist: playlistReducer,

    license: licenseReducer,
    schedule: scheduleReducer,    
    shopping: shoppingReducer,
    template: templateReducer,    
    
    pexels: pexelsReducer,

    persistor : persistedReducer,
    

    //reducers   
    [cartsApi.reducerPath]      : cartsApi.reducer,
    [contentsApi.reducerPath]   : contentsApi.reducer,
    [devicesApi.reducerPath]    : devicesApi.reducer,
    [emailsApi.reducerPath]     : emailsApi.reducer,
    [groupsApi.reducerPath]     : groupsApi.reducer,
    [mediasApi.reducerPath]     : mediasApi.reducer,        
    [licensesApi.reducerPath]   : licensesApi.reducer,
    [invoiceApi.reducerPath]    : invoiceApi.reducer,
    [schedulesApi.reducerPath]  : schedulesApi.reducer,
    // [shoppingsApi.reducerPath] : shoppingsApi.reducer,
    [templatesApi.reducerPath]  : templatesApi.reducer,
    [ticketsApi.reducerPath]    : ticketsApi.reducer,
    [accountApi.reducerPath]    : accountApi.reducer,
    [staffApi.reducerPath]      : staffApi.reducer,
    [staffApi3.reducerPath]     : staffApi3.reducer,
    [resellerApi.reducerPath]   : resellerApi.reducer,
    [pexelsApi.reducerPath]     : pexelsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({      
      // Customize the built-in serializability dev check
      immutableCheck: { warnAfter: 128 },
      serializableCheck: {
        warnAfter: 128,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    })    
    .concat(cartsApi.middleware)
    .concat(contentsApi.middleware)     
    .concat(devicesApi.middleware)    
    .concat(emailsApi.middleware)
    .concat(groupsApi.middleware)
    .concat(mediasApi.middleware)
    .concat(licensesApi.middleware)
    .concat(invoiceApi.middleware)
    .concat(schedulesApi.middleware)
    // .concat(shoppingsApi.middleware)
    .concat(templatesApi.middleware)
    .concat(ticketsApi.middleware)
    .concat(accountApi.middleware)
    .concat(staffApi.middleware)
    .concat(resellerApi.middleware)
    .concat(staffApi3.middleware)
    .concat(pexelsApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


// enable listener behavior for the store
setupListeners(store.dispatch)
