import { FC } from 'react'

type Props = {
    mfaSecret?: string
    mfaQRCode?: string
}

const TFAQRCode: FC<Props> = ({ mfaSecret = '', mfaQRCode = '' }) => {
    return (
        <div className='d-block text-start'>
            <div className='text-grey-200 fs-bolder mb-3 fs-5'>
                Instructions for setup
            </div>
            <div className='text-grey-200 mb-1'>
                1. Download an authentication app
            </div>
            <div className='text-muted mb-3'>
                We recommend downloading Google Authenticator or Microsoft Authenticator if you don't have one installed.
            </div>
            <div className='text-grey-200 mb-1'>
                2. Scan this QR Code or enter key manually
            </div>
            <div className='fv-row'>
                <div className="text-center">
                    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(mfaQRCode)}`} /><br />
                    Key: <span>{mfaSecret}</span>
                </div>
            </div>
        </div>

    )
}

export { TFAQRCode }