import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartItem, InitialState } from './shoppingModel';

const initialState: InitialState = {
    autoSendInvoice: false,    
    statusDoneCheckout: false,
    statusCartShow: false,
    cart: [],
    po: ""
}
const shoppingSlice = createSlice({
name: 'shopping',
initialState,
reducers:{
    setAutoSendInvoice: (state, action) => {
        state.autoSendInvoice = action.payload
    },
    setStatusDoneCheckout: (state, action) => {
        state.statusDoneCheckout = action.payload
    },
    setStatusCartShow: (state, action) => {
        state.statusCartShow = action.payload
    },
    setPO: (state, action) => {
        state.po = action.payload
    },
    emptyCart: (state) => {
        state.cart = []
    },
    // addItem: (state, action: PayloadAction<{cartItem: CartItem, qty: number}>) => {
    addCartItem: (state, action: PayloadAction<CartItem>) => {
        let isFound: boolean = false
        // -----------------------------
        state.cart.map((item, index) => {
            if (item.licensePackage.id === action.payload.licensePackage.id){
                if (!item.deviceId && !action.payload.deviceId){
                    item.qty += action.payload.qty
                    isFound = true
                }else if (item.deviceId === action.payload.deviceId){
                    // console.log("license this deviceId has already exist")
                    isFound = true
                }                
            }else{
                //in case of switching to different license for existing device                
                if (item.deviceId && (item.deviceId === action.payload.deviceId)){                    
                    state.cart.splice(index, 1) //remove the old one
                }
            }
        })
        // ------------------
        if (! isFound){
            state.cart.push(action.payload)
        }
    },

    substractCartItem: (state, action: PayloadAction<CartItem>) => {
        let foundInd: number = -1
        let isEmpty: boolean = false
        // -----------------------------
        state.cart.map((item, index) => {
            if ((!item.deviceId && !action.payload.deviceId) || 
                (item.deviceId && action.payload.deviceId && (item.deviceId === action.payload.deviceId))){
                    
                if (item.licensePackage.id === action.payload.licensePackage.id){
                    item.qty -= action.payload.qty
                    foundInd = index 
                    if (item.qty === 0){
                        isEmpty = true
                    }
                }
            }
        })
        if (isEmpty && (foundInd >= 0)){
            state.cart.splice(foundInd, 1)
        }
    },
    removeCartItem: (state, action: PayloadAction<CartItem>) => {
        let foundInd: number = -1
        // -----------------------------
        state.cart.find((item, ind) => {
            if (item.licensePackage.id === action.payload.licensePackage.id){                
                foundInd = ind
            }
        })
        if (foundInd >= 0){
            state.cart.splice(foundInd, 1)
        }        
    },

    
    // restocked: (state, action: PayloadAction<number>) => {
    //     state.numOfCakes += action.payload
    // }
}
})

export default shoppingSlice.reducer
export const {    
    setAutoSendInvoice,
    setStatusDoneCheckout,
    setStatusCartShow,
    setPO,
    
    emptyCart,
    addCartItem,
    substractCartItem,
    removeCartItem
}  = shoppingSlice.actions