/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useAddScheduleMutation, useDeleteScheduleMutation, useGetScheduleQuery, useUpdateScheduleMutation } from '../../../../redux/slices/schedule/apiScheduleSlice'
import { Group, OptionProps, layoutOptions, categoryOptions } from '../../../../redux/slices/group/groupModels'
import { 
    FormControl,
    InputLabel, MenuItem, 
    Select,
    FormHelperText,
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider, useFieldArray } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { AssignedContent, Sched, ScheduleInput, TimeRangeInput, emptyAssignedContent, extractRepeatModeFromSchedule, getEndDateTime, repeatOptions } from '../../../../redux/slices/schedule/scheduleModels'

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MultiInputTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputTimeRangeField';
import { useGetAllContentsQuery, useGetAllPlaylistsQuery } from '../../../../redux/slices/content/apiContentSlice'
import { useGetAllGroupsQuery } from '../../../../redux/slices/group/apiGroupSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import moment from 'moment'
import { DrawerComponent } from '../../../../../_metronic/assets/ts/components'
import { RECENTPERIOD, VERYRECENTPERIOD, getContentThumbnail, secondsToHHmmss } from '../../../../redux/slices/content/contentModels'
import { setContent } from '../../../../redux/slices/content/contentSlice'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'



type Props = {  
    className?: string
}

const schema = yup.object().shape({  
    id          : yup.string(),  
    contents    : yup.mixed().required('Campaign is required'),
    type        : yup.string(),
    deviceGroupIds: yup.string(),    
    groupInputs : yup.mixed().required('Groups is required'),

    start_time  : yup.string().min(3, 'Minimum 3 symbols').required('Start time is required'),
    end_time    : yup.string().min(3, 'Minimum 3 symbols').required('End time is required'), 
    repeat      : yup.string(),    
    cron        : yup.mixed()
})

const label = { 
                inputProps: { 'aria-label': 'Checkbox' },
                className:'w-20px h-20px bg-hover-primary me-1' 
            };

// ---------OPTION 1: 
// -------------------------------------------
const DEFAULT_MAX_ENDTIME_REACH = false
const DEFAULT_DURATION_TIMERANGE = 2    //by default the range is 4 hours
const DEFAULT_TR_DAYJS_LIST : TimeRangeInput[] = [
    [dayjs("08:00:00", "HH:mm:ss"), dayjs("16:00:00", "HH:mm:ss")]
]
const DEFAULT_STARTTIMERANGE_LIST = [
    DEFAULT_TR_DAYJS_LIST[0][0]
]
const DEFAULT_ENDTIMERANGE_LIST = [
    DEFAULT_TR_DAYJS_LIST[0][1]
]
const DEFAULT_TR_ERRs = [
    ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""],
    ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""],
    ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""], ["",""]
]

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerCRUDSchedule: FC<Props> = ({className}) => {
    const dispatch = useAppDispatch()
    //-----------------------------------------

    const [addSchedule] = useAddScheduleMutation();
    const [updateSchedule] = useUpdateScheduleMutation();
    const [deleteSchedule] = useDeleteScheduleMutation();
    //---------------------------------------------------- 
    const { data: listContents, isLoading: isLoadingContents, isFetching: isFetchingContents} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true }) 
    const { data: listPlaylists } = useGetAllPlaylistsQuery()
    const { data: allDeviceGroups} = useGetAllGroupsQuery(5, { refetchOnMountOrArgChange: true }) 

    const [contentIDs, setContentIDs] = useState<string []>([""]);
    const [grpIDs, setGrpIDs] = useState<string []>([""]);
    const [timeRangeArrays, setTimeRangeArrays] = useState<TimeRangeInput[]>([]);
    const [currentErrorTRArrays, setCurrentErrorTRArrays] = useState(DEFAULT_TR_ERRs);
    const [trErrMsg, setTrErrMsg] = useState("")
    const [timeRangeDur, setTimeRangeDur] = useState(DEFAULT_DURATION_TIMERANGE); // duration of each timerange
    const [endTimeRangeLimitReach, setEndTimeRangeLimitReach] = useState<boolean>(DEFAULT_MAX_ENDTIME_REACH); //standard or recur
    const [isAllDay, setIsAllDay] = useState(true); // duration of each timerange
    const [isNoEndDate, setIsNoEndDate] = useState(true); // duration of each timerange

    const [groupOrientation, setGroupOrientation] = useState('landscape');
    
    const [schedMode, setSchedMode] = useState('standard'); //standard or recur
    const [repeatMode, setRepeatMode] = useState('daily');
    const [startDateTime, setStartDateTime] = useState<Dayjs | null>(dayjs());
    const [endDateTime, setEndDateTime]     = useState<Dayjs | null>(dayjs().add(1, 'day'));

    const [startTimeRanges, setStartTimeRanges] = useState<Array<Dayjs | null>>(DEFAULT_STARTTIMERANGE_LIST);
    const [endTimeRanges, setEndTimeRanges]     = useState<Array<Dayjs | null>>(DEFAULT_ENDTIMERANGE_LIST);
    const [currTR, setCurrTR] = useState<TimeRangeInput>([dayjs(), dayjs()])   
    
    // console.log("emptyAssignedContent", emptyAssignedContent)
    // console.log("timeRangeDur", timeRangeDur)
    // console.log("isAllDay", isAllDay)
    // console.log("isNoEndDate", isNoEndDate)
    // console.log("startDateTime", startDateTime)
    // console.log("endDateTime", endDateTime)
    // console.log("timeRangeArrays", timeRangeArrays)
    // console.log("startTimeRanges", startTimeRanges)
    // console.log("endTimeRanges", endTimeRanges)
    // console.log("currTR:", currTR)
    // console.log("DEFAULT_STARTTIMERANGE_LIST:", DEFAULT_STARTTIMERANGE_LIST)
    // console.log("DEFAULT_ENDTIMERANGE_LIST:", DEFAULT_ENDTIMERANGE_LIST)


    const currSchId = useAppSelector(state => state.persistor._schedule.currSchedId)  
    const allSchs   = useAppSelector(state => state.persistor._schedule.allScheds) 
    const crudScheduleStatus = useAppSelector(state => state.persistor._schedule.crudSchedStatus)
    // console.log("persistor currSchId:", currSchId)
    // console.log("allSchs", allSchs)
    // console.log("content", currSchId)
    // console.log("currentErrorTRArrays", currentErrorTRArrays)
    // console.log("trErrMsg", trErrMsg)
    // console.log("",currentErrorTRArrays.map(e => e.join('')).join(''))

    //---------------------------------------------------- 
    const { 
        register, 
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        getValues,
        setError,
        formState:{
            errors,
            isDirty,            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful,            
        }
    } = useForm<ScheduleInput>({
        resolver: yupResolver(schema),
        defaultValues:{           
            id: "", 
            start_time  : (startDateTime? startDateTime: moment()).format('YYYY-MM-DD HH:mm:ss'),
            end_time    :   (endDateTime?   endDateTime: moment()).format('YYYY-MM-DD HH:mm:ss'),
            repeat: "daily",    
            deviceGroupIds: "",   
            groupInputs: [],      
            contents: [emptyAssignedContent],            

            type: "",
            cron:   [{
                timeRanges     : "*",   //"9:30-10:30,18:15-19:05",   "*"    
                dateRange      : "*",   //1-31, */2 
                monthRange     : "*",   //1-12, */2
                dayOfWeek      : "*",   //0-7,  */2
                timeRangeInputs: [],    //array of TimeRangeInput = [Dayjs, Dayjs]
            }]
        }
    }) 
    // console.log(watch())
    // console.log(watch(["cron"])[0])
    // console.log("watch([cron.0.timeRangeInputs])[0]: ", watch(["cron.0.timeRangeInputs"])[0])

    // BEGIN of fieldArray Group----------
    // -----------------------------------
    const { fields: fieldsGrp, append: appendGrp, prepend: prependGrp, remove: removeGrp, swap: swapGrp, move: moveGrp, insert: insertGrp, update: updateGrp } = useFieldArray({
        control,
        name: "groupInputs",
        rules: {
          required: "Please append at least 1 group"
        }
    });

    // console.log("fieldsGrp", fieldsGrp)
    // console.log("grpIDs", grpIDs)

    const updateFieldGrpChanged = (index: number, value: string) => {
        let newArr = [...grpIDs];        
        newArr[index] = value;      
        setGrpIDs(newArr);
        updateGrp(index, { grpId: value})
    } 

    const addFieldGrp = (defaultData = "") =>{
        setGrpIDs([...grpIDs, defaultData])
        appendGrp({grpId: defaultData})
    }
    
    const resetFieldGrp = (defaultData: string = "", grpOrient: string= "landscape") => {
        if (defaultData === ""){
            const filterGrps = allDeviceGroups?.filter(grp => {
                return (grp.displayType === grpOrient)
            })
            defaultData = filterGrps? (filterGrps[0].id? filterGrps[0].id:"") :""
        }
        // console.log("filterGrps", filterGrps)

        setValue('groupInputs', [{grpId:defaultData}]);
        setGrpIDs([defaultData])
    } 

    // --------------------
    const handleAddFieldGrp = (defaultData = "") =>{
        setGrpIDs([...grpIDs, defaultData])
        appendGrp({grpId: defaultData})
    }

    const handleRemoveFieldGrp = (index: number) => {
        removeGrp(index)

        let newArr = [...grpIDs]
        newArr.splice(index, 1)
        setGrpIDs(newArr)
    }
    // -----------------------------------
    // END of fieldArray------------------
    

    // BEGIN of fieldArray Content--------
    // -----------------------------------
    const { fields: fieldsContent, append: appendContent, prepend: prependContent, update: updateContent, remove: removeContent } = useFieldArray({
        name: "contents",
        control,
        rules: {
          required: "Please append at least 1 content"
        }
    });
    //---------------------------------------------------- 
    // console.log("fields content: ", fieldsContent)
    // console.log("contentIDs", contentIDs)

    const updateFieldContentChanged = (index: number, value: string) => {
        let newArr = [...contentIDs];        
        newArr[index] = value;      
        setContentIDs(newArr);
        updateContent(index, {...emptyAssignedContent, content_id: value})
    }    

    const resetFieldContent = (defaultContent: AssignedContent = emptyAssignedContent, grpOrient: string= "landscape") => {
        
        if (defaultContent.content_id === ""){
            const filterContents = listContents?.filter((content) => {
                return ((content.width >= content.height) && grpOrient === "landscape") || ((content.width < content.height) && grpOrient === "portrait") 
            })            

            defaultContent =    {   ...emptyAssignedContent,
                                    content_id : filterContents && filterContents.length>0? (filterContents[0].user_template_id? filterContents[0].user_template_id:""): "",
                                    play_duration: filterContents&& filterContents.length>0? filterContents[0].duration: 0,
                                    num_loops: 0,
                                    mode:''
                                }      
        }

        setValue('contents', [defaultContent]);     // removeContent()  //Do not use stack actions here because it unmount and remount
        setContentIDs([defaultContent.content_id])
    } 
    // -----------------------------------
    // END of fieldArray Content----------


    


    // BEGIN of fieldArray Timeranges------
    // ------------------------------------
    const { fields: fieldsTimeRange, append: appendTimeRange, prepend: prependTimeRange, remove: removeTimeRange, swap: swapTimeRange, move: moveTimeRange, insert: insertTimeRange, update: updateTimeRange } = useFieldArray({
        control,
        name: "cron.0.timeRangeInputs",
        rules: {
          required: "Please append at least 1 timerange"
        }
    });

    // console.log("startTimeRanges", startTimeRanges)
    // console.log("endTimeRanges", endTimeRanges)
    // console.log("timeRangeArrays", timeRangeArrays)
    // console.log("fieldsTimeRange", fieldsTimeRange)

    // // console.log("watch: ",watch())
    // console.log("watch([cron])[0]: ", watch(["cron"])[0])
    // console.log("watch([cron.0.timeRangeInputs])[0]: ", watch(["cron.0.timeRangeInputs"])[0])
    // console.log("watch([cron.0.timeRanges]): ", watch(["cron.0.timeRanges"]))



    const isMaxEndTimeReached = (newEndTime: Dayjs | null) => {
        if (moment.duration(moment(newEndTime?.toDate()).diff(moment("23:59:59","HH:mm:ss"))).asSeconds() >= 0){
            setEndTimeRangeLimitReach(true)
        }else{
            setEndTimeRangeLimitReach(false)
        }
    }


    const handleAddFieldTimeRange = () =>{

        const lastIndex = timeRangeArrays.length-1
        let newStartTime = dayjs(timeRangeArrays[lastIndex][1], "HH:mm:ss")
        let newEndTime   = dayjs(timeRangeArrays[lastIndex][1], "HH:mm:ss").add(timeRangeDur, 'hour')
        // ---------------------------

        if (moment.duration(moment(newStartTime?.toDate()).diff(moment("23:59:59","HH:mm:ss"))).asSeconds() > 0){
            newStartTime = dayjs().endOf('day')
        }
        if (moment.duration(moment(newEndTime?.toDate()).diff(moment("23:59:59","HH:mm:ss"))).asSeconds() > 0){
            newEndTime = dayjs().endOf('day')
            isMaxEndTimeReached(newEndTime)
        }
        // console.log("handleAddFieldTimeRange - newStartTime:",newStartTime)
        // console.log("handleAddFieldTimeRange - newEndTime:",newEndTime)

        if (moment.duration(moment(newEndTime?.toDate()).diff(moment(newStartTime?.toDate()))).asSeconds() > 0){
            const tmpRange : TimeRangeInput[] =  [[newStartTime, newEndTime]]
            let stTRs = startTimeRanges;    stTRs.push(newStartTime)
            let endTRs = endTimeRanges;    endTRs.push(newEndTime)
            // ------------------------------------------------------
            setStartTimeRanges(stTRs)
            setEndTimeRanges(endTRs)

            setTimeRangeArrays([...timeRangeArrays, ...tmpRange]) 

            const strTimeRanges = [...timeRangeArrays, ...tmpRange].map((tRange) => {
                return tRange[0].format("HH:mm:ss") + '-' + tRange[1].format("HH:mm:ss");
            }, '').join(",");
            setValue("cron.0.timeRanges", strTimeRanges)

            appendTimeRange([...tmpRange])      //add to the hook-form

            isMaxEndTimeReached(endTRs.slice(-1)? endTRs.slice(-1)[0]: null)
        }else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-right',
                iconColor: 'red',
                background: '#FFECEE',
                customClass: {
                  popup: 'colored-toast'                  
                },
                showConfirmButton: false,
                timer: 2000,
            })
            Toast.fire({
                icon: 'error',
                title: `<span class="text-danger fs-4">Unable to add timerange...!</span><br/><br/>Please adjust your timerange(s) again if necessary!</span>`,
                text:  'Max. end time (23:59:59) is reached!',
            }) 
        }
    }

    const handleRemoveFieldTimeRange = (index: number) => {
        let stTRs  = startTimeRanges.filter((tr, ind) => ind != index)
        let endTRs =   endTimeRanges.filter((tr, ind) => ind != index)
        // --------------------------------------

        setStartTimeRanges(stTRs)
        setEndTimeRanges(endTRs)

        let newArr = [...timeRangeArrays]
        newArr.splice(index, 1)
        setTimeRangeArrays(newArr)

        const strTimeRanges = newArr.map((tRange) => {
            return tRange[0].format("HH:mm:ss") + '-' + tRange[1].format("HH:mm:ss");
        }, '').join(",");
        setValue("cron.0.timeRanges", strTimeRanges)
        
        removeTimeRange(index)  //remove from the hook form        

        isMaxEndTimeReached(endTRs.slice(-1)? endTRs.slice(-1)[0]: null)

        // handle error msg
        let errArr = currentErrorTRArrays
        errArr[index] = ["", ""]
        setCurrentErrorTRArrays(errArr)                                                                                            
        setTrErrMsg(errArr.map(e => e.join('')).join(''))
    }

    const updateFieldTimeRangeChanged = (index: number, values: [Dayjs, Dayjs]) => {
        let stTRs = startTimeRanges;    stTRs[index] = values[0];
        let endTRs = endTimeRanges;    endTRs[index] = values[1];
        // --------------------------------------

        setStartTimeRanges(stTRs)
        setEndTimeRanges(endTRs) 

        let newArr = [...timeRangeArrays]
        newArr[index] = [values[0], values[1]]
        setTimeRangeArrays(newArr);

        const strTimeRanges = newArr.map((tRange) => {
            return tRange[0].format("HH:mm:ss") + '-' + tRange[1].format("HH:mm:ss");
        }, '').join(",");
        setValue("cron.0.timeRanges", strTimeRanges)

        updateTimeRange(index, [values[0], values[1]])        

        isMaxEndTimeReached(endTRs.slice(-1)? endTRs.slice(-1)[0]: null)

        // -------------------------
        let errArr = currentErrorTRArrays
        errArr[index] = ["", ""]
        setCurrentErrorTRArrays(errArr)                                                                                            
        setTrErrMsg(errArr.map(e => e.join('')).join(''))
    }     
    
    const resetFieldTimeRange = () => {       

        setStartTimeRanges(DEFAULT_STARTTIMERANGE_LIST)
        setEndTimeRanges(DEFAULT_ENDTIMERANGE_LIST)   

        setTimeRangeArrays(DEFAULT_TR_DAYJS_LIST)
        
        setValue("cron.0.timeRangeInputs", DEFAULT_TR_DAYJS_LIST)    //do not use remove and then append actions

        // --- Important section
        if (getValues("cron.0.timeRanges") !== "*"){
            const strTimeRanges = DEFAULT_TR_DAYJS_LIST.map((tRange) => {
                return tRange[0].format("HH:mm:ss") + '-' + tRange[1].format("HH:mm:ss");
            }, '').join(",");
            setValue("cron.0.timeRanges", strTimeRanges)        
        }
        // --- End of Important section

        setEndTimeRangeLimitReach(DEFAULT_MAX_ENDTIME_REACH)
    } 
    // -----------------------------------
    // END of fieldArray Timeranges-------







    //---------------------------------------------------- 
    const formSubmitHandler: SubmitHandler<ScheduleInput> = async (sche: ScheduleInput) => {        
        let submitFlag = true
        let tmpStrs = ["","","",""]
        let newSche: ScheduleInput
        // -----------------------------------

        // console.log("sche in submit: ", sche)
        // console.log("isAllDay: ", isAllDay)
        // console.log("repeatMode: ", repeatMode)
        // console.log("isNoEndDate: ", isNoEndDate)
        
        // 1. Generate necessary values
        // ------------------------------------
        if (schedMode === "standard"){
            if((moment(sche.end_time).diff(moment(sche.start_time))) < 0){
                submitFlag = false

                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Unable to add a schedule!',
                        text: 'Schedule end time cannot be earlier than start time.',
                        showCloseButton: true
                    }
                )               
            }
        }else if (schedMode === "recurrence"){
            sche.type = "2" 

            switch (repeatMode){
                case 'once':
                    sche.end_time = (repeatMode === "once" && schedMode === "recurrence")? moment(sche.start_time).endOf('day').format("YYYY-MM-DD HH:mm:ss"): sche.end_time
                    tmpStrs = ["","*","*","*"]
                    break;
                case 'daily':
                    tmpStrs = ["","*","*","*"]
                    break;
                case 'weekly':
                    tmpStrs = ["","*","*", moment(sche.start_time).format("d")]
                    break;
                case 'monthly':
                    tmpStrs = ["", moment(sche.start_time).format("DD"),"*","*"]
                    break;
                case 'yearly':
                    tmpStrs = ["", moment(sche.start_time).format("DD"), moment(sche.start_time).format("MM"),"*"]                    
                    break;
                case 'weekday':
                    tmpStrs = ["","*","*","1-5"]
                    break;
                case 'weekend':
                    tmpStrs = ["","*","*","6,0"]
                    break;
                case 'custom':
                    tmpStrs = ["","*","*","*"]
                    break;
                default:
                    console.log("there is an error!")                    
            }

            // Determine the timerange
            if (isAllDay){
                tmpStrs[0] = "*"
            }else{            
                if (sche.cron && sche.cron[0].timeRangeInputs){                    
                    tmpStrs[0] = sche.cron[0].timeRangeInputs.map((timRange) => {
                                        // return timRange[0]+"-"+timRange[1]
                                        return timRange[0].format('HH:mm:ss')+"-"+timRange[1].format('HH:mm:ss')
                                    }).join(",")
                }
            }
            
            if ((sche.cron) && (
                sche.cron[0].timeRanges === "" ||
                sche.cron[0].dateRange  === "" ||
                sche.cron[0].monthRange === "" ||
                sche.cron[0].dayOfWeek  === ""
                )
            ){
                submitFlag = false

                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Unable to create new schedule!',
                        text: 'Recurring schedule inputs are not correct.',
                        showCloseButton: true
                    }
                )                    
            }
        }
        
        // 2. Submit
        // ------------------------------------        
        if (submitFlag === true){
            try{

                sche["deviceGroupIds"] = sche.groupInputs
                                                        ?   sche.groupInputs.reduce((accum, grp) => {return accum + grp.grpId + ' ';}, '').trim().replaceAll(" ",",")
                                                        :   "";
                // delete sche["groupInputs"]

                newSche = {
                    id: crudScheduleStatus.updateSchedMode ? sche.id : "",
                    start_time: sche.start_time,
                    // end_time:   sche.end_time,
                    end_time:   (isNoEndDate && schedMode==="recurrence" && repeatMode !=="once") ? "": sche.end_time,
                    repeat: sche.repeat,
                    // repeat: "once",
                    contents: sche.contents? sche.contents: [],
                    deviceGroupIds: sche.deviceGroupIds,
                }  
                
                if (schedMode === "recurrence"){
                    newSche =   { ...newSche,
                                    type: sche.type? sche.type: "",
                                    cron: [{
                                        timeRanges  : tmpStrs[0] !== "" ? tmpStrs[0] : "*",   //"9:30-10:30,18:15-19:05",   "*"    
                                        dateRange   : tmpStrs[1] !== "" ? tmpStrs[1] : "*",   //1-31, */2 
                                        monthRange  : tmpStrs[2] !== "" ? tmpStrs[2] : "*",   //1-12, */2
                                        dayOfWeek   : tmpStrs[3] !== "" ? tmpStrs[3] : "*",   //0-7,  */2
                                    }]                                    
                                }
                }
                
                // console.log("newSche in formSubmit:", newSche)

                if (!crudScheduleStatus.updateSchedMode) {
                    const response = await addSchedule(newSche)
                    // console.log("response:", response)
                }else{
                    const response = await updateSchedule(newSche)
                    // console.log("response:", response)
                }
                                
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-right',
                    iconColor: 'green',
                    customClass: {
                    popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 2000,
                    // timerProgressBar: true
                })
                Toast.fire({
                    icon: 'success',
                    title: !(crudScheduleStatus.updateSchedMode) ? "New schedule <br/> has been successfully created.": "Schedule <br/> has been successfully updated.",
                }) 

                // resetFieldContent()
                // resetFieldGrp()
                // resetFieldTimeRange()
                // setRepeatMode("daily")
                // setSchedMode("standard")
                // setIsAllDay(true)
                // setIsNoEndDate(true)

                reset()
                
            }catch(error){
                Swal.fire(
                    'Schedule: ',
                    'There is something wrong during adding a new schedule!',
                    'error'
                )     
                    
            }
        }
    }
   

    // -----UseEffect Sections-----
    // ----------------------------
    useEffect(() => {
        // console.log("...crudScheduleStatus.updateSchedMode = ", crudScheduleStatus.updateSchedMode?"true":"false")

        if (crudScheduleStatus.updateSchedMode && crudScheduleStatus.updateSchedMode === true){
            resetFieldTimeRange() 

            // =====Update Sched = currSchId ==========
            // ========================================
            // console.log("---=UPDATE NEW SCHED=========")

            let currSc: Sched[] = []
            let contList : AssignedContent[] = []
            let tmpRepeat: string = ""
            let tmpEndDateTime = null
            // ------------------------------
            currSc = allSchs.filter((sch)=> {return sch.id === currSchId})
            // setCurrSc(allSchs.filter((sch)=> {return sch.id === currSchId}))
            // console.log("currSc[0]: ", currSc[0])

            if (currSc.length>0){  

                // Generate the missing content data
                // --------------------------------------------
                currSc[0].user_templates.map((cont)=>{
                    contList = [...contList, {
                            content_id      : cont.user_template_id?cont.user_template_id:"",
                            play_duration   : cont.duration, 
                            mode            : '',
                            num_loops       : 0,                 
                        }]
                })
                // Determine the orientation based on the first campaign
                // --------------------------------------------------------
                setGroupOrientation(currSc[0].user_templates[0].width >= currSc[0].user_templates[0].height ? "landscape": "portrait")

                // Determine the repeat value
                // -------------------------------                
                if (currSc[0].cron){
                    tmpRepeat = extractRepeatModeFromSchedule(currSc)                    
                }

                // Determine the isAllDay and isNoEndDate option
                // -------------------------------                
                setIsAllDay( currSc[0].cron && currSc[0].cron.timeRanges === "*" ? true: false)
                setIsNoEndDate(!currSc[0].end_time || currSc[0].end_time === "" || repeatMode === "once"? true: false)
                
                if (!currSc[0].end_time || currSc[0].end_time === ""){
                    tmpEndDateTime = dayjs(currSc[0].start_time).add(5, 'year')                    
                }else{
                    tmpEndDateTime = dayjs(currSc[0].end_time)
                }

                // -------------------------------
                reset({
                    id: currSc[0].id,
                    start_time  : currSc[0].start_time,
                    end_time    : tmpEndDateTime?.format("YYYY-MM-DD HH:mm:ss"),
                    repeat: tmpRepeat,            
                    contents: contList,
                    deviceGroupIds: currSc[0].group_id,
                    groupInputs: [{grpId: currSc[0].group_id}],
    
                    type: currSc[0].type_id+"",
                    cron: [{
                        timeRanges     : currSc[0].cron? currSc[0].cron.timeRanges: "*",   //"9:30-10:30,18:15-19:05",   "*"    
                        dateRange      : currSc[0].cron? currSc[0].cron.dateRange : "*",   //1-31, */2 
                        monthRange     : currSc[0].cron? currSc[0].cron.monthRange: "*",   //1-12, */2
                        dayOfWeek      : currSc[0].cron? currSc[0].cron.dayOfWeek : "*",    //0-7,  */2
                        timeRangeInputs: currSc[0].cron? currSc[0].cron.timeRangeInputs: [],      
                    }]
                })           

                // -----Reset the state params
                setGrpIDs([currSc[0].group_id])

                let newArr : string[] = []; 
                currSc[0].user_templates.map((cont, index)=>{                       
                    newArr[index] = cont.user_template_id?cont.user_template_id:"";      
                })
                setContentIDs(newArr);
                
                setSchedMode(currSc[0].type_id === 1? "standard":"recurrence")

                setStartDateTime(dayjs(currSc[0].start_time))
                setEndDateTime(tmpEndDateTime)

                // ---If it is Recurrence schedule
                // ------------------------------------------
                if (currSc[0].type_id === 2){
                    
                    let tmpStartTimeRanges  : (Dayjs | null)[] = []
                    let tmpEndTimeRanges    : (Dayjs | null)[] = []
                    let tmpTimeRangeList    : TimeRangeInput[] = []
                    let maxEndTime          : Dayjs | null = dayjs().startOf('day')
                    // ---------------------------------------------------
                    if (currSc[0].cron){
                        if (currSc[0].cron.timeRanges === "*"){
                            // resetFieldTimeRange()

                            setStartTimeRanges(DEFAULT_STARTTIMERANGE_LIST)
                            setEndTimeRanges(DEFAULT_ENDTIMERANGE_LIST)   

                            setTimeRangeArrays(DEFAULT_TR_DAYJS_LIST)
                            
                            setValue("cron.0.timeRangeInputs", DEFAULT_TR_DAYJS_LIST)    //do not use remove and then append actions

                            setEndTimeRangeLimitReach(DEFAULT_MAX_ENDTIME_REACH)

                            // removeTimeRange()
                            appendTimeRange(DEFAULT_TR_DAYJS_LIST)  

                        }else{

                            removeTimeRange()   //removeTR before appending it, need sometime

                            currSc[0].cron.timeRanges.split(",").forEach((timeRangeStr, ind)=>{
                                const tRange = timeRangeStr.split("-")
                                const startTime = dayjs(tRange[0],"HH:mm:ss")
                                const endTime   = dayjs(tRange[1],"HH:mm:ss")
                                // -------------------------------------------
                                
                                tmpStartTimeRanges.push(startTime)
                                tmpEndTimeRanges.push(endTime)

                                tmpTimeRangeList.push([startTime, endTime])
                                    //do not use remove and then append actions
                                
                                maxEndTime = endTime
                            })

                            setStartTimeRanges(tmpStartTimeRanges)
                            setEndTimeRanges(tmpEndTimeRanges)

                            setTimeRangeArrays(tmpTimeRangeList)
                            
                            // removeTimeRange()
                            appendTimeRange(tmpTimeRangeList)  
                            
                            setValue("cron.0.timeRangeInputs", tmpTimeRangeList)   //= removeTimeRange() + appendTimeRange(tmpJSONTimeRanges)  

                            isMaxEndTimeReached(maxEndTime)


                            // handle error msg
                            // let errArr = currentErrorTRArrays
                            // errArr.map(err => ["", ""])
                            // // errArr[2] = ["", ""]
                            // // errArr[3] = ["", ""]
                            // setCurrentErrorTRArrays(errArr)                                                                                            
                            // setTrErrMsg(errArr.map(e => e.join('')).join(''))
                            // console.log("===========================================================")

                        }
                    }

                    setSchedMode("recurrence")
                    setRepeatMode(tmpRepeat)  // need to findout based on cron

                }else{  //in case update from a standard schedule
                    setSchedMode("standard")
                    setRepeatMode("daily")
                    resetFieldTimeRange()
                }
            }else{
                setSchedMode("standard")
                setRepeatMode("daily")
                resetFieldTimeRange()
            }
        }else{
            // =====Add New Sched======================
            // ======================================== 
            // console.log("---=====ADD NEW SCHED=========")

            resetFieldGrp()

            setSchedMode("standard")
            setRepeatMode("daily")
            resetFieldTimeRange()   

            reset({
                start_time  : startDateTime?.format('YYYY-MM-DD HH:mm:ss'),
                end_time    :   endDateTime?.format('YYYY-MM-DD HH:mm:ss'),
                repeat: "daily",            
                contents: [emptyAssignedContent],
                deviceGroupIds: "",
                groupInputs: [{grpId: grpIDs? grpIDs[0] :""}],

                type: "",
                cron: [{
                        timeRanges    : "*",
                        dateRange     : "*",
                        monthRange    : "*",
                        dayOfWeek     : "*",
                        timeRangeInputs: DEFAULT_TR_DAYJS_LIST,
                }]
            })

            resetFieldContent() //must be after the reset hook form

            setSchedMode("standard")
            setIsAllDay(true)
            setRepeatMode("daily")
            setIsNoEndDate(true)            
        }

    }, [ allDeviceGroups, reset, allSchs, currSchId ])
    

    

    // -------------------------------
    useEffect(() => {
        if (crudScheduleStatus.updateSchedMode === false){  //when click on add new schedule
            setSchedMode(crudScheduleStatus.schedMode)            
            
            setGroupOrientation('landscape')
            resetFieldContent()
            resetFieldGrp()

            setSchedMode("standard")
            setRepeatMode("daily")
            resetFieldTimeRange()
        }
    },[crudScheduleStatus.schedMode])
    // -------------------------------

    

    //---Main Program-------------------------------------------------
    return (
        <>  
            <div
                id='kt_drawer_crud_schedule'
                className={`bg-body ${className?className:""}`}                
                style={{zIndex: 1050}}
                data-kt-drawer='true'
                data-kt-drawer-name='drawer_crud_schedule'
                data-kt-drawer-activate='true'
                data-kt-drawer-overlay='true'
                // data-kt-drawer-width="{default:'300px', 'lg': '500px'}"
                data-kt-drawer-width="{default:'300px', 'md': '350px', 'lg': '450px'}"
                
                // data-kt-drawer-direction='end'
                data-bs-backdrop="static" 
                data-kt-drawer-toggle='#kt_drawer_crud_schedule_toggle'
                data-kt-drawer-close='#kt_drawer_crud_schedule_close'
                aria-labelledby="staticBackdropLabel"
            >
                <div className='card shadow-none w-100'>
                    <div className='card-header bg-gradient-primary rounded-0' id='kt_drawer_crud_schedule_header'>
                        <h3 className="card-title text-white">
                            <KTSVG
                                path={`/media/icons/duotune/text/txt001.svg`}
                                className={`svg-icon-2x svg-icon-white me-3 mt-n2 d-inline`}
                            />
                            {crudScheduleStatus.updateSchedMode ? "Edit ":"Add New"} Schedule
                        </h3>

                        <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                                id='kt_drawer_crud_schedule_close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </button>
                        </div>
                    </div>
                    <div className='card-body position-relative' id='kt_drawer_crud_schedule_body'>
                        <div
                            id='kt_drawer_crud_schedule_scroll'
                            className='position-relative scroll-y me-n5 pt-4 pe-5 text-start'
                            data-kt-scroll='true'
                            data-kt-scroll-height='auto'
                            data-kt-scroll-wrappers='#kt_drawer_crud_schedule_body'
                            data-kt-scroll-dependencies='#kt_drawer_crud_schedule_header, #kt_drawer_crud_schedule_footer'
                            data-kt-scroll-offset='5px'
                        >
                            <form id="frm-add-schedule" className='form'  
                            onSubmit={handleSubmit(formSubmitHandler)}
                            >   
                                <div className="mb-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="select-orientation-label">Orientation</InputLabel>
                                        <Select 
                                            labelId="select-orientation-label"                                        
                                            label="Orientation"
                                            value={groupOrientation}
                                            onChange={(e) => {
                                                setGroupOrientation(e.target.value);
                                                resetFieldContent(emptyAssignedContent, e.target.value)
                                                resetFieldGrp("", e.target.value)
                                            }}
                                            MenuProps={{ disablePortal: true }}
                                        >        
                                            <MenuItem key={0} value={""} disabled>Select Orientation</MenuItem>
                                            <MenuItem key={1} value={"landscape"} className='w-100'>Landscape</MenuItem>
                                            <MenuItem key={2} value={"portrait"} className='w-100'>Portrait</MenuItem>                                                                               
                                        </Select>
                                        <FormHelperText>{`${errors.deviceGroupIds?.message ?? ''}`}</FormHelperText>
                                    </FormControl>
                                </div>

                                {/* Select multiple contents */}
                                {listContents && listPlaylists && 
                                    <div className="mb-4">
                                        {fieldsContent.map((fieldCont, index) => {
                                            return (
                                                <div className="d-flex mb-4" key={fieldCont.id}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="select-content-label">Campaign / Playlist</InputLabel>
                                                        <Select 
                                                            {...register(`contents.${index}.content_id`)} 
                                                            labelId="select-content-label"                                        
                                                            label="Campaign / Playlist"
                                                            value={`${contentIDs[index]}`}
                                                            onChange={(e) => updateFieldContentChanged(index, e.target.value)}
                                                            error={!! errors.contents}
                                                            MenuProps={{ disablePortal: true }}
                                                        >        
                                                            <MenuItem key={"cnt-00"} value={"0"} disabled>Select campaign or playlist</MenuItem>                                
                                                            {listContents && listContents.length >0 &&
                                                                <MenuItem key={"cnt-01"} value={"1"} disabled className='fw-bold bg-primary text-light'>Campaign Section</MenuItem>
                                                            }
                                                            {listContents
                                                            ?.slice().sort((a,b)=> a.user_template_name.toLowerCase() > b.user_template_name.toLowerCase() ? 1:-1)
                                                            ?.filter((content) => {
                                                                return ((content.width >= content.height) && groupOrientation === "landscape") || ((content.width < content.height) && groupOrientation === "portrait") 
                                                            })
                                                            // ?.sort((a, b) => {
                                                            //     if (a.user_template_name > b.user_template_name) {return -1 }
                                                            //     if (a.user_template_name < b.user_template_name) {return 1 }
                                                            //     return 0;
                                                            // })
                                                            .map((content) => {
                                                                const durationFromNow = moment().diff(moment.utc(content.modified_time).local())/(1000*60*60*24)
                                                                // -------------------------------------------------
                                                                return (
                                                                    <MenuItem key={content.user_template_id} value={content.user_template_id}>
                                                                        <div className='w-100 d-flex justify-content-between'>
                                                                            <div className={`d-flex justify-content-start w-175px text-capitalize `}>
                                                                                <div className={`symbol ${content.width >= content.height? "symbol-25px symbol-2by3":"symbol-25px symbol-3by1"} me-2`}>
                                                                                    <div className="symbol-label rounded-0 bg-transparent"
                                                                                        style={{ 
                                                                                            backgroundImage: `url('${getContentThumbnail(content)}')`,
                                                                                        }}
                                                                                    >       
                                                                                        <a  href="#" 
                                                                                            className="btn btn-link hover-scale"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#modal-preview-content"
                                                                                            onClick={() => { dispatch(setContent(content)); }}
                                                                                        >
                                                                                            <i className="bi bi-play-circle-fill fs-6 text-white text-hover-gradient-primary opacity-75"></i>                                    
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='text-wrap text-start'>{content.user_template_name}</div>
                                                                            </div>       
                                                                            <div className='d-flex'>
                                                                                {/* <div className='text-end ms-1'>
                                                                                    <span className={`badge badge-light text-capitalize ms-2`}>
                                                                                        {content.width >= content.height? "":"Portrait"}
                                                                                    </span>
                                                                                </div> */}
                                                                                <div className='text-end ms-1'>
                                                                                    <span className={`badge 
                                                                                        ${durationFromNow <= VERYRECENTPERIOD ? "badge-warning": (durationFromNow <= RECENTPERIOD ? "badge-light-warning": "badge-light")} 
                                                                                        ms-2`}
                                                                                    >
                                                                                        {moment.utc(content.modified_time).local().fromNow()}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='text-end ms-1' style={{width: `${content.duration >= 1000*60*60?"75px":"65px"}`}}>
                                                                                    <span className="badge badge-light">
                                                                                        <i className='bi bi-clock-history fs-8 me-1'></i>
                                                                                        {secondsToHHmmss(content.duration)}s
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>                                                        
                                                                    </MenuItem>
                                                                )
                                                            })} 

                                                            {/* ------------------ */}
                                                            { listPlaylists && listPlaylists.length >0 &&
                                                                <MenuItem key={"pl-0"} value={"2"} disabled className='fw-bold bg-primary text-light'>Playlist Section</MenuItem>
                                                            }
                                                            {listPlaylists
                                                            ?.slice().sort((a,b)=> a.user_template_name.toLowerCase() > b.user_template_name.toLowerCase() ? 1:-1)
                                                            ?.filter((playlist) => {
                                                                return ((playlist.width >= playlist.height) && groupOrientation === "landscape") || ((playlist.width < playlist.height) && groupOrientation === "portrait") 
                                                            })
                                                            .map((playlist) => { 
                                                                const durationFromNow = moment().diff(moment.utc(playlist.modified_time).local())/(1000*60*60*24)
                                                                // ----------------------------------
                                                                return (                                                        
                                                                    <MenuItem key={playlist.user_template_id} value={playlist.user_template_id}
                                                                        className='w-100 '
                                                                    >
                                                                        <div className='w-100 d-flex justify-content-between'>
                                                                            <div className={`d-flex w-175px text-capitalize ${playlist.elements[0].sources.length <= 0 ? "opacity-50":""}`}>
                                                                                <div>
                                                                                    <div className="symbol symbol-20px symbol-2by3 me-2">
                                                                                        <div className="symbol-label rounded-0 bg-transparent"
                                                                                            // style={{ 
                                                                                            //     backgroundImage: `url('${(playlist.elements && playlist.elements[0].sources.length>0)?(playlist.elements[0].sources[0].source?playlist.elements[0].sources[0].source.url_thumb:""):""}')`,
                                                                                            // }}
                                                                                        >                                                                                       
                                                                                            <a  href="#" 
                                                                                                className="btn btn-link hover-scale"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#modal-preview-content"
                                                                                                onClick={() => { dispatch(setContent(playlist)); }}
                                                                                            >
                                                                                                <i className="bi bi-play-circle-fill fs-6 text-gray-300 text-hover-gradient-primary opacity-75"></i>                                    
                                                                                            </a>
                                                                                            <KTSVG path='/media/icons/duotune/text/txt009.svg' className='svg-icon-5 svg-icon-primary' />
                                                                                        </div>
                                                                                        {playlist.elements[0].sources.length > 0 &&
                                                                                            <span className="symbol-badge badge badge-circle p-0 badge-light-primary top-100 start-100 opacity-75">
                                                                                                {playlist.elements[0].sources.length}
                                                                                            </span>
                                                                                        }
                                                                                    </div>    
                                                                                </div>                                                                
                                                                                <div className='text-wrap text-start ms-3'>{playlist.user_template_name}</div>
                                                                            </div>                                                                
                                                                            <div className='d-flex'>
                                                                                <div className='text-end ms-1'>
                                                                                    <span className={`badge 
                                                                                        ${durationFromNow <= VERYRECENTPERIOD ? "badge-warning": (durationFromNow <= RECENTPERIOD ? "badge-light-warning": "badge-light")} 
                                                                                        ms-2`}
                                                                                    >
                                                                                        {moment.utc(playlist.modified_time).local().fromNow()}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='text-end ms-1' style={{width: `${playlist.duration >= 1000*60*60?"75px":"65px"}`}}>
                                                                                    <span className="badge badge-light">
                                                                                        <i className='bi bi-clock-history fs-8 me-1'></i>
                                                                                        {secondsToHHmmss(playlist.duration)}s
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </MenuItem>
                                                                )
                                                            })}                                 
                                                        </Select>
                                                        <FormHelperText>{`${errors.contents?.message ?? ''}`}</FormHelperText>
                                                    </FormControl>

                                                    {/* Need to fix the button remove script. remove item at a specific index */}
                                                    {/* <button type="button"                                             
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary mt-1 ms-3'
                                                        onClick={() => {                                                
                                                            remove(index)
                                                            let newArr = [...contentIDs]
                                                            newArr.pop()
                                                            setContentIDs(newArr)
                                                        }}
                                                    >                                            
                                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon svg-icon-2x' />
                                                    </button> */}
                                                </div>
                                            );
                                        })}
                                    
                                        {/* <button type="button"                                             
                                            className='btn btn-icon btn-bg-light btn-active-color-primary mt-2'
                                            onClick={() => {
                                                setContentIDs([...contentIDs, ""])
                                                append(emptyAssignedContent)
                                            }}
                                        >                                            
                                            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon svg-icon-2x' />
                                        </button> */}
                                    </div>   
                                }
                                {allDeviceGroups &&
                                    <div className="mb-4">
                                        {fieldsGrp.map((field, index) => {
                                            return (
                                                <div className="d-flex mb-4" key={field.id}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="select-group-label">Group</InputLabel>
                                                        <Select 
                                                            {...register(`groupInputs.${index}.grpId`)}
                                                            labelId="select-location-label"                                        
                                                            label="Group"
                                                            value={`${grpIDs[index]}`}
                                                            onChange={(e) => {
                                                                updateFieldGrpChanged(index, e.target.value)
                                                            }}                                                
                                                            error={!! errors.groupInputs}
                                                            MenuProps={{ disablePortal: true }}
                                                        >        
                                                            <MenuItem key={0} value={0} disabled>Select group</MenuItem>                                
                                                            {allDeviceGroups
                                                            ?.slice().sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1:-1)
                                                            ?.filter((grp) => {
                                                                return (grp.displayType === groupOrientation) 
                                                            })
                                                            .map((opt) => {
                                                                return (
                                                                    <MenuItem key={opt.id} value={opt.id} className='w-100'>
                                                                        {/* {opt.group_name} (<span className='text-capitalize'>{opt.displayType}</span>) */}
                                                                        {opt.devices && 
                                                                            <div className='w-100 d-flex justify-content-between'>
                                                                                <span className={`w-150px text-capitalize ${opt.devices.length <= 0 ? "opacity-50":""}`}>
                                                                                    <i className="fa fa-folder me-2"></i>{opt.name} 
                                                                                </span>
                                                                                <span className="badge badge-light text-capitalize ms-3">
                                                                                    {opt.displayType}
                                                                                </span>
                                                                                {opt.devices.length > 0 
                                                                                    ?   <span className="badge badge-light ms-3">
                                                                                            {opt.devices.length} devices
                                                                                        </span>
                                                                                    :   <span className="w-75px">&nbsp;</span>
                                                                                } 
                                                                            </div>
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            })}                                        
                                                        </Select>
                                                        <FormHelperText>{`${errors.deviceGroupIds?.message ?? ''}`}</FormHelperText>
                                                    </FormControl>
                                                    {fieldsGrp.length > 1 &&
                                                        <button type="button"                                             
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary mt-1 ms-3'
                                                            onClick={() => handleRemoveFieldGrp(index)}                                                
                                                        >                                            
                                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon svg-icon-2x' />
                                                        </button>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                { !crudScheduleStatus.updateSchedMode &&
                                    <div className="d-flex flex-end">
                                        <button type="button"                                             
                                            className='btn btn-sm btn-bg-light btn-active-color-primary mb-4 tooltip-bottom'
                                            data-tooltip-content='Add another group'
                                            onClick={() => handleAddFieldGrp()}                           
                                        >                                            
                                            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon svg-icon-5' />
                                            Add group
                                        </button>
                                    </div> 
                                }


                                {/* ======Scheduling =============================== */}  

                                <div className='mb-n4'>
                                    <span className='bg-white text-muted fs-5 rounded ms-n1 mb-n7 py-1 px-5'>Schedule</span>
                                </div>
                                <div className='container-fluid bg-gray-300 pt-3 pb-1 px-5 rounded'>
                                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                        { (!crudScheduleStatus.updateSchedMode || (crudScheduleStatus.updateSchedMode && schedMode === "standard")) &&
                                            <li className="nav-item">
                                                <a className={`nav-link text-active-primary ${schedMode==="standard"? "active":""}`} data-bs-toggle="tab" href="#kt_tab_pane_standard_sched"
                                                    onClick={()=> {
                                                        setSchedMode("standard")
                                                        setValue("type", "")
                                                    }}
                                                >
                                                    <span><i className='bi bi-calendar2 text-normal fs-semibold me-2'></i>Standard</span>
                                                </a>
                                            </li>
                                        }
                                        { (!crudScheduleStatus.updateSchedMode || (crudScheduleStatus.updateSchedMode && schedMode === "recurrence")) &&
                                            <li className="nav-item">
                                                <a className={`nav-link text-active-primary ${schedMode==="recurrence"? "active":""}`} data-bs-toggle="tab" href="#kt_tab_pane_recurrence_sched"
                                                    onClick={()=> {
                                                        setSchedMode("recurrence")
                                                        setValue("type", "2")

                                                        if (repeatMode === "once") {
                                                            setEndDateTime(startDateTime)
                                                            setValue("end_time", startDateTime? startDateTime.format('YYYY-MM-DD HH:mm:ss'):"")
                                                        }
                                                    }}
                                                >
                                                    <span><i className='bi bi-arrow-repeat text-normal fs-semibold me-2'></i>Recurrence</span>
                                                </a>
                                            </li>
                                        }
                                    </ul>

                                    {/* ------------------------- */}
                                    <div className="tab-content" id="myTabContent">
                                        <div  className={`tab-pane fade ${schedMode==="standard"? "show active":""}`} id="kt_tab_pane_standard_sched" role="tabpanel">
                                            <div className="mb-4">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer
                                                        components={[
                                                            'MobileDateTimePicker',
                                                        ]}
                                                    >                                                
                                                        <DemoItem>
                                                            <MobileDateTimePicker 
                                                                className='mb-2 bg-white rounded'
                                                                label="Start Date & Time" 
                                                                value={startDateTime}
                                                                format='dddd, DD/MM/YYYY HH:mm:ss'
                                                                // format='dddd, DD/MM/YYYY HH:mm:ss'
                                                                onChange={(newVal, context)=>{ 
                                                                    console.log("start_time newVal:",newVal)

                                                                    setStartDateTime(newVal)
                                                                    setEndDateTime(newVal? newVal.add(1, "month"): newVal)

                                                                    setValue("start_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                                                    setValue("end_time",   newVal? newVal.add(1, "month").format('YYYY-MM-DD HH:mm:ss'):"")
                                                                }}
                                                            />
                                                            <MobileDateTimePicker 
                                                                className='mb-2 bg-white rounded'
                                                                label="End Date & Time" 
                                                                value={endDateTime}
                                                                minDate={startDateTime?.set("hour", moment(startDateTime.toDate()).hour())}
                                                                format='dddd, DD/MM/YYYY HH:mm:ss'
                                                                onChange={(newVal, context)=>{ 
                                                                    // console.log("end_time newVal:",newVal)

                                                                    setEndDateTime(newVal)

                                                                    setValue("end_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                                                }}
                                                            />                                        
                                                        </DemoItem>                                    
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        {/* ------------------------- */}
                                        <div  className={`tab-pane fade ${schedMode==="recurrence"? "show active":""}`} id="kt_tab_pane_recurrence_sched" role="tabpanel">
                                            <div className="mb-4">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            className='w-100 bg-white rounded' 
                                                            label="Start Date" 
                                                            value={startDateTime}
                                                            format='dddd, DD/MM/YYYY'
                                                            onChange={(newVal, context)=>{                                                                 
                                                                setStartDateTime(newVal)
                                                                setValue("start_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className="mb-4">
                                                <div className="d-flex justify-content-end form-check form-switch form-check-custom form-switch-sm form-check-solid ">                                                     
                                                    <label className="form-check-label text-gray-700 me-3" htmlFor="flexSwitchAllDay">
                                                        All day
                                                    </label>
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchAllDay" value="" checked={isAllDay}
                                                        onChange={() => setIsAllDay(!isAllDay)}
                                                    />
                                                </div>
                                            </div>
                                            {/* Timeranges Section */}
                                            {/* ================== */}
                                            {!isAllDay && 
                                                <div className="mb-4 bg-white rounded py-1 px-3">
                                                    <div className='d-flex flex-row'>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer                                                    
                                                                components={['TimePicker', 'MultiInputTimeRangeField']}
                                                                // components={['TimePicker','SingleInputTimeRangeField']}
                                                            >        
                                                                <div className="mb-2">
                                                                    {fieldsTimeRange.map((field, index) => {
                                                                        const momentLastEndTR   = endTimeRanges[index-1]? moment(endTimeRanges[index-1]?.toDate()): moment().startOf('day').add(7,'hour')
                                                                        const momentStartTR     = moment(startTimeRanges[index]?.toDate())
                                                                        const momentEndTR       = moment(endTimeRanges[index]?.toDate())
                                                                        // ------------------------------------------
                                                                        return (
                                                                            <div className="d-flex align-items-center mb-3" key={`timerange-${field.id}`}>                                                                                
                                                                                <MultiInputTimeRangeField                                                                                    
                                                                                    {...register(`cron.0.timeRangeInputs.${index}`)}                                                                                    
                                                                                    format='hh:mm A'
                                                                                    defaultValue={[
                                                                                        startTimeRanges[index], 
                                                                                        endTimeRanges[index]
                                                                                    ]}     
                                                                                    slotProps={{
                                                                                        textField: ({ position }) => ({
                                                                                            label: position === 'start' ? 'From' : 'To',
                                                                                            size: 'small',
                                                                                            // errors: "test",                                                                                            
                                                                                            helperText: position === 'start' ? <span className='text-danger'>{currentErrorTRArrays[index][0]}</span> : <span className='text-danger'>{currentErrorTRArrays[index][1]}</span>,
                                                                                            className: endTimeRangeLimitReach && (index === fieldsTimeRange.length -1)  ? (position === 'end' ? "bg-light-primary rounded":"") : "",                                                                                        
                                                                                        }),
                                                                                    }}
                                                                                    onError={(error, value)=>{
                                                                                        if (error){
                                                                                            let errArr = currentErrorTRArrays
                                                                                            errArr[index] = [error[0]?error[0]:"", error[`1`]?error[`1`]:""]
                                                                                            setCurrentErrorTRArrays(errArr)                                                                                            
                                                                                            setTrErrMsg(errArr.map(e => e.join('')).join(''))
                                                                                        }
                                                                                        
                                                                                    }}
                                                                                    onChange={(newValue: any , context) => {                                                                                        
                                                                                        try{
                                                                                            setCurrTR(newValue) //store it first, update when onBlur                                                                                            
                                                                                        }catch(err){
                                                                                            console.log("err", err)
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e: any) => {
                                                                                        try{                                                                                           
                                                                                            updateFieldTimeRangeChanged(index, currTR)
                                                                                        }catch(err){
                                                                                            console.log("err", err)
                                                                                        }
                                                                                    }}                                                                                    
                                                                                />
                                                                                

                                                                                {/* <TimePicker                                                                         
                                                                                    {...register(`cron.0.timeRangeInputs.${index}.startTimeRange`)} 
                                                                                    label="From"
                                                                                    className='w-100 me-2' 
                                                                                    // value={null}
                                                                                    value={startTimeRanges[index]}
                                                                                    // defaultValue={dayjs().set('hour', 0).startOf('hour')}
                                                                                    // defaultValue={dayjs().set('hour', momentLastEndTR.hour()).set('minute', momentLastEndTR.minutes()).set('second', momentLastEndTR.seconds()+1)}
                                                                                    // minTime=     {dayjs().set('hour', momentLastEndTR.hour()).set('minute', momentLastEndTR.minutes()).set('second', momentLastEndTR.seconds())}
                                                                                    format='hh:mm A'
                                                                                    slotProps={{ textField: { size: 'small' } }}
                                                                                    onChange={(newVal)=>{ 
                                                                                        let tmpStartArr = startTimeRanges
                                                                                        tmpStartArr[index] = newVal
                                                                                        // --------------------------------

                                                                                        setStartTimeRanges(tmpStartArr)
                                                                                        setValue(`cron.0.timeRangeInputs.${index}.startTimeRange`, newVal? newVal.format("HH:mm:ss"):"")
                                                                                        updateFieldTimeRangeChanged(index, [
                                                                                            newVal? newVal.format("HH:mm:ss"):"", 
                                                                                            fieldsTimeRange[index].endTimeRange
                                                                                        ])
                                                                                    }}
                                                                                />
                                                                                <div className='ms-0 me-2'>-</div>
                                                                                <TimePicker                                                                         
                                                                                    {...register(`cron.0.timeRangeInputs.${index}.endTimeRange`)} 
                                                                                    label="To"
                                                                                    className={`w-100 ${endTimeRangeLimitReach && (index === fieldsTimeRange.length -1) ? "bg-light-primary":""}`} 
                                                                                    value={endTimeRanges[index]}
                                                                                    // defaultValue={dayjs().set('hour', 23).endOf('hour')}
                                                                                    // defaultValue={dayjs().set('hour', momentLastEndTR.hour()).endOf('hour')}
                                                                                    // minTime=     {dayjs().set('hour', momentLastEndTR.hour()).endOf('hour')}
                                                                                    format='hh:mm A'
                                                                                    slotProps={{ textField: { size: 'small' } }}
                                                                                    onChange={(newVal)=>{ 
                                                                                        let tmpEndArr = endTimeRanges
                                                                                        tmpEndArr[index] = newVal
                                                                                        // --------------------------------

                                                                                        setEndTimeRanges(tmpEndArr)
                                                                                        setValue(`cron.0.timeRangeInputs.${index}.endTimeRange`, newVal? newVal.format("HH:mm:ss"):"")
                                                                                        updateFieldTimeRangeChanged(index, [
                                                                                            fieldsTimeRange[index].startTimeRange,
                                                                                            newVal? newVal.format("HH:mm:ss"):""
                                                                                        ])
                                                                                    }}
                                                                                /> */}

                                                                                {fieldsTimeRange.length > 1 &&
                                                                                    <button type="button"                                             
                                                                                        className='btn btn-icon btn-sm btn-bg-light btn-active-color-primary ms-3'
                                                                                        onClick={() => handleRemoveFieldTimeRange(index)}                                                
                                                                                    >                                            
                                                                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon svg-icon-2x' />
                                                                                    </button>
                                                                                } 
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                            
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                    { endTimeRangeLimitReach &&
                                                    <div className="d-flex align-items-start flex-end text-danger mx-0 mt-n4 mb-4 ">
                                                        Max. end time is reached.
                                                    </div>
                                                    }
                                                    <div className="d-flex align-items-start flex-end m-0">
                                                        <button type="button"                                             
                                                            className='btn btn-sm btn-bg-light btn-active-color-primary mb-4 ps-3 tooltip-bottom border border-1'
                                                            data-tooltip-content='Add another timerange'
                                                            disabled={endTimeRangeLimitReach}
                                                            onClick={handleAddFieldTimeRange}                           
                                                        >                                            
                                                            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon svg-icon-5' />
                                                            Add
                                                        </button>
                                                        {/* <TextField 
                                                            id='rangeDuration'
                                                            value={timeRangeDur}
                                                            className='p-0 m-0'
                                                            disabled={endTimeRangeLimitReach}
                                                            InputProps={{ 
                                                                inputProps: { min: 1, max: 23 },
                                                                sx: {   border: "0px solid #eff2f5",
                                                                        borderRadius: "0 5px 5px 0",
                                                                    },
                                                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                                                            }}
                                                            onChange={(e)=> setTimeRangeDur(parseInt(e.target.value))}
                                                            fullWidth 
                                                            size='small'
                                                            margin='dense'
                                                            type="number"
                                                            variant='outlined' 
                                                            style = {{width: "75px"}}
                                                            // error={!! errors.phone_1}
                                                            // helperText={`${errors.phone_1?.message ?? ''}`}
                                                            InputLabelProps={{ shrink: true }}
                                                        /> */}
                                                    </div>
                                                </div>
                                            }

                                            {/* Repeat Section */}
                                            {/* ============== */}
                                            <div className="mb-4">                                
                                                <FormControl fullWidth>
                                                    <InputLabel id="select-repeat-label">Repeat</InputLabel>
                                                    <Select 
                                                        {...register("repeat")} 
                                                        className='bg-white rounded'
                                                        labelId="select-repeat-label"                                        
                                                        label="Repeat"
                                                        value={repeatMode}
                                                        onChange={(e) => {
                                                            setRepeatMode(e.target.value)

                                                            if (schedMode === "recurrence"){
                                                                let newVal = null
                                                                // -------------------------
                                                                switch(e.target.value.toLowerCase()){
                                                                    case "once":
                                                                        newVal = startDateTime
                                                                        break;
                                                                    case "daily":
                                                                        if (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asWeeks()  >= 1){
                                                                            newVal = endDateTime
                                                                        }else{
                                                                            newVal = startDateTime?.add(6,'month')
                                                                        }                                                                
                                                                        break;
                                                                    case "weekly": 
                                                                    case "weekday": 
                                                                    case "weekend":
                                                                        if (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asWeeks()  >= 2){
                                                                            newVal = endDateTime
                                                                        }else{
                                                                            newVal = startDateTime?.add(6,'month')
                                                                        }
                                                                        break;
                                                                    case "monthly":
                                                                        if (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asMonths()  >= 1){
                                                                            newVal = endDateTime
                                                                        }else{
                                                                            newVal = startDateTime?.add(6,'month')
                                                                        }
                                                                        break;
                                                                    case "yearly":
                                                                        if (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asYears()  >= 1){
                                                                            newVal = endDateTime
                                                                        }else{
                                                                            newVal = startDateTime?.add(2,'year')
                                                                        }
                                                                        break;
                                                                    default:
                                                                        newVal = endDateTime
                                                                }
                                                                setEndDateTime(newVal? newVal: null)
                                                                setValue("end_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                                            }
                                                        }}
                                                        error={!! errors.repeat}    
                                                        MenuProps={{ disablePortal: true }}        
                                                    >                  
                                                        <MenuItem key="0" value="0" disabled>Select repeat mode</MenuItem>                                                        
                                                        {repeatOptions
                                                        .filter((opt, ind) => {
                                                            return  (   (!opt.hiddenCases) ||
                                                                        ((opt.hiddenCases) && (opt.hiddenCases.includes("allday") && !isAllDay))                                                                        
                                                                    )
                                                        })
                                                        .map((opt, ind) => (
                                                            <MenuItem key={opt.value} value={opt.value}>
                                                                {
                                                                    {                                                                    
                                                                        "once"      : <span>{opt.label}</span>,
                                                                        "daily"     : <span>{opt.label}</span>,
                                                                        "weekly"    : <span>{opt.label} {startDateTime && `on ${moment(startDateTime.toDate()).format("dddd")}`}</span>,
                                                                        "monthly"   : <span>{opt.label} {startDateTime && `on ${moment(startDateTime.toDate()).format("Do")}`}</span>,
                                                                        "yearly"    : <span>{opt.label} {startDateTime && `on ${moment(startDateTime.toDate()).format("MMMM Do ")}`}</span>,
                                                                        "weekday"   : <span>{opt.label}</span>,
                                                                        "weekend"   : <span>{opt.label}</span>,
                                                                        "custom"    : <span>{opt.label}</span>,
                                                                        
                                                                    }[opt.value]
                                                                }                                                                    
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{`${errors.repeat?.message ?? ''}`}</FormHelperText>
                                                </FormControl>                                                                
                                            </div>

                                            {/* For custom repeatMode */}
                                            {/* {repeatMode && repeatMode === "custom" &&
                                                <div className="mt-3 mb-2 bg-white rounded p-3">
                                                    <div className=' w-100 d-flex justify-content-start align-items-center'>
                                                        <div className='me-2'>Every</div>
                                                        <div className='me-2 p-0'>
                                                            <TextField 
                                                                // {...register("nb")} 
                                                                // label="Phone"
                                                                defaultValue={1}
                                                                value={1}
                                                                fullWidth 
                                                                size='small'
                                                                margin='dense'
                                                                type="number"
                                                                variant='outlined' 
                                                                style = {{width: 75}}
                                                                // error={!! errors.phone_1}
                                                                // helperText={`${errors.phone_1?.message ?? ''}`}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </div>
                                                        <div className='w-100px mt-2'>
                                                            <FormControl fullWidth>
                                                                <Select 
                                                                    // {...register("unitOfNb")} 
                                                                    labelId="select-unit-label"                                        
                                                                    value={"day"}
                                                                    defaultValue={"day"}
                                                                    fullWidth
                                                                    size='small'
                                                                    // value={unitOfNb}
                                                                    // onChange={(e) => setRepeatMode(e.target.value)}
                                                                    // error={!! errors.unit}    
                                                                    MenuProps={{ disablePortal: true }}        
                                                                >                  
                                                                    <MenuItem key="day" value="day">day{"s"}</MenuItem>
                                                                    <MenuItem key="week" value="week">week{"s"}</MenuItem>
                                                                    <MenuItem key="month" value="month">month{"s"}</MenuItem>
                                                                    <MenuItem key="year" value="year">year{"s"}</MenuItem>                                                                                               
                                                                </Select>
                                                                <FormHelperText>{`${errors.unitOfNb?.message ?? ''}`}</FormHelperText>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className='mb-4 w-100'>
                                                        <div className='me-2'>Repeat on (month of the year): </div>
                                                        <div className='text-end me-2 p-0'>
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />                     
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />                                                                                              
                                                        </div>                                            
                                                    </div>
                                                    <div className='mb-4 w-100'>
                                                        <div className='me-2'>Repeat on (day of the month): </div>
                                                        <div className='text-end me-2 p-0'>
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter7Icon className='text-secondary' />}
                                                                checkedIcon={<Filter7Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter7Icon className='text-secondary' />}
                                                                checkedIcon={<Filter7Icon className='text-primary' />}                                                    
                                                            /> 
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter7Icon className='text-secondary' />}
                                                                checkedIcon={<Filter7Icon className='text-primary' />}                                                    
                                                            /> 
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter7Icon className='text-secondary' />}
                                                                checkedIcon={<Filter7Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter7Icon className='text-secondary' />}
                                                                checkedIcon={<Filter7Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            /> 
                                                        </div>                                            
                                                    </div>
                                                    <div className='mb-0 w-100'>
                                                        <div className='me-2'>Repeat on (day of the week): </div>
                                                        <div className='text-end me-2 p-0'>
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksOneIcon className='text-secondary' />}
                                                                checkedIcon={<LooksOneIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<LooksTwoIcon className='text-secondary' />}
                                                                checkedIcon={<LooksTwoIcon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks3Icon className='text-secondary' />}
                                                                checkedIcon={<Looks3Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks4Icon className='text-secondary' />}
                                                                checkedIcon={<Looks4Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Looks5Icon className='text-secondary' />}
                                                                checkedIcon={<Looks5Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter6Icon className='text-secondary' />}
                                                                checkedIcon={<Filter6Icon className='text-primary' />}                                                    
                                                            />
                                                            <Checkbox
                                                                {...label}
                                                                icon={<Filter7Icon className='text-secondary' />}
                                                                checkedIcon={<Filter7Icon className='text-primary' />}                                                    
                                                            />                                                
                                                        </div>                                            
                                                    </div>
                                                </div>
                                            }
                                            */}

                                            {/* EndDate Section */}
                                            {/* =============== */}
                                            {repeatMode !== "once" &&
                                                <div className="my-3">
                                                    <div className="d-flex justify-content-end form-check form-switch form-check-custom form-switch-sm form-check-solid">
                                                        <label className="form-check-label text-gray-700 me-3" htmlFor="flexSwitchNoEndDate">
                                                            No end date
                                                        </label>
                                                        <input className="form-check-input" type="checkbox" id="flexSwitchNoEndDate" value="" checked={isNoEndDate}
                                                            onChange={() => setIsNoEndDate(!isNoEndDate)}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {(!isNoEndDate) &&
                                                <div className="mb-4">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                className='w-100 p-0 bg-white rounded' 
                                                                label="End Date" 
                                                                value={endDateTime}
                                                                // value={ schedMode === "recurrence" &&
                                                                //             repeatMode === "once"   ? startDateTime
                                                                //         :   repeatMode === "daily"  ? endDateTime
                                                                //         :   repeatMode === "weekly" ? (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asWeeks()  >= 1 ? endDateTime : startDateTime?.add(1,'months'))
                                                                //         :   repeatMode === "monthly"? (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asMonths() >= 1 ? endDateTime : startDateTime?.add(6,'months'))
                                                                //         :   repeatMode === "yearly" ? (moment.duration(moment(endDateTime?.toDate()).diff(moment(startDateTime?.toDate()))).asYears()  >= 1 ? endDateTime : startDateTime?.add(2,'year'))
                                                                //                                     : endDateTime
                                                                //     }
                                                                // defaultValue={dayjs()} 
                                                                format='dddd, DD/MM/YYYY'
                                                                disabled={schedMode === "recurrence" && repeatMode === "once"}
                                                                // slotProps={{
                                                                //     textField: {
                                                                //     //   error: {`${errors.repeat?.message ?? ''}`}, // Bolean
                                                                //       helperText: "Note: leave it empty for infinite End Date", // String
                                                                //     },
                                                                // }}
                                                                minDate={startDateTime}
                                                                onChange={(newVal)=>{ 
                                                                    setEndDateTime(newVal)
                                                                    setValue("end_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                                                }}                                             
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            }
                                            {/* <div className="mb-4">                            
                                                <div className="" hidden={!(repeatMode !== "once")}>
                                                    <div className="mb-4 d-flex">
                                                        <TextField                                         
                                                            {...register(`cron.timeRanges`)} 
                                                            label="Time range(s)" 
                                                            fullWidth 
                                                            margin='dense'
                                                            variant='outlined' 
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!! errors.cron?.timeRanges}
                                                            helperText={`${errors.cron?.timeRanges?.message ?? ''}`}
                                                        />
                                                        <TextField 
                                                            {...register(`cron.dateRange`)} 
                                                            label="Date Range(s)" 
                                                            fullWidth 
                                                            margin='dense'
                                                            variant='outlined' 
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!! errors.cron?.dateRange}
                                                            helperText={`${errors.cron?.dateRange?.message ?? ''}`}
                                                        />
                                                        <TextField 
                                                            {...register(`cron.monthRange`)} 
                                                            label="Month Range(s)" 
                                                            fullWidth 
                                                            margin='dense'
                                                            variant='outlined' 
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!! errors.cron?.monthRange}
                                                            helperText={`${errors.cron?.monthRange?.message ?? ''}`}
                                                        />
                                                        <TextField 
                                                            {...register(`cron.dayOfWeek`)} 
                                                            label="Day Of Week" 
                                                            fullWidth 
                                                            margin='dense'
                                                            variant='outlined' 
                                                            InputLabelProps={{ shrink: true }}
                                                            error={!! errors.cron?.dayOfWeek}
                                                            helperText={`${errors.cron?.dayOfWeek?.message ?? ''}`}
                                                        />
                                                    </div>  
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>        
                                    
                                    
                                    
                                    
                                </div>
                                
                                
                                
                                

                                <div className="pt-8 text-end">                                
                                    
                                    <button type="submit" className="btn btn-primary w-100" 
                                        // data-bs-toggle="offcanvas"
                                        // data-bs-dismiss="offcanvas"
                                        // data-kt-drawer-show="false"
                                        // id='kt_drawer_crud_schedule_close' 
                                        data-kt-drawer-dismiss="true"                                        
                                        aria-label="Close"
                                        disabled={  isSubmitting || 
                                                    (contentIDs[0] === "") || //for empty content
                                                    !(isValid  && (grpIDs.length>=1 && grpIDs[0]!==""))  || //for group
                                                    ((schedMode === "recurrence") &&    //for schedule
                                                        (!isAllDay && trErrMsg!=="")
                                                    )
                                                }
                                        onClick={()=> {
                                            DrawerComponent.getInstance("kt_drawer_crud_schedule")?.toggle()
                                        }}
                                    >
                                        <i className="fa fa-save fs-3 me-1 mt-n1"></i>
                                        Save {!crudScheduleStatus.updateSchedMode? "":"Change"}                                        
                                    </button>
                                </div>
                                {/* <button type="button" 
                                    className="btn btn-icon btn-light position-absolute bottom-0 end-0 me-7 mb-8" 
                                    // data-bs-dismiss="offcanvas"
                                    // data-kt-drawer-dismiss="true"
                                    id='kt_drawer_crud_schedule_close'
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr001.svg'
                                        className='svg-icon svg-icon-1x'
                                    />
                                </button>                        */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
                      
        </>
    )
}

export {DrawerCRUDSchedule}