import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../core/Auth";
import { getSubUsers, getUserByToken, setAccess } from "../../core/_requests";
import { emptyCart } from "../../../../redux/slices/shopping/shoppingSlice";
import { useAppDispatch } from "../../../../redux/hook";
import { CustomError } from "../../../errors/components/CustomError";

export function GoogleLogin() {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)
    const {saveMaster, saveAuth, setCurrentUser} = useAuth()
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(()=>{
        let token = searchParams.get("token");
        let expires_in = searchParams.get("expires_in");
        let token_type = searchParams.get("token_type");
        if(token && expires_in && token_type)
        {
            // Check token expire and token type? maybe later
            setupAuth(token)
        }else{
            navigate('/error/500')
        }
    }, [])
    
    
    async function setupAuth(token:string) {
        try{
            setLoading(true)
            const {data: auth} = await setAccess(token)
            saveAuth(auth)
            if(auth.account_type === "master")
            {
                const {data: master } = await getSubUsers(auth.access_token)
                if(master.code === 200)
                {
                  saveMaster(master.data)
                }
            }
            const {data: user} = await getUserByToken(auth.access_token)
            setCurrentUser(user)
            dispatch(emptyCart())
            navigate(`/dashboard`);
        }catch (error: any) {
            setErrMsg(error.response.data.message)
        }
        
    }
    return (
        <>
            {errMsg !== '' &&
                <CustomError message={`${errMsg}`} />
            }

        </>
    )
}