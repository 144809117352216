/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../../../redux/hook";
import { ProgressBar } from '../../../../../../../commons/widgets/ProgressBar';
import { useChangeMediaFolderMutation, useGetAllMediasQuery, useGetAllRootFoldersQuery, useGetDeletedMediaQuery, useRestoreMediaMutation } from '../../../../../../../redux/slices/media/apiMediaSlice';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  IconButton,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@mui/material';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { setCrrGroup } from '../../../../../../../redux/slices/group/groupSlice';
import { setCurrentFolder, setCurrentFolderEmpty } from '../../../../../../../redux/slices/editor/editorSlice';
import { 
  DraggableImage,
  DraggableVideo,
} from '../../../../EditorComponents';
import { AssociativeArray, extractMediaInUse, formatBytes, secondsToHHmmss, toCapitalize } from '../../../../../../../redux/slices/content/contentModels';
import { useGetAllContentsQuery } from '../../../../../../../redux/slices/content/apiContentSlice';
import { Folder, Media, Medias, RootFolders } from '../../../../../../../redux/slices/media/mediaModels';
import moment from 'moment';
import { setMediaIDInUse } from '../../../../../../../redux/slices/content/contentSlice';
import { useParams } from 'react-router-dom';

//----------------------------------------

type Props = {
  className: string,
}

const listOfDefaultFolders = ["default"]


//----------------------------------------
export const GalleryFiles: React.FC<Props> = ({className}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  // console.log("currContentPersistor", currContentPersistor)

  const mediaIDInUse = useAppSelector(state => state.persistor._content.mediaIDInUse)
  // const [mediaIDInUse, setMediaIDInUse] = useState<AssociativeArray[]>([])
  // console.log("mediaIDInUse: ", mediaIDInUse)
  // console.log("mediaIDInUse keys: ", Object.keys(mediaIDInUse))

  const { data: allContents, isLoading: isLoadingAllContents, isFetching: isFetchingAllContents} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allContents", allContents)

  const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("rootFolders",rootFolders)
  // console.log("rootFolders in GalleryFiles:", (rootFolders? rootFolders.data.map(folder => folder.sources? folder.sources.length: 0): []).filter(Number))

  const [rootFoldersWithBin, setRootFoldersWithBin] = useState<RootFolders>({data: []})
  // console.log("rootFoldersWithBin in GalleryFiles:", (rootFoldersWithBin? rootFoldersWithBin.data.map(folder => folder.sources? folder.sources.length: 0): []).filter(Number))

  
  const { data: allMedias, isLoading, isFetching, refetch: refetchAllMedias} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
  const [allMediasWithBin, setAllMediasWithBin] = useState<Medias>({data: []})
  

  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder",currentFolder)
  
  const selMediaSrcIds = useAppSelector(state => state.editor.selectedMediaSrcIds)  
  // console.log("selMediaSrcIds",selMediaSrcIds)


  // const [defaultFolderIndex, setDefaultFolderIndex] = useState(0)
  const [defaultFolderIndex, setDefaultFolderIndex] = useState(-1)
  
  // const deletedSources : Media[] = []
  const {data: deletedSources} = useGetDeletedMediaQuery();
  // console.log("deletedSources", deletedSources)
  const [deletedSrcIds, setDeletedSrcIds] = useState<string[]>([])
  // console.log("deletedSrcIds", deletedSrcIds)

  const [restoreMedia] = useRestoreMediaMutation();
  const [changeMediaFolder] = useChangeMediaFolderMutation();



  // ---For search autocomplete
  const flatProps = {    
    options: !allMedias? [] : allMedias.data.map((option: Media) => {
      return (toCapitalize(option.name))
    })
  };
  const {id} = useParams()
  const [searchKeyword, setSearchKeyword ] = useState<string>(id? id:"")

  // -----------------------
  useEffect(() => {    
    if ((deletedSources && deletedSources.length <= 0) && 
      currentFolder.source_group_id === "bin"
    ){
      dispatch(setCurrentFolderEmpty())
    }
  },[currentFolder, deletedSources])

  // --------------------
  useEffect(() => {
    if (rootFolders){

      const totalFolder     = rootFolders.data.length
      const totalFilesInBin = deletedSources? deletedSources.length : 0

      if (totalFolder <= 1){
        const defaultFolder = rootFolders.data[0]
        dispatch(setCurrentFolder(defaultFolder))
      }

      if (totalFilesInBin >= 0){
        const binFolder : Folder = {
          group_name      : "Recently Deleted",
          source_group_id : "bin",
          sources         : deletedSources,
          created_time    : "",
          deleted_at      : null,    
          ordering        : 0,            
          user_id         : rootFolders.data[0].user_id, 
        }

        setRootFoldersWithBin({data: [  ...rootFolders.data,
                                        {...binFolder}
                                      ]
        })
      }else{
        setRootFoldersWithBin({data: [  ...rootFolders.data ]})
      }
    }
  }, [ rootFolders, deletedSources ])
  // }, [ rootFolders, allMedias, allMediasWithBin, deletedSources ])
  
  // -----------------------
  useEffect(() => {    
    if (deletedSources && deletedSources.length > 0){
      const binFiles : Media[] = deletedSources
      setDeletedSrcIds(deletedSources.map(delFile => delFile.source_id))

      setAllMediasWithBin({data: [
                                      ...(allMedias? allMedias.data : []),
                                      ...binFiles
                                    ]
      })
    }else{
      setDeletedSrcIds([])
      setAllMediasWithBin({data: [...(allMedias? allMedias.data : [])]})
    }

  },[allMedias, deletedSources])


  // --------------------
  useEffect(() => { 
 
    // setMediaIDInUse(extractMediaInUse(allContents? [...allContents] : []))  
    // include the info of currContentPersitor as well.
    dispatch(setMediaIDInUse(extractMediaInUse(allContents ? [...allContents, {
                                                                      ...currContentPersistor,
                                                                      modified_time: null,
                                                                      user_id: "",
                                                                    }] 
                                                  : [{
                                                        ...currContentPersistor,
                                                        modified_time: null,
                                                        user_id: "",
                                                      }]
                                      )))       
  }, [ allContents, allMedias, currContentPersistor, deletedSources ])

  //-----------------------------------------
  return (
    <>      
      <div 
        className='h-100 w-100 mx-0'
        style={{
          maxHeight:"100%",
          overflowX:"hidden"
          // scrollbarWidth: "thin"
        }}
      >
        <div 
          id="kt_accordion_2" 
          className="accordion accordion-icon-toggle galleryFilePanel" 
          style={{maxHeight:"100%", overflowX:"hidden"}}
        >
          {/* Search */}
          <div className="px-4 pb-4 sticky-top bg-gray-300">
            <Autocomplete
              {...flatProps}
              id="searchKeyword"
              value={searchKeyword}
              freeSolo
              selectOnFocus
              includeInputInList
              sx={{
                border: "1px solid #C4C4C4",
                borderRadius: "5px",
                padding: "5px",
                "& .MuiOutlinedInput-root": {
                    border: "2px solid #000000",
                    borderRadius: "5",
                    padding: "0"
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee"
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField {...params} 
                  placeholder='Search for file ...'
                  variant="standard" 
                  fullWidth                  
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    type: 'search',
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    border: "0"
                  }}
                />
              )}
              onChange={(event, value) => {
                setSearchKeyword(value? value.trim():"")
              }}
            />
          </div>
          
          {currentFolder.source_group_id === "bin" &&           
            <div className="alert alert-dismissible rounded bg-light-warning d-flex flex-column flex-sm-row p-5 mb-5 ms-4 me-5">              
              <div className="d-flex flex-column text-gray-400 pe-0 pe-sm-5">
                <div className='d-flex'>
                  <i className='fa fa-exclamation-triangle text-gray-500 me-2'></i>
                  <h5 className="mb-1 text-gray-500">Notice</h5>
                </div>
                <span className='fs-7'>                
                  Photos or videos will be permanently deleted from the trash after 7 days.
                </span>
              </div>
            
              <button type="button" className="btn btn-link position-absolute m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                <span className="svg-icon svg-icon-1 s">x</span>
              </button>
            </div>
          }

          { rootFoldersWithBin?.data.slice()
            .sort((a,b)=> 
              (a.source_group_id === "bin") ? 1
                                            : ((b.readonly === 1)  ? 1 
                                                                : (a.group_name.toLowerCase() > b.group_name.toLowerCase() ? 1:-1))
            )
            .filter(folder =>{
              if (searchKeyword && searchKeyword !== "" && folder.sources){
                if (folder.sources.length <= 0){
                  return false
                }else{
                  const foundFiles = folder.sources.filter(file => file.name.toLowerCase().includes(searchKeyword.toLowerCase()))
                  return foundFiles.length >0 ? true : false
                }
              }
              return true
            })            
            .map((folder, index) => {
              var mediaFiles: Media[] =  []
              // -----------------------------
              if (folder.source_group_id !== "bin"){ 
                mediaFiles = allMediasWithBin?.data.filter((file)=> {
                                    return ((file.type !== "external") && (file.source_group_id === folder.source_group_id) &&
                                            (file.status !== "3")) &&
                                            (! deletedSrcIds.includes(file.source_id)) &&
                                            ((!searchKeyword || searchKeyword === "") ||
                                             (searchKeyword && searchKeyword !== "" && file.name.toLowerCase().includes(searchKeyword.toLowerCase()))
                                            )
                                        })
              }else{  //For BIN
                mediaFiles = deletedSources
                      ? deletedSources?.filter((file)=> {
                          return ((!searchKeyword || searchKeyword === "") ||
                                  (searchKeyword && searchKeyword !== "" && file.name.toLowerCase().includes(searchKeyword.toLowerCase()))
                                  )
                              }) 
                      : []
              }
              const isCurrFolder = (folder.source_group_id === currentFolder.source_group_id)

              // console.log("folder: ", folder)
              // if (index === 0) console.log("----------------------------------")
              // console.log("folder: ", folder.group_name)
              // console.log("mediaFiles: ", mediaFiles)
              // -------------------------------
              return (
                <div className="mb-0" key={index} style={{overflow: "hidden"}}>

                  {/* List of folders             */}
                  {/* --------------------------- */}
                  { (currentFolder.group_name==="") && 
                    ((folder.source_group_id !== "bin") || (deletedSources && deletedSources.length>0)) &&
                    <div className='row px-5 item-hover-light-primary border-0'>
                      <div
                        className={`accordion-header py-1 d-flex  text-start col-9                        
                          ${isCurrFolder ? "":"collapsed"}
                          ${mediaFiles && mediaFiles.length <= 0 ? "text-muted":""}
                        `}
                        // data-bs-toggle="collapse"
                        // data-bs-target={`#kt_accordion_2_item_${index}`}                      
                        onClick={() => {
                          dispatch(setCurrentFolder(folder))
                        }}
                      > 
                        <span className="mt-1">
                          <i className={`fa ${isCurrFolder  ? "fa-folder-open text-primary"
                                                            : folder.source_group_id === "bin" ? "fa-trash text-danger":"fa-folder" 
                                            }`}></i>
                        </span>
                        { folder.readonly === 1
                          ? <span className={`my-1 ms-2 fst-italic text-capitalize`}>{folder.group_name} (System)</span>
                          : <span className={`my-1 ms-2 text-capitalize ${folder.source_group_id === "bin" ? "text-danger":""} `}>{folder.group_name}</span>
                        }
                      </div>

                      <div className='col-3 text-end'>
                        {mediaFiles && mediaFiles.length >0 &&
                          <span className="badge  badge-light fw-semibold mt-2 ms-2">{mediaFiles.length}</span>
                        }                        
                      </div>
                    </div>
                  }
                  
                  {/* Files in each folder            */}
                  {/* ------------------------------- */}
                  <div
                    id={`kt_accordion_2_item_${index}`}                    
                    className={`fs-6 collapse px-3
                      ${(folder.source_group_id === currentFolder.source_group_id) 
                        ? "show"
                        : index === defaultFolderIndex 
                          ? "show"
                          : (currentFolder.source_group_id === "" && searchKeyword !== "")
                            ? "show"
                            : ""
                      } 
                    `}
                    data-bs-parent="#kt_accordion_2"
                  > 
                    { (!mediaFiles || mediaFiles.length <= 0) &&
                    
                      <div className='d-flex flex-center'>
                        <button        
                          className={`btn tooltip-bottom ${className}`}
                          data-kt-menu-trigger="{default: 'click'}"
                          
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_upload_files'
                        >
                          <img src='/media/_status/empty-folder.webp' className='w-75 opacity-25' />
                        </button>                        
                      </div>
                    }
                    { mediaFiles && mediaFiles.length > 0 &&
                      <div 
                        className={`w-100
                          ${(searchKeyword && searchKeyword !== "" && currentFolder.group_name==="" ? "d-flex flex-start ps-8" : "")}
                        `}                        
                      >
                        <div
                          style={{ 
                            width: (searchKeyword && searchKeyword !== "" && currentFolder.group_name==="" ? "90%" : "100%")                          
                          }}
                        >
                        <ImageList 
                          sx={{ width: '100%', paddingRight: '0px' }}
                          // sx={{ width: 'auto', maxHeight: '60%', paddingRight: '0px' }}
                          // variant="woven" 
                          cols={2} 
                          gap={5}
                        >
                          { mediaFiles
                            .filter(file =>{
                              if (searchKeyword && searchKeyword !== ""){                                
                                return file.name.toLowerCase().includes(searchKeyword.toLowerCase())
                              }
                              return true
                            }) 
                            .slice().sort((a,b)=> {
                              // console.log('a', a.created_at, moment.utc(moment(a.created_at, true)).local())
                              // console.log(moment.utc(moment(a.created_at, true)).local().diff(moment.utc(moment(b.created_at, true)).local(), "seconds"))
                              return moment.utc(moment(a.created_at, true)).local().diff(moment.utc(moment(b.created_at, true)).local(), "seconds") < 0 ? 1: -1
                            })
                            .map((item, ind) => {
                              // if (currentFolder.source_group_id === "bin"){
                                // console.log("file item: ", item)
                              //   console.log("file item: ", item.deleted_at)
                              // }
                              // --------------------------
                              return ( 
                                <Fragment key={ind}>                                
                                  {
                                    {
                                      'image':  <DraggableImage 
                                                    key={`${ind}`} 
                                                    className="rounded"
                                                    item={item}
                                                    inUse={Object.keys(mediaIDInUse).includes(item.source_id)? 1: 0}
                                                    deletable = {currentFolder.readonly === 1 ? false : true}
                                                    isSelected = {selMediaSrcIds.includes(item.source_id)}
                                                    isDraggable = {!deletedSrcIds.includes(item.source_id) ? true: false}
                                                  />,   
                                      'video':  <DraggableVideo
                                                    key={`${ind}`} 
                                                    className="rounded"
                                                    item={item}
                                                    inUse={Object.keys(mediaIDInUse).includes(item.source_id)? 1: 0}
                                                    deletable = {currentFolder.readonly === 1 ? false : true}
                                                    isSelected = {selMediaSrcIds.includes(item.source_id)}
                                                    isDraggable = {!deletedSrcIds.includes(item.source_id) ? true: false}
                                                  />,
                                      'pdf':  <DraggableImage 
                                                    key={`${ind}`} 
                                                    className="rounded"
                                                    item={item}
                                                    inUse={Object.keys(mediaIDInUse).includes(item.source_id)? 1: 0}
                                                    deletable = {currentFolder.readonly === 1 ? false : true}
                                                    isSelected = {selMediaSrcIds.includes(item.source_id)}
                                                    isDraggable = {!deletedSrcIds.includes(item.source_id) ? true: false}
                                                  />,
                                    }[item.type]
                                  }
                                </Fragment>
                              )
                            })                        
                          }
                        </ImageList>
                        </div>
                      </div>
                    }
                  </div>                  
                </div> 
              )
            }     
          )}          
        </div>
      </div>
    </>
  )
}

