import {FC, useState} from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import {
  DrawerAddInstantSchedule,
  DrawerCRUDSchedule,
  DrawerViewGroupDetail,
  DrawerViewScheduleDetail,
  GanttLegend,
  GanttSchedules,
} from './ScheduleComponents'
import { useGetAllSchedulesQuery } from '../../../redux/slices/schedule/apiScheduleSlice'
import { ScheduleHeader } from '../ScheduleHeader'
import { ModalPreviewContent } from '../../content/components/ContentComponents'
import { useIntl } from 'react-intl'
import { useInView } from "react-intersection-observer";


const Index: FC = () => {
  const intl = useIntl()
  // ------------------------------
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  //----------------------------
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SCHEDULE'})}</PageTitle>

      <div className='d-flex flex-row h-100 bg-gray-200 mb-9 p-0 rounded-bottom' 
        // style={{marginLeft:"-30px", marginRight:"-30px" }}
      >
        <div className='d-flex flex-column flex-row-fluid'>
          <div className="d-flex flex-column-auto flex-start">            
            <ScheduleHeader className='w-100 h-100 py-2'/>            
          </div>
        </div>
      </div>

      {/* Buttons */}
      {/* <div className='row mt-n14 mb-5'>
        <div className='col-12 text-end'>               
          <button type="button"
            className="btn btn-primary me-5 tooltip-bottom"
            data-bs-toggle="offcanvas"
            aria-controls="staticBackdrop"
            data-bs-target="#kt_drawer_add_schedule"
            
            id="kt_drawer_add_schedule_button"
            data-kt-drawer-show="true" 
            // data-kt-drawer-target="#kt_drawer_add_schedule"
            
            data-tooltip-content="Add a new schedule."
            // disabled={errStatusSchedule}
          > 
            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3' />
            New Schedule
          </button>
        </div>
      </div> */}

      {/* List of Drawers */}
      <DrawerCRUDSchedule />
      <DrawerViewScheduleDetail />
      <DrawerViewGroupDetail />
        


      {/* Schedules */}
      <div className='row gy-5 gx-xl-8 mb-4' ref={ref}>
        <div className='col-lg-12 col-xl-12'>          
          <GanttSchedules className='' inView={inView}/>
        </div>        
        <div className='col-lg-12 col-xl-12 text-end'>          
          <GanttLegend className='w-100 d-flex justify-content-center'/>
        </div>
                
      </div>
      <ModalPreviewContent className="" id="modal-preview-content"/>
      <DrawerAddInstantSchedule />

      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                  <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
              </svg>
          </span>
      </div>
    </>
  )
}

export {Index}