/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { SyntheticEvent, useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../../app/commons/css/customGlobal.css"
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { useDeleteContentMutation, useGetAllContentsQuery, useGetAllPlaylistsQuery } from '../../../../redux/slices/content/apiContentSlice';
import { AssociativeArray, Content, ContentInput, EleInput, getRibbonType, Source, toCapitalize } from '../../../../redux/slices/content/contentModels';

import {  
  Autocomplete,
  TextField,
  InputAdornment,
  Box,
  Tab,
  Tabs,
  Badge,
  tabsClasses,
} from '@mui/material';

import { CardContent } from '../ContentComponents';
import { useGetAllSchedulesQuery } from '../../../../redux/slices/schedule/apiScheduleSlice';
import { extractContentInUse } from '../../../../redux/slices/schedule/scheduleModels';
import { setContentIDInUse, setSelectedContentIds } from '../../../../redux/slices/content/contentSlice';
import { useParams } from 'react-router-dom';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import Swal from 'sweetalert2';

//----------------------------------------

type Props = {
  className: string,
  inView: boolean,
}

//----------------------------------------
const TableContentsAll: React.FC<Props> = ({className, inView}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  // const allContents: Content[] = []
  const { data: allContents, isLoading, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
  const { data: allPlaylists, isLoading: isLoadingPlaylist, isFetching: isFetchingPlaylist} = useGetAllPlaylistsQuery()  
    
  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  const selectedContentIds = useAppSelector(state => state.content.selectedContentIds)
  // console.log("selectedContentIds:",selectedContentIds)

  // -------------------------------------------------
  
  const flatProps = {    
    options: !allContents? [] : allContents.map((option) => {
      return (toCapitalize(option.user_template_name))
    })
  };
  const {id} = useParams()
  const [searchKeyword, setSearchKeyword ] = useState<string>(id? id:"")
  // console.log("searchKeyword:",searchKeyword)
  // -------------------------------------------------
  
  const [filteredContents,  setFilteredContents]  = useState<Content[]>([])
  const [landscapeContents, setLandscapeContents] = useState<Content[]>([])
  const [portraitContents,  setPortraitContents]  = useState<Content[]>([])
  const [activeContents, setActiveContents] = useState<Content[]>([])
  const [emergContents,  setEmergContents]  = useState<Content[]>([])
  const [allInEmergContents,  setAllInEmergContents]  = useState<Content[]>([])
    
  const { data: allSchedules} = useGetAllSchedulesQuery(5, { refetchOnMountOrArgChange: true }) 
  const currActiveSchedIds = useAppSelector(state => state.persistor._schedule.currActiveSchedIds) // for just pure a list of all sched
  const contentIDInUse : AssociativeArray[] = useAppSelector(state => state.persistor._content.contentIDInUse) // for just pure a list of all sched
  
  const [selectOpt, setSelectOpt]  = useState<boolean>(false) //activate the multi-select options
  // console.log("selectOpt", selectOpt)
  
  //---------------------------------------------------- 
  const [tabValue, setTabValue] = useState("tab_panel_all"); 
  const handleChange = (event: SyntheticEvent<Element, Event>, newTabValue: any) => {
      setTabValue(newTabValue); 
  };  

  // ---------Delete all selected campaigns--------------
  const [deleteContent] = useDeleteContentMutation();
  const deleteContentsHandler = async () => {  
    const selectedContents: Content[] = allContents? allContents.filter(cont=> (cont.user_template_id && selectedContentIds.includes(cont.user_template_id))) : []
    var tmpStr = ""
    // ----------------------
    tmpStr = `Are you sure you want to delete<br/> all these ${selectedContents.length} contents?<br/>`
    tmpStr += '<h3 style="color:#331168; margin: 15px 0;">'
    tmpStr += (selectedContents.map(cont => toCapitalize(cont.user_template_name))).join("<br/>")
    tmpStr += '</h3>'

    Swal.fire({
        title: tmpStr,  
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#9C258F',
        cancelButtonColor: '#E4E6EF',
        confirmButtonText: 'Yes, delete all!',
        customClass: {          
          cancelButton: 'order-1 left-gap me-5',
          confirmButton: 'order-2',
        }
    }).then(async (result) => {
        if (result.isConfirmed) {

          selectedContents.map(cont => {
          
            if(cont.user_template_id) {
              
              //1. Delete from its related playlist
              // -----------------------------------------------                    
              const foundPlists =   allPlaylists?.filter((playlist) => {
                                              const foundSrc = playlist?.elements[0].sources.find((src)=> src.param_source[0].campaign_id === cont.user_template_id)
                                              return foundSrc? true : false
                                          })
              if(foundPlists){
                  foundPlists.forEach((foundPlaylist)=>{
                      // Filter the unique campaign_ids from each playlist
                      const arrCampIds = foundPlaylist.elements[0].sources.map(src => src.param_source[0].campaign_id)
                      let arrUniqeCampIds: string[] = []
                      arrCampIds.forEach((val, ind) => {
                          if (val && (!arrUniqeCampIds.includes(val))){
                              arrUniqeCampIds.push(val)
                          } 
                      })

                      // If the playlist contains only one campaign, then delete the playlist
                      if (arrUniqeCampIds.length === 1){
                        if (foundPlaylist.user_template_id){
                          deleteContent(foundPlaylist.user_template_id)
                        }
                      }else{
                        let playlistDuration = 0
                        const newEleList : EleInput[] = []
                        const playListSource : Source[] = []
                        // -------------------------------

                        foundPlaylist?.elements[0].sources.forEach((src, i) => {
                            if (src.param_source[0].campaign_id !== cont.user_template_id){
                                const srcDuration = src.param_source[0].duration?src.param_source[0].duration:0
                                playlistDuration +=  srcDuration
                                playListSource.push({
                                    duration_override   : srcDuration,
                                    element_id: "",
                                    source_list_id: "",
                                    order: 1,
                                    source: null,
                                    source_id: "",
                                    
                                    type: "campaign",
                                    
                                    param_source: [{
                                        source_id: "",
                                        type: "campaign",
                                        campaign_id     : src.param_source[0].campaign_id,
                                        campaign_name   : src.param_source[0].campaign_name,
                                        duration        : src.param_source[0].duration,
                                        loop: 1
                                    }]
                                })
                            }
                        })

                        try{                            
                          newEleList.push({
                              element_id  : foundPlaylist.elements[0].element_id,
                              position_id : foundPlaylist.elements[0].position_id,
                              alias       : foundPlaylist.elements[0].alias,                          
                              element_setting : '{"transition_timer":null}',
                              type            : "campaign",
                              source_ids      : JSON.stringify(playListSource),
                          })
                          const newPlaylist :ContentInput = {
                              contentId: foundPlaylist.user_template_id? foundPlaylist.user_template_id:"",
                              user_template: {
                                  template_id : foundPlaylist.template_id? foundPlaylist.template_id:"",
                                  user_template_name: foundPlaylist.user_template_name, 
                                  type: "playlist",   
                                  duration: playlistDuration
                              },                    
                              elements: newEleList,
                              // emergency: foundPlaylist.emergency? foundPlaylist.emergency : 0
                          }

                          const res = updateContent(newPlaylist)
                          // console.log("res: ", res)

                        }catch(error){
                          Swal.fire({
                              icon: 'error',
                              title:  'Unable to update the found playlist.<br/>', 
                              showConfirmButton: false,                
                              timer: 1000                
                          })
                        }
                      }
                  })
              }

              //2. Delete the current campaign based on its id
              // -----------------------------------------------
              deleteContent(cont.user_template_id)
            }
          })

          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,
          })
          Toast.fire({
              icon: 'success',
              title: `All the selected contents<br/> are successfully deleted.`,
          })
        }
    })
  }


  // ---------------------------------------
  useEffect(() => { 
    if (inView){
      dispatch(setContentIDInUse(extractContentInUse(allSchedules? allSchedules:[], currActiveSchedIds)))
    }
  }, [ allSchedules, inView ])

  // ---------------------------------------
  useEffect(() => { 
    if (inView){
      dispatch(setSelectedContentIds([]))
      setSelectOpt(false)
    }
  }, [ allContents, inView ])
  
  // --------------------------
  useEffect(() => { 
    if (inView && allContents){
      setFilteredContents(allContents.filter((cont, ind)=>  { return  (cont.emergency === 0) && ((searchKeyword === "") || (cont.user_template_name && cont.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase()))) }))
      setLandscapeContents(allContents.filter((cont, ind)=> { return  (cont.emergency === 0) && (cont.width >= cont.height) && ((searchKeyword === "") || (cont.user_template_name && cont.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())))}))
      setPortraitContents(allContents.filter((cont, ind)=>  { return  (cont.emergency === 0) && (cont.width <  cont.height) && ((searchKeyword === "") || (cont.user_template_name && cont.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())))}))
      setActiveContents(allContents.filter((cont, ind)=>    { return  (cont.emergency === 0) && ((cont.user_template_id && Object.keys(contentIDInUse).includes(cont.user_template_id))) && ((searchKeyword === "") || (cont.user_template_name && cont.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())))}))
      setEmergContents(allContents.filter((cont,ind)=>  { return  (cont.emergency !== 0) && ((searchKeyword === "") || (cont.user_template_name && cont.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())))} ))      
      setAllInEmergContents(allContents.filter((cont,ind)=>  { return  ((searchKeyword === "") || (cont.user_template_name && cont.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())))} ))
    }
  }, [ allContents, searchKeyword, contentIDInUse, inView ])

  // ---------------------------------------
  const getSettings = (arrContents: Content[]) =>{
    const arrLen = arrContents?arrContents.length : 0

    const settings = {
      // fade: true,
      // centerMode: true,
      // className: "center",
      // centerPadding: "0px",
      dots: true,
      arrows: true,      
      autoplay: false,
      // infinite: true,      
      slidesToShow: arrLen<6? arrLen: 6,      
      slidesToScroll: arrLen<6? arrLen: 6,
      rows: arrLen<12? 1:2,
      // slidesPerRow: 6,    
      // initialSlide: 0,      
      speed: 500,
      // cssEase: "linear",
      pauseOnHover: true,   
      
      customPaging: function(i:number) {
          return  <div 
                    className="badge badge-light border border-0 p-2 px-3"
                  >
                    {i+1}
                  </div>
                  
      },
      // appendDots: function(dots: string) {
      //   return (
      //   <ul className="slick-dots" style={{ top: "20%", right: "-100%"}}>
      //   {dots.map((item, index) => {
      //   return (
      //   <li key={index} style={{ display: "list-item", paddingBottom: "2%"}}>{item.props.children}
      //   );
      //   })}
        
      //   )
      // },       
      // appendDots: function(dots: string) {
      //   return      
      //           <div
      //             style={{
      //               backgroundColor: "#ddd",
      //               borderRadius: "10px",
      //               padding: "10px"
      //             }}
      //           >
      //             <ul style={{ margin: "0px" }}> {dots} </ul>
      //           </div>
      // },
      
      responsive: [
        // {
        //   breakpoint: 1550,  //to reach all the max screen size
        //   settings: arrLen < 6 
        //     ? {
        //         unslick: true,
        //         slidesToShow: arrLen,
        //         slidesToScroll: 1,
        //         rows: 1,
        //       }
        //     : {            
        //         slidesToShow: 6,
        //         slidesToScroll: 6,
        //       }
        // },
        {
          breakpoint: 1400,
          settings: arrLen < 4 
            ? {
                unslick: true,
                slidesToShow: arrLen,
                rows: 1,
                // slidesToShow: 1, 
                // slidesToScroll: 1
              }
            : {            
                slidesToShow: 4,
                slidesToScroll: 4,
              }
        },
        {
          breakpoint: 1200,
          settings: arrLen < 4 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 4,
                slidesToScroll: 4,
              }
        },
        {
          breakpoint: 1024,
          settings: arrLen < 3 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 3,
                slidesToScroll: 1,
              }
        },
        {
          breakpoint: 768,
          settings: arrLen < 2 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            
        },
        {
          breakpoint: 480,
          settings: arrLen < 1 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 1,
                slidesToScroll: 1,
              }
        }
      ]
    }

    return settings
  }


  
 
  //-----------------------------------------
  return (
    <>              
    {inView &&
      <div className={`card ${className}`}>        
        <div className='w-100 h-50px d-flex justify-content-between align-items-center mt-8 px-10'>
          <div className='w-100 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50 w-xxl-450px'>
            <Autocomplete
              {...flatProps}
              id="searchKeyword"
              value={searchKeyword}
              freeSolo
              selectOnFocus
              includeInputInList
              sx={{
                border: "1px solid #C4C4C4",
                borderRadius: "5px",
                padding: "5px",
                "& .MuiOutlinedInput-root": {
                    border: "2px solid #000000",
                    borderRadius: "5",
                    padding: "0"
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee"
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField {...params} 
                  placeholder='Search for campaign ...'
                  variant="standard" 
                  fullWidth                  
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    type: 'search',
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    border: "0"
                  }}
                />
              )}
              onChange={(event, value) => setSearchKeyword(value? value:"")}
            />
          </div>
          <div className='w-25 text-end ms-2'>
            <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
              <button type='button' 
                className={`d-flex btn ${selectOpt ?"btn-primary":"btn-secondary"} btn-sm py-4 py-xl-2 ps-4 pe-3 tooltip-bottom`}
                data-tooltip-content= { selectOpt && selectedContentIds
                                          ? selectedContentIds.length>0 
                                            ? "Click to unselect all" 
                                            : "Click to unselect" 
                                          : "Click to select"
                                      }
                onClick={()=> {
                  if (selectOpt){ dispatch(setSelectedContentIds([])) } //reset
                  setSelectOpt(!selectOpt)                  
                }}
              >
                <i className='fa fa-check mt-1'></i>
                <span className='d-none d-xl-flex'>
                  {selectedContentIds && selectedContentIds.length>0 ? `${selectedContentIds.length} Selected` : "Select"}
                </span>
              </button>
              {/* <button type='button' className={`d-flex btn btn-secondary btn-sm py-4 py-xl-2 ps-4 pe-3 tooltip-bottom`}
                data-tooltip-content='Delete'
                disabled={!(selectedContentIds && selectedContentIds.length > 0)}
                onClick={()=> deleteContentsHandler()}
              >
                <i className='fa fa-trash mt-1'></i>                
                <span className='d-none d-xl-flex'>
                  Delete
                </span>
              </button> */}

              <button type="button" className="btn btn-secondary rounded-end me-2 p-0 ps-3 pe-2" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-three-dots "></i>
              </button>              
              <ul className="dropdown-menu w-200px shadow-sm mt-0 mb-n4 p-5 py-5 " 
                style={{zIndex: "3050"}}
              >
                {selectedContentIds && selectedContentIds.length <= 0 &&
                  <div className="mb-4 text-warning">
                    Please select campaign(s)!
                    <div className='separator my-4 opacity-75'></div>
                  </div>                
                }
                <div className="mb-2">
                  <button className='w-100 d-flex justify-content-start btn btn-sm btn-link text-hover-primary p-0'
                    type='button'
                    disabled={!(selectedContentIds && selectedContentIds.length > 0)}
                    onClick={()=> deleteContentsHandler()}
                  >
                    <i className='bi bi-trash fs-4 w-20px me-1'></i>
                    <span>Delete all</span>
                  </button>           
                </div>     
                <div className="mb-0">
                  <button className='w-100 d-flex justify-content-start btn btn-sm btn-link text-hover-primary p-0'
                    type='button'
                    disabled={!(selectedContentIds && selectedContentIds.length > 0)}
                    // onClick={()=> deleteContentsHandler()}
                    data-bs-toggle="offcanvas"
                    aria-controls="staticBackdrop"
                    data-bs-target="#kt_drawer_add_playlist"
                  >
                    <i className='bi bi-plus-circle fs-4 w-20px me-1'></i>
                    <span>Add to new playlist</span>
                  </button>
                </div>                
              </ul>              
            </div>
          </div>
        </div>
        <div className='card-body pt-5 pb-8'>
          <TabContext value={tabValue}> 
            <Box sx={{ borderBottom: 1, borderColor: "#E4E6EF" }}>               
              <Tabs 
                onChange={handleChange}
                value={tabValue}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                selectionFollowsFocus
                style={{padding: "0"}}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              > 
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "75px"}}   disabled={!(filteredContents && filteredContents.length>0)}   label={<Badge badgeContent={filteredContents?.length} color="secondary" className='opacity-75'><span className='me-3'>All</span></Badge>}         value="tab_panel_all" /> 
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "125px"}}  disabled={!(landscapeContents && landscapeContents.length>0)} label={<Badge badgeContent={landscapeContents?.length} color="secondary" className='opacity-75'><span className='me-3'>Landscape</span></Badge>}  value="tab_panel_landscape" /> 
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "100px"}}  disabled={!(portraitContents && portraitContents.length>0)}   label={<Badge badgeContent={portraitContents?.length} color="secondary" className='opacity-75'><span className='me-3'>Portrait</span></Badge>}    value="tab_panel_portrait" />
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "75px"}}   disabled={!(activeContents && activeContents.length>0)}       label={<Badge badgeContent={activeContents?.length} color="secondary" className='opacity-75'><span className='me-3'>Active</span></Badge>}         value="tab_panel_active" />
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "125px"}}  label={<Badge badgeContent={emergContents?.length} color="secondary" className='opacity-75'><span className='me-3'>Emergency</span></Badge>}      value="tab_panel_emergency" />
              </Tabs> 
            </Box> 
            <TabPanel value="tab_panel_all" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { filteredContents && 
                  filteredContents.length >= 12                     
                    ? <Slider {...getSettings(filteredContents)}>     
                        {
                          filteredContents.slice(0).reverse().map((content)=> {

                            return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType = { getRibbonType(content) }
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}                                                                
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : filteredContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType = { getRibbonType(content) }
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }        
              </div> 
            </TabPanel>
            <TabPanel value="tab_panel_landscape" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { landscapeContents && 
                  landscapeContents.length >= 12                     
                    ? <Slider {...getSettings(landscapeContents)}>     
                        {
                          landscapeContents.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType = { getRibbonType(content) }                                
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : landscapeContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType = { getRibbonType(content) }
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }  
                
              </div> 
            </TabPanel>
            <TabPanel value="tab_panel_portrait" className='p-1 py-5'>
              <div className="row w-100 pb-5">                
                { portraitContents && 
                  portraitContents.length >= 12                     
                    ? <Slider {...getSettings(portraitContents)}>     
                        {
                          portraitContents.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType = { getRibbonType(content) }
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : portraitContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType = { getRibbonType(content) }        
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }                 
              </div>
            </TabPanel>
            <TabPanel value="tab_panel_active" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { activeContents && 
                  activeContents.length >= 12                     
                    ? <Slider {...getSettings(activeContents)}>     
                        {
                          activeContents.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType = { getRibbonType(content) }
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}                                
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : activeContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType = { getRibbonType(content) }
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }        
              </div> 
            </TabPanel>
            <TabPanel value="tab_panel_emergency" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { allInEmergContents && 
                  allInEmergContents.length >= 12                     
                    ? <Slider {...getSettings(allInEmergContents)}>     
                        {
                          allInEmergContents.slice(0)
                          .reverse()
                          .sort((a,b)=> (a.emergency && a.emergency > 0) ? 1:-1)                          
                          .reverse()
                          .map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={false}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType = { getRibbonType(content) }                     
                                // inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}                                
                                isEmergencyTab={true}
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : allInEmergContents.slice(0)
                      .reverse()  
                      .sort((a,b)=> (a.emergency && a.emergency > 0) ? 1:-1)                       
                      .map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={false}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType = { getRibbonType(content) }
                            // inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                            isEmergencyTab={true}
                          />
                        </div>
                      )})                      
                }        
              </div>
            </TabPanel>
          </TabContext>
          {/* <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">            
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_all">
                <span className='ms-1 me-2'>All</span>
                <span className='badge bg-gray-400 me-1'>{filteredContents?.length}</span>
              </a>              
            </li>            
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_landscape">
                <span className='ms-1 me-2'>Landscape</span>
                <span className='badge bg-gray-400 me-1'>{landscapeContents?.length}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_portrait">
                <span className='ms-1 me-2'>Portrait</span>
                <span className='badge bg-gray-400 me-1'>{portraitContents?.length}</span>
              </a>
            </li> 
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_active">
                <span className='ms-1 me-2'>Active</span>
                <span className='badge bg-gray-400 me-1'>{activeContents?.length}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_emergency">
                <span className='ms-1 me-2'>Emergency</span>
                <span className='badge bg-gray-400 me-1'>{emergContents?.length}</span>
              </a>
            </li>
          </ul> */}

          {/* <div className="tab-content" id="myTabContent">
            <div  className="tab-pane fade show active" id="kt_tab_pane_all" role="tabpanel">
              <div className="w-100 row pb-5">
                { filteredContents && 
                  filteredContents.length >= 12                     
                    ? <Slider {...getSettings(filteredContents)}>     
                        {
                          filteredContents.slice(0).reverse().map((content)=> {

                            return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                ribbonType = { getRibbonType(content) }
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}                                
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : filteredContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            ribbonType = { getRibbonType(content) }
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }        
              </div>  
            </div>

            <div  className="tab-pane fade show" id="kt_tab_pane_landscape" role="tabpanel">
              <div className="w-100 row pb-5">
                { landscapeContents && 
                  landscapeContents.length >= 12                     
                    ? <Slider {...getSettings(landscapeContents)}>     
                        {
                          landscapeContents.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                ribbonType = { getRibbonType(content) }                                
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : landscapeContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            ribbonType = { getRibbonType(content) }
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }  
                
              </div>  
            </div>     

            <div  className="tab-pane fade show" id="kt_tab_pane_portrait" role="tabpanel">
              <div className="row w-100 pb-5">                
                { portraitContents && 
                  portraitContents.length >= 12                     
                    ? <Slider {...getSettings(portraitContents)}>     
                        {
                          portraitContents.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                ribbonType = { getRibbonType(content) }
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : portraitContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            ribbonType = { getRibbonType(content) }        
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }                 
              </div>  
            </div>

            <div  className="tab-pane fade" id="kt_tab_pane_active" role="tabpanel">
              <div className="w-100 row pb-5">
                { activeContents && 
                  activeContents.length >= 12                     
                    ? <Slider {...getSettings(activeContents)}>     
                        {
                          activeContents.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                ribbonType = { getRibbonType(content) }
                                inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}                                
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : activeContents.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            ribbonType = { getRibbonType(content) }
                            inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }        
              </div>  
            </div>

            <div  className="tab-pane fade" id="kt_tab_pane_emergency" role="tabpanel">
              <div className="w-100 row pb-5">
                { allInEmergContents && 
                  allInEmergContents.length >= 12                     
                    ? <Slider {...getSettings(allInEmergContents)}>     
                        {
                          allInEmergContents.slice(0)
                          .reverse()
                          .sort((a,b)=> (a.emergency && a.emergency > 0) ? 1:-1)                          
                          .reverse()
                          .map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={false}
                                actionIcons={true}
                                ribbonType = { getRibbonType(content) }                     
                                // inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}                                
                                isEmergencyTab={true}
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : allInEmergContents.slice(0)
                      .reverse()  
                      .sort((a,b)=> (a.emergency && a.emergency > 0) ? 1:-1)                       
                      .map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={false}
                            actionIcons={true}
                            ribbonType = { getRibbonType(content) }
                            // inUse={Object.keys(contentIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                            isEmergencyTab={true}
                          />
                        </div>
                      )})                      
                }        
              </div>  
            </div>
                   
          </div> */}
        </div>
      </div>
    }  
    </>
  )
}

export {TableContentsAll}
function updateContent(newPlaylist: ContentInput) {
  throw new Error('Function not implemented.');
}

