import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {LocationWrapper} from '../pages/location/LocationWrapper'
// import {DeviceWrapper} from '../pages/device/DeviceWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'
import AdvanceDevicePage from '../modules/device/advance/AdvanceDevicePage'
import SubAccountPage from '../modules/subaccount/SubAccountPage'

const PrivateRoutes = () => {  
  const { auth } = useAuth()

  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))  
  const ResellerPage = lazy(() => import('../modules/reseller/ResellerPage'))
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardPage'))
  const GroupPage = lazy(() => import('../modules/group/GroupPage'))
  // const DevicePage = lazy(() => import('../modules/device/DevicePage'))
  const GalleryPage = lazy(() => import('../modules/gallery/GalleryPage'))
  const ReportPage = lazy(() => import('../modules/report/ReportPage'))
  const LicensePage = lazy(() => import('../modules/license/LicensePage'))
  const MediaPage = lazy(() => import('../modules/media/MediaPage'))
  const ContentPage = lazy(() => import('../modules/content/ContentPage'))
  const EditorPage = lazy(() => import('../modules/editor/EditorPage'))
  const DocumentPage = lazy(() => import('../modules/doc/DocumentPage'))


  const PlaylistPage = lazy(() => import('../modules/playlist/PlaylistPage'))
  const SchedulePage = lazy(() => import('../modules/schedule/SchedulePage'))
  const ShoppingPage = lazy(() => import('../modules/shopping/ShoppingPage'))

  const TicketPage = lazy(() => import('../modules/ticket/TicketPage'))
  const TestPage = lazy(() => import('../modules/test/TestPage'))  
  return (
    <Routes>
      
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='device' element={<DeviceWrapper />} /> */}
        {/* <Route path='location' element={<LocationWrapper />} />         */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        
        {/* Lazy Modules */}        
        <Route
          path='/dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />

        <Route
          path='/editor/*'
          element={
            <SuspensedView>
              <EditorPage />
            </SuspensedView>
          }
        />
        

        <Route
          path='/group/*'
          element={
            <SuspensedView>
              <GroupPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/device/*'
          element={
            <SuspensedView>
              <DevicePage />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='/media/*'
          element={
            <SuspensedView>
              <MediaPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='/gallery/*'
          element={
            <SuspensedView>
              <GalleryPage />
            </SuspensedView>
          }
        />

        <Route
          path='/content/*'
          element={
            <SuspensedView>
              <ContentPage />
            </SuspensedView>
          }
        />

        <Route
          path='/subAccount/*'
          element={
            <SuspensedView>
              <SubAccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='/resolution/*'
          element={
            <SuspensedView>
              <TicketPage />
            </SuspensedView>
          }
        />       

        <Route
          path='/playlist/*'
          element={
            <SuspensedView>
              <PlaylistPage />
            </SuspensedView>
          }
        />

        <Route
          path='/schedule/*'
          element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          }
        />
        <Route
          path='/report/*'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='/advance/device/*'
          element={
            <SuspensedView>
              <AdvanceDevicePage />
            </SuspensedView>
          }
        />
        
        <Route
          path='/reseller/*'
          element={
            <SuspensedView>
              <ResellerPage />
            </SuspensedView>
          }
        />        

        <Route
          path='/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='/license/*'
          element={
            <SuspensedView>
              <LicensePage />
            </SuspensedView>
          }
        />

        <Route
          path='/shopping/*'
          element={
            <SuspensedView>
              <ShoppingPage />
            </SuspensedView>
          }
        />

        <Route
          path='/document/*'
          element={
            <SuspensedView>
              <DocumentPage />
            </SuspensedView>
          }
        />

        {/* <Route 
          path='manage/medias/*'
          element={
            <SuspensedView>
              <MediaPage />
            </SuspensedView>
          }
        /> */}
        





        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/supertest/*'
          element={
            <SuspensedView>
              <TestPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
