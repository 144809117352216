/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { createClient } from 'pexels';
import { PEXELS_API_KEY } from '../../../../../../../redux/slices/application/pexels/pexelsModels';
import { ImageList, ImageListItem, InputAdornment, TextField } from '@mui/material';
import { Props } from '../../../../../../../redux/slices/content/contentModels';

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2';
//----------------------------------------

type Query = {
  query: string
}

const schema = yup.object().shape({
  query: yup.string().trim().required().min(1).max(100),    
})

//----------------------------------------
export const SubTabVideo: React.FC<Props> = ({className}) => {
  const client = createClient(PEXELS_API_KEY);
  const [result, setResult] = useState<any[]>([]);
  // console.log("result : ", result)
  

  const { 
    register, 
    setValue,
    handleSubmit,
    trigger,
    reset,
    watch,
    setError,
    clearErrors,
    formState:{
        errors,        
        isLoading,         
        isValid,   
        isSubmitting,
        isSubmitSuccessful
    }
  } = useForm<Query>({
      resolver: yupResolver(schema),
      defaultValues:{
        query: "",
      }
  }) 


  //---------------------------------------------------- 
  const formSubmitHandler: SubmitHandler<Query> = async (inputs ) => {
    const query = inputs.query
    // --------------------

    try{
      if (query.length > 0){
        const response:any = await client.videos.search({ query, per_page: 100 });
        // console.log("response:", response)
        if (response){
          setResult(response.videos)
        } 
      }
    }catch(error){
      console.log("error", error)
      Swal.fire(
          'There is something wrong during the search!',
          'error'
      )
    }
  }

  // ---------------------------------
  useEffect(()=>{
    const response:any = client.videos.popular({ per_page: 50 }).then((response: any) => {
      // console.log("response: ", response)
      if (response){
        setResult(response.videos)
      } 
    })
    

  },[])

  //-----------------------------------------
  return (    
      <div 
        className='h-100 w-100 mx-0'
        style={{
          maxHeight:"100%",
          overflowX:"hidden"
          // scrollbarWidth: "thin"
        }}
      >                     
          <form id="frm-edit-query" className='form' onSubmit={handleSubmit(formSubmitHandler)}
          > 
            <div className="pb-4 sticky-top bg-gray-300">                      
              <TextField 
                {...register("query")}                
                label="Search"
                size='small' 
                autoComplete='off'
                // autoCorrect='on'
                fullWidth 
                margin='dense'
                variant='outlined' 
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  // disableUnderline: true,
                  type: 'search',
                  endAdornment: (
                    <InputAdornment position="end">
                      <button type='submit' className='btn btn-primary text-hover-white btn-sm px-1'
                        style={{marginRight: "-14px", borderRadius: "0px 3px 3px 0px"}}
                      >
                        <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
                      </button> 
                    </InputAdornment>
                  )
                }}
                // onChange={(e) => {
                //     setDuration(parseInt(e.target.value)) 
                // }}              
                error={!! errors.query}
                helperText={`${errors.query?.message ?? ''}`}              
              />            
            </div>
            {/* <div className='mb-4'>
              Pexels
            </div> */}
          </form>
        <div className="mb-3">
          {result && 
            <ImageList variant="masonry" cols={2} gap={8}>
              {result.map((vdo: any) => (
                <ImageListItem key={vdo.id}>
                  
                  <video autoPlay={false} controls={true} muted 
                    className={`w-100 h-100section-outline-hover-primary border-0`}   
                    poster={vdo.video_pictures[0].picture}
                    title={vdo.alt}
                    preload="none"                  
                  >
                    <source src={`${vdo.video_files[0].link}#t=3`} type="video/mp4"/>
                  </video>
                </ImageListItem>
              ))}
            </ImageList>
          }
        </div>
      </div>
   
  )
}

