import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Sched, Schedule, ScheduleInput, ScheduleLayer } from './scheduleModels';
import { getAuthModel } from "../../auth";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
//const API_URL : string | undefined  = process.env.REACT_APP_API_URL_DEV


export const schedulesApi = createApi({
    reducerPath: "schedulesApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{
            const auth = getAuthModel()            
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['Schedule', 'Sched'],

    // global configuration for the api
    //----------------------------------------
    // keepUnusedDataFor: 30,
    refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,    


    endpoints: (builder) => ({        
        getAllSchedules : builder.query<Schedule[], number>({
            query: () => "schedule",
            providesTags: ['Schedule', 'Sched'],
            keepUnusedDataFor: 5,   // configuration for an individual endpoint, overriding the api setting            
        }), 
        getSchedule : builder.query<Sched, string>({
            query: (id) => `schedule/${id}`,
            providesTags: ['Sched']
       }),


       //-----------------------------------
       addSchedule: builder.mutation<void, ScheduleInput>({
        query: (schedule) => ({
            url: "schedule",
            method: "POST",
            body: schedule
        }),
        invalidatesTags: ['Schedule']
        }),

        // updateSchedule: builder.mutation<void, Sched>({
        updateSchedule: builder.mutation<void, ScheduleInput>({
            query: ({id, ...rest}) => ({
                url: `schedule/${id}`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Schedule']
        }),

        deleteSchedule: builder.mutation<void, string>({
            query: (id) => ({
                url: `schedule/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['Schedule']
        }),

        setScheduleLayer: builder.mutation<void, ScheduleLayer>({
            query: ({id, layer}) => ({
                url: `schedule/${id}/layer/${layer}`,
                method: "PUT"
            }),
            invalidatesTags: ['Schedule']
        }),
    })
})

export const { 
    useGetAllSchedulesQuery, 
    useGetScheduleQuery,
    useAddScheduleMutation,
    useUpdateScheduleMutation,
    useDeleteScheduleMutation,
} = schedulesApi