/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/hook'
import { setHideDashboard } from '../../../../../app/redux/slices/editor/editorSlice'
import { useNavigate } from 'react-router-dom'
import { useGetAllDevicesQuery } from '../../../../../app/redux/slices/device/apiDeviceSlice'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch();

  const hideDashboard = useAppSelector(state => state.editor.hideDashboard)
  const { data: allDevices } = useGetAllDevicesQuery(5, { refetchOnMountOrArgChange: true })


  useEffect(() => {
    if (allDevices){
      if (allDevices.length > 0) {
        dispatch(setHideDashboard(false))  
      }else{
        dispatch(setHideDashboard(true))
      }  
    }    
  }, [allDevices])

  useEffect(() => {
    /**
     * TODO: Check user permission against the page view 
     */
    if (!(currentUser && currentUser.permission)) {
      navigate('/error/403')
    }
  }, [currentUser])


  // ----------------------------------
  return (
    <>
      {hideDashboard === false &&
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          titleShort='Dashboard'
          fontIcon='bi-app-indicator text-success'
          hasMarginTop={true}
          hasMarginBottom={true}
          hasBorderBottom={true}
        />
      }

      {(currentUser && currentUser.permission && currentUser.permission['device']) &&
        <SidebarMenuItem
          to='/group'
          title='Device'
          titleShort='Device'
          icon='/media/icons/duotune/electronics/elc003.svg'
          hasMarginTop={true}
        />
      }

      {(currentUser && currentUser.permission && currentUser.permission['campaign']) &&
        <SidebarMenuItem
          to='/content'
          title='Campaigns'
          titleShort='Campaigns'
          icon='/media/icons/duotune/abstract/abs027.svg'
        />
      }

      {(currentUser && currentUser.permission && currentUser.permission['playlist']) &&
        <SidebarMenuItem
          to='/playlist/'
          title='Playlists'
          titleShort='Playlists'
          icon='/media/icons/duotune/text/txt009.svg'
          dangerBadgeClass='badge-circle'
        />
      }

      {(currentUser && currentUser.permission && currentUser.permission['schedule']) &&
        <SidebarMenuItem
          to='/schedule'
          title='Scheduler'
          titleShort='Scheduler'
          icon='/media/icons/duotune/text/txt001.svg'
          dangerBadgeClass='badge-circle'
          hasMarginBottom={true}
          hasBorderBottom={true}
        />
      }

      {(currentUser && currentUser.permission && currentUser.permission['report']) &&
        <>
          <SidebarMenuItem
            to='/report'
            title='Report'
            titleShort='Report'
            icon='/media/icons/duotune/files/fil003.svg'
            hasMarginBottom={true}
            hasBorderBottom={true}
            hasMarginTop={true}
          />
        </>
      }
      
      <div className='menu-item-space pt-5'></div>

      {(currentUser && currentUser.permission && currentUser.permission['reseller']) &&
        <SidebarMenuItemWithSub
          to='#'
          title='Partner'
          titleShort='Partner'
          icon='/media/icons/duotune/finance/fin006.svg'
          hasMarginBottom={true}
          hasBorderBottom={true}
        >
          <SidebarMenuItem
            to='/reseller/rebate'
            title='Rebate'
            titleShort='Rebate'
            icon='/media/icons/duotune/finance/fin010.svg'
          />

          <SidebarMenuItem
            to='/reseller/customer'
            title='Customer'
            titleShort='Customer'
            icon='/media/icons/duotune/communication/com005.svg'
          />
        </SidebarMenuItemWithSub>
      }

      <div className='menu-item-space pt-5'></div>
      
      {(currentUser && currentUser.permission && currentUser.permission['account']) &&
        <SidebarMenuItemWithSub
          to='#'
          title='Account'
          titleShort='Account'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen051.svg'
        >
          <SidebarMenuItem
            to='/account/profile'
            title='Profile'
            titleShort='Profile'
            icon='/media/icons/duotune/general/gen049.svg'
          />
          {
            (currentUser && currentUser.permission && currentUser.permission['license']) &&
            <SidebarMenuItem
              to='/account/license'
              title='License'
              titleShort='License'
              icon='/media/icons/duotune/general/gen026.svg'
            />
          }
          {
            (currentUser && currentUser.permission && currentUser.permission['invoice']) &&
            <SidebarMenuItem
              to='/account/invoice'
              title='Invoice'
              titleShort='Invoice'
              icon='/media/icons/duotune/general/gen005.svg'
            />
          }
          {
            (currentUser && currentUser.permission && currentUser.permission['staff']) &&
            <SidebarMenuItem
              to='/account/user'
              title='User'
              titleShort='User'
              icon='/media/icons/duotune/communication/com006.svg'
            />
          }
        </SidebarMenuItemWithSub>
      }
      {
        (currentUser && currentUser.permission && currentUser.permission['subAccount']) &&
        <SidebarMenuItem
          to='/subAccount'
          title='Sub Account'
          titleShort='Sub Account'
          icon='/media/icons/duotune/communication/com012.svg'
          dangerBadgeClass='badge-user'
          hasMarginBottom={true}
          hasBorderBottom={true}
        />
      }
      {
        (currentUser && currentUser.permission && currentUser.permission['ticket']) &&
        <SidebarMenuItem
          to='/resolution'
          title='Resolution Center'
          titleShort='Resolution'
          icon='/media/icons/duotune/communication/com012.svg'
          dangerBadgeClass='badge-circle'
          hasMarginBottom={true}
          hasBorderBottom={true}
        />
      }
      <SidebarMenuItem
        to='/document/quickstart'
        title='Quick Start Guide'
        titleShort='Quick Start'
        icon='/media/icons/duotune/general/gen046.svg'
        dangerBadgeClass='badge-circle'
        hasMarginTop={true}
      />

      <SidebarMenuItem
        to='/download'
        title='Download Player'
        titleShort='Download'
        icon='/media/icons/duotune/files/fil021.svg'
        dangerBadgeClass='badge-circle'
        hasMarginBottom={true}
      />
    </>
  )
}

export { SidebarMenuMain }
