import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'

import { Provider } from 'react-redux';
import { store } from './app/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
// import { useAppSelector } from './app/redux/hook'

// import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
// import { productsApi } from './app/modules/cake/apiSlice'
// import { registerLicense } from '@syncfusion/ej2-base';

import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { 
  createTheme, 
  // styled, 
  ThemeProvider 
} from '@mui/material/styles';
// import { purple } from '@mui/material/colors';
// import { Accordion } from '@mui/material'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { StrictMode } from 'react'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Helvetica, sans-serif',
  },
  palette: {
    primary: {
      main: "#331168",
    },
    secondary: {
      main: '#E4E6EF',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ ownerState }) => ({          
          // boxShadow: 'none', // this styles directly apply to accordion
          // border: `1px`,
          borderLeft: `1px solid #E4E6EF`,
          // borderRadius: `0px`,
          // spacing:`0px`,          
          // backgroundColor: '#f4f4f4',

          '.MuiAccordion-gutters':{
            // padding:`3px`
            // backgroundColor: '#331168'
          },
          '.MuiAccordion-root':{
            // borderRadius: `0px`,
          },
          '.MuiAccordionActions-root':{
            // borderRadius: `0px`,            
          },          
          '.MuiAccordionSummary-root': {
            backgroundColor: '#33116888',
            minHeight: "24px",
            // padding: `2px`
          }, // this apply to Summary
          '.MuiAccordionSummary-content': {            
            margin: "0px !important",
            // minHeight: "24px",
          },
          // 'MuiAccordionSummary-expandIconWrapper':{
          //   color: "#fff",
          // }
          '.MuiAccordionDetails-root': {
            margin: "0px !important",            
          },
        }),
      },
    },
  },
});

// -------------------------------------

// // Registering Syncfusion license key
// registerLicense('Mgo+DSMBaFt+QHFqVkNrWU5GfkBAXWFKblJ8RmFTel9gBShNYlxTR3ZbQFVjSH1Rc0djWn9W;Mgo+DSMBPh8sVXJ1S0d+X1RPc0BAVXxLflF1VWJTfFx6cVRWESFaRnZdQV1nS3ZTd0ZhXH1edHBd;ORg4AjUWIQA/Gnt2VFhhQlJBfVpdX2tWfFN0RnNYdV1yflBHcC0sT3RfQF5jTXxbdkRgW3pccHJVTg==;MTg2NTkyNEAzMjMxMmUzMTJlMzMzNUhqQWpubUtycFBJU2g1bTgraUhxSWZaNHUyTXlaVXpLNEw0ZEgrOXBsMVU9;MTg2NTkyNUAzMjMxMmUzMTJlMzMzNUNvd1dUNEwzMjZMdkg5ci85aGJMa0ZVbm9zd2w3RTlkM3B2aERaOEVkWDQ9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RHQmFBYVF2R2BJflRzdl9DYUwgOX1dQl9gSXpRfkViWn1bcnVRR2g=;MTg2NTkyN0AzMjMxMmUzMTJlMzMzNUxSdS9oNlFnNlJ5ZjRvaFV4cEdyWExJQUYzcEFPTytrK0FJd01sbHdSalE9;MTg2NTkyOEAzMjMxMmUzMTJlMzMzNVJwcmZxVWhiNkVIbHJjMmUwU1lMOUZDWW9tY1UwRnp2WklmcFhlNVBpenc9;Mgo+DSMBMAY9C3t2VFhhQlJBfVpdX2tWfFN0RnNYdV1yflBHcC0sT3RfQF5jTXxbdkRgW3pccnVSTg==;MTg2NTkzMEAzMjMxMmUzMTJlMzMzNVJ5ekw2VnFYeDd6c2dscGQrWXFKblFPbDlJMnpTcFN2Y0EzMzVmbm1tUTA9;MTg2NTkzMUAzMjMxMmUzMTJlMzMzNVFIbUdIY2RQQ1NXOU1ROTRSQXkrcWx4R0ErTFRmS3JlYmFxN2pINnhaUG89;MTg2NTkzMkAzMjMxMmUzMTJlMzMzNUxSdS9oNlFnNlJ5ZjRvaFV4cEdyWExJQUYzcEFPTytrK0FJd01sbHdSalE9');
// registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfVpdX2tWfFN0RnNYdV1yflBHcC0sT3RfQF5jTXxbdkRmXn1ZcHNcQQ==')


/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

let persistor = persistStore(store)


const queryClient = new QueryClient()
const container = document.getElementById('root')
if (process.env.NODE_ENV == "production")
{
  console.log = () => {}
}
  
if (container) {
  createRoot(container).render(
    <GoogleReCaptchaProvider
    reCaptchaKey={(process.env.REACT_APP_RECAPTCHA_KEY) ? process.env.REACT_APP_RECAPTCHA_KEY : '6LcY6CMpAAAAAJOVNWaS-513Ys9VznWSOF7uI5R4'}
    //language="[optional_language]"
    //useRecaptchaNet="[optional_boolean_value]"
    //useEnterprise="[optional_boolean_value]"
    
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
    container={{ // optional to render inside custom element
      element: "[required_id_or_htmlelement]",
      parameters: {
       badge: 'bottomright',//'[inline|bottomright|bottomleft]', // optional, default undefined
        theme: 'dark', // dark, light optional, default undefined
      }
    }}
  >
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        {/* <StrictMode> */}
          <Provider store={store}>

            {/* <ApiProvider api={productsApi}> */}
              <AuthProvider>
                <PersistGate loading={null} persistor={persistor}>
                  <DndProvider backend={HTML5Backend}>
                    <ThemeProvider theme={theme}>
                      <AppRoutes />
                    </ThemeProvider>
                  </DndProvider>
                </PersistGate>
              </AuthProvider>
            {/* </ApiProvider> */}

          </Provider>
        {/* </StrictMode> */}
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      
    </QueryClientProvider>
    </GoogleReCaptchaProvider>
  )
}
