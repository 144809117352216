import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  reload?: Boolean
  title: string
  titleShort: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  hasMarginTop?: boolean
  hasMarginBottom?: boolean
  hasBorderTop?: boolean
  hasBorderBottom?: boolean
  activeBadge?: string
  activeBadgeClass?: string
  inactiveBadge?: string  
  inactiveBadgeClass?: string  
  dangerBadge?: string
  dangerBadgeClass?: string
  disabled?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  reload= false,
  title,
  titleShort,
  icon,
  fontIcon,
  hasBullet = false,
  hasMarginTop= false,
  hasMarginBottom= false,
  hasBorderTop= false,
  hasBorderBottom = false,
  activeBadge,
  activeBadgeClass,
  inactiveBadge,
  inactiveBadgeClass,
  dangerBadge,
  dangerBadgeClass,
  disabled = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      {hasBorderTop && <div className="border-top-space" style={{borderTop:"1px dashed #393945"}}></div>}
      {hasMarginTop && <div className='menu-item-space pt-5'></div>}
      
      {reload === false
      ? <Link className={clsx(`menu-link without-sub`, {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (<>
            {/* <span className='menu-icon'>
              {' '}
              <KTSVG path={icon} className='svg-icon-2 icon-success' />
            </span> */}
            <div className='menu-icon-block d-flex flex-column flex-center'>
              <span className='menu-icon'>
                {' '}
                <KTSVG path={icon} className='svg-icon-2 icon-success' />
              </span>
              <div className='menu-title-bottom fs-8 fw-normal mt-0'>{titleShort}</div>
            </div>
            
            </>)}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>        
          {activeBadge && (          
            <span className={clsx("badge badge-primary ", activeBadgeClass)}>{activeBadge}</span>
          )}
          {inactiveBadge && (          
            <span className={clsx("ms-1 badge badge-light ", inactiveBadgeClass)}>{inactiveBadge}</span>
          )}
          {dangerBadge && (          
            <span className={clsx("ms-1 badge badge-danger ", dangerBadgeClass)}>{dangerBadge}</span>
          )}
        </Link>
      :
        <a className={clsx(`menu-link without-sub`, {active: isActive})} href={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (<>
            {/* <span className='menu-icon'>
              {' '}
              <KTSVG path={icon} className='svg-icon-2 icon-success' />
            </span> */}
            <div className='menu-icon-block d-flex flex-column flex-center'>
              <span className='menu-icon'>
                {' '}
                <KTSVG path={icon} className='svg-icon-2 icon-success' />
              </span>
              <div className='menu-title-bottom fs-8 fw-normal mt-0'>{titleShort}</div>
            </div>
            
            </>)}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>        
          {activeBadge && (          
            <span className={clsx("badge badge-primary ", activeBadgeClass)}>{activeBadge}</span>
          )}
          {inactiveBadge && (          
            <span className={clsx("ms-1 badge badge-light ", inactiveBadgeClass)}>{inactiveBadge}</span>
          )}
          {dangerBadge && (          
            <span className={clsx("ms-1 badge badge-danger ", dangerBadgeClass)}>{dangerBadge}</span>
          )}
        </a>
      }

      {/* <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && config.app?.aside?.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && config.app?.aside?.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
       */}
      {children}
      {hasMarginBottom && <div className='menu-item-space pb-5'></div>}
      {hasBorderBottom && <div className="border-bottom-space" style={{borderBottom:"1px dashed #393945"}}></div>}      
    </div>    
  )
}

export {SidebarMenuItem}
