/* eslint-disable react/jsx-no-target-blank */
import {FC} from 'react'
import {KTSVG} from '../../../../helpers'
import {AuthorsTab} from './AuthorsTab'
import {MenuTab} from './MenuTab'
import {NotificationsTab} from './NotificationsTab'
import {ProjectsTab} from './ProjectsTab'
import {SubscriptionsTab} from './SubscriptionsTab'
import {TasksTab} from './TasksTab'
import { BtnNewFolder, BtnUploadFiles, SelectedTab } from '../../../../../app/modules/editor/components/EditorComponents'

type Props = {
  link: string
}

// const SelectedTab: FC<Props> = ({link}) => {
//   switch (link) {
//     case 'projects':
//       return <ProjectsTab />
//     case 'menu':
//       return <MenuTab />
//     case 'subscription':
//       return <SubscriptionsTab />
//     case 'tasks':
//       return <TasksTab />
//     case 'notifications':
//       return <NotificationsTab />
//     case 'authors':
//       return <AuthorsTab />
//     default:
//       return <ProjectsTab />
//   }
// }

const TabsBase: FC<Props> = ({link}) => {
  return (
    <div className='d-flex mh-900px flex-column'>
      {/* begin::Wrapper */}
      
      <div
        // className='flex-column-fluid hover-scroll-y'
        className='flex-column-fluid'
        data-kt-scroll='auto'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_aside_wordspace'
        data-kt-scroll-dependencies='#kt_aside_secondary_footer'
        data-kt-scroll-offset='0px'
        // style={{overflowY: "auto"}}
      >
        <div className='tab-content h-100'
        style={{maxHeight:"100%", overflowY:"hidden"}}
        >
          <div
            className='tab-pane fade active show h-100'
            id={`kt_aside_nav_tab_${link}`}
            role='tabpanel'
            // style={{maxHeight:"100%", overflowY:"auto"}}
            style={{maxHeight:"100%", overflowY:"hidden"}}
          >
            <SelectedTab link={link} 
            />
            
          </div>
        </div>
      </div>

      {/* begin::Footer */}
      {/* <div className='flex-column-auto pt-0 px-5' id='kt_aside_secondary_footer'>
        <a
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          target='_blank'
          className='btn btn-bg-light btn-color-gray-600 btn-flex btn-active-color-primary flex-center w-100'
          data-bs-toggle='tooltip'
          data-bs-custom-class='tooltip-dark'
          data-bs-trigger='hover'
          data-bs-offset='0,5'
          data-bs-dismiss-='click'
        >
          <span className='btn-label'>Docs & Components</span>
          <KTSVG
            path='/media/icons/duotune/general/gen005.svg'
            className='btn-icon svg-icon-4 ms-2'
          />
        </a>
      </div> */}
      
    </div>
  )
}

export {TabsBase}
