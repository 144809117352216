/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../../../redux/hook";

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { setContentName } from '../../../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { useUpdateContentMutation } from '../../../../../../../redux/slices/content/apiContentSlice';
import { ContentInput, secondsToHHmmss } from '../../../../../../../redux/slices/content/contentModels';

import { Folder } from '../../../../../../../redux/slices/media/mediaModels';
import { useAddFolderMutation, useGetAllMediasQuery, useGetAllRootFoldersQuery } from '../../../../../../../redux/slices/media/apiMediaSlice';
import { 
    FormControl,
    // Checkbox, 
    // InputLabel, MenuItem, 
    // Select,
    TextField, 
    FormHelperText
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { isError } from 'react-query';
// import { DropdownNewFolder } from '../../../EditorComponents';
//----------------------------------------
  
  type Props = {
    className?: string,
    title?: string,
  }
  
  
  const schema = yup.object().shape({
      group_name: yup.string()
          .min(1, 'Minimum 1 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Folder name is required'),          
  })


//----------------------------------------
export const ModalNewFolder: React.FC<Props> = ({className="btn-icon btn-sm btn-active-color-primary", title}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const [addFolder] = useAddFolderMutation();

  const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("rootFolders", rootFolders)

  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder::",currentFolder)
  
  const [existFolderNames, setExistFolderNames] = useState<string[]>([])
  // console.log("existFolderNames::",existFolderNames)
  const [grpName, setGrpName] = useState<string>("")
  const [customErrs, setCustomErrs] = useState<any>(null)
  // console.log('customErrs: ', customErrs)


  const { 
      register, 
      handleSubmit,
      // control,
      reset,
      watch,
      setError,
      clearErrors,
      setValue,
      getValues,
      trigger,

      formState:{
          errors,
          isDirty,
          
          isLoading,         
          isValid,   
          isSubmitting,
          isSubmitSuccessful,          
      }
  } = useForm<Folder>({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues:{            
          group_name  : "",            
      }
  }) 
  // console.log('register', register)
  // console.log('watch([group_name])[0]:', watch(['group_name'])[0])
  // console.log('-----------------------------------------------------')
  // console.log('getValues(group_name):', getValues('group_name'))
  // console.log('isValid', isValid)
  // console.log('isSubmitting', isSubmitting)
  // console.log('errors.group_name', errors.group_name)
  // console.log('disabled: ', isSubmitting || !isValid)
  
  // ---------------------------------
  useEffect(()=>{
    setExistFolderNames(rootFolders?rootFolders.data.map((folder: Folder)=> folder.group_name.toLowerCase()):[])
  },[rootFolders])

  // --------------------------
  useEffect(()=>{
    if (existFolderNames.includes(grpName.toLowerCase())){
      // setError("group_name", { type: 'custom', message: 'This name has already exist' });
      setCustomErrs({"group_name":"This name has already exist"})
    }else{      
      // clearErrors("group_name");
      setCustomErrs(null)
    }
  },[grpName])

  // --------------------------------------------
  const formSubmitHandler: SubmitHandler<Folder> = async (folder: Folder) => {        
    if (existFolderNames.includes(folder.group_name.toLowerCase())){
      setError("group_name", { type: 'custom', message: 'This name has already exist' });
    }else{      
      clearErrors("group_name");

      try{
        const result = await addFolder(folder).unwrap();
        
        reset()
        
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-right',
          iconColor: 'green',
          customClass: {
            popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 2000,            
        })
        await Toast.fire({
            icon: 'success',
            title: "New folder: <span class='text-capitalize text-primary'>"+ folder.group_name +"</span><br/>has been successfully created.",
        }) 

      }catch(error){
        Swal.fire(
            'Folder: '+ folder.group_name,
            'There is something wrong during the creation!',
            'error'
        )          
      }
    }
      
  }  
  
  
  //-----------------------------------------
  return (<>       
    <div className={`modal fade ${className}`} tabIndex={-1} 
      id='kt_modal_new_folder'
      // style={{zIndex:"2000"}}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form id="frm-new-folder" className='form'  
            onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className="modal-header py-3 px-5 d-flex justify-content-between flex-center bg-gradient-primary">
              <h4 className="modal-title text-white px-9">Create New Folder</h4>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>               
            <div className="modal-body py-3 px-5">
              <div className='w-100 my-5 px-8'>
                            
                <div className="mb-1">
                    <TextField 
                        {...register("group_name")} 
                        label="Folder Name"
                        required
                        placeholder='Enter your folder name'
                        fullWidth 
                        autoComplete='off'
                        margin='dense'
                        variant='outlined' 
                        InputLabelProps={{ shrink: true }}
                        onChange={(e)=>{
                          setValue('group_name', e.target.value)
                          trigger("group_name") //manually trigger the form or input validation

                          setGrpName(e.target.value)
                        }}
                        error={!! errors.group_name}
                        helperText={errors.group_name?.message}
                    />
                    {customErrs !== null && 
                      <div className='text-danger fs-9 ms-4' style={{marginTop:"-2px"}}>{customErrs["group_name"]}</div>
                    }
                </div>                                  
              
              </div>
            </div>
            <div className="modal-footer py-3 px-5">
              <button
                type="button"
                className="btn btn-sm btn-light me-2"
                data-bs-dismiss="modal"                    
              >
                <i className="fa fa-times me-1"></i>
                Cancel
              </button>                
              <button type="submit" className="btn btn-sm btn-primary" 
                  // data-bs-toggle="offcanvas"
                  // data-kt-menu-dismiss="true"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  // disabled={isSubmitting || !isValid || (errors.group_name? true:false)}
                  disabled={isSubmitting || !isValid || (customErrs !== null)}
              >
                  <i className="fa fa-save me-1 mt-0"></i>
                  Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
        
  </>)
}
