/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideTabs} from './AsideTabs'
import {AsideFooter} from './AsideFooter'
import {TabsBase} from './Tabs/_TabsBase'
import { useAppSelector } from "../../../../app/redux/hook";

//-------------------------------------

const AsideDefault = () => {

  const isEditor = useAppSelector(state => state.editor.editorModeOn)
  //---------------------------------------
  const {config} = useLayout()
  const {classes} = useLayout()
  const [link, setLink] = useState<string>('template')
  
  // ---------------------------
  return (
    <div
      id='kt_aside'
      className={clsx('aside aside-extended ', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      // data-kt-drawer-overlay={`${isMobile === true ? true : false}`}
      // data-kt-drawer-overlay='true'
      data-kt-drawer-overlay='false'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'      
      // style={{zIndex:1000}}
    >
      {/* begin::Primary */}
      <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto ' style={{backgroundColor:"#1e1e2d"}}>
        <div
          className='aside-logo h-70px d-none d-lg-flex flex-column align-items-center flex-column-auto pt-8 px-7'
          id='kt_aside_logo'
          style={{borderBottom: "1px dashed #393945"}}
        >
          <Link to='/dashboard' target={"_blank"}>        
            <img
              alt='Logo'
              className='h-35px app-sidebar-logo-default ms-0 mt-n3 tooltip-bottom'
              // data-tooltip-content='Superbolt'
              src={toAbsoluteUrl('/media/logos/default-white.png')}              
              style={{marginTop:"-2px", marginLeft:"-1px"}}
            /> 
          </Link>
        </div>
        {/* there is not margin-top inside 'aside-nav' */}
        <div
          className='aside-nav d-flex flex-column align-items-center flex-column-fluid w-100'
          id='kt_aside_nav'
        >
          <AsideTabs link={link} setLink={setLink} />
        </div>        
        <AsideFooter />
      </div>

      {config.app?.aside?.secondaryDisplay && (
      <>
      <div className='aside-secondary d-flex flex-row-fluid'>
        <div className='aside-workspace my-0 py-0' id='kt_aside_wordspace'>
          
          {/* <button
            id='kt_aside_toggle'
            className={clsx(
              // 'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-relative translate-middle end-0 bottom-0 shadow-sm d-lg-flex',
              classes.asideToggle.join(' ')
            )}
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            // style={{marginBottom: '1.35rem'}}
            style={{zIndex: '1080', marginTop:'2.50rem'}}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr021.svg'
              className='svg-icon-2 rotate-180'
            />
          </button> */}
          {/* problem here */}
          <TabsBase link={link} />            
        </div>
      </div>

      <div style={{marginLeft:"-30px"}}>
        <button
          id='kt_aside_toggle'
          className={clsx(
            // 'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
            // 'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-relative translate-middle end-0 bottom-0 shadow-sm d-lg-flex',
            'btn btn-sm btn-icon bg-body btn-color-muted btn-active-color-primary w-30px h-30px position-relative translate-middle start-100 bottom-0 shadow-sm d-lg-flex',
            classes.asideToggle.join(' ')
          )}
          data-kt-toggle='true'
          data-kt-toggle-state='active'
          data-kt-toggle-target='body'
          data-kt-toggle-name='aside-minimize'
          // style={{marginBottom: '1.35rem'}}
          style={{zIndex: '1080', marginTop:'2.6rem'}}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr079.svg'
            className='svg-icon-2 rotate-180'
          />
        </button>
      </div>

      {/* <button
        id='kt_aside_toggle'
        className={clsx(
          // 'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
          'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-relative translate-middle end-0 bottom-0 shadow-sm d-lg-flex',
          classes.asideToggle.join(' ')
        )}
        data-kt-toggle='true'
        data-kt-toggle-state='active'
        data-kt-toggle-target='body'
        data-kt-toggle-name='aside-minimize'
        // style={{marginBottom: '1.35rem'}}
        style={{zIndex: '1080', marginTop:'2.50rem'}}
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr021.svg'
          className='svg-icon-2 rotate-180'
        />
      </button> */}
      </>
      )}
    </div>
  )
}

export {AsideDefault}
