/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook"
import moment from "moment"
import {   
    Tooltip
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Content, ContentInput, DEFAULT_ZOOM_PERCENTAGE, EleInput, Source, defaultContentThumbnail, emptyContentThumbnail, secondsToHHmmss, toCapitalize} from '../../../../redux/slices/content/contentModels';
import { setContent, setPlaylist, setPrevContent, setSelectedContentIds, toggleContentId } from '../../../../redux/slices/content/contentSlice';
import { useActivateEmergencyContentMutation, useDeactivateEmergencyContentMutation, useDeleteContentMutation, useDuplicateContentMutation, useGetAllPlaylistsQuery, useSetEmergencyContentMutation, useUnsetEmergencyContentMutation, useUpdateContentMutation } from '../../../../redux/slices/content/apiContentSlice';
import Swal from 'sweetalert2';
import { setZoomPercentage } from '../../../../redux/slices/editor/editorSlice';


//----------------------------------------

type Props = {
  className: string,
  content: Content,
  autoLayout? : boolean,
  displayIcon?: boolean,
  actionIcons?: boolean,
  selectOpt?: boolean,
  ribbonType?: '' | 'recent' | 'emergency' | 'activeEmergency' | 'favorite' | 'recommended' | 'popular' | 'template'
  cardType?: 'portrait' | 'landscape'
  inUse? : number
  noTitle?: boolean
  linkTitle?: boolean
  isEmergencyTab?: boolean,  
  isSelected?: boolean,
}

const ribbonIconBorderColor = {
    'recent'            : ['border-warning' , 'bi bi-stars text-white'],
    'recent active'     : ['border-success' , 'bi bi-stars text-white'],
    'favorite'          : ['border-danger'  , 'bi bi-heart-fill text-white'],
    'recommended'       : ['border-info'    , 'bi bi-hand-thumbs-up-fill text-white'],
    'popular'           : ['border-success' , 'bi bi-star text-white'],
    'template'          : ['border-primary' , 'bi bi-columns text-white'],
    'emergency'         : ['border-danger' ,  'bi bi-exclamation-triangle text-white'],
    'activeEmergency'   : ['border-danger' , 'bi bi-exclamation-triangle-fill text-warning'],
}
//----------------------------------------

// const CardContent: React.FC<Props> = ({ className, content, imgSrc, width=0, height=0, autoLayout= true, displayIcon=true, caption='', duration=0, lastUpdated='', ribbonType, cardType = 'landscape' }) => {
const CardContent: React.FC<Props> = ({ className, content, ribbonType, inUse=0, autoLayout= true, displayIcon=true, actionIcons=false, selectOpt=false, noTitle=false, linkTitle=false, isEmergencyTab=false, isSelected=false, cardType = 'landscape' }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    //-----------------------------------------
    const [updateContent] = useUpdateContentMutation();
    
    const isPlaylist: boolean = (content.type === "playlist"? true: false)
    const { data: allPlaylists, isLoading, isFetching} = useGetAllPlaylistsQuery()  
    
    // if (isSelected) console.log("isSelected: ", content.user_template_id , isSelected)

    // const { data: listContents, isLoading: isLoadingContents, isFetching: isFetchingContents} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true }) 
    // // console.log("listContents", listContents)
    // const templateLandscapePlaylist = useGetTemplateQuery(templatePlaylistID[0])
    // // console.log("templateLandscapePlaylist: ", templateLandscapePlaylist)
    // const templatePortraitPlaylist = useGetTemplateQuery(templatePlaylistID[1])
    // // console.log("templatePortraitPlaylist: ", templatePortraitPlaylist)

    const [deleteContent] = useDeleteContentMutation();
    const deleteContentHandler = async (cont: Content) => {
        Swal.fire({
            title: 'Are you sure you want to delete this content?<h1 style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, delete it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {

                    //1. Delete from its related playlist
                    // -----------------------------------------------                    
                    const foundPlists =   allPlaylists?.filter((playlist) => {
                                                    const foundSrc = playlist?.elements[0].sources.find((src)=> src.param_source[0].campaign_id === cont.user_template_id)
                                                    return foundSrc? true : false
                                                })
                    // console.log("foundPlists: ", foundPlists)                

                    if(foundPlists){
                        foundPlists.forEach((foundPlaylist)=>{
                            // Filter the unique campaign_ids from each playlist
                            const arrCampIds = foundPlaylist.elements[0].sources.map(src => src.param_source[0].campaign_id)
                            let arrUniqeCampIds: string[] = []
                            arrCampIds.forEach((val, ind) => {
                                if (val && (!arrUniqeCampIds.includes(val))){
                                    arrUniqeCampIds.push(val)
                                } 
                            })

                            // If the playlist contains only one campaign, then delete the playlist
                            if (arrUniqeCampIds.length === 1){
                                if (foundPlaylist.user_template_id){
                                    deleteContent(foundPlaylist.user_template_id)
                                }
                            }else{
                                let playlistDuration = 0
                                const newEleList : EleInput[] = []
                                const playListSource : Source[] = []
                                // -------------------------------

                                foundPlaylist?.elements[0].sources.forEach((src, i) => {
                                    if (src.param_source[0].campaign_id !== cont.user_template_id){
                                        const srcDuration = src.param_source[0].duration?src.param_source[0].duration:0
                                        playlistDuration +=  srcDuration
                                        playListSource.push({
                                            duration_override   : srcDuration,
                                            element_id: "",
                                            source_list_id: "",
                                            order: 1,
                                            source: null,
                                            source_id: "",
                                            
                                            type: "campaign",
                                            
                                            param_source: [{
                                                source_id: "",
                                                type: "campaign",
                                                campaign_id     : src.param_source[0].campaign_id,
                                                campaign_name   : src.param_source[0].campaign_name,
                                                duration        : src.param_source[0].duration,
                                                loop: 1
                                            }]
                                        })
                                    }
                                })


                                try{                            
                                    newEleList.push({
                                        element_id  : foundPlaylist.elements[0].element_id,
                                        position_id : foundPlaylist.elements[0].position_id,
                                        alias       : foundPlaylist.elements[0].alias,                          
                                        element_setting : '{"transition_timer":null}',
                                        type            : "campaign",
                                        source_ids      : JSON.stringify(playListSource),
                                    })
                                    const newPlaylist :ContentInput = {
                                        contentId: foundPlaylist.user_template_id? foundPlaylist.user_template_id:"",
                                        user_template: {
                                            template_id : foundPlaylist.template_id? foundPlaylist.template_id:"",
                                            user_template_name: foundPlaylist.user_template_name, 
                                            type: "playlist",   
                                            duration: playlistDuration
                                        },                    
                                        elements: newEleList,
                                        // emergency: foundPlaylist.emergency? foundPlaylist.emergency : 0
                                    }

                                    const res = updateContent(newPlaylist)

                                }catch(error){
                                    Swal.fire({
                                        icon: 'error',
                                        title:  'Unable to update the found playlist.<br/>', 
                                        showConfirmButton: false,                
                                        timer: 1000                
                                    })
                                }
                            }
                        })
                    }

                    //2. Delete the current campaign based on its id
                    // -----------------------------------------------
                    deleteContent(cont.user_template_id)                

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Content <span class='text-primary'>${cont.user_template_name}</span><br/> is successfully deleted.`,
                    })
                }
            }
        })        
    }

    const [setEmergencyContent] = useSetEmergencyContentMutation();
    const setEmergencyContentHandler = async (cont: Content, typ: string = "content") => {
        Swal.fire({
            title: 'Convert to Emergency Campaign?<h1 class="text-capitalize" style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            text: "Note: This emergency campaign will consume your device's memory and storage capacity!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, convert it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {

                    

                    //2. Delete the current campaign based on its id
                    // -----------------------------------------------
                    setEmergencyContent(cont.user_template_id)                

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Content <span class='text-primary'>${cont.user_template_name}</span><br/> is in Emergency group now.`,
                    })
                }
            }
        })
    }

    const [unsetEmergencyContent] = useUnsetEmergencyContentMutation();
    const unsetEmergencyContentHandler = async (cont: Content, typ: string = "content") => {
        Swal.fire({
            title: 'Revert to Normal Campaign?<h1 class="text-capitalize" style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, revert it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {

                    

                    //2. Delete the current campaign based on its id
                    // -----------------------------------------------
                    unsetEmergencyContent(cont.user_template_id)                

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Content <span class='text-primary'>${cont.user_template_name}</span><br/> is becoming a normal content now.`,
                    })
                }
            }
        })
    }

    const [activateEmergencyContent] = useActivateEmergencyContentMutation();
    const activateEmergencyContentHandler = async (cont: Content, typ: string = "content") => {
        Swal.fire({
            title: 'Activate to display this Emergency campaign?<h1 class="text-capitalize" style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            /*text: "You won't be able to revert this!",*/
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, activate it now!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {                   
                    
                    activateEmergencyContent(cont.user_template_id)                
                    
                    navigate('/schedule')    

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Emergency Content <span class='text-primary'>${cont.user_template_name}</span><br/> has been activated.`,
                    })
                }
            }
        })
    }

    const [deactivateEmergencyContent] = useDeactivateEmergencyContentMutation();
    const deactivateEmergencyContentHandler = async (cont: Content, typ: string = "content") => {
        Swal.fire({
            title: 'Are you sure you want to deactivate this Emergency Content?<h1 class="text-capitalize" style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
            /*text: "You won't be able to revert this!",*/
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9C258F',
            cancelButtonColor: '#E4E6EF',
            confirmButtonText: 'Yes, stop it!',
            customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if(cont.user_template_id) {                   
                    
                    deactivateEmergencyContent(cont.user_template_id)                

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                          popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Emergency Content <span class='text-primary'>${cont.user_template_name}</span><br/> has been deactivated.`,
                    })
                }
            }
        })
    }

    // ---Duplicate the content
    // ----------------------------------
    const [duplicateContent, resultAddContent] = useDuplicateContentMutation();  
    const duplicateContentHandler = async (content: Content, typ: string = "content") => {

        Swal.fire({
            title: `New ${typ === "playlist" ? "Playlist":"Campaign"} Name`,
            input: 'text',
            inputValue: content.user_template_name + " - copied",
            inputAttributes: {
              autocapitalize: 'off'
            },            
            showCancelButton: true,
            icon: 'question',
            confirmButtonText: '<i class="fa fa-paste text-white me-2"></i>Duplicate Now',
            showLoaderOnConfirm: true,
            preConfirm: (name) => {                  
              return name        
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                try{
                    if(content.user_template_id)
                    {
                        let data = {
                            id: content.user_template_id,
                            new_name: result.value
                        }
                        const response : any = await duplicateContent(data)
                        
                        const str = '<a  href="#" data-bs-toggle="offcanvas" aria-controls="staticBackdrop" data-bs-target="#kt_drawer_instant_play"'+        
                                    'id="kt_drawer_instant_play_button" data-kt-drawer-show="true">' +
                                    '<button class="btn tooltip-bottom btn-gradient-primary w-75" data-tooltip-content="Superbolt Now" data-bs-dismiss="modal" aria-label="Close">'+
                                    '<i class="bi bi-lightning-fill text-white fs-4 me-2"></i>Let\' Superbolt Now!'+
                                    '</button>'+
                                    '</a><br/>&nbsp;'
    
                        if (response.data){
                            Swal.fire({
                                // position: 'bottom-end',
                                icon: 'success',
                                title:  'Your content has been created.<br/><br/>'+ str,                        
                                showConfirmButton: false,
                                timer: 2000
                            })                    
                        }
                    }
                }catch(error){
                    Swal.fire(
                        'Campaign: '+ content.user_template_name,
                        'There is something wrong during the creation!',
                        'error'
                    )
                } 
            }
        })  
    }

    
    // -------------------------------------
    useEffect(() => {
        if (resultAddContent.status === "fulfilled"){
            dispatch(setContent(resultAddContent.data));
        }
    }, [resultAddContent])
    
    
    const styleStr = (content.height > content.width) && (autoLayout) 
                        ? `h-250px h-lg-200px ${(content.type && content.type === "playlist")? "w-75":"w-65"}` 
                        : 'h-200px h-lg-150px'

    // console.log("content.thumbs: ", content.thumbs)
    // console.log("content.thumbs.length: ", content.thumbs?.length)
    //-----------------------------------------
    return (
        <div className={`item-hover rounded-3 ${(content.emergency===0 && isEmergencyTab) ?"opacity-50":""}`}>
            <div 
                className={`${className} overflow-hidden position-relative card-rounded
                    ${styleStr}
                `}
            >                
                {!selectOpt                     
                    ?   (ribbonType && ribbonIconBorderColor) && 
                        <div className={`ribbon ribbon-triangle ribbon-top-start ${ribbonIconBorderColor[ribbonType][0]}`}>
                            <div className="ribbon-icon mt-n5 ms-n6">
                                <i className={`${ribbonIconBorderColor[ribbonType][1]} fs-3 tooltip-bottom`} data-tooltip-content={toCapitalize(ribbonType)}></i>
                                { content.emergency===2 && 
                                    <div className=''>                                    
                                        <div className="position-absolute top-0 start-50 spinner-border text-danger" style={{width: "3rem", height: "3rem"}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <div className="position-absolute top-0 start-50 spinner-grow text-warning" style={{width: "3rem", height: "3rem"}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    :   <div 
                            className={`ribbon ribbon-triangle ribbon-top-start cursor-pointer tooltip-bottom
                                ${isSelected ? "border-primary text-white":"border-gray-300 text-hover-primary"}
                            `}
                            data-tooltip-content={!isSelected ?"Select":"Unselect"}
                            onClick={()=> {
                                if (content.user_template_id){
                                    dispatch(toggleContentId(content.user_template_id))
                                }
                            }}
                        >
                            <div className="ribbon-icon mt-n5 ms-n5">
                                <i  className={`fa fa-check fs-4 
                                        ${isSelected ? "text-white":""}
                                    `}
                                ></i>
                            </div>
                        </div>
                }   
                      
                <div className={`ribbon ribbon-triangle ribbon-bottom-end`}>
                    <div className="ribbon-icon w-50 d-flex justify-content-end mt-n1 ms-5">
                        <span className="badge badge-dark text-gray-100 p-1">{secondsToHHmmss(content.duration)}</span>
                    </div>
                </div>   
                
                {content && 
                    <div className={`ribbon ribbon-triangle ribbon-bottom-start`}>
                        <div className={`ribbon-icon ${ content.width < content.height ? "mt-n2 ms-n7":"mt-n1 ms-n6"} `}>                        
                            <div className={`symbol symbol-30px d-flex ms-1 me-2 mt-n1 tooltip-bottom`}
                                data-tooltip-content={`${inUse>0? "Active":"Inactive"} `}
                            >
                                <KTSVG                          
                                    className={`svg-icon-2x svg-icon d-inline
                                        ${inUse>0? "text-success":"text-gray-100"} 
                                    `}
                                    path={ content.width < content.height ?  `/media/icons/duotune/electronics/elc003.svg` : `/media/icons/duotune/electronics/elc011.svg` }
                                />  
                                                    
                                {inUse>0 &&                                
                                    <span className="bullet bullet-dot bg-success h-5px w-5px position-absolute translate-middle top-50 start-50 animation-blink"></span>                            
                                }
                            </div>                         
                        </div>
                    </div>
                }
                
                <div className="card overlay overflow-hidden">
                    <div className="card-body p-0">
                        <div className="overlay-wrapper ribbon ribbon-top ribbon-vertical" 
                            // style={{
                            //     // boxShadow: "20px 20px 5px 0px #ff00ff, 30px 30px 5px 0px #ff0000"
                            //     boxShadow: "inset 20px 20px 5px 0px red, inset 50px 50px 5px 0px gold"
                            //     // boxShadow: "5px 5px blue, 10px 10px red, 15px 15px green"
                            // }}
                        >
                            <div
                                className={`w-100 border border-0 rounded ${(content.emergency===0 && isEmergencyTab) ?"opacity-50":""} ${styleStr} `}
                            >
                                <div className={``}>

                                    {   content.thumbs && content.thumbs.length > 0 && content.thumbs[0] !== ""
                                        ?   <img
                                                // className={`w-100 rounded ${(content.emergency===0 && isEmergencyTab) ?"opacity-50":""} ${ (styleStr} `}
                                                className={`w-100 rounded ${(content.emergency===0 && isEmergencyTab) ?"opacity-50":""} ${styleStr} `}
                                                src=   {`${content.thumbs[0]}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-ss-a')}`}
                                                srcSet={`${content.thumbs[0]}?w=248&fit=crop&auto=format&v=${moment().format('HH-mm-ss-a')}&dpr=2 2x`}
                                                
                                                loading="lazy"
                                                crossOrigin="anonymous" 
                                            />
                                        :   (content.elements.length > 0 && content.elements[0].sources.length > 0 )
                                            ?   (content.type? content.type !== "playlist" : true)                                
                                                // For normal content
                                                // ----------------------------
                                                ?   (content.elements[0].sources[0].source !== null) && (content.elements[0].sources[0].source?.type)
                                                        ?   (content.elements[0].sources[0].source?.type === "image") ||
                                                            (content.elements[0].sources[0].source?.type === "video")
                                                            ?   
                                                                <img
                                                                    // className={`w-100 rounded ${(content.emergency===0 && isEmergencyTab) ?"opacity-50":""} ${ (styleStr} `}
                                                                    className={`w-100 rounded ${(content.emergency===0 && isEmergencyTab) ?"opacity-50":""} ${styleStr} `}
                                                                    src={`
                                                                        ${content.elements.length > 0 
                                                                                ?   (content.elements[0].sources.length > 0     
                                                                                    ?   (content.elements[0].sources[0].source !== null 
                                                                                        ?   content.elements[0].sources[0].source.is_remote === 0 
                                                                                            ? content.elements[0].sources[0].source.url_thumb +`?w=auto&fit=crop&auto=format&v=${moment().format('HH-mm-ss-a')}`
                                                                                            : content.elements[0].sources[0].source.url +`?w=auto&fit=crop&auto=format&v=${moment().format('HH-mm-ss-a')}`
                                                                                        :   defaultContentThumbnail
                                                                                        )
                                                                                    :   defaultContentThumbnail
                                                                                    )
                                                                                :   defaultContentThumbnail
                                                                        }
                                                                    `}
                                                                    loading="lazy"  
                                                                    // src={`${content.elements.length > 0 
                                                                    //         ?   (content.elements[0].sources.length > 0     
                                                                    //             ?   (content.elements[0].sources[0].source !== null 
                                                                    //                 ?   content.elements[0].sources[0].source.is_remote === 0 
                                                                    //                     ? content.elements[0].sources[0].source.url_thumb 
                                                                    //                     : content.elements[0].sources[0].source.url
                                                                    //                 :   defaultContentThumbnail
                                                                    //                 )
                                                                    //             :   defaultContentThumbnail
                                                                    //             )
                                                                    //         :   defaultContentThumbnail
                                                                    //     }`}
                                                                />
                                                            // :   (content.elements[0].sources[0].source?.type === "video") 
                                                                // ?   <video loop autoPlay={false} controls={false} muted 
                                                                //         className={`${className} rounded w-100 bordered-0 bg-dark ${styleStr} `}                                        
                                                                //     >
                                                                //         <source src={content.elements[0].sources[0].source?.url} type="video/mp4" />
                                                                //     </video> 
                                                                // ?   
                                                                //     <img
                                                                //         className={`w-100 rounded ${ (styleStr} `}
                                                                //         src={`${content.elements.length > 0 
                                                                //                 ?   (content.elements[0].sources.length > 0     
                                                                //                     ?   (content.elements[0].sources[0].source !== null 
                                                                //                         ?   content.elements[0].sources[0].source.is_remote === 0 ? content.elements[0].sources[0].source.url_thumb : content.elements[0].sources[0].source.url
                                                                //                         :   defaultContentThumbnail
                                                                //                         )
                                                                //                     :   defaultContentThumbnail
                                                                //                     )
                                                                //                 :   defaultContentThumbnail
                                                                //             }`}
                                                                //     />
                                                            :   (content.elements[0].sources[0].source?.type === "web") 
                                                                ?   <img
                                                                        className={`w-100 rounded ${styleStr} `}
                                                                        src={`${defaultContentThumbnail}`}
                                                                    />
                                                                :   <img
                                                                        className={`w-100 rounded ${styleStr} `}
                                                                        src={`${defaultContentThumbnail}`}
                                                                    />
                                                        :   <img
                                                                className={`w-100 rounded ${styleStr} `}
                                                                src={`${defaultContentThumbnail}`}
                                                            />

                                                // For playlist
                                                // ----------------------------
                                                :   <div className={`bg-gradient-primary-o75 d-flex justify-content-center align-content-center ${styleStr} w-100`}>
                                                        {   (content.elements.length > 0 && content.elements[0].sources.length > 0 ) &&
                                                            <div className="d-flex flex-column flex-row-auto">
                                                                <div className="d-flex h-75 justify-content-center align-content-center pt-12 ms-n2">
                                                                    <div className="symbol-group symbol-hover">
                                                                        {content.elements[0].sources.map((src, ind) => {
                                                                            // ----------------------
                                                                            return (
                                                                            <Fragment key={`ct-${ind}`}>                                                                    
                                                                                { ((content.elements[0].sources.length <= 3) || (ind < 2)) &&
                                                                                    <div className="symbol symbol-circle symbol-65px">                                                                                        
                                                                                        <div className="symbol-label bg-light-primary fs-3 fw-bold text-primary text-capitalize">
                                                                                            {src.param_source[0].thumbs && src.param_source[0].thumbs.length > 0 
                                                                                                ?   <img
                                                                                                        className={`w-100 h-65px rounded-circle `}
                                                                                                        src={`${src.param_source[0].thumbs[0]}`}
                                                                                                    />
                                                                                                :   <span>
                                                                                                        {src.param_source[0].campaign_name?.substring(0,3)}
                                                                                                    </span>
                                                                                            }
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                }
                                                                                { (content.elements[0].sources.length > 3) && (ind === 2) &&
                                                                                    <div className="symbol symbol-circle symbol-65px">
                                                                                        <div className="symbol-label bg-gradient-primary-o75 fs-3 fw-bold text-white text-capitalize">
                                                                                            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3 svg-icon-light' />
                                                                                        </div>
                                                                                    </div>
                                                                                }                                                                                
                                                                            </Fragment>)
                                                                        })}                                                            
                                                                    </div>
                                                                </div>                                                
                                                                <div className="d-flex flex-column-fluid flex-center">
                                                                    <span className="text-gray-200 p-1">
                                                                        <span className='fw-semibold ms-1'>{content.elements[0].sources.length} campaigns</span>
                                                                    </span>
                                                                </div>
                                                            </div>                                                
                                                        }                                                            
                                                    </div>                      

                                            // For empty content
                                            // ----------------------------
                                            :   <img
                                                    className={`w-100 rounded ${styleStr} `}
                                                    src={`${emptyContentThumbnail}`}
                                                />
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className='w-100 h-150px bg-primary rounded' 
                        style={{
                            // boxShadow: "20px 20px 5px 0px #ff00ff, 30px 30px 5px 0px #ff0000"
                            // boxShadow: "inset 20px 20px 5px 0px red, inset 50px 50px 5px 0px gold"
                            boxShadow: "5px 5px blue, 10px 10px red, 15px 15px green"
                        }}> 

                        </div>*/}
                        <div className="overlay-layer bg-dark bg-opacity-25 d-block" >
                            <div className="text-end" style={{ "height": "20%" }}>
                                { actionIcons && actionIcons === true &&
                                    <div className="btn-group py-0 ps-0 pe-2 mx-0" role="group">
                                        {content.emergency === 0 && !isEmergencyTab &&
                                            <Tooltip arrow placement="bottom" title="Superbolt Now">                                            
                                                <a  href="#" 
                                                    className="btn btn-link text-light hover-elevate-up"
                                                    data-bs-toggle="offcanvas"
                                                    aria-controls="staticBackdrop"
                                                    data-bs-target="#kt_drawer_instant_play"
                                                    
                                                    id="kt_drawer_instant_play_button"
                                                    data-kt-drawer-show="true" 

                                                    data-tooltip-content="Add a new schedule." 
                                                    onClick={() => { dispatch(setContent(content)); }}                                            
                                                >
                                                    <i className='bi bi-lightning-fill text-light text-hover-gradient-primary fs-4 me-1' />
                                                </a>
                                            </Tooltip>
                                        }
                                        { isEmergencyTab &&
                                            // <Tooltip arrow placement="bottom" title="Emergency">                                        
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <button type="button" className="btn btn-link text-light hover-elevate-up me-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-exclamation-triangle-fill text-light text-hover-danger"></i>
                                                    </button>
                                                    
                                                    <ul className="dropdown-menu w-50px p-0 shadow-sm mt-n3 mb-n4" 
                                                        style={{zIndex: "3050"}}
                                                    >
                                                        <div className='px-5 py-1 bg-danger rounded-top text-light'>
                                                            Emergeny
                                                        </div>
                                                        <div className='px-5 py-3'> 
                                                            {content.emergency === 0  
                                                                ?   <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                                        onClick={() => { 
                                                                            if (!isPlaylist){ 
                                                                                setEmergencyContentHandler(content); 
                                                                            }else{
                                                                                setEmergencyContentHandler(content, "playlist"); 
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="bi bi bi-arrow-bar-right text-warning"></i>
                                                                        <span className='text-start'>Convert</span>
                                                                    </div>
                                                                :   (content.emergency === 1)
                                                                    ?   <>
                                                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                                                onClick={() => { 
                                                                                    if (!isPlaylist){ 
                                                                                        activateEmergencyContentHandler(content); 
                                                                                    }else{
                                                                                        activateEmergencyContentHandler(content, "playlist"); 
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                                                                                <span className='text-start text-danger'>Activate now</span>
                                                                            </div>
                                                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                                                onClick={() => { 
                                                                                    if (!isPlaylist){ 
                                                                                        unsetEmergencyContentHandler(content); 
                                                                                    }else{
                                                                                        unsetEmergencyContentHandler(content, "playlist"); 
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="bi bi-arrow-bar-left"></i>                                                        
                                                                                <span className='text-start'>Revert</span>
                                                                            </div>
                                                                        </>
                                                                    :   (content.emergency === 2) &&
                                                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                                                onClick={() => { 
                                                                                    if (!isPlaylist){ 
                                                                                        deactivateEmergencyContentHandler(content); 
                                                                                    }else{
                                                                                        deactivateEmergencyContentHandler(content, "playlist"); 
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-ban"></i>
                                                                                <span className='text-start'>Stop now</span>
                                                                            </div> 
                                                            }                                                   
                                                        </div>
                                                    </ul>
                                                </div>  
                                            // </Tooltip>
                                        }
                                        { content.emergency !== 2 && <> 
                                            <Tooltip arrow placement="bottom" title="Duplicate">
                                                <a 
                                                    href="#"
                                                    className="btn btn-link text-light hover-elevate-up"
                                                    onClick={() => { 
                                                        duplicateContentHandler(content);                                                         
                                                    }}
                                                >
                                                    <i className='fa fa-clone text-light text-hover-primary me-2'></i>
                                                </a>
                                            </Tooltip>
                                            <Tooltip arrow placement="bottom" title="Edit">
                                                {! isPlaylist 
                                                    ?   <a 
                                                            href="#"
                                                            className="btn btn-link text-light hover-elevate-up"
                                                            onClick={() => { 
                                                                if (inUse > 0){
                                                                    Swal.fire({
                                                                        title: "This campaign is currently <span class='text-success fw-bold'>Active</span>. <br/>Are you sure you want to edit it?",
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#9C258F',
                                                                        cancelButtonColor: '#E4E6EF',
                                                                        confirmButtonText: 'Yes, edit it!',
                                                                        customClass: {          
                                                                        cancelButton: 'order-2 left-gap me-5',
                                                                        confirmButton: 'order-1',
                                                                        // denyButton: 'order-3',
                                                                        }
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            dispatch(setContent(content))                                                                           
                                                                            dispatch(setPrevContent(content)); 
                                                                            dispatch(setZoomPercentage(content.width < content.height ? 1 : DEFAULT_ZOOM_PERCENTAGE))
                                                                            setTimeout(() => {
                                                                                window.location.href = "/editor/index/gallery"
                                                                            }, 1500);
                                                                        }
                                                                    })
                                                                }else{
                                                                    // console.log("----content:", content)                                                                    

                                                                    // dispatch(fetchSetContentNew(content))                                                                    
                                                                    // .unwrap()
                                                                    // .then((originalPromiseResult: any) => {
                                                                    //     console.log("originalPromiseResult", originalPromiseResult)
                                                                    //     dispatch(setPrevContent(content)); 
                                                                    //     dispatch(setZoomPercentage(content.width < content.height ? 1 : DEFAULT_ZOOM_PERCENTAGE))
                                                                    //     window.location.href = "/editor/index/gallery"
                                                                    //     // setTimeout(() => {
                                                                    //     //     window.location.href = "/editor/index/gallery"
                                                                    //     // }, 1500);
                                                                    // })
                                                                    // .catch((rejectedValueOrSerializedError: any) => {
                                                                    //     console.log("rejectedValueOrSerializedError", rejectedValueOrSerializedError)
                                                                    // })

                                                                    dispatch(setContent(content))
                                                                    dispatch(setPrevContent(content)); 
                                                                    dispatch(setZoomPercentage(content.width < content.height ? 1 : DEFAULT_ZOOM_PERCENTAGE))
                                                                    setTimeout(() => {
                                                                        window.location.href = "/editor/index/gallery"
                                                                    }, 1500);
                                                                }

                                                            }}
                                                        >
                                                            <i className='fa fa-pencil text-light text-hover-primary me-2'></i>
                                                        </a>
                                                    :   <a 
                                                            href="#"
                                                            className="btn btn-link text-light hover-elevate-up"
                                                            data-bs-toggle="offcanvas"
                                                            aria-controls="staticBackdrop"
                                                            data-bs-target="#kt_drawer_edit_playlist"
                                                            
                                                            id="kt_drawer_edit_playlist_button"
                                                            data-kt-drawer-show="true"
                                                            onClick={() => { 
                                                                dispatch(setPlaylist(content)); 
                                                                dispatch(setZoomPercentage(content.width < content.height ? 1 : DEFAULT_ZOOM_PERCENTAGE))
                                                            }}
                                                        >
                                                            <i className='fa fa-pencil text-light text-hover-primary me-2'></i>
                                                        </a>
                                                }
                                            </Tooltip>
                                            <Tooltip arrow placement="bottom" title="Delete">
                                                <a  href="#" 
                                                    className="btn btn-link text-light hover-elevate-up"
                                                    onClick={()=> deleteContentHandler(content) }
                                                >
                                                    <i className='fa fa-trash text-light text-hover-primary me-2'></i>
                                                </a>    
                                            </Tooltip>
                                        </>
                                        }
                                </div>
                                }
                            </div>

                            {/* Preview button */}
                            <div className="d-flex flex-grow-1 flex-center" style={{ height: "61%", marginLeft: "-1px" }}>
                                
                                    <a  href="#" 
                                        className="btn btn-link hover-scale"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal-preview-content"
                                        onClick={() => { 
                                        dispatch(setContent(content));
                                    }}
                                    >
                                        <i className="bi bi-play-circle-fill fs-3x text-white text-hover-gradient-primary"></i>                                    
                                    </a>
                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>

            {/* Content Info */}
            {noTitle 
                ?   <div className='mb-3'>&nbsp;</div>
                :   <div 
                        className={`overflow-hidden position-relative pt-2 px-1 d-flex
                            ${(content.height > content.width) && (autoLayout) ? "h-75px":"h-125px"}
                            `}
                        style={{minHeight:`${noTitle === false? "70px":"20px"}`}}
                    >
                        <div className="ms-2">
                            <Link      to={`${linkTitle ? "/"+ (content.type === "playlist" ? "playlist":"content") +"/index/"+ content.user_template_name : "#"}`} 
                                className={`${linkTitle ? "btn-link btn-link-hover":"pe-none"} text-gray-600 `} 
                            >                            
                                <span className="text-capitalize fs-6 fw-semibold text-truncate mb-0" style={{ 
                                    "maxWidth": "250px",                                
                                    "WebkitLineClamp": "2",
                                    "WebkitBoxOrient": "vertical",                                 
                                    "whiteSpace": "normal",
                                    "display": "-webkit-box"
                                }}
                                >
                                    {content.user_template_name}
                                </span>
                            </Link>
                            <div className="text-muted small">
                                <span>{moment.utc(content.modified_time).local().fromNow()}</span>
                            </div>
                        </div>
                    
                    </div>
            }
        </div>
    )
}

export { CardContent }
