/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useSearchParams } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import { register } from '../core/_requests'
import { Recaptcha } from '../../../commons/widgets/ReCaptcha'
import { ModalTnC } from '../../content/components/modals/ModalTnC'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  repCode: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  lastname: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  repCode: Yup.string()
    .min(6, 'Referral code is 6 characters.')
    .max(6, 'Referral code is 6 characters.'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  let referral = searchParams.get('_referral');
  if (referral !== null && referral.length === 6) {
    initialValues.repCode = referral.substring(0, 6)
  }
  const { saveAuth, setCurrentUser } = useAuth()
  const [result, setResult] = useState('');
  const [resultStatus, setResultStatus] = useState('success');
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setResult('');
      setLoading(true)
      try {
        let res, resObj
        res = await register(
          values.email,
          values.firstname,
          values.lastname,
          (referral === "") || (referral?.length !== 6) ? values.repCode : referral
        )
        resObj = Object.values(res)[0]
        if (resObj.code == 201 || resObj.code == 200) {
          setResultStatus('success')
          resetForm()
        } else {
          setResultStatus('error')
          setSubmitting(false)
        }
        setResult(resObj.message)
        setLoading(false)

      } catch (error: any) {
        saveAuth(undefined)
        setResultStatus('error')
        setResult(error.response.data.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <ModalTnC className="" />
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>

          {/* end::Title */}

        </div>
        {/* end::Heading */}

        {result != "" ? (
          <div className={`mb-lg-15 alert alert-${resultStatus == "success" ? 'success' : 'danger'}`}>
            <div className='alert-text font-weight-bold text-capitalize'><strong>{resultStatus}</strong></div><br />
            <p>{result}</p>
          </div>
        ) : (
          <>
          </>
        )}



        {/* begin::Form group Firstname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-8'>
          {/* begin::Form group Lastname */}
          <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        {(referral === "") || (referral?.length !== 6) &&
          <div className='fv-row mb-8 card'>
            <div
              className='border-0 cursor-pointer bg-gray-200'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_sale_rep'
              aria-expanded='true'
              aria-controls='kt_sale_rep'
            >
              <label className='form-label fw-bolder btn btn-link fs-6 p-0'>
                Promotion Referral Code
                <i className='fa fa-caret-right ms-2'></i>
              </label>

            </div>
            <div id='kt_sale_rep' className='collapse bg-gray-200'>
              <div className='card-body p-0'>
                <div className='fv-row'>
                  <input
                    placeholder='Enter 6 digit referral code'
                    type='text'
                    autoComplete='off'
                    maxLength={6}
                    {...formik.getFieldProps('repCode')}
                    aria-describedby="promoHelp"
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.repCode && formik.errors.repCode },
                      {
                        'is-valid': formik.touched.repCode && !formik.errors.repCode,
                      }
                    )}
                  />
                  {formik.touched.repCode && formik.errors.repCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.repCode}</span>
                      </div>
                    </div>
                  )}
                  <div id="promoHelp" className="form-text">Get rewarded extra 30 days free.</div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* end:: sale rep */}
        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input border-gray-500 text-primary opacity-75'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <span className='fw-semibold'>
              {/* I Accept the{' '} */}
              I agree to the{' '}
              <a
                href='#'
                data-bs-toggle="modal"
                data-bs-target="#superbolt-tnc"
                className='ms-1 link-primary fw-bold'
              >
                <u>Terms &amp; Conditions</u>
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-gradient-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Submit Now</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {
            <Link to='/auth/resend-activate-email'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-secondary w-100 mb-5'
              >
                Resend Activation Email
              </button>
            </Link>
          }
        </div>
        {/* end::Form group */}
        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Already have an account?{' '}
          <Link to='/auth/login' className='link-primary'>
            Login
          </Link>
        </div>

        <Recaptcha pageName='registration' />

      </form>
    </>
  )
}
