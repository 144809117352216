/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router-dom'
import { 
  HomeLayout,
  ServiceLayout,
  
  Home,
  Products,  
  Services,
  Industries,
  ContactUs,
  AboutUs,
  FAQs,
} from './LandingComponents'
import { TermsConditions } from './components/termsCond/TermsConditions'
import { PrivacyPolicy } from './components/privacyPolicy/PrivacyPolicy'
import { DownloadApp } from '../download/DownloadApp'





export const HomePage = () => (
  <Routes>
    <Route element={<HomeLayout />}>
      <Route path='home' element={<Home />} />      
      <Route path='index' element={<Home />} />
      <Route index element={<Home />} />
    </Route>
  </Routes>
)

export const AboutUsPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>      
      <Route path='about-us' element={<AboutUs />} />
      <Route index element={<AboutUs />} />      
    </Route>
  </Routes>
)


export const ProductPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>      
      <Route path='products' element={<Products />} />
      <Route index element={<Products />} />      
    </Route>
  </Routes>
)

export const ServicePage = () => (
  <Routes>
    <Route element={<HomeLayout />}>
      <Route path='services' element={<Services />} />
      <Route index element={<Services />} />
    </Route>
  </Routes>
)

export const IndustryPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>      
      <Route path='industries' element={<Industries />} />
      <Route index element={<Industries />} />      
    </Route>
  </Routes>
)

export const ContactUsPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>      
      <Route path='https://superbolt.com.au/contact' element={<ContactUs />} />
      <Route index element={<ContactUs />} />      
    </Route>
  </Routes>
)

export const DownloadAppPage = () => (
  <Routes>
    <Route element={<Outlet />}>      
      <Route path='download' element={<DownloadApp />} />
      <Route index element={<DownloadApp />} />      
    </Route>
  </Routes>
)

// -----------------------------------------------------

export const TnCPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>
      <Route path='terms-conditions' element={<TermsConditions />} />
      <Route index element={<TermsConditions />} />
    </Route>
  </Routes>
)

export const PrivacyPolicyPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>
      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      <Route index element={<PrivacyPolicy />} />
    </Route>
  </Routes>
)

export const FAQsPage = () => (
  <Routes>
    <Route element={<HomeLayout />}>
      <Route path='faqs' element={<FAQs />} />
      <Route index element={<FAQs />} />
    </Route>
  </Routes>
)