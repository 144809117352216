/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { setContentFromTemplate, setContentName } from '../../../../redux/slices/content/contentSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { useGetAllTemplatesQuery } from '../../../../redux/slices/template/apiTemplateSlice'



//----------------------------------------

type Props = {
  className: string,
}


//----------------------------------------
const PanelTop: React.FC<Props> = ({className}) => { 
  const dispatch = useAppDispatch()
  //-----------------------------------------
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
  // console.log(currPersistContent)
  
  const currEditorZoom = useAppSelector(state => state.editor.zoomPercentage)


  const { data: allTemplates, isLoading: isLoadingTemplates } = useGetAllTemplatesQuery(5, { refetchOnMountOrArgChange: true })    
  // console.log("allTemplates",allTemplates)
 /*
  const seletedTemplateIds = [
    "9MjPVbPNkO",
    "xVb501RPLo",
    "wDAYgyPlOr","1gKYqwPWX2","jnEYaE6Ylb","1pb5924P8J",
    "Q94YVLPL0v", "1gKYqwPWX2","e87PnxY6KX", 
    "pnRPd1zyo2", "xVb50RzLo7","ZgMP7xvzWn","gGkYv0qPRq",
    "NOXPXVY0B7", "V0kY1Z5mqa", "WXZ5OQzo63", "e3ZYGRz64B", 
    "NOXPXVY0B7", "V0kY1Z5mqa",
    "8EbYZMzm3L", "7dV5y2ZPy6", 
    "pnJ5Jdz6Zb", "NVkzwyy51B","maV5RRy5o6",
    "NVkzwy51Be", "b17zjgYqDM", 
    "Z785x3eYk2", "pnJ5Jxdz6Z",
    "KXmY2xWPa4",
    "gWyPNVOPJR", "VkD5l12PMn", "xVb501RPLo", "Mgoz33vzXx"
    //  "", "", "", "", "", "", "", "", "", "", "", "", "",
    // "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""
  ]
  */
  const templatesLandscapes = allTemplates?.filter((tmp)=> {return (tmp.width >= tmp.height)})
  const templatesPortraits  = allTemplates?.filter((tmp)=> {return (tmp.width < tmp.height)})

  // const templatesLandscapes = allTemplates?.filter((tmp)=> {return tmp.width >= tmp.height})
  // const templatesPortraits  = allTemplates?.filter((tmp)=> {return tmp.width < tmp.height })


  // -------------------
  return (
    <>
      {/* <div className={`${className} d-flex`}> */}
      <div className={`${className} d-none`}>
        {/* Top-Left Toolbar */}
        <div className="w-50"> 

          <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
            <div className="btn-group" role="group">
              <button 
                className={`btn btn-small w-100px tooltip-bottom ${currPersistContent.width >= currPersistContent.height? "btn-bg-primary text-white":"btn-bg-secondary text-muted"}  btn-sm`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/electronics/elc011.svg' className='svg-icon-2x'/><br/>
                <span className=''>Landscape</span>
              </button>
              
              <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px shadow-md mt-2' data-kt-menu='true'>
                <div className='px-7 py-5 text-start bg-light-primary rounded-top'>            
                  <div className='fs-6 text-primary fw-semibold'>
                    <KTSVG path='/media/icons/duotune/layouts/lay001.svg' className='svg-icon-2 me-2 text-primary' /> 
                    Landscape Templates
                  </div>
                </div>

                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5'>                     
                  { !isLoadingTemplates && templatesLandscapes && 
                    <div className='d-flex '>
                      <div className='me-3'> 
                        {templatesLandscapes?.map((tmplate, ind)=>(                         
                          <div className='my-2' key={ind}>
                            <a href="#"
                              onClick={() => { 
                                dispatch(setContentFromTemplate(tmplate)); 
                                dispatch(setContentName(currPersistContent?.user_template_name)); 
                              }}
                            >
                              <i className='fa fa-square me-2'></i>
                              {/* <PanelEditor 
                                currentContent={currPersistContent}
                                className={`card-xxl-stretch`}
                                // w={getCorrespondWidth(currEditorW, currEditorW)} 
                                // h={getCorrespondHeight(currEditorH, currEditorH)} 
                                w={getCorrespondWidth(160, 90, currPersistContent.width, currPersistContent.height, currEditorZoom)} 
                                h={getCorrespondHeight(160, 90, currPersistContent.width, currPersistContent.height, currEditorZoom)} 
                              /> */}
                              <span className='text-capitalize'>{tmplate.template_name}</span>
                            </a>
                          </div>
                        ))}
                      </div>                      
                    </div>
                  }
                </div>
              </div>
            </div> 

            <div className="btn-group" role="group">
              {/* <button
                className='btn btn-sm btn-bg-secondary opacity-75 btn-active-color-primary rounded-end tooltip-bottom px-3 '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='top-end'
                data-tooltip-content='More Templates'
              >
                <i className='bi bi-three-dots fs-3 my-1 ms-2'></i><br/>                
              </button> */}

              <button 
                className={`btn btn-small w-100px tooltip-bottom rounded-end ${currPersistContent.width < currPersistContent.height? "btn-bg-primary text-white":"btn-bg-secondary text-muted"}  btn-sm`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/electronics/elc003.svg' className='svg-icon-2x'/><br/>
                <span className=''>Portrait</span>
              </button>
              
              <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px shadow-md mt-2' data-kt-menu='true'>
                <div className='px-7 py-5 text-start bg-light-primary rounded-top'>            
                  <div className='fs-6 text-primary fw-semibold'>
                    <KTSVG path='/media/icons/duotune/layouts/lay006.svg' className='svg-icon-2 me-2 text-primary' /> 
                    Portrait Templates
                  </div>
                </div>

                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5'>                     
                  { !isLoadingTemplates && templatesLandscapes && 
                    <div className='d-flex '>
                      <div className='me-3'>                         
                        {templatesPortraits?.map((tmplate, ind)=>(                         
                          <div className='my-2' key={ind}>
                            <a href="#"
                              onClick={() => { 
                                dispatch(setContentFromTemplate(tmplate)); 
                                dispatch(setContentName(currPersistContent?.user_template_name)); 
                              }}
                            >
                              <i className='fa fa-square me-2'></i>
                              <span className='text-capitalize'>{tmplate.template_name}</span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>  
          </div>

            {/* <button 
              className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-3'
              data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
              // onClick={() => handleEditDevice(dev)}
              title='Favorite' 
            >
              <KTSVG path='/media/icons/duotune/general/gen003.svg' className='svg-icon-3'/>
            </button>
            <button 
              className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-3'
              data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
              // onClick={() => handleEditDevice(dev)}
              title='Undo' 
            >
              <i className='fa fa-undo'></i>
            </button>
            <button 
              className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm me-3'
              data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
              // onClick={() => handleEditDevice(dev)}
              title='Redo' 
            >
              <i className='fa fa-redo'></i>
            </button> */}
        </div>

        {/* Top-Right Toolbar */}
        <div className="w-50 d-flex justify-content-end flex-center">
          {/* <BtnRenameContent/> */}

          {/* <PanelContentDesc /> */}

            <div className="form-check form-switch form-check-custom form-check-solid">
              <input className="form-check-input" type="checkbox" id="flexSwitchDefault" value="" />
              <label className="form-check-label" htmlFor="flexSwitchDefault">
                Show Alias/Label
              </label>
            </div>
          {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span> */}
            <button 
              className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm ms-3'
              data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
              // onClick={() => handleEditDevice(dev)}
              title='New Section' 
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3'/>
            </button>
          {/* <button 
            className='btn btn-bg-white btn-active-color-primary btn-sm ms-3'
            // data-bs-toggle="offcanvas" data-bs-target="#existingDevice" aria-controls="staticBackdrop"
            onClick={() => {dispatch(resetContent());}}
            title='New Content' 
          >
            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3'/>
            New Content
          </button> */}
        </div>
      </div>
    </>
  )
}

export {PanelTop}