/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { Folder } from '../../../../redux/slices/media/mediaModels';
import { useAddFolderMutation } from '../../../../redux/slices/media/apiMediaSlice';
import { 
    FormControl,
    // Checkbox, 
    // InputLabel, MenuItem, 
    // Select,
    TextField, 
    FormHelperText
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
//----------------------------------------

type Props = {
  className: string,
}

const schema = yup.object().shape({
    group_name: yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Folder name is required'),
})

//----------------------------------------
const DropdownNewFolder: React.FC<Props> = ({className}) => {
  
    const dispatch = useAppDispatch()  
    const [addFolder] = useAddFolderMutation();

    //-----------------------------------------
    // const { data: allMedias, isLoading, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
    // console.log("allMedias")  
    // console.log(allMedias)

    const { 
        register, 
        handleSubmit,
        // control,
        reset,
        watch,
        setError,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful,
            
        }
    } = useForm<Folder>({
        resolver: yupResolver(schema),
        defaultValues:{            
            group_name  : "",            
        }
    }) 

    

    const formSubmitHandler: SubmitHandler<Folder> = async (folder: Folder) => {        
        try{
            const result = await addFolder(folder).unwrap();
            

            // setPin(PINCODE);

            // Swal.fire(                
            //     'New Device: '+ dev.deviceName + '<br/>'+
            //     'has been added to your account.<br/>&nbsp;<br/>' +
            //     'PIN : '+ PINCODE ,
            //     'Pleasae use this PIN to <br/>'+
            //     'link your physical device to your account.<br/>',                
            //     'success'
            // )
        }catch(error){
            Swal.fire(
                'Folder: '+ folder.group_name,
                'There is something wrong during the creation!',
                'error'
            )            
            // setError("name",{
            //     type: "custom",
            //     message: "This is sth wrong with name from server"
            // })
        }
    }

    //-----------------------------------------
    return (
    <>
        <form id="frm-new-folder" className='form'  
            onSubmit={handleSubmit(formSubmitHandler)}
        >              
            <div className="mb-1">
                <TextField 
                    {...register("group_name")} 
                    label="Folder Name" 
                    fullWidth 
                    margin='dense'
                    variant='outlined' 
                    error={!! errors.group_name}
                    helperText={`${errors.group_name?.message ?? ''}`}
                />
            </div>                    
                
            <div className="mt-2 text-end">                                                
                <button type="submit" className="btn btn-sm btn-primary" 
                    // data-bs-toggle="offcanvas"
                    disabled={isSubmitting || !isValid}
                >
                    <i className="fa fa-save me-1 mt-n1"></i>
                    Create
                </button>
            </div>                
        </form>

        <div className='separator border-gray-200 mt-6'></div>

    </>)
}

export {DropdownNewFolder}

