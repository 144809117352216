import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Index } from './components/Index'

const SubAccountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Subaccount',
      path: '/subaccount/index',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

const SubAccountPage = () => (
<Routes>
<Route
  element={
    <>          
      <Outlet />
    </>
  }
> 
  <Route
    path='index'
    element={
      <>      
      <PageTitle breadcrumbs={SubAccountBreadCrumbs}>Sub Account</PageTitle>      
        <Index />                      
      </>
    }
  />
  
  {/* Default route */}
  <Route index element={<Navigate to='/subaccount/index' />} />

</Route>
</Routes>
)

export default SubAccountPage