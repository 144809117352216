/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import {FC, Dispatch, SetStateAction, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import { AsideFooter } from './AsideFooter';
import {
  Tabs,  
} from '../../../../app/modules/editor/components/EditorComponents'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hook';
import { setSelectedMenu } from '../../../../app/redux/slices/editor/editorSlice';

// const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
 
//   // {
//   //   link: 'subscription',
//   //   icon: '/media/icons/duotune/social/soc007.svg',
//   //   tooltip: 'Videos',
//   // },
//   // {
//   //   link: 'tasks',
//   //   icon: '/media/icons/duotune/art/art001.svg',
//   //   tooltip: 'Text',
//   // },
//   // {
//   //   link: 'notifications',
//   //   icon: '/media/icons/duotune/abstract/abs027.svg',
//   //   tooltip: 'Elements',
//   // },
//   // {
//   //   link: 'authors',
//   //   icon: '/media/icons/duotune/files/fil005.svg',
//   //   tooltip: 'Widget',
//   // }  
// ]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}

const AsideTabs: FC<Props> = ({link, setLink}) => {
  const dispatch = useAppDispatch()
  // -----------------------------------------------------------
  const selMenu = useAppSelector(state => state.editor.selectedMenu)
  // console.log('selMenu', selMenu)
  
  useEffect(() => {
    setLink(selMenu)  //change the tabMenu
  }, [selMenu])

  // -----------------------------------------------------------
  return (<>
    {/* <div className='d-flex flex-column align-items-lg-center flex-row-auto'> */}

    <div
      // className='hover-scroll-y mb-0 pt-5'
      className=' mb-0 pt-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      <ul className='nav flex-column' id='kt_aside_nav_tabs'>
        {Tabs.map((t) => (
          <li key={t.link}>
            <a
              className={clsx(
                `nav-link btn btn-active-color-primary btn-color-gray-400 btn-active-light-primary py-2 px-2 mb-1 ${t.hidden?"d-none":""}`,
                // 'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light p-2',              
                {active: t.link === link}
              )}            
              onClick={() => setLink(t.link)}
            >
              <div className='d-flex flex-column align-items-lg-center flex-row-auto'>
                <KTSVG path={t.icon} className='svg-icon-2x me-0' />
                <span className="text-active-primary fw-semibold fs-8">{t.tooltip}</span>
              </div>
            </a>

            {/* <span className="menu-link menu-center">
              <span className="menu-icon me-0">
                <i className="ki-outline ki-home-2 fs-2x"></i>
              </span>
              <span className="menu-title">Home</span>
            </span> */}

          </li>
        ))}
      </ul>
      

      {/* </div> */}
    </div>

    </>
  )
}


export {AsideTabs}
