import React, {useEffect, useRef, useState} from 'react'
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
  VideoPreview,
} from './UploadMediaStyle'
import {convertBytesToKB} from '../../redux/slices/content/contentModels'
import {useAppSelector} from '../../redux/hook'
import Swal from 'sweetalert2'

// ------------------------------------------------

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1073741824 // 536870912 = 512MB; 256MB; 64000000; //64MB
const DEFAULT_MAX_FILE_UPLOAD = 20 // maximum upload 20 files per upload
const ACCEPT_FILE_TYPES = ["image", "video", "audio"]
const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key])

// -----------------------------------------------------
const FileUpload = ({
  label,
  updateFilesCb,
  totalSize,
  selectedFolder,
  uploadedFolder,
  currentUploadingFile,
  isSubmitting = false,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const currentFolder = useAppSelector((state) => state.editor.currentFolder)
  // console.log("currentFolder ", currentFolder)

  const fileInputField = useRef(null)
  const [files, setFiles] = useState({})
  // console.log("files ", files)

  const [uploadedFiles, setUploadedFiles] = useState([])

  // -----------------------------------------------------
  const handleUploadBtnClick = () => {
    fileInputField.current.click()
  }

  // -----------------------------------------------------
  const addNewFiles = (newFiles) => {
    var unSupportedFile = []
    
    for (let file of newFiles) {
      if (files && Object.values(files).length >= DEFAULT_MAX_FILE_UPLOAD) {
        Swal.fire({
          icon: 'warning',
          title:
            'Oops! You have selected more than ' +
            DEFAULT_MAX_FILE_UPLOAD +
            ' files!<br/> Click upload to load your first ' +
            DEFAULT_MAX_FILE_UPLOAD +
            ' files.',
        })
      } else {
        if ((ACCEPT_FILE_TYPES.indexOf(file.type.split("/")[0]) > -1) || (file.type === "application/pdf")){
          
          if (file.size < maxFileSizeInBytes) {
            if (!otherProps.multiple) {
              return {file}
            }
            files[file.name] = file
          }
        }else{
          unSupportedFile.push(file.name)
        }
      }
    }
    if (unSupportedFile.length > 0) {
      let isOrAre = unSupportedFile.length > 1 ? 'are' : 'is'
      Swal.fire({
        icon: 'warning',
        title: 'File: <span class="text-primary">' + unSupportedFile.toString() + '</span><br/>' + isOrAre + ' not supported.',
      })
    }
    calculateUploadSize(files)

    return {...files}
  }

  // -----------------------------------------------------
  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files)
    updateFilesCb(filesAsArray)
  }

  // -----------------------------------------------------
  const handleNewFileUpload = (e) => {
    const {files: newFiles} = e.target
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles)
      setFiles(updatedFiles)
      callUpdateFilesCb(updatedFiles)
    }
  }

  // -----------------------------------------------------
  const removeFile = (fileName) => {
    delete files[fileName]
    setFiles({...files})
    callUpdateFilesCb({...files})
    calculateUploadSize(files)
  }

  // -----------------------------------------------------
  const calculateUploadSize = (updatedFiles) => {
    let totalUploadSize = 0
    let uploadFiles = Object.values(updatedFiles)
    uploadFiles.map((uploadFile) => {
      totalUploadSize += uploadFile.size
    })
    totalSize(totalUploadSize)
  }

  // ---useEffect------------------------------------------
  useEffect(() => {
    setFiles({})
  }, [currentFolder])

  useEffect(() => {
    if (selectedFolder.sources.length === 0) {
      setFiles({})
    }
  }, [selectedFolder])

  useEffect(() => {
    if (uploadedFolder && uploadedFolder.uploadResponses) {
      setUploadedFiles(uploadedFolder.uploadResponses.map((res) => res.name))
    }
  }, [uploadedFolder])
  // -----------------------------------------
  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <div className='d-none d-md-block d-lg-block d-xl-block d-xxl-block'>
          <img src='/media/illustrations/icons-editor/upload-files.png' className='w-175px' />
        </div>
        <div className='my-5 text-center text-muted'>
          {/* Drag and Drop your files here or */}
          Click to upload your files here. <br />
          jpg, png, mp4 or pdf.
          <br />
          &nbsp;
          <br />
          Max limit of {DEFAULT_MAX_FILE_UPLOAD} files with total size of{' '}
          {convertBytesToKB(DEFAULT_MAX_FILE_SIZE_IN_BYTES)}.
        </div>
        <UploadFileBtn
          type='button'
          onClick={handleUploadBtnClick}
          className='btn btn-primary px-6 m-0'
          disabled={isSubmitting}
        >
          <div className='d-flex flex-center p-3'>
            <i className='bi bi-cloud-upload fs-2x me-3' />
            <span className='fs-6 fw-semibold text-nowrap'>
              Browse {otherProps.multiple ? 'files' : 'a file'}
            </span>
          </div>
        </UploadFileBtn>
        <FormField
          type='file'
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=''
          value=''
          disabled={isSubmitting}
          {...otherProps}
        />
      </FileUploadContainer>

      <FilePreviewContainer>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName]
            let fileType = file.type.split('/')[0]
            // --------------------------------------------
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {fileType === 'image' && (
                    <ImagePreview src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
                  )}
                  {fileType === 'video' && (
                    <VideoPreview
                      playsInline
                      src={URL.createObjectURL(file)}
                      alt={`preview video ${index}`}
                    />
                  )}
                  {file.type === 'application/pdf' && (
                    <div class="h-100 d-flex align-items-center justify-content-center">
                      <i class="bi bi-file-pdf text-red fs-1"></i>
                  </div>
                  )}
                  <FileMetaData isimagefile={fileType === 'image'}>
                    <span className='fw-light fs-7'>{file.name}</span>
                    <aside>
                      <span className='fw-light fs-7'>{convertBytesToKB(file.size)}</span>
                      {!isSubmitting && (
                        <RemoveFileIcon
                          className='fas fa-trash-alt'
                          onClick={() => removeFile(fileName)}
                        />
                      )}
                    </aside>
                  </FileMetaData>

                  {/* Progress status on each upload file */}
                  {uploadedFiles.includes(file.name) ? (
                    <div className='w-100 h-100 position-absolute top-0 d-flex justify-content-center align-items-center'>
                      <div className='symbol symbol-35px symbol-circle'>
                        <span className='symbol-label bg-white'>
                          <i className='fa fa-check-circle text-success fs-3x'></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    // : file.name === currentUploadingFile &&
                    currentUploadingFile !== '' && (
                      <div className='w-100 h-100 position-absolute top-0 d-flex justify-content-center align-items-center'>
                        <div className='symbol symbol-50px'>
                          <span className='symbol-label bg-transparent'>
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle text-white w-50px h-50px'></span>
                            </span>
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </PreviewContainer>
            )
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  )
}

export default FileUpload
