/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useAddScheduleMutation, useDeleteScheduleMutation, useGetScheduleQuery, useUpdateScheduleMutation } from '../../../../redux/slices/schedule/apiScheduleSlice'
import { Group, OptionProps, layoutOptions, categoryOptions, GroupInput } from '../../../../redux/slices/group/groupModels'
import { 
    FormControl,
    InputLabel, MenuItem, 
    Select,
    TextField, 
    FormHelperText,
} from '@mui/material'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { Schedule, ScheduleInput, durationOptions, emptyAssignedContent, repeatOptions } from '../../../../redux/slices/schedule/scheduleModels'

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { useGetAllContentsQuery } from '../../../../redux/slices/content/apiContentSlice'
import { useGetAllGroupsQuery } from '../../../../redux/slices/group/apiGroupSlice'
import { useAppSelector } from '../../../../redux/hook'
import { useNavigate } from 'react-router-dom'
import { CardContent } from '../../../content/components/ContentComponents'
import moment from 'moment'
import { RECENTPERIOD, getRibbonType } from '../../../../redux/slices/content/contentModels'





type Props = {  
    className?: string
}

const schema = yup.object().shape({    
    start_time: yup.string().min(3, 'Minimum 3 symbols').required('Start time is required'),
    end_time: yup.string().min(3, 'Minimum 3 symbols').required('End time is required'),    
    repeat: yup.string().min(1, 'Minimum 1 symbols').required('Repeat is required'),    
    type: yup.string().required('type is required'),
    deviceGroupIds: yup.string(),
    groupInputs: yup.mixed().required('Groups is required'),
    contents: yup.mixed().required('Content is required')
})


const DEFAULT_STARTDATETIME = dayjs()
const DEFAULT_ENDDATETIME   = dayjs().add(1, 'week')
const DEFAULT_STARTDATETIME_STR = DEFAULT_STARTDATETIME.format('YYYY-MM-DD HH:mm:ss')
const DEFAULT_ENDDATETIME_STR   = DEFAULT_ENDDATETIME.format('YYYY-MM-DD HH:mm:ss')

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerAddInstantSchedule: FC<Props> = ({className}) => {
    const navigate = useNavigate()
    const [addSchedule] = useAddScheduleMutation();
    //---------------------------------------------------- 
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)
    // console.log("currPersistContent: ", currPersistContent)

    const { data: allDeviceGroups} = useGetAllGroupsQuery(5, { refetchOnMountOrArgChange: true }) 
    // console.log("allDeviceGroups: ", allDeviceGroups)
    const [filterGrps, setFilterGrps] = useState<Group[]>([])
    // console.log("filterGrps: ", filterGrps)
    const [contentIDs, setContentIDs] = useState<string []>([currPersistContent.user_template_id?currPersistContent.user_template_id:""]);    
    const [grpIDs, setGrpIDs] = useState<string []>([""]);
    // console.log("grpIDs: ", grpIDs)
    // const [groupOrientation, setGroupOrientation] = useState('landscape');
    
    const [repeatMode, setRepeatMode] = useState('once');
    // const [duration, setDuration] = useState<string>("today");
    const [duration, setDuration] = useState<string>("1w");
    const [startDateTime, setStartDateTime] = useState<Dayjs | null>(DEFAULT_STARTDATETIME);
    const [endDateTime, setEndDateTime] = useState<Dayjs | null>(DEFAULT_ENDDATETIME);

    

    //---------------------------------------------------- 
    const { 
        register, 
        handleSubmit,
        control,
        reset,
        watch,
        getValues,
        setValue,
        setError,
        formState:{
            errors,
            isLoading,         
            isValid,   
            isSubmitting,
        }
    } = useForm<ScheduleInput>({
        resolver: yupResolver(schema),
        defaultValues:{            
            // name : "",
            start_time: DEFAULT_STARTDATETIME_STR,
            end_time:   DEFAULT_ENDDATETIME_STR,
            repeat: "once",    
            type: "1",
            deviceGroupIds: "",     //used only when submitting
            groupInputs: [],      
            contents: [{...emptyAssignedContent, content_id: currPersistContent.user_template_id}],
        }
    }) 

    // console.log("watch:", watch())
    // console.log("watch:", watch(["groupInputs"]))
    // console.log('watch all', watch(["start_time", "end_time", "repeat", "contents"]))
    // console.log('watch contents', watch(["contents"]), 'watch end_date', watch(["end_time"]))
    // ---------------------------------------------------




    // BEGIN of fieldArray Group----------------------------------------------

    const { fields: fieldsGrp, append: appendGrp, prepend: prependGrp, remove: removeGrp, swap: swapGrp, move: moveGrp, insert: insertGrp, update: updateGrp } = useFieldArray({
        control,
        name: "groupInputs",
        rules: {
          required: "Please append at least 1 group"
        }
    });

    // console.log("fieldsGrp outside:", fieldsGrp)
    // console.log("grpIDs outside:", grpIDs)

    const updateFieldGrpChanged = (index: number, value: string) => {
        let newArr = [...grpIDs];        
        newArr[index] = value;      
        setGrpIDs(newArr);
        updateGrp(index, { grpId: value})
    } 

    const addFieldGrp = () =>{
        setGrpIDs([...grpIDs, ""])
        appendGrp({grpId:""})
    }
    
    const resetFieldGrp = (defaultData = "") => {        
        setValue('groupInputs', [{grpId:defaultData}]);
        setGrpIDs([defaultData])
        removeGrp()
    } 

    // --------------------
    const handleAddFieldGrp = (defaultData = "") =>{
        setGrpIDs([...grpIDs, defaultData])
        appendGrp({grpId: defaultData})
    }

    const handleRemoveFieldGrp = (index: number) => {
        removeGrp(index)

        let newArr = [...grpIDs]
        newArr.splice(index, 1)
        setGrpIDs(newArr)
    }
    // END of fieldArray----------------------------------
    



    // ----------------------------------
    // Need to improve this algorithm
    const changeDuration = (data: string) => {
        setDuration(data)
        
        if      (data === "today")  {  setEndDateTime(dayjs().endOf('day'))  }
        else if (data === "1d")     {  setEndDateTime(dayjs().add(1, 'day'))  }
        else if (data === "1w")     {  setEndDateTime(dayjs().add(1, 'week'))  }
        else if (data === "2w")     {  setEndDateTime(dayjs().add(2, 'week'))  }
        else if (data === "3w")     {  setEndDateTime(dayjs().add(3, 'week'))  }
        else if (data === "1m")     {  setEndDateTime(dayjs().add(1, 'month'))  }
        else if (data === "2m")     {  setEndDateTime(dayjs().add(2, 'month'))  }
        else if (data === "3m")     {  setEndDateTime(dayjs().add(3, 'month'))  }
        else if (data === "4m")     {  setEndDateTime(dayjs().add(4, 'month'))  }
        else if (data === "5m")     {  setEndDateTime(dayjs().add(5, 'month'))  }
        else if (data === "6m")     {  setEndDateTime(dayjs().add(6, 'month'))  }
        else if (data === "1y")     {  setEndDateTime(dayjs().add(1, 'year'))   }        
        
    }
        
    
   
    // ------------------------------
    const formSubmitHandler: SubmitHandler<ScheduleInput> = async (sche: ScheduleInput) => {        
        try{

            sche["deviceGroupIds"] = sche.groupInputs
                                            ?   sche.groupInputs.reduce((accum, grp) => {
                                                    return accum + grp.grpId + ' ';
                                                }, '').trim().replaceAll(" ", ",")
                                            :   "";
            delete sche["groupInputs"]            
            // console.log("sche in formSubmit:", sche)

            const newSche = {   ...sche,
                                start_time  : moment.utc(sche.start_time),
                                end_time    : moment.utc(sche.end_time),
                            }
            // console.log("newSche in formSubmit:", newSche)

            
            const response = await addSchedule(sche)
            // console.log("response in formSubmit:", response)

            // reset()
            reset({
                start_time  : DEFAULT_STARTDATETIME_STR,
                end_time    : DEFAULT_ENDDATETIME_STR,
                repeat      : "once",            
                type        : "1",  
                contents    : [],
                deviceGroupIds: "",
                groupInputs : []
            })

            Swal.fire({
                icon: 'success',
                title: 'Your campaign <span className="fs-1">"'+ currPersistContent.user_template_name +'"</span> is <br/><br/> <h1>SUPERBOLT NOW.</h1><br/>',
                showConfirmButton: false,
                timer: 1500
            }) 
            
            removeGrp()    //remove from fields
            // resetFieldGrp()
            setGrpIDs([""]) //remove from state grpIDs

            reset()
            
            navigate('/schedule')

        }catch(error){
            Swal.fire(
                'Schedule: '+ sche.name,
                'There is something wrong during the creation!',
                'error'
            )     
                 
            // setError("name",{
            //     type: "custom",
            //     message: "This is sth wrong with name from server"
            // })
        }        
    }

    // -------------------------------------
    useEffect(() => {        
        const filterGrps = allDeviceGroups?.filter((grp)=> {
            return  (   
                        ((grp.group_name && grp.group_name.toLowerCase() === "default") || 
                        ((grp.displayType === "landscape")&&(currPersistContent.width >= currPersistContent.height)) || 
                        ((grp.displayType === "portrait")&&(currPersistContent.width < currPersistContent.height))) 
                    )
        })

        setFilterGrps(filterGrps? filterGrps : [])

        if (filterGrps && filterGrps.length > 0 && filterGrps[0].id){
            updateFieldGrpChanged(0, filterGrps[0].id)
            setGrpIDs([filterGrps[0].id]);
        }

    }, [ currPersistContent, allDeviceGroups ])

    // -------------------------------------
    useEffect(() => {
       
        reset({            
            start_time: startDateTime ? startDateTime.format('YYYY-MM-DD HH:mm:ss') : DEFAULT_STARTDATETIME_STR,
            end_time:   endDateTime   ? endDateTime.format('YYYY-MM-DD HH:mm:ss')   : DEFAULT_ENDDATETIME_STR,
            repeat: "once",            
            type: "1",            
            contents: [{...emptyAssignedContent, content_id: currPersistContent.user_template_id}],
            deviceGroupIds: "",
            groupInputs: grpIDs && grpIDs.length > 0    ? grpIDs.map(grpId => {return {grpId: grpId} })
                                                        : [{grpId: filterGrps? filterGrps[0].id :""}],
        })
        setContentIDs([currPersistContent.user_template_id? currPersistContent.user_template_id:""])

        // ----For the first initialization---

        // if (grpIDs && grpIDs.length === 1 && grpIDs[0] === ""){
        //     if (filterGrps && filterGrps.length > 0 && filterGrps[0].id){
        //         updateFieldGrpChanged(0, filterGrps[0].id)
        //         setGrpIDs([filterGrps[0].id]);
        //     }
        // }

    }, [ currPersistContent, filterGrps, endDateTime ])
   

    //----------------------------------------------------
    return (
        <>  
            <div
                id="kt_drawer_instant_play"
                data-kt-drawer-toggle={`#kt_drawer_instant_play_button`}
                data-kt-drawer-close= {`#kt_drawer_instant_play_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className?className:""}`}
                data-kt-drawer-activate="true"
                aria-labelledby="staticBackdropLabel"

                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-gradient-primary">
                    <h5 className="offcanvas-title text-white" id="staticBackdropLabel">
                        {/* <KTSVG
                            path={`/media/icons/duotune/text/txt001.svg`}
                            className={`svg-icon-2x svg-icon-primary me-2 mt-n2 d-inline`}
                        /> */}
                        <i className='bi bi-lightning-fill text-white fs-4 me-2'></i>
                        Instant Schedule of <span className='text-capitalize text-gray-500 ms-1'>'{currPersistContent.user_template_name}'</span>
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                    // onClick={handleActivityClose}
                    ></button>                        
                </div>
                <div className="offcanvas-body text-start">
                    <form id="frm-add-schedule" className='form'  
                        onSubmit={handleSubmit(formSubmitHandler)}
                    >
                        <div className='col-8 mb-0'>
                            <CardContent 
                                className="" 
                                content = {currPersistContent}
                                autoLayout={true}
                                displayIcon={false}
                                actionIcons={false}
                                ribbonType = { getRibbonType(currPersistContent) }                                
                                noTitle={true}
                            />     
                        </div>
                        
                        <div className="mb-4">
                            <FormControl fullWidth>
                                <InputLabel id="select-duration-label">Duration</InputLabel>
                                <Select 
                                    id="duration"
                                    labelId="select-duration"                                        
                                    label="Duration"
                                    value={duration}
                                    onChange={(e) => {
                                        changeDuration(e.target.value)
                                    }}
                                    MenuProps={{ disablePortal: true }}
                                >                                        
                                    {durationOptions.map((opt) => {
                                        return (<MenuItem key={opt.value} value={opt.value}>
                                            {opt.label}</MenuItem>)
                                    })}                                        
                                </Select>
                                <FormHelperText>{`${errors.repeat?.message ?? ''}`}</FormHelperText>
                            </FormControl>                                                                
                        </div>

                        <div className="mb-4">
                            {fieldsGrp.map((field, index) => {
                                // console.log("getValues(groupInputs)", getValues(`groupInputs.${index}.grpId`))                               
                                // console.log("grpIDs[index]:",grpIDs[index])
                                // -----------------------
                                return (
                                    <div className="d-flex mb-4" key={field.id}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-location-label">Group</InputLabel>
                                            <Select 
                                                {...register(`groupInputs.${index}.grpId`)}
                                                labelId="select-location-label"
                                                label="Group"
                                                value={`${grpIDs[index]}`}
                                                onChange={(e) => {
                                                    updateFieldGrpChanged(index, e.target.value)
                                                }}
                                                error={!! errors.groupInputs}
                                                MenuProps={{ disablePortal: true }}
                                            >   
                                                <MenuItem key={0} value={0} disabled>Select group</MenuItem>
                                                {filterGrps
                                                ?.slice().sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1:-1)
                                                .map((opt, ind) => {
                                                    // console.log(ind, "opt: ", opt)
                                                    // ------------------------------
                                                    return (
                                                        <MenuItem key={opt.id} value={opt.id} 
                                                            className={`w-100 ${opt.id && grpIDs.includes(opt.id) ? "bg-light-primary": ""}`}
                                                            disabled = {opt.id && grpIDs.includes(opt.id) ? true: false}
                                                        >                                                    
                                                            {opt.devices && 
                                                                <div className='w-100 d-flex justify-content-between'>
                                                                    <span className={`w-150px text-capitalize ${opt.devices.length <= 0 ? "opacity-50":""}`}>
                                                                        <i className="fa fa-folder me-2"></i>{opt.name}
                                                                        {/* <i className="fa fa-folder me-2"></i>{opt.id}-{opt.name} */}
                                                                    </span>                                                                    
                                                                    {opt.devices.length > 0 
                                                                        ?   <span className="badge badge-light ms-3">
                                                                                {opt.devices.length} devices
                                                                            </span>
                                                                        :   <span className="w-75px">&nbsp;</span>
                                                                    } 
                                                                </div>
                                                            }
                                                        </MenuItem>
                                                    )
                                                })}                                        
                                            </Select>
                                            <FormHelperText>{`${errors.contents?.message ?? ''}`}</FormHelperText>
                                        </FormControl>

                                        {fieldsGrp.length > 1 &&
                                            <button type="button"                                             
                                                className='btn btn-icon btn-bg-light btn-active-color-primary mt-1 ms-3'
                                                onClick={() => handleRemoveFieldGrp(index)}                                                
                                            >                                            
                                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon svg-icon-2x' />
                                            </button>
                                        }
                                    </div>
                                )
                            })}
                        </div> 
                        {filterGrps && grpIDs.length < filterGrps.length &&
                            <div className="d-flex flex-end">
                                <button type="button"                                             
                                    className='btn btn-sm btn-bg-light btn-active-color-primary tooltip-bottom'
                                    data-tooltip-content='Add another group'
                                    onClick={() => handleAddFieldGrp()}
                                >                                            
                                    <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon svg-icon-5' />
                                    Add group
                                </button> 
                            </div>
                        }
                        
                        



                        <div className="mb-4" hidden={true}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                    // 'DateTimePicker',
                                    'MobileDateTimePicker',
                                    // 'DesktopDateTimePicker',
                                    // 'StaticDateTimePicker',
                                    ]}
                                >
                                   
                                    <DemoItem>
                                        <MobileDateTimePicker 
                                            className='mb-5'
                                            label="Start Date & Time" 
                                            value={startDateTime}
                                            defaultValue={dayjs()} 
                                            onChange={(newVal)=>{ 
                                                setStartDateTime(newVal)
                                                setValue("start_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                            }}
                                            disabled={true}
                                        />
                                        <MobileDateTimePicker 
                                            className='mb-4'
                                            label="End Date & Time" 
                                            value={endDateTime}
                                            defaultValue={dayjs()} 
                                            onChange={(newVal)=>{ 
                                                setEndDateTime(newVal)
                                                setValue("end_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                            }}
                                            disabled={true}
                                        />                                        
                                    </DemoItem>                                    
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>                                                
                        <div className="mb-4" hidden={true}>                                
                            <FormControl fullWidth>
                                <InputLabel id="select-repeat-label">Repeat</InputLabel>
                                <Select 
                                    {...register("repeat")} 
                                    labelId="select-repeat-label"                                        
                                    label="Repeat"
                                    value={repeatMode}
                                    onChange={(e) => setRepeatMode(e.target.value)}
                                    error={!! errors.repeat}   
                                    MenuProps={{ disablePortal: true }}         
                                >                                        
                                    {repeatOptions.map((opt) => {
                                        return (<MenuItem key={opt.value} value={opt.value}>
                                            {opt.label}</MenuItem>)
                                    })}                                        
                                </Select>
                                <FormHelperText>{`${errors.repeat?.message ?? ''}`}</FormHelperText>
                            </FormControl>                                                                
                        </div>
                        <div className="mb-4" hidden={true}>
                            <TextField 
                                {...register(`contents.${0}.content_id`)} 
                                value={`${contentIDs[0]}`}
                                label="Content" 
                                fullWidth 
                                margin='dense'
                                variant='outlined' 
                                error={!! errors.contents}
                                helperText={`${errors.contents?.message ?? ''}`}
                                disabled={true}
                            />
                        </div>
                        <div className="mb-4" hidden={true}>
                            <TextField 
                                {...register("type")}                                 
                                value={"1"}                                
                                label="Type" 
                                fullWidth 
                                margin='dense'
                                variant='outlined' 
                                error={!! errors.type}
                                helperText={`${errors.type?.message ?? ''}`}
                                disabled={true}
                            />
                        </div>

                        
                        
                        
                            

                        <div className="pt-8 text-end">                            
                            <button type="submit" className="btn btn-primary bg-gradient-primary w-100"                                 
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"                                
                                disabled={isSubmitting || !(isValid && (grpIDs.length>=1 && grpIDs[0]!==""))}
                            >
                                <i className="bi bi-lightning-fill fs-4 me-1 mt-n1"></i>
                                Superbolt Now
                            </button>
                        </div>

                        <button type="button" 
                            className="btn btn-icon btn-light-primary position-absolute bottom-0 end-0 me-7 mb-8" 
                            data-bs-dismiss="offcanvas"
                            data-kt-drawer-dismiss="true"
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr001.svg'
                                className='svg-icon svg-icon-1x'
                            />
                        </button>                       
                    </form>
                </div>
            </div>
        </>
    )
}

export {DrawerAddInstantSchedule}