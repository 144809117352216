import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { Template } from "./templateModels";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL


export const templatesApi = createApi({
    reducerPath: "templatesApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{
            const auth = getAuthModel()            
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['Template', 'Position'],
    refetchOnMountOrArgChange: 30,  

    endpoints: (builder) => ({        
        getAllTemplates : builder.query<Template[], number>({
            query: () => "template",
            providesTags: ['Template', 'Position'],
            // keepUnusedDataFor: 5,   // configuration for an individual endpoint, overriding the api setting            
        }), 
        getTemplate : builder.query<Template, string>({
            query: (id) => `template/${id}`,
            providesTags: ['Template', 'Position']
        }),
        getAllPlaylistTemplates : builder.query<Template, void>({
            query: () => `template/playlist`,
            providesTags: ['Template', 'Position']
        }),
    })
})

export const {
    useGetAllTemplatesQuery,
    useGetTemplateQuery,
    useGetAllPlaylistTemplatesQuery,
} = templatesApi