import clsx from 'clsx'

import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { SaveContentFrm } from './SaveContentFrm'
import { Link } from 'react-router-dom'
import { BtnInstantPlay } from '../../EditorComponents'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook'
import { useEffect, useState } from 'react'
import { resetContentLandscape, setCheckSum, setContent } from '../../../../../redux/slices/content/contentSlice'
import { isEmptyElements, isSimilarContent } from '../../../../../redux/slices/content/contentModels'

const NavbarContent = () => {
  const dispatch = useAppDispatch()  
  // ----------------------------------------------------------------------
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)    
  const prevPersistContent = useAppSelector(state => state.persistor._content.prevContent)
  const chckSum = useAppSelector(state => state.persistor._content.checkSum)  

  const [sameContentFlag, setSameContentFlag] = useState(false)
  
  const editorStatusParams = useAppSelector(state => state.editor.editorStatusParams)
  // console.log("editorStatusParams: ", editorStatusParams)

   // ---------------------------------
  useEffect(()=>{    
    setSameContentFlag(isSimilarContent(currPersistContent, prevPersistContent))    
  },[currPersistContent, prevPersistContent])


  // -------------------------------------------
  return (<>      
     
      <div className='gy-0 gx-xl-8 py-5'>
        {currPersistContent.user_template_name === "" 
          ? <Link to="/content"
              className="btn btn-secondary btn-sm me-3 tooltip-bottom"
              data-tooltip-content='Cancel and Return to Media Content Page'
              onClick={() => dispatch(resetContentLandscape())  }
            >            
              <i className="bi bi-x-lg p-0 mt-n1"></i>               
              <span className='d-inline ms-1'>Cancel</span>
            </Link>
          : <a href="/content"
              className={`btn btn-secondary btn-sm me-3 tooltip-bottom 
                ${[1].includes(editorStatusParams.savingStatus)? "disabled":""}
              `}
              data-tooltip-content='Return to Media Content Page'              
              onClick={() => dispatch(resetContentLandscape())  }
            >            
              <i className="bi bi-arrow-left p-0 mt-n1 me-2"></i>               
              <span className='d-inline'>Back</span>
            </a>
        }

        <SaveContentFrm />

        {currPersistContent && currPersistContent.user_template_name !== "" && 
          <button 
            type='button'
            className={`btn tooltip-bottom btn-sm btn-primary ms-3`}
            data-tooltip-content={`Preview`}
            // data-bs-dismiss="modal"
            
            data-bs-toggle="modal"
            data-bs-target="#modal-preview-content"   
            aria-label="Close"
            disabled={(!sameContentFlag)
                      // (!(!sameContentFlag && (prevPersistContent.user_template_name === "") && (currPersistContent.user_template_name !== "")))
            }
            onClick={() => { 
              dispatch(setContent(currPersistContent)); 
              dispatch(setCheckSum(chckSum+1)); 
            }}          
          > 
            <i className="bi bi-play-circle-fill fs-3 p-0"></i> 
            <span className='d-none d-xl-inline ms-2'>Preview</span>      
          </button>
        }
        {currPersistContent && currPersistContent.user_template_name !== "" && 
          <BtnInstantPlay className={`btn-sm btn-primary bg-gradient-primary ms-3 ${sameContentFlag? "":"disabled"}`} title="Superbolt Now"/>
        }
      </div>

      
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div> */}

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )} */}
  </>)
}

export {NavbarContent}
