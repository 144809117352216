import moment from 'moment'
import { AuthModel } from '../../app/modules/auth/core/_models'
import * as authHelper from '../../app/modules/auth/core/AuthHelpers'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'


export const getAuthModel = (): AuthModel | undefined =>  {
  
    
    if (!localStorage) {
      return
    }
    
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
      return
    }
    
    try {
      var auth: AuthModel = JSON.parse(lsValue) as AuthModel
      if (auth) {
        if(parseInt(moment().format("YYYYMMDDHHmmss")) > auth.expires_in)
        {
          authHelper.removeAuth()
          window.location.reload()
        }
        return auth 
      }
    } catch (error) {
      
      //console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}


