import {Task} from "gantt-task-react"
// import styles from "../styles/tooltip.module.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useGetAllSchedulesQuery } from "../../../../redux/slices/schedule/apiScheduleSlice";
import { Sched, extractRepeatModeFromSchedule} from "../../../../redux/slices/schedule/scheduleModels";
import { ScheduleInfo } from "./ScheduleInfo";

type TooltipContentProps = {
    task: Task;
    fontSize: string;
    fontFamily: string;
    allScheds : Sched[],
    currActiveSchedIds: string[],
}

export const TooltipContent: React.FC<TooltipContentProps> = ({task, fontSize, fontFamily, allScheds, currActiveSchedIds}) => {
    const style = { fontSize, fontFamily, };

    const startDate = moment(task.start)
    const endDate = moment(task.end)

    const todalDuration =   endDate.diff(startDate, 'days') > 0 ? endDate.diff(startDate, 'days') 
                                : (endDate.diff(startDate, 'hours')>0   ? 1 : 0)

    const progressDuration  =   moment().diff(startDate, 'days') < 0 ? 0
                                : moment().diff(endDate, 'days') >= 0  ? todalDuration : moment().diff(startDate, 'days')

    const [taskStatus, setTaskStatus] =  useState(0)
    const [taskName, setTaskName] =  useState("")

    const [currSch, setCurrSch] = useState<Sched>()

    

    // const itemName   = isPlaylist === true  
    //                                     ? isCronTask === true   ? task.name.substring(2,) 
    //                                                             : task.name.substring(1,)
    //                                     : isCronTask === true   ? task.name.substring(1,) 
    //                                                             : task.name
    // console.log("task: ", task)
    // console.log("allScheds: ", allScheds)
    // console.log("currSch: ", currSch)
    // console.log("currActiveSchedIds: ", currActiveSchedIds)

    useEffect(()=>{
        const foundSchs = allScheds.filter((sche)=> sche.id === task.id)
        setCurrSch(foundSchs? foundSchs[0] : undefined)
        setTaskStatus(task.progress <= 0  ? 0   
                                          : task.progress === 100   ? 100 
                                                                    : foundSchs && foundSchs.length>0 && foundSchs[0].cron ? -1 : 50
                    )
        
        const isPlaylist = (task.name.substring(0,1).toLowerCase() === ".")? true: false
        const isCronTask = (task.name.substring(0,1).toLowerCase() === "*") || (task.name.substring(0,2).toLowerCase() === ".*") ? true: false
        setTaskName( isPlaylist === true  
                                        ? isCronTask === true   ? task.name.substring(2,) 
                                                                : task.name.substring(1,)
                                        : isCronTask === true   ? task.name.substring(1,) 
                                                                : task.name
        )
    },[task])

    // ------------------------------------------
    return (
        
            <div 
                className={`container w-250px p-3 border border-1 rounded 
                    ${task.type !== "project"? "":" d-none"}
                    ${currActiveSchedIds.includes(task.id) ? "bg-success bg-opacity-75 text-capitalize text-white"
                        : taskStatus === -1 ?                "bg-primary bg-opacity-75 text-capitalize text-white"
                            : taskStatus === 0 ? (currSch && currSch.cron ? "bg-primary bg-opacity-75 text-capitalize text-white": "bg-info bg-opacity-75 text-capitalize text-white")
                                // : taskStatus == 50 ? "bg-warning bg-opacity-75 text-capitalize text-white"
                                : taskStatus === 50 ?       "bg-info bg-opacity-75 text-capitalize text-white"
                                    : taskStatus === 100?   "bg-dark bg-opacity-75 text-capitalize text-white"
                                        : ""
                    }                    
                `}
                style={style}
            >
                <div className="row gy-1">                                    
                    <div className="fw-bold fs-7">{taskName}</div>                    
                    {currSch &&
                        <ScheduleInfo className="p-0 ps-3" textClassName="text-white" sch={currSch} repeatMode={extractRepeatModeFromSchedule ([currSch])}/>
                    }
                </div>                                    
            </div>
        
    );
};