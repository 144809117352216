import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, MasterModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import { useSearchParams } from 'react-router-dom'

type AuthContextProps = {
  master: MasterModel | undefined
  saveMaster: (master: MasterModel | undefined) => void
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  master: undefined,
  saveMaster: () => {},
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [master, setMaster] = useState<MasterModel | undefined>()
  
  // -----------------
  const saveMaster = (master: MasterModel | undefined) => {
    setMaster(master)
    if(master)
    {
      authHelper.setMaster(master)
    }else{
      authHelper.removeMaster()
    }
  }

  // -----------------
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  // ----------------
  const logout = () => {
    saveMaster(undefined)
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  // ----------------
  return (
    <AuthContext.Provider value={{master, saveMaster, auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}


const AuthInit: FC<WithChildren> = ({children}) => {

  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application


  const [searchParams, setSearchParams] = useSearchParams();

  // console.log("searchParams", searchParams)
  // console.log("Auth pin = ", searchParams.get("pin"))


  // --------------------------
  const requestUser = async (apiToken: string) => {
    try {
      if (!didRequest.current) {
        const {data} = await getUserByToken(apiToken)
        if (data) {
          setCurrentUser(data)
        }
      }
    } catch (error) {
      console.error(error)
      if (!didRequest.current) {
        logout()
      }
    } finally {
      setShowSplashScreen(false)
    }

    return () => (didRequest.current = true)
  }


  // ------------------------------------
  useEffect(() => {

    // Save PIN in case it is attached within the URL ?pin=......
    if(searchParams && searchParams.get("pin")){
      authHelper.setPinCode(""+ searchParams.get("pin"))
    }
    // console.log("localStorage ----", localStorage)

    // --------------------------------
    if (auth && auth.access_token) {
      requestUser(auth.access_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  // ------------------------------------------
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
