import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../../../../_metronic/assets/ts/components'
import { useEffect } from 'react'
import { 
  GalleryBreadcrumb,
  GalleryFiles,
  GalleryMenu
} from '../../../EditorComponents'


const TabGallery = () => {

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (    
    <div className="d-flex flex-column flex-row-auto w-100 vh-100 bg-gray-300">
        <div className="d-flex flex-column-auto h-70px bg-gradient-primary pt-5">
          <GalleryMenu className=''/>
        </div>
        <div className="d-flex flex-column-auto bg-light">
          <GalleryBreadcrumb className=''/>
        </div>
        <div className="d-flex flex-column-fluid flex-center pt-5"
            style={{maxHeight:"90%", overflowY:"auto"}}
        >
          {/* <GalleryFiles className='card-xxl-stretch mb-5 mb-xl-8 h-100'/> */}
          <GalleryFiles className='card-xxl-stretch mb-5 mb-xl-8 h-100'/>
        </div>
    </div>
  )
}

export {TabGallery}
