/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { Folder, Media, MediaInputFields } from '../../../../redux/slices/media/mediaModels';
import { useGetAllRootFoldersQuery, useUploadFilesMutation } from '../../../../redux/slices/media/apiMediaSlice';
import { 
    FormControl,
    // Checkbox, 
    InputLabel, MenuItem, 
    Select,
    TextField, 
    FormHelperText
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
//----------------------------------------

type Props = {
  className: string,
}

const schema = yup.object().shape({
    // src_filepath: yup.string()
    //     .min(3, 'Minimum 3 symbols')
    //     .max(50, 'Maximum 50 symbols')
    //     .required('File path is required'),
    // src_filepath: yup.mixed().test('required', 'Please select a file', value => {return value && value.length})
})

//----------------------------------------
const DropdownUploadFiles: React.FC<Props> = ({className}) => {
  
    const dispatch = useAppDispatch()  
    const [uploadFiles] = useUploadFilesMutation();

    //-----------------------------------------
    const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders, isError: isErrorFolders } = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })        
    //---------------------------------------------------- 
    const [srcGrpId, setSrcGrpId] = useState(0)

    // const { data: allMedias, isLoading, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
    // console.log("allMedias")  
    // console.log(allMedias)

    const { 
        register, 
        handleSubmit,
        // control,
        reset,
        watch,
        setError,
        setValue,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful,
            
        }
    } = useForm<MediaInputFields>({
        resolver: yupResolver(schema),
        defaultValues:{   
            src_filepath: null,
            src_filepaths: null,
            // source_group_id : 0,            
            source_group_id : 1724,            
            is_remote   : 0,
            url         : "",
            widthList   : "",
            heightList  : "",
            durationList: "",           
        }
    }) 

    // const [filePath, setFilePath] = useState(null)
    // const [image, setImage] = useState("")

    // const convert2base64 = (file: File) => {
    //     const reader = new FileReader()
    //     reader.onloadend = () => {
    //         setImage(reader.result?reader.result.toString():"")
    //     }
    //     reader.readAsDataURL(file)
    // }

    // console.log(watch("src_filepaths"))


    // const handleChange = (e: any) =>{
    //     console.log(`Selected file - ${e.target.files[0].name}`);
    //     setImage(e.target.files[0].name)
    // }



    // const formSubmitHandler: SubmitHandler<any> = async (mFile: any) => {        
    const formSubmitHandler: SubmitHandler<MediaInputFields> = async (mFile: MediaInputFields) => {        
        try{
            // console.log(" mFile" )
            // console.log(mFile)
            // console.log(mFile.src_filepaths)
            // console.log(mFile.src_filepaths?mFile.src_filepaths[0]:"")
            // console.log(mFile.src_filepaths?mFile.src_filepaths[0].name:"")
            
            // console.log(mFile.src_filepath)

            // setValue('src_filepath', mFile.src_filepaths?mFile.src_filepaths[0]:null)
            // console.log(" mFile" )
            // console.log(mFile)

            // console.log('watch')
            // console.log(watch())
            const newFile = {   
                src_filepath: mFile.src_filepaths?mFile.src_filepaths[0]:null,
                source_group_id : 1724,            
                is_remote   : 0,
                url         : "",
                widthList   : "",
                heightList  : "",
                durationList: "",           
            }

            const result = await uploadFiles(newFile)
            
        }catch(error){
            Swal.fire(
                'Files Uploading... ',
                'There is something wrong during the upload!',
                'error'
            )                        
        }
    }

    //-----------------------------------------
    return (
    <>
        
        <form id="frm-upload-files" className='form'  
            onSubmit={handleSubmit(formSubmitHandler)}
        >              
            <div className="mb-1">
                {/* <TextField 
                    {...register("src_filepath")} 
                    label="File Path" 
                    fullWidth 
                    margin='dense'
                    variant='outlined' 
                    error={!! errors.src_filepath}
                    helperText={`${errors.src_filepath?.message ?? ''}`}
                /> */}
                {/* { !watch("src_filepath") || watch("src_filepath").length === 0  
                    ?   (*/}
                        <div>
                            <input
                                type='file'
                                id="fileUpload"
                                {...register("src_filepaths")} 
                                // onChange={ handleChange }
                                // onChange={(e) => setFilePath( e.target.files[0].name )}
                            />
                            {/* <label
                                htmlFor='fileUpload'
                                style={{cursor: 'pointer'}}
                            >
                                Select File...
                            </label> */}
                        </div>
                        {/* )
                    :   (<span>file exists</span>)
                }                 */}
            </div>

            {/* <div className="mb-4">                                
                <FormControl fullWidth>
                    <InputLabel id="select-folder">Folder</InputLabel>
                    <Select 
                        {...register("source_group_id")} 
                        labelId="select-layout-label"                                        
                        label="Folder"
                        value={srcGrpId}
                        // onChange={(e) => setSrcGrpId(e.target.value)}
                        onChange={(e) => setSrcGrpId(1724)}
                        error={!! errors.source_group_id}                                        
                    >   
                        {rootFolders?.data.map((fold) => {
                            // return (<MenuItem key={grp.id} value={grp.id}><span className="badge badge-primary me-2">{grp.id}</span> {grp.name} <span className="badge badge-success ms-3">{grp.devices?.length}</span></MenuItem>)
                            return (<MenuItem key={fold.source_group_id} value={fold.source_group_id}>{fold.group_name} </MenuItem>)
                        })}                                        
                    </Select>
                    <FormHelperText>{`${errors.source_group_id?.message ?? ''}`}</FormHelperText>
                </FormControl>                                                                
            </div>                      */}
                
            <div className="mt-2 text-end">
                <button type="submit" className="btn btn-sm btn-primary" 
                    // data-bs-toggle="offcanvas"
                    disabled={isSubmitting || !isValid}
                >
                    <i className="fa fa-cloud-upload me-1 mt-n1"></i>
                    Upload
                </button>
            </div>                
        </form>  
        <div className='separator border-gray-200 mt-6'></div>          
    </>)
}

export {DropdownUploadFiles}

