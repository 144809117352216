import {SidebarMenuMain} from './SidebarMenuMain'
import {useLayout} from '../../../core'

const SidebarMenu = () => {

  const {config, classes} = useLayout()
  //----------------------------------------------

  return (<>
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y mb-5'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          <SidebarMenuMain />
          {/* <div className='container text-danger'> */}
            {/* Test From here<br/> */}
            {/* {config.app?.sidebar?.componentName}<br/> */}
            {/* {config.app?.sidebar?.display &&  <>display<br/></> }        */}
            {/* {config.app?.sidebar?.default?.class}<br/> */}
            {/* {config.app?.sidebar?.default?.push?.header &&  <>header<br/></>} */}
            {/* {config.app?.sidebar?.default?.push?.toolbar &&  <>toolbar<br/></>} */}
            {/* {config.app?.sidebar?.default?.push?.footer &&  <>footer<br/></>} */}
            {/* {config.app?.sidebar?.default?.drawer?.enabled &&  <>drawer enable<br/></>} */}
            {/* {config.app?.sidebar?.default?.drawer?.attributes}<br/> */}
            {/* {config.app?.sidebar?.default?.sticky?.enabled &&  <>sticky enable<br/></>} */}
            {/* {config.app?.sidebar?.default?.sticky?.attributes}<br/> */}
            {/* {config.app?.sidebar?.default?.fixed?.desktop &&  <>fixed desktop<br/></>} */}
            {/* {config.app?.sidebar?.default?.minimize?.desktop?.enabled &&  <>minimize desktop enable<br/></>} */}
            {/* {config.app?.sidebar?.default?.minimize?.desktop?.default &&  <>minimize desktop default<br/></>} */}
            {/* {config.app?.sidebar?.default?.minimize?.desktop?.hoverable &&  <>minimize desktop hoverable<br/></>} */}
            {/* {config.app?.sidebar?.default?.minimize?.mobile?.enabled &&  <>minimize mobile enable<br/></>} */}
            {/* {config.app?.sidebar?.default?.minimize?.mobile?.default &&  <>minimize mobile default<br/></>} */}
            {/* {config.app?.sidebar?.default?.minimize?.mobile?.hoverable &&  <>minimize mobile hoverable<br/></>} */}
            {/* {config.app?.sidebar?.default?.menu?.iconType}<br/> */}
            {/* {config.app?.sidebar?.default?.collapse?.desktop?.enabled &&  <>collapse desktop enable<br/></>} */}
            {/* {config.app?.sidebar?.default?.collapse?.desktop?.default &&  <>collapse desktop default<br/></>} */}
            {/* {config.app?.sidebar?.default?.collapse?.mobile?.enabled &&  <>collapse mobile enable<br/></>} */}
            {/* {config.app?.sidebar?.default?.collapse?.mobile?.default &&  <>collapse mobile default<br/></>} */}
            {/* {config.app?.sidebar?.default?.stacked &&  <>stacked<br/></>} */}
            {/* {config.app?.sidebar?.toggle &&  <>toggle<br/></>}  */}
          {/* </div> */}
        </div>
      </div>
    </div>
    </>
  )
}

export {SidebarMenu}
