import {FC} from 'react'
import Slider from 'react-slick'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'


const HeaderBackgroundTermsConditions: FC<{clsName?:string}> = ({clsName="h-300px"}) => {
    
    //--------------------------------
    return (<>
        <Slider {...{
            fade: true,                                        
            dots: false,
            // dotPosition: "top",
            arrows: false,      
            autoplay: true,
            infinite: true,      
            slidesToShow:   1,      
            slidesToScroll: 1,                                        
            speed: 7500,                                        
            pauseOnHover: false
            
            }}
        >                                 
            <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
                <div 
                    className='w-100'
                    style={{background:`#eff2f5cc url('media/_background/bg-001.jpg') no-repeat top center / cover`, opacity:"1"}}
                >
                    &nbsp;
                </div>                                        
            </div>
            <div className={`d-flex ${clsName} justify-content-center overflow-hidden w-100 ms-0 me-0`}>
                <div 
                    className='w-100'
                    style={{background:`#eff2f5cc url('media/_background/bg-001.jpg') no-repeat top center / cover`, opacity:"1"}}
                >
                    &nbsp;
                </div>                                        
            </div>        
        </Slider>        
    </>)
}

const HeaderBannerTermsConditions: FC = () => {
    
  //--------------------------------
  return (
    <div
      id='banner'
      className='w-100' 
    >
        <div className="d-flex flex-center justify-content-center align-content-center w-100" >
            <div className="text-white fw-bold fs-3x fs-lg-4x mt-10 mx-5">
                {/* <span style={{background: "linear-gradient(to top, #331168 5%, #FFFFFF 50%, #FFFFFF 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>                    
                    Our TermsConditions
                </span>  */}
                <span style={{background: "linear-gradient(to bottom, #FFFFFF 5%,  #FFFFFF55 70%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>                    
                    Terms &amp; Conditions
                </span>                                                     
            </div>                                                        
        </div> 
        {/* <Slider {...{
                  fade: true,                  
                  dots: false,
                  arrows: false,      
                  autoplay: true,
                  infinite: true,      
                  slidesToShow:   1,      
                  slidesToScroll: 1,                  
                  speed: 7500,
                  pauseOnHover: false
              }}
        >           
            <div className="d-flex flex-center justify-content-center align-content-center w-100 mt-0 ms-0 me-0" >
                <div className="text-center w-75 text-gray-200 fw-bold fs-6 opacity-50">
                    <div>A list of devices suitable for your business.</div>
                </div>                                            
            </div>
            <div className="d-flex flex-center justify-content-center align-content-center w-100 mt-0 ms-0 me-0" >
                <div className="text-center w-75 text-gray-200 fw-bold fs-6 opacity-50">
                    <div>Please select any device!</div>                    
                </div>                                            
            </div>
        </Slider>                                     */}
    </div>
  )
}

export {HeaderBackgroundTermsConditions, HeaderBannerTermsConditions}
