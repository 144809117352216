import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { ChangePassword, Staff } from "./staffModels";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
const auth = getAuthModel();

export const staffApi = createApi({
    reducerPath: "staffApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),

    tagTypes: ['Staff'],
    refetchOnMountOrArgChange: 30,

    endpoints: (builder) => ({
        
        getAllStaffs : builder.query<Staff[], void>({
            query: () => `staff`,
            providesTags: ['Staff'],
            keepUnusedDataFor: 5,              
        }),

        createStaff: builder.mutation<any, Staff>({
            query(data){
                return{
                    url: "staff",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ['Staff']
        }),

        updateStaff: builder.mutation<any, Staff>({
            query: ({id, ...rest}) => ({
                url: `staff/${id}`,
                method: "PATCH",
                body: rest
            }),
            invalidatesTags: ['Staff']
        }),
        activateStaff: builder.mutation<any, any>({
            query: (id) => ({
                url: `staff/${id}/activate`,
                method: "GET"
            }),
            invalidatesTags: ['Staff']
        }),
        deactivateStaff: builder.mutation<any, any>({
            query: (id) => ({
                url: `staff/${id}/deactivate`,
                method: "GET"
            }),
            invalidatesTags: ['Staff']
        }),
        changePasswordStaff: builder.mutation<any, ChangePassword>({
            query: ({id, ...rest}) => ({
                url: `staff/${id}/changepasswordstaff`,
                method: "PATCH",
                body: rest
            })
        }),

        changePasswordUser: builder.mutation<any, ChangePassword>({
            query: (data) => ({
                url: `staff/changepassworduser`,
                method: "PATCH",
                body: data
            })
        }),
    })
});

export const {
    useGetAllStaffsQuery,
    useCreateStaffMutation,
    useUpdateStaffMutation,
    useActivateStaffMutation,
    useDeactivateStaffMutation,
    useChangePasswordStaffMutation,
    useChangePasswordUserMutation
} = staffApi