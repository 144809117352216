import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionStaff, ChangePassword, InitialState } from "./staffModels";

const initialState: InitialState = {
    selectedStaff: {
        title: "Add New User"
    },
    changePassword: {
        id: "",
        mode: "user",
        currentPassword: "",
        newPassword: "",
        confirmedPassword: ""
    }
}

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers:{
        setSelectedStaff: (state, action: PayloadAction<ActionStaff>) => {
            state.selectedStaff = action.payload
        }, 
        setChangePassword: (state, action: PayloadAction<ChangePassword>) => {
            state.changePassword = action.payload
        }
    }
})

export default staffSlice.reducer
export const {
    setSelectedStaff,
    setChangePassword
} = staffSlice.actions

