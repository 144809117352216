/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice';
import { Media, Medias, defaultThumbnails, defaultTransparentGrids } from '../../../../redux/slices/media/mediaModels';
import { DraggableImage, DroppableZone, WebsiteZone } from '../EditorComponents';
import { defaultEditorLandscapeWidth, defaultEditorLandscapeHeight } from '../../../../redux/slices/editor/editorModels';
import { Content, ContentInput } from '../../../../redux/slices/content/contentModels';
import { useUpdateContentMutation } from '../../../../redux/slices/content/apiContentSlice';
import Swal from 'sweetalert2';
import { setContentName } from '../../../../redux/slices/content/contentSlice';


//----------------------------------------

type Props = {
  className: string,
  autoLayout? : boolean,
  maxH?: string,
  w?: number,
  h?: number,
}


//----------------------------------------
const PanelEditor: React.FC<Props> = ({className, maxH="mh-600px", autoLayout=true, w, h}) => {
  // const PanelEditor: React.FC<Props> = ({currentContent, className, maxH="mh-600px", autoLayout=true, w, h}) => {
  
  const { data: allMedias, isLoading: isLoadingMedias, isFetching: isFetchingMedias} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  

  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
  // console.log("currPersistContent in Panel Editor: ", currPersistContent)

  // ---------------------------------------
  // const renameContentHandler = async () => {
  //   const inputValue = currPersistContent.user_template_name
  //   // ------------------------------------------------------------

  //   Swal.fire({
  //     title: 'Campaign Name',
  //     input: 'text',
  //     inputValue,
  //     inputAttributes: {
  //       autocapitalize: 'off'
  //     },
  //     showCancelButton: true,
  //     confirmButtonText: 'Rename',
  //     showLoaderOnConfirm: true,
  //     preConfirm: (name) => {                  
  //       return name        
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
        
  //       // currPersistContent.user_template_name = result.value
  //       setContName(result.value)
  //       dispatch(setContentName(result.value))

  //       // -------------------------------
  //       var eleList:any[] = []
  //       currPersistContent.elements?.map((section, index) => {
  //         eleList.push({
  //           element_id  : section.element_id,
  //           position_id : section.position_id,
  //           alias       : section.alias,
  //           element_setting : JSON.stringify(section.element_setting),
  //           type            : section.element_type,
  //           source_ids      : JSON.stringify(section.sources),
  //         })
  //       })

  //       const content: ContentInput = {
  //         contentId:  currPersistContent? (currPersistContent.user_template_id? currPersistContent.user_template_id:""):"",
  //         user_template:{
  //           user_template_name  : result.value,
  //           template_id         : currPersistContent? (currPersistContent.template_id? currPersistContent.template_id:""):"",
  //           duration            : currPersistContent.duration,          
  //         },
  //         elements: eleList,
  //         emergency: currPersistContent.emergency? currPersistContent.emergency : 0
  //       }         
  //       await updateContent(content)

  //       Swal.fire({
  //         // position: 'bottom-end',
  //         icon: 'success',
  //         title: 'Your campaign has been renamed.',
  //         showConfirmButton: false,
  //         timer: 1500
  //       })
        
  //     }
  //   })        
  // }

  //-----------------------------------------
  return (
    <>
      {! isLoadingMedias && (
        <div 
          id="editor-panel"
          className={`card rounded-0 shadow-lg ${className} ${maxH}`} 
          style={{
            width:`${w}px`, 
            height:`${h}px`, 
            // backgroundColor: "#000000"
            background:`url(${defaultTransparentGrids[0]}) no-repeat center center / cover`
          }}
        > 
          {/* <div 
            key="card-01"
            className='d-flex justify-content-between position-absolute top-0 end-0 mt-n8 py-1 px-3 bg-gray-300 text-gray-400 rounded-top'
          >
            <span className='me-2'>Name: </span>
            <div className='d-flex justify-content-between'>
            {(currPersistContent && currPersistContent.user_template_name !== "")          
                ? <>
                    <span className='text-gray-400 text-truncate text-end'>{currPersistContent.user_template_name}</span>
                    <a className='ms-2 tooltip-bottom' data-tooltip-content='Rename'
                      onClick={()=> renameContentHandler() }
                    ><i className='fa fa-edit text-gray-400'></i>
                    </a>
                  </>
                : <span className='text-gray-400'>Unknown</span>
            }    
            </div>
          </div>  */}

          <div 
            className='card-body p-0 ' 
            key="card-02"
          >
            {currPersistContent.elements?.map((section, ind) => {

              // console.log("currentContent inside:", currentContent)              
              // console.log("section inside:", section)
              // console.log("section inside:", section.type)
              // -----------------
              return (<Fragment key={ind}>
                  <Fragment key={section.element_id}>
                    {
                      {
                        "image" : <DroppableZone  className="" section={section} sectionId={(ind+1).toString().padStart(2, '0')}/>,
                        "video" : <DroppableZone  className="" section={section} sectionId={(ind+1).toString().padStart(2, '0')}/>,
                        "text"  : <DroppableZone  className="" section={section} sectionId={(ind+1).toString().padStart(2, '0')}/>,
                        "web"   : <WebsiteZone    className="" section={section} sectionId={(ind+1).toString().padStart(2, '0')}/>,                        
                      }[section.type]
                    }  
                  </Fragment>
              </Fragment>)}
            )}            
          </div>
        </div>
              
      )
      }
    </>
  )
}

export {PanelEditor}