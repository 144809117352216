/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

import { 
    FormControl,
    InputLabel, MenuItem, 
    Select,
    FormHelperText
} from '@mui/material'
// import Select from "react-select";

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { useChangeMediaFolderMutation, useGetAllRootFoldersQuery } from '../../../../redux/slices/media/apiMediaSlice'
import { ChangeFolder } from '../../../../redux/slices/media/mediaModels'
import { setMultiSelectMode, setSelectedMediaSrcIds } from '../../../../redux/slices/editor/editorSlice'


type Props = {  
    className?: string
}

const schema = yup.object().shape({
    newGroupId: yup.string().required().min(3).max(100)
})

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerChangeFolder: FC<Props> = ({className}) => {

    const dispatch = useAppDispatch()  
    //-----------------------------------------
    const [changeMediaFolder] = useChangeMediaFolderMutation();


    const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
    
    const currentFolder = useAppSelector(state => state.editor.currentFolder)  
    // console.log("currentFolder",currentFolder)
    const selMediaSrcIds = useAppSelector(state => state.editor.selectedMediaSrcIds) 
    // console.log("selMediaSrcIds",selMediaSrcIds)

    const [destFolder, setDestFolder] = useState<string>("")
  


    //-----------------------------------------    

    const { 
        register, 
        setValue,
        handleSubmit,
        // control,
        reset,
        watch,
        setError,
        trigger,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful
            
        }
    } = useForm<ChangeFolder>({
        resolver: yupResolver(schema),
        defaultValues:{            
            newGroupId: ""
        }
    }) 

    //---------------------------------------------------- 
    const formSubmitHandler: SubmitHandler<ChangeFolder> = async ( data ) => {  

        try{
                var res = changeMediaFolder({
                                        sourceIds: selMediaSrcIds, 
                                        newGroupId: data.newGroupId
                                    });
                let objRes = Object.values(res)
                // console.log("change folder result", [objRes]);

            // Reset
            dispatch(setMultiSelectMode(false))
            dispatch(setSelectedMediaSrcIds([]))

            // -----

            const destFolderName = rootFolders?rootFolders.data.find((folder) => folder.source_group_id === data.newGroupId)?.group_name : ""

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-right',
                iconColor: 'green',
                customClass: {
                popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 2000,
                // timerProgressBar: true
            })
            Toast.fire({
                icon: 'success',
                title: `Folder has been succesfully moved to <span class='text-primary fs-4'>"${destFolderName}"</span>.`,
            })
           
            reset()
            setDestFolder("")
            
        }catch(error){
            console.log("error", error)
            Swal.fire(
                'There is something wrong during the change!',
                'error'
            )            
        
        }
    }  
    


    //----------------------------------------------------
    return (
            <div
                id="kt_drawer_change_folder"
                data-kt-drawer-toggle={`#kt_drawer_change_folder_button`}
                data-kt-drawer-close= {`#kt_drawer_change_folder_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
                // data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                // data-bs-backdrop="static" 
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-gradient-primary">
                    <h5 className="offcanvas-title text-white" id="staticBackdropLabel">
                        <KTSVG
                            path={`/media/icons/duotune/art/art005.svg`}
                            className={`svg-icon-2 svg-icon-white me-2 mt-n2 d-inline`}
                        />
                        Change Folder of
                        {selMediaSrcIds && selMediaSrcIds.length > 0 &&
                            <span> {selMediaSrcIds.length} file{selMediaSrcIds.length > 1? "s":""}</span>
                        }                  
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                    
                    ></button>                        
                </div>
                <div className="offcanvas-body text-start">
                    {selMediaSrcIds && selMediaSrcIds.length > 0 &&
                        <div className="">

                            <form id="frm-edit-layer" className='form mt-5'  
                                onSubmit={handleSubmit(formSubmitHandler)}
                            >              
                                <div className="mb-2">                                
                                    <FormControl fullWidth>
                                        <InputLabel id="select-layout-label">Destination Folder</InputLabel>
                                        <Select 
                                            {...register("newGroupId")} 
                                            labelId="select-folder-label"                                        
                                            label="Destination Folder"
                                            value={destFolder}
                                            onChange={(e) => {
                                                setDestFolder(e.target.value)
                                                setValue('newGroupId', e.target.value)
                                                trigger("newGroupId") //manually trigger the form or input validation
                                            }}
                                            error={!! errors.newGroupId}                                        
                                            MenuProps={{ disablePortal: true }}
                                        >                                         
                                            <MenuItem key={`folder----`} value={""} className='w-100' disabled>
                                                Select a folder
                                            </MenuItem>
                                            {   (rootFolders
                                                    ?   rootFolders.data.slice().sort((a,b)=> a.group_name.toLowerCase() > b.group_name.toLowerCase()? 1: -1)
                                                    :   []
                                                )
                                                .map((folder, ind) => {
                                                    return (
                                                        <MenuItem key={`folder--${ind}`} value={folder.source_group_id} 
                                                            className='w-100'
                                                            disabled={folder.source_group_id === currentFolder.source_group_id}
                                                        >
                                                            <div className='w-100 d-flex justify-content-between'>
                                                                <div className={`w-200px text-capitalize`}>
                                                                    <i className='fa fa-folder me-2'></i>                                                                                
                                                                    <span className=''>{folder.group_name}</span>
                                                                </div>
                                                                <div className='d-flex flex-column'> 
                                                                    {folder.sources && folder.sources.length > 0 && 
                                                                        <span className={`badge badge-secondary w-100 min-w-125px}`}>
                                                                            {folder.sources?.length}
                                                                        </span> 
                                                                    }                                                                
                                                                </div> 
                                                            </div> 
                                                        </MenuItem>
                                                    )
                                                }) 
                                            }                                       
                                        </Select>
                                        <FormHelperText>{`${errors.newGroupId?.message ?? ''}`}</FormHelperText>
                                    </FormControl>                                                                
                                </div> 

                                
                                
                                <div className="pt-8 text-end row">
                                    <div className='col-12 text-end'>
                                        <button type="submit" className="btn btn-primary w-100" 
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                            disabled={isSubmitting || !isValid}
                                        >                                
                                            {/* <i className="fa fa-save fs-3 me-1 mt-n1"></i> */}
                                            Change Folder
                                        </button>
                                    </div>
                                    
                                </div>
                                {/*
                                <button type="button" 
                                    className="btn btn-icon btn-light position-absolute bottom-0 end-0 me-7 mb-8" 
                                    data-bs-dismiss="offcanvas"
                                    data-kt-drawer-dismiss="true"
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr001.svg'
                                        className='svg-icon svg-icon-1x'
                                    />
                                </button>
                                    */}
                            </form>

                        </div>
                    }
                </div>
            </div>
    )
}

export {DrawerChangeFolder}