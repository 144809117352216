/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useAppSelector } from '../../../../redux/hook'
import { Sched, extractRepeatModeFromSchedule } from '../../../../redux/slices/schedule/scheduleModels'
import { useGetAllGroupsQuery, useGetGroupQuery } from '../../../../redux/slices/group/apiGroupSlice'
import { RECENTPERIOD, contentPreviewPath, getRibbonType } from '../../../../redux/slices/content/contentModels'
import { CardContent } from '../../../content/components/ContentComponents'
import moment from 'moment'
import { ScheduleInfo } from '../ScheduleComponents'







type Props = {  
    className?: string
}

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerViewScheduleDetail: FC<Props> = ({className}) => {

    const currSchId = useAppSelector(state => state.schedule.currSchedId)  
    const allSchs   = useAppSelector(state => state.schedule.allScheds)  
    //---------------------------------------------------- 
    const currSchs: Sched[] = allSchs.filter((sch)=> {return sch.id === currSchId})
    
    //----------------------------------------------------
    return (
        <>  
            <div
                id='kt_drawer_view_schedule'
                className='bg-body '
                data-kt-drawer='true'
                data-kt-drawer-name='drawer_view_schedule'
                data-kt-drawer-activate='true'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width="{default:'300px', 'md': '350px', 'lg': '450px'}"
                
                // data-kt-drawer-direction='end'
                data-bs-backdrop="static" 
                data-kt-drawer-toggle='#kt_drawer_view_schedule_toggle'
                data-kt-drawer-close='#kt_drawer_view_schedule_close'
            >
                <div className='card shadow-none w-100'>
                    <div className='card-header bg-gradient-primary rounded-0' id='kt_drawer_view_schedule_header'>
                        <h3 className="card-title text-white">
                            <KTSVG
                                path={`/media/icons/duotune/general/gen014.svg`}
                                className={`svg-icon-1x svg-icon-white me-3 mt-n2 d-inline`}
                            />
                            Schedule Details
                        </h3>

                        <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                                id='kt_drawer_view_schedule_close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </button>
                        </div>
                    </div>
                    <div className='card-body position-relative' id='kt_drawer_view_schedule_body'>
                        <div
                            id='kt_drawer_view_schedule_scroll'
                            className='position-relative scroll-y me-n5 pe-5 text-start'
                            data-kt-scroll='true'
                            data-kt-scroll-height='auto'
                            data-kt-scroll-wrappers='#kt_drawer_view_schedule_body'
                            data-kt-scroll-dependencies='#kt_drawer_view_schedule_header, #kt_drawer_view_schedule_footer'
                            data-kt-scroll-offset='5px'
                        >
                            { currSchs.length>0 && <>
                                { currSchs.slice(0).reverse().map((sch)=> {
                                    const repeatMode = extractRepeatModeFromSchedule ([sch])
                                    // ------------------------
                                    return (
                                        <Fragment key={sch.id}>                                            
                                            {sch.user_templates.map((content) => (
                                                <div className="w-100 row p-0" key={content.user_template_id} >
                                                    <div className="col-7 pt-1">
                                                        <CardContent 
                                                            className="" 
                                                            content = {content}
                                                            autoLayout={true}
                                                            displayIcon={false}
                                                            actionIcons={false}
                                                            noTitle={false}
                                                            linkTitle={true}
                                                            ribbonType = { getRibbonType(content) }
                                                        />    
                                                    </div>
                                                    <ScheduleInfo className='col-5 small pt-1 px-0 ' sch={sch} repeatMode={repeatMode} />
                                                </div>
                                            ))}
                                            
                                        </Fragment> 
                                    )})
                                }                                
                                
                                {/* Including the analytical and tracking data  */}

                            </>}
                            
                        </div>
                    </div>                    
                </div>
            </div>

        </>
    )
}

export {DrawerViewScheduleDetail}