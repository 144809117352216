/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

import { 
    TextField, 
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { useUpdateContentMutation } from '../../../../redux/slices/content/apiContentSlice'
import { setContentSourceLayer, setLayerId, setSectionId } from '../../../../redux/slices/content/contentSlice'
import { Source, SourceInput } from '../../../../redux/slices/content/contentModels'


type Props = {  
    className?: string
}

const schema = yup.object().shape({
    order: yup.number().required(),
    dur: yup.number().required()
})



// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerAddToSection: FC<Props> = ({className}) => {

    const dispatch = useAppDispatch()  
    //-----------------------------------------
    const [updateContent] = useUpdateContentMutation();
    
    //--------------------------------------------- 
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
    // console.log("currPersistContent in Edit Layer: ",currPersistContent)
    // console.log(currPersistContent.elements[0].sources)

    const currSectId = useAppSelector(state => state.content.currSectionId)  
    // console.log('currSectId:',currSectId)

    const currLayerId = useAppSelector(state => state.content.currLayerId)  
    // console.log('currLayerId:',currLayerId)
    
    const [selectedLayer, setSelectedLayer] = useState<Source>()
    // console.log('selectedLayer outside:',selectedLayer)
    // console.log("...selectedLayer", {...selectedLayer})

    // const [isDeleted, setIsDeleted] = useState(false);
    // const currentFolder = useAppSelector(state => state.editor.currentFolder)  
    // const [updateFolder] = useUpdateFolderMutation();
    // const [deleteFolder] = useDeleteFolderMutation();

    const [duration, setDuration] = useState<number>(selectedLayer? (selectedLayer.param_source[0].duration?selectedLayer.param_source[0].duration/1000:0):0)
    const [maxOrder, setMaxOrder] = useState<number>(0)
    // console.log("duration in sec.", duration)
    //-----------------------------------------    
    
    

    const { 
        register, 
        setValue,
        handleSubmit,
        // control,
        reset,
        watch,
        setError,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful
            
        }
    } = useForm<SourceInput>({
        resolver: yupResolver(schema),
        defaultValues:{            
            ...selectedLayer
        }
    }) 
    //---------------------------------------------------- 
    
    // console.log('watch', watch())
    // console.log('watch', watch(["name", "displayType", "category"]))    
    // console.log("watch param_source:", watch("param_source"))
    

    //---------------------------------------------------- 
    const handleOrderChange = (inputOrder: number) =>{
        
        if (inputOrder <= 0){
            inputOrder = 1
        }else if (inputOrder > maxOrder){
            inputOrder = maxOrder     
        }

        reset({
            ...selectedLayer,
            order: inputOrder,
            param_source: [{
                ...selectedLayer?.param_source[0],
                duration: (duration * 1000)
            }]
        })
    }

    //---------------------------------------------------- 
    const formSubmitHandler: SubmitHandler<SourceInput> = async (layer: SourceInput) => {        
        try{
            // console.log('currSectId:',currSectId)
            // console.log('currLayerId:',currLayerId)
            // console.log("layer in submit", layer)
            
            dispatch(setSectionId(currSectId))  //which section it belongs to
            dispatch(setLayerId(currLayerId))  //which section it belongs to
            delete layer["dur"]
            dispatch(setContentSourceLayer(layer))
           
        }catch(error){
            // console.log("error", error)
            Swal.fire(
                'Layer: '+ layer.source_list_id,
                'There is something wrong during the update request!',
                'error'
            )            
        
        }
    }
   
    // ---------------------------
    useEffect(() => { 
        const selectedSect = currPersistContent.elements.find((sect, ind)=> sect.element_id === currSectId)
        setMaxOrder(selectedSect? selectedSect.sources.length : 0)

        
        const foundSect = currPersistContent.elements.find((sect)=>sect.element_id === currSectId)?.sources.find((layer)=>layer.source_list_id === currLayerId)
        // console.log('foundSect in useEffect:',foundSect)

        if (currPersistContent && foundSect){
            const dur = foundSect? (foundSect.param_source[0].duration? foundSect.param_source[0].duration/1000:0):0
            
            setSelectedLayer(foundSect)
            setDuration(dur)

            reset({
                ...foundSect,
                order: foundSect?.order,
                param_source: [{
                    ...foundSect?.param_source[0],
                    duration: (dur * 1000)
                }]
            })
        }

    }, [ currLayerId])
 
    // ---------------------------
    useEffect(() => {         
        reset({
            ...selectedLayer,
            order: selectedLayer?.order,
            param_source: [{
                ...selectedLayer?.param_source[0],
                duration: (duration * 1000)
            }]
        })
        // console.log("selectLayer in useEffect: ", selectedLayer)
        // console.log("param_source in useEffect: ",watch(["param_source"]))
    }, [ duration, reset ])    


    //----------------------------------------------------
    return (
            <div
                id="kt_drawer_add_to_section"
                data-kt-drawer-toggle={`#kt_drawer_add_to_section_button`}
                data-kt-drawer-close= {`#kt_drawer_add_to_section_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
                // data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                // data-bs-backdrop="static" 
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-gradient-primary">
                    <h5 className="offcanvas-title text-white" id="staticBackdropLabel">
                        <KTSVG
                            path={`/media/icons/duotune/art/art005.svg`}
                            className={`svg-icon-2 svg-icon-white me-2 mt-n2 d-inline`}
                        />
                        Add To Section                        
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                    
                    ></button>                        
                </div>
                <div className="offcanvas-body text-start">
                    <div>
                        {/* for img and video */}
                        {selectedLayer && selectedLayer.source &&                            
                            {
                                "image":    <img src={selectedLayer.source.url} className='w-100'/>,
                                "video":    <video key={selectedLayer.source.url} autoPlay={true} controls muted className='w-100 h-100'>
                                                <source src={selectedLayer.source.url} type="video/mp4" />
                                            </video>,                                
                            }[selectedLayer.source.type]
                        }
                        
                        {/* for web */}
                        {/* {selectedLayer && selectedLayer.param_source && selectedLayer.param_source[0].url && selectedLayer.param_source[0].url !== "" &&                            
                            <iframe src={selectedLayer.param_source[0].url} className='w-100 h-450px' scrolling='no'></iframe>
                        } */}
                        {/* for text */}
                        
                    </div>

                    {selectedLayer && selectedLayer.source &&                            
                        {
                            "image":    <span className='fs-7 text-capitalize'>{selectedLayer?.source?.name}</span>,
                            "video":    <span className='fs-7 text-capitalize '>{selectedLayer?.source?.name}</span>,
                        }[selectedLayer.source.type]
                    }
                    {selectedLayer && selectedLayer.param_source && selectedLayer.param_source[0].url && selectedLayer.param_source[0].url !== "" &&                            
                        <span className='badge badge-light fs-7 ms-2'>
                            <i className="bi bi-globe text-primary fs-7 me-3"></i>
                            {selectedLayer.param_source[0].url}
                        </span>
                    }
                    
                   
                    {/* <FormProvider {...methods} > */}
                        <form id="frm-edit-layer" className='form mt-5'  
                            onSubmit={handleSubmit(formSubmitHandler)}
                        >              
                            <div className="mb-3">
                                <TextField 
                                    {...register("dur")} 
                                    value={duration}
                                    type="number"
                                    label="Duration (sec)" 
                                    fullWidth 
                                    margin='dense'
                                    variant='outlined' 
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        setDuration(parseInt(e.target.value)) 
                                    }}
                                    error={!! errors.dur}
                                    helperText={`${errors.dur?.message ?? ''}`}
                                    // focused={watch(`param_source[0][duration]`) ? true:false}                                    
                                />
                            </div>
                            <div className="mb-3">
                                <TextField 
                                    {...register("order")}                                     
                                    type="number"
                                    label="Order" 
                                    fullWidth 
                                    margin='dense'
                                    variant='outlined'                                     
                                    hidden={true}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => handleOrderChange(parseInt(e.target.value))}
                                    error={!! errors.order}
                                    helperText={`${errors.order?.message ?? `Min: 1, Max: ${maxOrder}`}`}
                                    disabled = {maxOrder === 1? true: false}
                                />
                            </div>


                            <div className="mb-3" hidden={true}>
                                <TextField 
                                    {...register(`param_source.0.duration`)} 
                                    // value={duration * 1000}
                                    type="number"
                                    label="Duration (sec)" 
                                    fullWidth 
                                    margin='dense'
                                    variant='outlined' 
                                    InputLabelProps={{ shrink: true }}
                                    // error={!! errors.group_name}
                                    // helperText={`${errors.group_name?.message ?? ''}`}
                                    // focused={watch(`param_source[0][duration]`) ? true:false}                                    
                                />
                            </div>
                            
                            <div className="pt-8 text-end row">
                                <div className='col-12 text-end'>
                                    <button type="submit" className="btn btn-primary w-100" 
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        disabled={isSubmitting || !isValid}
                                    >                                
                                        {/* <i className="fa fa-save fs-3 me-1 mt-n1"></i> */}
                                        Apply Change
                                    </button>
                                </div>
                                
                            </div>
                            {/*
                            <button type="button" 
                                className="btn btn-icon btn-light position-absolute bottom-0 end-0 me-7 mb-8" 
                                data-bs-dismiss="offcanvas"
                                data-kt-drawer-dismiss="true"
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr001.svg'
                                    className='svg-icon svg-icon-1x'
                                />
                            </button>
                                */}
                        </form>
                    {/* </FormProvider> */}
                </div>
            </div>
    )
}

export {DrawerAddToSection}