import {FC, useEffect, Dispatch, SetStateAction} from 'react'
//import {useIntl} from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { PanelEditor, PanelRight, PanelTop, PanelBottom, DrawerEditLayer, 
  ModalNewFolder, ModalUploadFiles, ModalSelectImageVideo, DrawerEditFolder, DrawerEditSection, 
  AccordionHideShowBtn, DrawerAddToSection, DrawerChangeFolder,   
  DrawerTextEditor
} from './EditorComponents'

import { useAppSelector, useAppDispatch } from "../../../redux/hook";
import { 
  enableEditorMode, 
  setCurrentEditorWidth, 
  setCurrentEditorHeight, 
  setSelectedMenu,
  setZoomPercentage
} from '../../../redux/slices/editor/editorSlice'
import { getCorrespondWidth, getCorrespondHeight } from '../../../redux/slices/editor/editorModels';
import { DrawerAddInstantSchedule } from '../../schedule/components/ScheduleComponents';
import { useParams } from 'react-router-dom';
import { ModalPreviewContent } from '../../content/components/ContentComponents';
import { ModalPreviewVideo } from './menu/tabContents/tabGallery/modals/ModalPreviewVideo';
import WebFont from 'webfontloader'
import { DEFAULT_ZOOM_PERCENTAGE } from '../../../redux/slices/content/contentModels';


type NewType = {
  // link: string;
  setLink?: Dispatch<SetStateAction<string>>;
};

type Props = NewType


const Index: FC<Props> = ({setLink}) => {
  
  const dispatch = useAppDispatch()
  // ------------------------------------------
  const { id } = useParams(); //get the tabLink, e.g. /editor/index/gallery

  /*
  const isEditor = useAppSelector(state => state.editor.editorModeOn)
  
  const currEditorW = useAppSelector(state => state.editor.currEditorWidth)
  const currEditorH = useAppSelector(state => state.editor.currEditorHeight)
  */
  const currEditorZoom = useAppSelector(state => state.editor.zoomPercentage)
  const hidePanelR = useAppSelector(state => state.editor.hidePanelRight)

  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)

  useEffect(() => {    
    dispatch(setZoomPercentage(currPersistContent.width < currPersistContent.height ? 1 : DEFAULT_ZOOM_PERCENTAGE))
  }, [])

  useEffect(() => {
    dispatch(enableEditorMode())
    dispatch(setCurrentEditorWidth(currPersistContent.width))
    dispatch(setCurrentEditorHeight(currPersistContent.height))    
  }, [currPersistContent])

  useEffect(() => {
    if (id)  {
      dispatch(setSelectedMenu(id))
    }
  }, [id])

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Bayon',
          'Bungee Shade',
          'Eater',
          'Honk',
          'Jura',
          'Monoton',
          'Open Sans', 
          'Parisienne',
          'Roboto', 
          'TitanOne',          
        ]
      }
    });
  }, []);

  // ------------------------------------------------
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EDITOR'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}> </PageTitle>

      {/* <HeaderWrapper /> */}
      
        
    
      <div className='d-flex flex-row h-100' style={{marginLeft:"-30px", marginRight:"-30px" }}>
        
        <div className='d-flex flex-column flex-row-fluid'>
          {/* Panel Top */}
          <div className="d-flex flex-column-auto flex-center">            
            <PanelTop className='w-100 h-100 py-3 px-9 bg-gray-200'/>            
          </div>
          {/* <div className="w-100">            
            <PanelContentDesc className='w-100 h-100 py-3 px-5 '/>
          </div> */}
          
          
          {/* Panel Middle - Editor */}
          <div className="d-flex flex-row flex-column-fluid">
            <div className="d-flex flex-column flex-column-fluid flex-center p-10 ps-0 pt-15 w-100"> 
                     
              {/* portrait or landscape with different size */}
              <PanelEditor 
                // currentContent={currPersistContent}
                className={`card-xxl-stretch`}
                // w={getCorrespondWidth(currEditorW, currEditorW)} 
                // h={getCorrespondHeight(currEditorH, currEditorH)} 
                w={getCorrespondWidth(currPersistContent.width, currPersistContent.height, currPersistContent.width, currPersistContent.height, currEditorZoom)} 
                h={getCorrespondHeight(currPersistContent.width, currPersistContent.height, currPersistContent.width, currPersistContent.height, currEditorZoom)} 
              />              
            </div>
          </div>

          {/* Panel Bottom */}
          <div className="d-flex flex-column-auto h-50px flex-center">            
            <PanelBottom className='w-100 h-100 py-3 px-5'/>
          </div>
        </div>
       
      
        <div className='d-xl-flex flex-xl-column flex-xl-row-auto w-250px'>
          <AccordionHideShowBtn/>
          {!hidePanelR && 
            <div className={`d-flex flex-column-fluid align-items-start bg-white`} style={{zIndex:"1030", borderLeft: "1px solid #E4E6EF"}}>
              <PanelRight className='card-xxl-stretch w-100'/>
            </div>
          }
        </div>
      </div>     
      <DrawerEditLayer/>
      <DrawerTextEditor className=''/>
      <DrawerEditFolder />
      <DrawerEditSection/>
      <DrawerAddInstantSchedule/>

      <DrawerChangeFolder />
      <DrawerAddToSection />

      <ModalNewFolder/>
      <ModalUploadFiles />
      <ModalSelectImageVideo />
      <ModalPreviewContent className="" id="modal-preview-content"/>
      <ModalPreviewVideo className="" id="modal-preview-video"/>
      {/* <ModalTextEditor className='' id="modal-text-editor"/> */}
    </>
  )
}

export {Index}