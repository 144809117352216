/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

import { 
    FormControl,
    Checkbox, 
    InputLabel, MenuItem, 
    Select,
    TextField, 
    FormHelperText
} from '@mui/material'
// import Select from "react-select";

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { useDeleteFolderMutation, useDeleteMediaMutation, useGetAllMediasQuery, useGetAllRootFoldersQuery, useUpdateFolderMutation } from '../../../../redux/slices/media/apiMediaSlice'
import { Folder, Media } from '../../../../redux/slices/media/mediaModels'
import { setCurrentFolder, setCurrentFolderEmpty } from '../../../../redux/slices/editor/editorSlice'
import { useGetAllContentsQuery, useUpdateContentMutation } from '../../../../redux/slices/content/apiContentSlice'
import { Content, ContentInput, EleInput, Source } from '../../../../redux/slices/content/contentModels'
import { setContentSource, setSectionId } from '../../../../redux/slices/content/contentSlice'


type Props = {  
    className?: string
}

const schema = yup.object().shape({
    group_name: yup.string().required().min(3).max(100),    
})

// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerEditFolder: FC<Props> = ({className}) => {
    const dispatch = useAppDispatch()  
    //-----------------------------------------
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
    const { data: allContents, isLoading: isLoadingContents, isFetching: isFetchingContents} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
    // console.log("allContents", allContents)


    const [isDeleted, setIsDeleted] = useState(false);
    const [existFolderNames, setExistFolderNames] = useState<string[]>([])
    const [grpName, setGrpName] = useState<string>("")

    const currentFolder = useAppSelector(state => state.editor.currentFolder)
      
    const [updateFolder] = useUpdateFolderMutation();
    const [deleteFolder] = useDeleteFolderMutation();
    const [updateContent] = useUpdateContentMutation()
    const [deleteDraggableImage] = useDeleteMediaMutation()
    //-----------------------------------------    
    const { data: allMedias, isLoading: isLoadingAllMedias, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
    // console.log("allMedias")  
    // console.log(allMedias)

    // console.log('currentFolder',currentFolder)

    const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
    // console.log("rootFolders in DrawerEditFolder",rootFolders)      

    const nbMediaFile = allMedias?.data.filter((file)=> {
        return (file.type !== "external") && (file.source_group_id === currentFolder.source_group_id)
    }).length
    
    

    const { 
        register, 
        setValue,
        handleSubmit,
        // control,
        trigger,
        reset,
        watch,
        setError,
        clearErrors,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful
            
        }
    } = useForm<Folder>({
        resolver: yupResolver(schema),
        defaultValues:{            
            
            group_name: currentFolder.group_name, 
            
        }
    }) 
    //---------------------------------------------------- 
    
    // console.log('watch name', watch(["name", "displayType", "category"]))
    
    const handleDeleteFolder = (folder: Folder) => {
        // console.log("folder:", folder)

        if(folder.source_group_id === "9MjPVbPNkO")
        {
            Swal.fire(
                'Permission denied!',
                'You are not allowed to delete this folder.',
                'warning'
            )
        }else{
            Swal.fire({
                title: 'Do you really want to delete this folder?<h1 style="color:#331168; margin: 15px 0;">'+ folder.group_name +'</h1>',
                text: "All media files under this folder will be deleted!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#9C258F',
                cancelButtonColor: '#E4E6EF',
                confirmButtonText: 'Yes, delete it!',
                customClass: {          
                  cancelButton: 'order-1 left-gap me-5',
                  confirmButton: 'order-2',
                  
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    
                    const deletedMediaIds = folder.sources?.map(med => med.source_id)

                    // Delete all the media inside the folder
                    // ------------------------------------------
                    if (deletedMediaIds && deletedMediaIds.length>0){
                        try{
                            // 1- delete images from all the related contents if any
                            // --------------------------------------------------
                            allContents?.map((content: Content, ind)=> {
                                const newEleList : EleInput[] = []
                                var flagUpdate : boolean = false
                                // ----------------------------
                    
                                content.elements.map((section, indSect) => {
                                    var sourcesAfterDelete : Source[] = []
                    
                                    if (["image", "video"].includes(section.element_type.toLowerCase())){
                                        sourcesAfterDelete = section.sources?.filter((src)=>{
                                            return (src.source && !(deletedMediaIds?.includes(src.source.source_id)))                            
                                        })                                            
                        
                                        if (section.sources.length !== sourcesAfterDelete.length){
                                            flagUpdate = true
                                            // console.log(ind, "content", content)
                                            // console.log("ele.sources: ",section.sources)
                                            // console.log("sourcesAfterDelete: ",sourcesAfterDelete)
                                        }
                                    }
                    
                                    newEleList.push({
                                        element_id      : section.element_id,
                                        position_id     : section.position_id,
                                        alias           : section.alias,                        
                                        element_setting : JSON.stringify(section.element_setting),
                                        type            : section.element_type,
                                        source_ids      : JSON.stringify(flagUpdate === false? section.sources : sourcesAfterDelete),
                                    })
                                })

                                if (flagUpdate){
                                    const newContent :ContentInput = {
                                        contentId: content.user_template_id?content.user_template_id:"",
                                        user_template: {
                                        template_id : content.template_id?content.template_id:"",
                                        user_template_name: content.user_template_name,
                                        duration: content.duration
                                        },                            
                                        elements: newEleList
                                    }
                                    // console.log("newContent : ", newContent)
                                                            
                                    updateContent(newContent) //update the content correspondance
                                }
                            })
                    
                            // 2- Delete images from currContent
                            // --------------------------------------------------
                            // console.log("currPersistContent ---: ", currPersistContent)

                            currPersistContent.elements.map((section, indSect)=>{
                                var sourcesAfterDelete : Source[] = []
                    
                                if (["image", "video"].includes(section.element_type.toLowerCase())){
                                    sourcesAfterDelete = section.sources?.filter((src)=>{
                                        return (src.source && !(deletedMediaIds?.includes(src.source.source_id)))
                                    })

                                    // console.log("currPersistContent : ", currPersistContent)
                                    // console.log("section.sources : ", section.sources)
                                    // console.log("sourcesAfterDelete : ", sourcesAfterDelete)

                                    if (section.sources.length !== sourcesAfterDelete.length){
                                        dispatch(setSectionId(section.element_id))  //which section it belongs to
                                        dispatch(setContentSource(sourcesAfterDelete))
                                    }
                                }
                            }) 
                    
                            // 3 - Delete the image source
                            // ----------------------------
                            deletedMediaIds?.forEach((src_id, ind) => {
                                deleteDraggableImage(src_id)
                            })

                            // 4 - If it's a bin, then back to root 'My Gallery' 
                            if (folder.source_group_id === "bin"){
                                dispatch(setCurrentFolderEmpty())
                            }
                                
                        }catch(error){
                            Swal.fire(
                                'File: ',
                                'There is something wrong during the deletion!',
                                'error'
                            )                                    
                        }
                    }

                    // Delete the folder
                    // -------------------------------
                    if (currentFolder.source_group_id !== "bin"){
                        if (deletedMediaIds && deletedMediaIds.length>0){
                            setTimeout(() => {
                                deleteFolder(folder.source_group_id)     
                            }, 3000);
                        }else{
                            deleteFolder(folder.source_group_id)     
                        }
                    }
                    
                    reset()
                    setIsDeleted(true)     
                    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        iconColor: 'green',
                        customClass: {
                        popup: 'colored-toast'
                        },
                        showConfirmButton: false,
                        timer: 2000,
                        // timerProgressBar: true
                    })
                    Toast.fire({
                        icon: 'success',
                        title: `Folder: <span class="text-primary fw-bold">'${folder.group_name}'</span><br/> has been succesfully deleted.`,
                    })

                    //reset to return back to root folder
                    setCurrentFolder({
                        source_group_id: "",
                        group_name: "",
                    })
                    dispatch(setCurrentFolderEmpty())
                    
                }
            })
        }
    }


    const formSubmitHandler: SubmitHandler<Folder> = async (folder: Folder) => {        
        
        if(currentFolder.source_group_id === "9MjPVbPNkO")
        {
            Swal.fire(
                'Permission denied!',
                'You are not allowed to edit this folder.',
                'warning'
            )
        }else{
            try{
                const updatedFolder = {
                    ...folder,
                    source_group_id: currentFolder.source_group_id      
                }
                
                updateFolder(updatedFolder)
                // setCurrentFolder(folder)
                // console.log("rootFolders",rootFolders)
                // console.log("updatedFolder",updatedFolder)
                // console.log("folder",folder)
                // console.log("from root folder: ", rootFolders?.data.filter((fold, index)=> fold.source_group_id === currentFolder.source_group_id)[0])
                // setCurrentFolder(rootFolders?.data.filter((fold, index)=> fold.source_group_id === currentFolder.source_group_id)[0]?)
            
                const msg = ( currentFolder.group_name !== folder.group_name ? currentFolder.group_name +" => "+ folder.group_name+ "<br/>": "")

                Swal.fire({
                    // position: 'bottom-start',
                    icon: 'success',
                    title: 'Folder Update: '+ (msg !== "" ? "<br/>&nbsp;<br/><div className='my-5'>"+msg+"</div><br/>":""),
                    text: 'has been successfully done!',
                    showConfirmButton: false,
                    timer: 2000
                })                
                
            }catch(error){
                Swal.fire(
                    'Group: '+ folder.group_name,
                    'There is something wrong during the update request!',
                    'error'
                )            
            
            }
        }
    }
   
    useEffect(() => {        
        setValue("group_name", currentFolder.group_name)
        setIsDeleted(false)
    }, [ currentFolder, reset ])


    useEffect(()=>{
        setExistFolderNames(rootFolders?rootFolders.data.map((folder: Folder)=> folder.group_name.toLowerCase()):[])
    },[rootFolders])

    useEffect(()=>{
        if (existFolderNames.includes(grpName.toLowerCase()) && (currentFolder.group_name && (grpName.toLowerCase() !== currentFolder.group_name.toLowerCase()))){
          setError("group_name", { type: 'custom', message: 'This name has already exist' });
        }else{      
          clearErrors("group_name");
        }
    },[grpName])

    
    //----------------------------------------------------
    return (
        <>  
            <div
                id="kt_drawer_edit_folder"
                data-kt-drawer-toggle={`#kt_drawer_edit_group_button`}
                data-kt-drawer-close= {`#kt_drawer_edit_group_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-light-primary">
                    <h5 className="offcanvas-title text-primary" id="staticBackdropLabel">
                        <KTSVG
                            path={`/media/icons/duotune/art/art005.svg`}
                            className={`svg-icon-1x svg-icon-primary me-2 mt-n2 d-inline`}
                        />
                        <span className='me-2'>Edit Folder: </span>
                        {/* <i className='fa fa-folder-open me-2'></i> */}
                        {currentFolder.group_name}
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" 
                    
                    ></button>                        
                </div>
                <div className="offcanvas-body text-start">
                    {/* <FormProvider {...methods} > */}
                        <form id="frm-add-group" className='form'  
                        onSubmit={handleSubmit(formSubmitHandler)}
                        >              
                            {currentFolder.source_group_id !== "bin" &&
                                <div className="mb-4">
                                    <TextField 
                                        {...register("group_name")} 
                                        label="Folder Name" 
                                        fullWidth 
                                        autoComplete='off'
                                        margin='dense'
                                        variant='outlined' 
                                        // disabled= {currentFolder.source_group_id === "bin"}
                                        onChange={(e)=>{
                                            setGrpName(e.target.value)
                                            setValue("group_name", e.target.value)
                                            trigger("group_name")
                                        }}
                                        error={!! errors.group_name}
                                        helperText={`${errors.group_name?.message ?? ''}`}
                                        focused={watch('group_name').length>0 ? true:false}                                    
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            }
                            
                            <div className="pt-8 text-end row">  
                                <div className='col-6 text-start'>
                                    
                                    { rootFolders && rootFolders.data.length > 1  &&    //if there is more than one folders
                                    <button type="button" className="btn btn-danger me-3 text-start" 
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        // data-kt-drawer-dismiss="true"                                        
                                        disabled={isDeleted}
                                        onClick={()=>{ handleDeleteFolder(currentFolder) }}
                                    >
                                        <i className="fa fa-trash me-1 mt-n1"></i>
                                        {currentFolder.source_group_id !== "bin" ? "Delete Folder":"Empty Folder"}
                                    </button>
                                    }
                                </div>  

                                {currentFolder.source_group_id !== "bin" &&                           
                                    <div className='col-6 text-end'>
                                        <button type="submit" className="btn btn-primary" 
                                            data-bs-dismiss="offcanvas"
                                            data-kt-drawer-dismiss="true"
                                            // disabled={isSubmitting || !isValid || isDeleted}
                                            disabled={isSubmitting || !isValid || isDeleted || 
                                                    (errors.group_name? true:false) ||
                                                    (currentFolder.source_group_id === "bin")
                                            }
                                        >
                                            <i className="fa fa-save me-1 mt-n1"></i>
                                            Save Change
                                        </button>
                                    </div>
                                }
                                
                            </div>
                            
                        </form>
                    {/* </FormProvider> */}
                </div>
            </div>
        </>
    )
}

export {DrawerEditFolder}