import { ImageListItem, styled } from "@mui/material";
import { KTSVG } from "../../../../_metronic/helpers";
import { Folder } from "../media/mediaModels";

export interface InitialState {
    hideDashboard: boolean,
    hidePanelRight: boolean,
    editorModeOn: boolean,
    
    currentFolder: Folder,
    selectedMenu: string,
    
    currEditorWidth : number,
    currEditorHeight: number,
    newSelectedContentIds : string[],
    zoomPercentage: number,    

    multiSelectMode: boolean,
    selectedMediaSrcIds: string[],

    textEditorCnt : string,

    editorStatusParams: EditorStatusParams,
}

export interface EditorStatusParams {
    // editorModeOn: boolean,
    // hideDashboard: boolean,
    // hidePanelRight: boolean,

    savingStatus: number,   //-1: emptyContent;  0: unsaved/changed;   1: saving;  2: saved;

}

// Data Pre-assignment
// ----------------------------------------------
//16:9 at 1/120 propotion
// export const deviceWidth  = 1920
// export const deviceHeight = 1080
// //16:9 at 1/80 propotion
// export const defaultEditorWidth  = 1280
// export const defaultEditorHeight = 720
// //16:9 at 1/60 propotion

export const DEBUG_MODE : boolean | undefined  = (process.env.REACT_APP_DEBUG_MODE
    ?   (process.env.REACT_APP_DEBUG_MODE === "true" || process.env.REACT_APP_DEBUG_MODE !== "0"
            ? true 
            : false
        )
    :   false
    )

/* Open fullscreen */
export const openFullscreen = () =>{
    var elem = document.documentElement
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    // } else if (elem.webkitRequestFullscreen) { /* Safari */
    //   elem.webkitRequestFullscreen();
    // } else if (elem.msRequestFullscreen) { /* IE11 */
    //   elem.msRequestFullscreen();
    }
    }

/* Close fullscreen */
export const closeFullscreen = () => {
    // var elem = document.documentElement
    // if (document.exitFullscreen) {    
    //     document.exitFullscreen();
    // // } else if (document.webkitExitFullscreen) { /* Safari */
    // //   document.webkitExitFullscreen();
    // // } else if (document.msExitFullscreen) { /* IE11 */
    // //   document.msExitFullscreen();
    // }

    // if (document.fullscreenElement !== null) {
    //     document.exitFullscreen();
    // }
    if (document.fullscreenElement) {
        setTimeout(() => document.exitFullscreen(), 1000);
    }
}


export const ImageListItemWithStyle = styled(ImageListItem)(({ theme }) => ({
    border: `solid 1px #E4E6EF`,
    "&:hover MuiImageListItemBar-root span.text-muted":{
        color:"#fff"
    },
    
    "&:hover": {
        cursor: "pointer",
        // opacity: 0.8,
        border: `solid 1px #D58DCF`,
        // boxShadow: `inset 0 0 0 1px #FBE5F9`,
        backgroundColor:`#FBE5F9`,
        color:`#fff`,
        borderRadius:"0 0 2px 2px",
        //boxShadow: `5px 10px ${theme.palette.primary.main}`,
    },
}));

export const ImageListItem2WithStyle = styled(ImageListItem)(({ theme }) => ({
    border: `solid 1px #E4E6EF`,
    "&:hover MuiImageListItemBar-root span.text-muted":{
        color:"#fff"
    },   
    
    "&:hover": {
        cursor: "pointer",
        // border: `solid 0px #009ef7`,
        // backgroundColor:`#FBE5F9`,
        backgroundColor:`#009ef7`,
        // color:`#fefefe`,
        borderRadius:"7px",
        //boxShadow: `5px 10px ${theme.palette.primary.main}`,
    },
    
}));

// Get the icon correspond to each section
export const sectColorCodes = {
    "image": "info",
    "video": "info",
    "web"  : "success",    
    "text"      : "warning",    
}
export const getSectColorCodes = (sectType="image", clsName="") => {
    return  (
            ["image", "video"].includes(sectType) ? "gray-700"
            :   ["web"].includes(sectType) ? "primary" 
                :   ["text"].includes(sectType) ? "info" : ""
    )
}

// Get the icon correspond to each section
export const getSectionIcon = (sectType="image", clsName="") => {
    return (
            <span className='w-20px'>
                {sectType === "image"    && <KTSVG path='/media/icons/duotune/general/gen006.svg' className={`svg-icon-4 me-2 ${clsName}`}/>}
                {sectType === "video"    && <i className={`bi bi-camera-video-fill me-2 ${clsName}`}></i>}
                {sectType === "text"     && <KTSVG path='/media/icons/duotune/art/art001.svg' className={`svg-icon-4 me-2 ${clsName}`}/>}
                {sectType === "web"      && <i className={`bi bi-globe me-2 ${clsName}`}></i>}
                {sectType === "campaign" && <KTSVG path='/media/icons/duotune/abstract/abs026.svg' className={`svg-icon-4 me-2 ${clsName}`}/>}
                {sectType === "other"    && <KTSVG path='/media/icons/duotune/general/gen046.svg'  className={`svg-icon-4 me-2 ${clsName}`}/>}
            </span>
    )           
}
// --------------------------------------------
// Calculate the propotion of Editor (16:9 screensize)
// --------------------------------------------
export const DEFAULT_LOAD_ATTEMPT = 5   //nb. of attempts to download the img/video after uploading to S3

export const defaultEditorLandscapeWidth  = 960
export const defaultEditorLandscapeHeight = 540

export const defaultEditorPortraitWidth  = 315
export const defaultEditorPortraitHeight = 560

export const RECYCLE_BIN_DURATION = 7   //files will be auto-deleted after 7 days in the bin

export const getCorrespondWidth = (w:number, h:number, realEditorW: number, realEditorH: number, zoom:number = 1) => {
    if (realEditorW >= realEditorH){
        return (w*(defaultEditorLandscapeWidth / realEditorW))* zoom
    }else{
        return (w*(defaultEditorPortraitWidth  / realEditorW))* zoom
    }
}
  
export const getCorrespondHeight = (w:number, h:number, realEditorW: number, realEditorH: number, zoom:number = 1) => {    
    if (realEditorW >= realEditorH){
        return (h*(defaultEditorLandscapeHeight / realEditorH))* zoom
    }else{
        return (h*(defaultEditorPortraitHeight  / realEditorH))* zoom
    }
}