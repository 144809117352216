import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'

import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
  ToggleComponent,
} from '../../../../../../../../_metronic/assets/ts/components'
import { useEffect } from 'react'
import { 
  DropdownNewFolder,
  DropdownUploadFiles,
  DrawerUploadMedia,
  GalleryFiles,
  BtnNewFolder,
  BtnUploadFiles,
  BtnSearchGallery,
  BtnNewFolderModal,
  BtnUploadFilesModal
} from '../../../../EditorComponents'
import { HeaderMenuUploadFile } from './HeaderMenuUploadFile'
import { Search } from '../../../../../../../../_metronic/partials/layout/search/Search'
import { Dropdown1 } from '../../../../../../../../_metronic/partials/content/dropdown/Dropdown1'
import { SearchInner } from '../../../../../../../../_metronic/partials'


type Props = {
  className: string,
}


//----------------------------------------
export const GalleryMenu: React.FC<Props> = ({className}) => { 

  // ------------------------
  return (

      <div className='w-100 d-flex justify-content-between mt-5 ms-3 me-8 pt-0 '>
        <div className='flex-start'>
          <BtnNewFolderModal  title="New" noIcon={false} className='btn-icon btn-sm btn-active-color-primary ms-5 me-2'/>
          <BtnUploadFilesModal title="Upload"/>
          {/* <BtnNewFolder/> */}
          {/* <BtnUploadFiles /> */}
          
        </div>                

        {/* <div className='w-25 flex-start'>
          <a 
                className="nav-link dropdown-toggle" 
                data-bs-toggle="offcanvas"
                data-bs-target="#kt_drawer_upload_media"
                id="kt_drawer_upload_media_button"
                data-kt-drawer-show="true" 
                data-tooltip-content="Upload media files"
                href="#" 
                role="button" 
                aria-expanded="false"
              >
                <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2 me-2' />
                <span className='me-2'>Upload</span>
              </a>
          <DrawerUploadMedia />

          <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
            <div className="btn-group" role="group">
              <button 
                className={`btn btn-icon btn-sm btn-active-color-primary tooltip-bottom `}
                // data-tooltip-content='Upload Files'

                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='top-end'
                data-kt-menu-overflow='true'
              >
                <i className="bi bi-cloud-upload fs-3 text-light"></i>
              </button>              
              <HeaderMenuUploadFile/>
            </div> 
          </div>
        </div>         */}
        {/* <div className='d-flex flex-row-fluid flex-end'>
          <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
            <GallerySearch />           */}
            {/* <div className='flex-shrink-0 ms-2'>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              </button>

              <Dropdown1 />
            </div>  */}
{/*             
          </div>
        </div> */}
        

      </div>      
  )
}

