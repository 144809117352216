import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { QuickLinksContent } from '../../../../../app/modules/editor/components/widgets/QuickLinksContent'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../../../_metronic/partials'



const AsideFooterContent = () => {

    return (<>
        {/* link to /editor/contents which is the same as /content */}
        {/* <div className='d-flex align-items-center mb-2'>
            <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Quick links'
            >
            <KTSVG
                path='/media/icons/duotune/general/gen022.svg'
                className='svg-icon-2 svg-icon-lg-1'
            />
            </div>
            <QuickLinksContent />
        </div>
        <div className='d-flex align-items-center mb-2'>
            <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Quick links'
            >
            <KTSVG
                path='/media/icons/duotune/general/gen022.svg'
                className='svg-icon-2 svg-icon-lg-1'
            />
            </div>
            <QuickLinks />
        </div>
        <div className='d-flex align-items-center mb-2'>
            <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Activity Logs'
            id='kt_activities_toggle'
            >
            <KTSVG
                path='/media/icons/duotune/general/gen032.svg'
                className='svg-icon-2 svg-icon-lg-1'
            />
            </div>
        </div>
        <div className='d-flex align-items-center mb-2'>
            <div
            className='btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Notifications'
            >
            <KTSVG
                path='/media/icons/duotune/general/gen025.svg'
                className='svg-icon-2 svg-icon-lg-1'
            />
            </div>
            <HeaderNotificationsMenu/>
        </div>*/}
        <div className='d-flex align-items-center mb-10' 
            id='kt_header_user_menu_toggle'>
            <div
            className='cursor-pointer symbol symbol-40px'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-start'
            title='User profile'
            >
            {/* <img src={toAbsoluteUrl('/media/avatars/man-001.png')} alt='avatar' /> */}
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='rounded-circle' alt='avatar' />

            </div>
            <HeaderUserMenu />
        </div>
    </>)
}

export {AsideFooterContent}
