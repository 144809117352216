import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from './cartModel';

const initialState: InitialState = {
    errStatus: false,    
    page : 1,
}
const cartSlice = createSlice({
name: 'cart',
initialState,
reducers:{
    setErrStatusDevice: (state, action) => {
        state.errStatus = action.payload
    },

    increased: (state) =>{
        state.page++
    },
    decreased: (state) =>{
        state.page--
    },
    
    // ordered: (state) =>{
    //     state.numOfCakes--
    // },
    // restocked: (state, action: PayloadAction<number>) => {
    //     state.numOfCakes += action.payload
    // }
}
})

export default cartSlice.reducer
