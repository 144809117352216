import { FC, useEffect } from "react"
import { ParamSource, Source} from "../../../../../../../redux/slices/content/contentModels"
import Marquee from "react-fast-marquee"
import { getCorrespondWidth } from "../../../../../../../redux/slices/editor/editorModels"


type Props = {
  className : string,
  item      : ParamSource,
  txtSrc?   : Source,
  isLabel?  : boolean,  
}


const RenderedText: FC<Props> = ({className, item, isLabel=false}) => { 
  const currEditorW = 720
  const currEditorH = 1280
  
  var currEditorZoomL = 0.162
  var currEditorZoomP = 0.288
  var minHeighL = "51px"
  var minHeighP = "161px"
  if (window.screen.width < 992){
      currEditorZoomL = 0.216
      currEditorZoomP = 0.385
      minHeighL = "68px"
      minHeighP = "215px"
  }

  var fntSize = item.size
  if (item.size){
    fntSize = getCorrespondWidth(item.size, item.size, currEditorW, currEditorH, 1)
  }    
  // -----------------------------------------------------
  return (<div 
    className={` ${className}
      ${isLabel ? "opacity-50 pt-1":""}
      ${item.font? "font-"+ item.font.toLowerCase().replace(' ','-'): ""} 
    `}
    style={{
      color: item.fontcolor? item.fontcolor : "",      
      // backgroundColor: item.backgroundcolor? item.backgroundcolor : "",    
      backgroundColor: item.backgroundcolor? (item.backgroundcolor[0]==="#"? item.backgroundcolor : "#"+item.backgroundcolor) : "",  
      fontWeight: item.fontweight? item.fontweight: "",
      fontStyle: item.fontitalic? item.fontitalic: "",
      textDecoration: item.fontdecoration ? (item.fontdecoration === "lineThrough"? "line-through" : item.fontdecoration) : "",
      // fontSize: isLabel === false ? (parseInt(""+item.size)/2) : 11
      fontSize: isLabel === false ? fntSize : 11  //co-related with editor screen.
    }}
    dangerouslySetInnerHTML={{ __html: item.text? item.text : "" }} 
  />)
}       


// ---------------------------
export const RenderedHtmlText: FC<Props> = ({className, txtSrc, item, isLabel=false}) => {  
  // console.log("txtSrc", txtSrc)
  // ---------------------------------
  return (
    <div className={`d-flex align-items-center text-center ${className}`}>
      {(
        ((item.marquee && !isLabel) && item.text) 
        // && 
        // (item.direction && !["up", "down"].includes(item.direction))
      ) &&
        <Marquee 
          direction={item.direction ? item.direction : "left"} 
          loop={item.loop}
          speed={item.speed_x}  
          // className="w-100 h-100 text-wrap"
          className="text-wrap"
          style={{
            width:  item.direction && ["up", "down"].includes(item.direction) ? "100%":"100%", 
            height: item.direction && ["up", "down"].includes(item.direction) ? "":"100%", 
          }}
        >
          <RenderedText className="text-wrap" item={item} isLabel={isLabel} />
        </Marquee>
      }

      {(
        ((!item.marquee || isLabel) && item.text) 
        // ||
        // (item.direction && ["up", "down"].includes(item.direction))        
      ) &&
        <RenderedText className="" item={item} isLabel={isLabel} />        
      }
    </div>
  )
}

