import { Source } from "../content/contentModels"

export interface RootFolders{
    data: Folder[]
}

export interface Folder{
    source_group_id  : string,
    group_name       : string,
    sources?         : Media[],
    created_time?    : string,
    deleted_at?      : string | null,    
    ordering?        : number,    
    readonly?        : number,    
    user_id?         : number,
    users?           : number[]
}

export interface ChangeFolder{
    sourceIds    : string[],
    newGroupId : string
}
// --------------------------------
export interface Medias{
    data: Media[]
}

export interface Media{
    
    source_id   : string,
    source_group_id : string,
    name        : string,    

    created_at  : string | null,
    updated_at  : string | null,
    deleted_at  : string | null,    
    
    width       : number,
    height      : number,
    duration    : number,

    is_remote   : number,
    modified_time   : string | null,
    
    rotate_down : string,
    rotate_left : string,
    rotate_right    : string,
    save_name   : string,
    size        : number,
    
    status      : string | null,    //status: 0: not ready, 1: ready to use, 3: deleted 
    storage     : string,  
    thumb       : string,
    type        : string,    
    
    url         : string,
    url_thumb   : string,

    user_id     : string,
    approve_by  : number,    
}

export interface MediaInputFields{
   
    src_filepath: File | null,
    src_filepaths?: FileList | null,
    source_group_id : number,
    // user_id?     : number,
    // is_remote?   : number[] | null,
    // url?         : string[] | null,
    // widthList?   : number[] | null,
    // heightList?  : number[] | null,
    // durationList?: number[] | null,
    
    // thumbnails  : string[] | null,
    // sync        : boolean,

    is_remote       : number,
    url             : string,
    widthList       : string,
    heightList      : string,
    durationList    : string,
    // thumbnails   : string[] | null,
    // sync         : boolean,
    user_id?     : number,
}

// Data Pre-assignment
// ----------------------------------------------

export const defaultThumbnails = [  
                                    "/media/_editor/defaultImageVideoIcon-white-4.png", 
                                    "/media/_editor/defaultVideoIcon-white.png",
                                    "/media/_editor/defaultWebIcon-white.png",
                                    "/media/_editor/defaultTextIcon-white.png",
                                    "/media/_editor/defaultContentIcon-white-01.png",                                    
                                    "/media/_editor/defaultContentIcon-white.png",
                                ]

export const defaultTransparentGrids = [  
                                    "/media/_editor/transparent-grid-bg-dark-01.png",
                                    "/media/_editor/transparent-grid-bg-03.png",
                                    "/media/_editor/transparent-grid-bg-01.png", 
                                    "/media/_editor/transparent-grid-bg-02.png"
                                ]