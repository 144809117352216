import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Dropdown1, Search} from '../../../../../../_metronic/partials'
import { useGetAllTemplatesQuery } from '../../../../../redux/slices/template/apiTemplateSlice'
import { CardTemplate } from '../../../../content/components/ContentComponents'
import { ImageList, ImageListItem } from '@mui/material'
import { Fragment } from 'react'
import { useAppSelector } from '../../../../../redux/hook'

const TabTemplates = () => {

  const { data: allTemplates, isLoading: isLoadingTemplates } = useGetAllTemplatesQuery(5, { refetchOnMountOrArgChange: true })    
  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)

 /*
  const seletedTemplateIds = [
    "bglzpj5Jro",
    "xVb501RPLo",
    "wDAYgyPlOr","1gKYqwPWX2","jnEYaE6Ylb","1pb5924P8J",
    "Q94YVLPL0v", "1gKYqwPWX2","e87PnxY6KX", 
    "pnRPd1zyo2", "xVb50RzLo7","ZgMP7xvzWn","gGkYv0qPRq",
    "NOXPXVY0B7", "V0kY1Z5mqa", "WXZ5OQzo63", "e3ZYGRz64B", 
    "NOXPXVY0B7", "V0kY1Z5mqa",
    "8EbYZMzm3L", "7dV5y2ZPy6", 
    "pnJ5Jdz6Zb", "NVkzwyy51B","maV5RRy5o6",
    "NVkzwy51Be", "b17zjgYqDM", 
    "Z785x3eYk2", "pnJ5Jxdz6Z",
    "KXmY2xWPa4",
    "gWyPNVOPJR", "VkD5l12PMn", "xVb501RPLo", "Mgoz33vzXx"
    //  "", "", "", "", "", "", "", "", "", "", "", "", "",
    // "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""
  ]
  */
  const templatesLandscapes = allTemplates?.filter((tmp)=> {return (tmp.width >= tmp.height) })
  const templatesPortraits  = allTemplates?.filter((tmp)=> {return (tmp.width < tmp.height)  })

  
  // ----------------------------------------
  return (
    <div className="d-flex flex-column flex-row-auto w-100 vh-100 bg-gray-300">
    {/* <div className="d-flex flex-column flex-row-auto w-100 vh-100 bg-gray-300 bg-primary"> */}
        <div className="d-flex flex-column-auto h-70px bg-gradient-primary" 
          style={{
            paddingTop:"2px", 
            // backgroundImage: "linear-gradient(#F04E5D, #9C258F)"
          }}
        >
          {/* Tab Menu */}
          <div className='w-100 d-flex justify-content-center mt-8 ms-0 me-0 pt-0 '>        
            <ul className="nav nav-tabs mb-5 fs-6" style={{borderBottom:"unset"}}>  
              <li className="nav-item">
                <a  href="#kt_tab_pane_landscape" className="nav-link active bg-active-primary border-0 px-2" data-bs-toggle="tab">
                  <KTSVG path='/media/icons/duotune/electronics/elc011.svg' className='svg-icon-1 text-light-primary' />
                  <span className='text-light ms-1 fs-md-8 fs-lg-6'>Landscape</span>
                  {/* {templatesLandscapes && <span className='badge badge-light ms-1'>{templatesLandscapes.length}</span>} */}
                </a>
              </li>
              <li className="nav-item">              
                <a  href="#kt_tab_pane_portrait" className="nav-link bg-active-primary bg-hover-primary border-0 px-2"  data-bs-toggle="tab">
                  <KTSVG path='/media/icons/duotune/electronics/elc003.svg' className='svg-icon-1 text-light-primary' />                  
                  <span className='text-light ms-1 fs-md-8 fs-lg-6'>Portrait</span>
                  {/* {templatesPortraits && <span className='badge badge-light ms-1'>{templatesPortraits.length}</span>} */}
                </a>
              </li>  
              <li className="nav-item">              
                <a  href="#kt_tab_pane_custom" className="nav-link bg-active-primary bg-hover-primary border-0 px-2"  data-bs-toggle="tab">
                  <KTSVG path='/media/icons/duotune/layouts/lay001.svg' className='svg-icon-1 text-light-primary' />     
                  <span className='text-light ms-1 fs-md-8 fs-lg-6'>Custom</span>
                </a>
              </li>           
            </ul>
          </div>
        </div>
        
        <div className="d-flex flex-column-fluid flex-center pt-7"
            style={{maxHeight:"90%", overflowY:"auto"}}
        >
          <div className="tab-content pb-10 mb-5 mb-xl-8 w-100 h-100 ms-1" id="myTabContent">
            {/* Landscape Templates */}
            <div
              className="tab-pane fade show active h-100 w-100 mx-0"
              id="kt_tab_pane_landscape"
              role="tabpanel"
              // style={{maxHeight:"850px", overflowX:"hidden"}}
              // style={{maxHeight:"100%", overflowX:"hidden"}}
            >              
            
              <div className="container">
                <div className="row px-0 gy-1">
                  { !isLoadingTemplates && templatesLandscapes && templatesLandscapes.length > 0 &&                                 
                    <>          
                      { templatesLandscapes
                        .slice().sort((a,b) => a.template_name.toLowerCase() > b.template_name.toLowerCase() ? 1: -1)
                        .map((tmplate, ind) => {
                          // --------------------------
                          return ( 
                            <Fragment key={"l-"+ind}>
                               <CardTemplate
                                 key={"l-"+ind+"-tmp-"+tmplate.template_id}
                                 className="col-6 col-lg-4 col-xl-4 col-xxl-4" 
                                 template = {tmplate}
                                 autoLayout={false}
                                 active={tmplate.template_id === currContentPersistor.template_id}
                                 // ribbonType = { (moment().diff(moment.utc(content.modified_time).local())/(1000*60*60*24)) <= RECENTPERIOD ? "recent": ""}                                         
                               />
                            </Fragment>
                          )
                        })                        
                      }
                    </>                       
                  }
                </div>              
              </div>
            </div>

            {/* Portrait Templates */}
            <div  
              className="tab-pane fade" 
              id="kt_tab_pane_portrait"
              role="tabpanel"
              // style={{maxHeight:"850px", overflowX:"hidden"}}
            >
              <div className="container">
                <div className="row px-0 gy-1">
                  {!isLoadingTemplates && templatesPortraits && templatesPortraits.length > 0 &&
                    templatesPortraits
                    .slice().sort((a,b) => a.template_name.toLowerCase() > b.template_name.toLowerCase() ? 1: -1)
                    .map((tmplate, ind)=>{
                      // --------------------------
                      return ( 
                        <Fragment key={"p-"+ind}>
                            <CardTemplate
                              key={"tmp-p-"+tmplate.template_id}
                              className="col-6 col-lg-4 col-xl-4 col-xxl-4"  
                              template = {tmplate}
                              autoLayout={true}
                              active={tmplate.template_id === currContentPersistor.template_id}
                              // ribbonType = { (moment().diff(moment.utc(content.modified_time).local())/(1000*60*60*24)) <= RECENTPERIOD ? "recent": ""}
                            />
                        </Fragment>                                            
                      )
                    })
                  }
                </div>              
              </div>
            </div> 

            {/* Custom Templates */}
            <div  
              className="tab-pane fade" 
              id="kt_tab_pane_custom"
              role="tabpanel"
              // style={{maxHeight:"850px", overflowX:"hidden"}}
            >
              <div className="container">
                <div className="row text-center fw-semibold mt-7">
                  <div>Need a <span className='fs-2 fw-bold text-white bg-danger px-2 py-1'>FREE</span> Custom Template? </div>
                  <a href="https://superbolt.com.au/contact">Contact us</a>
                  <div className='py-8'>
                  <img src="/media/_landing_contact_us/contact-us-01.png" className="w-75 "/>
                  </div>
                </div>              
              </div>
            </div>            
          </div>
        </div>
    </div>
  )
}

export {TabTemplates}
