import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import {Logout, AuthPage, useAuth} from '../../../auth'
import useContentful from '../../components/useContentful'
import Slider from 'react-slick'
import { 
    HeaderBackgroundHome, HeaderBannerHome,
    HeaderBackgroundAboutUs, HeaderBannerAboutUs,
    HeaderBackgroundProducts, HeaderBannerProducts, 
    HeaderBackgroundServices, HeaderBannerServices, 
    HeaderBackgroundSupport, HeaderBannerSupport, 
    HeaderBackgroundIndustries, HeaderBannerIndustries, 
    HeaderBackgroundContactUs, HeaderBannerContactUs, 
    HeaderBackgroundTermsConditions, HeaderBannerTermsConditions, 
    HeaderBackgroundPrivacyPolicy, HeaderBannerPrivacyPolicy, 
    HeaderBannerFAQs, HeaderBackgroundFAQs,  
} from '../../LandingComponents'
import { ThemeModeSwitcher } from '../../../../../_metronic/partials'



const contentBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/',
        isSeparator: true,
        isActive: true,
    },    
    {
        title: 'Products',
        path: '/products',
        isSeparator: true,
        isActive: true,
    },
    {
        title: 'Contact Us',
        path: 'https://superbolt.com.au/contact',
        isSeparator: true,
        isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: true,
    },
]


const Header: FC = () => {
    const {currentUser} = useAuth()    
    // -------------------------------------
    const location = useLocation()
    // console.log("location: ",location)
    // console.log("contentBreadCrumbs: ",contentBreadCrumbs)
    //--------------------------------
    return (
    <>
        <div className="mb-0" id="home">
            <div className="container-fluid text-center ">
                <div className="w-100">
                    {/*
                        {
                            "/": <HeaderBackgroundHome/>,
                            "/about-us": <HeaderBackgroundAboutUs clsName="h-200px h-lg-250px h-xl-300px"/>,
                            "/products": <HeaderBackgroundProducts clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "/services": <HeaderBackgroundServices clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "/support": <HeaderBackgroundSupport clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "/industries": <HeaderBackgroundIndustries clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "https://superbolt.com.au/contact": <HeaderBackgroundContactUs clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "/terms-conditions": <HeaderBackgroundTermsConditions clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "/privacy-policy": <HeaderBackgroundPrivacyPolicy clsName="h-200px h-lg-250px h-xxl-300px"/>,
                            "/faqs": <HeaderBackgroundFAQs clsName="h-200px h-lg-250px h-xxl-300px"/>,

                        }[location.pathname]
                    */}                    
                </div>                
                <div 
                    className="d-flex flex-column position-absolute top-0 text-white fs-2 w-100"
                    style={{margin: "-10px"}}
                >
                    <div 
                        // className="bgi-no-repeat size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg min-vh-100"                        
                        // style={{backgroundImage: "url("+toAbsoluteUrl('/media/svg/illustrations/landing.svg')+")"}}                                
                    >
                        <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                            <div className="container">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center flex-equal">
                                        <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none" id="kt_landing_menu_toggle">
                                            <span className="svg-icon svg-icon-2hx">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                                                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                        <a href="/">
                                            <img alt="Logo" src={toAbsoluteUrl('/media/logos/custom-1.png')} className="logo-default h-35px h-lg-50px" />
                                            <img alt="Logo" src={toAbsoluteUrl('/media/logos/custom-01.png')} className="logo-sticky h-60px h-lg-65px" />
                                            {/* default-white */}
                                        </a>
                                    </div>
                                    <div className="d-lg-block" id="kt_header_nav_wrapper">
                                        <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">                                            
                                            <div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold" data-kt-menu="true" id="kt_landing_menu">                                                
                                                 {/*
                                                <div className="menu-item">
                                                    <a className={`menu-link nav-link ${location.pathname === "/about-us"?"active":""} py-3 px-4 px-xxl-6`} href="/about-us" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">About Us</a>
                                                </div>
                                                <div className="menu-item" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                                                    <a href="#" className="menu-link">                                                        
                                                        <span className="menu-title">Products & Services</span>
                                                        <span className="menu-arrow"></span>
                                                    </a>
                                                    <div className="menu-sub menu-sub-dropdown w-175px py-2">
                                                        <div className="menu-item">
                                                            <a href="/products" className="menu-link">
                                                                <span className="menu-icon">
                                                                    <i className="ki-duotone ki-chart-simple-2 fs-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
                                                                </span>
                                                                <span className="menu-title">Devices</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>                                                 */}
                                                {/*
                                                <div className="menu-item">
                                                    <a className={`menu-link nav-link ${location.pathname === "/products"?"active":""} py-3 px-4 px-xxl-6`} href="/products" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Our Products</a>
                                                </div>
                                                <div className="menu-item">
                                                    <a className={`menu-link nav-link ${location.pathname === "/services"?"active":""} py-3 px-4 px-xxl-6`} href="/services" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Our Services</a>
                                                </div>
                                                <div className="menu-item">
                                                    <a className={`menu-link nav-link ${location.pathname === "/industries"?"active":""} py-3 px-4 px-xxl-6`} href="/industries" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Industries</a>
                                                </div>
                                                */}
                                                {/* <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="case-studies" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Case Studies</a>
                                                </div> */}
                                                {/* <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#how-it-works" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">How it Works</a>
                                                </div> */}
                                                {/* <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#achievements" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Achievements</a>
                                                </div>
                                                <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#team" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Team</a>
                                                </div>
                                                <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#portfolio" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Portfolio</a>
                                                </div> */}
                                                {/* <div className="menu-item">
                                                <a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#pricing" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Pricing</a>
                                                </div> */}
                                                {/*
                                                <div className="menu-item">
                                                    <a className={`menu-link nav-link ${location.pathname === "/contact-us"?"active":""} py-3 px-4 px-xxl-6`} href="/contact-us" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Contact Us</a>
                                                </div>
                                                */}
                                                {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}
                                                
                                                {currentUser ? (
                                                    <>
                                                    <div className="menu-item ms-5">
                                                        <a href="/dashboard" className="btn btn-primary">
                                                            {/* <i className="bi bi-speedometer2 mt-n1 me-1"></i> */}
                                                            <span className='menu-icon'>
                                                                {' '}
                                                                <KTSVG path={toAbsoluteUrl('/media/icons/duotune/art/art002.svg')} className='svg-icon-2 icon-success' />
                                                            </span>
                                                            My Dashboard
                                                        </a>
                                                    </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="menu-item mb-4 mb-md-4 mb-lg-0 ms-3 ms-lg-5">
                                                            <a href="/auth" className="btn btn-primary" title="Already had account? Let's sign in now!"><i className="bi bi-box-arrow-in-right mt-n1 me-1"></i>Sign In</a>
                                                        </div>
                                                        <div className="menu-item mb-4 mb-md-4 mb-lg-0 ms-3 ms-lg-5">
                                                            <a href="/auth/registration" className="btn btn-primary" title="New User? Let's create a new account now!"><i className="bi bi-person-plus mt-n1 me-1"></i>Sign Up</a>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>								
                                </div>
                            </div>
                        </div>

                        {
                            {
                                "/": <HeaderBannerHome/>,
                                "/about-us": <HeaderBannerAboutUs/>,
                                "/products": <HeaderBannerProducts/>,
                                "/services": <HeaderBannerServices/>,
                                "/support": <HeaderBannerSupport/>,
                                "/industries": <HeaderBannerIndustries/>,
                                "/contact-us": <HeaderBannerContactUs/>,
                                "/terms-conditions": <HeaderBannerTermsConditions/>,
                                "/privacy-policy": <HeaderBannerPrivacyPolicy/>,
                                "/faqs": <HeaderBannerFAQs/>,
                            }[location.pathname]
                        }
                    </div>                    
                    <div className="landing-curve landing-dark-color mt-n14 mb-10 mb-lg-20">&nbsp;
                        {/* <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
                        </svg> */}
                        {/* <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
                        </svg> */}
                    </div>
                
                </div>                        
            </div>
            { location.pathname !== "/" &&            
            <div 
                className={`container-fluid text-center h-5px`}
                style={{                
                    background: `${
                        {                        
                            "/about-us":    "#010103",
                            "/products":    "#331168",
                            "/services":    "#001977",
                            "/industries":  "#22145F",
                            "/contact-us":  "#01061C",
                            "/terms-conditions" :    "#131a2d",
                            "/privacy-policy"   :    "#131a2d",
                            "/faqs":        "#525f96",

                        }[location.pathname]
                    }`
                    
                }}
            >&nbsp;</div>
            }  
        </div>
    </>
  )
}

export {Header}
