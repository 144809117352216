import {Task} from "gantt-task-react"
// import styles from "../styles/tooltip.module.css";
import React, { useState } from "react";
import moment from "moment";
import { Sched, extractStartEndDatesFromSched } from "../../../../redux/slices/schedule/scheduleModels";

type Props = {
    className?: string,
    textClassName?: string,
    sch: Sched,
    repeatMode: string,    
}

export const ScheduleInfo: React.FC<Props> = ({className="", textClassName="", sch, repeatMode= ""}) => {
    // console.log("sch in SchedInfo:", sch)
    // console.log("sch.start_time in SchedInfo:", sch.start_time)    
    // const [repMode, setRepMode] = useState(repeatMode)
    // console.log("repeatMode:", repeatMode)
    
    const [startDate, endDate] = extractStartEndDatesFromSched(sch) 
    // console.log("startDate",startDate)
    // console.log("endDate",endDate)
    // ------------------------------------------
    return (
        <div className={`${className}`}>
            <div className='d-flex my-2'>
                <div className='w-20px mt-1'>
                    <i className={`bi bi-calendar me-1 mt-2 ${textClassName}`}></i>
                </div>
                <div>
                    <div>
                        {startDate.format("DD/MM/YYYY hh:mm A")} ~ 
                    </div>                                                                
                    <div>
                        {endDate.format("DD/MM/YYYY hh:mm A")}
                    </div>
                </div>
            </div>
            {sch.cron &&
                <div className='d-flex my-2'>
                    <div className='w-20px mt-1'>
                        <i className={`bi bi-arrow-repeat me-1 mt-2 ${textClassName}`}></i>
                    </div>
                    <div>
                            
                        <div className={`badge badge-light text-capitalize`}>
                            <span className='fw-normal me-2'>Repeat</span>
                            <span className='me-1'>{repeatMode}</span>
                            { repeatMode === "weekly"   ? <span> {sch.start_time ? `on ${moment(sch.start_time,"YYYY-MM-DD HH:mm:ss").format("dddd")}`:""}</span>
                                                        : repeatMode === "monthly"  ? <span> {sch.start_time ? `on ${moment(sch.start_time,"YYYY-MM-DD HH:mm:ss").format("Do")}`:""}</span>
                                                                                    : repeatMode === "yearly"   ?  <span> {sch.start_time ? `on ${moment(sch.start_time,"YYYY-MM-DD HH:mm:ss").format("MMMM Do")}`:""}</span>
                                                                                                                :  ""
                            }
                        
                        </div>
                        
                        {sch.cron.timeRanges === "*" 
                            ?   <div className='my-1'><span className='badge badge-light mt-1'>All Day</span></div>
                            :   <div className='my-1'>
                                    {sch.cron.timeRanges.split(",").map((timeRangeStr, ind) => (
                                        <span key={`tRange-${ind}`} className='badge badge-light mt-1'>
                                            {moment(timeRangeStr.split("-")[0], "HH:mm:ss").format("h:mm A")}
                                            <span className='mx-2'>-</span>
                                            {moment(timeRangeStr.split("-")[1], "HH:mm:ss").format("h:mm A")}
                                        </span>
                                    ))}
                                </div>
                        }
                        
                    </div>                                                            
                </div>
            }
        </div>
    );
};