import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useThemeMode } from '../../../../_metronic/partials'

type Props = {
  message: string
}
const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']

const CustomError: FC<Props> = ({ message = '' }) => {
  const { mode } = useThemeMode()
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
        : `url(${toAbsoluteUrl('/media/auth/bg7.jpg')})`

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [mode])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>


              {/* begin::Title */}
              <h1 className='fw-bolder fs-2hx text-gray-900 mb-8'>{message}</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='mt-8'>
                <Link to='/dashboard' className='btn btn-sm btn-primary'>
                  Return Home
                </Link>
              </div>
              {/* end::Link */}


            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export { CustomError }
