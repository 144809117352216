
type Props = {
    className: string,
}

const ModalTnC: React.FC<Props> = ({className}) => {
    return (
    <div className={`modal modal-lg fade ${className}`} tabIndex={-1} id="superbolt-tnc">
        <div className={`modal-dialog modal-xlmodal-dialog-centered`}>
            <div className={`modal-content rounded-0`}
            >
                <div className={`modal-content rounded-0`}
                    style={{
                    padding: "15px",
                    margin: "0",
                    color: "#fff",
                    background: "linear-gradient(205deg, rgba(232,75,96,1) 0%, rgba(156,37,143,1) 100%)"
                    }}
                >
                    Terms and Conditions
                </div>
                <div className="modal-body"
                    style={{
                        maxHeight: "500px",
                        overflow: "auto"
                    }}
                >
                    <h2 className=" text-primary fs-3hx fw-bold mb-5 mb-lg-10 mt-5 mt-lg-0" id="team" data-kt-scroll-offset="{default: 100, lg: 150}">User Agreement</h2>
                    <div className="container text-start">
            <p>
            Welcome to ("<a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a>'s") User Agreement. 
            This Agreement describes the terms and conditions that govern your use of our services at <a href="https://www.superbolt.com.au" className='fw-semibold text-underline'><u>https://www.superbolt.com.au</u></a> (the "Site").
            If you have any questions, please refer to our User Agreement Frequently Asked Questions (FAQ) at “<a href="https://superbolt.com.au/faqs" className='fw-semibold'><u>https://superbolt.com.au/faqs</u></a>”). 
            </p>

            <p>
            You must read, agree with and accept all of the terms and conditions contained in this User Agreement and the Privacy Policy, 
            which include those terms and conditions expressly set out below and those incorporated by reference, 
            before you may become a registered user of <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>. We strongly recommend that, 
            as you carefully read this User Agreement, you also access and read the information contained in the other pages and websites 
            referred to in this document, as they may contain further terms and conditions which apply to you as a <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> registered user.
            <div className='fst-italic mt-2'><strong>Please note:</strong> underlined words and phrases are click-through links to these pages and websites.</div> 
            </p>

            <p>
            By accepting this User Agreement, you also agree to be bound by the User Agreements and posted terms and conditions 
            on other <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> websites to the extent that you use those websites.
            If you do not agree to be bound by this User Agreement, please discontinue your use of our Site and services.                
            </p>
            
            <p>
            We may amend this User Agreement at any time in our discretion by posting the amended terms on the Site. 
            Amendments will be effective immediately after they are initially posted on the Site. 
            Your continued use of the Site after amendments becomes effective will embody your agreement to be obligated by this User Agreement as amended without any liability or notice.
            The fact that amendments have been made to the User Agreement will also be notified on our Announcements Board located at (“<a href="https://superbolt.com.au/" className='fw-semibold'><u>https://superbolt.com.au</u></a>”).
            If you do not agree with such amendments, you must terminate your User Membership of <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a>.
            This Agreement may not be otherwise amended except in writing signed by you and <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a>.
            This agreement is effective on your registration date.               
            </p>
            
            <p>
            <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> reserves the right to modify, limit or terminate any information contained on this Site. 
            At any time, <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> also has the right to limit, suspend or terminate their service if there is unlawful,
            unauthorised activity displayed on the Site which requires technical steps to keep users off the Site to resolve the issue.
            <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a>, may also issue warning messages and remove hosted content at any time and is no liable for any damages of any kind related to this action.
            <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> owns the copyright to the Site and therefore may not be copied, modified, distributed, broadcasted, hyperlinked or transmitted in any way without written permission from <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a>. 
            </p>
            
            <p className="counter fs-1 text-primary">
                <ol>
                    <li>User Membership
                        <ol className='mt-8 mb-12 fs-3'>
                            <li className='mb-5'>Eligibility</li>

                                <p className='ms-12'>
                                Registration for User Membership will be required in order to fully utilise the Site and Services. 
                                As a result, a user account and password will be created and it is YOUR responsibility to secure your User ID and password in order to maintain confidentiality.
                                You are also responsible for all activities which occur under your User ID and password.
                                </p>
                                <p className='ms-12'>
                                Our services are available only to, and may only be used by, 
                                individuals who can form legally binding contracts under applicable law. 
                                Without limiting the foregoing, our services are not available to persons under 18 years of age or to temporarily or indefinitely suspended <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> user memberships.
                                If you do not qualify, please do not use our services. 
                                Further, your <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> user account (including feedback), User ID and Membership may not be transferred or sold to another party.<br/>
                                SUPERBOLT PTY LTD is protected by copyright laws.
                                </p>
                                <p className='ms-12'>
                                You may terminate your User Membership by written notice to <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a>. <br/>
                                <a href="/" className='fw-semibold'>SUPERBOLT PTY LTD</a> reserves the right to also terminate your User Membership at any time. 
                                You indemnify <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> for any costs, expenses, damages and losses that 
                                <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> may incur or suffer if any User Membership information you provided is incorrect.
                                </p>
                            
                            <li className='mb-5'>Information Control</li>

                                <p className='ms-12'>
                                We send you communications and notices regarding your transactions and your account on the Site. 
                                Our communications do not represent any endorsement, guarantee or legitimisation of your transactions. 
                                You are responsible for completing all transactions you participate in (including monitoring the status and complying with all relevant legal obligations). 
                                We do not control, endorse or approve the items, information or content provided by other users which is made available through our system. 
                                You may find other users' information to be offensive, harmful, inaccurate, or deceptive. 
                                Please use caution, common sense, and practice safe trading when using our Site. 
                                Please note that there are also risks of dealing with underage persons or people acting under false pretences.
                                Additionally, there may be risks associated with international trading and dealing with foreign nationals.
                                </p>

                            <li className='mb-5'>Fraud</li>

                                <p className='ms-12'>
                                Without limiting any other remedies available to <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> at law,
                                in equity or under this User Agreement, <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> may, in its sole discretion, 
                                suspend or terminate your User Membership and account if we suspect that you (by conviction, settlement, insurance or escrow investigation,
                                or otherwise in our sole discretion) have engaged in fraudulent activity in connection with the Site. 
                                </p>
                        </ol>
                    </li>
                    <li >Your Information
                        <ol className='my-8  fs-3'>
                            <li className='mb-5'>Definition</li>

                                <p className='ms-12'>
                                <strong>"Your Information"</strong> is defined as any information you provide to us or other users in the registration, 
                                bidding, trading or listing process, in any public message area or through any email feature. 
                                Your Information includes the descriptions of items you list for sale or display on the Site as well as any content you post on other web pages within the Site. 
                                You are solely responsible for Your Information, and we act as a passive conduit for your online distribution and publication of Your Information.
                                </p>
                                
                            <li className='mb-5'>Restrictions</li>

                                <p className='ms-12'>
                                Your Information (including but not limited to any items listed) <span className='text-danger fw-bold'>must not</span>:<br/><br/>
                                    <div className='ms-8'>
                                        <table className="table">
                                            <tr>
                                                <td className='align-text-top fw-bold'>a.</td>
                                                <td>be false, inaccurate or misleading;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>b.</td>
                                                <td>be fraudulent, violate any laws or involve the sale of counterfeit or stolen items;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>c.</td>
                                                <td>infringe any third party's copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>d.</td>
                                                <td>violate any applicable law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, criminal law, antidiscrimination or trade practices/fair trading laws);</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>e.</td>
                                                <td>be defamatory, trade libelous, unlawfully threatening or unlawfully harassing;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>f.</td>
                                                <td>be obscene or contain child pornography or otherwise adult in nature or harmful to persons under the age of 18 years.</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>g.</td>
                                                <td>distribute or contain any viruses, Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, modify, delete, detrimentally interfere with, surreptitiously intercept, access without authority or expropriate any system, data or personal information and be harmful to <a href="/" className='fw-semibold'> SUPERBOLT  PTY LTD</a>, property or interests of <a href="/" className='fw-semibold'> SUPERBOLT  PTY LTD</a>'s registered users;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>h.</td>
                                                <td>distribute or contain chain letters, pyramid schemes or spam;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>i.</td>
                                                <td>create liability for us or cause us to lose (in whole or in part) the services of our ISPs or other suppliers;</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>j.</td>
                                                <td>be inferred as consent by <a href="/" className='fw-semibold p-0'> SUPERBOLT  PTY LTD</a> to receive unsolicited commercial electronic messages. User email addresses on this Site and other affiliated sites is to facilitate communications relating to services provided by <a href="/" className='fw-semibold p-0'> SUPERBOLT  PTY LTD</a>only; and</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top fw-bold'>k.</td>
                                                <td>link directly or indirectly to or include descriptions of goods or services that:</td>
                                            </tr>
                                            <tr>
                                                <td className='align-text-top' colSpan={2}>
                                                    <table className='ms-10'>
                                                        <tr>
                                                            <td className='align-text-top fw-bold'>i.</td>
                                                            <td>are prohibited under this User Agreement and other related <a href="/" className='fw-semibold p-0'> SUPERBOLT PTY LTD</a> policies</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-text-top fw-bold'>ii.</td>
                                                            <td>are identical to other items and have up for auction but are priced lower than your item's reserve or minimum bid amount;</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-text-top fw-bold'>iii.</td>
                                                            <td>are concurrently listed for sale on a web site other than <a href="/" className='fw-semibold p-0'> SUPERBOLT PTY LTD</a>'s (this does not prevent linking to or advertising a <a href="/" className='fw-semibold p-0'> SUPERBOLT PTY LTD</a> item from another web site); or </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-text-top fw-bold'>iv.</td>
                                                            <td>you do not have a right to link to or include</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pt-5" colSpan={2}>
                                                            Furthermore, you may not list any item on the Site (or complete any transaction that was initiated using our service) that, by paying to us the required fee/s, could cause us to violate any applicable law, statute, ordinance or regulation, or that violates our current Prohibited, Usage Policy (located at <a href="https://superbolt.com.au" className='fw-bold'><u>https://superbolt.com.au/privacy-policy</u></a>).
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>                                                
                                            </tr>
                                        </table>                                        
                                    </div>                                    
                                </p>
                                
                            <li className='mb-5'>License</li>

                                <p className='ms-12'>
                                Solely to enable <a href="/" className='fw-semibold'> SUPERBOLT  PTY LTD</a> to use the information you supply us with, 
                                so that we are not violating any rights you might have in that information, you agree to grant us a non-exclusive, worldwide, perpetual right to use, 
                                irrevocable, royalty-free, sub licensable (through multiple tiers) right to exercise the copyright, publicity, 
                                and database rights (but no other rights) you have in Your information, in any media now known or not currently known, 
                                with respect to Your Information. 
                                <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> may display your postings on other sites
                                for advertising purposes and may remove your posting if deemed inappropriate or violates any policy or law or causes any issues. 
                                Refer to <a href="https://superbolt.com.au" className='fw-bold'> Privacy Policy (<u>https://superbolt.com.au/privacy-policy</u>)</a> for further information.                   
                                </p>
                        </ol>                        
                    </li>
                    <p className='ms-8'>
                    You waive in favour of <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>, all moral rights in any jurisdiction.
                    Anything you post on this Site will be treated as non-proprietary and non-confidential and 
                    maybe used by <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> or 
                    its affiliates without limitation.
                    </p>
                    {/* <li >
                        <ol className='my-8 fs-3'>
                            <li className='mb-5'></li>

                                <p className='ms-12'>
                                
                                </p>
                                
                            <li className='mb-5'>Information Control</li>

                                <p className='ms-12'>
                               
                                </p>

                            <li className='mb-5'>Fraud</li>

                                <p className='ms-12'>
                                                               
                                </p>
                        </ol>
                    </li> */}
                    <li className='mt-12'>Access and Interference</li>

                        <p className='my-8 ms-8'>
                        The Site contains robot exclusion headers and you agree that you will not use any robot, spider, other automatic device, 
                        or manual process to monitor or copy our web pages or the content contained herein without our prior expressed written 
                        permission. You agree that you will not use any device, software or routine to bypass our robot exclusion headers, 
                        or to interfere or attempt to interfere with the proper working of the Site or any transaction being conducted through 
                        our Site. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on 
                        our infrastructure. Much of the information on our Site is updated on a real time basis and is proprietary or is licensed 
                        to <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> by our users or third parties. 
                        You agree that you will not copy, reproduce, alter, modify, create derivative works, or publicly display any content 
                        (except for Your Information) from the Site without the prior express written permission 
                        of <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> or the appropriate third party 
                        authorised to grant such permission.
                        </p>

                    <li >Breach</li>

                        <p className='my-8 ms-8'>
                        Without limiting other remedies available to <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> at law, 
                        in equity or under this User Agreement, we may, in our sole discretion, immediately issue a warning, temporarily suspend, 
                        indefinitely suspend or terminate your user membership and refuse to provide our services to you if:<br/><br/>
                            <div className='ms-8'>
                                a. you breach this User Agreement, the Privacy Policy or the terms and policies those documents incorporate by reference; <br/>
                                b. we are unable to verify or authenticate any information you provide to us; or<br/>
                                c. we believe that your actions may cause legal liability for you, our users or us.
                            </div>
                        </p>
                        

                    <li >Privacy</li>

                        <p className='my-8 ms-8'>
                        <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> has a Privacy Policy which applies 
                        to all registered users and is incorporated into this User Agreement by reference. 
                        Our current <a href="https://superbolt.com.au" className='fw-semibold'>Privacy Policy is available at <u>https://superbolt.com.au/#privacy-policy</u></a>. 
                        You must read and accept our <a href="https://superbolt.com.au" className='fw-semibold'>Privacy Policy</a> upon registration in order to use the Site and services, 
                        and you will continue to be bound by its provisions (and any amendments we make) while you are a registered users of <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>. 
                        </p>
                    
                    <li>No Warranty</li>

                        <p className='my-8 ms-8'>
                        <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>'s Site and Services are on an "As Available" basis, 
                        including text, graphics, hyperlinks and other items, and without any warranty to the extent permitted my law.
                        <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>, does not warrant the reliability, 
                        accuracy, completeness and adequacy of information and disclaims liability for omissions and errors. 
                        No warranty of any form, implied, statutory or expressed, as <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> makes no representation, 
                        warranty or endorsement of any of the services, products and information provided. 
                        Furthermore, we do not guarantee continuous, uninterrupted or secure access to our services, and operation of our Site may be interfered 
                        with by numerous factors outside our control. 
                        </p>

                    <li>Liability Limit</li>

                        <p className='my-8 ms-8'>
                        In no event shall <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>,
                        our affiliates, directors, agents, families, subsidiaries, employees and related entities or suppliers 
                        be liable for lost profits or any special, incidental, consequential damages (eg. personal injury or death)
                        arising out of or in connection with the Site, our services or this Agreement (however arising, including negligence). 
                        You agree to accept sole responsibility for the legality of your actions under the laws which apply to you. 
                        You also agree that <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> and
                        all affiliates and related entities of <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> hold no responsibility 
                        for the legality of our users' actions (things that they or other users post or carry out) to the extent permitted by law, 
                        our liability to you or any third parties in any circumstance is limited to the amount of fees you pay to us (AUD) in the 12 months 
                        prior to the action giving rise to liability.
                        </p>

                    <li >Indemnity</li>

                        <p className='my-8 ms-8'>
                        You agree to indemnify and hold us and (as applicable) our staff, directors, agents, affiliates, families, subsidiaries and employees harmless 
                        from and against any claims, demands, proceedings, losses and damages (actual, special and consequential) of every kind and nature,
                        known and unknown, including reasonable solicitors' fees, made by any third party due to or arising out of your breach of 
                        this Agreement or the terms and policies it incorporates by reference, or your violation of any law or the rights of a third party.
                        </p>

                    <li>Legal Compliance</li>

                        <p className='my-8 ms-8'>
                        You shall comply with all applicable domestic (including common law) and international laws, statutes, ordinances and regulations 
                        regarding your use of the Site and service and your bidding on, listing, purchase, trading, solicitation of offers to purchase, 
                        and sale of items. Specifically, you must ensure that your activities, items you list, 
                        and Your Information you post on our Site do not violate any Victorian and other relevant state-based fair trading legislation.<br/>
                        YOU ALONE ARE RESPONSIBLE FOR CONFIRMING THAT YOUR ACTIVITIES ARE LAWFUL. YOU MUST ENSURE THAT YOU COMPLY WITH ALL APPLICABLE LAWS. YOU MUST ALSO ENSURE THAT YOU COMPLY WITH ALL CLAUSES OF THIS USER AGREEMENT AND THE PRIVACY POLICY, AND ALL OTHER TERMS AND POLICIES INCORPORATED BY REFERENCE IN THOSE DOCUMENTS.
                        </p>

                    <li>No Agency</li>

                        <p className='my-8 ms-8'>
                        You and <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement.
                        </p>

                    <li>Arbitration</li>

                        <p className='my-8 ms-8'>
                        Any controversy or claim arising out of or in connection with this User Agreement may at our discretion be settled by binding arbitration by reference to a commercial disputes centre. You agree to be bound by the ruling arbitrator. The costs of the dispute are borne by the originator.
                        </p>
                        <p className='ms-8'>
                            <i className='bi bi-dot'></i> User Agreement FAQ at <a href="https://superbolt.com.au/faqs" className='fw-semibold'><u>https://superbolt.com.au/faqs</u></a><br/>
                            {/*<i className='bi bi-dot'></i> Announcement Board at <a href="/dashboard/index" className='fw-semibold'><u>https://superbolt.com.au/dashboard/index</u></a><br/>*/}
                            <i className='bi bi-dot'></i> Fees Policy at <a href="https://superbolt.com.au/pricing" className='fw-semibold'><u>https://superbolt.com.au/pricing</u></a><br/>
                            {/*<i className='bi bi-dot'></i> Usage Policy at <a href="/privacy-policy" className='fw-semibold'><u>https://superbolt.com.au/privacy-policy</u></a><br/>*/}
                            <i className='bi bi-dot'></i> Contact Us at <a href="https://superbolt.com.au/contact" className='fw-semibold'><u>https://superbolt.com.au/contact</u></a>                            
                        </p>

                    <li>General</li>

                        <p className='my-8 ms-8'>
                        This Agreement shall be governed in all respects by the laws of Australia. You agree that this Agreement and all incorporated agreements may be automatically assigned by <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a>, in our sole discretion, to a third party in the event of a merger or acquisition. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. You agree that this Agreement may not be construed adversely against us solely because we prepared it. 
                        </p>

                    <li>Disclosures </li>

                        <p className='my-8 ms-8'>
                        Any disputes between you and <a href="/" className='fw-semibold'> SUPERBOLT PTY LTD</a> regarding our services may be reported to <a href="https://superbolt.com.au/contact" className='fw-semibold'>Customer Support by going to <u>https://superbolt.com.au/contact</u></a>. 
                        We encourage you to try and resolve disputes with other users directly with them, and to use a certified mediation or arbitration entity or report disputes to your local police or law enforcement agency as appropriate.
                        </p>

                </ol>
            </p>
            
        </div>
                </div>
                <div className="modal-footer">
                    <button 
                        className='btn btn-primary'
                        data-tooltip-content={`Instant Play`}

                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div> 
      
    )
}

export {ModalTnC}