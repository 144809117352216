/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
// import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { Box } from '@mui/material';
import moment from 'moment';
import { 
  Element,
  Source,
  secondsToHHmmss 
} from '../../../../../redux/slices/content/contentModels';
import { setContentSource, setHoverLayerId, setHoverLayerSourceId, setHoverSectionId, setLayerId, setSectionId } from '../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { getSectColorCodes, getSectionIcon } from '../../../../../redux/slices/editor/editorModels';
import { setHidePanelRight } from '../../../../../redux/slices/editor/editorSlice';

//----------------------------------------

type Props = {
  className?: string,
}


//----------------------------------------
const AccordionHideShowBtn: React.FC<Props> = ({className}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const hidePanelR = useAppSelector(state => state.editor.hidePanelRight)  
  
  //-----------------------------------------
  return (
    <div 
      className={` ${className} position-absolute h-25px rounded w-100px w-sm-125px w-md-200px w-lg-175px w-xl-225px 
          ${hidePanelR ? "text-end" : ""}
      `}     
      // style={{zIndex:"1040", marginTop: "6px"}} 
      style={{zIndex:"109", marginTop: "6px"}} 
    >
      <button
        className={`btn btn-icon btn-sm text-hover-primary bg-white w-25px h-25px border border-1
          ${hidePanelR ? "me-n6" : "ms-n4"}        
        `}
        style={{paddingTop: "2px"}}
        onClick={()=> dispatch(setHidePanelRight(!hidePanelR))}        
      >
        {hidePanelR
          ? <>
              <i className="fa fa-angle-double-left fs-8"></i>
            </>
          : <i className="fa fa-angle-double-right fs-8"></i>
        }
      </button>
    </div>
  )
}

export {AccordionHideShowBtn}