/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { 
    FormControl,
    InputLabel, MenuItem, 
    Select,
    FormHelperText
} from '@mui/material'

import {useDropzone} from 'react-dropzone';

import { useGetAllRootFoldersQuery, useUploadFilesMutation } from "../../../../../../../redux/slices/media/apiMediaSlice";
import { useAppSelector } from '../../../../../../../redux/hook'
import FileUpload from '../../../../../../../commons/uploadFiles/FileUpload'

// ---------------------------------------

type Props = {  
  className?: string,
  title?: string,
}

const schema = yup.object().shape({
    // folderId: yup.string().required(),
    noFile: yup.number()
         .min(1)
         .required('At least one file selected.')
})

//----------------------------------------
export const BtnUploadFilesModal: React.FC<Props> = ({className, title}) => {
  
  const [uploadMedia] = useUploadFilesMutation();
  // -----------------------------------------

  const { data: allFolders } = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })      
  // console.log("allFolders",allFolders)

  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder: ",currentFolder)


  const [noFile, setNoFile] = useState('');
  const [selectedFolder, setSelectedFolder] = useState({                                                  
                                                  folderId: currentFolder.source_group_id,
                                                  sources: []
                                              })
  // console.log("selectedFolder", selectedFolder)

  // ---------------------------------------------
  const updateSources = (files: any) => {
      setSelectedFolder({ ...selectedFolder, sources: files})
  }

  
  
  

  // -----------------------
  const formSubmitHandler: SubmitHandler<any> = async (data) => {
      const formData = new FormData();
      // -----------------------------

      /* do manual validation if at least one valid file is selected for upload */
      if(selectedFolder.sources.length < 1){
          setNoFile("At least one file is selected")
      }        

      // formData.append("source_group_id", data.folderId)
      formData.append("source_group_id", currentFolder.source_group_id)
      formData.append("is_remote", "0")

      selectedFolder.sources.forEach(source => {
          formData.append("src_filepath[]", source)
          formData.append("i_url[]", "")
          formData.append("is_remote[]", "0")
          formData.append("url[]", "")
          formData.append("durationList[]", "")
          formData.append("widthList[]", "")
          formData.append("heightList[]", "")
      })
      
      await uploadMedia(formData);      
  }

  const { 
      register, 
      handleSubmit,
      
      formState:{
          errors,
          isDirty,
          
          isLoading,         
          isValid,   
          isSubmitting,
          isSubmitSuccessful,
          
      }
  } = useForm<any>({
      resolver: yupResolver(schema),
      defaultValues:{            
          noFile: 0
      },
      values:{
          noFile: selectedFolder.sources.length
      }
  }) 
  
  
  // ---------------------------------------------
  useEffect(() => { 
    setSelectedFolder({ ...selectedFolder, folderId: currentFolder.source_group_id})
  },[currentFolder])


  //-----------------------------------------
  return (<>
    
    <div className="btn-group btn-group-sm me-2" role="group" >
      <button
        // className={`btn btn-icon btn-sm btn-active-color-primary tooltip-bottom `}
        // // data-tooltip-content={`Upload to Folder "${currentFolder.group_name}"`}        
        // data-kt-menu-trigger="{default: 'click'}"
        // data-kt-menu-attach='parent'
        // data-kt-menu-placement='bottom-start'
        // data-kt-menu-flip='top-end'

        // disabled={currentFolder.source_group_id === ""? true:false}
        
        // data-bs-dismiss="offcanvas"
        // data-kt-drawer-dismiss="true"

        // data-bs-toggle="offcanvas"
        // aria-controls="staticBackdrop"
        // data-bs-target="#kt_drawer_edit_folder"
        // id="kt_drawer_edit_folder_button"
        // data-kt-drawer-show="true"
        
        className={`btn ${className}  text-hover-info `}
        data-tooltip-content='Upload Files'
        data-kt-menu-trigger="{default: 'click'}"
        // data-kt-menu-attach='parent'
        // data-kt-menu-placement='bottom-start'
        // data-kt-menu-flip='top-end'
        
        // // data-bs-dismiss="offcanvas"
        // // data-kt-drawer-dismiss="true"
        
        disabled={(currentFolder.source_group_id === "") || (currentFolder.readonly === 1)
            || (currentFolder.source_group_id === "bin")
          ? true:false
        }
        
        // data-bs-toggle="offcanvas"
        // aria-controls="staticBackdrop"
        // data-bs-target="#kt_drawer_edit_folder"
        // id="kt_drawer_edit_folder_button"
        // data-kt-drawer-show="true" 

        data-bs-toggle='modal'
        data-bs-target='#kt_modal_upload_files'
      >
        <i className="bi bi-cloud-upload fs-3 text-light"></i>
        <span className='fs-5 text-light text-hover-info'>{title}</span>
      </button>
      
    </div>
  </>)
}
