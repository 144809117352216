/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useDrop } from 'react-dnd'
import { useGetAllMediasQuery } from '../../../../../redux/slices/media/apiMediaSlice'
import { DraggableImage, RenderedHtmlText} from '../../EditorComponents'
import { Media, Medias, defaultThumbnails } from '../../../../../redux/slices/media/mediaModels'
import { Content, Element, IMAGEDURATION, ParamSource, Source, TEXTSTYLE_LIST, getBgTemplateSize, templateColorPallets } from '../../../../../redux/slices/content/contentModels'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook'
import { defaultEditorLandscapeHeight, defaultEditorLandscapeWidth, getCorrespondHeight, getCorrespondWidth, getSectColorCodes, getSectionIcon, sectColorCodes } from '../../../../../redux/slices/editor/editorModels'
import { setCheckSum, setContentSource, setHoverLayerId, setHoverLayerOrder, setHoverSectionId, setLayerId, setLayerOrder, setSectionId } from '../../../../../redux/slices/content/contentSlice'
import { setCurrentFolder, setCurrentFolderEmpty, setMultiSelectMode } from '../../../../../redux/slices/editor/editorSlice'
import moment from 'moment'
import { Item1 } from '../../../../../../_metronic/partials/content/activity/Item1'


//----------------------------------------

type Props = {
  className: string,  
  section: Element,
  sectionId: string
}

type Item = {
  id: string,
  url: string
}

const ARR_FALSE = [
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false,
  false, false, false, false, false, false, false, false, false, false
]

//section in this component is not automatically updated // pass by value... need to make it auto-update
//----------------------------------------
const DroppableTextZone: React.FC<Props> = ({className, section, sectionId}) => { 
  const dispatch = useAppDispatch()  
  //-----------------------------------------    
  const currEditorW     = useAppSelector(state => state.editor.currEditorWidth)
  const currEditorH     = useAppSelector(state => state.editor.currEditorHeight)
  const currEditorZoom  = useAppSelector(state => state.editor.zoomPercentage)

  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  // console.log("currContentPersistor in DroppableImageVideoZone: ", currContentPersistor)
  
  // console.log("section in DroppableImgVideoZone:", section)
  // console.log("sectionId in DroppableImgVideoZone:", sectionId)

  // const { data: allMedias, isLoading, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allMedias in DroppableImgVideoZone",allMedias)


  // console.log('=====================================')
  const unselectedSectIds = useAppSelector(state => state.content.unselectedSectionIds)  
  // console.log('unselectedSectIds:',unselectedSectIds)
  const currSectId = useAppSelector(state => state.content.currSectionId)  
  // console.log('currSectId:',currSectId)
  const currLayerId = useAppSelector(state => state.content.currLayerId)  
  // console.log('currLayerId:',currLayerId)
  const hoverSectId = useAppSelector(state => state.content.hoverSectionId)  
  // console.log('hoverSectId:',hoverSectId)
  const hoverLayerId = useAppSelector(state => state.content.hoverLayerId)  
  // console.log('hoverLayerId:',hoverLayerId)
  const hoverLayerOrder = useAppSelector(state => state.content.hoverLayerOrder)  
  // console.log('hoverLayerOrder:',hoverLayerOrder)
  const hoverLayerSourceId = useAppSelector(state => state.content.hoverLayerSourceId)  
  // console.log('hoverLayerSourceId:',hoverLayerSourceId)
    
  const [addText, setAddText]= useState<boolean>(false)
  // console.log(addText)

  const [relativeH, setRelativeH]= useState<number>(0)
  const THRESHOLD_H = 50
  
  const [sect, setSect] = useState<Element>(section)
  // const [boardSection, setBoardSection] = useState<ParamSource[]>([])
  const [boardSection, setBoardSection] = useState<Source[]>([])
  // console.log("boardSection: ",boardSection)
  // -------------------------------------------------------  

  
  // ---------------------------
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "text",
    drop: (item: Item) => {
      // console.log("dropped item:", item)
      addTextToDropZone(item.id, TEXTSTYLE_LIST)
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),  
  }), [currContentPersistor, TEXTSTYLE_LIST])   //the state needed must be here !!!


  // ----------------------------------
  const initSectionItems = () =>{
    var arrItems: any[] = []  //a variable to store an array of items
    // ------------------------
    const recentSect = currContentPersistor.elements.find((ele)=> {return ele.element_id === section.element_id})
    // console.log("recentSect in intiSectionItems: ", recentSect)
    
    recentSect?.sources.map((source)=>{

      // const itemList =  TEXTSTYLE_LIST.filter((txtStyl)=> {
      //                       return source.param_source[0].txtId && (txtStyl.txtId === source.param_source[0].txtId)
      //                     })
      const itemList = [source]
      // const itemList = [source.param_source[0]]
      // console.log("itemList: ", itemList)

      {itemList && 
        arrItems.push({
          ...itemList[0]                     
        })
      }
    })    
    
    // console.log("arrItems: ", arrItems)
    return arrItems
  }


  // ---------------------------
  const addTextToDropZone = (id: string, allTxtParamSrcs: ParamSource[] | undefined) => {

    var arrItems: any[] = initSectionItems()   

    const itemList =  TEXTSTYLE_LIST.filter((txtStyl)=> {return (txtStyl.txtId === id)})
    if (itemList){
      itemList[0] = {
        ...itemList[0]
      }
    }
    {itemList && arrItems.push(itemList[0])}

    // console.log("itemList",itemList)
    // console.log("arrItems",arrItems)

    // Add a dropped item to the boardSection
    // -------------------------------------------------    
    {arrItems && setBoardSection(arrItems) }
    
    // Add a dropped item to the currContentSource
    // -------------------------------------------------
    const recentSect = currContentPersistor.elements.find((ele)=> {return ele.element_id === section.element_id})

    var sourceList: Source[] = []
    var sourceOrder: number = 1
    recentSect?.sources.map((src)=>{
      sourceList = [...sourceList, {...src, order: src.order}]
      if(sourceOrder <= src.order) {sourceOrder = src.order+1}
    })
    
    // Determine the values of paramSrc
    // ------------------------------------------
    // - Remember the last slide's setting
    // - fit center, if (landscape section with portrait image/video) or inverse
    let newParamSrc: ParamSource[] = [{
                                        delay: 0,
                                        // duration: srcDuration,
                                        volume: null,

                                        bg_color:  sourceList.length> 0 ? sourceList[0].param_source[0].bg_color : "#00000000",

                                        ...itemList[0]
                                       
                                      }]

    // -----------------------------------
    var newSource: Source = {
      source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
      element_id    : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
      source_id: '0',
      order:  0,
      duration_override: -1,    

      type: "text",
      source:  null,

      param_source : newParamSrc
    }
    // console.log("newSource: ",newSource)

    sourceList = [newSource] //take only the last one
    // sourceList = [newSource, ...sourceList ] //add to the first position, // do not use push
    // console.log("sourceList after: ",sourceList)

    dispatch(setSectionId(sect.element_id))  //which section it belongs to
    dispatch(setContentSource(sourceList))

    // dispatch(setLayerId(newSource.source_list_id))
    // dispatch(setLayerOrder(sect.sources.length))

    dispatch(setHoverSectionId(sect.element_id))
    // dispatch(setHoverLayerId(newSource.source_list_id))    
    // dispatch(setHoverLayerOrder(sect.sources.length))                      
    
  }

  // ----------------------------------
  useEffect(() => {    
    var arrItems: any[] = initSectionItems()  //load items from section
    {arrItems && setBoardSection(arrItems)}

    setRelativeH(getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom))
  }, [currContentPersistor, sect, TEXTSTYLE_LIST])

  //-----------------------------------------
  // Main program here
  //-----------------------------------------
  return (
      <div 
        ref = {drop}
        key={sect.element_id} 
        onMouseOver={()=> {
          setAddText(true)
          dispatch(setHoverSectionId(section.element_id))
        }}
        onMouseOut={()=> {
          setAddText(false)
          dispatch(setHoverSectionId(""))
        }}       
        style={{
          background: boardSection.length? ``:`no-repeat ${getBgTemplateSize(sect.w, sect.h)} ${templateColorPallets[4]} url(${defaultThumbnails[3]})`,
          position:'absolute',          
          width: `${getCorrespondWidth (section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,
          height:`${getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,          
          left:  `${getCorrespondWidth (section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
          top:   `${getCorrespondHeight(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
        }}
        className={`${className} border             
          ${boardSection.length? 'border-0 border-gray-300':'border-2 border-gray-500 '}  
          ${isOver? ' bg-primary opacity-50':''}
          ${unselectedSectIds.length>0 && unselectedSectIds.includes(section.element_id) ? "d-none":""}
        `}        
      >
        { boardSection.slice(0).reverse().map((item, index)=> { 
            // console.log("----------------",index,".item", item)

            let arrIndexFound: number[] = []
            const arrSrcs: Source[] = section.sources.filter((src, ind)=> {                        
              if (src.source_list_id === item.source_list_id){              
                arrIndexFound.push(ind)
                return true
              }else{
                return false
              }
            })
            // console.log("arrSrcs:", arrSrcs)
            // console.log("arrIndexFound", arrIndexFound)

            // ---------------------------

            const seletedLayerOrder = hoverLayerOrder && hoverLayerOrder>=0 ? (section.sources.length - hoverLayerOrder): 0

            const foundIndex = arrIndexFound.findIndex(ele => ele === seletedLayerOrder)
            
            const currSrc: Source | undefined = arrSrcs[foundIndex]
            const currSrcParams: ParamSource | undefined = (currSrc && currSrc.param_source[0]) ? currSrc.param_source[0]: undefined

            // console.log("---------------------------------")
            // console.log("arrIndexFound:", arrIndexFound)
            // console.log("seletedLayerOrder:", seletedLayerOrder)

            // console.log("currSrc:", currSrc)
            // console.log("currSrcParams:", currSrcParams)

            let screenClassName    = "d-none"
            let containerClassName = "w-100 h-100"
            let containerStyle     = ["",""]

            if (currSrcParams){
              
              //--- Fill_to---------------------------
              if (!currSrcParams.fill_to || currSrcParams.fill_to  === "" || currSrcParams.fill_to  === "full"){ //Full stretching
                screenClassName=""
                containerClassName = "w-100 h-100"
                containerStyle = ["", ""]

              }else if (currSrcParams.fill_to === "fit"){ //Fit to the screen
                screenClassName = "d-flex"
                if (section.w >= section.h){
                  containerClassName = "h-100 "
                  containerStyle = ["fit-content", "auto"] //style [width, height]
                }else{
                  containerClassName = "w-100 "
                  containerStyle = ["auto", "fit-content"]
                }

              }else if (currSrcParams.fill_to === "original"){  //original size
                  screenClassName = "d-flex"

                  const maxEditorPixels = (currEditorW >= currEditorH) ? currEditorW: currEditorH
                  // const itemW = getCorrespondWidth (item.width, item.height, currEditorW, currEditorH, currEditorZoom)
                  // const itemH = getCorrespondHeight(item.width, item.height, currEditorW, currEditorH, currEditorZoom)
                  // const itemW = getCorrespondWidth (item.width, item.height, 1920, 1080, currEditorZoom)
                  // const itemH = getCorrespondHeight(item.width, item.height, 1920, 1080, currEditorZoom)

                  const itemW = getCorrespondWidth ( 1920, 1080, 1920, 1080, currEditorZoom)
                  const itemH = getCorrespondHeight( 1920, 1080, 1920, 1080, currEditorZoom)

                  // console.log("item: ", item.width, item.height)
                  // console.log("item After: ", itemW, itemH)
                  // console.log("item After 2: ", itemW / maxEditorPixels, itemH / maxEditorPixels)


                  containerStyle = [Math.floor(itemW)+"px", Math.floor(itemH)+"px"]
              }

              //--- Alignment---------------------------
              if (!currSrcParams.alignment || currSrcParams.alignment  === ""){
                screenClassName += " align-items-start justify-content-start"
              }else if (["center"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-center justify-content-center"
              }else if (["left"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-center justify-content-start"
              }else if (["right"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-center justify-content-end"

              }else if (["top"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-start justify-content-center"
              }else if (["top left"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-start justify-content-start"
              }else if (["top right"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-start justify-content-end"

              }else if (["bottom"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-end justify-content-center"
              }else if (["bottom left"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-end justify-content-start"
              }else if (["bottom right"].includes(currSrcParams.alignment)){
                screenClassName += " align-items-end justify-content-end"
              }
            }
            
            // console.log("screenClassName: ", screenClassName)
            // console.log("containerClassName: ", containerClassName)            
            // console.log("containerStyle: ", containerStyle)
            // console.log("SRC: ", ((sect.element_id === hoverSectId) ||
            //                       (hoverLayerSourceId === "" )
            //                         ? "":"d-none")
            // )
            // console.log("currSrc ? d-none: ", currSrc ? "":"d-none")

            // ----------------------------------            
            return (
              <div key={`txtZone-${index}`}
                className={`SCR position-absolute w-100 h-100 
                  ${screenClassName}                  
                  ${(sect.element_id === hoverSectId) ||
                    (hoverLayerSourceId === "" )
                      ? "":"d-none"
                  }                  
                  ${currSrc ||
                    (hoverLayerId === "" )
                    ? "":"d-none"
                  }
                `}
                style={{
                  zIndex: `${(sect.element_id === hoverSectId) && 
                    ((item.source_id === hoverLayerSourceId) || (item.source_list_id === hoverLayerId)) 
                    ? "1":"0"}`,
                  backgroundColor: `${currSrcParams ? currSrcParams.bg_color : "#00000000"}`
                  // backgroundColor: "#00000000"
                }}                                
              >
                <div 
                  className={`CTNer ${containerClassName} `}
                  style={{
                    width:  `${containerStyle[0]}`,
                    height: `${containerStyle[1]}`
                  }}
                >
                  <div 
                    className={`border w-100 h-100 text-white d-flex align-items-center
                      ${ sect.element_id === hoverSectId ? "section-outline-hover-primary":"border-0"}
                    `}
                    style={{zIndex: 3500}}
                  >
                    <div className='w-100 h-100 fs-1' 
                      style={{
                        // backgroundColor: item.backgroundcolor? (item.backgroundcolor[0]==="#"? item.backgroundcolor : "#"+item.backgroundcolor) : ""
                        // backgroundColor: item.param_source[0].backgroundcolor? (item.param_source[0].backgroundcolor[0]==="#"? item.param_source[0].backgroundcolor : "#"+item.param_source[0].backgroundcolor) : ""
                      }}
                    >
                      <RenderedHtmlText className="w-100 h-100" item={item.param_source[0]} />
                    </div>
                  </div>                  
                </div>
              </div>
            )             
          })
        }

        {/* hover part */}
        <div 
          className={`border w-100 h-100 position-absolute top-0 start-0 
            section-outline-hover-primary
            cursor-pointer            
          `}
          title={section.sources.length <= 0 ? "Add text":"Add more text"}  
          style={{background: `#ffffff66 `}}
          // style={{background: `#ffffff66 ${section.sources.length <= 0? "": "url('/media/_editor/defaultWebIcon-white.png') center center / 35% no-repeat"}`}}
  
          hidden={!addText}
        
          // data-kt-menu-trigger="{default: 'click'}"                    
          // data-bs-toggle='modal'
          // data-bs-target="#modal-text-editor"      
          
          data-bs-toggle="offcanvas"
          aria-controls ="staticBackdrop"                      
          data-bs-target="#kt_drawer_text_editor"

          onClick={() => { 
            dispatch(setSectionId(sect.element_id))
            dispatch(setLayerId(""))
            dispatch(setCheckSum(0))           
            
            dispatch(setLayerOrder(-1))
          }}
        >
          <form id="frm-add-content-text" className='form h-100' 
            // onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className='h-100 d-flex justify-content-center top-50 align-items-center text-center'>
              <div className='w-75'>
                <button type="button" 
                  className={`btn btn-sm btn-info opacity-75 rounded tooltip-bottom
                    ${relativeH > THRESHOLD_H ? "p-4": "p-1"} 
                  `}
                  style={{marginRight:`${relativeH > THRESHOLD_H ? "-3px": "-1px"}`, borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                  data-tooltip-content={section.sources.length <= 0 ? "Add text":"Add more text"}  
                >
                  <KTSVG path={`/media/icons/duotune/arrows/arr013.svg`} 
                    className={`m-0 ${relativeH > THRESHOLD_H ? "svg-icon-2x": " svg-icon-2"}`}
                  />
                </button>                                  
              </div>
            </div>
          </form>
        </div>

        {/* Label of each section */}
        <div 
          className='position-absolute top-0 d-flex justify-content-between'
          style={{zIndex: `${section.element_id === hoverSectId ?"104":""}` }} 
        >
          <div 
            className={`py-1 px-2 fw-light fs-9 text-white opacity-75
              ${section.element_id === hoverSectId ? "bg-primary": `bg-${getSectColorCodes(section.element_type)}`} 
            `} 
            style={{borderBottomRightRadius:"5px"}}
          >
            <span className='w-20px'>
              {getSectionIcon(section.element_type, "text-white")}                      
            </span>                    
            <span className='fs-8 pt-2 text-truncate w-150px'>
              {sectionId} [{Math.floor(section.w*1.5)}x{Math.floor(section.h*1.5)}]                      
            </span>                     
          </div>
        </div>

      </div>    
  )
}

export {DroppableTextZone}