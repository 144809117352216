import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultEditorLandscapeHeight, defaultEditorLandscapeWidth, EditorStatusParams, InitialState } from './editorModels';
import { actions } from 'react-table';
import { Folder } from '../media/mediaModels';
import { DEFAULT_ZOOM_PERCENTAGE } from '../content/contentModels';


const initialState: InitialState = {
    hideDashboard : false,
    hidePanelRight: false,
    editorModeOn : false,  
    selectedMenu : 'template',  
    currentFolder : {
        source_group_id: "",
        group_name: "",
    },
    newSelectedContentIds: [],
    currEditorWidth : defaultEditorLandscapeWidth,
    currEditorHeight: defaultEditorLandscapeHeight,
    zoomPercentage: DEFAULT_ZOOM_PERCENTAGE,

    multiSelectMode: false,
    selectedMediaSrcIds: [],

    textEditorCnt: "",

    editorStatusParams: {
        savingStatus: 0
    }
}

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers:{
        setHideDashboard: (state, action: PayloadAction<boolean>) =>{
            state.hideDashboard = action.payload
        },
        setHidePanelRight: (state, action: PayloadAction<boolean>) =>{
            state.hidePanelRight = action.payload
        },
        toggleEditorMode: (state) =>{
            state.editorModeOn = !(state.editorModeOn)
        },
        enableEditorMode: (state) =>{
            state.editorModeOn = true
        },        
        disableEditorMode: (state) =>{
            state.editorModeOn = false
        },
        setSelectedMenu: (state, action: PayloadAction<string>) => {
            state.selectedMenu = action.payload
        },
        setCurrentFolder: (state, action: PayloadAction<Folder>) => {
            state.currentFolder = action.payload
        },
        setCurrentFolderEmpty: (state) => {
            state.currentFolder = {
                                    source_group_id: "",
                                    group_name: "",
                                }
        },
        setNewSelectedContentIds(state, action: PayloadAction<string[]>){ 
            const newContentIds: string[] = action.payload           
            // console.log("newContentIds in Slice", newContentIds)
            state.newSelectedContentIds = newContentIds
        },

        setCurrentEditorWidth: (state, action: PayloadAction<number>) => {
            state.currEditorWidth = action.payload
        },
        setCurrentEditorHeight: (state, action: PayloadAction<number>) => {
            state.currEditorHeight = action.payload
        },
        setZoomPercentage: (state, action: PayloadAction<number>) => {
            state.zoomPercentage = action.payload
        },   
        setMultiSelectMode: (state, action: PayloadAction<boolean>) =>{
            state.multiSelectMode = action.payload
            
            if (action.payload === false){
                state.selectedMediaSrcIds = [] //set to empty array
            }
        },  
        setSelectedMediaSrcIds(state, action: PayloadAction<string[]>){ 
            const mediaIds: string[] = action.payload           
            state.selectedMediaSrcIds = mediaIds
        },   
        setTextEditorCnt(state, action: PayloadAction<string>){
            state.textEditorCnt = action.payload
        },
        setEditorStatusParams(state, action: PayloadAction<EditorStatusParams>){
            state.editorStatusParams = action.payload
        },
    }
})

export default editorSlice.reducer
export const {
    setHideDashboard,
    setHidePanelRight,
    toggleEditorMode,
    enableEditorMode,
    disableEditorMode,  
    setSelectedMenu,  
    setCurrentFolder,
    setCurrentFolderEmpty,
    setNewSelectedContentIds,

    setCurrentEditorWidth,
    setCurrentEditorHeight,
    setZoomPercentage,
    
    setMultiSelectMode,
    setSelectedMediaSrcIds,

    setTextEditorCnt,

    setEditorStatusParams,

}  = editorSlice.actions
