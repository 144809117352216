import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hook'
import { useGetAllMediasQuery, useGetAllRootFoldersQuery } from '../../../../../../../redux/slices/media/apiMediaSlice'
import { IMAGEDURATION, ParamSource, Source, formatBytes, secondsToHHmmss } from '../../../../../../../redux/slices/content/contentModels'
import { setContentSource, setMedia } from '../../../../../../../redux/slices/content/contentSlice'
import { setCurrentFolder, setCurrentFolderEmpty, setMultiSelectMode, setSelectedMediaSrcIds } from '../../../../../../../redux/slices/editor/editorSlice'
import { BtnNewFolder } from '../buttons/BtnNewFolder'
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import { BtnNewFolderModal, BtnUploadFilesModal } from '../../../../EditorComponents'
import moment from 'moment'
import { DEFAULT_LOAD_ATTEMPT } from '../../../../../../../redux/slices/editor/editorModels'
import { KTSVG } from '../../../../../../../../_metronic/helpers'

type Props = {  
    className?: string
}

export const ModalSelectImageVideo: React.FC<Props> = ({className=""}) => {
    const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }

    const getColumns = (width: number) => {
        if (width < breakpoints.sm) {
            return 2
        } else if (width < breakpoints.md) {
            return 3
        } else if (width < breakpoints.lg) {
            return 4
        } else if (width < breakpoints.xl) {
            return 4
        } else {
            return 4
        }
    }
    const dispatch = useAppDispatch()

    const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true }) 
    const { data: allMedias, isLoading, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })
    
    
    const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
    const currSectId = useAppSelector(state => state.content.currSectionId)  
    const currentFolder = useAppSelector(state => state.editor.currentFolder) 
      
    const [defaultFolderIndex, setDefaultFolderIndex] = useState(-1)
    const [grpName, setGrpName] = useState(currentFolder.group_name)
    const [columns, setColumns] = useState(getColumns(window.innerWidth))
    
    const [imgError, setImgError] = useState<number[]>([])
    const [vidError, setVidError] = useState<number[]>([])
    const [loadAttempt, setLoadAttemp] = useState(0)

    const selMediaSrcIds = useAppSelector(state => state.editor.selectedMediaSrcIds)  
    
    
    /**
     * Need to handle selected source ids outside the modal
     * i.e. drag-drop, deleted from section layer, edit existing content.
     * 
     */
    /*
    function setupSelectedSourceIds()
    {
      currContentPersistor.elements.map((sect, index) => {
        if(currSectId == sect.element_id)
        {
          sect.sources.map((source, i)=>{
            if(!arrSourceIds.includes(source.source_id))
            {
              arrSourceIds.push(source.source_id);
            }
          })
        }
      })
    }

    useEffect(() =>{
      setupSelectedSourceIds();
      setAssignSourceIds(arrSourceIds)
    }, [currContentPersistor])
    */
    
    
    const updatedimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    const addSelectedMediaFiles = () => {
        var arrItems: any[] = []
         
        const recentSect = currContentPersistor.elements.find((ele)=> {return ele.element_id === currSectId})
        recentSect?.sources.map((source: Source)=>{
            const itemList =  allMedias?.data.filter((file)=> {return (file.source_id == source.source_id)})
            {itemList && arrItems.push({
                ...itemList[0],           
                duration: (itemList[0].duration>0 ? itemList[0].duration : IMAGEDURATION)
              })
            }
        })

        const itemList =  allMedias?.data.filter((file)=> {
            return (selMediaSrcIds.includes(file.source_id ))
        })

        if (itemList){
            itemList.map((item, ind) => {
              itemList[ind] = {
                ...itemList[ind],
                duration : (itemList[ind].duration>0 ? itemList[ind].duration : IMAGEDURATION)
              }
              arrItems.push(itemList[ind])
            })      
        }
        var sourceList: Source[] = []
        var sourceOrder: number = 1
        recentSect?.sources.map((src)=>{
          sourceList =[...sourceList, {...src, order: src.order}]
          if(sourceOrder <= src.order) sourceOrder = src.order+1
        })
        if (itemList){
            itemList.map((item, ind) => {
              
              // Determine the values of paramSrc
              // ------------------------------------------
              // - Remember the last slide's setting
              // - fit center, if (landscape section with portrait image/video) or inverse
              let newParamSrc: ParamSource[] = [{
                                                  delay: 0,
                                                  duration: (itemList[ind].duration>0 ? itemList[ind].duration : IMAGEDURATION),
                                                  volume: null,

                                                  bg_color:  sourceList.length> 0 ? sourceList[0].param_source[0].bg_color : "#00000000"
                                                }]
              if ((recentSect && itemList) && 
                  (
                    ((recentSect.w >= recentSect.h) && (itemList[0].width <  itemList[0].height)) ||  //for landscape section, portrait  items
                    ((recentSect.w <  recentSect.h) && (itemList[0].width >= itemList[0].height))     //for portrait  section, landscape items
                  )
              ){  
                newParamSrc = [{
                                ...newParamSrc[0],
                                fill_to:   "fit",
                                alignment: "center"
                              }]
              }else{
                newParamSrc = [{
                                ...newParamSrc[0],
                                fill_to:   sourceList.length> 0 ? sourceList[0].param_source[0].fill_to  : "",
                                alignment: sourceList.length> 0 ? sourceList[0].param_source[0].alignment: ""
                              }]
              }


              var newSource: Source = {
                source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                // source_list_id: (ind+1).toString().padStart(2, '0'),
                element_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                // element_id: (ind+1).toString().padStart(2, '0'),
                source_id: itemList?itemList[ind].source_id:"",      
                order:  sourceOrder++,
                duration_override: -1, 
                param_source: newParamSrc,     
                // param_source : [{
                //   delay: 0,
                //   duration: (itemList[ind].duration>0 ? itemList[ind].duration : IMAGEDURATION),
                //   volume: null,

                //   bg_color:  sourceList.length> 0 ? sourceList[0].param_source[0].bg_color : "#00000000",
                //   fill_to:   sourceList.length> 0 ? sourceList[0].param_source[0].fill_to: "",
                //   alignment: sourceList.length> 0 ? sourceList[0].param_source[0].alignment: ""
                // }],
                source:  itemList? itemList[ind]:null,
              }
              
              sourceList = [newSource, ...sourceList ] //do not use push
            })      
        }
        
        dispatch(setContentSource(sourceList))
        dispatch(setSelectedMediaSrcIds([]))
    }

    useEffect(() => {
        window.addEventListener("resize", updatedimensions);
        return () => window.removeEventListener("resize", updatedimensions);
    }, []);

    useEffect(() => {
        setGrpName(currentFolder.group_name)  
    }, [currentFolder])

    useEffect(() => {        
        if (rootFolders && currentFolder.source_group_id !== ""){    
          const updatedCurrFolder = rootFolders.data.filter((fold, index)=> fold.source_group_id === currentFolder.source_group_id)[0]
          
          if (updatedCurrFolder) {
            setGrpName(updatedCurrFolder.group_name)  
            setCurrentFolder(updatedCurrFolder)
          }
        }        
    }, [ rootFolders ])

    return (<>
<div className={`modal fade ${className}`} tabIndex={-1} 
    id='kt_modal_select_image_video'
>
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content mh-600px pb-5">
        <div className='bg-gradient-primary py-2 px-3 rounded-top'>
            <BtnNewFolderModal  title="New" noIcon={false} className='btn-icon btn-sm btn-active-color-primary ms-5 me-2'/>
            <BtnUploadFilesModal title="Upload"/>
            <div className="float-end btn-group btn-group-sm" role="group" >        
                <button
                    className='btn btn-icon btn-close'
                    data-bs-dismiss="modal" 
                    aria-label="Close"
                >                
                </button>
            </div>
        </div>
        
      

      {/* {currentFolder.group_name !== "" && */}
      <div className='w-100 d-flex justify-content-between bg-gray-300 py-3 ps-5'>
        <ol className="breadcrumb text-muted fs-6 fw-bold">
          <li className="pe-0">
            {currentFolder.group_name !== "" 
              ? <a href="#" className="" onClick={() => {dispatch(setCurrentFolderEmpty())}}>              
                  <i className='fa fa-folder-open text-primary me-2'></i>
                  <span className={`mt-2 text-capitalize tooltip-bottom`} data-tooltip-content="Back to 'My Gallery' to create new folder(s)">My Gallery</span>
                  <i className='fa fa-chevron-right text-muted mx-2'></i>
                </a>
              : <span className={`mt-2 text-capitalize`}><i className='fa fa-folder-open'></i> My Gallery</span>
            }
          </li> 
          {currentFolder.group_name !== "" && rootFolders?.data.filter((folder, index)=> folder.source_group_id === currentFolder.source_group_id)[0] &&
            <li className="breadcrumb-item px-0 text-muted ">
              {/* <a href="#"
                className='text-muted text-hover-primary tooltip-bottom'
                onClick={() => dispatch(setCurrentFolder(rootFolders?.data.filter((folder, index)=> folder.source_group_id === currentFolder.source_group_id)[0]))}
            
                data-tooltip-content='Click to edit this folder.'

                data-bs-toggle="offcanvas"
                aria-controls="staticBackdrop"
                data-bs-target="#kt_drawer_edit_folder"
                id="kt_drawer_edit_folder_button"
                data-kt-drawer-show="true"
              > */}
                {/* <i className='fa fa-folder-open me-2'></i> */}
                <span className='text-capitalize'>{grpName}</span>
              {/* </a> */}
            </li>
          }
        </ol>
        <div className='me-5'>
          <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
            <button className='btn btn-light btn-sm text-hover-primary py-2 px-3'
              onClick={()=> {
                dispatch(setMultiSelectMode(true))
                dispatch(setSelectedMediaSrcIds(currentFolder.sources? currentFolder.sources.map(src => src.source_id) : []))
              }}
              disabled = {(currentFolder.group_name === "") || 
                ((selMediaSrcIds.length > 0) && (currentFolder.sources && selMediaSrcIds.length === currentFolder.sources.length))
              }
            >
              <i className='bi bi-check-all fs-3 me-1'></i>
              <span>Select All</span>
            </button>
            <button className='btn btn-light btn-sm text-hover-primary py-2 px-3'
              onClick={()=> {
                dispatch(setSelectedMediaSrcIds([]))
              }}
              disabled = {selMediaSrcIds.length <= 0}
            >
              <i className='bi bi-x fs-3 me-1'></i>
              <span>Unselect All</span>
            </button>
          </div>
        </div>
      </div>
      {/* } */}
      <div 
        className='h-100 w-100 ps-8 pt-8 pb-3'
        style={{maxHeight:"100%", overflowX:"hidden"}}
      >
        <div 
          id="kt_accordion_2" 
          className="accordion accordion-icon-toggle" 
          style={{maxHeight:"100%", overflowX:"hidden"}}
        >
          { rootFolders?.data.slice().sort((a,b)=> a.group_name.toLowerCase() > b.group_name.toLowerCase() ? 1:-1)
          .map((folder, index) => {
            const mediaFiles =  allMedias?.data.filter((file)=> {
              return (file.type !== "external") && (file.source_group_id === folder.source_group_id)
            })
            const isCurrFolder = (folder.source_group_id === currentFolder.source_group_id)
            // ---------------------------------------------------------
            return (
              <div className="mb-0" key={index}>
                { (currentFolder.group_name==="")&&
                  <div className='row px-5 bg-hover-light-primary'>
                    <div
                      className={`accordion-header py-1 d-flex  text-start col-9                        
                        ${isCurrFolder ? "":"collapsed"}
                        ${mediaFiles && mediaFiles.length <= 0 ? "text-muted":"text-gray-800"}
                      `}
                      onClick={() => {
                        dispatch(setCurrentFolder(folder))
                      }}
                    > 
                      <span className="mt-2">
                        <i className={`fa ${isCurrFolder ? "fa-folder-open text-primary":"fa-folder" }`}></i>
                      </span>
                      <span className={`mt-2 ms-2 ${isCurrFolder?"fw-bold":"fw-semibold"}`}>
                        {folder.group_name}
                      </span>
                    </div>
                    <div className='col-3 text-end'>
                      {mediaFiles && mediaFiles.length >0 &&
                        <span className="badge  badge-light fw-semibold mt-2 ms-2">{mediaFiles.length}</span>
                      }                        
                    </div>
                  </div>
                }
                {/* Files in each folder */}
                <div
                  id={`kt_accordion_2_item_${index}`}                    
                  className={`fs-6 collapse px-3
                    ${(folder.source_group_id === currentFolder.source_group_id) ? "show": (index === defaultFolderIndex ? "show":"")} 
                  `}
                  data-bs-parent="#kt_accordion_2"
                > 
                  { (!mediaFiles || mediaFiles.length <= 0) &&
                    <div className='d-flex flex-center'>
                      <img src='/media/_status/empty-folder.webp' className='w-75 opacity-25' />
                    </div>
                  }
                  { mediaFiles && mediaFiles.length > 0 &&
                    <ImageList 
                      sx={{ width: 'auto', paddingRight: '0px' }}
                      cols={columns} 
                      gap={5}
                    >
                      { mediaFiles
                      .slice().sort((a,b)=> {
                        // console.log('a', a.created_at, moment.utc(moment(a.created_at, true)).local())
                        // console.log(moment.utc(moment(a.created_at, true)).local().diff(moment.utc(moment(b.created_at, true)).local(), "seconds"))
                        return moment.utc(moment(a.created_at, true)).local().diff(moment.utc(moment(b.created_at, true)).local(), "seconds") < 0 ? 1: -1
                      })
                      .map((item, ind) => {
                          const limitFileNamelen = 12
                          const fileName = item.name.split('.').slice(0, -1).join('.')
                          const ext = item.name.split('.').pop()
                          const isSelected = selMediaSrcIds.includes(item.source_id)
                          // --------------------------------------------
                          return ( 
                            <Fragment key={ind}>
                              {
                                {
                                  'image':
                                    <ImageListItem 
                                      className={`item-hover-light-primary 
                                        ${isSelected ?"bg-primary text-white opacity-25":""}
                                      `}

                                      onClick={()=>{
                                        if (selMediaSrcIds.includes(item.source_id)){
                                          dispatch(setSelectedMediaSrcIds(selMediaSrcIds.filter(id => id !== item.source_id)))
                                        }else{
                                          dispatch(setSelectedMediaSrcIds([...selMediaSrcIds, item.source_id]))
                                        }
                                      }}
                                    > 
                                      {imgError.includes(ind) 
                                        ? <div className='rounded-0 w-100 h-100px bordered-1 border-light-primary-darker '>
                                            <span className="text-primary fw-bold text-center">Error</span><br />
                                            <span>This image cannot add to section.</span>
                                          </div>
                                        : <div className=''>
                                            <img                                                       
                                              className={`${className} rounded-0 bordered-0 
                                                ${item.width >= item.height? " w-100 h-auto " : " w-auto h-auto"}                                             
                                              `}
                                              style={{
                                                maxHeight: `${item.width >= item.height? "100px" : "315px"}`, 
                                                maxWidth:  `${item.width >= item.height? "190px" : "190px"}` 
                                              }}
                                              src={`${item.is_remote === 0 ? item.url_thumb : item.url}`}
                                              onError={({ currentTarget }) => {
                                                currentTarget.src= '../media/icons/loading.gif';
                                                currentTarget.draggable = false
                                                
                                                setTimeout(function(){
                                                  currentTarget.src = item.url_thumb
                                                  setLoadAttemp(loadAttempt+1)
                                                  if(loadAttempt == DEFAULT_LOAD_ATTEMPT)
                                                  {
                                                    currentTarget.src = item.url
                                                  }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT)
                                                  {
                                                    currentTarget.onerror = null; // prevents looping
                                                    setImgError([
                                                      ...imgError, ind
                                                    ])
                                                    
                                                  }
                                                }, 1000
                                                )
                                              }}
                                              alt={item.name}
                                              loading="lazy"                                              
                                            />
                                            <div className='d-flex justify-content-between m-1 mb-2'>
                                              <span className='d-flex fs-9 fw-semibold text-muted text-capitalize tooltip-bottom'
                                                data-tooltip-content={item.name}
                                              >
                                                <i className={`bi ${item.is_remote === 0 ? "bi-image":"bi-share-fill"} text-gray-400 me-2`}></i>
                                                {`${fileName.length <= limitFileNamelen ? fileName: fileName.substring(0, limitFileNamelen)+"..."}.${ext}`}
                                              </span>
                                              <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
                                            </div>
                                          </div>
                                      }
                                      <ImageListItemBar
                                        position='top' className='rounded-bottom bg-transparent'
                                        actionIcon={<>
                                          {isSelected === true &&
                                            <span className='opacity-100 m-0 mt-1 ms-1 p-1 pb-0 position-absolute top-0 start-0 bagde bg-info rounded-circle'
                                              style={{borderBottomRightRadius:"5px"}}
                                            >
                                              <i className='bi bi-check text-light fw-bold fs-3 shadow-sm'/>
                                            </span>
                                          }                                           
                                        </>}
                                      /> 
                                    </ImageListItem>,
                                  'video':
                                    <ImageListItem 
                                      className={`item-hover-light-primary 
                                        ${isSelected?"bg-primary text-white opacity-25":""}
                                      `}
                                      
                                      onClick={()=>{
                                        if (selMediaSrcIds.includes(item.source_id)){
                                          dispatch(setSelectedMediaSrcIds(selMediaSrcIds.filter(id => id !== item.source_id)))
                                        }else{
                                          dispatch(setSelectedMediaSrcIds([...selMediaSrcIds, item.source_id]))
                                        }
                                      }}
                                    > 
                                      {vidError.includes(ind) 
                                        ? <div className='rounded-0 w-100 h-100px bordered-1 border-light-primary-darker'>
                                            <span className="text-primary fw-bold text-center">Error</span><br />
                                            <span>This video cannot add to section.</span>
                                          </div>
                                        : 
                                          <div className=''>
                                            <img                                                       
                                              className={`${className} rounded-0 bordered-0 
                                                ${item.width >= item.height? " w-100 h-auto " : " w-auto h-auto"}                                             
                                              `}
                                              style={{
                                                maxHeight: `${item.width >= item.height? "100px" : "315px"}`, 
                                                maxWidth:  `${item.width >= item.height? "190px" : "190px"}` 
                                              }}
                                              // className={`${className} rounded-0 bordered-0 
                                              //   ${item.width >= item.height? " w-100 h-auto max-w-landscape" : " w-auto h-auto max-w-portrait"}
                                              // `}
                                              // style={{
                                              //   maxHeight: `${item.width >= item.height? "100px" : "300px"}`, 
                                              // }}
                                              src={`${item.is_remote === 0 ? item.url_thumb : item.url}`}
                                              onError={({ currentTarget }) => {
                                                currentTarget.src= '../media/icons/loading.gif';
                                                currentTarget.draggable = false
                                                
                                                setTimeout(function(){
                                                  currentTarget.src = item.url_thumb
                                                  setLoadAttemp(loadAttempt+1)
                                                  if(loadAttempt == DEFAULT_LOAD_ATTEMPT)
                                                  {
                                                    currentTarget.src = item.url
                                                  }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT)
                                                  {
                                                    currentTarget.onerror = null; // prevents looping
                                                    setImgError([
                                                      ...imgError, ind
                                                    ])
                                                    
                                                  }
                                                }, 1000
                                                )
                                              }}
                                              alt={item.name}
                                              loading="lazy"
                                            />
                                          </div>
                                          // <video autoPlay={false} controls={false} muted 
                                          //   key={item.url}
                                          //   className={`${className} rounded-0 w-100 bordered-0`}
                                          //   onError={({ currentTarget }) => {
                                          //     currentTarget.onerror = null;
                                            
                                          //     setVidError([
                                          //       ...vidError, ind
                                          //     ])
                                
                                          //   }}
                                          //   onClick={()=>{
                                          //     handleSourceClick(item.source_id)
                                          //   }}
                                          // >
                                          //   <source src={`${item.url}#t=3`} type="video/mp4" />
                                          // </video>
                                      }
                                      <ImageListItemBar
                                        position='top' className='rounded-bottom bg-transparent'
                                        actionIcon={<>
                                          {isSelected === true &&
                                            <span className='opacity-100 m-0 mt-1 ms-1 p-1 pb-0 position-absolute top-0 start-0 bagde bg-info rounded-circle'
                                              style={{borderBottomRightRadius:"5px"}}
                                            >
                                              <i className='bi bi-check text-light fw-bold fs-3 shadow-sm'/>
                                            </span>
                                          }                                           
                                        </>}
                                      /> 
                                      <ImageListItemBar
                                        position={`${item.width >= item.height? "top" : "bottom"}`} 
                                        className='rounded-top bg-transparent mb-8'   
                                        style={{marginTop: `${item.width >= item.height? "60px" : ""}`}}                                                                                            
                                        actionIcon={<>
                                          <span className="badge badge-dark text-gray-100 p-1 me-2">{secondsToHHmmss(item.duration)}</span>  
                                          <a className='opacity-100 m-0 mt-n1 ms-0 pt-1 pb-0 px-1 position-absolute top-0 start-0 bagde tooltip-bottom'
                                            style={{borderBottomRightRadius:"5px"}}
                                            data-bs-toggle="modal"
                                            data-bs-target="#modal-preview-video"
                                            onClick={() => { dispatch(setMedia(item)); }}
                                          >
                                            <i className="bi bi-play-circle-fill fs-1 text-white text-hover-gradient-primary"></i>                                    
                                          </a>                                                  
                                        </>}        
                                      />       
                                        <div className='d-flex justify-content-between m-1 mb-2'>
                                          <span className='d-flex fs-9 fw-semibold text-muted tooltip-bottom'
                                            data-tooltip-content={item.name}
                                          >
                                            <i className='fa fa-video-camera text-info opacity-100 me-2'></i>
                                            {`${fileName.length <= limitFileNamelen ? fileName: fileName.substring(0, limitFileNamelen)+"..."}.${ext}`}
                                          </span>
                                          <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
                                        </div>
                                      </ImageListItem>,

                                    //   <ImageListItemBar
                                    //     position='bottom' className='rounded-top bg-transparent mb-8'                                                                                               
                                    //     actionIcon={
                                    //       <span className="badge badge-dark text-gray-100 p-1 me-2">{secondsToHHmmss(item.duration)}</span>                                                  
                                    //     }        
                                    //   /> 
                                    //   <div className='d-flex justify-content-between m-1 mb-2'>
                                    //     <span className='d-flex fs-9 fw-semibold text-muted tooltip-bottom'
                                    //       data-tooltip-content={item.name}
                                    //     >
                                    //       <i className='fa fa-video-camera text-info opacity-100 me-1'></i>
                                    //       {`${fileName.length <= limitFileNamelen ? fileName: fileName.substring(0, limitFileNamelen)+"..."}.${ext}`}
                                    //     </span>
                                    //     <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
                                    //   </div>
                                    // </ImageListItem>,
                                  'pdf':  
                                    <ImageListItem 
                                      className={`item-hover-light-primary 
                                        ${isSelected ?"bg-primary text-white opacity-25":""}
                                      `}

                                      onClick={()=>{
                                        if (selMediaSrcIds.includes(item.source_id)){
                                          dispatch(setSelectedMediaSrcIds(selMediaSrcIds.filter(id => id !== item.source_id)))
                                        }else{
                                          dispatch(setSelectedMediaSrcIds([...selMediaSrcIds, item.source_id]))
                                        }
                                      }}
                                    >                                                                            
                                      <div className=''>                                        
                                        <div className='rounded-0 w-100 h-80px bg-white bordered-1 border-light-primary-darker'>
                                          <div className="h-100 d-flex justify-content-center top-50 align-items-center text-center">
                                            <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-3x m-0'/>
                                          </div>
                                        </div> 
                                        <div className='d-flex justify-content-between m-1 mb-2'>
                                          <span className='d-flex fs-9 fw-semibold text-muted text-capitalize tooltip-bottom'
                                            data-tooltip-content={item.name}
                                          >
                                            <i className={`bi ${item.is_remote === 0 ? "bi-file-earmark-pdf":"bi-share-fill"} text-gray-400 me-2`}></i>
                                            {`${fileName.length <= limitFileNamelen ? fileName: fileName.substring(0, limitFileNamelen)+"..."}.${ext}`}
                                          </span>
                                          <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
                                        </div>
                                      </div>
                                      <ImageListItemBar
                                        position='top' className='rounded-bottom bg-transparent'
                                        actionIcon={<>
                                          {isSelected === true &&
                                            <span className='opacity-100 m-0 mt-1 ms-1 p-1 pb-0 position-absolute top-0 start-0 bagde bg-info rounded-circle'
                                              style={{borderBottomRightRadius:"5px"}}
                                            >
                                              <i className='bi bi-check text-light fw-bold fs-3 shadow-sm'/>
                                            </span>
                                          }                                           
                                        </>}
                                      /> 
                                    </ImageListItem>,
                                  // <DraggableImage 
                                  //                   key={`${ind}`} 
                                  //                   className="rounded"
                                  //                   item={item}
                                  //                   inUse={Object.keys(mediaIDInUse).includes(item.source_id)? 1: 0}
                                  //                   deletable = {currentFolder.readonly === 1 ? false : true}
                                  //                   isSelected = {selMediaSrcIds.includes(item.source_id)}
                                  //                   isDraggable = {!deletedSrcIds.includes(item.source_id) ? true: false}
                                  //                 />,
                                }[item.type]
                              }
                            </Fragment>
                          )
                        })
                      }
                    </ImageList>
                  }
                </div>
              </div>
            )
            })
          }
            
         </div>
        </div>
        <div className='h-100 w-100 mx-0'>
        {currentFolder.group_name !== "" && 
          <div className='d-flex justify-content-between pt-5 px-3'>
            <div>
              
            </div>
            <button className='btn btn-primary'
              data-bs-dismiss="modal"
              aria-label="Close"
              disabled={selMediaSrcIds.length <= 0}
              onClick={()=> addSelectedMediaFiles()}
            >
              Add to section
            </button>
          </div>
        }
        </div>
      </div>
      
  </div>
</div>
</>
)
}
