import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Content } from "../../content/contentModels";
import { PEXELS_API_KEY } from "./pexelsModels";



export const pexelsApi = createApi({

    reducerPath: "pexelsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.pexels.com/v1/',
        // baseUrl: 'https://cors-anywhere.herokuapp.com/https://api.pexels.com/v1',
        mode: 'no-cors',
        credentials: "same-origin", 
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${PEXELS_API_KEY}`);
            console.log("API_EXTERNAL_KEY: ", PEXELS_API_KEY)
            console.log("headers: ", headers)

            return headers;
        },

        // baseUrl: 'https://api.pexels.com/v1/',
        // // credentials: "same-origin", 
        // prepareHeaders: (headers) => {
        //     const accessToken = localStorage.getItem("token");
        //     if (accessToken) {
        //         headers.set("authorization", `Bearer ${accessToken}`);
        //         headers.set("Content-Type", "application/json");
        //     }

        //     return headers;
        // },
    }),
    tagTypes: ['Content'],

    // global configuration for the api
    //----------------------------------------
    // keepUnusedDataFor: 30,
    // refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,

    endpoints: (builder) => ({
        getImages: builder.query({
            // query: (searchTerm) => `search?query=${searchTerm}&per_page=10`,
            query: (searchTerm) => `search?query=${searchTerm}`,
            providesTags: ['Content'],
            // keepUnusedDataFor: 5,
        }),
        

    })
})

export const { 
    useGetImagesQuery,

} = pexelsApi