import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { resetPassword } from "../core/_requests";
import { Recaptcha } from "../../../commons/widgets/ReCaptcha";

const initialValues = {
    password: '',
    changepassword: ''
}

const activateSchema = Yup.object().shape({
password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
    is: (val: string) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    })
})
export function ResetPassword() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let token = searchParams.get('_req');
    
    
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState('');
    const formik = useFormik({
        initialValues,
        validationSchema: activateSchema,
        onSubmit: async (values, {setStatus, resetForm, setSubmitting}) => {
          setLoading(true)
          try {
            const {data: auth} = await resetPassword(
                token, values.password
            );
            if(auth.code == 200)
            {
            setResult("Your password has been reset.")
            resetForm()
            }else{
            setStatus(auth.message)
            setSubmitting(false)
            }
          }catch (error) {
            setStatus('Unable to reset account password.')
            setSubmitting(false)
            setLoading(false)
          }
        },
    });
    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Reset account password</h1>
        
        {/* end::Title */}

      </div>
      {/* end::Heading */}

            {result != "" ? (
              <div className='mb-lg-15 alert alert-success'>
                <div className='alert-text font-weight-bold'><strong>{result}</strong></div><br />
                
                <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_login_button'
                  className='btn btn-gradient-primary'
                >
                  Login
                </button>
            
              </Link>
              </div>
            ) : (
              <>
              <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
            <div className='text-center'>
                <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
                >
                {!loading && <span className='indicator-label'>Activate</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>
            </div>
              </>
            )} 

            {/* begin::Form group Password */}
          <Recaptcha pageName="resetPassword" />
        </form>
    );
}