import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { Account, apiResponse, subAccount } from "./accountModel";
import { ChangePassword } from "../staff/staffModels";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL

export  const auth = getAuthModel();

export const accountApi = createApi({
    reducerPath: "accountApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),

    tagTypes: ['Account'],
    refetchOnMountOrArgChange: 30,

    endpoints: (builder) => ({
        getAccount : builder.query<Account, void>({
            query: () => `account`,
            providesTags: ['Account'],
            keepUnusedDataFor: 5,              
        }),

        updateAccount: builder.mutation<any, Account>({
            query: ({id, ...rest}) => ({
                url: `account/${id}`,
                method: "PATCH",
                body: rest
            }),
            invalidatesTags: ['Account']
        }),

        createSubAccount: builder.mutation<any, subAccount>({
            query: (data) => ({
                url: `account/sub/create`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['Account']
        }),
        
        getSubAccounts : builder.query<subAccount[], void>({
            query: () => `account/sub/list`,
            providesTags: ['Account'],
            keepUnusedDataFor: 5,              
        }),
    })
})

export const {
    useGetAccountQuery,
    useUpdateAccountMutation,
    useGetSubAccountsQuery,
    useCreateSubAccountMutation,
} = accountApi