import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useAuth} from '../core/Auth'
import {useFormik} from 'formik'
import { getSubUsers, getUserByToken, requestAccess, requestPasscode, setAccess } from '../core/_requests'
import { getAuthModel } from '../../../redux/auth'
import { Link, useNavigate } from "react-router-dom";
import clsx from 'clsx'
import Swal from 'sweetalert2'
import { getPinCode } from '../core/AuthHelpers'
import { Recaptcha } from '../../../commons/widgets/ReCaptcha'
import { useAppDispatch } from '../../../redux/hook'
import { emptyCart } from '../../../redux/slices/shopping/shoppingSlice'

type Props = {  
    className?: string
}

const initialValues = {
    passcode: ''
  }

const PasscodeSchema = Yup.object().shape({
    passcode: Yup.string().trim()
    //.email('Wrong email format')
    .length(6, 'Invalid passcode.')
    .required('Passcode is required')
});

export function Passcode() {
    const dispatch = useAppDispatch()
    // -----------------------------------------
    const navigate = useNavigate();   
    const {saveMaster, saveAuth, setCurrentUser} = useAuth()
    const tmpAuth = getAuthModel()
    const _token = tmpAuth?tmpAuth.access_token:'';
    const _mfa = tmpAuth?tmpAuth.mfa:0;
    useEffect(() => {
      if(_token === "" || _token === null)
      {
        navigate(`/auth`);
      }
    })

    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false)
    const [requestResult, setRequestResult] = useState('')

    // ---------------------------------
    const resendPasscode = async () => {
      try{
        const {data: auth} = await requestPasscode(_token);
        saveAuth(auth)  
        setResult('')
        setRequestResult('A new passcode has been sent to your email.')
        

      }catch(error)
      {
        setResult('Unable to send new passcode to your email.')
        //setRequestResult('Unable to send new passcode to your email.')
      }
      
    }

    // -------------------------------
    const formik = useFormik({
      initialValues,
      validationSchema: PasscodeSchema,
      onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        try {
            const {data: auth} = await requestAccess(tmpAuth?tmpAuth.access_token:'', values.passcode)
            if(auth.token_type==="ready"){
              const {data: auth} = await setAccess(tmpAuth?tmpAuth.access_token:'')
              saveAuth(auth)
              if(auth.account_type === "master")
              {
                const {data: master } = await getSubUsers(auth.access_token)
                if(master.code === 200)
                {
                  saveMaster(master.data)
                }
              }
            }
                
            const {data: user} = await getUserByToken(auth.access_token)        
            setCurrentUser(user)
            
            dispatch(emptyCart())

            if (getPinCode()){
              // console.log("getPinCode()", getPinCode())
              navigate(`/group`);
            }

        }catch (error) {
            
            setResult('The passcode is not correct.')
            setSubmitting(false)
            setLoading(false)
          }
      }
    })

    // --------------------------------
    return (
      
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Access Passcode</h1>
            {/* end::Title */}
            {/* begin::Link */}
            <div className='text-gray-500 fw-semibold fs-6'>
              {/* Enter the passcode to access. */}
              {_mfa !=1 ?
                <span>A passcode has been sent to your email address.<br/>              
                Please enter the passcode below to complete sign in. </span>    
                :
                <span>
                  Please check your authenticator app and enter passcode below.
                </span>         
              }
            </div>
            {/* end::Link */}
          </div>
          { result != ""  ? (
                              <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'><strong>{result}</strong></div>
                              </div>
                            ) 
                          : requestResult ? (
                                  <div className='mb-lg-15 alert alert-success'>
                                    <div className='alert-text font-weight-bold'><strong>{requestResult}</strong></div>
                                  </div>            
                              )
                              : (
                                  <>
                                  </>
                                )
          } 
    
          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Passcode</label>
            <input
              type='text'
              placeholder='Enter your passcode'
              autoComplete='off'
              {...formik.getFieldProps('passcode')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.passcode && formik.errors.passcode},
                {
                  'is-valid': formik.touched.passcode && !formik.errors.passcode,
                }
              )}
            />
            {formik.touched.passcode && formik.errors.passcode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.passcode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
    
          {/* begin::Form group */}
          <div className='d-grid mb-10'>

          {/* <div className='d-flex flex-wrap justify-content-center pb-lg-0'> */}
            <button type='submit' id='kt_password_reset_submit' className='btn btn-gradient-primary w-100 my-5'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>  
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_password_reset_form_cancel_button'
                className='btn btn-lg btn-secondary w-100 mb-5'
              >
                Cancel
              </button>
            </Link>                      
          </div>
          

          {/* <div className='text-gray-500 fw-semibold fs-6 mt-8'> */}
          {_mfa == 1 ?
          <div className='text-gray-500 text-center fw-semibold fs-6'>
          I can't access my authentication app.<br />
          <a href="#" className='link-primary' onClick={()=>{ resendPasscode() }}>Click here to send passcode to your email.</a>
        </div>
          :
          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Didn't receive the passcode?{' '}
            <a href="#" className='link-primary' onClick={()=>{ resendPasscode() }}>Click here to resend.</a>
          </div>
          }

          {/* end::Form group */}
          <Recaptcha pageName='requestPasscode' />
        </form>
      )
}