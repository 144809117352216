/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
// import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { Box, TextField } from '@mui/material';
import { setContentIDInUse, setContentName } from '../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { useGetAllContentsQuery, useUpdateContentMutation } from '../../../../../redux/slices/content/apiContentSlice';
import { AssociativeArray, Content, ContentInput, secondsToHHmmss } from '../../../../../redux/slices/content/contentModels';
import { extractContentInUse } from '../../../../../redux/slices/schedule/scheduleModels';

//----------------------------------------

type Props = {
  className?: string,
}

//----------------------------------------
const AccordionContent: React.FC<Props> = ({className}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const [updateContent] = useUpdateContentMutation();  
  
  const { data: allContents, isLoading: isLoadingContents, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
  const [existContentNames, setExistContentNames] = useState<string[]>([])

  const [contName, setContName] = useState("")
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)

  const contentIDInUse : AssociativeArray[] = useAppSelector(state => state.persistor._content.contentIDInUse) // for just pure a list of all sched
  const status =  currPersistContent.user_template_id && Object.keys(contentIDInUse).includes(currPersistContent.user_template_id)? "active":"inactive"
  
  // ---------------------------------------
  const renameContentHandler = async () => {

    const inputValue = currPersistContent.user_template_name

    Swal.fire({
      title: 'Campaign Name',
      input: 'text',
      inputValue,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#9C258F',
      confirmButtonText: 'Rename',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {
        if (!name) {
          Swal.showValidationMessage('You need to write something!')
        }else{
          if (existContentNames.includes(name.toLowerCase()) && (currPersistContent.user_template_name && (name.toLowerCase() !== currPersistContent.user_template_name.toLowerCase()))){
            Swal.showValidationMessage('This name already exist!')
          }
        } 
      return name        
    },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        
        // currPersistContent.user_template_name = result.value
        setContName(result.value)
        dispatch(setContentName(result.value))

        // -------------------------------
        var eleList:any[] = []
        currPersistContent.elements?.map((section, index) => {
          eleList.push({
            element_id  : section.element_id,
            position_id : section.position_id,
            alias       : section.alias,
            element_setting : JSON.stringify(section.element_setting),
            type            : section.element_type,
            source_ids      : JSON.stringify(section.sources),
          })
        })

        const content: ContentInput = {
          contentId:  currPersistContent? (currPersistContent.user_template_id? currPersistContent.user_template_id:""):"",
          user_template:{
            user_template_name  : result.value,
            template_id         : currPersistContent? (currPersistContent.template_id? currPersistContent.template_id:""):"",
            duration            : currPersistContent.duration,          
          },
          elements: eleList
        }         
        updateContent(content)

        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-right',
          iconColor: 'green',
          customClass: {
            popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 2000,
        })
        Toast.fire({
            icon: 'success',
            title: `Campaign <span class='text-primary text-capitalize'>${currPersistContent.user_template_name}</span><br/> is successfully renamed to <span class='text-primary text-capitalize'>${result.value}</span>.`,
        })
        
      }
    })        
  }


  // ---------------------------------------
  useEffect(() => {
    setContName(currPersistContent.user_template_name)
  }, [currPersistContent])

  // ----------------------------------------------
  useEffect(()=>{
    setExistContentNames(allContents?allContents.map((cont: Content)=> cont.user_template_name.toLowerCase()):[])
  },[allContents])
  
  //-----------------------------------------
  return (    
    <Accordion 
      // TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={true}
      square={true}
      disableGutters={true}          
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className='p-3'  
        style={{backgroundColor:"#1e1e2d88"}}        
      >
        <Typography>
          <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-3 text-white me-2'/>
          <span className='text-white fw-semibold'>Description</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className='m-0 p-3 mh-500px overflow-auto'      
      >        
        <div className='d-flex justify-content-between'>
          <div className='text-muted'>Name: </div>
          <div className='d-flex justify-content-between text-capitalize'>
          {(currPersistContent && currPersistContent.user_template_name !== "")          
              ? <>
                  <span className='text-truncate w-125px text-end'>{currPersistContent.user_template_name}</span>
                  <a className='ms-2 tooltip-bottom' data-tooltip-content='Rename'
                    onClick={()=> renameContentHandler() }
                  ><i className='fa fa-edit'></i>
                  </a>
                </>
              : <span className='text-muted'>Untitled</span>
          }    
          </div>
        </div>
        <div className='d-flex justify-content-between text-capitalize'>
          <span className='text-muted'>Status: </span>
          {status === "active" &&
            <span className="fw-bold text-success">{status}</span>
          }
          {status === "inactive" &&
            <span className="text-muted">{status}</span>
          }
          
        </div>        
        <div className='d-flex justify-content-between'>
          <span className='text-muted'>Orientation:</span>
          <span>{currPersistContent.width >= currPersistContent.height? "Landscape":"Portrait"}</span> 
        </div>
        {/*
        <div className='d-flex justify-content-between'>          
          <span className='text-muted'>Duration: </span>
          <span>{secondsToHHmmss(currPersistContent.duration)}</span>          
        </div>
      */}
          
        
      </AccordionDetails>
    </Accordion>
  )
}

export {AccordionContent}