
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback, useEffect, useState } from 'react'
import { Box, Slider } from '@mui/material';

type Props = {
className?: string
pageName?: string
}
  
const Recaptcha: React.FC<Props> = ({className, pageName}) => {    
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isCaptcha, setIsCapcha] = useState(true);
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
        
        const token = await executeRecaptcha(pageName);
        
        if(token)
        {
            setIsCapcha(true)
        }
        
    }, [executeRecaptcha]);
    
    const handleChange = (event: any, newValue: any) => {
        if(newValue == 100)
        {
            handleReCaptchaVerify()
        }
      };
    useEffect(() => {
    handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
    return(
        <>
            {
                !isCaptcha ?  
                <Box sx={{ width: 320 }}>
                    <Slider slotProps={{ rail: { className: 'my-rail' } }} defaultValue={10} max={100} onChange={handleChange} />
                
                </Box>
                : 
                <div>
                    <GoogleReCaptcha
                        onVerify={handleReCaptchaVerify}
                        refreshReCaptcha={false}
                    />
                </div>
            }
        </>
        
    );
}
  
export  {Recaptcha}