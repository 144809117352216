import {FC} from 'react'
import { useAppDispatch } from '../../redux/hook'
import { resetContentLandscape } from '../../redux/slices/content/contentSlice';
import { Props } from '../../redux/slices/content/contentModels';


const ContentHeader: FC<Props> = ({className}) => {

    const dispatch = useAppDispatch()   
    // --------------------------------------------------

    // --------------------------------------------------
    return (
      <div className={`${className} d-flex`}>
            <div className="w-100"> 
                <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
                  <a href="#">
                    <button 
                        className={`btn text-hover-primary`}                        
                        onClick={() => { 
                          dispatch(resetContentLandscape());  
                          setTimeout(() => {
                            window.location.href = "/editor"
                          }, 1000);                   
                        }}
                    >
                        <i className='bi bi-plus-circle text-gray-900 text-hover-primary fs-5 mt-n1 me-1'></i>
                        <span className='fs-6'>Create new campaign</span>
                    </button>
                  </a>
                </div>            
            </div>
        </div>
  )
}

export {ContentHeader}
