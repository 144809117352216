import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { staffMFA } from "./staffModels";

const API_URL: string | undefined = process.env.REACT_APP_API3_URL

export const staffApi3 = createApi({
    reducerPath: "staffApi3",
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_URL}`,
        prepareHeaders: (headers, { getState }) => {
            const auth = getAuthModel()
            headers.set('authorization', `Bearer ${auth?.access_token}`)
            return headers
        }
    }),
    refetchOnMountOrArgChange: 30,
    endpoints: (builder) => ({

        updateMFAStaff: builder.mutation<any, any>({
            query: ({id, ...rest}) => ({
                url: `staff/${id}/updateMfa`,
                method: "PATCH",
                body: rest
            })
        }),
        validateMFAStaff: builder.mutation<any, any>({
            query: ({ id, ...rest }) => ({
                url: `staff/${id}/validateMfa`,
                method: "PATCH",
                body: rest
            })
        }),
    })
});

export const {
    useUpdateMFAStaffMutation,
    useValidateMFAStaffMutation,
} = staffApi3