/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../../../redux/hook";

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { setContentName } from '../../../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { useUpdateContentMutation } from '../../../../../../../redux/slices/content/apiContentSlice';
import { ContentInput, secondsToHHmmss } from '../../../../../../../redux/slices/content/contentModels';

import { Folder } from '../../../../../../../redux/slices/media/mediaModels';
import { useAddFolderMutation, useGetAllMediasQuery, useGetAllRootFoldersQuery } from '../../../../../../../redux/slices/media/apiMediaSlice';
import { 
    FormControl,
    // Checkbox, 
    // InputLabel, MenuItem, 
    // Select,
    TextField, 
    FormHelperText
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
// import { DropdownNewFolder } from '../../../EditorComponents';
//----------------------------------------
  
  type Props = {
    className?: string,
    title?: string,
    noIcon?: boolean
  }
  
  const schema = yup.object().shape({
      group_name: yup.string()
          .min(1, 'Minimum 1 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Folder name is required'),
  })


//----------------------------------------
export const BtnNewFolderModal: React.FC<Props> = ({className="btn-icon btn-sm btn-active-color-primary", title, noIcon=false}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const [addFolder] = useAddFolderMutation();

  const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("rootFolders")  
  // console.log(rootFolders)
  
  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder::",currentFolder)
  


  // const { data: allMedias, isLoading: isLoadingAllMedias, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allMedias")  
  // console.log(allMedias)

  const { 
      register, 
      handleSubmit,
      // control,
      reset,
      watch,
      setError,
      formState:{
          errors,
          isDirty,
          
          isLoading,         
          isValid,   
          isSubmitting,
          isSubmitSuccessful,
          
      }
  } = useForm<Folder>({
      resolver: yupResolver(schema),
      defaultValues:{            
          group_name  : "",            
      }
  }) 

  // console.log('watch', watch(['group_name']))
  // console.log('isValid', isValid)
  // console.log('isSubmitting', isSubmitting)


  // --------------------------------------------
  const formSubmitHandler: SubmitHandler<Folder> = async (folder: Folder) => {        
      try{
          const result = await addFolder(folder).unwrap();
          
          reset()

          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
            popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,            
          })
          await Toast.fire({
              icon: 'success',
              title: "New folder has been successfully created.",
          }) 

      }catch(error){
          Swal.fire(
              'Folder: '+ folder.group_name,
              'There is something wrong during the creation!',
              'error'
          )            
          // setError("name",{
          //     type: "custom",
          //     message: "This is sth wrong with name from server"
          // })
      }
  }  
  
  
  //-----------------------------------------
  return (<>       
      <div className={`btn-group btn-group-sm me-2`} role="group" >
        <button
          className={`btn tooltip-bottom ${className} text-hover-info`}
          data-tooltip-content='New Folder'
          data-kt-menu-trigger="{default: 'click'}"
          // data-kt-menu-attach='parent'
          // data-kt-menu-placement='bottom-start'
          // data-kt-menu-flip='top-end'
          
          // // data-bs-dismiss="offcanvas"
          // // data-kt-drawer-dismiss="true"

          disabled={currentFolder.source_group_id !== ""? true:false}
          
          // data-bs-toggle="offcanvas"
          // aria-controls="staticBackdrop"
          // data-bs-target="#kt_drawer_edit_folder"
          // id="kt_drawer_edit_folder_button"
          // data-kt-drawer-show="true" 

          data-bs-toggle='modal'
          data-bs-target='#kt_modal_new_folder'
        >
          
          {/* {title 
            ? <>
                <i className='bi bi-folder-plus text-primary fs-4 me-1'></i>
                <span className='fs-6 text-light'>{title}</span>
              </>
            : <i className="bi bi-folder-plus fs-3 text-light"></i>
          } */}

          {!noIcon && <i className="bi bi-folder-plus fs-3 text-light me-2"></i> }
          {title && <span className='fs-5 text-light text-hover-info text-nowrap'>{title}</span> }
          
        </button>        
        
      </div>
  </>)
}
