/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
// import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionContent, AccordionHideShowBtn, AccordionLayerAnimation, AccordionLayerStyle, AccordionSections } from '../EditorComponents';
//----------------------------------------

type Props = {
  className: string,
}

//----------------------------------------
const PanelRight: React.FC<Props> = ({className}) => {
  
  // const dispatch = useAppDispatch()  
  //-----------------------------------------
  // const { data: allMedias, isLoading, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allMedias")  
  // console.log(allMedias)


  //-----------------------------------------
  return (
      <div 
        className={`${className} overflow-auto`}
        // style={{maxHeight: "899px"}}
      >
        {/* <AccordionLayerStyle /> */}
        {/* <AccordionLayerAnimation /> */}
        
        <AccordionContent />
        <AccordionSections />
        
      </div>
  )
}

export {PanelRight}