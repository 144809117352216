import React, { useState } from "react";
import { Task } from "gantt-task-react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import moment from "moment";
import { getContentIconPath } from "../../../../redux/slices/content/contentModels";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { setCurrScheduleId } from "../../../../redux/slices/schedule/scheduleSlice";
import { useGetAllGroupsQuery, useGetGroupsByScheduleModeQuery } from "../../../../redux/slices/group/apiGroupSlice";
import { filterOnlineDevices, isDeviceOnline } from "../../../../redux/slices/device/deviceModels";
import { DropdownNewFolder } from "../../../editor/components/EditorComponents";
import { HeaderUserMenu } from "../../../../../_metronic/partials/layout/header-menus/HeaderUserMenu";
import clsx from "clsx";
import { Translate } from "@mui/icons-material";
import { useGetAllDevicesQuery } from "../../../../redux/slices/device/apiDeviceSlice";


type TaskListTableProps = {
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: Task[];
    selectedTaskId: string;
    currActiveSchedIds: string[],
    setSelectedTask: (taskId: string) => void;
    onExpanderClick: (task: Task) => void;
    handleAddTask: (task: Task) => void;
    handleDeleteTask: (task: Task) => void;
    handleUpdateTask: (task: Task) => void;
    handleClearAllTasks: (task: Task) => void;
    handleViewGroup: (task: Task) => void;
    handleMoveUp: (task: Task) => void;
    handleMoveDown: (task: Task) => void;
}
  
export const TaskListTable = ({
    tasks,
    rowWidth,
    rowHeight,
    fontFamily,
    currActiveSchedIds,
    onExpanderClick,
    handleAddTask,
    handleDeleteTask,
    handleUpdateTask,
    handleClearAllTasks,
    handleViewGroup,
    handleMoveUp,
    handleMoveDown,
  }: TaskListTableProps) => {
    // -------------------------------------------
    const dispatch = useAppDispatch() 
    const showNbOfDevices = useAppSelector(state => state.persistor._schedule.showNumberOfDevices)
    const showWarningMsgs = useAppSelector(state => state.persistor._schedule.showWarningMsg)
    const enableClearAll  = useAppSelector(state => state.persistor._schedule.enableClearAll)
    

    
    const { data: deviceGrps, isLoading: isLoadingDevGrps, isFetching: isFetchingDevGrps, isSuccess, isError } = useGetAllGroupsQuery(5, { refetchOnMountOrArgChange: true }) 
    // console.log("deviceGrps",deviceGrps)
    
    const { data: allDevices, isLoading: isLoadingDevice, isFetching: isFetchingDevice, isError: isErrorDevice, isSuccess: isSuccessDevice } = useGetAllDevicesQuery(5, { refetchOnMountOrArgChange: true })
    // console.log("allDevices", allDevices)

    const allOnlineDeviceIds = filterOnlineDevices(allDevices).map((dev)=> dev.id)
    // console.log("allOnlineDeviceIds", allOnlineDeviceIds)

    const { data: allSchedGrps, isLoading:isLoadingSchedGrps, isFetching: isFetchingSchedGrps} = useGetGroupsByScheduleModeQuery(1, { refetchOnMountOrArgChange: true })
    // console.log("allSchedGrps:", allSchedGrps)
    const scheduledGrps = allSchedGrps?.map(grp => grp.id)
    // console.log("scheduledGrps:", scheduledGrps)

    var isActiveAvailable = false    //init
    // -------------------------------------------
    return (
        <div 
            className="task-list-table min-h-300px"
            style={{ 
                // border: "1px solid #dfe1e5", 
                // border: "1px solid #E9E1F6", 
                // width:"auto", 
                // width:"225px", 
                // minWidth: 300,
                // borderBottomLeftRadius: "10px"
            }}
        >
            {tasks.map((item, i) => {
                // console.log("item: ", item)

                const isGroup = item.type === "project";
                const isPlaylist = (item.name.substring(0,1).toLowerCase() === ".")? true: false
                const isCronTask = (item.name.substring(0,1).toLowerCase() === "*") || (item.name.substring(0,2).toLowerCase() === ".*") ? true: false

                const itemName   = isPlaylist === true  
                                                    ? isCronTask === true   ? item.name.substring(2,) 
                                                                            : item.name.substring(1,)
                                                    : isCronTask === true   ? item.name.substring(1,) 
                                                                            : item.name
                                                                            
                isActiveAvailable =  ((isActiveAvailable === true) || (isGroup === true) ? true : false )

                const isExpired = (!isGroup) && (moment().diff(moment(item.end),'seconds') > 0)
                const isComing  = (!isGroup) && (moment().diff(moment(item.start),'seconds') < 0)
                // const isActive  = (!isGroup) && 
                //                   (!isExpired) && (!isComing) && (isActiveAvailable)
                const isActive  = (!isGroup) && (currActiveSchedIds.includes(item.id))
                const textClrClass =  (isActive ? "fw-bold text-success": "fw-semibold text-muted")
                const bgClrClass   =  (isActive ? "bg-success": "")
                
                if (isActive) {isActiveAvailable = false}   //only the first schedule is active
                
                const grpTasks = tasks.filter(t => t.type==="task" && t.project === item.id)

                const grp = deviceGrps?.filter((grp) => {return grp.id === item.id})                
                const onlineDevicesInGrp = grp && grp.length>0 && grp[0].devices
                                            ?   grp[0].devices.filter((dev)=>{                                                    
                                                    return allOnlineDeviceIds.includes(dev.id)
                                                })
                                            :   []
                const offlineDevicesInGrp = grp && grp.length>0 && grp[0].devices
                                            ?   grp[0].devices.filter((dev)=>{                                                    
                                                    return !allOnlineDeviceIds.includes(dev.id)
                                                })
                                            :   []
                

                // console.log(i, isGroup, isExpired, isComing, grp, isActive)
                

                return (
                    <div
                        key={`task-${item.id}`}
                        className={`                            
                            task-list-table
                            ${isGroup ?"fw-bold bg-secondary":"bg-hover-light-warning"}                             
                        `}
                        style={{
                            // borderLeft: isGroup ? "2px solid #9C258F88": "unset",                            
                            //height: isGroup ? rowHeight: 25,
                            height: rowHeight,
                            // width: rowWidth,
                            // width: 250,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: isGroup ? "pointer" : "auto",
                            fontFamily: fontFamily,
                            background: i % 2 === 0 ? "unset" : "#f4f5f622",
                            padding: 5,
                            // paddingLeft: isGroup ? 15 : 38
                            paddingLeft: isGroup ? 10 : 28
                        }}
                    >
                        <div
                            id='kt_drawer_view_schedule_toggle' //to open an activity drawer

                            onClick={isGroup
                                ? () => {
                                            // console.log('expander')
                                            onExpanderClick(item)
                                        }  
                                : () => {
                                            // console.log('setCurrSchedId')
                                            dispatch(setCurrScheduleId(item.id))
                                        }
                            }                            
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: 0
                            }}                            
                        >
                            {isGroup 
                                ?   <KTSVG path='/media/icons/duotune/files/fil012.svg' className='svg-icon-3 me-2 opacity-50 text-gray-800' /> 
                                :   isComing    ?   <KTSVG path='/media/icons/duotune/general/gen013.svg' className={`svg-icon-4 me-2 text-muted`} /> 
                                                :   isExpired   ?   <i className="fa fa-check me-3 opacity-75"></i>    //<KTSVG path='/media/icons/duotune/arrows/arr012.svg' className={`svg-icon-6 me-2`}/> 
                                                                :   <div>                                                                        
                                                                        <div className='symbol symbol-25px me-2'>
                                                                            {!isPlaylist &&
                                                                                <KTSVG path={getContentIconPath()} className={`svg-icon-5 ${textClrClass}`}/>
                                                                            }
                                                                            {isPlaylist &&
                                                                                <KTSVG path={`/media/icons/duotune/text/txt009.svg`} className={`svg-icon-5 ${textClrClass}`}/>
                                                                            }
                                                                            {isActive && <span className={`bullet bullet-dot ${bgClrClass} opacity-75 h-5px w-5px position-absolute translate-middle top-0 start-50 animation-blink`}></span>}
                                                                        </div>
                                                                        <div className='symbol symbol-25px me-2 tooltip-bottom'
                                                                            data-tooltip-content="Recurring schedule"
                                                                        >
                                                                            <i className={`bi bi-${isCronTask? "arrow-repeat":"calendar2"} ${textClrClass}`}></i>
                                                                        </div>
                                                                    </div>
                            }
                            
                            <a  href="#"
                                className={`text-normal text-truncate text-capitalize w-125px
                                    ${  isGroup ?   "fw-bold opacity-75 text-gray-800 " 
                                                :   isExpired   ? "text-muted opacity-75"
                                                                : isComing  ? "text-muted fw-semibold opacity-75"
                                                                            : `${textClrClass} `
                                    }                                  
                                `}
                                title={itemName}                                
                            >
                                {itemName} 
                            </a>                            
                        </div>
                        <div
                            className="d-flex justify-content-end"
                        >
                            {   showWarningMsgs && isExpired && (
                                <div>
                                    <div className=" badge badge-dark bg-gray-500 opacity-50 fw-normal fs-9 px-2 mt-2 me-1 tooltip-bottom"
                                        data-tooltip-content={`Expired since ${moment(item.end).format("DD-MMM-YYYY HH:mm")}`}
                                    >
                                        Expired
                                    </div>
                                </div>
                            )}

                            {/* ---Task--------------------- */}
                            {!isGroup && (                                
                                <div className="btn-group btn-group-sm" role="group">
                                    <button type="button" className="btn btn-link text-end text-hover-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-gear-wide-connected tooltip-bottom"></i>
                                    </button>
                                    
                                    <ul className="dropdown-menu w-50px p-0 shadow-sm mt-n4 mb-n4" 
                                    style={{zIndex: "3050"}}
                                    >
                                        <div className='px-5 py-3'>   
                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                data-tooltip-content="View schedule"
                                                id='kt_drawer_view_schedule_toggle'                                                
                                                onClick={() => dispatch(setCurrScheduleId(item.id))}
                                            >
                                                {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-6 me-2' /> */}
                                                <i className="fa fa-eye "></i>
                                                <span>View</span>
                                            </div>

                                            {/* <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                        data-tooltip-content="View the schedule details of the group"
                                                        id='kt_drawer_view_group_schedule_toggle'
                                                        onClick={() => dispatch(setCurrScheduleId(item.id))}
                                                    >
                                                        <i className="fa fa-caret-right me-2"></i>
                                                        <span>View details</span>
                                                    </div> */}
                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                data-tooltip-content="Edit schedule"
                                                id='kt_drawer_edit_schedule_toggle'                                                
                                                onClick={() => handleUpdateTask(item)}
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-6 me-2' />
                                                <span>Edit</span>
                                            </div>
                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                data-tooltip-content="Delete schedule"
                                                id='kt_drawer_delete_schedule_toggle'                                                
                                                onClick={() => handleDeleteTask(item)}
                                            >
                                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-5 me-2' />  
                                                <span>Delete</span>
                                            </div>

                                            {/* <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                // data-tooltip-content="Delete schedule"
                                                // id='kt_drawer_delete_schedule_toggle'                                                
                                                onClick={() => handleMoveUp(item)}
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className='svg-icon-5 me-2' />  
                                                <span>Move up</span>
                                            </div>
                                            <div className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                // data-tooltip-content="Delete schedule"
                                                // id='kt_drawer_delete_schedule_toggle'                                                
                                                onClick={() => handleMoveDown(item)}
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className='svg-icon-5 me-2' />  
                                                <span>Move down</span>
                                            </div> */}
                                        </div>
                                    </ul>
                                </div>
                            )}

                            {/* ---Group--------------------- */}
                            {isGroup && 
                                <div className="d-flex me-n2">
                                    <div className="small ">   
                                        { showWarningMsgs && (grp && grp.length>0 && !(scheduledGrps?.includes(item.id)) ) && 
                                            <span className="badge badge-light-warning text-warning me-2 tooltip-bottom"
                                                data-tooltip-content="No Schedule"
                                            >                                            
                                                <KTSVG path={`/media/icons/duotune/text/txt001.svg`} className="svg-icon-1x text-warning ms-1 me-1"/>
                                                0
                                            </span> 
                                        }
                                        { showWarningMsgs && (grp && grp.length>0 && (grp[0].devices? grp[0].devices.length : 0) <=0 ) && 
                                            <span className="badge badge-light-warning me-2 tooltip-bottom"
                                                data-tooltip-content="No Device"
                                            >                                            
                                                <KTSVG path={`/media/icons/duotune/electronics/${grp[0].displayType==="landscape"? "elc011":"elc003"}.svg`} className="svg-icon-1x svg-icon-warning ms-1 me-1"/>
                                                0
                                            </span> 
                                        }                                                                        
                                        { showNbOfDevices && (grp && grp.length>0) && (grp[0].devices && grp[0].devices.length>0) && 
                                            <div className="badge badge-light me-2">
                                                {onlineDevicesInGrp.length > 0 &&
                                                    <span className="tooltip-bottom text-info"
                                                        data-tooltip-content={`${onlineDevicesInGrp.length} online devices`}
                                                    >
                                                        <KTSVG path={`/media/icons/duotune/electronics/${grp[0].displayType==="landscape"? "elc011":"elc003"}.svg`} className="svg-icon-1x text-info me-1"/>
                                                        {onlineDevicesInGrp.length}
                                                    </span>
                                                }
                                                {offlineDevicesInGrp.length > 0 &&
                                                    <span className="tooltip-bottom ms-1"
                                                        data-tooltip-content={`${offlineDevicesInGrp.length} offline devices`}
                                                    >
                                                        <KTSVG path={`/media/icons/duotune/electronics/${grp[0].displayType==="landscape"? "elc011":"elc003"}.svg`} className="svg-icon-1x text-gray me-1"/>
                                                        {offlineDevicesInGrp.length}
                                                    </span>
                                                }
                                            </div>
                                        }                                        
                                        
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-link text-end text-hover-primary py-1 ms-0" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-caret-right-fill tooltip-bottom ps-2 pe-2"></i>
                                            </button>
                                            
                                            <ul className="dropdown-menu w-150px p-0 shadow-sm mt-n1 mb-n4" >
                                                <div className='px-5 py-3'>   
                                                    <div 
                                                        className="btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom"
                                                        data-tooltip-content="View the schedule details of the group"
                                                        id='kt_drawer_view_group_schedule_toggle'
                                                        // onClick={() => {
                                                        //     dispatch(setCurrScheduleId(item.id))
                                                        // }}
                                                        onClick={() => handleViewGroup(item)}
                                                    >
                                                        <i className="fa fa-caret-right me-2"></i>
                                                        <span>View details</span>
                                                    </div>
                                                    {/* { grpTasks.length > 0 &&
                                                        <div className= {`btn btn-sm btn-link d-flex justify-content-start align-items-center text-hover-primary fw-normal p-0 mb-2 tooltip-bottom
                                                            ${!enableClearAll ? "disabled":""} `}
                                                            data-tooltip-content="Clear all schedules in the group"
                                                            onClick={() => {
                                                                if (enableClearAll && grpTasks.length > 0){
                                                                    handleClearAllTasks(item)
                                                                }
                                                            }}                                                            
                                                        >
                                                            <i className="fa fa-caret-right me-2"></i>
                                                            <span>Clear schedules</span>
                                                        </div>
                                                    } */}
                                                </div>
                                            </ul>
                                        </div>
                                    </div>                                                                    
                                </div>
                            }
                        </div>                        
                    </div>                    

                );
            })}
        </div>
    )
}