/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { createClient } from 'pexels';
import { PEXELS_API_KEY } from '../../../../../../../redux/slices/application/pexels/pexelsModels';
import { ImageList, ImageListItem, InputAdornment, TextField } from '@mui/material';
import { Props } from '../../../../../../../redux/slices/content/contentModels';

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2';
// import Configuration, { OpenAI } from "openai";
import OpenAI from "openai";
import axios from 'axios';
import { getResponse } from './openaiService';
//----------------------------------------

type Query = {
  query: string
}

const schema = yup.object().shape({
  query: yup.string().trim().required().min(1).max(100),    
})

// const OPENAI_API_KEY : string  = process.env.REACT_APP_OPENAI_API_KEY 
//                                                         ? process.env.REACT_APP_OPENAI_API_KEY
//                                                         : "UsCTfaaf6qJQVU0fnGh9ORDku3rFtxZqSURvhA4lFqQyszqg7ie57INN"
// const OPENAI_API_KEY : string = "sk-ftF_zy06RJsEs-C2RwqQsd6As2CzF-sgDDsOJPbJoST3BlbkFJ_OsSmI-YHqRwv26qjPehgwt_0AAZM-36KOB-h3X2MA"
const OPENAI_API_KEY : string = "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"

//----------------------------------------
export const SubTabAI: React.FC<Props> = ({className}) => {
  // const configuration = new Configuration({
  //   apiKey: OPENAI_API_KEY
  // })
  // const openai = new OpenAI();
  // const openai = new OpenAI({apiKey: OPENAI_API_KEY, dangerouslyAllowBrowser: true});
  

  // const client = createClient(PEXELS_API_KEY);
  // // console.log("pexels client : ", client)
  const [results, setResults] = useState<any[]>([]);
  

  const openai = new OpenAI({
    apiKey: OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true
  });
  
  // const openaiReq = async () => {

  //   // const completion = await openai.chat.completions.create({
  //   //   messages: [{ role: "system", content: "You are a helpful assistant." }],
  //   //   model: "gpt-3.5-turbo",
  //   // });  
  //   // console.log(completion.choices[0]);

  //   const response = await openai.images.generate({
  //     // prompt: "a white siamese cat",
  //     prompt: input,

  //     // model: "dall-e-3",
  //     // n: 1,            
  //     model: "dall-e-2",
  //     n: 5, 
      
  //     // size: "1792x1024",
  //     size: "1024x1024",
  //     // size: "1024x1792",      
  //   });
  //   console.log("response:", response)

  //   setResults(response.data)
  // }


  const [input, setInput] = useState('');
  // const [response, setResponse] = useState<string[]>([]);
  console.log("input ai : ", input)
  console.log("results ai : ", results)


  // --------------------------------------------
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const aiResponse = await getResponse(input);
    // setResponse(aiResponse);

    const response = await openai.images.generate({
      // prompt: "a white siamese cat",
      prompt: input,

      // model: "dall-e-3",
      // n: 1,            
      model: "dall-e-2",
      n: 5, 
      
      // size: "1792x1024",
      size: "1024x1024",
      // size: "1024x1792",      
    });
    console.log("response:", response)

    setResults(response.data)
    // const image_url = response.data[0].url;
    // const image_url = https://oaidalleapiprodscus.blob.core.windows.net/private/org-E6OJM4bB1nOrMgIeJMRqfI4f/user-8rPBSqloXEXh4WS4lKzp39Cq/img-wdayliL3KvcQp7j7BieYAfVC.png?st=2024-09-04T04%3A12%3A21Z&se=2024-09-04T06%3A12%3A21Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-09-03T23%3A31%3A04Z&ske=2024-09-04T23%3A31%3A04Z&sks=b&skv=2024-08-04&sig=FQSJq/NHrEK3ODM2napsyO2PkiBsTmUODkmRGQqavmw%3D"

  };

  // const { 
  //   register, 
  //   setValue,
  //   handleSubmit,
  //   trigger,
  //   reset,
  //   watch,
  //   setError,
  //   clearErrors,
  //   formState:{
  //       errors,        
  //       isLoading,         
  //       isValid,   
  //       isSubmitting,
  //       isSubmitSuccessful
  //   }
  // } = useForm<Query>({
  //     resolver: yupResolver(schema),
  //     defaultValues:{
  //       query: "",
  //     }
  // }) 


  //---------------------------------------------------- 
  // const formSubmitHandler: SubmitHandler<Query> = async (inputs ) => {
  //   const query = inputs.query
  //   // --------------------

  //   try{
  //     if (query.length > 0){
  //       const completion = await openai.chat.completions.create({
  //         model: "gpt-4o",
  //         messages: [
  //             {"role": "user", "content": "write a haiku about ai"}
  //         ]
  //       });

  //       console.log("completion", completion)

  //       // const response = await axios.post(
  //       //   'https://api.openai.com/v1/images/generations',
  //       //   {
  //       //     prompt: prompt,
  //       //     n: 1,
  //       //     size: '1024x1024'
  //       //   },
  //       //   {
  //       //     headers: {
  //       //       'Authorization': `Bearer ${OPENAI_API_KEY}`,
  //       //       'Content-Type': 'application/json'
  //       //     }
  //       //   }
  //       // );
  //       // console.log("response", response)

  //       // const response:any = await client.photos.search({ query, per_page: 10 });

  //       // if (response){
  //       //   setResult(response.photos)
  //       // } 
  //     }
  //   }catch(error){
  //     console.log("error", error)
  //     Swal.fire(
  //         'There is something wrong during the search!',
  //         'error'
  //     )
  //   }
  // }

  // ---------------------------------
  // useEffect(()=>{
  //   const response:any = client.photos.curated({ per_page: 10 }).then((response: any) => {
  //     console.log("response: ", response)
  //     if (response){
  //       setResult(response.photos)
  //     } 
  //   })
    
    

  // },[])

  // useEffect(()=>{
    
  //   // const response = axios.post(
  //   //     'https://api.openai.com/v1/engines/davinci-codex/completions',
  //   //     {
  //   //       prompt: "cat with wing",
  //   //       max_tokens: 150,
  //   //     },
  //   //     {
  //   //       headers: {
  //   //         'Content-Type': 'application/json',
  //   //         'Authorization': `Bearer ${OPENAI_API_KEY}`,
  //   //       },
  //   //     }
  //   // );
  //   // console.log("response: ,,,", response)
  //   // const aiResponse = getResponse(input);
  //   // console.log("aiResponse: ", aiResponse)

  //   // const response = openai.images.generate({
  //   //   model: "dall-e-3",
  //   //   prompt: "a white siamese cat",
  //   //   n: 1,
  //   //   size: "1024x1024",
  //   // });
  //   // console.log("response: ,,,", response)


  //   const completion = openai.chat.completions.create({
  //     messages: [{ role: "system", content: "You are a helpful assistant." }],
  //     model: "gpt-3.5-turbo",
  //   });
  //   console.log("completion:", completion)
  
  //   // console.log(completion.choices[0]);

  //   // image_url = response.data[0].url;
  // },[])

  //-----------------------------------------
  return (    
      <div 
        className='h-100 w-100 mx-0'
        style={{
          maxHeight:"100%",
          overflowX:"hidden"
          // scrollbarWidth: "thin"
        }}
      >                     
          {/* <form id="frm-edit-query" className='form' onSubmit={handleSubmit(formSubmitHandler)}
          > 
            <div className="pb-4 sticky-top bg-gray-300">                      
              <TextField 
                {...register("query")}                
                label="Search"
                size='small' 
                autoComplete='off'
                // autoCorrect='on'
                fullWidth 
                margin='dense'
                variant='outlined' 
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  // disableUnderline: true,
                  type: 'search',
                  endAdornment: (
                    <InputAdornment position="end">
                      <button type='submit' className='btn btn-primary text-hover-white btn-sm px-1'
                        style={{marginRight: "-14px", borderRadius: "0px 3px 3px 0px"}}
                      >
                        <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
                      </button> 
                    </InputAdornment>
                  )
                }}
                // onChange={(e) => {
                //     setDuration(parseInt(e.target.value)) 
                // }}              
                error={!! errors.query}
                helperText={`${errors.query?.message ?? ''}`}              
              />            
            </div>            
          </form> */}

        <form onSubmit={handleSubmit}>
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            // rows="4"
            // cols="50"
          />
          <br />
          <button type="submit">Ask OpenAI</button>
        </form>
        <h2>Response:</h2>
        <div className='overflow-auto'>
          {results && results.length > 0 && 
            <div className='w-100'>            
              {results.map((res, ind) => (
                <img key={`img-${ind}`} src={res.url} className='w-100'/>
              ))}
            
            </div>
          }
        </div>
        
      </div>
   
  )
}

