/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { 
    FormControl,
    InputLabel, MenuItem, 
    Select,
    FormHelperText
} from '@mui/material'

import {useDropzone} from 'react-dropzone';

import { useGetAllRootFoldersQuery, useUploadFilesMutation } from "../../../../../../../redux/slices/media/apiMediaSlice";
import { useAppSelector } from '../../../../../../../redux/hook'
import FileUpload from '../../../../../../../commons/uploadFiles/FileUpload'

// ---------------------------------------
/**
 * Look like this is never called?
 * Duplicate with ModalUploadFile.tsx
 */
type Props = {  
  className?: string
}

const schema = yup.object().shape({
    // folderId: yup.string().required(),
    noFile: yup.number()
         .min(1)
         .required('At least one file selected.')
})

//----------------------------------------
export const BtnUploadFiles: React.FC<Props> = ({className}) => {
  
  const [uploadMedia] = useUploadFilesMutation();
  // -----------------------------------------

  const { data: allFolders } = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })      
  // console.log("allFolders",allFolders)

  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder: ",currentFolder)


  const [noFile, setNoFile] = useState('');
  const [selectedFolder, setSelectedFolder] = useState({                                                  
                                                  folderId: currentFolder.source_group_id,
                                                  sources: []
                                              })
  // console.log("selectedFolder", selectedFolder)

  // ---------------------------------------------
  const updateSources = (files: any) => {
      setSelectedFolder({ ...selectedFolder, sources: files})
  }

  const fileSizeToUpload = (totalSize: number) => {
    console.log("outside total BTN", [totalSize])
  }
  
  

  // -----------------------
  const formSubmitHandler: SubmitHandler<any> = async (data) => {
      const formData = new FormData();
      // -----------------------------

      /* do manual validation if at least one valid file is selected for upload */
      if(selectedFolder.sources.length < 1){
          setNoFile("At least one file is selected")
      }        

      // formData.append("source_group_id", data.folderId)
      formData.append("source_group_id", currentFolder.source_group_id)
      formData.append("is_remote", "0")

      selectedFolder.sources.forEach(source => {
          formData.append("src_filepath[]", source)
          formData.append("i_url[]", "")
          formData.append("is_remote[]", "0")
          formData.append("url[]", "")
          formData.append("durationList[]", "")
          formData.append("widthList[]", "")
          formData.append("heightList[]", "")
      })
      
      await uploadMedia(formData);      
  }

  const { 
      register, 
      handleSubmit,
      
      formState:{
          errors,
          isDirty,
          
          isLoading,         
          isValid,   
          isSubmitting,
          isSubmitSuccessful,
          
      }
  } = useForm<any>({
      resolver: yupResolver(schema),
      defaultValues:{            
          noFile: 0
      },
      values:{
          noFile: selectedFolder.sources.length
      }
  }) 
  
  
  // ---------------------------------------------
  useEffect(() => { 
    setSelectedFolder({ ...selectedFolder, folderId: currentFolder.source_group_id})
  },[currentFolder])


  //-----------------------------------------
  return (<>
    
    <div className="btn-group btn-group-sm" role="group" >
      <button
        className={`btn btn-icon btn-sm btn-active-color-primary tooltip-bottom `}
        // data-tooltip-content={`Upload to Folder "${currentFolder.group_name}"`}        
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-start'
        data-kt-menu-flip='top-end'

        disabled={currentFolder.source_group_id === ""? true:false}
        
        // data-bs-dismiss="offcanvas"
        // data-kt-drawer-dismiss="true"

        // data-bs-toggle="offcanvas"
        // aria-controls="staticBackdrop"
        // data-bs-target="#kt_drawer_edit_folder"
        // id="kt_drawer_edit_folder_button"
        // data-kt-drawer-show="true" 
      >
        <i className="bi bi-cloud-upload fs-3 text-light"></i>
      </button>

      <div
        className='menu menu-sub menu-sub-dropdown menu-row w-450px w-lg-950px h-450px h-lg-700px shadow-lg mt-2'
        data-kt-menu='true'
        style={{zIndex:"1001", marginLeft:"-25px"}}
      >
        <div className="d-flex flex-column flex-row-auto w-sm-100 w-md-75">
            <div className="d-flex justify-content-between flex-center bg-light-primary h-50px"
              style={{borderTopLeftRadius:"0.475rem"}}
              // style={{background: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}') no-repeat center center / cover`}}
            >
              <h4 className='text-primary fw-bold px-9 mt-6 mb-6'>
                Upload Media Files 
                <i className='fa fa-long-arrow-right fs-3 text-gray-400 mx-3'></i>
                <i className='fa fa-folder fs-3 text-gray-400 me-2'></i>
                <span className='text-gray-400 fs-6'>{currentFolder.group_name}</span>
                {/* <span className='fs-8 opacity-75 ps-3'>to folder <i className='fa fa-folder text-primary me-2'></i>{currentFolder.group_name}</span> */}
              </h4>                          
            </div>
            {/* <div className='separator border-gray-400'></div>  */}
            
            <div className="d-flex flex-column-fluid bg-light rounded-bottom" style={{borderBottomLeftRadius:"0.475rem"}}>              
              <form id="frm-add-device" className='form w-100' onSubmit={handleSubmit(formSubmitHandler)}>
                <div className='w-100 scroll-y mh-550px my-5 px-8'>
                  <div className="offcanvas-body text-start">
                      <div className=''>
                        
                      </div>
                      <div className="mb-4">
                          <input type="hidden"  {...register("noFile")}  />
                          {/*<input type="file" {...register("file")} multiple/>  */}
                          {/* <FileUpload 
                              accept='.jpg, .png, .jpeg, .mp4, .mp3'
                              label=""
                              multiple
                              selectedFolder = {selectedFolder}
                              updateFilesCb={updateSources}
                              totalSize={fileSizeToUpload}
                          /> */}
                      </div>
                      
                  </div>
                </div>
                <div className="px-8 my-5 text-end">    
                  <button className="btn btn-secondary"                       
                      data-kt-menu-dismiss="true"   
                      aria-label="Close"                      
                  >
                      <i className="fa fa-times me-1 mt-n1"></i>
                      Cancel
                  </button>                                
                  <button type="submit" className="btn btn-primary" 
                      // data-bs-toggle="offcanvas"
                      // data-bs-dismiss="offcanvas"
                      // data-bs-toggle="offcanvas"
                      data-kt-menu-dismiss="true"   
                      aria-label="Close"
                      disabled={isSubmitting || !isValid}
                  >
                      <i className="fa fa-upload me-1 mt-n1"></i>
                      Upload
                  </button>
                </div>
              </form>
            </div>
        </div>
        
        <div className="bg-primary rounded-end d-sm-none d-md-flex w-md-25">
          <div className='w-100 scroll-y mh-325px my-5 px-8  text-white'>
            <div className='text-end'>
              <img src="/media/illustrations/icons-editor/file-folder-1.png" className="w-100px"/>
            </div>
            <div className='bg-gray-500 text-light min-h-100px rounded px-5 py-8 mt-n6 '>          
              <div><i className='fa fa-caret-right me-2 text-light'></i> Upload your assets to the cloud</div>
              {/* <div className='ms-5'>(i.e., by type, project, brand kit ...)</div>
              <div className='d-none'><i className='fa fa-caret-right me-2 text-light'></i> Import content from multiple sources</div>
              <div className='d-none ms-5'>such as Google Drive, Dropbox & more.</div> */}
            </div>          
          </div>            
        </div>
        <div className='position-absolute top-0 end-0 me-n2 '>
          <button type="button" className="btn btn-link text-muted me-3" 
              data-bs-toggle="offcanvas"
              data-kt-menu-dismiss="true"                          
          >
              <span className='badge badge-circle'><i className="fa fa-times text-muted me-1 mt-0"></i></span>
          </button>   
        </div>
      </div>
    </div>
  </>)
}
