import { FC, useEffect, useState } from "react"
import { useAppSelector } from "../../../../../redux/hook"
import {useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import Swal from "sweetalert2"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextField } from "@mui/material"
import { ChangePassword, Staff } from "../../../../../redux/slices/staff/staffModels"
import { useChangePasswordStaffMutation, useChangePasswordUserMutation } from "../../../../../redux/slices/staff/apiStaffSlice"
import { PasswordMeterComponent } from "../../../../../../_metronic/assets/ts/components"

type Props = {  
    className?: string
}

const schema = yup.object().shape({
    currentPassword: yup.string()
        .required('Old password is required')
        .min(6, 'Minimum 6 characters')
        .max(16, 'Maximum 16 characters'),
    newPassword: yup.string()
        .min(6, 'Minimum 6 characters')
        .max(16, 'Maximum 16 characters')
        .required('New password is required'),
    confirmedPassword: yup.string()
      .required('Confirmed password is required')
      .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('newPassword')], "New password and confirmed password didn't match"),
      })
})

const DrawerChangePassword: FC<Props> = ({className}) => {
    const actionChangePassword = useAppSelector(state => state.staff.changePassword)

    const [changeUserPassword]  = useChangePasswordUserMutation()
    const [changeStaffPassword] = useChangePasswordStaffMutation()

    const [loading, setLoading] = useState(false)
    
    const { 
        register, 
        setValue,
        handleSubmit,
        reset,
        formState:{
            errors,        
            isValid,   
            isSubmitting,
        },
    } = useForm<ChangePassword>({
        resolver: yupResolver(schema),
        defaultValues: actionChangePassword
    }) 
    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])
    useEffect(() => {
        setLoading(false)
        setValue('newPassword', '')
        // do not need to enter current password if admin want to change password for a staff
        setValue('currentPassword', actionChangePassword.mode === "staff"? 'fakeone' : "") 
        setValue('confirmedPassword', '')
    },[actionChangePassword])
    const formSubmitHandler: SubmitHandler<ChangePassword> = async (data: ChangePassword) => {     
        setLoading(true)
        
        try{
            let res, resObj
            if(actionChangePassword.mode === "staff")
            {
                data.id = actionChangePassword.id
                data.currentPassword = "";
                res  = await changeStaffPassword(data)
                resObj = Object.values(res)

            }else{
                res  = await changeUserPassword(data)
                resObj = Object.values(res)
            }
            if(resObj[0].code === 200 || resObj[0].code === 201)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: resObj[0].message
                }).then(()=>{
                    reset()
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: resObj[0].message
                })
            }
           
            setLoading(false)
        }catch(error){
            setLoading(false)
            Swal.fire(
                'Warning:',
                'Unable to change password.',
                'error'
            )            
        }
        
    }

    return (
        <>  
        
            <div
                id="kt_drawer_change_password"
                data-kt-drawer-toggle={`#kt_drawer_change_password_button`}
                data-kt-drawer-close= {`#kt_drawer_change_password_close`}

                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
                // data-kt-drawer="true"
                data-kt-drawer-activate="true"
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
            <form id="frm-change_password" className='form' onSubmit={handleSubmit(formSubmitHandler)}>
                <div className="offcanvas-header bg-light-primary">
                    <h5 className="offcanvas-title text-primary" id="staticBackdropLabel">
                        <i className="fa fa-key text-primary"></i> Change password
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />                        
                </div>
                <div className="offcanvas-body text-start">
                    <div style={{ maxWidth: "750px" }}>
                        {
                            actionChangePassword.mode === "user" ?
                            <div className="mb-4">
                                <TextField
                                    {...register("currentPassword")} 
                                    label="Old Password" 
                                    type="password"
                                    fullWidth 
                                    autoFocus
                                    required
                                    margin='dense'
                                    variant='outlined' 
                                    error={!! errors.currentPassword }
                                    helperText={`${errors.currentPassword?.message ?? ''}`}
                                    InputLabelProps={{ shrink: true }}
                                />
                                
                            </div>
                            :
                            <>
                            <div className="mb-4">
                                <div className='fs-6 text-muted'>
                                    Changing password for staff: <span className="fw-bold text-primary">{actionChangePassword.name}</span>
                                </div>
                            </div>
                            <div className='separator separator-dashed my-5'></div>
                             </>
                        }
                        <div className='fv-row mb-8' data-kt-password-meter='true'>
                        <div className="mb-4">
                            
                            <TextField
                                {...register("newPassword")} 
                                label="New Password" 
                                type="password"
                                fullWidth 
                                autoFocus
                                required
                                margin='dense'
                                variant='outlined' 
                                error={!! errors.newPassword }
                                helperText={`${errors.newPassword?.message ?? ''}`}
                                InputLabelProps={{ shrink: true }}
                            />
                            {/* begin::Meter */}
                                <div
                                className='d-flex align-items-center mb-3'
                                data-kt-password-meter-control='highlight'
                                >
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                                </div>
                                {/* end::Meter */}
                        </div>
                        </div>
                        <div className="mb-4">
                            <TextField
                                {...register("confirmedPassword")} 
                                label="Confirmed Password" 
                                type="password"
                                fullWidth 
                                autoFocus
                                required
                                margin='dense'
                                variant='outlined' 
                                error={!! errors.confirmedPassword }
                                helperText={`${errors.confirmedPassword?.message ?? ''}`}
                                InputLabelProps={{ shrink: true }}
                            />
                            
                        </div>
                    </div>

                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type="submit" data-bs-dismiss="offcanvas" aria-label="Close" className="btn btn-primary" 
                                disabled={isSubmitting || !isValid || loading}
                            >
                                {
                                    loading 
                                    ? 
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    :
                                        <span><i className="fa fa-save me-1 mt-n1"></i>
                                            Save
                                        </span>
                                }
                                
                            </button>
                </div>
                </form>
            </div>
            
        </>
    )
}

export { DrawerChangePassword }