import { useMemo } from "react"
import { useGetSubAccountsQuery } from "../../../redux/slices/account/apiAccountSlice"
import { subAccount } from "../../../redux/slices/account/accountModel"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table"
import { Box } from "@mui/material"
import moment from "moment"


type Props = {
    className: string,
}

const TableSubAccount: React.FC<Props> = ({ className }) => {
    const { data: subAccounts } = useGetSubAccountsQuery()

    const subAccountColumns = useMemo<MRT_ColumnDef<subAccount>[]>(
        () => [
            {
                accessorKey: 'firstName',
                header: 'Full name',
                enableGrouping: true,
                enableEditing: false, //disable editing on this column    
                enableHiding: false,  //disable column hiding for this column,
                enableColumnOrdering: false,  //disable column ordering for this column,      

                Cell: ({ row }) => {
                    return (
                        <>
                            {row.original.firstName} {row.original.lastName}
                        </>
                    )

                },
            },
            {
                accessorKey: 'email',
                header: 'Email',
                maxSize: 50,
                enableGrouping: true,
                enableEditing: false, //disable editing on this column    
                enableHiding: false,  //disable column hiding for this column,
                enableColumnOrdering: false,  //disable column ordering for this column,      

                Cell: ({ cell }) => {
                    return (
                        <>
                            {cell.getValue()}
                        </>
                    )

                },
            },
            {
                accessorKey: 'status',
                header: 'Status',
                maxSize: 20,
                enableGrouping: true,
                enableEditing: false, //disable editing on this column    
                enableHiding: false,  //disable column hiding for this column,
                enableColumnOrdering: false,  //disable column ordering for this column,      

                Cell: ({ cell }) => {
                    return (
                        <>
                            {cell.getValue() == 0 ? <div className="badge badge-light-success fw-bolder">Active</div> : cell.getValue() == -1 ? <div className="badge badge-secondary fw-bolder">Pending</div> : <div className="badge badge-warning fw-bolder">Deactivated</div>}
                        </>
                    )

                },
            },
            {
                accessorKey: 'created_at',
                header: 'Created At',
                maxSize: 50,
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell }) => (
                  <div className={`d-flex justify-content-start`} >
                    {moment(moment.utc(cell.getValue<string>()).toDate()).format("DD-MMM-YYYY  HH:mm:ss A")}
                  </div>
                ),
              },
              {
                accessorKey: 'updated_at',
                header: 'Last updated',
                maxSize: 50,
                enableColumnOrdering: false,
                enableEditing: false,
                enableSorting: true,
                Cell: ({ cell }) => (
                  <div className={`d-flex justify-content-start`} >
                    {moment(moment.utc(cell.getValue<string>()).toDate()).format("DD-MMM-YYYY  HH:mm:ss A")}
                  </div>
                ),
              },
        ], []
    )

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Sub Account Management</h3>
                    </div>
                    <button
                        className='btn btn-primary align-self-center'
                        data-bs-toggle="offcanvas"

                        data-bs-target="#kt_drawer_create_subaccount"
                        id="kt_drawer_create_subaccount_button"
                        data-kt-drawer-show="true"
                        onClick={() => {

                        }}
                    >Create new sub account</button>
                </div>
                <div className='card-body border-top p-9'>
                    <div className='table-responsive'>
                        {subAccounts &&
                            <MaterialReactTable
                                columns={subAccountColumns}
                                data={subAccounts}
                                enableFullScreenToggle={false}
                                muiTablePaperProps={{
                                    elevation: 0, //change the mui box shadow
                                    sx: {
                                        borderRadius: '1',
                                        backgroundColor: 'unset',
                                    },
                                }}
                                defaultColumn= {{
                                    maxSize: 400,
                                    minSize: 80,
                                    size: 160, //default size is usually 180
                                }}
                                initialState={{
                                    columnVisibility: { category: false },
                                    density: 'compact',
                                    showGlobalFilter: true,
                                }}
                                muiSearchTextFieldProps={{
                                    placeholder: 'Search Ticket',
                                    sx: { minWidth: '18rem' },
                                    variant: 'outlined',
                                    size: 'small',
                                }}
                                muiTopToolbarProps={{
                                    sx: {
                                        // backgroundColor: 'unset',
                                        marginBottom: "1.5rem",
                                        borderRadius: 1
                                    }
                                }}
                                muiBottomToolbarProps={{
                                    sx: {
                                        fontSize: '1.5rem',
                                        borderBottomLeftRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                    }
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        align: 'right',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase',
                                        fontSize: '1.1rem',
                                        borderBottom: '2px solid #9C258F',
                                        paddingTop: '10px',
                                        paddingLeft: '10px',

                                        '&:first-of-type': { borderTopLeftRadius: 5 },
                                        '&:last-child': { borderTopRightRadius: 5 }

                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontSize: '1.1rem',
                                        backgroundColor: '#fcfcfc55',
                                    }
                                }}
                                displayColumnDefOptions={{
                                    'mrt-row-actions': {
                                        muiTableHeadCellProps: { align: 'right' },
                                        size: 50,
                                    },
                                    'mrt-row-expand': {
                                        size: 10,
                                        muiTableHeadCellProps: { align: 'right', color: '#ff0000' },
                                        muiTableBodyCellProps: { align: 'right', },
                                    },
                                }}
                                positionActionsColumn="first"

                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: 'flex', gap: '0.75rem', justifyContent: 'end' }}>
                                    </Box>
                                )}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export { TableSubAccount }