import { FC, useEffect, useMemo, useState } from 'react'
import { useGetAllDevicesQuery } from '../../../../redux/slices/device/apiDeviceSlice';
import { Device, LICENCEPACKAGE_FOR_ADVANCE_FEATURES, isDeviceOnline } from '../../../../redux/slices/device/deviceModels';
import DeviceMQTT from './partials/DeviceMQTT';
import MaterialReactTable, { MRT_ColumnDef, MRT_RowSelectionState } from 'material-react-table';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import AdvanceDeviceHeader from '../AdvanceDeviceHeader';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Autocomplete, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const Index = () => {
  const intl = useIntl()

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  
  const { data: devices } = useGetAllDevicesQuery(5, { refetchOnMountOrArgChange: true })
  const [deviceLicenses, setDeviceLicenses] = useState<Device[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [selectedDeviceStatus, setSelectedDeviceStatus] = useState<string>("")

  // const [rowSelection, setRowSelection] = useState({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  // console.log("devices: ", devices)
  // console.log("deviceLicenses: ", deviceLicenses)
  // console.log("selectedDevice: ", selectedDevice)
  // console.log("selectedDeviceId: ", selectedDeviceId)

  const flatProps = {    
    options: !deviceLicenses? [] : deviceLicenses.map((dev) => {
      return (dev.deviceName)
    })
  };
  
  // ---------------------------------------
  const selectDevice = (device: Device) => {
    if (device) {
      setSelectedDevice(device);

      const momentDateStr = moment(moment.utc(device.checkin_time).toDate()).local().format("DD-MMM-YYYY  HH:mm:ss")  
      setSelectedDeviceStatus(isDeviceOnline(momentDateStr)? "online": "offline")
    }else{
      setSelectedDeviceStatus("")
    }    
  }

  // --------------------
  // const deviceLicenseColumns = useMemo<MRT_ColumnDef<Device>[]>(
  //   () => [ 
           
  //       {            
  //           accessorKey:'deviceName',
  //           header: 'Device',
  //           size: 100,
  //           enableColumnOrdering: false,
  //           Cell: ({cell, row }) => {
  //               return (
  //                   <div className="d-flex flex-start">
  //                     <div className='symbol symbol-30px me-2 mt-n1'>
  //                       <KTSVG
  //                         path={`/media/icons/duotune/electronics/${row.original.groupDisplayType !== "portrait" ? "elc011" : "elc003"}.svg`}
  //                         className="svg-icon-2x svg-icon"
  //                       />
  //                     </div>
  //                     {cell.getValue<string>()}
  //                   </div>
  //               )
  //             }
  //       },
  //       {
  //         accessorFn: (currRow) => {  
  //           const momentDate = moment(moment.utc(currRow.checkin_time).toDate()).local()
  //           const momentDateStr = moment(moment.utc(currRow.checkin_time).toDate()).local().format("DD-MMM-YYYY  HH:mm:ss")  

  //           return isDeviceOnline(momentDateStr)? "online": "offline"
  //         }, 
  //         id: 'checkin_time',        
  //         header: 'STATUS',
  //         enableColumnOrdering: false,
  //         enableEditing: false, //disable editing on this column
  //         enableSorting: true,        
  //         enableColumnFilterModes: false,
  //         enableColumnFilter: true,
  //         enableGrouping: false,
  //         size: 80,        
  //         Cell: ({ renderedCellValue, cell, row }) => {
  //           const momentDate = moment(moment.utc(cell.getValue<string>()).toDate()).local()
  //           const momentDateStr = moment(moment.utc(row.original.checkin_time).toDate()).local().format("DD-MMM-YYYY  HH:mm:ss")  
  //           // ------------------------
  //           return (
  //             renderedCellValue !== "" 
  //               ? <div 
  //                   className={`d-flex text-${isDeviceOnline(momentDateStr)?'success':'danger'} justify-content-start tooltip-bottom`}
  //                   data-tooltip-content={`Last check-in ${momentDate.format("DD-MMM-YYYY  hh:mm A")}`}
  //                 >            
  //                   {isDeviceOnline(momentDateStr)
  //                     ? <span className='badge badge-light-success'><i className='fa fa-circle text-success me-2'></i>Online</span>
  //                     : <span className='badge badge-light-danger'><i className='fa fa-circle text-danger me-2'></i>Offline</span>
  //                   } 
  //                 </div>
  //               : <span>&nbsp;</span>
  //           )
  //         },
  //       },
  //       {            
  //         accessorKey:'groupName',
  //         header: 'Group',
  //         size: 100,
  //         Cell: ({cell, row }) => {
  //           return (
  //             <div className="d-flex flex-start">
  //                 {cell.getValue<string>()}
  //             </div>
  //           )
  //         }
  //       },

                
        
  //   ],[deviceLicenses]
  // )

  // --------------------------------------------
  useEffect(() => {    
      setDeviceLicenses(devices? devices.filter(device => {
        return (device.license && device.license.package) && (LICENCEPACKAGE_FOR_ADVANCE_FEATURES.includes(device.license.package.license_id))
      }) : [])
  }, [devices])

  // ---------------------------------------------------
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DEVICE'})}</PageTitle> */}
      {/* <div className='d-flex flex-row h-100 bg-gray-200 mb-9 p-0 rounded-bottom' 
        // style={{marginLeft:"-30px", marginRight:"-30px" }}
      >
        <div className='d-flex flex-column flex-row-fluid'>
          <div className="d-flex flex-column-auto flex-start">            
            <AdvanceDeviceHeader className='w-100 h-100 py-1'/>            
          </div>
        </div>
      </div> */}

      <div className='row gy-5 gx-xl-8 h-md-300 mt-5 mb-9' ref={ref}>
        {deviceLicenses && deviceLicenses.length > 0 && inView &&
          <div className='col-12'>
            <FormControl fullWidth>
              <InputLabel id="select-device-label">Select a device</InputLabel>
              <Select 
                // {...register("displayType")}
                id="device" 
                labelId="select-device-label"                                        
                label="Select a device"
                value={selectedDeviceId}
                // required
                onChange={(e) => {
                  // console.log("e.target.value: ", e.target.value)
                  setSelectedDeviceId(e.target.value)

                  const selDevice = deviceLicenses.find((dev) => dev.id === e.target.value)

                  if (selDevice){
                    selectDevice(selDevice)
                  }
                }}
                // error={!! errors.displayType}    
                MenuProps={{ disablePortal: true }}                                    
              > 
                {/* <MenuItem key={"0"} value={""} disabled>Select a device</MenuItem> */}
                <MenuItem key={"0"} value={""} disabled>
                  <div className='d-flex'>
                    <div className='w-30px'></div>
                    <div className='w-300px'>Device Name</div>
                    <div className='w-300px'>Group Name</div>
                    <div className='w-300px d-none d-md-block d-lg-block d-xl-block d-xxl-block'>License</div>
                  </div>
                </MenuItem>
                {deviceLicenses
                .slice().sort((a, b) => {
                  const A_momentDate = moment(moment.utc(a.checkin_time).toDate()).local()
                  const A_momentDateStr = moment(moment.utc(a.checkin_time).toDate()).local().format("DD-MMM-YYYY  HH:mm:ss")
                  const A_onlineStatus = isDeviceOnline(A_momentDateStr)? "online": "offline"

                  const B_momentDate = moment(moment.utc(b.checkin_time).toDate()).local()
                  const B_momentDateStr = moment(moment.utc(b.checkin_time).toDate()).local().format("DD-MMM-YYYY  HH:mm:ss")
                  const B_onlineStatus = isDeviceOnline(B_momentDateStr)? "online": "offline"
                  // ------------------------------------
                  return (B_onlineStatus).localeCompare(A_onlineStatus)
                })
                .map((dev) => {
                  const momentDate = moment(moment.utc(dev.checkin_time).toDate()).local()
                  const momentDateStr = moment(moment.utc(dev.checkin_time).toDate()).local().format("DD-MMM-YYYY  HH:mm:ss")
                  
                  // ---------------------------------------------
                  return (  <MenuItem key={dev.id} value={dev.id} disabled={! isDeviceOnline(momentDateStr)}>
                              <div className='d-flex'>
                                <div className='symbol symbol-30px me-2 mt-n1'>
                                  <KTSVG
                                    path={`/media/icons/duotune/electronics/${dev.groupDisplayType !== "portrait" ? "elc011" : "elc003"}.svg`}
                                    className={`svg-icon-2x svg-icon-${isDeviceOnline(momentDateStr)?'success':'danger'}`}
                                  />
                                  {isDeviceOnline(momentDateStr) &&
                                    <span className="bullet bullet-dot bg-success h-5px w-5px position-absolute translate-middle top-50 start-50 animation-blink"></span>
                                  }
                                </div>
                                <div className='w-300px'>{dev.deviceName}</div>
                                <div className='w-300px'>
                                  <div className='symbol symbol-30px me-2 mt-n1'>
                                    <KTSVG path="/media/icons/duotune/files/fil012.svg" className="svg-icon-2x svg-icon-gray-400"/>
                                  </div>
                                  {dev.groupName}
                                </div>
                                <div className='w-300px d-none d-md-block d-lg-block d-xl-block d-xxl-block'>
                                  <div className='symbol symbol-30px me-2 mt-n1'>
                                    <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-2x svg-icon-gray-400"/>
                                  </div>
                                  {dev.license?.name}
                                </div>
                              </div>
                            </MenuItem>
                          )
                })}                                        
              </Select>
               {/* <FormHelperText>{`${errors.displayType?.message ?? ''}`}</FormHelperText> */}
            </FormControl>             
          </div>
        }
      </div>
        {/* <div className='col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4'>          
          <div className='card card-xxl-stretch h-md-100 min-h-600px'>            
            <div className='card-body p-5'>              
              {inView &&
                <MaterialReactTable
                  columns={deviceLicenseColumns}
                  data={deviceLicenses? deviceLicenses:[]}
                  enableStickyHeader={true}
                  muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                  //customize paper styles
                  sx: {
                      borderRadius: '1',
                      backgroundColor: 'unset',
                      // border: '1px dashed #e0e0e0',              
                  },
                  }}
                  muiTableBodyProps={{
                  sx: {
                      '& td': {
                          // color: "#ff0000",
                          verticalAlign: "top"
                      },
                      // backgroundColor: '#ff1100',
                      //stripe the rows, make odd rows a darker color              
                      '& tr:nth-of-type(odd)': {
                      // backgroundColor: '#fcfcfc',                
                      },
                      '& tr:not(:last-child) td': {
                          borderBottom: '1px solid #f6f6f6',
                      },
                      '& tr:last-child td': {                
                          borderBottom: '0px',
                      },
                  },
                  }} 
                  
                  editingMode="modal"
                  // enableColumnOrdering

                  // enableColumnActions={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableGlobalFilter={false}
                  enableHiding={false} 
                  // enablePagination={false}
                  enablePagination={true}
                  // enableSorting={false}
                  
                  enableFilters={false}
                  enableTopToolbar={true}
                  enableBottomToolbar={true}
                  positionToolbarAlertBanner= "bottom"
                  positionGlobalFilter="left"
                  enableGrouping
                  
                  initialState={{ 
                      density: 'compact',
                      showGlobalFilter: true,     

                      // expanded: true, //expand all groups by default
                      // expanded: {
                      //   1: true,
                      //   2: true,
                      // },

                      // grouping: ['groupName'], //an array of columns to group by by default (can be multiple)
                      pagination: { pageIndex: 0, pageSize: 100 },
                      sorting: [{ id: 'checkin_time', desc: true }, { id: 'deviceName', desc: true }], //sort by state by default       
                  }}

                  // getRowId={(row) => row.userId}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      setRowSelection((prev) => ({
                        // ...prev, //for multiple selection
                        [row.id]: !prev[row.id],
                      }))
                      selectDevice(row.original)
                    },
                    selected: rowSelection[row.id],
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                  // state={{ rowSelection }}

                  muiTopToolbarProps={{ sx: { marginBottom:"1.5rem" } }}
                  muiBottomToolbarProps={{ 
                      sx: { 
                          fontSize: '1.5rem',
                          borderBottomLeftRadius: '5px',                    
                          borderBottomRightRadius: '5px', 
                          // '&:first-of-type': { borderBottomLeftRadius: 10 },
                          // '&:nth-child(2)': { borderBottomLeftRadius: 10 },
                          // '&:last-child': { borderBottomRightRadius: 10 } 
                      } 
                  }}    
                  muiSearchTextFieldProps={{
                      placeholder: 'Search...',
                      sx: { minWidth: '15rem', marginLeft:"0", paddingLeft:"0"},
                      variant: 'outlined',
                      size: 'small',            
                  }}                 
                  muiTableHeadCellProps={{ 
                      sx: { 
                          align: 'center',
                          fontWeight: 'bold', 
                          textTransform: 'uppercase', 
                          fontSize: '1.1rem',
                          // backgroundColor: '#E9E1F6',
                          // backgroundColor: '#F3CCEE', 
                          borderBottom: '2px solid #9C258F',
                          paddingTop:'10px',
                          
                          
                          '&:first-of-type': { borderTopLeftRadius: 5 },
                          '&:last-child': { borderTopRightRadius: 5 }
                      
                      }, 
                  }}
                  muiTableBodyCellProps={{ 
                      sx: { 
                          fontSize: '1.1rem', 
                          backgroundColor: '#fcfcfc55',                    
                      } 
                  }}                            
                  displayColumnDefOptions={{
                      'mrt-row-actions': {
                          size: 30,
                          muiTableHeadCellProps: { align: 'right', color:'#00FF00'},              
                      },
                      'mrt-row-expand': {
                          size: 10,
                          muiTableHeadCellProps: { align: 'right', color:'#FF0000'},
                          muiTableBodyCellProps: { align: 'right', },
                      },
                  }}
                />
              }
            </div>              
          </div>
        </div> */}

        {/* ------------ */}
      <div className='row gy-1 gx-xl-8 h-md-300 mt-2 mb-9' ref={ref}>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          {/* {selectedDeviceStatus === "" && 
            <div className='card card-xxl-stretch h-md-100 min-h-600px'>
              <div className='card-body bg-secondary rounded p-5 d-flex align-items-center justify-content-center'>
                <div className='row gy-5 gx-xl-8 h-md-300 mb-7'>    
                  <div className='col-lg-12 col-xl-12 d-flex flex-center'>          
                    <img src={toAbsoluteUrl('/media/_device/select-device.png')} className='w-50 mt-10 opacity-25' />
                  </div>
                  <div className='col-lg-12 col-xl-12 text-center '>
                    <h1 className='text-muted'>Please select an <span className='text-success'>Active</span> device!</h1>
                  </div>        
                </div>
              </div>
            </div>
          }       */}
          
          {/* {selectedDeviceStatus !== "" && selectedDevice && selectedDeviceStatus === "online" &&     */}
            <div className='card card-xxl-stretch h-md-100 min-h-600px'>
              <div className='card-body p-0'>
                {/* {selectedDevice && */}
                  {/* <DeviceMQTT device={selectedDevice} /> */}
                {/* } */}
              </div>
            </div>
          {/* } */}
        </div>
      </div>      
    </>
  )
}

export { Index }
