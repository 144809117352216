/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { useGetAllSchedulesQuery } from '../../../../redux/slices/schedule/apiScheduleSlice';
// import { Scheduler, SchedulerData } from "@bitnoi.se/react-scheduler";
// import "@bitnoi.se/react-scheduler/dist/style.css";


type Props = {
  className: string,
}

const BitnoiseScheduler: React.FC<Props> = ({className}) => {
  
    const [filterButtonState, setFilterButtonState] = useState(0);

    // const mockedSchedulerData: SchedulerData = [{
    //     id: "070ac5b5-8369-4cd2-8ba2-0a209130cc60",
    //     label: {
    //         icon: "https://picsum.photos/24",
    //         title: "Joe Doe",
    //         subtitle: "Frontend Developer"
    //     },
    //     data: [
    //         {
    //           id: "8b71a8a5-33dd-4fc8-9caa-b4a584ba3762",
    //           startDate: new Date("2023-04-13T15:31:24.272Z"),
    //           endDate: new Date("2023-08-28T10:28:22.649Z"),
    //           occupancy: 3600,
    //           title: "Project A",
    //           subtitle: "Subtitle A",
    //           description: "array indexing Salad West Account",
    //           bgColor: "rgb(254,165,177)"
    //         },
    //         {
    //           id: "22fbe237-6344-4c8e-affb-64a1750f33bd",
    //           startDate: new Date("2023-10-07T08:16:31.123Z"),
    //           endDate: new Date("2023-11-15T21:55:23.582Z"),
    //           occupancy: 2852,
    //           title: "Project B",
    //           subtitle: "Subtitle B",
    //           description: "Tuna Home pascal IP drive",
    //           bgColor: "rgb(254,165,177)"
    //         },
    //         {
    //           id: "3601c1cd-f4b5-46bc-8564-8c983919e3f5",
    //           startDate: new Date("2023-03-30T22:25:14.377Z"),
    //           endDate: new Date("2023-09-01T07:20:50.526Z"),
    //           occupancy: 1800,
    //           title: "Project C",
    //           subtitle: "Subtitle C",
    //           bgColor: "rgb(254,165,177)"
    //         },
    //         {
    //           id: "b088e4ac-9911-426f-aef3-843d75e714c2",
    //           startDate: new Date("2023-10-28T10:08:22.986Z"),
    //           endDate: new Date("2023-10-30T12:30:30.150Z"),
    //           occupancy: 11111,
    //           title: "Project D",
    //           subtitle: "Subtitle D",
    //           description: "Garden heavy an software Metal",
    //           bgColor: "rgb(254,165,177)"
    //         }
    //     ]
    // }];

    const EVENTS = [
        {
          event_id: 1,
          title: "Event 1",
          start: new Date("2021 5 2 09:30"),
          end: new Date("2021 5 2 10:30")
        },
        {
          event_id: 2,
          title: "Event 2",
          start: new Date("2021 5 4 10:00"),
          end: new Date("2021 5 4 11:00")
        },
        {
          event_id: 3,
          title: "Event 3",
          start: new Date("2021 4 27 09:00"),
          end: new Date("2021 4 28 10:00")
        },
        {
          event_id: 4,
          title: "Event 4",
          start: new Date("2021 5 4 9:00"),
          end: new Date("2021 5 4 10:36")
        },
        {
          event_id: 5,
          title: "Event 5",
          start: new Date("2021 5 1 10:00"),
          end: new Date("2021 5 18 11:00")
        },
        {
          event_id: 6,
          title: "Event 6",
          start: new Date("2021 5 2 11:00"),
          end: new Date("2021 5 2 12:00")
        },
        {
          event_id: 7,
          title: "Event 7",
          start: new Date("2021 5 1 12:00"),
          end: new Date("2021 5 1 13:00")
        },
        {
          event_id: 8,
          title: "Event 8",
          start: new Date("2021 5 1 13:00"),
          end: new Date("2021 5 1 14:00")
        },
        {
          event_id: 9,
          title: "Event 11",
          start: new Date("2021 5 5 16:00"),
          end: new Date("2021 5 5 17:00")
        },
        {
          event_id: 10,
          title: "Event 9",
          start: new Date("2021 5 6  15:00"),
          end: new Date("2021 5 6 16:00")
        },
        {
          event_id: 11,
          title: "Event 10",
          start: new Date("2021 5 6 14:00"),
          end: new Date("2021 5 6 15:00")
        }
    ];
      

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(true);

      // fetching data
      
      setIsLoading(false);
    }, []);

  //-----------------------------------------
  return (
    <div className={`card ${className}`}>
        

        {/* begin::Body */}
        <div className='card-body py-5'>
            <section>

                {/* <Scheduler
                    view="week"
                    events={EVENTS}
                    selectedDate={new Date(2021, 4, 5)}
                /> */}
                
                {/* <Scheduler
                    data={mockedSchedulerData}
                    isLoading={isLoading}
                    // onRangeChange={(newRange) => console.log(newRange)}
                    // onTileClick={(clickedResource) => console.log(clickedResource)}
                    // onItemClick={(item) => console.log(item)}
                    // onFilterData={() => {
                    // // Some filtering logic...
                    // setFilterButtonState(1);
                    // }}
                    // onClearFilterData={() => {
                    // // Some clearing filters logic...
                    // setFilterButtonState(0)
                    // }}
                    // config={{
                    // zoom: 0,
                    // filterButtonState,
                    // }}
                /> */}

                {/* <Scheduler
                        // decide when to show loading indicators
                        isLoading={isLoading}
                        // your data
                        data={mockedSchedulerData}
                        // callback when user click's on one of the grid's tile
                        onItemClick={(clickedItem) => console.log(clickedItem)}
                        // filter function that let's you handling filtering on your end
                        onFilterData={() => {
                        // filter your data
                        }}
                        // callback when user clicks clearing filter button
                        onClearFilterData={() => {
                        // clear all your filters
                        }}
                        config={{
                    
                        //    change filter button state based on your filters
                        //    < 0 - filter button invisible,
                        //    0 - filter button visible, no filter applied, clear filters button invisible,
                        //    > 0 - filter button visible, filters applied (clear filters button will be visible)
                        
                        filterButtonState: 0,
                        // decide start zoom variant (0 - weeks, 1 - days)
                        zoom: 0,
                        // select language for scheduler
                        lang: "en",
                        // decide how many resources show per one page
                        maxRecordsPerPage: 20,
                        }}
                    /> */}
            </section>
        </div>
    </div>
  )
}

export {BitnoiseScheduler}

