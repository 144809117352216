import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { Reseller, ResellerClaimHistory, ResellerClaimable, ResellerCustomer } from "./resellerModels";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL

const auth = getAuthModel();

export const resellerApi = createApi({
    reducerPath: "resellerApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),

    tagTypes: ['Reseller'],
    refetchOnMountOrArgChange: 30,

    endpoints: (builder) => ({
        
        getResellerProfile : builder.query<Reseller, void>({
            query: () => `reseller`,
            providesTags: ['Reseller'],
            keepUnusedDataFor: 5,              
        }),

        getResellerCustomer: builder.query<ResellerCustomer[], void>({
            query: () => `reseller/customers`,
            providesTags: ['Reseller'],
            keepUnusedDataFor: 5,
        }),

        getResellerClaimable: builder.query<ResellerClaimable[], void>({
            query: () => `reseller/claimable`,
            providesTags: ['Reseller'],
            keepUnusedDataFor: 5,
        }),

        getResellerClaimHistory: builder.query<ResellerClaimHistory[], void>({
            query: () => `reseller/claimHistories`,
            providesTags: ['Reseller'],
            keepUnusedDataFor: 5,
        }),

        getResellerClaimDetail: builder.query<ResellerClaimable[], string>({
            query: (id) => `reseller/claimDetail/${id}`,
            providesTags: ['Reseller'],
            keepUnusedDataFor: 5,
        }),

        submitClaim: builder.mutation<any, void>({
            query(){
                return{
                    url: "reseller/claim/submit",
                    method: "POST",
                }
            },
            invalidatesTags: ['Reseller']
        }),
    })
})

export const {
    useGetResellerProfileQuery,
    useGetResellerCustomerQuery,
    useGetResellerClaimHistoryQuery,
    useGetResellerClaimableQuery,
    useGetResellerClaimDetailQuery,
    useSubmitClaimMutation
} = resellerApi