import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'

import { useEffect, useState } from 'react'
import { SearchInner } from '../../../../../../../../_metronic/partials'
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hook'
import { setCurrentFolderEmpty, setMultiSelectMode, setSelectedMediaSrcIds } from '../../../../../../../redux/slices/editor/editorSlice'
import { useDeleteMediasMutation, useGetAllMediasQuery, useGetAllRootFoldersQuery, useGetDeletedMediaQuery, useRestoreMediaMutation } from '../../../../../../../redux/slices/media/apiMediaSlice'
import { Medias, RootFolders } from '../../../../../../../redux/slices/media/mediaModels'
import Swal from 'sweetalert2'
import { setContentSource, setSectionId } from '../../../../../../../redux/slices/content/contentSlice'
import { Source } from '../../../../../../../redux/slices/content/contentModels'


type Props = {
  className: string,
}


//----------------------------------------
export const GalleryBreadcrumb: React.FC<Props> = ({className}) => { 
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const [restoreMedia] = useRestoreMediaMutation();
  const [emptyMediaBin] = useDeleteMediasMutation();
  // ---------------------------------------
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent) 
  const mediaIDInUse = useAppSelector(state => state.persistor._content.mediaIDInUse)
  // console.log("mediaIDInUse: ", mediaIDInUse)
  // console.log("Object.keys(mediaIDInUse): ", Object.keys(mediaIDInUse))
  

  const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("rootFolders in Breadcrumb:", (rootFolders? rootFolders.data.map(folder => folder.sources? folder.sources.length: 0): []).filter(Number))
  
  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder:", currentFolder)

  const [grpName, setGrpName] = useState(currentFolder.group_name)

  const multiSelMode = useAppSelector(state => state.editor.multiSelectMode)  
  const selMediaSrcIds = useAppSelector(state => state.editor.selectedMediaSrcIds)  
  // console.log("selMediaSrcIds:", selMediaSrcIds)
  
  // ------------------------------
  const handleBulkDelete = async() =>{
    let inUseStatus = selMediaSrcIds.some( ai => Object.keys(mediaIDInUse).includes(ai) );

    if ((currentFolder.source_group_id === "bin") || //remove permanently from the trash bin
        (inUseStatus) // bulk delete the files (currently in use in the campaigns)
    ){ 
      const tmpTitle = inUseStatus  ? `<h3>Delete ${selMediaSrcIds.length> 1? `all of these ${selMediaSrcIds.length} files`:"this file"} ?<h3>`
                                    : `<h3>Delete ${selMediaSrcIds.length> 1? `all of these ${selMediaSrcIds.length} files`:"this file"} permantly?<h3>`

      Swal.fire({
        title: tmpTitle, 
        text: inUseStatus ? "Some files are currently in use.":"",       
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#9C258F',
        cancelButtonColor: '#E4E6EF',
        confirmButtonText: 'Yes, of course!',
        customClass: {          
            cancelButton: 'order-1 left-gap me-5',
            confirmButton: 'order-2',
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try{
            if(selMediaSrcIds.length > 0){
              
              var res = await emptyMediaBin(selMediaSrcIds)
              let objRes: any = Object.values(res)      
              // console.log("objRes: ", objRes)
      
              if(objRes && objRes[0].code === 200){
      
                // 2- Delete images from currContent
                // --------------------------------------------------
                currPersistContent.elements.map((section, indSect)=>{
                  var sourcesAfterDelete : Source[] = []
      
                  if (["image", "video"].includes(section.element_type.toLowerCase())){
                    sourcesAfterDelete = section.sources?.filter((src) => {
                      return (src.source && (! Object.keys(mediaIDInUse).includes(src.source.source_id)))                           
                    })
                    if (section.sources.length !== sourcesAfterDelete.length){
                      dispatch(setSectionId(section.element_id))  //which section it belongs to
                      dispatch(setContentSource(sourcesAfterDelete))
                    }
                  }
                }) 

                dispatch(setMultiSelectMode(false))

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-right',
                  iconColor: 'green',
                  customClass: {
                    popup: 'colored-toast'
                  },
                  showConfirmButton: false,
                  timer: 2000,
                  // timerProgressBar: true
                })
                Toast.fire({
                    icon: 'success',
                    title: `Files have been permanently deleted.`,
                })

              }else{
                Swal.fire(
                  'There is something wrong during the deletion!',
                  'error'
                )
              }
            }
          }catch(error){
            console.log("error", error)
            Swal.fire(
                'There is something wrong during the deletion!',
                'error'
            )            
          }
        }
      })
    }else{
      //Move multiple files to Trash bin without confirmation
      // --------------------------------------------
      if(selMediaSrcIds.length > 0){
        var res = await emptyMediaBin(selMediaSrcIds)
        let objRes: any = Object.values(res)      
        // console.log("objRes: ", objRes)

        if(objRes && objRes[0].code === 200){

          dispatch(setMultiSelectMode(false))

          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,
          })
          Toast.fire({
              icon: 'success',
              title: `Files have been succesfully moved to trash.`,
          })
        }else{
          Swal.fire(
            'There is something wrong during the deletion!',
            'error'
          )
        }
      }
    }


    
  }

  // ------------------------------
  const handleRestore = async() =>{
    Swal.fire({
      title: `<h3>Restore ${selMediaSrcIds.length> 1? `all of these ${selMediaSrcIds.length} files`:"this file"}?<h3>`,              
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#9C258F',
      cancelButtonColor: '#E4E6EF',
      confirmButtonText: 'Yes, of course!',
      customClass: {          
          cancelButton: 'order-1 left-gap me-5',
          confirmButton: 'order-2',
      }
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        try{
          var res = await restoreMedia(selMediaSrcIds);
          let objRes: any = Object.values(res)
          // console.log("Restore result", objRes);

          if(objRes && objRes[0].code === 200){

            dispatch(setMultiSelectMode(false))
            dispatch(setSelectedMediaSrcIds([]))

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-right',
                iconColor: 'green',
                customClass: {
                popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 2000,
                // timerProgressBar: true
            })
            Toast.fire({
                icon: 'success',
                title: `Recovery has been successfully done.`,
            })

          }else{
            Swal.fire(
              'There is something wrong during the recovery !',
              'error'
            )
          }
            
        }catch(error){
            console.log("error", error)
            Swal.fire(
                'There is something wrong during the recovery!',
                'error'
            )            
        
        }
      }
    })
  }





  // -----------------------------------------
  useEffect(() => {
    setGrpName(currentFolder.group_name) 
  }, [currentFolder])

  // -----------------------------------------
  useEffect(() => {
    const updatedFolder = rootFolders?.data.find((folder, index)=> folder.source_group_id === currentFolder.source_group_id)
    if (updatedFolder){
      setGrpName(updatedFolder.group_name)
    }

  }, [rootFolders])


  // useEffect(() => {       
  //   // console.log("currentFolder in useEffect: ", currentFolder) 

  //   if (rootFolders && currentFolder.source_group_id !== ""){  

  //     var tmpRootFoldersWithBin: RootFolders = {data: []}
  //     // --------------------------------------------------
  //     if (deletedSources && deletedSources.length > 0){
  //       setAllMediasWithBin({data: [
  //                                       ...(allMedias? allMedias.data : []),
  //                                       ...deletedSources
  //                                     ]
  //       })

  //       const binFolder : Folder = {
  //         group_name      : "Recently Deleted",
  //         source_group_id : "bin",
  //         sources         : deletedSources,
  //         created_time    : "",
  //         deleted_at      : null,    
  //         ordering        : 0,            
  //         user_id         : rootFolders.data[0].user_id, 
  //       }

  //       tmpRootFoldersWithBin = {data: [  ...rootFolders.data,
  //                                         {...binFolder}
  //                                       ]
  //                               }        
  //     }else{
  //       setAllMediasWithBin({data: [...(allMedias? allMedias.data : [])]})
  //       tmpRootFoldersWithBin = {data: [  ...rootFolders.data        ]}
  //     }
      
  //     // console.log("tmpRootFoldersWithBin:", tmpRootFoldersWithBin)
  //     setRootFoldersWithBin(tmpRootFoldersWithBin)

  //     // -------------------------------------
  //     const updatedCurrFolder = tmpRootFoldersWithBin.data.filter((fold, index)=> fold.source_group_id === currentFolder.source_group_id)[0]
  //     // console.log("updatedCurrFolder", updatedCurrFolder)      
      
  //     if (updatedCurrFolder) {
  //       setGrpName(updatedCurrFolder.group_name)  
  //       setCurrentFolder(updatedCurrFolder)
  //       setNbFiles(updatedCurrFolder.sources? updatedCurrFolder.sources.length: 0)
  //     }
  //   }        
  // // }, [ rootFolders, allMedias, currentFolder])
  // }, [ rootFolders, allMedias, currentFolder, deletedSources ])



  // ------------------------
  return (<>
    {/* {currentFolder.group_name !== "" && */}
      <div className='w-100 d-flex justify-content-between my-3 ms-5 me-3 pt-0 '>        
        <ol className="w-100 breadcrumb text-muted fs-6 fw-bold">
          <li className="pe-0">
            {currentFolder.group_name !== "" 
              ? <a href="#" className=""
                  onClick={() => {
                    dispatch(setCurrentFolderEmpty())

                    dispatch(setMultiSelectMode(false))
                    dispatch(setSelectedMediaSrcIds([]))
                  }}
                >              
                  <i className='fa fa-folder-open text-primary me-2'></i>
                  <span className={`mt-2 text-capitalize tooltip-bottom`} data-tooltip-content="Back to 'My Gallery' to create new folder(s)">My Gallery</span>
                  <i className='fa fa-chevron-right text-muted mx-2'></i>
                </a>
              : <span className={`mt-2 text-capitalize`}><i className='fa fa-folder-open me-1'></i> My Gallery</span>
            }
          </li>  

          {currentFolder.group_name !== "" && <>
            {currentFolder.readonly === 1 || currentFolder.source_group_id === "bin"
              ? <li className={`breadcrumb-item px-0 text-muted`}>
                  { currentFolder.readonly === 1
                      ? <span className={`fst-italic text-capitalize`}>{grpName} (System)</span>
                      : <span className={``}>{grpName}</span>
                  }
                </li>
              : <li className={`breadcrumb-item px-0 text-muted text-hover-primary`}>
                  <a href="#"
                    className='link-hover-with-icon text-muted text-hover-primary tooltip-bottom'
                    data-tooltip-content='Click to edit this folder.'

                    data-bs-toggle={ currentFolder.readonly !== 1 ? "offcanvas":""}
                    aria-controls= { currentFolder.readonly !== 1 ? "staticBackdrop":""}
                    data-bs-target="#kt_drawer_edit_folder"
                    id="kt_drawer_edit_folder_button"
                    data-kt-drawer-show="true"
                  >
                    { currentFolder.readonly === 1
                              ? <span className={`mt-2 fst-italic text-capitalize`}>{grpName} (System)</span>
                              : <span className={`mt-2 text-capitalize`}>
                                  {grpName}
                                  <i className='link-hover-icon fa fa-pen ms-2'></i>                                  
                                </span>
                    }                    
                  </a>
                </li>
              }
            </>}         
        </ol>

        {currentFolder.group_name !== "" &&        
          <div className='d-flex text-end ms-3'>
            <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
              <button className={`btn ${multiSelMode? "btn-info":"btn-secondary"} text-hover-primary p-0 px-1 tooltip-bottom`}
                data-tooltip-content={`${multiSelMode? (selMediaSrcIds? (selMediaSrcIds.length>1? `${selMediaSrcIds.length} files are`: `${selMediaSrcIds.length} file is `):0)+" selected":"Activate multi-select mode"}`}
                onClick={()=> dispatch(setMultiSelectMode(!multiSelMode))}
              >
                <i className='bi bi-check fs-1 w-25px'></i>
              </button>

              <button type="button" className="btn btn-secondary rounded-end me-2 p-0 ps-2 pe-1" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-three-dots "></i>
              </button>
              
              <ul className="dropdown-menu w-200px shadow-sm mt-0 mb-n4 p-5 py-5 " 
                  style={{zIndex: "3050"}}
              >
                <div className="mb-0">
                  <button className='w-100 d-flex justify-content-start btn btn-link text-hover-primary p-0'
                    data-bs-toggle={ currentFolder.readonly !== 1 ? "offcanvas":""}
                    aria-controls= { currentFolder.readonly !== 1 ? "staticBackdrop":""}
                    data-bs-target="#kt_drawer_edit_folder"
                    id="kt_drawer_edit_folder_button"
                    data-kt-drawer-show="true"
                  >
                    <i className='fa fa-pen fs-8 w-25px mt-1 me-1'></i>
                    <span>Edit Folder</span>
                  </button>
                </div>

                <div className='separator separator-dashed my-5' />

                <div className="mb-0">
                  <button className='w-100 d-flex justify-content-start btn btn-link text-hover-primary p-0'
                    onClick={()=> {
                      dispatch(setMultiSelectMode(true))
                      dispatch(setSelectedMediaSrcIds(currentFolder.sources? currentFolder.sources.map(src => src.source_id) : []))
                    }}
                  >
                    <i className='bi bi-check-all fs-3 w-25px mt-1 me-1'></i>
                    <span>Select All</span>
                  </button>
                </div>
                <div className="mb-0">
                  <button className='w-100 d-flex justify-content-start btn btn-link text-hover-primary p-0'
                    onClick={()=> {
                      dispatch(setSelectedMediaSrcIds([]))
                    }}
                    disabled = {selMediaSrcIds.length <= 0}
                  >
                    <i className='bi bi-x fs-3 w-25px mt-1 me-1'></i>
                    <span>Unselect All</span>
                  </button>
                </div>

                {/* <div className='separator separator-dashed my-5' /> */}
                
                {/* { currentFolder.readonly !== 1 && 
                  <>         
                    <button 
                      className='btn btn-link text-hover-primary p-0' 

                      data-bs-toggle="offcanvas"
                      aria-controls="staticBackdrop"
                      data-bs-target="#kt_drawer_edit_folder"
                      id="kt_drawer_edit_folder_button"
                      data-kt-drawer-show="true" 
                    >
                      <span className='w-25px me-1'>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-5 me-4' />
                        <span>Edit folder</span>
                      </span>
                    </button>
                  </> 
                } */}

                {currentFolder.source_group_id === "bin" && <>
                  <div className='separator separator-dashed my-5' />
                  <div className="mb-1">
                    <button className='w-100 d-flex justify-content-start btn btn-link text-hover-primary p-0'
                      onClick={handleRestore}
                      disabled= {selMediaSrcIds.length <= 0}
                    >
                      <i className='fa fa-recycle fs-4 w-25px mt-1 me-1'></i>
                      <span>Restore</span>
                    </button>
                  </div>
                </>}
                  

                {currentFolder.source_group_id !== "bin" && 
                <>
                  <div className='separator separator-dashed my-5'/>
                  <div className="mb-1">
                    <button className='w-100 d-flex justify-content-start btn btn-link text-hover-primary p-0'
                      data-bs-toggle="offcanvas"
                      aria-controls="staticBackdrop"
                      data-bs-target="#kt_drawer_change_folder"
                      id="kt_drawer_change_folder_button"
                      data-kt-drawer-show="true" 

                      disabled= {selMediaSrcIds.length <= 0}
                    >
                      <i className='bi bi-folder-symlink fs-4 w-25px mt-1 me-1'></i>
                      <span>Move to ...</span>
                    </button>                    
                  </div>
                  

                  {/* <div className="mb-1">                    
                    <button className='btn btn-linktext-hover-primary p-0'
                      data-bs-toggle="offcanvas"
                      aria-controls="staticBackdrop"
                      data-bs-target="#kt_drawer_add_to_section"
                      id="kt_drawer_add_to_section_button"
                      data-kt-drawer-show="true" 
                    >
                      <span className='w-25px me-1'>
                        <KTSVG path='/media/icons/duotune/layouts/lay001.svg' className='svg-icon-3' />
                      </span>
                      <span>Add to section</span>
                    </button>
                  </div> */}

                </>}

                <div className='separator separator-dashed my-5' />
                <div className="mb-1">
                  <button className='w-100 d-flex justify-content-start btn btn-link text-hover-primary p-0'
                    onClick={handleBulkDelete}
                    disabled= {selMediaSrcIds.length <= 0}
                  >
                    <i className='fa fa-trash fs-5 w-25px mt-1 me-1'></i>
                    <span>{currentFolder.source_group_id === 'bin'? "Delete permanently":"Move to trash"} </span>
                  </button>
                </div>
                
              </ul>
            </div>
          </div>
        }
      </div> 
    {/* }      */}
  </>)
}

