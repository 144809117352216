import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./resellerModels";

const initialState: InitialState = {
}

const staffSlice = createSlice({
    name: 'reseller',
    initialState,
    reducers:{
    }
})

export default staffSlice.reducer
export const {
    
} = staffSlice.actions