import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth"
import { Cart } from "./cartModel";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL

export  const auth = getAuthModel();

export const cartsApi = createApi({
    reducerPath: "cartsApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    
    tagTypes: ['cart', 'cartPackage', 'AccountcartPackage'],
    refetchOnMountOrArgChange: 30,    
    
    endpoints: (builder) => ({
        /**
         * If the device does not have any cart. Free cart will be included.
         */
        // getAllLicenses : builder.query<License[], number>({
        //     query: () => `licenses/packages`,
        //     providesTags: ['License', 'AccountLicensePackage'],
        //     keepUnusedDataFor: 5,              
        // }),
        
        // getAccountLicenses : builder.query<AccountLicensePackage[], number>({
        //     query: (filter) => `account/licenses/${filter}`,
        //     providesTags: ['AccountLicensePackage'],
        //     keepUnusedDataFor: 5,              
        // }),

        // addLicense: builder.mutation<Object, BuyLicense>({
        //     query(data){
        //         return{
        //             url: "license/payment/complete",
        //             method: "POST",
        //             body: data
        //         }
        //     },
        //     invalidatesTags: ['AccountLicensePackage']
        // }),

        // getAccountStorageAllowance : builder.query<number, number>({
        //     query: () => `/account/storage/allowance`,
        //     keepUnusedDataFor: 5,              
        // }),

        // getAccountStoragAvailable : builder.query<number, number>({
        //     query: () => `/account/storage/available`,
        //     keepUnusedDataFor: 5,              
        // }),

        // getAccountStoragUsed : builder.query<number, number>({
        //     query: () => `/account/storage/used`,
        //     keepUnusedDataFor: 5,              
        // }),
    })

});

export const {
    // useGetAllLicensesQuery,
    // useGetAccountLicensesQuery,
    // useAddLicenseMutation,
    // useGetAccountStoragAvailableQuery,
    // useGetAccountStorageAllowanceQuery,
    // useGetAccountStoragUsedQuery,
} = cartsApi