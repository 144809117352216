/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import { useDrop } from 'react-dnd'
import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice'
import { DraggableImage, DroppableImageVideoZone} from '../EditorComponents'
import { Media, Medias, defaultThumbnails } from '../../../../redux/slices/media/mediaModels'
import { Element, Source, defaultContentThumbnail, defaultContentBackgrounds, getBgTemplateSize, templateColorPallets, validateURL, IMAGEDURATION } from '../../../../redux/slices/content/contentModels'
import { useAppDispatch, useAppSelector } from '../../../../redux/hook'
import { getCorrespondHeight, getCorrespondWidth, getSectColorCodes, getSectionIcon } from '../../../../redux/slices/editor/editorModels'
import { decode as decodeBase64 } from 'base-64'
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte"
import { IconButton, InputAdornment, makeStyles, TextField } from '@mui/material'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { setContentSource, setHoverSectionId, setSectionId } from '../../../../redux/slices/content/contentSlice'
//----------------------------------------

type Props = {
  className: string,  
  section: Element,
  sectionId: string
}

type Website = {  
  url: string
}

// const useHelperTextStyles = makeStyles(() => ({
//   color: "#168168",
//   root: {
//       // color: TextFieldColours.error["status-text"]
//       color: "#168168"
//   }
// }));


const schema = yup.object().shape({
  url:  yup.string()
        .matches(
            // /^((https?):\/\/)(www.)?[a-zA-Z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            // /^((https?):\/\/)(www.)?[a-zA-Z0-9]+(\.[a-z]{2,}){1,3}(#?\/?\.?\=?[a-zA-Z0-9#_\-%&]*)*$/,
            // /^((https?):\/\/)(www.)?[a-zA-Z0-9]+(\.[a-z]{2,}){1,3}.*$/,
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
            'Enter correct URL! (e.g., http://........ or https://........)'
        )
        .min(7)
        .required('Please enter website')  
})

//----------------------------------------
const WebsiteZone: React.FC<Props> = ({className, section, sectionId}) => { 
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  
  const currEditorW = useAppSelector(state => state.editor.currEditorWidth)
  const currEditorH = useAppSelector(state => state.editor.currEditorHeight)
  const currEditorZoom = useAppSelector(state => state.editor.zoomPercentage)

  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  const unselectedSectIds = useAppSelector(state => state.content.unselectedSectionIds)  
  const currSectId = useAppSelector(state => state.content.currSectionId)  
  const currLayerId = useAppSelector(state => state.content.currLayerId)  
  const hoverSectId = useAppSelector(state => state.content.hoverSectionId)  
  const hoverLayerId = useAppSelector(state => state.content.hoverLayerId)  
  const hoverLayerSourceId = useAppSelector(state => state.content.hoverLayerSourceId)  

  const [addWeb, setAddWeb]= useState<boolean>(false)
  
  const { 
    register, 
    setValue,
    handleSubmit,
    // control,
    reset,
    watch,
    setError,
    formState:{
        errors,
        isDirty,
        
        isLoading,         
        isValid,   
        isSubmitting,
        isSubmitSuccessful
    }
  } = useForm<Website>({
      resolver: yupResolver(schema),
      defaultValues:{                      
        url: "https://",           
      }
  }) 

  //---------------------------------------------------- 

  const formSubmitHandler: SubmitHandler<Website> = async (websit: Website) => {        

    // console.log("currContentPersistor",currContentPersistor)
    // console.log("section in Webzone",section)
    // console.log("websit",websit)

    if (! validateURL(websit.url)){
      Swal.fire(
        '<div class="text-primary fs-2 mt-3">'+websit.url+'</div><br/>The input URL is not correct.',
        // 'It must be started with http:// or https://',
        'error'
      ) 
    }else{
      try{        

        var sourceList: Source[] = []
        section.sources.map((src)=>{
          sourceList = [...sourceList, {...src, order: src.order+1}]
        })
        // var sourceList: Source[] = section.sources? section.sources:[]    
        // console.log("sourceList before: ",sourceList)

        // let srcDuration = IMAGEDURATION
        // if (itemList && itemList[0].type === "video"){
        //   srcDuration = itemList[0].duration
        // }

        var newSource: Source = {
          source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
          element_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
          // element_id: (1).toString().padStart(2, '0'),
          source_id: '0',
          // source_id: itemList?itemList[0].source_id:"",      
          order:  0,
          duration_override: -1,      
          param_source : [{
            delay   : 0,
            duration: IMAGEDURATION,
            type    : "web",
            url     : websit.url,
            view_mode: "desktop"
          }],
          source:  null,
        }

        sourceList = [ newSource, ...sourceList] //do not use push
        // console.log("sourceList after: ",sourceList)

        dispatch(setSectionId(section.element_id))  //which section it belongs to
        dispatch(setContentSource(sourceList))

        reset({url:"https://"})

      }catch(error){
        Swal.fire(
          'Website: '+ websit.url,
          'There is something wrong when adding a new website!',
          'error'
        )            
      }
    }
  }
  //-----------------------------------------
  return (
      <div 
        key={section.element_id}
        onMouseOver={()=> {
          setAddWeb(true)
          dispatch(setHoverSectionId(section.element_id))
        }}
        onMouseOut={()=> {
          setAddWeb(false)
          dispatch(setHoverSectionId(""))
        }}
        style={{              
          position:'absolute',          
          width:`${getCorrespondWidth(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,
          height:`${getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,          
          left:`${getCorrespondWidth(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
          top:`${getCorrespondHeight(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,

          background: `${section.sources.length ? ``:`no-repeat ${getBgTemplateSize(section.w, section.h)} ${templateColorPallets[2]} url(${defaultThumbnails[2]})`}`,
          // zIndex: `${section.element_id === hoverSectId && section.source_id === hoverLayerSourceId ? "1":"0"}`
          
        }}        
        className={`${className} border 
          ${section.sources.length ? 'border-0 border-gray-300':'border-1 border-gray-500'}
          ${unselectedSectIds.length>0 && unselectedSectIds.includes(section.element_id) ? "d-none":""}
        `}
      >          
        {section.sources.length> 0 &&
          // <iframe 
          //     src={section.sources[0].param_source[0].url}                    
          //     className={`border w-100 h-100
          //       ${section.element_id === hoverSectId ? "section-outline-hover-success":"border-0"} 
          //     `}
          //     scrolling='no'
          // ></iframe>
          <div 
              className={`w-100 h-100 position-absolute`}
              // style={{
              //   // zIndex: `${section.element_id === hoverSectId && item.source_id === hoverLayerSourceId ? "1":"0"}`,
              //   background: `url("${defaultContentWebBackground}") no-repeat contain center`
              // }}                                
            >
              <img 
                className={`border w-100 h-100 opacity-100
                `}
                style={{position:"absolute", top:"0"}}
                src={`${defaultContentBackgrounds[0]}`}
                srcSet={`${defaultContentBackgrounds[0]}`}
                loading="lazy"
              />
              {/* <div className='text-white mt-10'>
              {section.sources[0].param_source[0].url} 
              </div> */}
            </div>
        }        

        {/* hover part */}
        <div 
          className={`border w-100 h-100 position-absolute top-0 start-0 cursor-pointer
            section-outline-hover-primary
          `}
          title={section.sources.length <= 0 ? "Add URL":"Add more"}

          style={{background: `#ffffff66 ${section.sources.length <= 0? "": "url('/media/_editor/defaultWebIcon-white.png') center center / 35% no-repeat"}`}}
          hidden={!addWeb}
        >
          <form id="frm-add-content-web" className='form h-100' onSubmit={handleSubmit(formSubmitHandler)}>
            <div className='h-100 d-flex justify-content-center top-50 align-items-center text-center'>
              <div className='w-75'>                    
                <TextField 
                  {...register("url")}                        
                  label="URL" 
                  placeholder='Enter your website: https://...'
                  fullWidth 
                  margin='dense'
                  variant='outlined' 
                  autoComplete='off'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: 
                    (
                        <button type="submit" className='btn btn-sm btn-primary p-4 px-5 rounded-right tooltip-bottom'
                          style={{marginRight:"-14px", borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                          data-tooltip-content={section.sources.length <= 0 ? "Add URL":"Add more"}                            
                        >
                          <KTSVG path='/media/icons/duotune/arrows/arr013.svg' className='svg-icon-2x m-0'/>
                        </button>
                    ),
                  }} 
                  // FormHelperTextProps={{
                  //   classes:{
                  //     root:useHelperTextStyles
                  //   }
                  // }}                      
                  error={!! errors.url}
                  helperText={`${errors.url?.message ?? ''}`}
                />
              </div>
            </div>
          </form>
        </div>

        {/* Label of each section */}
        <div className='position-absolute top-0 d-flex justify-content-between'>
          <div 
            className={`py-1 px-2 fw-light fs-9 text-white opacity-75              
              ${section.element_id === hoverSectId ? "bg-primary": `bg-${getSectColorCodes(section.element_type)}`} 
            `} 
            style={{borderBottomRightRadius:"5px"}}
          >
            <span className='w-20px'>
              {getSectionIcon(section.element_type, "text-white")}                      
            </span>                    
            <span className='fs-8 pt-2 text-truncate w-150px'>{section.element_id} [{Math.floor(section.w*1.5)}x{Math.floor(section.h*1.5)}]</span>                    
          </div>
        </div>                
      </div>
  )     
}

export {WebsiteZone}