import {FC} from 'react'

const ContactUs: FC = () => {
    
    window.location.href = "https://superbolt.com.au/?page_id=79";
    
    return (
        
     <></>
    )
}

export {ContactUs}
