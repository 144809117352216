import {FC, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

const DownloadApp: FC = () => {
    window.location.href = "https://superbolts3-public.s3.ap-southeast-2.amazonaws.com/data/app/superbolt.apk";

    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(()=>{
            navigate( -1 );
        }, 500)
        
    },[])
    return (
     <></>
    )
}

export {DownloadApp}
