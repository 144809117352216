import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Logout, AuthPage, useAuth} from '../../../auth'
import useContentful from '../../components/useContentful'
import Slider from 'react-slick'
type Props = {
    pageName?: string
    }

const Footer: React.FC<Props> = ({pageName}) => {
    //--------------------------------
    return (
    <>
        <div className="mt-10 mb-n20 position-relative z-index-2">
            <div className="container">
                <div className="text-center mb-17">
                    {/* <h3 className="fs-2hx text-dark mb-5" id="clients" data-kt-scroll-offset="{default: 125, lg: 150}">What Our Clients Say</h3>
                    <div className="fs-5 text-muted fw-bold">Save thousands to millions of bucks by using single tool
                    <br />for different amazing and great useful admin</div> */}
                </div>
                
                <div className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13" style={{background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%)"}}>
                    <div className="my-2 me-5 mb-xs-9 mb-sm-9">
                        <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">Start With Superbolt Today!
                        {/* <span className="fw-normal">Speed Up Development!</span> */}
                        </div>
                        <div className="fs-6 fs-lg-5 text-warning fw-semibold opacity-75">Connect with Your Customers Effortlessly, Let's Get Started!</div>
                        {/* <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">Just a few click away, Let's Get Connected with Your Customers.</div> */}
                    </div>
                    <div className="text-md-end">
                        <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">Would You Need a Custom Project?
                            {/* <span className="fw-normal">Speed Up Development!</span> */}
                        </div>
                        <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">
                            Use Our Custom Development Service.
                            {/* <a href="https://superbolt.com.au/contact" className="text-white opacity-50 text-hover-primary">Contact Us </a> */}
                        </div>
                        <a href="https://superbolt.com.au/contact" className="btn btn-lg btn-outline text-hover-primary border-2 btn-outline-white flex-shrink-0 mt-4">Click to Get a Quote</a>
                    </div>
                </div>
            </div>
        </div>
        {!pageName &&

        
        <div className="mb-0">
        {/* <div className="mt-n20 mb-0"> */}
            <div className="landing-curve landing-darker-color"  
                // style={{color:"#41335B"}}
                // style={{background: "url('media/_background/wave.svg') no-repeat center center / cover"}}
            >
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
                </svg>
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#150233dd" fill-opacity="1" d="M0,32L30,58.7C60,85,120,139,180,176C240,213,300,235,360,250.7C420,267,480,277,540,250.7C600,224,660,160,720,128C780,96,840,96,900,106.7C960,117,1020,139,1080,154.7C1140,171,1200,181,1260,160C1320,139,1380,85,1410,58.7L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
                </svg> */}
            </div>
            <div className="landing-darker-bg pt-20">
            {/* <div className="landing-darker-bg pt-0" style={{backgroundColor:"#43335B"}}> */}
                <div className="container-fluid">
                    {/* <div className="row py-10 pt-lg-20 pb-0"> */}
                    <div className="row py-10 pt-lg-0 pb-0">
                        <div className="col-12"
                            // style={{background: "url('media/_background/wave2.svg') no-repeat center center / cover"}}
                            // style={{background: "url('media/_landing_banners/footer-silhouette-003.png') repeat-x bottom center / contain"}}
                            
                        >
                            <div className="container">
                                <div className="row py-10 py-lg-20">
                                    <div className="col-lg-5 pe-lg-16 mb-10 mb-lg-0">
                                        <div className="rounded landing-darker-border p-9 mb-10">
                                            <h2 className="text-white">Would you like any SUPPORT?</h2>
                                            
                                            <a href="https://superbolt.com.au/contact" className="text-white opacity-50 text-hover-info">
                                            <span className="fw-normal fs-4 text-gray-700">Contact us</span>
                                            </a>
                                        </div>
                                        <div className="rounded landing-darker-border p-9">
                                            <h2 className="text-white">How about a DEMO?</h2>
                                            <span className="fw-normal fs-4 text-gray-700">Let's us know if you're interested. <br/>
                                                <a 
                                                    href="https://superbolt.com.au/contact" 
                                                    // id="kt_engage_demos_toggle" 
                                                    className="text-white opacity-50 text-hover-info" 
                                                    // className="engage-demos-toggle text-white opacity-50 text-hover-primary" 
                                                    // data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click" data-bs-trigger="hover"
                                                >Click to Request for a Demo</a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-2 ps-lg-0">
                                        <div className="d-flex fw-semibold flex-column me-0">
                                            <h4 className="fw-bold text-gray-400 mb-6">More for Superbolt</h4>
                                            <a href="/about-us" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>About Us</a>
                                            <a href="/products" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>Our Products</a>
                                            <a href="/services" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>Our Services</a>
                                            <a href="/industries" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>Industries</a>                                    
                                        </div>
                                    </div>                                    
                                    <div className="col-sm-6 col-md-4 col-lg-2 mt-8 mt-sm-0 ps-lg-0">
                                        <div className="d-flex fw-semibold flex-column me-0">
                                            <h4 className="fw-bold text-gray-400 mb-6">Customer Support</h4>
                                            <a href="/faqs" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>FAQs</a>                                    
                                            <a href="/terms-conditions" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>Terms &amp; Conditions</a>
                                            <a href="/privacy-policy" className="text-white opacity-50 text-hover-info fs-5 mb-4"><i className=" fa fa-caret-right me-2"></i>Privacy Policy</a>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-3 mt-8 mt-md-0 ps-lg-0">
                                        <div className="d-flex fw-semibold flex-column ms-0" id="kt_contact_us">
                                            <h4 className="fw-bold text-gray-400 mb-6">Contact Us</h4>
                                            <div className="d-flex text-white opacity-50 text-hover-info fs-5 mb-4">
                                                <i className="bi bi-house-fill mt-1 me-3"></i>
                                                <address>24 Industrial Dr,<br/>
                                                Pakenham, VIC 3810<br/>
                                                Australia</address>
                                            </div>
                                            <a className="mb-4"href="https://superbolt.com.au/contact">                                                
                                                <span className="text-white opacity-50 text-hover-info fs-5 mb-6"><i className="bi bi-envelope-fill me-3"></i>Support@superbolt.com.au</span>
                                            </a>                                            

                                            {/* <h4 className="fw-bold text-gray-400 mt-10 mb-6">Stay Connected</h4>
                                            <a href="/faqs" className="mb-4">
                                                <img src={toAbsoluteUrl("/media/svg/brand-logos/facebook-4.svg")} className="h-20px me-2" alt="" />
                                                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>
                                            </a>
                                            <a href="https://www.instagram.com/keenthemes" className="mb-4">
                                                <img src={toAbsoluteUrl("/media/svg/brand-logos/instagram-2-1.svg")} className="h-20px me-2" alt="" />
                                                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Instagram</span>
                                            </a> */}
                                            {/* <a href="https://github.com/KeenthemesHub" className="mb-6">
                                                <img src={toAbsoluteUrl("/media/svg/brand-logos/github.svg")} className="h-20px me-2" alt="" />
                                                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Github</span>
                                            </a>
                                            <a href="https://twitter.com/keenthemes" className="mb-6">
                                                <img src={toAbsoluteUrl("/media/svg/brand-logos/twitter.svg")} className="h-20px me-2" alt="" />
                                                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>
                                            </a>
                                            <a href="https://dribbble.com/keenthemes" className="mb-6">
                                                <img src={toAbsoluteUrl("/media/svg/brand-logos/dribbble-icon-1.svg")} className="h-20px me-2" alt="" />
                                                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Dribbble</span>
                                            </a> */}                                            
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="landing-darker-separator"></div>
                <div className="landing-darkest-bg" id="footer-bottom">
                    <div className="container">
                        <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                            <div className="d-flex align-items-center order-2 order-md-1">
                                <a href="/">
                                    <img alt="Logo" src={toAbsoluteUrl("/media/logos/custom-1.png")} className="h-35px h-md-35px" />
                                </a>
                                <a className="mx-5 fs-6 fw-semibold text-gray-300 pt-1" href="/">&copy; 2023, All rights reserved.</a>
                            </div>
                            <ul className="menu menu-gray-300 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                <li className="menu-item">
                                    <a href="/about-us" target="_self" className="menu-link px-2">About Us</a>
                                </li>
                                {/* <li className="menu-item">
                                    <Link to="/services" target="_self" className="menu-link px-2">Services</Link>
                                </li>                                 */}
                                <li className="menu-item">
                                    <a href="https://superbolt.com.au/contact" target="_superbolt" className="menu-link px-2">Contact Us</a>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
            <span className="svg-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                    <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                </svg>
            </span>
        </div>
        
    </>
  )
}

export {Footer}
