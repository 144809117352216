import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {checkIsActive, getCurrentUrl, reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'

import 'react-tooltip/dist/react-tooltip.css'
import { AsideDefault } from './components/aside/AsideDefault'
import { useAppSelector, useAppDispatch } from "../../app/redux/hook";
import { disableEditorMode, enableEditorMode } from '../../app/redux/slices/editor/editorSlice'
import { Tooltip } from 'react-tooltip'
import { DrawerChangePassword } from '../../app/modules/accounts/components/staff/drawers/ChangePassword'
import { DrawerSetupMFA } from '../../app/modules/accounts/components/staff/drawers/SetupMFA'
//import HookMqtt from '../../app/commons/mqtt/components/Hook'

const MasterLayout = () => {
  const isEditor = useAppSelector(state => state.editor.editorModeOn)
  const dispatch = useAppDispatch()
  //----------------------------------------------------------------------
  const location = useLocation()
  const EDITOR_PATH : string | undefined  = process.env.REACT_APP_CONTENT_EDITOR_URL   

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  useEffect(() => {
    // console.log("getCurrentUrl(location.pathname) = "+ getCurrentUrl(location.pathname))        
    // console.log("EDITOR_PATH = "+ EDITOR_PATH)
    if ([EDITOR_PATH, EDITOR_PATH+"/index", EDITOR_PATH+"index"].includes(getCurrentUrl(location.pathname))){
      dispatch(enableEditorMode())
      // console.log("enableEditorMode")
    }else{      
      dispatch(disableEditorMode())
      // console.log("disableEditorMode")
    }    
  }, [location])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        {isEditor===false && 
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper />
              <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                <Sidebar />
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                  <div className='d-flex flex-column flex-column-fluid mb-18'>
                    <ToolbarWrapper />
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  <FooterWrapper />
                </div>
              </div>
            </div>
          </div>          
        }

        {/* Theme for Content Editor */}
        {isEditor===true &&
          <div className='page d-flex flex-row flex-column-fluid' >
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        }


        {/* begin:: Drawers */}
        <ActivityDrawer />
        <DrawerChangePassword />
        <DrawerSetupMFA />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
        {/*<HookMqtt />*/}
        {/*Custom Tooltip wrappers */}
        <Tooltip anchorSelect=".tooltip-bottom" 
          className="bg-light-warning border border-light-gray text-gray-600"
          place='bottom'
          style={{zIndex:"2000"}}
        />
        <Tooltip anchorSelect=".tooltip-top" 
          className="bg-light-warning border border-light-gray text-gray-600"
          place='top'
          style={{zIndex:"2000"}}
        />
        <Tooltip anchorSelect=".tooltip-left" 
          className="bg-light-warning border border-light-gray text-gray-600"
          place='left'
          style={{zIndex:"2000"}}
        />
        <Tooltip anchorSelect=".tooltip-right" 
          className="bg-light-warning border border-light-gray text-gray-600"
          place='right'
          style={{zIndex:"2000"}}
        />  

      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
