import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  titleShort: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  hasMarginTop?: boolean
  hasMarginBottom?: boolean
  hasBorderTop?: boolean
  hasBorderBottom?: boolean
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  titleShort,
  icon,
  fontIcon,
  hasBullet,
  hasMarginTop= false,
  hasMarginBottom= false,
  hasBorderTop= false,
  hasBorderBottom = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      {hasBorderTop && <div className="border-top-space" style={{borderTop:"1px dashed #393945"}}></div>}
      {hasMarginTop && <div className='menu-item-space pt-5'></div>}
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          // <span className='menu-icon'>
          //   <KTSVG path={icon} className='svg-icon-2 ' />
          // </span>
          <div className='menu-icon-block d-flex flex-column flex-center'>
          <span className='menu-icon'>
            {' '}
            <KTSVG path={icon} className='svg-icon-2 icon-success' />
          </span>
          <div className='menu-title-bottom fs-8 fw-normal mt-0'>{titleShort}</div>
        </div>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        {/* <span className='menu-title text-success'>{title}</span> */}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
      {hasMarginBottom && <div className='menu-item-space pb-5'></div>}
      {hasBorderBottom && <div className="border-bottom-space" style={{borderBottom:"1px dashed #393945"}}></div>}    
    </div>
  )
}

export {SidebarMenuItemWithSub}
