/* eslint-disable react/jsx-no-target-blank */
import {FC, useEffect, useState} from 'react'

import { useGetAccountStoragAvailableQuery, useGetAccountStoragUsedQuery, useGetAccountStorageAllowanceQuery } from '../../../../app/redux/slices/license/apiLicenseSlice'
import { formatBytes } from '../../../../app/redux/slices/content/contentModels'

const SidebarFooter = () => {
  const {data: accountStorageAllowance} = useGetAccountStorageAllowanceQuery(0);
  const {data: accountStorageAvailable} = useGetAccountStoragAvailableQuery(0);
  const {data: accountStorageUsed} = useGetAccountStoragUsedQuery(0);  
  const [styleColor, setStyleColor] = useState('success')

  useEffect(()=>{
    if(accountStorageUsed && accountStorageAllowance)
    {
      if((accountStorageUsed / accountStorageAllowance)*100 > 70)
        setStyleColor('warning')
      if((accountStorageUsed / accountStorageAllowance)*100 > 90)
        setStyleColor('danger')
    }
  }, [accountStorageUsed, accountStorageAvailable])
  
  
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      
      {accountStorageUsed && accountStorageAllowance &&
        <div className='storage-usage d-flex flex-column w-100 me-2' id="">
          {/* <div className='d-flex flex-stack mb-2'>
            <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
          </div> */}
          <div className="storage-usage-title text-success d-flex align-items-center justify-content-between mb-2" >
              <span className="mr-2 text-success font-size-sm font-weight-bold">
                  <i className="fa fa-cloud text-success me-2"></i>Storage
              </span>            
          </div>
         
          <div className="storage-usage-info d-flex align-items-center justify-content-between mb-2">
            <span className={`text-${styleColor} mr-2 font-size-sm font-weight-bold`}>
                {((accountStorageUsed*100)/accountStorageAllowance).toFixed(1)}%
            </span>
            <span className="text-muted font-size-sm font-weight-bold">
              <span className="storage-usage-of text-muted font-size-sm font-weight-bold">
              {formatBytes(accountStorageUsed)} used of{' '}
              </span>              
              {formatBytes(accountStorageAllowance)} 
              <span className="storage-usage-of text-muted font-size-sm font-weight-bold ms-1">
              
              </span>
            </span>            
          </div>
          
          <div className='storage-usage-progress-bar progress bg-gray-500 h-6px w-100'>
            <div
              className={`progress-bar bg-${styleColor}`}
              role='progressbar'
              style={{width: `${((accountStorageUsed*100)/accountStorageAllowance).toFixed(1)}%`}}
            ></div>
          </div>
        </div>
      }

    </div>
    
  )
}



const StorageUsage: FC = () => {
  
 
  // --------------------------------------
  return (
    <>    
      
    </>      
  )
}

// const StorageUsage: FC = () => (
//     <div className='mb-md-5 mb-xl-10'>
//       <CardsWidget20
//         className='h-md-50 mb-5 mb-xl-10'
//         description='Active Projects'
//         color='#F1416C'
//         img={toAbsoluteUrl('/media/patterns/vector-1.png')}
//       />
//     </div>    
// )

// const SidebarFooter = () => {
//   return (
//     <>      
//       <StorageUsage />
//     </>
//   )
// }

export {SidebarFooter}
