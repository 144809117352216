/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../../../redux/hook";

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { Box, TextField } from '@mui/material';
import { setContentName } from '../../../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { useUpdateContentMutation } from '../../../../../../../redux/slices/content/apiContentSlice';
import { ContentInput, secondsToHHmmss } from '../../../../../../../redux/slices/content/contentModels';
import { DrawerAddInstantSchedule } from '../../../../../../schedule/components/ScheduleComponents';

//----------------------------------------

type Props = {
  className?: string,
  title?: string,
}

//----------------------------------------
export const BtnInstantPlay: React.FC<Props> = ({className="btn-icon btn-sm btn-active-color-primary", title}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------  
  // const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
  
  // ---------------------------------------
  

  // ---------------------------------------
  // useEffect(() => {
  //   // console.log("currPersistContent - in useEffect", currPersistContent)    
  //   setContName(currPersistContent.user_template_name)

  // }, [currPersistContent])

  //-----------------------------------------
  return (<> 
    
      {/* <a  href="#" 
        data-bs-toggle="offcanvas"
        aria-controls="staticBackdrop"
        data-bs-target="#kt_drawer_instant_play"
        
        id="kt_drawer_instant_play_button"
        data-kt-drawer-show="true" 

        // onClick={() => { dispatch(setContent(content)); }}     
        
        // onClick={()=> {                  
        //   closeFullscreen()
        //   setFullScreenMode(false)
        // }}
      > */}
        <button 
          type='button'
          className={`btn tooltip-bottom ${className}`}
          data-tooltip-content={`Instant Play`}
          // data-bs-dismiss="modal"
          // aria-label="Close"

          data-bs-toggle="offcanvas"
          aria-controls="staticBackdrop"
          data-bs-target="#kt_drawer_instant_play"
          
          id="kt_drawer_instant_play_button"
          data-kt-drawer-show="true"
        >
          {title 
            ? <>
                <i className='bi bi-lightning-fill fs-4'></i>
                <span className='d-none d-xl-inline ms-2'>{title}</span>
              </>
            : <i className="bi bi-lightning-fill fs-3"></i>
          }
        </button>
      {/* </a> */}
   
  </>)
}
