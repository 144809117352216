/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-gray-200'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='https://superbolt.com.au' className='px-5'>
              Home
            </a>
            {/* <a href='/terms' className='px-5' target='_blank'>
              Terms
            </a>
            <a href='/plans' className='px-5' target='_blank'>
              Plans
            </a> */}            
            <a href='https://superbolt.com.au/contact/' className='px-5'>
              Contact Us
            </a>
          </div>
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-screens-bg.jpg')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-5 px-5 px-md-5 w-100'>
          {/* begin::Logo */}
          <a href='https://superbolt.com.au' className='px-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1-white.png')} 
            className='mx-auto w-400px w-md-600px px-20 px-xxl-20  mb-10 mb-lg-20' 
            />
          </a>
          <h1 className=' text-white' style={{fontSize: "28px"}}> Create, schedule, publish.</h1>
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            {/* Fast, Efficient and Productive */}
            
            <span style={{background: "linear-gradient(to right, #FFD80C 0%, #12CE5D 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>
            <span id="kt_landing_hero_text">   Lightning Fast.</span>
            
          </span>
          
          </h1>
          {/*
          <div className='d-none d-md-inline d-lg-inline d-xl-inline text-white fs-base text-center pb-15 px-5 px-md-15'>
            A leading provider of innovative
            <span className='opacity-75-hover text-warning fw-bold'> digital signage solutions </span>,<br />
            empowering businesses to 
            <span className='opacity-75-hover text-warning fw-bold'> enhance their brand visibility </span>
            and<br />
            <span className='opacity-75-hover text-warning fw-bold'> engage with customers </span>
            through 
            <span className='opacity-75-hover text-warning fw-bold'> dynamic</span> and
            <span className='opacity-75-hover text-warning fw-bold'> captivating visual experiences</span>
            .<br />            
          </div>
          */}
          {/* begin::Image */}
          <img
            className='mx-auto w-375px w-md-100 w-xl-500px w-xxl-100 px-0 px-xxl-20  mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens-01.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          
          
          {/* end::Title */}

          
          
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
