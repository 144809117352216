import {createClient} from 'contentful'

const useContentful = () => {
    const client = createClient({
        space: 'a3hagon1cd2u',            
        // accessToken: 'VE9L-qCnTXkcEN44-f9tDEIt5yiAqKSZYS_w6THxyIQ',
        // environment: 'master', // defaults to 'master' if not set
        accessToken: 'dQnwY9f9DJbza__XQvTJlc7n_98Wv0nK3Cvb9Cnapkg',
        // accessToken: 'EYhvlmlalp-X3fp2lLHWNUWI95jheRsszf4nKyciTBg',
        host: 'preview.contentful.com'
    });

    const getSections = async() =>{
        // event.preventDefault();
        //--------------------------------
        try{
            const entries = await client.getEntries({
                content_type: "homeMainMenu",
                select: "fields"
            });

            // console.log("entries");
            // console.log(entries);
            return entries;

            // const sanitizedEntries = entries.items.map((item)=>{
            //     // const 
            //     return {
            //         ...item.fields
            //     }
            // });
            // return sanitizedEntries;

        }catch (error){
            console.log(`Error message: ${error}`);
        }
    };


    const getBanners = async() =>{
        // event.preventDefault();
        //--------------------------------
        try{
            const entries = await client.getEntries({
                content_type: "homeBanner",
                select: "fields"
            });

            // console.log("entries");
            // console.log(entries);
            return entries;
        }catch (error){
            console.log(`Error message: ${error}`);
        }
    };

    return {
        getBanners,
        getSections
    }; 

    // const getBlogPosts = () => client.getEntries().then(response => response.items)
    // const getSinglePost = slug =>
    // client.getEntries({
    //     'fields.slug': slug,
    //     content_type: 'blogPost'
    //     })
    //     .then(response => response.items)

    // export { getBlogPosts, getSinglePost }

}
export default useContentful;