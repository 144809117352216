
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useCreateSubAccountMutation } from '../../../redux/slices/account/apiAccountSlice'
import Swal from 'sweetalert2'
import { subAccount } from '../../../redux/slices/account/accountModel'
import { TextField } from '@mui/material'

type Props = {
    className?: string,
}

const schema = yup.object().shape({
    firstName: yup.string()
        .min(2, 'Minimum 2 characters')
        .max(50, 'Maximum 50 characters')
        .required('First name is required'),
    lastName: yup.string()
        .min(2, 'Minimum 2 characters')
        .max(50, 'Maximum 50 characters')
        .required('Last name is required'),
    email: yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Email is required'),

})

const DrawerSubAccount: FC<Props> = ({ className }) => {
    const [loading, setLoading] = useState(false)
    const {
        register,
        reset,
        handleSubmit,
        formState: {
            errors,
            isSubmitting,
            isValid,

        }
    } = useForm<any>({
        resolver: yupResolver(schema),
    })
    const [createSubAccount] = useCreateSubAccountMutation();
    const formSubmitHandler: SubmitHandler<subAccount> = async (data: subAccount) => {
        try {
            setLoading(true);

            const result: any = await createSubAccount(data)
            if (result.data.code == 201 || result.data.code == 200) {
                Swal.fire(
                    'Complete',
                    'Sub account created.',
                    'success'
                )
                reset()
            } else {
                Swal.fire(
                    'Error',
                    result.data.message,
                    'error'
                )
            }
            setLoading(false)
        } catch (error) {
            Swal.fire(
                'Error ',
                'There is something wrong during the process!',
                'error'
            )
        }
    }

    return (
        <>
            <div
                id="kt_drawer_create_subaccount"
                data-kt-drawer-toggle={`#kt_drawer_create_subaccountbutton`}
                data-kt-drawer-close={`#kt_drawer_create_subaccountclose`}
                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
            >
                <div className="offcanvas-header bg-light-primary">
                    <h5 className="offcanvas-title text-primary" id="staticBackdropLabel">Create sub account</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body text-start">
                    <div className='row'>
                        <form id="frm-ticket" className='form'
                            onSubmit={handleSubmit(formSubmitHandler)}
                        >
                            <div className="mb-4">
                                <TextField
                                    {...register("firstName")}
                                    label="First Name"
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    margin='dense'
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}

                                    error={!!errors.firstName}
                                    helperText={`${errors.firstName?.message ?? ''}`}
                                />

                            </div>
                            <div className="mb-4">
                                <TextField
                                    {...register("lastName")}
                                    label="last Name"
                                    required
                                    fullWidth
                                    autoComplete='off'
                                    margin='dense'
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}

                                    error={!!errors.lastName}
                                    helperText={`${errors.lastName?.message ?? ''}`}
                                />

                            </div>
                            <div className="mb-4">
                                <TextField
                                    {...register("email")}
                                    label="Email"
                                    required
                                    type='email'
                                    fullWidth
                                    autoComplete='off'
                                    margin='dense'
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}

                                    error={!!errors.email}
                                    helperText={`${errors.email?.message ?? ''}`}
                                />

                            </div>
                            <div className="pt-8 text-end">
                                <button type="submit" className="btn btn-primary"
                                    data-bs-dismiss="offcanvas"
                                    data-kt-drawer-dismiss="true"
                                    disabled={isSubmitting || !isValid}
                                >
                                    {loading
                                        ?
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        :
                                        <span><i className="fa fa-paper-plane me-1 mt-n1"></i>Submit</span>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DrawerSubAccount }