import { useDeleteContentMutation, useGetAllPlaylistsQuery } from '../../../../redux/slices/content/apiContentSlice';
import { CardContent } from '../../../content/components/cards/CardContent';
import React, { SyntheticEvent, useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../../app/commons/css/customGlobal.css"

import { useAppSelector, useAppDispatch } from "../../../../redux/hook";
import { AssociativeArray, Content, ContentInput, EleInput, getRibbonType, Source, toCapitalize } from '../../../../redux/slices/content/contentModels';

import {
  Autocomplete,
  TextField,
  InputAdornment,
  Chip,
  Box,
  Tab,
  Tabs,
  tabsClasses,
  Badge,
} from '@mui/material';
import { useGetAllSchedulesQuery } from '../../../../redux/slices/schedule/apiScheduleSlice';
import { extractContentInUse } from '../../../../redux/slices/schedule/scheduleModels';
import { useParams } from 'react-router-dom';
import { setSelectedContentIds } from '../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { TabContext, TabPanel } from '@mui/lab';



type Props = {
  className: string,
  inView: boolean,
}

const TablePlaylists: React.FC<Props> = ({className, inView}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------

  const { data: allPlaylists, isLoading, isFetching} = useGetAllPlaylistsQuery()  
  // console.log("allPlaylists:",allPlaylists)
  const currActiveSchedIds = useAppSelector(state => state.persistor._schedule.currActiveSchedIds) // for just pure a list of all sched

  const selectedContentIds = useAppSelector(state => state.content.selectedContentIds)
  // console.log("selectedContentIds:",selectedContentIds)
  
  const flatProps = {
    options: !allPlaylists? [] : allPlaylists.map((option, ind) => {
      return (option.user_template_name.charAt(0).toUpperCase() + option.user_template_name.slice(1))
    })
  };

  const {id} = useParams()
  const [searchKeyword, setSearchKeyword ] = useState<string>(id? id:"")
  // -------------------------------------------------
  
  const [filteredPlaylists, setFilteredPlaylists] = useState<Content[]>([])
  const [landscapePlaylists, setLandscapePlaylists] = useState<Content[]>([])
  const [portraitPlaylists, setPortraitPlaylists] = useState<Content[]>([])
  const [activePlaylists, setActivePlaylists] = useState<Content[]>([])
    
  // console.log("filteredPlaylists", filteredPlaylists)
  // console.log("landscapeContents", landscapeContents)
  // console.log("portraitContents", portraitContents)
  // console.log("activePlaylists", activePlaylists)

  const { data: allSchedules} = useGetAllSchedulesQuery(5, { refetchOnMountOrArgChange: true }) 
  // console.log("allSchedules", allSchedules);

  const [playlistIDInUse, setPlaylistIDInUse] = useState<AssociativeArray[]>([])
  // console.log("playlistIDInUse: ", playlistIDInUse) 

  const [selectOpt, setSelectOpt]  = useState<boolean>(false) //activate the multi-select options


  //---------------------------------------------------- 
  const [tabValue, setTabValue] = useState("tab_panel_all"); 
  const handleChange = (event: SyntheticEvent<Element, Event>, newTabValue: any) => {
      setTabValue(newTabValue); 
  };
  
  
  // ---------Delete all selected campaigns--------------
  const [deleteContent] = useDeleteContentMutation();
  const deletePlaylistsHandler = async () => {  
    const selectedContents: Content[] = allPlaylists? allPlaylists.filter(cont=> (cont.user_template_id && selectedContentIds.includes(cont.user_template_id))) : []
    var tmpStr = ""
    // ----------------------
    tmpStr = `Are you sure you want to delete<br/> all these ${selectedContents.length} playlist?<br/>`
    tmpStr += '<h3 style="color:#331168; margin: 15px 0;">'
    tmpStr += (selectedContents.map(cont => toCapitalize(cont.user_template_name))).join("<br/>")
    tmpStr += '</h3>'

    Swal.fire({
        title: tmpStr,  
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#9C258F',
        cancelButtonColor: '#E4E6EF',
        confirmButtonText: 'Yes, delete all!',
        customClass: {          
          cancelButton: 'order-1 left-gap me-5',
          confirmButton: 'order-2',
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
          selectedContents.map(cont => {          
            if(cont.user_template_id) {                            
              deleteContent(cont.user_template_id)
            }
          })

          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,
          })
          Toast.fire({
              icon: 'success',
              title: `All the selected contents<br/> are successfully deleted.`,
          })
        }
    })
  }

  // ---------------------------------------
  useEffect(() => { 
    if (inView){
      const activePlist = extractContentInUse(allSchedules? allSchedules:[], currActiveSchedIds, true)
      setPlaylistIDInUse(activePlist)
    }
  }, [ allSchedules, inView ])

  // ---------------------------------------
  useEffect(() => { 
    if (inView){
      dispatch(setSelectedContentIds([]))
      setSelectOpt(false)
    }
  }, [ allPlaylists, inView ])

  // ------------------------------
  useEffect(() => { 
    // console.log("searchKeyword:",searchKeyword)
    if (allPlaylists && inView){

      setFilteredPlaylists(allPlaylists.filter((playlist, ind)=>  { return  (playlist.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())) }))
      setLandscapePlaylists(allPlaylists.filter((playlist, ind)=> { return  (playlist.width >= playlist.height) && (playlist.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase()))}))
      setPortraitPlaylists(allPlaylists.filter((playlist, ind)=>  { return  (playlist.width < playlist.height) && (playlist.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())) }))
      setActivePlaylists(allPlaylists.filter((playlist, ind)=>    { return  ((playlist.user_template_id && Object.keys(playlistIDInUse).includes(playlist.user_template_id))) && ((searchKeyword === "") || (playlist.user_template_name && playlist.user_template_name.toUpperCase().includes(searchKeyword.toUpperCase())))}))
    }
  }, [ allPlaylists, searchKeyword, playlistIDInUse, inView ])

  // ---------------------------------------
  const getSettings = (arrPlaylists: Content[]) =>{
    const arrLen = arrPlaylists?arrPlaylists.length : 0

    const settings = {
      // fade: true,
      // centerMode: true,
      // className: "center",
      // centerPadding: "0px",
      dots: true,
      arrows: true,      
      autoplay: false,
      // infinite: true,      
      slidesToShow: arrLen<6? arrLen: 6,      
      slidesToScroll: arrLen<6? arrLen: 6,
      rows: arrLen<12? 1:2,
      // slidesPerRow: 6,    
      // initialSlide: 0,      
      speed: 500,
      // cssEase: "linear",
      pauseOnHover: true,   
      
      customPaging: function(i:number) {
          return  <div 
                    className="badge badge-light border border-0 p-2 px-3"
                  >
                    {i+1}
                  </div>
                  
      },
      // appendDots: function(dots: string) {
      //   return (
      //   <ul className="slick-dots" style={{ top: "20%", right: "-100%"}}>
      //   {dots.map((item, index) => {
      //   return (
      //   <li key={index} style={{ display: "list-item", paddingBottom: "2%"}}>{item.props.children}
      //   );
      //   })}
        
      //   )
      // },       
      // appendDots: function(dots: string) {
      //   return      
      //           <div
      //             style={{
      //               backgroundColor: "#ddd",
      //               borderRadius: "10px",
      //               padding: "10px"
      //             }}
      //           >
      //             <ul style={{ margin: "0px" }}> {dots} </ul>
      //           </div>
      // },
      
      responsive: [
        // {
        //   breakpoint: 1550,  //to reach all the max screen size
        //   settings: arrLen < 6 
        //     ? {
        //         unslick: true,
        //         slidesToShow: arrLen,
        //         slidesToScroll: 1,
        //         rows: 1,
        //       }
        //     : {            
        //         slidesToShow: 6,
        //         slidesToScroll: 6,
        //       }
        // },
        {
          breakpoint: 1400,
          settings: arrLen < 4 
            ? {
                unslick: true,
                slidesToShow: arrLen,
                rows: 1,
                // slidesToShow: 1, 
                // slidesToScroll: 1
              }
            : {            
                slidesToShow: 4,
                slidesToScroll: 4,
              }
        },
        {
          breakpoint: 1200,
          settings: arrLen < 4 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 4,
                slidesToScroll: 4,
              }
        },
        {
          breakpoint: 1024,
          settings: arrLen < 3 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 3,
                slidesToScroll: 1,
              }
        },
        {
          breakpoint: 768,
          settings: arrLen < 2 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            
        },
        {
          breakpoint: 480,
          settings: arrLen < 1 
            ? {
                unslick: true,
                slidesToShow: arrLen,
              }
            : {            
                slidesToShow: 1,
                slidesToScroll: 1,
              }
        }
      ]
    }

    return settings
  }
  //-----------------------------------------
  return (
    <>
    {inView &&
      <div className={`card ${className}`}>                
        {/* <div className='position-absolute w-100 h-50px d-flex justify-content-end  align-items-center pe-10'> */}
        <div className='w-100 h-50px d-flex justify-content-between align-items-center mt-8 px-10'>
          <div className='w-100 w-xs-100 w-sm-100 w-md-75 w-lg-50 w-xl-50 w-xxl-450px'>          
            <Autocomplete
              {...flatProps}              
              id="searchKeyword"
              value={searchKeyword}
              freeSolo
              selectOnFocus
              includeInputInList
              sx={{
                border: "1px solid #C4C4C4",
                borderRadius: "5px",
                padding: "5px",
                "& .MuiOutlinedInput-root": {
                    border: "2px solid #000000",
                    borderRadius: "5",
                    padding: "0"
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #eee"
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )
              }}              
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  placeholder='Search for playlist ...'
                  variant="standard" 
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    type: 'search',
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fa fa-search ms-3 me-1 text-black" aria-hidden="true"></i>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    border: "0"
                  }}
                />
              )}
              onChange={(event, value) => setSearchKeyword(value? value:"")}
            />
          </div>
          <div className='w-25 text-end ms-2'>
            <div className="btn-group btn-group-sm" role="group" aria-label="Large button group">
              <button type='button' 
                className={`d-flex btn ${selectOpt ?"btn-primary":"btn-secondary"} btn-sm py-4 py-xl-2 ps-4 pe-3 tooltip-bottom`}
                data-tooltip-content= { selectOpt && selectedContentIds
                                          ? selectedContentIds.length>0 
                                            ? "Click to unselect all" 
                                            : "Click to unselect" 
                                          : "Click to select"
                                      }
                onClick={()=> {
                  if (selectOpt){ dispatch(setSelectedContentIds([])) } //reset
                  setSelectOpt(!selectOpt)                  
                }}
              >
                <i className='fa fa-check mt-1'></i>
                <span className='d-none d-xl-flex'>
                  {selectedContentIds && selectedContentIds.length>0 ? `${selectedContentIds.length} Selected` : "Select"}
                </span>
              </button>
              <button type='button' className={`d-flex btn btn-secondary btn-sm py-4 py-xl-2 ps-4 pe-3 tooltip-bottom`}
                data-tooltip-content='Delete'
                disabled={!(selectedContentIds && selectedContentIds.length > 0)}
                onClick={()=> deletePlaylistsHandler()}
              >
                <i className='fa fa-trash mt-1'></i>                
                <span className='d-none d-xl-flex'>
                  Delete
                </span>
              </button>              
            </div>
          </div>
        </div>
        <div className='card-body pt-5 pb-8'>
          <TabContext value={tabValue}> 
            <Box sx={{ borderBottom: 1, borderColor: "#E4E6EF" }}>               
              <Tabs 
                onChange={handleChange}
                value={tabValue}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
                selectionFollowsFocus
                style={{padding: "0"}}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              > 
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "75px"}}   disabled={!(filteredPlaylists && filteredPlaylists.length>0)}   label={<Badge badgeContent={filteredPlaylists?.length} color="secondary" className='opacity-75'><span className='me-3'>All</span></Badge>}         value="tab_panel_all" /> 
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "125px"}}  disabled={!(landscapePlaylists && landscapePlaylists.length>0)} label={<Badge badgeContent={landscapePlaylists?.length} color="secondary" className='opacity-75'><span className='me-3'>Landscape</span></Badge>}  value="tab_panel_landscape" /> 
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "100px"}}  disabled={!(portraitPlaylists && portraitPlaylists.length>0)}   label={<Badge badgeContent={portraitPlaylists?.length} color="secondary" className='opacity-75'><span className='me-3'>Portrait</span></Badge>}    value="tab_panel_portrait" />
                  <Tab className="fs-5 text-hover-primary p-0" sx={{textTransform :"none", minWidth: "75px"}}   disabled={!(activePlaylists && activePlaylists.length>0)}       label={<Badge badgeContent={activePlaylists?.length} color="secondary" className='opacity-75'><span className='me-3'>Active</span></Badge>}         value="tab_panel_active" />                  
              </Tabs> 
            </Box> 
            <TabPanel value="tab_panel_all" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { filteredPlaylists && 
                  filteredPlaylists.length >= 12                    
                    ? <Slider {...getSettings(filteredPlaylists)}>     
                        {
                          filteredPlaylists.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType ={getRibbonType(content)}
                                inUse={Object.keys(playlistIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : 
                      filteredPlaylists.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType ={getRibbonType(content)}
                            inUse={Object.keys(playlistIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }       
              </div> 
            </TabPanel>
            <TabPanel value="tab_panel_landscape" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { landscapePlaylists && 
                  landscapePlaylists.length >= 12                     
                    ? <Slider {...getSettings(landscapePlaylists)}>     
                        {
                          landscapePlaylists.slice(0).reverse().map((plist)=> {return (                      
                            <div 
                              key={plist.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {plist}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                                ribbonType ={getRibbonType(plist)}
                                inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : landscapePlaylists.slice(0).reverse().map((plist)=> {return (                      
                        <div 
                          key={plist.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {plist}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                            ribbonType ={getRibbonType(plist)}
                            inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }   
                
              </div> 
            </TabPanel>
            <TabPanel value="tab_panel_portrait" className='p-1 py-5'>
              <div className="row w-100 pb-5">                
                { portraitPlaylists && 
                  portraitPlaylists.length >= 12                     
                    ? <Slider {...getSettings(portraitPlaylists)}>     
                        {
                          portraitPlaylists.slice(0).reverse().map((plist)=> {return (                      
                            <div 
                              key={plist.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {plist}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                                ribbonType ={getRibbonType(plist)}
                                inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                              
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : portraitPlaylists.slice(0).reverse().map((plist)=> {return (                      
                        <div 
                          key={plist.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {plist}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                            ribbonType ={getRibbonType(plist)}
                            inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                        
                          />
                        </div>
                      )})                      
                }                
              </div>
            </TabPanel>
            <TabPanel value="tab_panel_active" className='p-1 py-5'>
              <div className="w-100 row pb-5">
                { activePlaylists && 
                  activePlaylists.length >= 12                    
                    ? <Slider {...getSettings(activePlaylists)}>     
                        {
                          activePlaylists.slice(0).reverse().map((plist)=> {return (                      
                            <div 
                              key={plist.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {plist}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                                ribbonType ={getRibbonType(plist)}
                                inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                                                               
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : 
                      activePlaylists.slice(0).reverse().map((plist)=> {return (                      
                        <div 
                          key={plist.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {plist}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                            ribbonType ={getRibbonType(plist)}
                            inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                                                               
                          />
                        </div>
                      )})                      
                }        
              </div> 
            </TabPanel>            
          </TabContext>          
          {/* <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_all">
                <span className='ms-1 me-2'>All</span>
                <span className='badge bg-gray-400 me-1'>{filteredPlaylists?.length}</span>
              </a>              
            </li>            
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_landscape">
                <span className='ms-1 me-2'>Landscape</span>
                <span className='badge bg-gray-400 me-1'>{landscapePlaylists?.length}</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_portrait">
                <span className='ms-1 me-2'>Portrait</span>
                <span className='badge bg-gray-400 me-1'>{portraitPlaylists?.length}</span>
              </a>
            </li> 
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_active">
                <span className='ms-1 me-2'>Active</span>
                <span className='badge bg-gray-400 me-1'>{activePlaylists?.length}</span>
              </a>
            </li>
          </ul> */}

          {/* All */}
          {/* <div className="tab-content" id="myTabContent">
            <div  className="tab-pane fade show active" id="kt_tab_pane_all" role="tabpanel">
              <div className="w-100 row pb-5">
                { filteredPlaylists && 
                  filteredPlaylists.length >= 12                    
                    ? <Slider {...getSettings(filteredPlaylists)}>     
                        {
                          filteredPlaylists.slice(0).reverse().map((content)=> {return (                      
                            <div 
                              key={content.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {content}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                                ribbonType ={getRibbonType(content)}
                                inUse={Object.keys(playlistIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : 
                      filteredPlaylists.slice(0).reverse().map((content)=> {return (                      
                        <div 
                          key={content.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {content}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && content.user_template_id && selectedContentIds.includes(content.user_template_id)? true: false}
                            ribbonType ={getRibbonType(content)}
                            inUse={Object.keys(playlistIDInUse).includes(content.user_template_id? content.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }        
              </div>  
            </div>

            <div  className="tab-pane fade show" id="kt_tab_pane_landscape" role="tabpanel">
              <div className="w-100 row pb-5">
                { landscapePlaylists && 
                  landscapePlaylists.length >= 12                     
                    ? <Slider {...getSettings(landscapePlaylists)}>     
                        {
                          landscapePlaylists.slice(0).reverse().map((plist)=> {return (                      
                            <div 
                              key={plist.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {plist}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                                ribbonType ={getRibbonType(plist)}
                                inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : landscapePlaylists.slice(0).reverse().map((plist)=> {return (                      
                        <div 
                          key={plist.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {plist}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                            ribbonType ={getRibbonType(plist)}
                            inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}
                          />
                        </div>
                      )})                      
                }  
                
              </div>  
            </div>     

            <div  className="tab-pane fade show" id="kt_tab_pane_portrait" role="tabpanel">
              <div className="row w-100 pb-5">                
                { portraitPlaylists && 
                  portraitPlaylists.length >= 12                     
                    ? <Slider {...getSettings(portraitPlaylists)}>     
                        {
                          portraitPlaylists.slice(0).reverse().map((plist)=> {return (                      
                            <div 
                              key={plist.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {plist}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                                ribbonType ={getRibbonType(plist)}
                                inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                              
                              />
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : portraitPlaylists.slice(0).reverse().map((plist)=> {return (                      
                        <div 
                          key={plist.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {plist}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                            ribbonType ={getRibbonType(plist)}
                            inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                        
                          />
                        </div>
                      )})                      
                } 
                
              </div>  
            </div>
            <div  className="tab-pane fade" id="kt_tab_pane_active" role="tabpanel">
              <div className="w-100 row pb-5">
                { activePlaylists && 
                  activePlaylists.length >= 12                    
                    ? <Slider {...getSettings(activePlaylists)}>     
                        {
                          activePlaylists.slice(0).reverse().map((plist)=> {return (                      
                            <div 
                              key={plist.user_template_id} 
                              className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                            > 
                              <CardContent 
                                className="" 
                                content = {plist}
                                autoLayout={true}
                                displayIcon={true}
                                actionIcons={true}
                                selectOpt = {selectOpt}
                                isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                                ribbonType ={getRibbonType(plist)}
                                inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                                                               
                              />                              
                            </div>
                          )}
                        )
                      }  
                      </Slider>   
                    : 
                      activePlaylists.slice(0).reverse().map((plist)=> {return (                      
                        <div 
                          key={plist.user_template_id} 
                          className="col-6 col-md-4 col-xl-3 col-xxl-2 pt-5"
                        > 
                          <CardContent 
                            className="" 
                            content = {plist}
                            autoLayout={true}
                            displayIcon={true}
                            actionIcons={true}
                            selectOpt = {selectOpt}
                            isSelected = {selectedContentIds && plist.user_template_id && selectedContentIds.includes(plist.user_template_id)? true: false}
                            ribbonType ={getRibbonType(plist)}
                            inUse={Object.keys(playlistIDInUse).includes(plist.user_template_id? plist.user_template_id:"")? 1: 0}                                                               
                          />
                        </div>
                      )})                      
                }        
              </div>  
            </div>

          </div> */}
        </div>
      </div>
    }
    </>
  )
}

export {TablePlaylists}
