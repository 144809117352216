import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import AdvanceDeviceHeader from "./AdvanceDeviceHeader";
import { Index } from "./components/Index";

const advanceDeviceBreadCrumbs: Array<PageLink> = [
    {
      title: 'Device console',
      path: '/index',
      isSeparator: false,
      isActive: false,
    }
  ]

  const AdvanceDevicePage = () => (
    <Routes>
      <Route
        element={
          <>
            {/* <AdvanceDeviceHeader /> */}
            <Outlet />
          </>
        }
      > 
  
        <Route
          path='/index'
          element={
            <>
              <PageTitle breadcrumbs={advanceDeviceBreadCrumbs}>Index</PageTitle>
              <Index />
            </>
          }
        />
        
        {/* Default route */}
        <Route index element={<Navigate to='/advance/device/index' />} />
  
      </Route>
    </Routes>
  )
  
  export default AdvanceDevicePage