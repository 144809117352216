/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../redux/hook"
import moment from "moment"
import {   
    Tooltip
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Content, DEFAULT_ZOOM_PERCENTAGE, Element, defaultContentThumbnail, emptyContent, emptyElement, getBgTemplateSize, templateColorPallets } from '../../../../redux/slices/content/contentModels';
import { setContent, setContentFromTemplate, setContentName, setUnselectedSectionIds } from '../../../../redux/slices/content/contentSlice';
import { useDeleteContentMutation } from '../../../../redux/slices/content/apiContentSlice';
import Swal from 'sweetalert2';
import { Position, Template } from '../../../../redux/slices/template/templateModels';
import { defaultThumbnails, defaultTransparentGrids } from '../../../../redux/slices/media/mediaModels';
import { defaultEditorLandscapeHeight, defaultEditorLandscapeWidth, getCorrespondHeight, getCorrespondWidth } from '../../../../redux/slices/editor/editorModels';
import { DroppableZone } from '../../../editor/components/EditorComponents';
import { setZoomPercentage } from '../../../../redux/slices/editor/editorSlice';

//----------------------------------------

type Props = {
  className: string,
  template: Template,
  autoLayout? : boolean,
  displayIcon?: boolean,
  active?: boolean,
  ribbonType?: '' | 'recent' | 'favorite' | 'recommended' | 'popular' | 'template'
  cardType?: 'portrait' | 'landscape'
}

const ribbonIconBorderColor = {
    'recent'        : ['border-warning' , 'bi bi-stars text-white'],
    'favorite'      : ['border-danger'  , 'bi bi-heart-fill text-white'],
    'recommended'   : ['border-info'    , 'bi bi-hand-thumbs-up-fill text-white'],
    'popular'       : ['border-success' , 'bi bi-star text-white'],
    'template'      : ['border-primary' , 'bi bi-columns text-white'],
}
//----------------------------------------

// const CardTemplate: React.FC<Props> = ({ className, content, imgSrc, width=0, height=0, autoLayout= true, displayIcon=true, caption='', duration=0, lastUpdated='', ribbonType, cardType = 'landscape' }) => {
const CardTemplate: React.FC<Props> = ({ className, template, ribbonType, autoLayout= true, displayIcon=true, active=false, cardType = 'landscape', }) => {
    //-----------------------------------------
    const dispatch = useAppDispatch()
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)

    const currEditorW = 720
    const currEditorH = 1280
    
    var currEditorZoomL = 0.162
    var currEditorZoomP = 0.288
    var minHeighL = "51px"
    var minHeighP = "161px"
    if (window.screen.width < 992){
        currEditorZoomL = 0.216
        currEditorZoomP = 0.385
        minHeighL = "68px"
        minHeighP = "215px"
    }
    
    // const [deleteContent] = useDeleteContentMutation();
    // const deleteDeviceHandler = async (cont: Content) => {
    //     Swal.fire({
    //         title: 'Do you really want to delete this content?<h1 style="color:#331168; margin: 15px 0;">'+ cont.user_template_name +'</h1>',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#331168',
    //         cancelButtonColor: '#E4E6EF',
    //         confirmButtonText: 'Yes, delete it!',
    //         customClass: {          
    //           cancelButton: 'order-1 left-gap me-5',
    //           confirmButton: 'order-2',
    //           // denyButton: 'order-3',
    //         }
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             deleteContent(cont.user_template_id)                
    //             Swal.fire(
    //                 'Deleted!',
    //                 `'${cont.user_template_name}' has been succesfully deleted.`,
    //                 'success'
    //             )
    //         }
    //     })
    // }

    // console.log("template", template)

    //-----------------------------------------
    return (
        <div 
            className={`${className} mb-2 pt-2 pb-1 ${active === true? "bg-gradient-primary rounded bg-opacity-50":" bg-hover-light-primary border border-1 border-secondary border-hover-primary rounded"}`}
            onClick={() => { 
                dispatch(setContentFromTemplate({   ...template, 
                                                    user_template_id: currPersistContent.user_template_id,
                                                    user_template_name: currPersistContent.user_template_name,
                                                })); 
                dispatch(setZoomPercentage(template.width < template.height ? 1: DEFAULT_ZOOM_PERCENTAGE))
                dispatch(setUnselectedSectionIds([]))
            }}
        >
            <div className={`w-100 rounded bg-warning`}>
                {ribbonType && ribbonIconBorderColor && 
                    <div className={`ribbon ribbon-triangle ribbon-top-start ${ribbonIconBorderColor[ribbonType][0]}`}>
                        <div className="ribbon-icon mt-n5 ms-n6">
                            <i className={`${ribbonIconBorderColor[ribbonType][1]} fs-3 `}></i>
                        </div>
                    </div>                
                }                 
                <div className="card overlay overflow-hidden rounded-0 pe-auto bg-gray-300">
                    <div className="card-body p-0" style={{minHeight: `${minHeighL}`}}>
                        <div 
                            // className={`w-100 overlay-wrapper ribbon ribbon-top ribbon-vertical `}
                            className={`overlay-wrapper ribbon ribbon-top ribbon-vertical `}
                            style={{                                
                                width:  `${(template.height > template.width) && (autoLayout) ? '86px'  : '100%'}`,
                                height: `${(template.height > template.width) && (autoLayout) ? minHeighP : minHeighL}`,
                                background:`url(${defaultTransparentGrids[0]}) no-repeat center center / cover`
                            }}
                        >
                            {template.positions?.
                            slice().sort((a,b)=> a.layer > b.layer ? 1: -1 )
                            .map((position: Position, ind: number) => { 
                                // console.log("position.layer:", position.layer)
                                // ----------------------------------------
                                return(<Fragment key={"pos-"+ind}>
                                    {/* {position.element_type !== "text" && */}
                                        <Fragment key={position.position_id}>                                        
                                            <div                                         
                                                style={{
                                                    background: `no-repeat 
                                                        ${getBgTemplateSize(position.w, position.h)}
                                                        
                                                        ${  position.element_type === "image" ? "url('"+defaultThumbnails[0]+"') "+templateColorPallets[0]
                                                                :   position.element_type === "video" ? "url('"+defaultThumbnails[0]+"') "+templateColorPallets[0]
                                                                    :   position.element_type === "web" ? "url('"+defaultThumbnails[2]+"') "+templateColorPallets[2]
                                                                        :   position.element_type === "text" ? "url('"+defaultThumbnails[3]+"') "+templateColorPallets[4]
                                                                        :   defaultThumbnails[0]
                                                        }                                                  
                                                    `,
                                                    position:'absolute',          
                                                    width: `${getCorrespondWidth (position.w, position.h, currEditorW, currEditorH, (template.height > template.width) && (autoLayout) ? currEditorZoomP : currEditorZoomL)}px`,
                                                    height:`${getCorrespondHeight(position.w, position.h, currEditorW, currEditorH, (template.height > template.width) && (autoLayout) ? currEditorZoomP : currEditorZoomL)}px`,          
                                                    left:  `${getCorrespondWidth (position.x, position.y, currEditorW, currEditorH, (template.height > template.width) && (autoLayout) ? currEditorZoomP : currEditorZoomL)}px`,
                                                    top:   `${getCorrespondHeight(position.x, position.y, currEditorW, currEditorH, (template.height > template.width) && (autoLayout) ? currEditorZoomP : currEditorZoomL)}px`,
                                                }}
                                                className={`${className} border border-1 border-light-primary`}        
                                            >
                                                &nbsp;
                                            </div>   
                                        </Fragment>
                                    {/* } */}
                                </Fragment>)}
                            )}
                        </div>
                    </div>
                </div>            
            </div>
            <div className="overflow-hidden position-relative pt-1 px-1 d-flex">                
                <div className="ms-0">
                    <Link 
                        to="#" 
                        className='btn-link btn-link-hover' 
                        title="Resize the browser window to see that its width (max-width) will change at different breakpoints."
                    >
                        <span className={`text-capitalize fs-8 text-truncate mb-0 ${active === true?"text-light fw-bold":"text-muted fw-semibold"}`} style={{ 
                            "maxWidth": "250px",                                
                            "WebkitLineClamp": "2",
                            "WebkitBoxOrient": "vertical",                                 
                            "whiteSpace": "normal",
                            "display": "-webkit-box"
                        }}
                        >                            
                            {template.template_name}
                            {/* - {template.template_id} */}
                        </span>
                    </Link>                    
                </div>
            </div>
        </div>
    )
}

export { CardTemplate }
