/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../../../redux/hook";

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { setContentName } from '../../../../../../../redux/slices/content/contentSlice';
import Swal from 'sweetalert2';
import { useUpdateContentMutation } from '../../../../../../../redux/slices/content/apiContentSlice';
import { ContentInput, secondsToHHmmss } from '../../../../../../../redux/slices/content/contentModels';

import { Folder } from '../../../../../../../redux/slices/media/mediaModels';
import { useAddFolderMutation, useGetAllMediasQuery, useGetAllRootFoldersQuery } from '../../../../../../../redux/slices/media/apiMediaSlice';
import { 
    FormControl,
    // Checkbox, 
    // InputLabel, MenuItem, 
    // Select,
    TextField, 
    FormHelperText
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
// import { DropdownNewFolder } from '../../../EditorComponents';
//----------------------------------------
  
  type Props = {
    className?: string,
    title?: string,
  }
  
  const schema = yup.object().shape({
      group_name: yup.string()
          .min(1, 'Minimum 1 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Folder name is required'),
  })


//----------------------------------------
export const BtnNewFolder: React.FC<Props> = ({className="btn-icon btn-sm btn-active-color-primary", title}) => {
  
  const dispatch = useAppDispatch()  
  //-----------------------------------------
  const [addFolder] = useAddFolderMutation();

  const { data: rootFolders, isLoading: isLoadingFolders, isFetching: isFetchingFolders} = useGetAllRootFoldersQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("rootFolders")  
  // console.log(rootFolders)
  
  const currentFolder = useAppSelector(state => state.editor.currentFolder)  
  // console.log("currentFolder::",currentFolder)
  


  // const { data: allMedias, isLoading: isLoadingAllMedias, isFetching} = useGetAllMediasQuery(5, { refetchOnMountOrArgChange: true })  
  // console.log("allMedias")  
  // console.log(allMedias)

  const { 
      register, 
      handleSubmit,
      // control,
      reset,
      watch,
      setError,
      formState:{
          errors,
          isDirty,
          
          isLoading,         
          isValid,   
          isSubmitting,
          isSubmitSuccessful,
          
      }
  } = useForm<Folder>({
      resolver: yupResolver(schema),
      defaultValues:{            
          group_name  : "",            
      }
  }) 

  // console.log('watch', watch(['group_name']))
  // console.log('isValid', isValid)
  // console.log('isSubmitting', isSubmitting)


  // --------------------------------------------
  const formSubmitHandler: SubmitHandler<Folder> = async (folder: Folder) => {        
      try{
          const result = await addFolder(folder).unwrap();
          // console.log(" result" )
          // console.log(result)
          
          reset()
          
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
            popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,            
          })
          await Toast.fire({
              icon: 'success',
              title: "New folder has been successfully created.",
          }) 

      }catch(error){
          Swal.fire(
              'Folder: '+ folder.group_name,
              'There is something wrong during the creation!',
              'error'
          )            
          // setError("name",{
          //     type: "custom",
          //     message: "This is sth wrong with name from server"
          // })
      }
  }  
  
  
  //-----------------------------------------
  return (<>       
      <div className="btn-group btn-group-sm" role="group" >
        <button
          className={`btn tooltip-bottom ${className}`}
          // data-tooltip-content='Create New Folder'
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-start'
          data-kt-menu-flip='top-end'
          
          // data-bs-dismiss="offcanvas"
          // data-kt-drawer-dismiss="true"

          disabled={currentFolder.source_group_id !== ""? true:false}
          
          // data-bs-toggle="offcanvas"
          // aria-controls="staticBackdrop"
          // data-bs-target="#kt_drawer_edit_folder"
          // id="kt_drawer_edit_folder_button"
          // data-kt-drawer-show="true" 
        >
          
          {title 
            ? <>
                <i className='bi bi-folder-plus text-primary fs-4 me-1'></i>
                <span className='fs-6'>{title}</span>
              </>
            : <i className="bi bi-folder-plus fs-3 text-light"></i>
          }
        </button>

        <div
          className='menu menu-sub menu-sub-dropdown menu-row w-350px w-lg-650px h-250px shadow-lg mt-2'
          data-kt-menu='true'
          style={{zIndex:"1001"}}
        >          
          <div className="d-flex flex-column flex-row-auto w-sm-100 w-md-50">          
            <div className="d-flex justify-content-between flex-center bg-light-primary h-50px"
              style={{borderTopLeftRadius:"0.475rem"}}
              // style={{background: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}') no-repeat center center / cover`}}
            >
              <h4 className='text-primary fw-bold px-9 mt-6 mb-6'>
                Create New Folder 
                {/* <span className='fs-8 opacity-75 ps-3'>24 reports</span> */}
              </h4>                      
            </div>
            {/* <div className='separator border-gray-400'></div>  */}

            <div className="d-flex flex-column-fluid flex-center bg-light" style={{borderBottomLeftRadius:"0.475rem"}}>
              {/* <div className='w-100 scroll-y mh-325px my-5 px-8'> */}
              <div className='w-100 my-5 px-8'>
                <form id="frm-new-folder" className='form'  
                  onSubmit={handleSubmit(formSubmitHandler)}
                >              
                  <div className="mb-1">
                      <TextField 
                          {...register("group_name")} 
                          label="Folder Name" 
                          placeholder='Enter your folder name'
                          fullWidth 
                          margin='dense'
                          variant='outlined' 
                          // InputLabelProps={{ shrink: true }}
                          error={!! errors.group_name}
                          helperText={`${errors.group_name?.message ?? ''}`}
                      />
                  </div>
                  <div className="mt-3 text-end">                                                                 
                      <button type="submit" className="btn btn-sm btn-primary" 
                          data-bs-toggle="offcanvas"
                          data-kt-menu-dismiss="true"
                          disabled={isSubmitting || !isValid}
                      >
                          <i className="fa fa-save me-1 mt-0"></i>
                          Create
                      </button>
                  </div>                
                </form>
              </div>
            </div>
          </div>          
          
          <div className="bg-primary rounded-end d-md-flex w-md-50">          
            <div className='w-100 scroll-y mh-325px my-5 px-8  text-white'>
              <div className='text-end'>
                <img src="/media/illustrations/icons-editor/file-folder-1.png" className="w-100px"/>
              </div>
              <div className='bg-gray-500 text-light min-h-100px rounded px-5 py-8 mt-n6 '>          
                <div><i className='fa fa-caret-right me-2 text-light'></i> Easily organize your assets</div>
                <div className='ms-5'>(i.e., by type, project, brand kit ...)</div>
                <div className='d-none'><i className='fa fa-caret-right me-2 text-light'></i> Import content from multiple sources</div>
                <div className='d-none ms-5'>such as Google Drive, Dropbox & more.</div>
              </div>          
            </div>
          </div>
          <div className='position-absolute top-0 end-0 me-n2 '>
            <button type="button" className="btn btn-link text-muted me-3" 
                data-bs-toggle="offcanvas"
                data-kt-menu-dismiss="true"                          
            >
                <span className='badge badge-circle'><i className="fa fa-times text-muted me-1 mt-0"></i></span>
            </button>   
          </div>
        </div>
      </div>
  </>)
}
