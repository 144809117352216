import moment from 'moment'
import {AuthModel, MasterModel} from './_models'
import Swal from 'sweetalert2'
import { refreshToken } from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const MASTER_LOCAL_STORAGE_KEY = 'superbolt-master'
const PIN_LOCAL_STORAGE_KEY = "pin-code"

const getMaster = (): MasterModel[] | undefined => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(MASTER_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const auths: MasterModel[] = JSON.parse(lsValue) as MasterModel[]
    if (auths) {
      return auths
    }
  } catch (error) {
    console.error('MASTER LOCAL STORAGE PARSE ERROR', error)
  }
}

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getPinCode = (): string | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(PIN_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }else{
    return lsValue
  }  
}




const setMaster = (auths: MasterModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auths)
    localStorage.setItem(MASTER_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('MASTER LOCAL STORAGE SAVE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    /**
     * Vey: The api sent valid peroid. So we add valid peroid to the start time to get valid until.
     * set cms to expire 5 minutes before api
     */
    var addTime = auth.expires_in < 10 ? auth.expires_in: auth.expires_in - 5
    
    auth.expires_in = parseInt(moment().add(addTime, "minutes").format("YYYYMMDDHHmmss"))
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    setTimeout(function(){
      Swal.fire({                
        icon: 'error',
        title: '',
        text: `Your session has expired. Would you like to continue your session?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',

        },
        timer: 60000
      }).then((result) => {
        if (result.isConfirmed) {
          const token = auth.access_token
          refreshToken(auth.access_token).then((result)=>{
            return result.data
          }).then((newAuth)=>{
            auth.access_token = newAuth.access_token
            auth.expires_in = newAuth.expires_in
            setAuth(auth)
            
          })
        }else{
          removeAuth()
          window.location.reload()
        }

      })
    }, addTime*60*1000)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

// ---This PIN is used for remembering the code redirect from the QR code
const setPinCode = (pin: string) => {
  
  if (!localStorage) {
    return
  }

  try {    
    localStorage.setItem(PIN_LOCAL_STORAGE_KEY, pin)
  } catch (error) {
    
  }
}


const removeMaster = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(MASTER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('MASTER LOCAL STORAGE REMOVE ERROR', error)
  }
}
const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
const removePinCode = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(PIN_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('PIN LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {
  getAuth, setAuth, removeAuth,
  getPinCode, setPinCode, removePinCode,
  getMaster, setMaster, removeMaster, 
  AUTH_LOCAL_STORAGE_KEY,
  MASTER_LOCAL_STORAGE_KEY,
  PIN_LOCAL_STORAGE_KEY
}
