import { AsideFooterContent } from '../../../../app/modules/editor/components/EditorComponents'
import { QuickLinksContent } from '../../../../app/modules/editor/components/widgets/QuickLinksContent'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'

const AsideFooter = () => {
  return (<> 
    {/* <span className='text-white'>test</span> */}
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    > &nbsp;
      <AsideFooterContent />
    </div>
    </>
  )
}

export {AsideFooter}
