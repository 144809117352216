import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { Passcode } from './components/Passcode'
import { ActivateAccount } from './components/Activate'
import { ResetPassword } from './components/ResetPassword'
import { ResendActivateEmail } from './components/ResendActivateEmail'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='passcode' element={<Passcode />} />
      <Route path='resend-activate-email' element={<ResendActivateEmail />} />
      <Route path='activate' element={<ActivateAccount />} />
      <Route path='registration/*' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
