import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { useAppSelector } from '../../../../app/redux/hook'
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse'
import { NavbarContent } from '../../../../app/modules/editor/components/EditorComponents'
import { useGetUnreadTicketQuery } from '../../../../app/redux/slices/ticket/apiTicketSlice'
import { useEffect, useState } from 'react'
import { HeaderShoppingCart } from '../../../../app/modules/shopping/components/header-menus/HeaderShoppingCart'
import {getMaster, useAuth} from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'
const btnClass  = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'


const Navbar = () => {  
  const { data: unreadTicket } = useGetUnreadTicketQuery(1, { refetchOnMountOrArgChange: true })
  const {config} = useLayout()
  const {currentUser, setCurrentUser, saveAuth, logout} = useAuth()

  const isEditor = useAppSelector(state => state.editor.editorModeOn)
  const currCart = useAppSelector(state => state.persistor._shopping.cart)

  // -----------------------------------------------------------
  return (<>
    <div className='app-navbar flex-shrink-0'>
      { isEditor===false
        ? <>
            {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
              <Search />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
              <div id='kt_activities_toggle' className={btnClass}>
                <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
              </div>
            </div> */}
              
            {/* <div className={clsx('app-navbar-item', itemClass)}>
              <div
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                className={btnClass}
              >
                <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
              </div>
              <HeaderNotificationsMenu />
            </div> */}
              

            {/* Resolution Notification */}
            <div className={clsx('app-navbar-item', itemClass)}>
              <div className={clsx('btn-active-light-primary position-relative', btnClass)} >
                <a href='/resolution'>
                  <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
                  {
                    unreadTicket &&
                      unreadTicket.length > 0 
                        ? <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
                        : <></>                      
                  }
                </a>
              </div>
            </div> 

            {/* Mode Switcher */}
            <div className={clsx('app-navbar-item', itemClass)}>
              <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
            </div>

            {/* Purchase Cart */}
            <div className={clsx('app-navbar-item', itemClass)}>
              <div
                className={clsx(`btn-active-light-primary position-relative`, btnClass)}
                // data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'                                  
              >
                <KTSVG path='/media/icons/duotune/ecommerce/ecm001.svg' className={`${currCart && currCart.length > 0? "text-primary":""} ${btnIconClass}`} />
                {currCart && currCart.length > 0 &&
                  <span className='badge badge-circle w-20px h-20px bg-light-primary text-primary opacity-75 position-absolute translate-middle top-25 start-75'>
                    {currCart.length}
                  </span>
                }
              </div>
              <HeaderShoppingCart />
            </div>

            {/* User profile */}
            <div className={clsx('app-navbar-item ', itemClass)}>
              <div
                className={clsx('cursor-pointer symbol d-flex fs-8 text-hover-primary ms-3', userAvatarClass)}
                // data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-trigger="{default: 'click', lg: 'click'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-permanent={true}
              >
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='rounded-circle' alt='' />
                {/* <img src={toAbsoluteUrl('/media/avatars/man-001.png')} alt='' /> */}
                <div className='d-none d-lg-flex flex-column align-item-center mt-1'>
                  <div className='text-gray-800'>
                    <span className='ms-2'>Hi</span> 
                    <span className='ms-1'>{currentUser?.name.split(' ')[0]}</span>
                  </div>
                  {/* <span className='ms-2'>My Superbolt</span>  */}
                </div>
              </div>
              <HeaderUserMenu />
            </div>   

          </>
        : <NavbarContent />        
      }
      
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-none d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  </>)
}

export {Navbar}
