/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useDrag } from 'react-dnd'
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material'
import { ContentInput, EleInput, Source, formatBytes, secondsToHHmmss } from '../../../../../redux/slices/content/contentModels'
import { Media } from '../../../../../redux/slices/media/mediaModels'
import { useDeleteMediaMutation } from '../../../../../redux/slices/media/apiMediaSlice'
import Swal from 'sweetalert2'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook'
import { useGetAllContentsQuery, useUpdateContentMutation } from '../../../../../redux/slices/content/apiContentSlice'
import { setContentSource, setMedia, setSectionId } from '../../../../../redux/slices/content/contentSlice'
import { DEFAULT_LOAD_ATTEMPT, ImageListItemWithStyle, RECYCLE_BIN_DURATION } from '../../../../../redux/slices/editor/editorModels'
import { setSelectedMediaSrcIds } from '../../../../../redux/slices/editor/editorSlice'
import moment from 'moment'
// import isReachable from 'is-reachable';
//----------------------------------------

type Props = {
  className: string,
  item: Media,
  inUse?: number,
  deletable?: boolean,
  isSelected?: boolean,
  isDraggable?: boolean,
}


//----------------------------------------
const DraggableVideo: React.FC<Props> = ({className, item, inUse=0, isSelected=false, deletable=true, isDraggable=true}) => { 
  const dispatch = useAppDispatch()
  // --------------------------------------------
  const currPersistContent = useAppSelector(state => state.persistor._content.currContent)    
  // console.log("currPersistContent in Accordion", currPersistContent)  

  const currentFolder = useAppSelector(state => state.editor.currentFolder)  


  const { data: allContents, isLoading, isFetching} = useGetAllContentsQuery(5, { refetchOnMountOrArgChange: true })  
 
  const multiSelMode = useAppSelector(state => state.editor.multiSelectMode)  
  const selMediaSrcIds = useAppSelector(state => state.editor.selectedMediaSrcIds)  
  
  
  const [videoError, setVideoError] = useState(false)
  const [thumbnailError, setThumbnailError] = useState(false)
  const [vidError, setVidError] = useState<number[]>([])

  const [{isDragging}, drag] = useDrag(
    () => ({
      type: "imageVideo",
      item: {
        id: item.source_id,
        // grp: item.source_group_id,
        // name: item.name,
      },
      collect: (monitor) => {
        // console.log("monitor, item:", monitor, item)          
        return {        
                  isDragging: !!monitor.isDragging(),
        }
      },
    }), [item]  // Require to refresh obj 'item' when it changes, DEPENDENCY ARRAY HERE, need for refreshing
  )

  const limitFileNamelen = 11
  const fileName = item.name.split('.').slice(0, -1).join('.')
  // const ext = item.name.split('.').pop()
  const ext = item.name.split('.').pop()  
  
  // const [imgError, setImgError] = useState(false)
  const [loadAttempt, setLoadAttemp] = useState(0);

  // console.log("thumbnailError = ", thumbnailError);  

  
  // console.log("item = ", item);  
  // (async () => {    
  //   console.log(await isReachable(item.url_thumb))    
  //   // console.log(await isReachable('google.com:443'));
  //   //=> true
  // })();

  
  //---Delete Image Handler
  const [updateContent] = useUpdateContentMutation()
  const [deleteDraggableImage] = useDeleteMediaMutation()
  const deleteMediaHandler = async (med: Media, inUse=0) => { 
    
    if (inUse > 0 || currentFolder.source_group_id === "bin" || (!isDraggable) ){   

      Swal.fire({
          title: 'Are you sure you want to <br/>'+(currentFolder.source_group_id === "bin" || (!isDraggable) ? "permanently":"")+' delete this file?<h2 style="color:#331168; margin: 15px 0;">'+ med.name +'</h2>',
          // text: "This file is in use!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#9C258F',
          cancelButtonColor: '#E4E6EF',
          confirmButtonText: 'Yes, delete it!',
          customClass: {          
              cancelButton: 'order-1 left-gap me-5',
              confirmButton: 'order-2',
              // denyButton: 'order-3',
          }
      }).then((result) => {

          if (result.isConfirmed) {
              try{
                if (med.source_id){
                  // console.log("med.source_id: ", med.source_id)

                  // 1- delete images from all the related contents if any
                  // --------------------------------------------------
                  // allContents?.map((content)=> {
                  //   // console.log("content.elements", content.elements)

                  //   const newEleList : EleInput[] = []
                  //   var flagUpdate : boolean = false
                  //   // ----------------------------

                  //   content.elements.map((section, indSect)=>{
                  //     // console.log("section", section)

                  //     var sourcesAfterDelete : Source[] = []

                  //     if (["image", "video"].includes(section.element_type.toLowerCase())){
                  //       // console.log(ele.element_type)
                  //       sourcesAfterDelete = section.sources?.filter((src)=>{
                  //         // console.log(src.source?.source_id)
                  //         return (src.source && src.source.source_id !== med.source_id)                            
                  //       })
                  //       // console.log("ele.sources: ",section.sources)
                  //       // console.log("sourcesAfterDelete: ",sourcesAfterDelete)

                  //       if (section.sources.length !== sourcesAfterDelete.length){
                  //         // console.log("delete this")
                  //         flagUpdate = true
                  //       }
                  //     }

                  //     newEleList.push({
                  //       element_id  : section.element_id,
                  //       position_id : section.position_id,
                  //       alias       : section.alias,                        
                  //       element_setting : JSON.stringify(section.element_setting),
                  //       type            : section.element_type,
                  //       source_ids      : JSON.stringify(flagUpdate === false? section.sources : sourcesAfterDelete),
                  //     })

                  //     if (flagUpdate === true){
                  //       const newContent :ContentInput = {
                  //         contentId: content.user_template_id?content.user_template_id:"",
                  //         user_template: {
                  //           template_id : content.template_id?content.template_id:"",
                  //           user_template_name: content.user_template_name,
                  //           duration: content.duration
                  //         },                            
                  //         elements: newEleList
                  //       }
                  //       // console.log("newContent : ", newContent)
                                                
                  //       updateContent(newContent) //update the content correspondance
                  //     }
                  //   })                    
                  // })

                  // 2- Delete images from currContent
                  // --------------------------------------------------
                  currPersistContent.elements.map((section, indSect)=>{
                    // console.log("section", section)

                    var sourcesAfterDelete : Source[] = []

                    if (["image", "video"].includes(section.element_type.toLowerCase())){
                      // console.log(ele.element_type)
                      sourcesAfterDelete = section.sources?.filter((src)=>{
                        // console.log(src.source?.source_id)
                        return (src.source && src.source.source_id !== med.source_id)                            
                      })
                      // console.log("ele.sources: ",section.sources)
                      // console.log("sourcesAfterDelete: ",sourcesAfterDelete)

                      if (section.sources.length !== sourcesAfterDelete.length){
                        // console.log("delete this")

                        dispatch(setSectionId(section.element_id))  //which section it belongs to
                        dispatch(setContentSource(sourcesAfterDelete))
                      }
                    }
                  }) 

                  // 3 - Delete the image source
                  // ----------------------------
                  deleteDraggableImage(med.source_id)
                                    
                  Swal.fire({
                    icon: 'success',
                    title: `Deleted! <br/><br/>'${med.name}'<br/><br/> has been succesfully deleted.<br/><br/>&nbsp;`,                  
                    showConfirmButton: false,
                    timer: 2000
                  })
                }  
                  
              }catch(error){
                  Swal.fire(
                      'File: '+ med.name,
                      'There is something wrong during the deletion!',
                      'error'
                  )                                    
              } 
          }
      })
    }else{
      try{
        if (med.source_id){          

          // 2- Delete images from currContent
          // --------------------------------------------------
          currPersistContent.elements.map((section, indSect)=>{
            // console.log("section", section)

            var sourcesAfterDelete : Source[] = []

            if (["image", "video"].includes(section.element_type.toLowerCase())){
              // console.log(ele.element_type)
              sourcesAfterDelete = section.sources?.filter((src)=>{
                // console.log(src.source?.source_id)
                return (src.source && src.source.source_id !== med.source_id)                            
              })
              // console.log("ele.sources: ",section.sources)
              // console.log("sourcesAfterDelete: ",sourcesAfterDelete)

              if (section.sources.length !== sourcesAfterDelete.length){
                // console.log("delete this")

                dispatch(setSectionId(section.element_id))  //which section it belongs to
                dispatch(setContentSource(sourcesAfterDelete))
              }
            }
          }) 

          // 3 - Delete the image source
          // ----------------------------
          deleteDraggableImage(med.source_id)

          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,
            // timerProgressBar: true
          })
          await Toast.fire({
            icon: 'success',
            title: `File: <span class="text-primary fw-bold">'${med.name}'</span><br/> has been succesfully deleted.`,
          })
                    
        }
      }catch(error){
          Swal.fire(
              'File: '+ med.name,
              'There is something wrong during the deletion!',
              'error'
          )                                    
      }
    }

    // console.log("confirmDelete in Handler", confirmDelete)


    // if (confirmDelete === true){
    //   
    // }
  }

  
  //-----------------------------------------
  return (    

    <ImageListItemWithStyle
      ref = {currentFolder.source_group_id !== 'bin' && isDraggable? drag : null}
      className={`w-100 ${isDraggable || multiSelMode ? "" : "pe-none"} `}
      onClick={()=>{
        if (multiSelMode){
          if (selMediaSrcIds.includes(item.source_id)){
            dispatch(setSelectedMediaSrcIds(selMediaSrcIds.filter(id => id !== item.source_id)))
          }else{
            dispatch(setSelectedMediaSrcIds([...selMediaSrcIds, item.source_id]))
          }
        }
      }}
    >
      
      {thumbnailError 
        ? 
          <img 
            ref = {drag}
            // alt={item.name}
            loading="lazy"

            className={`${className} rounded-0 bordered-0 
              ${item.width >= item.height? " w-100 h-auto max-w-landscape" : " w-auto h-auto max-w-portrait"}
              ${isDragging? 'opacity-25':''}            
            `}
            style={{
              maxHeight: `${item.width >= item.height? "100px" : "300px"}`, 
            }}

            src={`/media/_editor/nothumbnail${item.width >= item.height?"":"-portrait"}.png`}
          />
          
        : <img 
            ref = {drag}
            // alt={item.name}
            loading="lazy"

            className={`${className} rounded-0 bordered-0 
              ${item.width >= item.height? " w-100 h-auto max-w-landscape" : " w-auto h-auto max-w-portrait"}
              ${isDragging? 'opacity-25':''}            
            `}
            style={{
              maxHeight: `${item.width >= item.height? "100px" : "300px"}`, 
            }}

            src={`${item.is_remote === 0 ? item.url_thumb : item.url}`}

            onError={({ currentTarget }) => {
              currentTarget.src= '../media/icons/loading.gif';
              currentTarget.draggable = false
              
              setTimeout(function(){
                currentTarget.src = item.url_thumb
                currentTarget.draggable = true
                setLoadAttemp(loadAttempt+1)
                if(loadAttempt == DEFAULT_LOAD_ATTEMPT){
                  currentTarget.src = item.url
                }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT){
                  currentTarget.onerror = null; // prevents looping
                  setThumbnailError(true)                    
                }
              }, 1000)
            }}
          />
      }
        {/* // ? videoError 
        //     ? <div className='rounded-0 w-100 h-80px bordered-1 border-light-primary-darker '>
        //         <span className="text-primary fw-bold text-center">Error</span><br />
        //         <span>Please delete video and re-upload</span>
        //       </div>
              
        //     : <>
        //         <video autoPlay={false} controls={false} muted 
        //           ref = {drag}
        //           className={`${className} rounded-0 w-100 h-80px bordered-0 bg-dark
        //             ${isDragging? 'opacity-25':''}
        //           `}
        //           onError={() => {
        //             setVideoError(true)
        //           }}
        //           // onError={({ currentTarget }) => {
        //           //   currentTarget.src= '../media/icons/loading.gif';
        //           //   currentTarget.draggable = false
                    
        //           //   setTimeout(function(){
        //           //     currentTarget.src = item.url_thumb
        //           //     currentTarget.draggable = true
        //           //     setLoadAttemp(loadAttempt+1)
        //           //     if(loadAttempt == DEFAULT_LOAD_ATTEMPT){
        //           //       currentTarget.src = item.url
        //           //     }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT){
        //           //       currentTarget.onerror = null; // prevents looping
        //           //       setVideoError(true)                    
        //           //     }
        //           //   }, 1000)
        //           // }}
        //         >
        //           <source src={`${item.url}#t=3`} type="video/mp4" />
        //         </video>
        //       </>
        // : <img 
        //     ref = {drag}
        //     // alt={item.name}
        //     loading="lazy"

        //     className={`${className} rounded-0 bordered-0 
        //       ${item.width >= item.height? " w-100 h-auto " : " w-auto h-auto "}
        //       ${isDragging? 'opacity-25':''}            
        //     `}
        //     style={{maxHeight: `${item.width >= item.height? "85px" : "300px"}` }}

        //     src={`${item.is_remote === 0 ? item.url_thumb : item.url}`}

        //     onError={({ currentTarget }) => {
        //       currentTarget.src= '../media/icons/loading.gif';
        //       currentTarget.draggable = false
              
        //       setTimeout(function(){
        //         currentTarget.src = item.url_thumb
        //         currentTarget.draggable = true
        //         setLoadAttemp(loadAttempt+1)
        //         if(loadAttempt == DEFAULT_LOAD_ATTEMPT){
        //           currentTarget.src = item.url
        //         }else if(loadAttempt >DEFAULT_LOAD_ATTEMPT){
        //           currentTarget.onerror = null; // prevents looping
        //           setThumbnailError(true)                    
        //         }
        //       }, 1000)
        //     }}
        //   /> */}
      {/* } */}
        
      <ImageListItemBar
        position='top' className='rounded-top bg-transparent'                                                                                                       
        actionIcon={<>       
          {inUse > 0 && 
            <span className='opacity-100 m-0 pt-1 pb-0 px-1 position-absolute top-0 start-0 bagde tooltip-bottom'
              style={{borderBottomRightRadius:"5px"}}
              data-tooltip-content='This file is in use!'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-3 text-light shadow-sm ms-1'/>
            </span>
          }
          {isSelected === true &&
            <span className='opacity-100 m-0 mt-1 ms-1 p-1 pb-0 position-absolute top-0 start-0 bagde bg-info rounded-circle'
              style={{borderBottomRightRadius:"5px"}}
            >
              <i className='bi bi-check text-light fw-bold fs-3 shadow-sm'/>
            </span>
          }  
          { deletable === true &&
            <a href="#" 
              className={`pe-auto bg-secondary bg-hover-primary opacity-75 m-0 py-0 px-2 position-absolute top-0 end-0 tooltip-bottom`}
              data-tooltip-content={`${currentFolder.source_group_id !== "bin" ? 'Move to Trash':''}`}
              style={{borderBottomLeftRadius:"5px"}}
              onClick={()=>{ deleteMediaHandler(item, inUse);}}
            >
              <i className='bi bi-trash text-dark text-hover-white opacity-75'></i>
            </a> 
          }  
        </>}
      /> 
      {/* <ImageListItemBar
        position='top' className='bg-primary mt-10'                                                                                                       
        actionIcon={
          <div className="position-absolute top-50 start-50">
            <a  href="#" 
                className="btn btn-link hover-scale ms-n6 mt-n6"
                data-bs-toggle="modal"
                data-bs-target="#modal-preview-video"
                onClick={() => { dispatch(setMedia(item)); }}
            >
                <i className="bi bi-play-circle-fill fs-3x text-white text-hover-gradient-primary"></i>                                    
            </a>
          </div>
        }
      /> */}
      <ImageListItemBar
        position={`${item.width >= item.height? "top" : "bottom"}`} 
        className='rounded-top bg-transparent mb-8'   
        style={{marginTop: `${item.width >= item.height? "60px" : ""}`}}                                                                                            
        actionIcon={<>
          <span className="badge badge-dark text-gray-100 p-1 me-2">{secondsToHHmmss(item.duration)}</span>  
          <a className='opacity-100 m-0 mt-n1 ms-0 pt-1 pb-0 px-1 position-absolute top-0 start-0 bagde tooltip-bottom'
            style={{borderBottomRightRadius:"5px"}}
            data-bs-toggle="modal"
            data-bs-target="#modal-preview-video"
            onClick={() => { dispatch(setMedia(item)); }}
          >
            <i className="bi bi-play-circle-fill fs-1 text-white text-hover-gradient-primary"></i>                                    
          </a>                                                  
        </>}        
      />       
      <div className='d-flex justify-content-between m-1 mb-2'>
        <span className='d-flex fs-9 fw-semibold text-muted tooltip-bottom'
          data-tooltip-content={item.name}
        >
          <i className='fa fa-video-camera text-info opacity-100 me-2'></i>
          {`${fileName.length <= limitFileNamelen ? fileName: fileName.substring(0, limitFileNamelen)+"..."}.${ext}`}
        </span>
        <span className='fs-9 fw-semibold text-muted'>{formatBytes(item.size)}</span>
      </div>
      {item.deleted_at && 
        <div className='d-flex justify-content-end fs-9 text-muted pe-1'>         
          {moment.utc(moment(item.deleted_at, true).toString()).local().fromNow()}
          {/* {RECYCLE_BIN_DURATION - moment.utc(moment(item.deleted_at, true).toString()).local().diff(moment(), "days")} days left    */}
        </div>
      }      
    </ImageListItemWithStyle>
  )
}

export {DraggableVideo}