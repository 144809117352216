

export interface InitialState {

    selectedContentIds: string[],

}


export const PEXELS_API_KEY : string  = process.env.REACT_APP_PEXELS_API_KEY 
                                                        ? process.env.REACT_APP_PEXELS_API_KEY
                                                        : "UsCTfaaf6qJQVU0fnGh9ORDku3rFtxZqSURvhA4lFqQyszqg7ie57INN"