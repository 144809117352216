import { InitialState, Ticket, TicketPartial } from "./ticketModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: InitialState = {
    errStatus: false,    
    page : 1,
    viewTicket: {
        id: "",
        deviceId: "",
        groupName: "",
        reference: "",
        statusId: 1,       
        created_at: null,
        updated_at: null, 
        processes: [],
    },
    actionTicket:{
        id: "",
    }
}

const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers:{
        setDrawerTicket: (state, action: PayloadAction<TicketPartial>) => {
            state.actionTicket = action.payload
        },

        setTicket: (state, action: PayloadAction<Ticket>) => {
            state.viewTicket = action.payload
        },
        
        increased: (state) =>{
            state.page++
        },
        decreased: (state) =>{
            state.page--
        },
    }
})
export default ticketSlice.reducer
export const {
    setTicket, 
    setDrawerTicket,
    increased,
    decreased   
}  = ticketSlice.actions