import {Task} from "gantt-task-react"
// import styles from "../styles/tooltip.module.css";
import React, { useState } from "react";
import moment from "moment";
import { Sched, extractStartEndDatesFromSched } from "../../../../redux/slices/schedule/scheduleModels";

type Props = {
    className?: string,     
}

export const GanttLegend: React.FC<Props> = ({className=""}) => {
    
    return (
        <div className={`${className}`}>
            {/* <ul className="legend">
                <li><span className="select-schedule ms-2"></span><strong className="text-warning">Selected</strong> schedule</li>
            </ul> */}
            <ul className="legend">
                <li><span className="active-schedule ms-2"></span><strong className="text-success">Active</strong> schedule</li>
            </ul>
            <ul className="legend">
                <li><span className="past-schedule ms-2"></span><strong className="text-gray-400">Past</strong> schedule</li>
            </ul>
            <ul className="legend">
                <li><span className="standard-schedule ms-2"></span> Current <strong className="text-info">standard</strong> schedule</li>
                <li><span className="recurring-schedule ms-2"></span> Current <strong className="text-primary">recurring</strong> schedule</li>
            </ul>
            <ul className="legend">
                <li><span className="upcoming-standard-schedule ms-2"></span> <strong style={{color:"#009ef7", opacity:"0.35"}}>Upcoming standard</strong> schedule</li>
                <li><span className="upcoming-recurring-schedule ms-2"></span> <strong style={{color:"#9C258F", opacity:"0.35"}}>Upcoming recurring</strong> schedule</li>
            </ul>
        </div>
    );
};