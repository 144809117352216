import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth"
import { ResultObject, Ticket, TicketPartial, TicketProcess } from "./ticketModel";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL

export  const auth = getAuthModel();

export const ticketsApi = createApi({
    reducerPath: "ticketsApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['Ticket', 'TicketProcess'],
    refetchOnMountOrArgChange: 30,    
    
    endpoints: (builder) => ({
        
        getAllTickets : builder.query<Ticket[], number>({
            query: () => `tickets`,
            providesTags: ['Ticket', 'TicketProcess'],
            keepUnusedDataFor: 5,              
        }),

        getTicketProcess : builder.query<TicketProcess[], string>({
            query: (id) => `ticket/${id}/process`,
            providesTags: ['TicketProcess'],
            keepUnusedDataFor: 5,              
        }),

        getUnreadTicket : builder.query<TicketProcess[], number>({
            query: () => `ticket/unread`,   
            providesTags: ['Ticket', 'TicketProcess'],                     
            keepUnusedDataFor: 0,
        }),

        markTicketAsRead : builder.query<ResultObject, string>({
            query: (id) => `ticket/read/${id}`,   
            providesTags: ['Ticket', 'TicketProcess'],                     
            keepUnusedDataFor: 5,
        }),

        createReplyTicket: builder.mutation<ResultObject, TicketPartial>({
            query(data){
                return{
                    url: "ticket/process",
                    method: "POST",
                    body: data,
                }
            },
            invalidatesTags: ['Ticket', 'TicketProcess']
        }),
    })
});

export const {
    useGetAllTicketsQuery,
    useGetTicketProcessQuery,
    useGetUnreadTicketQuery,
    useMarkTicketAsReadQuery,
    useCreateReplyTicketMutation,
} = ticketsApi