import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth";
import { Invoice } from "./invoiceModel";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL

export  const auth = getAuthModel();

export const invoiceApi = createApi({
    reducerPath: "invoiceApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),

    tagTypes: ['Invoice'],
    refetchOnMountOrArgChange: 30,

    endpoints: (builder) => ({
        
        getAllInvoices : builder.query<Invoice[], number>({
            query: () => `invoice/all`,
            keepUnusedDataFor: 5,              
        }),
    })
});

export const {
    useGetAllInvoicesQuery
} = invoiceApi
