import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Device, InitialState } from './deviceModels';
import { AssociativeArray } from '../content/contentModels';


const initialState: InitialState = {
    errStatus: false,    
    mqttDevice: {
        id          : "",
        deviceName  : "",
        groupName   : "",
        deviceUnique: "", 
        license: ""
    },
    arrMQTTResponses: {},
    currDevice: {
        // app_ver     : "",
        // checkin_time: new Date,
        // created_at  : new Date,
        // device_manufacturer: "",
        // device_model: "", 
        // deviceName  : "",
        // device_time_zone: "",
        // deviceUnique: "",
        // groupId     : "",
        // has_camera  : false,
        // has_touch   : false,
        // id          : "",
        // note        : "",
        // pin         : "",
        // platform    : "",
        // platform_ver: "",
        // rotation    : "0",
        // status_id   : 1,
        // type        : "",   
        // updated_at  : new Date,
        // userId      : "",  
        // status: "", // to be deleted
        

        id          : "",
        deviceName  : "",
        // rotation    : "0",

        groupId     : "0",
        groupName   : "",
        groupDisplayType : "",
        category    : "",
        groupCreatedAt: null,
        groupUpdatedAt: null,


        app_ver     : "",
        checkin_time: "",
        created_at  : null,
        updated_at  : null,
        device_manufacturer: "",
        device_model: "",     
        device_time_zone: "",
        deviceUnique: "",    
        has_camera  : false,
        has_touch   : false,    
        note        : "",
        pin         : "",
        platform    : "",
        platform_ver: "",    
        status_id   : 1,
        type        : "",  
        userId      : "", 
        user_settings: {
            rotation: "0",
            device_time_zone: "Australia/Melbourne"
        },
        status      : "", // to be deleted
    },
    currDeviceMqttClient : null ,
    lastDeviceStatus: []   
}

const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers:{
        setErrStatusDevice: (state, action) => {
            state.errStatus = action.payload
        },        
        setDevice(state, action: PayloadAction<Device>){
            const device = action.payload;
            state.currDevice = device
        },
        setMQTTDevice(state, action: PayloadAction<Device>){
            state.mqttDevice = action.payload
        }, 
        setDeviceMqttClient(state, action: PayloadAction<any>){
            state.currDeviceMqttClient = action.payload
        },       
        setArrMQTTResponses(state, action: PayloadAction<any>){
            state.arrMQTTResponses = action.payload
        },
        setLastDeviceStatus(state, action: PayloadAction<any>){
            state.lastDeviceStatus = action.payload
        },
        // setArrMQTTResponsesByKey(state, action: PayloadAction<any>){
        //     state.arrMQTTResponses = action.payload
        // },
        resetArrMQTTResponses(state){
            state.arrMQTTResponses = {}
        }, 
        resetArrMQTTResponsesByKey(state, action: PayloadAction<string>){
            const keyStr = action.payload
            if (Object.keys(state.arrMQTTResponses).includes(keyStr)){
                (state.arrMQTTResponses)[keyStr] = []
            }
        },
        removeArrMQTTResponsesByKey(state, action: PayloadAction<string>){
            const keyStr = action.payload
            if (Object.keys(state.arrMQTTResponses).includes(keyStr)){
                delete (state.arrMQTTResponses)[keyStr]
            }
        }
              
    }
})

export default deviceSlice.reducer
export const {
    setErrStatusDevice,
    setDevice,
    setMQTTDevice,
    setDeviceMqttClient,
    setLastDeviceStatus,
    
    setArrMQTTResponses,
    // setArrMQTTResponsesByKey,
    resetArrMQTTResponses,

    resetArrMQTTResponsesByKey,
    removeArrMQTTResponsesByKey,

}  = deviceSlice.actions
