import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Medias, Media, RootFolders, Folder, MediaInputFields, ChangeFolder} from './mediaModels';
import { getAuthModel } from "../../auth";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
// const API_URL : string | undefined  = process.env.REACT_APP_API_URL_DEV


export const mediasApi = createApi({
    reducerPath: "mediasApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{
            const auth = getAuthModel()            
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['Medias', 'RootFolders', 'Media','MediaInputFields', 'Folder'],

    // global configuration for the api
    //----------------------------------------
    // keepUnusedDataFor: 30,
    refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,

    endpoints: (builder) => ({
        getAllRootFolders : builder.query<RootFolders, number>({
            query: () => "source/group?with[]=sources",
            providesTags: ['RootFolders'],
            keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        }),  
        getAllMedias : builder.query<Medias, number>({
            query: () => "source",
            providesTags: ['Medias'],
            keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        }),         
        getMedia : builder.query<Media, string>({
            query: (id) => `source/${id}`,
            providesTags: ['Media']
        }),

        getDeletedMedia : builder.query<Media[], void>({
            query: () => `source/trash/list`,
            providesTags: ['Medias']
        }),
        // ----------------------------------------------------

        addFolder: builder.mutation<void, Folder>({
            query: (folder) => ({
                url: "source/group",
                method: "POST",
                body: folder
            }),
            invalidatesTags: ['Folder', 'RootFolders']
        }),
        updateFolder: builder.mutation<void, Folder>({
            query: ({source_group_id, ...rest}) => ({
                url: `source/group/${source_group_id}`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Folder', 'RootFolders']            
        }),
        deleteFolder: builder.mutation<void, string>({    
            query: (id) => ({
                url: `source/group/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['RootFolders', 'Medias']
        }),
        uploadFiles: builder.mutation<void, any>({
            query: (media) => ({
                url: "source",
                method: "POST",
               
                body: media
            }),
            async onQueryStarted(props, { dispatch, queryFulfilled }) {
                await queryFulfilled
                setTimeout(() => {
                  dispatch(mediasApi.util.invalidateTags(['Medias', 'Media']))
                }, 3000)
              },
            //invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        }),
        // updateMedia: builder.mutation<void, Media>({
        //     query: ({id, ...rest}) => ({
        //         url: `medias/${id}`,
        //         method: "PUT",
        //         body: rest
        //     }),
        //     invalidatesTags: ['Media']            
        // }),   
        // deleteMedia: builder.mutation<void, any>({
        //     query: (data) => ({                
        //         url: `source/${data.id}`,
        //         method: "DELETE",
        //         body: data.permanentDelete
        //     }),
        //     invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        // }),     
        deleteMedia: builder.mutation<void, string>({
            query: (id) => ({                
                url: `source/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        }),

        deleteMedias: builder.mutation<Media, string[]>({
            query: (sourceIds) => ({                
                url: `source/bulkDelete`,
                method: "POST",
                body: {ids:sourceIds}
            }),
            invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        }),

        restoreMedia: builder.mutation<Media, string[]>({
            query: (sourceIds) => ({                
                url: `source/trash/restore`,
                method: "POST",
                body: {ids:sourceIds}
            }),
            invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        }),

        changeMediaFolder: builder.mutation<Media, ChangeFolder>({
            query: (data) => ({  
                url: `source/changeGroup`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        }),
    })
})

export const { 
    useGetAllRootFoldersQuery,
    useGetAllMediasQuery, 
    useGetMediaQuery,
    useGetDeletedMediaQuery,
    useAddFolderMutation,
    useUpdateFolderMutation,
    useDeleteFolderMutation,
    useUploadFilesMutation,
    useDeleteMediasMutation,
    useDeleteMediaMutation,
    useRestoreMediaMutation,
    useChangeMediaFolderMutation
} = mediasApi