import { InitialState, Template } from "./templateModels";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: InitialState = {
    currentTemplate: {
        template_id: "",
        category        : 0,
        width           : 0,
        height          : 0,
        target_layout   : 0,
        template_name   : "",
    }
}

const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers:{
        setSelectedTemplate(state, action: PayloadAction<Template>)
        {
            state.currentTemplate = action.payload
        }
    }
})

export default templateSlice.reducer

export const {
    setSelectedTemplate
} = templateSlice.actions