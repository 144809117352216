import React, { useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { setEditableMode, setEnableClearAll, setHeightLimited, setShowActiveSchedule, setShowComingSchedule, setShowExpiredSchedule, setShowNumberOfDevices, setShowWarningMsg } from "../../../../redux/slices/schedule/scheduleSlice";
import "./customGantt.css"

type ViewSwitcherProps = {
  isChecked: boolean
  onViewListChange: (isChecked: boolean) => void
  onViewModeChange: (viewMode: ViewMode) => void
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked
}) => {
  const dispatch = useAppDispatch()  
  // ------------------------------------
  // const persitSched = useAppSelector(state => state.persistor._schedule)
  // console.log("persitSched: ", persitSched)

  const isHeightLimited = useAppSelector(state => state.persistor._schedule.isHeightLimited)
  const showNbOfDevices = useAppSelector(state => state.persistor._schedule.showNumberOfDevices)
  const showWarningMsgs = useAppSelector(state => state.persistor._schedule.showWarningMsg)
  
  const showActiveSch   = useAppSelector(state => state.persistor._schedule.showActiveSched)
  const showExpiredSch  = useAppSelector(state => state.persistor._schedule.showExpiredSched)
  const showComingSch   = useAppSelector(state => state.persistor._schedule.showComingSched)  
  const editableMode    = useAppSelector(state => state.persistor._schedule.editMode)
  const enableClearAll  = useAppSelector(state => state.persistor._schedule.enableClearAll)
  const [viewName, setViewName] = useState("day")
  // --------------------------------------
  return (<>

    <div className="btn-group btn-group-sm px-8" role="group" aria-label="Large button group">
      <button
        // className="Button"
        className={`btn btn-secondary ${viewName==="hour"?"active":""}`}
        onClick={() => {onViewModeChange(ViewMode.Hour); setViewName('hour')}}
      >
        Hour
      </button>
      {/* <button
        // className="Button"
        className="btn btn-outline"
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </button> */}
      {/* <button
        // className="Button"
        className="btn btn-outline"
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </button> */}
      <button 
        // className="Button" 
        className={`btn btn-secondary ${viewName==="day"?"active":""}`}
        onClick={() => {onViewModeChange(ViewMode.Day); setViewName('day')}}
      >
        Day
      </button>
      <button
        // className="Button"
        className={`btn btn-secondary ${viewName==="week"?"active":""}`}
        onClick={() => {onViewModeChange(ViewMode.Week); setViewName('week')}}
      >
        Week
      </button>
      <button
        // className="Button"
        className={`btn btn-secondary ${viewName==="month"?"active":""}`}
        onClick={() => {onViewModeChange(ViewMode.Month); setViewName('month')}}
      >
        Month
      </button>
      <button
        // className="Button"
        className={`btn btn-secondary ${viewName==="year"?"active":""}`}
        onClick={() => {onViewModeChange(ViewMode.Year); setViewName('year')}}
      >
        Year
      </button>
      {/* <button
        className="Button"
        onClick={() => onViewModeChange(ViewMode.QuarterYear)}
      >
        Year
      </button> */}

      <div className="btn-group" role="group">
        <button
          className='btn btn-sm btn-dark btn-active-color-primary rounded-end px-3'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <i className='bi bi-three-dots fs-3'></i>
        </button>
        
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px shadow-md mt-2' data-kt-menu='true'>
          <div className='px-7 py-5 text-start bg-gradient-primary rounded-top'>            
            <div className='fs-6 text-white fw-semibold'>
              
              <KTSVG path='/media/icons/duotune/files/fil001.svg' className='svg-icon-2 me-2 text-white' /> 
              Gantt Chart Settings
            </div>
          </div>

          {/*  */}
          <div className='separator border-gray-200'></div>

          <div className='px-7 py-5'>   
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2 tooltip-bottom"
              data-tooltip-content="When 'Edit Mode' is on, user can interact with Gantt chart directly (e.g., moving schedule, ...)"
            >
              <label className="form-check-label text-gray-700 " htmlFor="flexSwitchGrpSchedules" data-tooltip-html="Enable to click and drag schedule.">
                Enable "Edit Mode" - Gantt chart
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={editableMode}
                onChange={() => dispatch(setEditableMode(!editableMode))}
              />
            </div>
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show "Device Group Schedules"
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={isChecked}
                onChange={() => onViewListChange(!isChecked)}
              />
            </div>
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">                            
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchLimitH">
                Limit chart height
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchLimitH" value="" checked={isHeightLimited}
                onChange={() => dispatch(setHeightLimited(!isHeightLimited))}
              />              
            </div>
           
            {/* <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show Empty Group
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={isChecked}
                // onChange={() => onViewListChange(!isChecked)}
              />
            </div> */}
          </div> 
          {/* <div className='separator border-gray-200'></div>
          <div className='px-7 py-5'>            
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Enable "Clear Schedules"
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={enableClearAll}
                onChange={() => dispatch(setEnableClearAll(!enableClearAll))}
              />
            </div>
          </div>  */}
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5'>            
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show number of devices
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={showNbOfDevices}
                onChange={() => dispatch(setShowNumberOfDevices(!showNbOfDevices))}
              />
            </div>
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show warning messages
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={showWarningMsgs}
                onChange={() => dispatch(setShowWarningMsg(!showWarningMsgs))}
              />
            </div>
          </div> 
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5'>
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show active schedules
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={showActiveSch}
                onChange={() => dispatch(setShowActiveSchedule(!showActiveSch))}
              />
            </div>
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show expired schedules
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={showExpiredSch}
                onChange={() => dispatch(setShowExpiredSchedule(!showExpiredSch))}
              />
            </div>
            <div className="d-flex justify-content-between form-check form-switch form-check-custom form-switch-sm form-check-solid mb-2">
              <label className="form-check-label text-gray-700" htmlFor="flexSwitchGrpSchedules">
                Show upcoming schedules
              </label>
              <input className="form-check-input" type="checkbox" id="flexSwitchGrpSchedules" value="" checked={showComingSch}
                onChange={() => dispatch(setShowComingSchedule(!showComingSch))}
              />
            </div>
          </div>
          <div className='separator border-gray-200'></div>
          
        </div>
      </div>      
    </div>
  </>)
}