import { FC, useState } from "react"
import { TFAQRCode } from "../../../../../commons/widgets/TFAQRCode"
import { useAuth } from "../../../../auth"
import { staffMFA } from "../../../../../redux/slices/staff/staffModels"
import axios from "axios"
import { useUpdateMFAStaffMutation, useValidateMFAStaffMutation } from "../../../../../redux/slices/staff/api3StaffSlice"
import Swal from "sweetalert2"

type Props = {
    className?: string
}
export function getStaffMFA(staffId: string | undefined){
    const API_URL: string | undefined = process.env.REACT_APP_API3_URL
    return axios.get<{result:staffMFA}>(`${API_URL}/staff/${staffId}/mfa`)

}

const DrawerSetupMFA: FC<Props> = ({ className }) => {
    const {auth} = useAuth()
    const currentTFA = auth?.extra? auth.extra.tfa : 0

    const [tfa, setTfa] = useState(currentTFA)
    const [mfaSecret, setMfaSecret] = useState('')
    const [mfaQrCode, setMfaQrCode] = useState('')
    const [validateCode, setValidateCode] = useState('')
    const [validateStatus, setValidateStatus] = useState(0)
    const [appAvailable, setAppAvailable] = useState(true)
    const [loading, setLoading] = useState(false)

    const [validateAuthenticationApp] = useValidateMFAStaffMutation();
    const [updateMFA] = useUpdateMFAStaffMutation();

    const staffId = auth?.extra? auth.extra.id : "";
    const handleAuthenticationApp = async() =>{
        setTfa(1)
        if(mfaSecret == '')
        {
            try{
                let result = await getStaffMFA(staffId)
                let staffMfa:any = Object.values(result)
                if(staffMfa[0].status)
                {
                    let data = staffMfa[0].data
                    setMfaSecret(data.mfaSecret)
                    setMfaQrCode(data.qrCode)
                }
            }catch(error){
                setAppAvailable(false)
            }
        }
    }
    const validateMFA = async () => {
        setValidateStatus(0)
        setLoading(true)
        try{
            let data = {
                id: staffId,
                code: validateCode,
            }
            let resp = await validateAuthenticationApp(data)
            let result:any = Object.values(resp)
            if(result[0].code == 200)
            {
                setValidateStatus(1)
            }else{
                setValidateStatus(2)
            }
            
            setLoading(false)
        }catch(error)
        {
            setValidateStatus(2)
            setLoading(false)
        }
    }
    const udpateMFA = async () => {
        setLoading(true)
        try{
            let data = {
                id: staffId,
                mfa: tfa,
            }
            let resp = await updateMFA(data)
            let result:any = Object.values(resp)
            if(result[0].code == 200)
            {
                Swal.fire('Success', 'Successful update MFA', 'success')
            }else{
                Swal.fire('Error', 'Unable to update MFA', 'error')
            }
            setLoading(false)
        }catch(error)
        {
            Swal.fire('Error', 'Unable to update MFA', 'error')
            setLoading(false)
        }
    }
    return (
        <>
            <div
            
                id="kt_drawer_setup_mfa"
                data-kt-drawer-toggle={`#kt_drawer_setup_mfa_button`}
                data-kt-drawer-close={`#kt_drawer_setup_mfa_close`}
                tabIndex={-2}
                className={`offcanvas offcanvas-end bg-white ${className}`}
                // data-kt-drawer="true"
                data-kt-drawer-activate="true"
                aria-labelledby="staticBackdropLabel"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'300px', 'md': '500px'}"
            >
                <div className="offcanvas-header bg-light-primary">
                    <h5 className="offcanvas-title text-primary" id="staticBackdropLabel">
                        <i className="fa fa-key text-primary"></i> Two Factor Authentication
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body text-start">
                    <div style={{ maxWidth: "750px" }}>
                        {
                            /**
                             * Display two factor authentication selection.
                             */
                        }
                        <>
                            <div className='text-muted fs-7 mb-5 text-start'>
                                Two-factor authentication protect your account by requiring an additional code when you sign in your account.
                            </div>
                            <div className='fw-bolder text-dark mb-5 text-start'>
                                Choose your security method
                            </div>
                            {/* User APP*/}
                            <div className="border border-secondary rounded-top p-5">
                                <div className='d-flex mb-3'>
                                    <div className="form-check form-check-custom form-switch w-100">
                                        <input className="form-check-input h-20px w-35px" type="radio" defaultChecked={tfa==1} value="1" onClick={handleAuthenticationApp} name="mfa" id="mfa_use_app" />
                                        <label className="form-check-label" htmlFor="mfa_use_app">
                                            <span className="me-2 text-dark">
                                                Authentication app
                                            </span>

                                        </label>
                                    </div>
                                    <div className="text-end">
                                        <span className='text-success fs-7'>
                                            [Recommended]
                                        </span>
                                    </div>
                                </div>
                                <div className='text-muted'>
                                    <span className='text-muted'>
                                        Get access code from authentication app.
                                    </span>
                                </div>
                                {currentTFA != 1 &&
                                <div id="use_app" className={`mt-5 d-${tfa == 1 ? 'block' : 'none'}`}>
                                    {mfaSecret != '' ?
                                    <>
                                    <TFAQRCode mfaSecret={mfaSecret} mfaQRCode={ mfaQrCode} />
                                    <div className='text-grey-200 mb-3 mt-3'>
                                        3. Validate authentication app
                                        <input
                                            type='tel'
                                            placeholder='Enter code'
                                            autoComplete='off'
                                            name="passcode"
                                            className='form-control mb-3 mt-3'
                                            value={validateCode}
                                            onChange={evt => setValidateCode(evt.target.value)}
                                        />
                                    </div>
                                    </>
                                    :
                                    <>
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                    Getting QR Code. Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2 mb-3'></span>
                                    </span>
                                    </>
                                    }
                                    { validateStatus > 0 && (
                                        validateStatus == 1 ?
                                        <div className='text-success fs-7'>
                                            App validated successful.
                                        </div>
                                        :
                                        <div className='text-danger fs-7'>
                                            App validated failed.
                                        </div>
                                        )
                                    }
                                    <button
                                        type='submit'
                                        id='kt_sign_up_submit'
                                        className='btn btn-light-info mb-5'
                                        onClick={() => validateMFA()}
                                    >
                                        {!loading && <span className='indicator-label'>Validate</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                }
                            </div>
                            {/* User Email*/}
                            <div className="border border-top-0 border-secondary p-5">
                                <div className='d-block mb-3'>
                                    <div className="form-check form-check-custom form-switch w-100">
                                        <input className="form-check-input h-20px w-35px" type="radio" value="2" defaultChecked
                                        ={tfa==2} onClick={() => { setTfa(2) }} name="mfa" id="mfa_use_email" />
                                        <label className="form-check-label" htmlFor="mfa_use_email">
                                            <span className="me-2 text-dark">
                                                Email access code
                                            </span>

                                        </label>
                                    </div>
                                    <div className="text-end">
                                        <span className='text-success'>

                                        </span>
                                    </div>
                                </div>
                                <div className='text-muted'>
                                    <span className='text-muted'>
                                        We'll send an access code to your registered email address.
                                    </span>
                                </div>
                            </div>
                            {/* No TFA*/}
                            <div className="border border-top-0 rounded-bottom border-secondary p-5">
                                <div className='d-flex mb-3'>
                                    <div className="form-check form-check-custom form-switch w-100">
                                        <input className="form-check-input h-20px w-35px" type="radio" value="0" defaultChecked={tfa==0} onClick={() => { setTfa(0) }} name="mfa" id="mfa_use_none" />
                                        <label className="form-check-label" htmlFor="mfa_use_none">
                                            <span className="me-2 text-dark">
                                                Disable 2FA
                                            </span>

                                        </label>
                                    </div>
                                    <div className="text-end w-100">
                                        <span className='text-danger fs-'>
                                            [Not Recommended]
                                        </span>
                                    </div>
                                </div>
                                <div className='text-muted'>
                                    <span className='text-muted'>
                                        No access code require after successful login.
                                    </span>
                                </div>
                            </div>

                            <div className='card-footer d-flex justify-content-end pt-5'>
                                <button type="submit" data-bs-dismiss="offcanvas" aria-label="Close" className="btn btn-primary"
                                    disabled={(tfa == 1 && validateStatus != 1)} onClick={() => udpateMFA()}
                                >
                                    <span><i className="fa fa-save me-1 mt-n1"></i>
                                    Save
                                    </span>

                                </button>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DrawerSetupMFA }