import { Task } from "gantt-task-react";
import { KTSVG } from "../../../../../_metronic/helpers";

type TaskListHeaderProps = {
    headerHeight: number    
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
}
  
export const TaskListHeader : React.FC<TaskListHeaderProps> = ({
    headerHeight, rowWidth, fontFamily, fontSize
}) => {    
    return (
        <div
            className="text-dark fs-5"
            style={{                
                height: headerHeight,
                fontFamily: "sans-serif",
                fontWeight: "bold",
                paddingLeft: 0,
                margin: 0,
                marginBottom: -1,
                display: "flex",
                alignItems: "center",
                borderTopLeftRadius: `8px`,
                borderBottom: "2px solid #9C258F",
            }}
        >
            <div className="ps-3 text-gray-800" style={{minWidth:"155px"}}>Device Group Schedules</div>
            {/* <div className="ps-5 text-white" style={{minWidth:"155px"}}>Start Date</div>
            <div className="ps-5 text-white" style={{minWidth:"155px"}}>End Date</div> */}
        </div>
    )
}