import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Content, FontList } from './contentModels';
import { getAuthModel } from '../../auth'


const API_URL : string | undefined  = process.env.REACT_APP_API_URL
// const API_URL : string | undefined  = process.env.REACT_APP_API_URL_DEV

export const contentsApi = createApi({

    reducerPath: "contentsApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{
            const auth = getAuthModel()            
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['Content', 'Schedule', 'Sched'],

    // global configuration for the api
    //----------------------------------------
    // keepUnusedDataFor: 30,
    refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,

    endpoints: (builder) => ({
        getAllContents : builder.query<Content[], number>({
            query: () => "campaign",
            providesTags: ['Content'],
            keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        }),
        
        getAllPlaylists : builder.query<Content[], void>({
            query: () => "playlist",
            providesTags: ['Content'],
            keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        }),

        // getAllContents : builder.query<Content[], number>({
        //     query: () => "contents",
        //     providesTags: ['Content'],
        //     // keepUnusedDataFor: 5,   // configuration for an individual endpoint, overriding the api setting            
        // }), 
        getContent : builder.query<Content[], string>({
            query: (id) => `campaign/${id}`,
            providesTags: ['Content']
        }),

        getFontList : builder.query<FontList[], void>({
            query: () => `font/list`
        }),
        // ----------------------------------------------------

        // addContent: builder.mutation<void, any>({
        addContent: builder.mutation<Content, any>({
            query: (data) => ({
                    url: "campaign",
                 method: "POST",        
                 body: data
             }),
             invalidatesTags: ['Content']
        }),
        updateContent: builder.mutation<void, any>({
            query: ({contentId, ...rest}) => ({
                url: `campaign/${contentId}`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Content', 'Schedule', 'Sched']            
        }),
        duplicateContent: builder.mutation<void, any>({
            query: ({id, ...rest}) => ({
                url: `campaign/${id}/copy`,                
                method: "POST",     
                body: rest
            }),
            invalidatesTags: ['Content', 'Schedule', 'Sched']            
        }),
        deleteContent: builder.mutation<void, string>({
            query: (id) => ({
                url: `campaign/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['Content', 'Schedule', 'Sched']
        }),
        /**
         * Emergency content: Start
         */
        setEmergencyContent: builder.mutation<any, string>({
            query: (contentId) => ({
                url: `/campaign/${contentId}/Emergency/set`,
                method: "POST"
            }),
            invalidatesTags: ['Content']            
        }),

        unsetEmergencyContent: builder.mutation<any, string>({
            query: (contentId) => ({
                url: `/campaign/${contentId}/Emergency/unset`,
                method: "POST"
            }),
            invalidatesTags: ['Content']            
        }),

        activateEmergencyContent: builder.mutation<any, string>({
            query: (contentId) => ({
                url: `/campaign/${contentId}/Emergency/activate`,
                method: "POST"
            }),
            invalidatesTags: ['Content']            
        }),

        deactivateEmergencyContent: builder.mutation<any, string>({
            query: (contentId) => ({
                url: `/campaign/${contentId}/Emergency/deactivate`,
                method: "POST"
            }),
            invalidatesTags: ['Content']            
        }),
        /**
         * Emergency content: End
         */


    })
})

export const { 
    useGetAllContentsQuery, 
    useGetAllPlaylistsQuery,
    // useGetContent,
    useGetFontListQuery,
    useAddContentMutation,
    useUpdateContentMutation,
    useDuplicateContentMutation,
    useDeleteContentMutation,

    useSetEmergencyContentMutation,
    useUnsetEmergencyContentMutation,
    useActivateEmergencyContentMutation,
    useDeactivateEmergencyContentMutation,
} = contentsApi