/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useAppSelector, useAppDispatch } from "../../../../../redux/hook";
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
// import { useGetAllMediasQuery } from '../../../../redux/slices/media/apiMediaSlice';
import * as yup from 'yup'
import Swal from 'sweetalert2'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled, withStyles } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps } from '@mui/material/Accordion';
import {  AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { Box, Slider, TextField } from '@mui/material';
import moment from 'moment';
import { 
  Element,
  Source,
  secondsToHHmmss 
} from '../../../../../redux/slices/content/contentModels';
import { setContentSource, setContentSourceLayer, setHoverLayerId, setHoverLayerSourceId, setHoverSectionId, setLayerId, setSectionId } from '../../../../../redux/slices/content/contentSlice';
import { getSectColorCodes, getSectionIcon } from '../../../../../redux/slices/editor/editorModels';
import { useUpdateContentMutation } from '../../../../../redux/slices/content/apiContentSlice';
import { yupResolver } from '@hookform/resolvers/yup';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
// import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
//----------------------------------------

type Props = {
  className?: string,
}


const schema = yup.object().shape({
  // group_name: yup.string().required().min(3).max(100),
})



//----------------------------------------
const AccordionLayerAnimation: React.FC<Props> = ({className}) => {
    
  const dispatch = useAppDispatch()  
    //-----------------------------------------
    const [updateContent] = useUpdateContentMutation();
    
    //--------------------------------------------- 
    const currPersistContent = useAppSelector(state => state.persistor._content.currContent)  
    // console.log("currPersistContent in Edit Layer: ",currPersistContent)

    const currSectId = useAppSelector(state => state.content.currSectionId)  
    // console.log('currSectId:',currSectId)

    const currLayerId = useAppSelector(state => state.content.currLayerId)  
    // console.log('currLayerId:',currLayerId)
    
    const selectedLayer = currPersistContent.elements.find((sect)=>sect.element_id === currSectId)?.sources.find((layer)=>layer.source_list_id === currLayerId)
    // console.log('selectedLayer:',selectedLayer)
    // console.log("...selectedLayer", {...selectedLayer})

    // const [isDeleted, setIsDeleted] = useState(false);
    // const currentFolder = useAppSelector(state => state.editor.currentFolder)  
    // const [updateFolder] = useUpdateFolderMutation();
    // const [deleteFolder] = useDeleteFolderMutation();
    //-----------------------------------------    
    
    const { 
        register, 
        setValue,
        handleSubmit,
        // control,
        reset,
        watch,
        setError,
        formState:{
            errors,
            isDirty,
            
            isLoading,         
            isValid,   
            isSubmitting,
            isSubmitSuccessful
            
        }
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues:{            
            ...selectedLayer
        }
    }) 
    //---------------------------------------------------- 
    
    // console.log('watch', watch())
    // console.log('watch', watch(["name", "displayType", "category"]))    
    

    //---------------------------------------------------- 
    const formSubmitHandler: SubmitHandler<Source> = async (layer: Source) => {        
        
        try{
            
            var tranformLayer = {
              ...layer,
              param_source: [{
                    ...layer?.param_source,
                    duration: (layer? (layer.param_source[0].duration? layer.param_source[0].duration: 0): 0) * 1000
              }]
            }       
            console.log("tranformLayer in submit",tranformLayer)

            dispatch(setSectionId(currSectId))  //which section it belongs to
            dispatch(setLayerId(currLayerId))  //which section it belongs to
            // dispatch(setContentSourceLayer(layer))
            dispatch(setContentSourceLayer(tranformLayer))
        


            // console.log("currPersistContent after submit: ",currPersistContent)


            // const msg = ( layer.source_list_id )
            // Swal.fire(
            //     'Layer Update: '+ (msg !== "" ? "<br/>&nbsp;<br/><div className='my-5'>"+msg+"</div><br/>":""),
            //     'has been successfully done!',
            //     'success'
            // )
        }catch(error){
            Swal.fire(
                'Layer: '+ layer.source_list_id,
                'There is something wrong during the update request!',
                'error'
            )            
        
        }
    }
   
    // ---------------------------
    useEffect(() => { 
        //console.log("selectedLayer in useEffect",selectedLayer)
        var tranformSelectedLayer = {
          ...selectedLayer,
          param_source: [{
                ...selectedLayer?.param_source,
                duration: (selectedLayer? (selectedLayer.param_source[0].duration? selectedLayer.param_source[0].duration: 0): 0)/1000
          }]
        }       
        //console.log("tranformSelectedLayer in useEffect",tranformSelectedLayer)

        reset({
            // ...selectedLayer,
            ...tranformSelectedLayer
            // param_source: [{
            //     ...selectedLayer?.param_source,
            //     duration: parseInt(selectedLayer?selectedLayer.param_source[0].duration: 0)
            // }]
        })
    }, [ selectedLayer, reset ])

  //-----------------------------------------
  return (<>
    {selectedLayer && selectedLayer.source_list_id !== "" && 
      <Accordion 
        // TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={true}
        square={true}
        disableGutters={true}          
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className='p-3 bg-primary'        
        >
          <Typography>
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-3 text-white me-2' 
            // style={{transform: "rotate(45deg)"}}
            />
            <span className='text-white fw-semibold'>Animation</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className='m-0 px-3'      
          // className='m-0 px-3 overflow-auto'      
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                // 'MobileTimePicker', 
                'MobileTimePicker', 
                'TimePicker'
              ]}
            >
              {/* <DemoItem label={'"hours", "minutes" and "seconds"'}>
                <MobileTimePicker views={['hours', 'minutes', 'seconds']} />
              </DemoItem>
              <DemoItem label={'"hours"'}>
                <MobileTimePicker views={['hours']} />
              </DemoItem> */}
              <DemoItem label={'Duration (mm:ss)'}>
                <MobileTimePicker views={['minutes', 'seconds']} format="mm:ss" />
              </DemoItem>
              {/* <DemoItem label={'"minutes" and "seconds"'}>
                <TimePicker views={['minutes', 'seconds']} format="mm:ss" />
              </DemoItem> */}
              {/* <DemoItem label={'"minutes" and "seconds"'}>
                <TimePicker views={['minutes', 'seconds']} format="mm:ss" />
                <TimePicker
                  label="With Time Clock"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
                <MobileTimePicker
                  label={'"seconds"'}
                  openTo="seconds"
                  views={['hours','minutes', 'seconds']}
                  format="hh:mm:ss"
                />
              </DemoItem> */}
              {/* <DemoItem label={'"minutes" and "seconds"'}>
                <MultiSectionDigitalClock views={['minutes', 'seconds']} />
              </DemoItem> */}
            </DemoContainer>
          </LocalizationProvider>
          {/* <form id="frm-edit-layer" className='form'  
              // onSubmit={handleSubmit(formSubmitHandler)}
              onChange={handleSubmit(formSubmitHandler)}
          >              
              <div className="mb-4">
                  <TextField 
                      {...register(`param_source[0][duration]`)} 
                      type="number"
                      label="Duration (sec)" 
                      fullWidth 
                      margin='dense'
                      variant='outlined' 
                      InputLabelProps={{ shrink: true }}
                      // error={!! errors.group_name}
                      // helperText={`${errors.group_name?.message ?? ''}`}
                      // focused={watch(`param_source[0][duration]`) ? true:false}                                    
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                    >
                      <DemoItem label={'"hours", "minutes" and "seconds"'}>
                        <TimePicker views={['hours', 'minutes', 'seconds']} />
                      </DemoItem>
                      <DemoItem label={'"hours"'}>
                        <TimePicker views={['hours']} />
                      </DemoItem>
                      <DemoItem label={'"minutes" and "seconds"'}>
                        <TimePicker views={['minutes', 'seconds']} format="mm:ss" />
                      </DemoItem>
                    </DemoContainer>
                    
                    <DemoContainer
                        components={[
                        // 'DateTimePicker',
                        'MobileDateTimePicker',
                        // 'DesktopDateTimePicker',
                        // 'StaticDateTimePicker',
                        ]}
                    >                        
                        <DemoItem>
                            <MobileDateTimePicker 
                                className='mb-5'
                                label="Start Date & Time" 
                                value={startDateTime}
                                defaultValue={dayjs()} 
                                onChange={(newVal)=>{ 
                                    // setStartDateTime(newVal)
                                    // setValue("start_time", newVal? newVal.format('YYYY-MM-DD HH:mm:ss'):"")
                                }}
                            />                            
                        </DemoItem>                                    
                    </DemoContainer>
                </LocalizationProvider>
                  
              </div>              
              
          </form> */}
        </AccordionDetails>
      </Accordion>
    }    
  </>)
}

export {AccordionLayerAnimation}