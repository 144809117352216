import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {resendActivationEmail} from '../core/_requests'
import { Recaptcha } from '../../../commons/widgets/ReCaptcha'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
})

export function ResendActivateEmail() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
      try {
        setLoading(true)
        setHasErrors(undefined)
        const data = await resendActivationEmail(values.email)
        if(data.data.code !== 200)
        {
            setHasErrors(true)
        }
        else   {
            setHasErrors(false)
        }
      } catch (error) {
        setHasErrors(true)
        setSubmitting(false)
        setLoading(false)
      }
    }
      /*
      setTimeout(() => {
        resendActivationEmail(values.email)
          .then(({data: {result}}) => {
            console.log(result)
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Oop, something went wrong.')
          })
      }, 1000)
    },*/
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Resend Account Activation Email</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to get account activation email.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
          Unable to send account activation email. <br />
          Please consider one of the following option<br /><br />
          <Link 
          to='/auth/registration'
          >
          1. Sign up a new account
          </Link><br />
          <Link 
          to='/auth/forgot-password'
          >
          2. Forgot account password
          </Link><br />
          <a href='https://superbolt.com.au/contact'>
            3. Contact the Superbolt support team
          </a>
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-lg-15 alert alert-success'>
        <div className='alert-text font-weight-bold'>
        Please check your email for Superbolt account activation instructions. If you did not receive this email, please re-check your details and try again otherwise contact our support team.
        </div>
      </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}      
      <div className='text-center'>
        <button type='submit' 
          id='kt_password_reset_submit' 
          className='btn btn-lg btn-gradient-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/registration'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'            
            className='btn btn-lg btn-secondary w-100 mb-5'
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
      <Recaptcha pageName='resendActivateEmail' />
    </form>
  )
}
