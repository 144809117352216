/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Component, MouseEventHandler, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../../app/commons/css/customGlobal.css"
import { useAppSelector, useAppDispatch } from "../../../../redux/hook";

import { contentPreviewPath, isSimilarContent, validateURL } from '../../../../redux/slices/content/contentModels';
import { closeFullscreen, openFullscreen } from '../../../../redux/slices/editor/editorModels';
import { Translate } from '@mui/icons-material';
import { setCheckSum } from '../../../../redux/slices/content/contentSlice';

//----------------------------------------

type Props = {
  id: string,
  className: string,
}


//----------------------------------------
const ModalPreviewContent: React.FC<Props> = ({ id, className}) => {

  const dispatch = useAppDispatch()
  //-----------------------------------------
  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  // console.log("currContentPersistor: ", currContentPersistor)    

  const [fullScreenMode, setFullScreenMode] = useState(false)

  const chckSum = useAppSelector(state => state.persistor._content.checkSum)
  // console.log("chckSum: ", chckSum)    

  const ratioW = 0.6
  const ratioH = 0.9
  
  const BORDERSIZE = 6  //6px
  const [previewScreenW, previewScreenH] = currContentPersistor.width >= currContentPersistor.height 
                                                ? [Math.round(ratioW  * window.innerWidth), Math.round((9/16) * ratioW * window.innerWidth)]
                                                : [Math.round((9/16) * ratioH * window.innerHeight), Math.round(ratioH * window.innerHeight)]  
  // console.log("previewScreenW : ",previewScreenW)
  // console.log("previewScreenH : ",previewScreenH)

  // ----------------------------------------------
  

  // useEffect(() => {
  //   if (currContentPersistor.width >= currContentPersistor.height){
  //     setPreviewScreenW(Math.round(ratio  * window.innerWidth))
  //     setPreviewScreenH(Math.round((9/16) * ratio * window.innerWidth))
  //   }else{
  //     setPreviewScreenW(Math.round((9/16) * ratio * window.innerHeight))
  //     setPreviewScreenH(Math.round(ratio  * window.innerHeight))
  //   }
  // }, [window.innerWidth, window.innerHeight, currContentPersistor.width >= currContentPersistor.height ])
  
  //-----------------------------------------
  let allowPreviewContent = true;
  currContentPersistor.elements.map((element) =>{
      if(element.element_type == "web"){
          element.sources.map((source) => {
            source.param_source.map((paramSource) =>{
              if ((paramSource.url != undefined) && validateURL(paramSource.url)){
                try{
                  let domain = (new URL(paramSource.url));
                  if(domain.hostname != "app.superbolt.com.au"){
                    allowPreviewContent =  false;
                  }
                }catch(err){
                  console.log("err: ", err)
                }
              }
            })
          })
      }
  })

  return (
    <div className={`modal fade ${className}`} tabIndex={-1} id={id} style={{zIndex: 2051}}>
      <div className={`modal-dialog ${fullScreenMode === false ? "modal-xl":"modal-fullscreen"} modal-dialog-centered`}      
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width:  fullScreenMode === false 
                                            ? (previewScreenW+BORDERSIZE)+"px" 
                                            : (currContentPersistor.width >= currContentPersistor.height? "100vw": "calc(100vh - 300px)"), 
          height: fullScreenMode === false  ? (previewScreenH+BORDERSIZE)+"px" 
                                            : "100%",                  
          // WebkitTransform:(fullScreenMode === true && currContentPersistor.width < currContentPersistor.height ? "translate(75%)":"translate(0%)"),
          // transform:      (fullScreenMode === true && currContentPersistor.width < currContentPersistor.height ? "translate(75%)":"translate(0%)"),
          // transform: "translate(-25%, 0%)"
          
          // top:"50%",
          // left:"50%"
          // marginLeft:     (fullScreenMode === true && currContentPersistor.width < currContentPersistor.height ?"300px":""),
          // marginLeft:     (fullScreenMode === false && window.innerWidth < 768 ?"70px":"auto"),
        }}
      >      
        <div className={`modal-content rounded-0 shadow-lg`}
          style={{
            height: fullScreenMode === false ? previewScreenH+6+"px" : "100%", 
            padding: fullScreenMode === false ? "3px" : "0px",
            background: "repeating-linear-gradient(-45deg, #150232, #150232, #030008)",
          }}
        >          
          <div className={`modal-body p-0 ${fullScreenMode === false ? "shadow-lg":""}` }
            onMouseOver={()=> document.getElementById('preview-content-name')?.classList.remove('d-none')}
            onMouseOut={()=> document.getElementById('preview-content-name')?.classList.add('d-none')}
            // style={{width: previewScreenW+"px", height: previewScreenH+"px"}}
          >
            {allowPreviewContent && 
            currContentPersistor && currContentPersistor.user_template_id !== "" ? 
            <iframe 
              key={chckSum}
              id="iframe-preview-content"
              title='Content preview'
              src={contentPreviewPath + currContentPersistor.user_template_id +".json"}
              className='w-100 h-100'
              scrolling="no"
              loading="lazy"
              style={{overflow:"hidden"}}
            ></iframe>
            :
            <div className='text-center bg-white text-black h-100' style={{verticalAlign:"middle"}}>
              <h5 style={{paddingTop:"25%"}} className='text-muted'>The campaign contains website which denies preview.</h5>
              <h4>The campaign will play as normal in the target devices.</h4>
            </div>
            }
            <div id="preview-content-name" className='d-none position-absolute top-0 start-0 d-flex justify-content-end py-2 px-2 '>
              <span className="fs-5 text-capitalize text-gray-600">{currContentPersistor.user_template_name}</span>
            </div>
            <div className='position-absolute top-0 end-0 d-flex justify-content-end py-2 px-2 '>
              <a  href="#" 
                  className="btn btn-link text-gray hover-elevate-up pt-0"
                  data-bs-toggle="offcanvas"
                  aria-controls="staticBackdrop"
                  data-bs-target="#kt_drawer_instant_play"
                  
                  id="kt_drawer_instant_play_button"
                  data-kt-drawer-show="true" 

                  data-tooltip-content="Instant Play" 
                  // onClick={() => { dispatch(setContent(content)); }}     
                  
                  onClick={()=> {                  
                    closeFullscreen()
                    setFullScreenMode(false)
                  }}
              >
                  {/* <i className='bi bi-lightning-fill text-light fs-4 me-1' /> */}
                  <button 
                    className='btn btn-icon btn-sm btn-transparent tooltip-bottom'
                    data-tooltip-content={`Instant Play`}

                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className={`bi bi-lightning-fill text-hover-primary fs-3`}></i>
                  </button>
              </a>

              {/* <button 
                className='btn btn-icon btn-sm btn-transparent tooltip-bottom'

                data-bs-dismiss="modal"
                aria-label="Close"               

                data-bs-toggle="offcanvas"
                aria-controls="staticBackdrop"
                // data-bs-target="#kt_drawer_instant_play"
                
                id="kt_drawer_instant_play_button"
                data-kt-drawer-show="true" 
                data-tooltip-content={`Instant Play`}

                
                onClick={()=> {
                    closeFullscreen()
                    setFullScreenMode(false)
                }}                
              >
                <i className={`bi bi-lightning-fill fs-3`}></i>
              </button> */}
              <button 
                className='btn btn-icon btn-sm btn-transparent tooltip-bottom'
                onClick={()=> {
                  setFullScreenMode(! fullScreenMode)
                  fullScreenMode === false ? openFullscreen(): closeFullscreen()
                }}
                data-tooltip-content={`${fullScreenMode === false ? 'Fullscreen':'Minimize'} `}
              >
                <i className={`fa fa-${fullScreenMode === false ? "expand":"compress"} text-hover-primary fs-2`}></i>
              </button>
              <button 
                className='btn btn-icon btn-sm btn-transparent tooltip-bottom'
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=> {                  
                  closeFullscreen()
                  setFullScreenMode(false)
                }}
              >
                <i className={`fa fa-times text-hover-primary fs-2`}></i>
              </button>              
            </div>
          </div>          
        </div>
      </div>
    </div>
  )
}

export { ModalPreviewContent }
