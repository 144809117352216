import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { EmailContent } from './emailModels';
import { getAuthModel } from "../../auth";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
//const API_URL : string | undefined  = "https://superbolt.com.au/api/"

export const emailsApi = createApi({
    reducerPath: "emailsApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{
            const auth = getAuthModel()            
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['EmailContent'],

    // global configuration for the api
    //----------------------------------------
    // keepUnusedDataFor: 30,
    refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,

    endpoints: (builder) => ({

        sendEmail: builder.mutation<void, EmailContent>({
            query: (email) => ({
                url: "email/contact",
                method: "POST",
                body: email
            }),
            invalidatesTags: ['EmailContent']
        }),
        // updateFolder: builder.mutation<void, Folder>({
        //     query: ({source_group_id, ...rest}) => ({
        //         url: `source/group/${source_group_id}`,
        //         method: "PUT",
        //         body: rest
        //     }),
        //     invalidatesTags: ['Folder', 'RootFolders']            
        // }),
        // deleteFolder: builder.mutation<void, string>({    
        //     query: (id) => ({
        //         url: `source/group/${id}`,
        //         method: "DELETE"
        //     }),
        //     invalidatesTags: ['RootFolders', 'Medias']
        // }),
        // uploadFiles: builder.mutation<void, any>({
        //     query: (media) => ({
        //         url: "source",
        //         method: "POST",
               
        //         body: media
        //     }),
        //     invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        // }),
        // // updateMedia: builder.mutation<void, Media>({
        // //     query: ({id, ...rest}) => ({
        // //         url: `medias/${id}`,
        // //         method: "PUT",
        // //         body: rest
        // //     }),
        // //     invalidatesTags: ['Media']            
        // // }),        
        // deleteMedia: builder.mutation<void, string>({
        //     query: (id) => ({                
        //         url: `source/${id}`,
        //         method: "DELETE"
        //     }),
        //     invalidatesTags: ['Medias', 'Media', 'MediaInputFields', 'RootFolders']
        // }),
    })
})

export const { 
    useSendEmailMutation,

    // useGetAllRootFoldersQuery,
    // useGetAllMediasQuery, 
    // useGetMediaQuery,

    // useAddFolderMutation,
    // useUpdateFolderMutation,
    // useDeleteFolderMutation,
    // useUploadFilesMutation,
    // // useUpdateMediaMutation,
    // useDeleteMediaMutation,

} = emailsApi