import { FC } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { SubAccountHeader } from '../SubAccountHeader'
import { TableSubAccount } from '../widgets/TableSubAccount'
import { DrawerSubAccount } from '../drawers/DrawerSubAccount'

export function Index() {

  return (
    <>
      <PageTitle breadcrumbs={[]}> </PageTitle>

      <div className='d-flex flex-row h-100 bg-gray-200 mb-9' style={{ marginLeft: "-30px", marginRight: "-30px" }}>
        <div className='d-flex flex-column flex-row-fluid'>
          <div className="d-flex flex-column-auto flex-start">
            <SubAccountHeader className='w-100 h-100 py-1 px-9' />
          </div>
        </div>
      </div>

      {/* Contents */}
      <div className='row gy-5 gx-xl-8 h-md-300 mb-9'>
        <div className='col-lg-12 col-xl-12'>
          <DrawerSubAccount />
          <TableSubAccount className='card-xxl-stretch mb-5 mb-xl-8 h-md-100' />
        </div>
      </div>
    </>
  )
}


