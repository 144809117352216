/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useAppSelector } from '../../../../redux/hook'
import { Sched, extractCurrActiveSchedules, extractRepeatModeFromSchedule, schedulePreviewPath } from '../../../../redux/slices/schedule/scheduleModels'
import { useGetAllGroupsQuery, useGetGroupQuery } from '../../../../redux/slices/group/apiGroupSlice'
import { Group } from '../../../../redux/slices/group/groupModels'
import { TableDevices } from '../../../group/components/GroupComponents'
import { Link } from 'react-router-dom'
import { CardContent } from '../../../content/components/ContentComponents'
import moment from 'moment'
import { Content, RECENTPERIOD, getRibbonType } from '../../../../redux/slices/content/contentModels'
import Slider from 'react-slick'
import { ScheduleInfo } from '../ScheduleComponents'
import { useGetAllSchedulesQuery } from '../../../../redux/slices/schedule/apiScheduleSlice'



type Props = {  
    className?: string,
}



// --------------------------------------------------
// Main component
// --------------------------------------------------
const DrawerViewGroupDetail: FC<Props> = ({className}) => {

    const currSchId = useAppSelector(state => state.schedule.currSchedId)  
    const allSchs   = useAppSelector(state => state.schedule.allScheds)  
    //---------------------------------------------------- 

    const { data: deviceGrps, isLoading: isLoadingDevGrps, isFetching: isFetchingDevGrps, isSuccess, isError } = useGetAllGroupsQuery(5, { refetchOnMountOrArgChange: true }) 

    const selectedGrp = deviceGrps?.filter((grp)=> {return grp.id === currSchId})[0] 

    const currGrpSchs: Sched[] = allSchs.filter((sch)=> {return sch.group_id === currSchId})    
    // console.log("currGrpSchs:",currGrpSchs)

    let { data: allSchedules, isLoading:isLoadingSchedules, isFetching: isFetchingSchedules} = useGetAllSchedulesQuery(5, { refetchOnMountOrArgChange: true })  
    // const [currActiveSchedIds  , setCurrActiveSchedIds] = useState<string[]>([]);  
    const currActiveSchedIds = useAppSelector(state => state.persistor._schedule.currActiveSchedIds) // for just pure a list of all sched

    // console.log("currActiveSchedIds:",currActiveSchedIds)

    // useEffect(()=>{
    //     if (allSchedules){
    //         setCurrActiveSchedIds(extractCurrActiveSchedules(allSchedules, moment()))
    //     }
    // },[allSchedules])
    //----------------------------------------------------
    return (
        <>  
            <div
                id='kt_drawer_view_group_schedule'
                className='bg-body '
                style={{zIndex: 2050}}
                data-kt-drawer='true'
                data-kt-drawer-name='drawer_view_group_schedule'
                data-kt-drawer-activate='true'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width="{default:'300px', 'md': '350px', 'lg': '450px'}"
                
                // data-kt-drawer-direction='end'
                data-bs-backdrop="static" 
                data-kt-drawer-toggle='#kt_drawer_view_group_schedule_toggle'
                data-kt-drawer-close='#kt_drawer_view_group_schedule_close'
                aria-labelledby="staticBackdropLabel"
            >
                <div className='card shadow-none w-100'>
                    <div className='card-header bg-gradient-primary rounded-0' id='kt_drawer_view_group_schedule_header'>                        
                        <h3 className="card-title text-white text-capitalize">
                            <KTSVG
                                path={`/media/icons/duotune/files/fil012.svg`}
                                className={`svg-icon-2x svg-icon-white me-3 mt-0 d-inline`}
                            />                            
                            {selectedGrp && `${selectedGrp.group_name}`}
                        </h3>

                        <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                                id='kt_drawer_view_group_schedule_close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </button>
                        </div>
                    </div>
                    <div className='card-body position-relative' id='kt_drawer_view_group_schedule_body'>                        
                        
                        <div
                            id='kt_drawer_view_group_schedule_scroll'
                            className='position-relative scroll-y me-n5 pe-5 text-start'
                            data-kt-scroll='true'
                            data-kt-scroll-height='auto'
                            data-kt-scroll-wrappers='#kt_drawer_view_group_schedule_body'
                            data-kt-scroll-dependencies='#kt_drawer_view_group_schedule_header, #kt_drawer_view_group_schedule_footer'
                            data-kt-scroll-offset='5px'
                        >
                            {/* List of devices & their device settings */}
                            {/* <div className="">
                                { (selectedGrp && selectedGrp.devices?.length)
                                    ?   <TableDevices 
                                            className="bg-light m-0 p-0 border-bottom-0" 
                                            // listDevices={selectedGrp.devices} 
                                            // allGroups = {deviceGrps}
                                            grpId={selectedGrp.id?selectedGrp.id:""}
                                        />
                                    :   <div 
                                            className='d-flex w-100 h-200px align-items-center justify-content-center' 
                                            style={{
                                                backgroundImage:"url('/media/_schedule/device-not-found.jpg')",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover'
                                            }}
                                        >                                                
                                            <div className='text-center fw-bold'>
                                                <div className='fs-3 text-primary mt-9'>No Device!</div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className='separator border-gray-200 my-5'></div> */}

                            <div className="w-100 p-0">
                            {(currGrpSchs && currGrpSchs.length)
                                ?   currGrpSchs
                                    .slice().sort((a, b) => 
                                        (
                                            // (currActiveSchedIds.includes(b.id)) ||   //active is at the top
                                            (a.sche_layer < b.sche_layer)
                                        ) ? 1 : -1
                                    )
                                    .map((sch)=> {
                                    // console.log("sch", sch)
                                    const repeatMode = extractRepeatModeFromSchedule ([sch])
                                    // ------------------------
                                    return (
                                        <Fragment key={sch.id}>                                            
                                            {sch.user_templates.map((content) => (
                                                <div key={content.user_template_id} 
                                                    className={`w-100 row p-0 pt-2 my-2 ms-2 rounded
                                                        ${currActiveSchedIds.includes(sch.id)? "border border-success bg-light-success":""}
                                                    `}                                                     
                                                >
                                                    <div className="col-7 pt-1"> 
                                                        <CardContent 
                                                            className="" 
                                                            content = {content}
                                                            autoLayout={true}
                                                            displayIcon={false}
                                                            ribbonType = { getRibbonType(content) }                                                             
                                                            actionIcons = {false}
                                                            linkTitle={true}
                                                        />                              
                                                    </div>
                                                    <ScheduleInfo className='col-5 small pt-1 px-0 ' sch={sch} repeatMode={repeatMode} />
                                                </div>

                                            ))}
                                            
                                        </Fragment> 
                                    )})
                                :   <div 
                                        className='d-flex w-100 h-200px align-items-center justify-content-center' 
                                        style={{
                                            backgroundImage:"url('/media/_schedule/schedule-empty.jpg')",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover'
                                        }}
                                    >                                                
                                        <div className='text-center fw-bold'>
                                            <div className='fs-3 text-primary mt-9'>No Scheduled Content!</div>
                                            {/* <div className='btn btn-link' id='kt_drawer_view_group_schedule_close'>
                                                <i className='fa fa-angle-double-right text-gray-700'></i> 
                                                Please schedule a content to this group!
                                            </div> */}
                                        </div>
                                    </div>
                            }
                            </div>
                                    
                                
                            
                            
                            
                            
                            {/* <div className='separator border-gray-200 my-5'></div>
                            Including the analytical and tracking data <br/> */}
                            
                        </div>
                        
                    </div>                    
                </div>
            </div>
        </>
    )
}

export {DrawerViewGroupDetail}