import moment from "moment";
import { Content } from "../content/contentModels";
import { CarbonDate, GroupInput, OptionProps } from "../group/groupModels";
import dayjs, { Dayjs } from "dayjs";
import { DISABLE_SPEEDY } from "styled-components/dist/constants";
import { Task } from "gantt-task-react";


export interface InitialState {
    allScheds: Sched[],
    currSchedId: string,
    isHeightLimited: boolean,  

    showNumberOfDevices: boolean,
    showWarningMsg: boolean,

    showActiveSched: boolean, 
    showExpiredSched: boolean, 
    showComingSched: boolean, 

    enableClearAll: boolean,
    
    editMode: boolean,
    crudSchedStatus: {
                        updateSchedMode: boolean,
                        schedMode: string
                    },
    currActiveSchedIds: string[]
      
}

export interface Schedule{
    id?: string,
    name? : string,

    cacheno     : number,
    category    : string, 
    created_at  : string | null,
    deleted_at  : string | null,
    displayType : string,
    groupId     : string,
    group_location_id:  number,
    group_name  : string,   
    params      : string | null,
    schedules   : Sched[],
    target_layout   : number,
    updated_at  : string | null,
    user_id     : number,
}

export interface Sched{
    schedule_id     : string,
    start_time      : string | null,
    end_time        : string | null,
    
    type_id         : number,
    publish         : number,
    
    id              : string,
    modified_time   : string | null,
    deleted_at      : string | null,
    
    repeat_type     : number,
    end_repeat_time : string | null,

    require_schedule: number,
    overlap_mode    : number,
    sche_layer      : number,
    
    
    group_id        : string,

    user_template_id: string,
    user_templates  : Content[],
    cron?           : Cron,
}

export interface Cron {
    timeRanges     : string,   //"9:30-10:30,18:15-19:05",   "*"    
    dateRange      : string,   //1-31, */2 
    monthRange     : string,   //1-12, */2
    dayOfWeek      : string    //0-7,  */2

    timeRangeInputs? : TimeRangeInput[],    
}

export interface ScheduleLayer{
    id: string,
    layer: number
}

// ----------------------------------
export interface ScheduleInput{
    id?: string,
    name? : string,
    
    start_time: string | null,
    end_time: string | null,
    repeat?: string,
    contents: AssignedContent[],

    deviceGroupIds: string, //"group1,group2,group3"
    groupInputs?: GroupInput[],

    type?: string | null,   //1: normal schedule, 2: cron schedule
    cron?: Cron[],
}
export type TimeRangeInput = [Dayjs, Dayjs]

export interface AssignedContent{
    content_id: string,
    play_duration : number, 
    mode: string, //'' |'loop',
    num_loops: number,    
}


// Data Pre-assignment
// ----------------------------------------------
export const DEFAULT_FOREVER_DURATION = 10 //10 years

export const durationOptions: OptionProps[] = [
    { value: 'today', label: 'Today only'},
    { value: '1d', label: '1 day (24h from now)'},
    { value: '1w', label: '1 week'},
    { value: '2w', label: '2 weeks'},
    { value: '3w', label: '3 weeks'},    
    { value: '1m', label: '1 month'},
    { value: '2m', label: '2 months'},
    { value: '3m', label: '3 months'},
    { value: '4m', label: '4 months'},
    { value: '5m', label: '5 months'},
    { value: '6m', label: '6 months'},
    { value: '1y', label: '1 year'}    
]

export const repeatOptions: OptionProps[] = [
    { value: 'once', label: 'Do not repeat', hiddenCases: ["allday"]},
    { value: 'daily', label: 'Daily'},
    { value: 'weekly', label: 'Weekly'},
    { value: 'monthly', label: 'Monthly'},
    { value: 'yearly', label: 'Yearly'},
    { value: 'weekday', label: 'Every weekday (Monday to Friday)'},
    { value: 'weekend', label: 'Every weekend (Saturday to Sunday)'},
    // { value: 'custom', label: 'Custom...'},
]

export const schedulePreviewPath : string  = process.env.REACT_APP_PREVIEW_PATH ? process.env.REACT_APP_PREVIEW_PATH+"?url=https://superbolt.com.au/api/data/group/" : ""

export const emptyAssignedContent = {
    content_id: "",
    play_duration : 10, 
    mode: '',
    num_loops: 0,    
}

export const emptySched: Sched = {
    deleted_at      : null,
    end_repeat_time : null,
    end_time        : null,
    group_id        : "",
    id              : "",
    modified_time   : null,
    overlap_mode    : 0,
    publish         : 0,
    type_id         : 1,
    repeat_type     : 0,
    require_schedule: 0,
    sche_layer      : 0,
    schedule_id     : "",
    start_time      : null,
    user_template_id: "",
    user_templates  : [],
}

export const getListSchedules = (allScheds: Schedule[]) => {
    var arrSchedules : Sched[] = []
    allScheds?.map((grp)=> {
      arrSchedules = [...arrSchedules, ...grp.schedules]
    })
    
    return arrSchedules
}

export const getEndDateTime = (startDateTime: Dayjs | null ) => {
    const momentDT = moment(startDateTime?.toDate())
    const str = startDateTime?.set('date', parseInt(momentDT.format("DD"))).set('month', parseInt(momentDT.format("MM"))).set('year', parseInt(momentDT.format("YYYY"))).set('hour', parseInt(momentDT.format("HH"))).endOf('hour')
    console.log("str",str)
    return str
}
// -------------------------------------------------------------------
export const extractContentInUse = (allScheds : Schedule[], currActiveSchIds: string[] | null,  playlistOnly=false) => {
    var extractedList: any[] = []
    // --------------------------------    

    if (currActiveSchIds && currActiveSchIds.length > 0){
        allScheds?.map((schedGrp, indSchedGrp)=>{
            schedGrp.schedules.map((sched, indSched)=>{
                if (currActiveSchIds.includes(sched.id)){   //if it is an active schedule
                    sched.user_templates.map((tmplt, indTmplt)=>{  
                        if ((playlistOnly === false && tmplt.type !== "playlist") ||    //pure content
                            (playlistOnly === true  && tmplt.type === "playlist")       //pure playlist
                        ){                           
                            extractedList.push({                            
                                cont_id: tmplt.user_template_id,
                                schedGrp: schedGrp,
                            })
                        }                
                    })
                }
            })
        })
    }
    // console.log("extractedList", extractedList)

    // Grouping by source_id and content_id
    const extractedListGroupBy = extractedList.reduce(function (r, a) {
        r[a.cont_id] = r[a.cont_id] || [];
        r[a.cont_id][a.schedGrp.schedules[0].schedule_id] = r[a.cont_id][a.schedGrp.schedules[0].schedule_id] || [];
        r[a.cont_id][a.schedGrp.schedules[0].schedule_id] = a.schedGrp;        
        return r;
    }, Object.create(null));
    
    // console.log("extractedListGroupBy", extractedListGroupBy); 
    // console.log("extractedListGroupBy", extractedListGroupBy["MGYKvknz2w"]); 
    
    return (extractedListGroupBy)
}

// -------------------------------------------------------------------
export const extractContentScheduled = (allScheds : Schedule[]) => {
    var extractedList: any[] = []
    // --------------------------------    

    allScheds?.map((schedGrp, indSchedGrp)=>{
        schedGrp.schedules.map((sched, indSched)=>{
            sched.user_templates.map((tmplt, indTmplt)=>{                
                extractedList.push({
                                    cont_id: tmplt.user_template_id,
                                    schedGrp: schedGrp,
                                })
                
            })
        })
    })
    // console.log("extractedList", extractedList)

    // Grouping by source_id and content_id
    const extractedListGroupBy = extractedList.reduce(function (r, a) {
        r[a.cont_id] = r[a.cont_id] || [];
        r[a.cont_id][a.schedGrp.schedules[0].schedule_id] = r[a.cont_id][a.schedGrp.schedules[0].schedule_id] || [];
        r[a.cont_id][a.schedGrp.schedules[0].schedule_id] = a.schedGrp;        
        return r;
    }, Object.create(null));
    
    // console.log("extractedListGroupBy", extractedListGroupBy); 
    // console.log("extractedListGroupBy", extractedListGroupBy["MGYKvknz2w"]); 
    
    return (extractedListGroupBy)
}

// -------------------------------------------------------------------
export const extractRepeatModeFromSchedule = (currSc: Sched[]) => {
    let tmpRepeat = "daily"
    // console.log("currSc[0]: ",currSc[0])
    // --------------------------------
    if (currSc && currSc.length>0) {
        if (currSc[0].cron){
            if       ((currSc[0].cron.dateRange === "*") && (currSc[0].cron.monthRange === "*") && (currSc[0].cron.dayOfWeek === "*")) {
                if (moment(currSc[0].end_time).format("DD/MM/YYYY") === moment(currSc[0].start_time).format("DD/MM/YYYY")){
                    tmpRepeat = "once"
                }else{
                    tmpRepeat = "daily"
                }
            }else if ((currSc[0].cron.dateRange === "*") && (currSc[0].cron.monthRange === "*") && (currSc[0].cron.dayOfWeek === "1-5")) {
                tmpRepeat = "weekday"
            }else if ((currSc[0].cron.dateRange === "*") && (currSc[0].cron.monthRange === "*") && (currSc[0].cron.dayOfWeek === "6,0")) {
                tmpRepeat = "weekend"                                           
            }else if ((currSc[0].cron.dateRange === "*") && (currSc[0].cron.monthRange === "*") && (currSc[0].cron.dayOfWeek !== "*")) {
                tmpRepeat = "weekly"
            }else if ((currSc[0].cron.dateRange !== "*") && (currSc[0].cron.monthRange === "*") && (currSc[0].cron.dayOfWeek === "*")) {
                tmpRepeat = "monthly"
            }else if ((currSc[0].cron.dateRange !== "*") && (currSc[0].cron.monthRange !== "*") && (currSc[0].cron.dayOfWeek === "*")) {
                tmpRepeat = "yearly"
            }
        }
    }
    return tmpRepeat
}


// ----------------------------------------
export const extractStartEndDatesFromSched = (sch: Sched) =>{
    
    // ------------------------------------

    if (!sch.cron){    // For a normal schedule
        return [
            moment(sch.start_time, 'YYYY-MM-DD HH:mm:ss'),  //startDate
            moment(sch.end_time, 'YYYY-MM-DD HH:mm:ss')     //endDate
        ]
    }else{  
        // For a recurrence schedule
        // -------------------------------------
        let startDate = moment(sch.start_time, "YYYY-MM-DD HH:mm:ss").startOf('day')
        let endDate   = moment((sch.end_time && sch.end_time !== "" && sch.end_time !== null) 
                                    ? moment(sch.end_time, 'YYYY-MM-DD HH:mm:ss').endOf('day')
                                    : moment().add(DEFAULT_FOREVER_DURATION,"year")
                                )   

        if (sch.cron.timeRanges === "*"){   //for Allday option
            // const startDate = moment(sch.start_time, "YYYY-MM-DD HH:mm:ss").startOf('day')
            // const endDate   = moment((sch.end_time && sch.end_time !== "" && sch.end_time !== null) 
            //                             ? moment(sch.end_time, 'YYYY-MM-DD HH:mm:ss').endOf('day')
            //                             : moment().add(DEFAULT_FOREVER_DURATION,"year")
            //                         )
            
            return [startDate, endDate]

        }else{  //for timeranges options
            
            //Find the earliest timeranges to represent the Cron/Recurrence Schedule
            let earliestStartTime : Dayjs | null = dayjs(sch.start_time, "YYYY-MM-DD HH:mm:ss")
            let earliestEndTime   : Dayjs | null = dayjs(sch.start_time, "YYYY-MM-DD HH:mm:ss")
            // -----------------------------------------------------------------

            sch.cron?.timeRanges.split(",").forEach((timeRangeStr, ind)=>{
                const tRange = timeRangeStr.split("-")
                const tmpStartT = dayjs(tRange[0],"HH:mm:ss")
                const tmpEndT   = dayjs(tRange[1],"HH:mm:ss")
                const startT = earliestStartTime?.set('hour', tmpStartT.hour()).set('minute', tmpStartT.minute()).set('second', tmpStartT.second())
                const endT   = earliestStartTime?.set('hour', tmpEndT.hour()).set('minute', tmpEndT.minute()).set('second', tmpEndT.second())
                // -------------------------------------------
                                               
                if (startT && endT){
                    if ((moment(startT.toDate()).diff(moment(earliestStartTime?.toDate()))/(1000*60*60*24)) <= 0){
                        earliestStartTime =  startT
                        earliestEndTime =  endT
                    }
                }
            })

            startDate = startDate.set('hour', earliestStartTime.hour()).set('minute', earliestStartTime.minute()).set('second', earliestStartTime.second());
            endDate   = endDate.set('hour', earliestEndTime.hour()).set('minute', earliestEndTime.minute()).set('second', earliestEndTime.second());

            return [startDate, endDate]
        }
    }    
}

// ------------------------------------------------------------
export const extractCurrActiveSchedules = (allSchedules: Schedule[], dtNow = moment()) =>{
    // const extractedSchedIds: string[] = ["W7ON6YlOxJ","ERMy29QO6Y"]
    const extractedSchedIds: string[] = []
    // --------------------------------------
    // console.log("allSchedules : ", allSchedules)
    // console.log("dtNow : ", dtNow)
    allSchedules.forEach((grpSchedules, indGrp) => {
        let isDetected: boolean = false
        // ----------------------------

        grpSchedules.schedules
        .slice().sort((a, b) => a.sche_layer < b.sche_layer ? 1 : -1)   //this sorting is helping in select 
                                                                        //the top layer of active schedule in each group
                                                                        //the biggest value is the top
        .forEach((sch, ind) => {

            if (!isDetected){ //if active schedule of each group is not yet detected, keep looping

                if (!sch.cron){    // For a normal schedule
                    if ((dtNow.diff(moment(sch.start_time, 'YYYY-MM-DD HH:mm:ss'), 'seconds') >= 0) && 
                        (dtNow.diff(moment(sch.end_time, 'YYYY-MM-DD HH:mm:ss'), 'minutes') <= 0)){
                        isDetected = true                        
                    }
                }else{  
                    // For a recurrence schedule
                    // -------------------------------------
                    let startDate = moment(sch.start_time, "YYYY-MM-DD HH:mm:ss").startOf('day')
                    let endDate   = moment((sch.end_time && sch.end_time !== "" && sch.end_time !== null) 
                                                ? moment(sch.end_time, 'YYYY-MM-DD HH:mm:ss').endOf('day')
                                                : moment().add(DEFAULT_FOREVER_DURATION,"year")
                                            )
                    // -------------------------------------
                                            
                    if (sch.cron.timeRanges === "*"){   //for Allday option
                        if ((dtNow.diff(startDate, 'seconds') >= 0) && (dtNow.diff(endDate, 'minutes') <= 0)){
                            isDetected = true                        
                        }
                    }else{  //for timeranges options  

                        //  1. dateVerification => verify if today has any active recurring schedule or not
                        // ----------------------------------------------------------------
                        let dateDetected = false
                        // ----------------------
                        if ((dtNow.diff(startDate, 'seconds') >= 0) && (dtNow.diff(endDate, 'minutes') <= 0)){
                            if ((sch.cron.dateRange === "*") && (sch.cron.monthRange === "*") && (sch.cron.dayOfWeek === "*")) {                                
                                dateDetected = true     // automatically true if (repeatMode = ["once", "daily"])
                            }else if ((sch.cron.dateRange === "*") && (sch.cron.monthRange === "*") && (sch.cron.dayOfWeek === "1-5")) {
                                if (dtNow.day()>=1 && dtNow.day()<=5){      // if weekdays
                                    dateDetected = true
                                }
                            }else if ((sch.cron.dateRange === "*") && (sch.cron.monthRange === "*") && (sch.cron.dayOfWeek === "6,0")) {
                                if (dtNow.day()===0 || dtNow.day()===6){    // if weekend
                                    dateDetected = true
                                }
                            }else if ((sch.cron.dateRange === "*") && (sch.cron.monthRange === "*") && (sch.cron.dayOfWeek !== "*")) {                            
                                if (dtNow.day()=== startDate.day()){    // if weekly
                                    dateDetected = true
                                }
                            }else if ((sch.cron.dateRange !== "*") && (sch.cron.monthRange === "*") && (sch.cron.dayOfWeek === "*")) {
                                if (dtNow.date()=== startDate.date()){    // if monthly
                                    dateDetected = true
                                }
                            }else if ((sch.cron.dateRange !== "*") && (sch.cron.monthRange !== "*") && (sch.cron.dayOfWeek === "*")) {
                                if ((dtNow.date()=== startDate.date()) && (dtNow.month()=== startDate.month())){    // if yearly
                                    dateDetected = true
                                }
                            }
                        }
                        
                        // 2. timeranges verification => check if current time of today has any active recurring schedule or not
                        // ---------------------------------------------------------
                        if (dateDetected){
                            sch.cron?.timeRanges.split(",").forEach((timeRangeStr, ind)=>{
                                if (!isDetected){
                                    const tRange = timeRangeStr.split("-")                                
                                    // -------------------------------------------                                                            
                                    if ((dtNow.diff(moment(tRange[0],"HH:mm:ss"), 'seconds') >= 0) && (dtNow.diff(moment(tRange[1],"HH:mm:ss"), 'minutes') <= 0)){
                                        isDetected = true                        
                                    }
                                }
                            })
                        }
                    }
                } 

                // if detected, then push Schedule_id to the list
                // -----------------------------------------------------------
                if(isDetected){
                    extractedSchedIds.push(sch.schedule_id)
                }
            }
        })
    })

    return extractedSchedIds;
}
