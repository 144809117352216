import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Group, InitialState } from './groupModels';


const initialState: InitialState = {
    errStatus: false,
    page : 1,    
    // modalOpenStatus: false,
    currGroup: {
        id: "",
        name: "",
        displayType: "",
        category: "",  // "device group" | "location" | "others",
        
        // devices:[]
        group_name: "",    
        cacheno: 0,        
        params: null,
        target_layout: 0,
        created_at: null,
        deleted_at: null,    
        updated_at: null,

        group_id: "",
        group_location_id: "",
        user_id: ""
    },
    targetTab: "",
    optShowDeviceStatus : false,

}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers:{

        setErrStatusGroup: (state, action) => {
            state.errStatus = action.payload
        },
        setCrrGroup: (state, action: PayloadAction<Group>) => {
            state.currGroup = action.payload
        }, 
        setTargetTab: (state, action: PayloadAction<string>) => {
            state.targetTab = action.payload
        }, 
        setOptShowDeviceStatus: (state, action) =>{
            state.optShowDeviceStatus = action.payload
        },
    }
})

export default groupSlice.reducer
export const {
    setErrStatusGroup,    
    setCrrGroup,
    setTargetTab,
    setOptShowDeviceStatus,
    
}  = groupSlice.actions
