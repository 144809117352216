import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { ResultObject, TicketPartial } from '../../../redux/slices/ticket/ticketModel'

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
const API3_URL : string | undefined  = process.env.REACT_APP_API3_URL

export const REGISTER_URL         = `${API3_URL}/user/register`
export const RESEND_ACTIVATION_URL= `${API3_URL}/user/resend-activation`
export const REGISTER_VERIFY_URL  = `${API3_URL}/user/verify`
export const ENABLE_GOOGLE2FA_URL = `${API3_URL}/user/enableGoogle2FA`
export const REGISTER_VALIDATE_URL= `${API3_URL}/user/validate`
export const RESET_PASSWORD_URL   = `${API3_URL}/user/reset`
export const REQUEST_PASSWORD_URL = `${API3_URL}/user/forget`

export const LOGIN_URL  = `${API3_URL}/authenticate`
export const ACCESS_URL = `${API3_URL}/authenticate/tfa`
export const REQUEST_PASSCODE_URL = `${API3_URL}/authenticate/resetpasscode`

export const SET_ACCESS_URL = `${API_URL}/authenticate/user/access`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/authenticate/user`
export const REFRESH_TOKEN_URL = `${API_URL}/authenticate/refresh`
export const GET_USER_BY_MASTER_URL = `${API_URL}/master/users`
export const REQUEST_TICKET_PASSCODE_URL = `${API_URL}/ticket/passcode`
export const SUBMIT_SUPPORT_TICKET_URL = `${API_URL}/ticket`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  repCode?: string | null
) {
  return axios.post<{result:object}>(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    rep_code: repCode
  })
}

export function validateAccount(activation: string | null){
  return axios.post<{result:object}>(REGISTER_VALIDATE_URL, {
    activation
  })
}

export function verifyAccount(activation: string | null, password: string){
  return axios.post(REGISTER_VERIFY_URL, {
    activation, password
  })
}

export function enableGoogle2FAOnSignUp(mfaSecret: string, passCode: string){
  return axios.post(ENABLE_GOOGLE2FA_URL, {
    mfaSecret, passCode
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resendActivationEmail(email: string) {
  return axios.post(RESEND_ACTIVATION_URL, {
    email,
  })
}

export function resetPassword(token: string | null, password: string){
  return axios.post(RESET_PASSWORD_URL, {
    token, password
  })
}

export function requestAccess(token: string, passcode: string)
{
  return axios.post<AuthModel>(ACCESS_URL, {
    token, passcode
  })
}

export function setAccess(token: string)
{
  return axios.post<AuthModel>(SET_ACCESS_URL, {
    token
  })
}

export function requestPasscode(token: string)
{
  return axios.post<AuthModel>(REQUEST_PASSCODE_URL, {
    token
  })
}

export function requestTicketPasscode(deivceId: string | null)
{
  if(deivceId != null)
  {
    return axios.post<ResultObject>(REQUEST_TICKET_PASSCODE_URL, {
      deivceId
    })
  }
}

export function submitSupportTicket(data: TicketPartial | null)
{
  return axios.post<ResultObject>(SUBMIT_SUPPORT_TICKET_URL, {
    data
  })
}

export function getUserByToken(token: string) {
  var basicAuth = 'Bearer ' + token;
  
  return axios({
    method: 'get',
    url: GET_USER_BY_ACCESSTOKEN_URL,  
    headers: {'Authorization': basicAuth}
  })
}

export function getSubUsers(token: string) {
  var basicAuth = 'Bearer ' + token;
  
  return axios({
    method: 'get',
    url: GET_USER_BY_MASTER_URL,  
    headers: {'Authorization': basicAuth}
  })
}

export async function refreshToken(token: string) {
  var basicAuth = 'Bearer ' + token;
  
  return axios({
    method: 'get',
    url: REFRESH_TOKEN_URL,  
    headers: {'Authorization': basicAuth}
  })
}