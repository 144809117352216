import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { getAuthModel } from "../../auth"
import { AccountLicensePackage, BuyLicense, License, UpdateLicense } from "./licenseModel";

const API_URL : string | undefined  = process.env.REACT_APP_API_URL

export  const auth = getAuthModel();

export const licensesApi = createApi({
    reducerPath: "licensesApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{  
            const auth = getAuthModel()              
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    
    tagTypes: ['License', 'LicensePackage', 'AccountLicensePackage', 'Group', 'Device'],
    refetchOnMountOrArgChange: 30,    
    
    endpoints: (builder) => ({
        /**
         * If the device does not have any license. Free license will be included.
         */
        getAllLicenses : builder.query<License[], number>({
            query: () => `licenses/packages`,
            providesTags: ['License', 'AccountLicensePackage'],
            keepUnusedDataFor: 5,              
        }),
        /**
         * @param int $filter
         * 0 => get all licenses;
         * 1 => get available licenses;
         * 2 => get assigned licenses;
         * 3 => get expired licenses;
         */
        getAccountLicenses : builder.query<AccountLicensePackage[], number>({
            query: (filter) => `account/licenses/${filter}`,
            providesTags: ['AccountLicensePackage'],
            keepUnusedDataFor: 5,              
        }),

        /**
         * this is not in-used yet until 
         */
        addLicense: builder.mutation<Object, BuyLicense>({
            query(data){
                return{
                    url: "license/payment/complete",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ['AccountLicensePackage', 'License', 'LicensePackage', 'Group', 'Device']
        }),
        /**
         * End
         */

        buyLicense: builder.mutation<any, Object>({
            query(data){
                return{
                    url: "account/license/buy",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ['AccountLicensePackage', 'License', 'LicensePackage']
        }),

        updateLicense: builder.mutation<Object, UpdateLicense>({
            query: ({oldLicenseId, ...data}) => ({
                url: `account/license/update/${oldLicenseId}`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ['AccountLicensePackage', 'License', 'LicensePackage', 'Group', 'Device']
        }),

        getAccountStorageAllowance : builder.query<number, number>({
            query: () => `/account/storage/allowance`,
            keepUnusedDataFor: 5,              
        }),

        getAccountStoragAvailable : builder.query<number, number>({
            query: () => `/account/storage/available`,
            keepUnusedDataFor: 5,              
        }),

        getAccountStoragUsed : builder.query<number, number>({
            query: () => `/account/storage/used`,
            keepUnusedDataFor: 5,              
        }),
    })

});

export const {
    useGetAllLicensesQuery,
    useGetAccountLicensesQuery,
    useAddLicenseMutation,
    useBuyLicenseMutation,
    useUpdateLicenseMutation,
    useGetAccountStoragAvailableQuery,
    useGetAccountStorageAllowanceQuery,
    useGetAccountStoragUsedQuery,
} = licensesApi