/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useDrop } from 'react-dnd'
import { useGetAllMediasQuery } from '../../../../../redux/slices/media/apiMediaSlice'
import { DraggableImage, DroppableImageVideoZone, DroppableTextZone} from '../../EditorComponents'
import { Media, Medias, defaultThumbnails } from '../../../../../redux/slices/media/mediaModels'
import { Element, getBgTemplateSize, templateColorPallets } from '../../../../../redux/slices/content/contentModels'
import { useAppSelector } from '../../../../../redux/hook'
import { getCorrespondHeight, getCorrespondWidth } from '../../../../../redux/slices/editor/editorModels'
import { decode as decodeBase64 } from 'base-64'
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte"
import { IconButton, InputAdornment, TextField } from '@mui/material'

//----------------------------------------

type Props = {
  className: string,  
  section: Element,
  sectionId: string
}

type Item = {
  id: string,
  url: string
}


//----------------------------------------
const DroppableZone: React.FC<Props> = ({className, section, sectionId}) => { 
  const currEditorW = useAppSelector(state => state.editor.currEditorWidth)
  const currEditorH = useAppSelector(state => state.editor.currEditorHeight)
  const currEditorZoom = useAppSelector(state => state.editor.zoomPercentage)


  const [addWeb, setAddWeb]= useState<boolean>(false)
  // console.log(addWeb)

  // console.log("section in droppable zone", section)
  // console.log(section.sources[0]?.param_source[0].text)
  // console.log("sectionId in droppable zone", sectionId)
  // console.log("section.sources[0]", section.sources[0])
  

  //-----------------------------------------
  return (<Fragment key={section.element_id}>
    {section.type === "image" 
      ? <DroppableImageVideoZone 
          key={section.element_id}
          className=""
          section={section}
          sectionId={sectionId}
        />
      : section.type === "video" 
        ? <DroppableImageVideoZone 
            key={section.element_id}
            className=""
            section={section}
            sectionId={sectionId}
          /> 
        : section.type === "text" 
          ? <DroppableTextZone 
              key={section.element_id}
              className=""
              section={section}
              sectionId={sectionId}
            />        
        
          : section.type === "campaign" 
            ? section.sources.length 
              ? <div 
                  key={section.element_id}
                  style={{              
                    position:'absolute',          
                    width:`${getCorrespondWidth(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,
                    height:`${getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,          
                    left:`${getCorrespondWidth(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
                    top:`${getCorrespondHeight(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,

                    background: `${section.sources[0].param_source[0].backgroundcolor !== "" ? section.sources[0].param_source[0].backgroundcolor : `no-repeat center/5% #eff2f5cc url(${defaultThumbnails[1]})`}`,
                    color:`${section.sources[0].param_source[0].fontcolor??""}`,
                    fontSize: `${section.sources[0].param_source[0].size}px`,
                  }}
                  className={`
                    ${className} 
                    border ${section.sources.length? 'border-0 border-gray-300':'border-2 border-gray-500'} 
                    ${section.sources[0].param_source[0].fontalign  !== "" ? "text-"+section.sources[0].param_source[0].fontalign : ""}
                    ${section.sources[0].param_source[0].fontweight !== "" ? "fw-"+section.sources[0].param_source[0].fontweight  : ""}                  
                  `}
                >          
                  {section.sources? section.sources[0].param_source[0].text : ""}
                </div>
              : <div 
                  key={section.element_id}
                  style={{              
                    position:'absolute',          
                    width:`${getCorrespondWidth(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,
                    height:`${getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,          
                    left:`${getCorrespondWidth(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
                    top:`${getCorrespondHeight(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,

                    background: `no-repeat ${getBgTemplateSize(section.w, section.h)} ${templateColorPallets[4]} url(${defaultThumbnails[4]})`,                    
                  }}
                  className={`
                    ${className} 
                    border ${section.sources.length? 'border-1 border-gray-300':'border-2 border-gray-500'}                   
                  `}
                >          
                  &nbsp;
                </div>
            : <div 
              key={section.element_id}
              style={{
                background: `no-repeat ${getBgTemplateSize(section.w, section.h)} ${templateColorPallets[5]} url(${defaultThumbnails[5]})`,
                position:'absolute',          
                width:`${getCorrespondWidth(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,
                height:`${getCorrespondHeight(section.w, section.h, currEditorW, currEditorH, currEditorZoom)}px`,          
                left:`${getCorrespondWidth(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
                top:`${getCorrespondHeight(section.x, section.y, currEditorW, currEditorH, currEditorZoom)}px`,
              }}
              className={`${className} border ${section.sources.length? 'border-1 border-gray-300':'border-2 border-gray-500'} `}
            >   
                else - {section.type}

            </div>
    }
  </Fragment>)
}

export {DroppableZone}