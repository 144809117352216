import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {MegaMenuScheduling} from './MegaMenuScheduling'
import { useAppSelector, useAppDispatch } from "../../../../../app/redux/hook";
import { KTSVG } from '../../../../helpers'
import { Link, useLocation } from 'react-router-dom'

export function MenuInner() {
  const intl = useIntl()
  const location = useLocation()
  const isEditor = useAppSelector(state => state.editor.editorModeOn)
  const dispatch = useAppDispatch()

  return (
    <>
      
      {isEditor === true && 
        <>
        <div className='menu-item me-lg-1 text-muted'><Link to="/dashboard" className='text-muted text-hover-primary'>HOME</Link></div>
        <div className='menu-item me-lg-1 text-muted mx-2'><span className='text-muted me-2'>/</span></div>

        {/* <MenuInnerWithSub
          isMega={true}
          // title=''
          to='/mega-menu'          
          // fontIcon='bi-bar-chart-steps'      
          icon='/media/icons/duotune/abstract/abs015.svg'
          // icon='/media/icons/duotune/text/txt001.svg'       
          // hasArrow={true}
          menuPlacement='bottom-start'
          menuTrigger='click'
          className='text-muted p-0'
        >
          <MegaMenuScheduling />
        </MenuInnerWithSub> */}

        {/* <div className='menu-item me-lg-1 text-muted'><span className='text-muted'>/</span></div> */}
        {/* <MenuItem
            // icon='/media/icons/duotune/text/txt001.svg'
          to='/content'
          title='CONTENT MANAGEMENT' 
          className='text-muted'
        /> */}

        <div className='menu-item me-lg-1 text-muted'><Link to="/content" className='text-muted text-hover-primary'>CAMPAIGN</Link></div>
        <div className='menu-item me-lg-1 text-muted mx-2'><span className='text-muted me-2'>/</span></div>

        {/* <div className='menu-item me-lg-1 text-muted me-5'><span className='text-muted me-5'>/</span></div> */}
        <div className='menu-item me-lg-1 '>EDITOR</div>          

        {/* <MenuInnerWithSub
          title='SCHEDULING' to='/' fontIcon='bi-bar-chart-steps'
          hasArrow={true} menuPlacement='bottom-start' menuTrigger='click'>
          <MenuItem
            fontIcon='bi-bar-chart-steps'
            to='/dashboard'
            title='Dashboard' />
          <MenuItem
            icon='/media/icons/duotune/art/art003.svg'
            to='/group'
            title='Group Management' />
          <MenuItem
            icon='/media/icons/duotune/ecommerce/ecm002.svg'
            to='/content'
            title='Content Management' />
          <MenuItem
            icon='/media/icons/duotune/ecommerce/ecm002.svg'
            to='/playlist'
            title='Playlist' />
          <MenuItem
            icon='/media/icons/duotune/ecommerce/ecm002.svg'
            to='/schedule'
            title='Schedule' />
        </MenuInnerWithSub> */}
        </>
      }

      {isEditor === false && <>
        <div className='menu-item me-lg-1 text-muted'><Link to="/dashboard" className='text-muted text-hover-primary'>HOME</Link></div>
        <div className='menu-item me-lg-1 text-muted mx-2'><span className='text-muted me-2'>/</span></div>
        <div className='menu-item me-lg-1 '>
          {
            {
              "/dashboard/index": <span>DASHBOARD</span>,
              "/group/index"    : <span>DEVICE</span>,
              "/gallery/index"  : <span>GALLERY</span>,
              "/content/index"  : <span>CAMPAIGN</span>,
              "/playlist/index" : <span>PLAYLIST</span>,
              "/schedule/index" : <span>SCHEDULE</span>,
              "/report/index"   : <span>REPORT</span>,
              
              "/license/index"    : <span>LICENSE</span>,
              "/license/purchase" : <span>LICENSE</span>,
              
              "/document/index"     : <span>DOCUMENT</span>,
              "/document/quickstart": <span>QUICK START GUIDE</span>,
              
              "/reseller/rebate"    : <span>RESELLER</span>,
              "/reseller/rebate/"    : <span>RESELLER</span>,
              "/reseller/rebate/invoice"      : <span>RESELLER</span>,
              "/reseller/rebate/submitted"    : <span>RESELLER</span>,
              "/reseller/rebate/completed"    : <span>RESELLER</span>,
              "/reseller/rebate/rejected"     : <span>RESELLER</span>,
              "/reseller/customer"  : <span>RESELLER</span>,
              "/reseller/history"  : <span>RESELLER</span>,

              "/account/overview"   : <span>ACCOUNT</span>,
              "/account/settings"   : <span>ACCOUNT</span>,
              "/account/profile"    : <span>ACCOUNT</span>,
              "/account/license"    : <span>ACCOUNT</span>,
              "/account/invoice"    : <span>ACCOUNT</span>,
              "/account/user"       : <span>ACCOUNT</span>,
              
              "/shopping/cart"      : <span>CART</span>,
              "/shopping/checkout"  : <span>CART</span>,
              "/shopping/success"   : <span>CART</span>,
              "/shopping/cancel"    : <span>CART</span>,

              "/resolution/index"   : <span>RESOLUTION CENTER</span>,

            }[location.pathname]  
            || ((location.pathname.indexOf("/shopping/checkoutInvoice") === 0) && <span>PAY INVOICE</span>) //for "/shopping/checkoutInvoice/*"
            || ((location.pathname.indexOf("/account/invoice") === 0) && <span>ACCOUNT</span>) //for "/shopping/checkoutInvoice/*"
          }
        </div>         
        {/* <MenuItem
          icon='/media/icons/duotune/art/art003.svg'                    
          to='/editor/index'
          title='CONTENT EDITOR'
          refreshOn= {true}
          // onClick={() => { dispatch(resetContent()); }}            
        /> */}
        </>
      }
      {/* <MenuItem
        icon='/media/icons/duotune/ecommerce/ecm002.svg'          
        to='/builder'
        title='OUR SHOPS - (N/A)'
      />
      <MenuItem
        icon='/media/icons/duotune/ecommerce/ecm002.svg'
        to='/crafted/pages/profile/projects'
        title='MARKETPLACE - (N/A)'          
      /> */}

      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Layout Builder' to='/builder' />
      <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Accounts'
          to='/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
