/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Component, MouseEventHandler, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hook'
import { DraggableText, TextEditor } from '../../../../EditorComponents'
import { closeFullscreen, openFullscreen } from '../../../../../../../redux/slices/editor/editorModels'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { watch } from 'fs';
import { Element, emptyElement, ParamSource, Source, TEXTSTYLE_LIST } from '../../../../../../../redux/slices/content/contentModels';
import { useInView } from 'react-intersection-observer'
import { setContentSource, setHoverSectionId, setSectionId } from '../../../../../../../redux/slices/content/contentSlice'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material'
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input'
import { auto } from '@popperjs/core'
import { useGetFontListQuery } from '../../../../../../../redux/slices/content/apiContentSlice'
import WebFont from 'webfontloader'
//----------------------------------------

type Props = {
  id: string,
  className: string,
}

type TextProps = {
  text: string,  
}


const schema = yup.object().shape({  
  text: yup.string()
       .min(1, 'min. one characher')
       .required('Required')
})


//----------------------------------------
const ModalTextEditor: React.FC<Props> = ({ id, className}) => {

  const dispatch = useAppDispatch()
  //-----------------------------------------
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  //-----------------------------------------

  const currContentPersistor = useAppSelector(state => state.persistor._content.currContent)
  // console.log("currContentPersistor: ", currContentPersistor)    
  

  const { data: allFonts, isLoading: isLoadingAllFonts, isFetching: isFetchingAllFonts, isError } = useGetFontListQuery()
  // console.log("allFonts: ", allFonts)    


  const txtEditorCnt = useAppSelector(state => state.editor.textEditorCnt)  
  // console.log("txtEditorCnt in ModalTextEditor:", txtEditorCnt)  

  const [fullScreenMode, setFullScreenMode] = useState(false)

  const chckSum = useAppSelector(state => state.persistor._content.checkSum)
  // console.log("chckSum: ", chckSum)    

  const ratioW = 0.7
  const ratioH = 0.9
  
  const BORDERSIZE = 6  //6px
  const [previewScreenW, previewScreenH] = currContentPersistor.width >= currContentPersistor.height 
                                                ? [Math.round(ratioW  * window.innerWidth), Math.round((9/16) * ratioW * window.innerWidth)]
                                                : [Math.round((9/16) * ratioH * window.innerHeight), Math.round(ratioH * window.innerHeight)]  
  // console.log("previewScreenW : ",previewScreenW)
  // console.log("previewScreenH : ",previewScreenH)


  
  const currSectId = useAppSelector(state => state.content.currSectionId)  
  const [currSect, setCurrSect] = useState<Element>(emptyElement)
  // console.log("currSect: ", currSect)

  const [selectedTxtStyle, setSelectedTxtStyle] = useState<string>("")

  const [txtColor, setTxtColor] = useState("#000000")  
  const [chkboxBgColor, setChkboxBgColor] = useState(false)
  const [bgColor, setBgColor] = useState("")
  const [fontSize, setFontSize] = useState(36)
  const [fontFam, setFontFam] = useState<string>("Roboto")
  const [isHTML, setIsHTML] = useState(false)
  const [isMarqueeText, setIsMarqueeText] = useState(false)


  const { 
    register, 
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState:{
        errors,
        isDirty,
        
        isLoading,         
        isValid,   
        isSubmitting,
        isSubmitSuccessful,
    }
  } = useForm<ParamSource>({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues:{  

        text      : "",
        txtId     : "",
        fontcolor       : "#000000",

        html            : true,        
        backgroundcolor : null,

        marquee         : false,
        direction       : "left",                
        
        fill_to         : "fit",
        alignment       : "center",
        
        font            : "",
        fontitalic      : "",
        fontstyle       : "",
        fontweight      : "",
        fontdecoration  : "",
        hasshadow       : false,

        size            : 36,

        // "direction": "left",
        // "speed_x": 50,
        // "speed_y": 0,
        // "loop": 0,
       

        
        // "duration": 0,
        // "delay": 0,
        // "text": "This is the sample text for Flutter TextScroll widget RTL សូមពិនិត្យមើលខ្ញុំចេញ។ ហេ! ខ្ញុំជាអត្ថបទ RTL សូមពិនិត្យមើលខ្ញុំចេញ។ ចុចខ្ញុំដើម្បីចាប់ផ្តើមរមូរ។ |",
        // "html": false,

        // "font"      : 'Roboto',
        // "size"      : 30,

        // // "shadows": [
        // //     {
        // //         "dx": 3,
        // //         "dy": 3,
        // //         "radius": 3,
        // //         "color": "#770F99FF"
        // //     },
        // //     {
        // //         "dx": 2,
        // //         "dy": 2,
        // //         "radius": 2,
        // //         "color": "#77FF0000"
        // //     }
        // // ], 
      },      
  }) 

  console.log("watch:", watch())


  // ---------------------------------------------
  const handleChangeTxtTemplate = (txtStyle: ParamSource) => {
    
    let newParamSrc: ParamSource[] = [{
                                            delay: 0,
                                            // duration: srcDuration,
                                            volume: null,
                                            bg_color: "#00000000",

                                            ...txtStyle     
                                          }]

    // -----------------------------------
    var newSource: Source = {
                              source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                              element_id    : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                              source_id: '0',
                              order:  0,
                              duration_override: -1,    

                              type: "text",
                              source:  null,

                              param_source : newParamSrc
                            }
    // console.log("newSource: ",newSource)
    
    var sourceList: Source[] = []
    sourceList = [newSource] //take only the last one

    dispatch(setContentSource(sourceList))
  } 

  // -------------------------
  const handleChangeTxtColor = (hexColor: string, colors: MuiColorInputColors) =>{
    setTxtColor(hexColor)
    setValue("fontcolor", hexColor)

    if (currSect.sources && currSect.sources.length > 0){        
        const newSrcList = currSect.sources.map(src => {
                                    return  {   ...src,
                                                param_source: [{  ...src.param_source[0],
                                                                  fontcolor: hexColor
                                                                }]
                                            }                                                
                                })
        dispatch(setContentSource(newSrcList))
    }
  }

  // -------------------------
  const handleChangeBgColor = (hexColor: string, colors: MuiColorInputColors) =>{
    setBgColor(hexColor)
    setValue("backgroundcolor", hexColor)

    if (currSect.sources && currSect.sources.length > 0){        
        const newSrcList = currSect.sources.map(src => {
                                    return  {   ...src,
                                                param_source: [{  ...src.param_source[0],
                                                                  backgroundcolor: hexColor
                                                                }]
                                            }                                                
                                })
        dispatch(setContentSource(newSrcList))
    }
  }



  // --------------------------------------------
  const formSubmitHandler: SubmitHandler<ParamSource> = async (textEle: ParamSource) => {  

    var arrItems: any[] = []
    // ----------------------------------------

    // console.log("textEle: ", textEle)
    
    // correcting the format of text content
    // --------------------------------------
    var tmpTextContent = textEle.text    
    if (tmpTextContent){
      if (tmpTextContent.substring(0,3) === "<p>"){
        tmpTextContent = tmpTextContent.substring(3, tmpTextContent.length - 5)
      }

      //remove all html tags for normal text and marquee text
      // if (textEle.marquee || !textEle.html){
      //   tmpTextContent = tmpTextContent.replace(/<\/[^>]+(>|$)/g, "")
      //   // console.log("tmpTextContent============: ", tmpTextContent)
      // }
    }

    
                       


    let newParamSrc: ParamSource[] = [{
      delay: 0,
      bg_color: "#00000000",

      ...textEle,
      text: tmpTextContent
    
    }]

    // -----------------------------------
    var newSource: Source = {
                              source_list_id: Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                              element_id    : Math.random().toString(36).slice(0,12).replace('0.', '').toUpperCase(),
                              source_id: '0',
                              order:  0,
                              duration_override: -1,    

                              type: "text",
                              source:  null,

                              param_source : newParamSrc
                            }
    // console.log("newSource: ",newSource)
    
    var sourceList: Source[] = []
    sourceList = [newSource] //take only the last one

    // dispatch(setSectionId(sect.element_id))  //which section it belongs to
    dispatch(setContentSource(sourceList))

  }
  
  
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Bayon',
          'Bungee Shade',
          'Eater',
          'Honk',
          'Jura',
          'Monoton',
          'Open Sans', 
          'Parisienne',
          'Roboto', 
          'TitanOne',          
        ]
      }
    });
  }, []);

  // -----------------------------
  // useEffect(() => { 
  //   const selectedSect = currContentPersistor.elements.find((sect, ind)=> sect.element_id === currSectId)    
  //   setCurrSect(selectedSect? selectedSect: emptyElement)

  //   if (selectedSect && selectedSect.sources.length > 0){
  //     const tmp: ParamSource = selectedSect.sources[0].param_source[0]
  //     if (tmp){        
  //       reset({
  //         txtId: selectedTxtStyle,
  //         text: txtEditorCnt,
  //         fontcolor       : tmp.fontcolor? tmp.fontcolor : "#000000",

  //         html            : tmp.html? tmp.html : false,        
  //         backgroundcolor : tmp.backgroundcolor? tmp.backgroundcolor : "",

  //         marquee         : tmp.marquee ? tmp.marquee : false,
  //         direction       : tmp.direction? tmp.direction : "left",                
  //         alignment       : "",
  //       })
  //     }
  //   }else{
  //     reset({
  //       txtId: selectedTxtStyle,
  //       text: txtEditorCnt,        
  //       fontcolor       : "#000000",

  //       html            : false,        
  //       backgroundcolor : null,

  //       marquee         : false,
  //       direction       : "left",                
  //       alignment       : "",
  //     }) 
  //   } 
  // },[txtEditorCnt])

  // -----------------------------
  useEffect(() => { 
    // console.log("currSectId changed :", currSectId)

    const selectedSect = currContentPersistor.elements.find((sect, ind)=> sect.element_id === currSectId)

    if (selectedSect && selectedSect.sources.length > 0){
      const tmp: ParamSource = selectedSect.sources[0].param_source[0]
      if (tmp){
        setSelectedTxtStyle(tmp.txtId? tmp.txtId : "")
        reset({
          ...tmp,

          txtId           : tmp.txtId? tmp.txtId: "",
          text            : tmp.text? tmp.text : "",
          font            : tmp.font? tmp.font : "Roboto",
          fontcolor       : tmp.fontcolor? tmp.fontcolor : "#000000",
          fontitalic      : tmp.fontitalic? tmp.fontitalic : "",
          fontstyle       : tmp.fontstyle? tmp.fontstyle : "",
          fontweight      : tmp.fontweight? tmp.fontweight : "",
          fontdecoration  : tmp.fontdecoration? tmp.fontdecoration : "",

          size            : tmp.size ? tmp.size : 36,

          html            : tmp.html? tmp.html : false,        
          backgroundcolor : tmp.backgroundcolor? tmp.backgroundcolor : "",

          marquee         : tmp.marquee ? tmp.marquee : false,
          direction       : tmp.direction? tmp.direction : "left",                
          alignment       : tmp.alignment? tmp.alignment : "",
          
        })

        setTxtColor(tmp.fontcolor? tmp.fontcolor : "")
        setBgColor (tmp.backgroundcolor? tmp.backgroundcolor : "")
        setChkboxBgColor(tmp.backgroundcolor && tmp.backgroundcolor !== ""? true: false)
        setFontSize(tmp.size ? tmp.size : 36)
        setIsHTML(tmp.html? tmp.html : false)
        setIsMarqueeText(tmp.marquee ? tmp.marquee : false)
      }
    }else{
      setSelectedTxtStyle("")
      reset()
      
      setTxtColor("#000000")
      setBgColor("")
      setChkboxBgColor(false)
      setFontFam("Roboto")
      setFontSize(36)
      setIsHTML(false)
      setIsMarqueeText(false)
    }
  }, [ currContentPersistor, currSectId])
  
  // -------------------------------------------------
  return (
    <div className={`modal fade ${className}`} tabIndex={-1} id={id} style={{zIndex: 2051}}
      ref={ref}
    >
      <div className={`modal-dialog ${fullScreenMode === false ? "modal-xl":"modal-fullscreen"} modal-dialog-centered`}      
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width:  fullScreenMode === false 
                                            ? (previewScreenW+BORDERSIZE)+"px" 
                                            : (currContentPersistor.width >= currContentPersistor.height? "100vw": "calc(100vh - 300px)"), 
          height: fullScreenMode === false  ? (previewScreenH+BORDERSIZE)+"px" 
                                            : "100%",                  
          // WebkitTransform:(fullScreenMode === true && currContentPersistor.width < currContentPersistor.height ? "translate(75%)":"translate(0%)"),
          // transform:      (fullScreenMode === true && currContentPersistor.width < currContentPersistor.height ? "translate(75%)":"translate(0%)"),
          // transform: "translate(-25%, 0%)"
          
          // top:"50%",
          // left:"50%"
          // marginLeft:     (fullScreenMode === true && currContentPersistor.width < currContentPersistor.height ?"300px":""),
          // marginLeft:     (fullScreenMode === false && window.innerWidth < 768 ?"70px":"auto"),
        }}
      >   
        {inView &&  
        <form id="frm-text-editor" className='form'  
          onSubmit={handleSubmit(formSubmitHandler)}
        >
          <div className={`modal-content rounded shadow-lg`}
            style={{
              height: fullScreenMode === false ? previewScreenH+6+"px" : "100%", 
              padding: fullScreenMode === false ? "0px" : "0px",
              // background: "repeating-linear-gradient(-45deg, #150232, #150232, #030008)",
            }}
          >          
            <div className="modal-header bg-gradient-primary p-4">
              <div className=''>
                <KTSVG path='/media/icons/duotune/coding/cod003.svg' className='svg-icon-2x text-light' />              
                <span className='text-light fs-4 ms-2'>Text Editor</span>
              </div>

              <div id="preview-content-name" className='d-none position-absolute top-0 start-0 d-flex justify-content-end py-2 px-2 '>
                <span className="fs-5 text-capitalize text-gray-600">{currContentPersistor.user_template_name}</span>
              </div>
              
              <div className='position-absolute top-0 end-0 d-flex justify-content-end py-2 px-2 '>
                <button 
                  className='btn btn-icon btn-sm btn-transparent tooltip-bottom'
                  onClick={()=> {
                    setFullScreenMode(! fullScreenMode)
                    fullScreenMode === false ? openFullscreen(): closeFullscreen()
                  }}
                  data-tooltip-content={`${fullScreenMode === false ? 'Fullscreen':'Minimize'} `}
                >
                  <i className={`fa fa-${fullScreenMode === false ? "expand":"compress"} text-hover-primary fs-2`}></i>
                </button>
                <button 
                  className='btn btn-icon btn-sm btn-transparent tooltip-bottom'
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={()=> {                  
                    closeFullscreen()
                    setFullScreenMode(false)
                  }}
                >
                  <i className={`fa fa-times text-hover-primary fs-2`}></i>
                </button>              
              </div>
            </div>
            <div className={`modal-body p-0 ${fullScreenMode === false ? "":""}` }
              onMouseOver={()=> document.getElementById('preview-content-name')?.classList.remove('d-none')}
              onMouseOut={()=> document.getElementById('preview-content-name')?.classList.add('d-none')}
              // style={{width: previewScreenW+"px", height: previewScreenH+"px"}}
            >            
              <div className=''>
                {/* List of text templates */}
                <div className='container px-5 mb-4'>
                  <div className='row'>        
                    {TEXTSTYLE_LIST.map((txtStyle, ind)=> {
                      return (
                        <div key={`txt-style-${ind}`} 
                          className='col-6 col-xl-3 w-25 mt-5 pe-auto'
                          onClick={() => {
                            handleChangeTxtTemplate(txtStyle)
                            setSelectedTxtStyle(txtStyle.txtId? txtStyle.txtId: "")
                          }}
                        >
                          <DraggableText className='mb-4 border-white' item= {txtStyle} 
                            draggable  = {false}

                            selectable = {true}
                            selected   = {selectedTxtStyle === txtStyle.txtId}                
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
                {selectedTxtStyle === "" &&
                  <div className='px-5 mb-4'>
                    <img src="/media/_editor/select-text-template-02.png" className='w-75 w-lg-50 w-xl-50 ms-n5 opacity-75'/>
                  </div>
                }
                {selectedTxtStyle !== "" && !isHTML &&
                  <div className='px-5 mb-4'>
                    <div className='px-2 mb-4'>
                      <div className="btn-group btn-group-sm" role="group" aria-label="button group">
                        <button type='button' className={`${getValues("fontweight")==="bold"? "active":""} btn btn-sm btn-secondary rounded-0 rounded-start p-3 ps-5 pb-2`}
                          onClick={()=>{ setValue("fontweight", (getValues("fontweight") === "bold" ? "": "bold")) }}
                        >
                          <i className='fa fa-bold'></i>
                        </button>
                        <button type='button' className={`${getValues("fontitalic")==="italic"? "active":""} btn btn-sm btn-secondary rounded-0 p-3 ps-4 pb-2`}
                          onClick={()=>{ setValue("fontitalic", (getValues("fontitalic") === "italic" ? "": "italic")) }}
                        >
                          <i className='fa fa-italic'></i>
                        </button>
                        <button type='button' className={`${getValues("fontdecoration")==="underline"? "active":""} btn btn-sm btn-secondary rounded-0 rounded-end p-3 ps-4 pb-2`}
                          onClick={()=>{ setValue("fontdecoration", (getValues("fontdecoration") === "underline" ? "": "underline")) }}
                        >
                          <i className='fa fa-underline'></i>
                        </button>
                      </div>
                      <div className="btn-group btn-group-sm ms-5" role="group" aria-label="button group">                      
                        <div className='d-flex'
                          // style={{width: "140px"}}
                        > 
                          <div className='mx-1 mt-3 me-2'>
                            <i className='fa fa-font fs-3'></i>
                          </div>
                          
                          <div className="me-3">
                            <FormControl fullWidth>
                              <InputLabel id="select-layout-label">Font name</InputLabel>
                              <Select 
                                  {...register("font")} 
                                  labelId="select-size-label"                                        
                                  label="Font name"
                                  className='w-175px'
                                  size='small'
                                  value={fontFam}
                                  onChange={(e) => {
                                    setFontFam(""+ e.target.value)
                                    setValue('font', ""+ e.target.value)
                                    // trigger("size") //manually trigger the form or input validation
                                  }}
                                  error={!! errors.font}                                        
                                  MenuProps={{ disablePortal: true }}
                              >   
                                  { allFonts?.slice()
                                    .sort((a,b)=> a.font_name.toLowerCase() > b.font_name.toLowerCase() ? 1:-1)
                                    .map((fnt, ind) => {
                                      return (
                                          <MenuItem key={`font--${ind}`} value={fnt.font_name} 
                                              className={`w-100`}
                                              disabled={fnt.font_name.toLowerCase() === fontFam.toLowerCase()}
                                              // style={{fontFamily: '"'+fnt.font_name+'"'}}
                                          >
                                            <span className={`font-${fnt.font_name.toLowerCase().replace(' ','-')}`}>{fnt.font_name}</span>
                                          </MenuItem>
                                      )
                                    }) 
                                  }                                       
                              </Select>
                              <FormHelperText>{`${errors.font?.message ?? ''}`}</FormHelperText>
                            </FormControl> 
                          </div>
                          <div className="me-3">
                            <FormControl fullWidth>
                              <InputLabel id="select-layout-label">Size</InputLabel>
                              <Select 
                                  {...register("size")} 
                                  labelId="select-size-label"                                        
                                  label="Size"
                                  size='small'                                
                                  value={fontSize}
                                  onChange={(e) => {
                                    const numb = parseInt(""+ e.target.value)
                                    setFontSize(numb)
                                    setValue('size', numb)
                                    // trigger("size") //manually trigger the form or input validation
                                  }}
                                  error={!! errors.size}                                        
                                  MenuProps={{ disablePortal: true }}
                              >   
                                  { [ 8, 9, 10, 11, 12, 14, 16, 18, 
                                    20, 22, 24, 26, 28, 
                                    36, 48, 72,
                                    84,
                                    96,
                                    120,                              
                                    240,
                                    360,
                                    ].map((siz, ind) => {
                                      return (
                                          <MenuItem key={`size--${ind}`} value={siz} 
                                              className='w-100'
                                              disabled={siz === fontSize}
                                          >
                                            {siz}  
                                          </MenuItem>
                                      )
                                    }) 
                                  }                                       
                              </Select>
                              <FormHelperText>{`${errors.size?.message ?? ''}`}</FormHelperText>
                            </FormControl> 
                          </div>
                          <div className="w-125px me-3">
                            <FormControl fullWidth>
                                <MuiColorInput 
                                  label="Color"
                                  format="hex"  
                                  className='tooltip-bottom'
                                  data-tooltip-content='Background color'
                                  value={txtColor} 
                                  size='small'
                                  variant="outlined"
                                  inputProps={{ 
                                      style: {
                                        border: "0px",
                                        borderRadius: 0, 
                                      }, 
                                      sx: {
                                          borderRadius: 0,
                                          // '& > :not(style)': { m: 1, width: '25ch' },
                                      }
                                  }}
                                  onChange={handleChangeTxtColor}
                                />
                                <FormHelperText>{`${errors.size?.message ?? ''}`}</FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                        
                      </div>
                      
                      <div className="btn-group btn-group-sm ms-3" role="group" aria-label="button group">                      
                        <div className='d-flex ms-3'
                          style={{width: "175px"}}
                        > 
                          <div className='mx-1 mt-3'>
                            <i className='bi bi-paint-bucket fs-3'></i>
                          </div>
                          <MuiColorInput 
                            label="Background"
                            format="hex"  
                            className='tooltip-bottom'
                            data-tooltip-content='Background color'
                            value={bgColor} 
                            size='small'
                            variant="outlined"
                            inputProps={{ 
                                style: {
                                  border: "0px",
                                  borderRadius: 0, 
                                }, 
                                sx: {
                                    borderRadius: 0,
                                    // '& > :not(style)': { m: 1, width: '25ch' },
                                }
                            }}
                            onChange={handleChangeBgColor}
                          />
                          <div 
                            className='rounded-end me-n1'
                            style={{
                                height: "36px",
                            }}
                          >
                            <Checkbox 
                              checked={chkboxBgColor}
                              className='tooltip-bottom'
                              data-tooltip-content='Unset background color'
                              disabled = {bgColor === ""}
                              hidden   = {bgColor === ""}
                              onChange={()=>{
                                  handleChangeBgColor("", {
                                      hex: "",
                                      hsl: "",
                                      hsv: "",
                                      rgb: "",
                                      hex8: ""
                                  })
                                  setChkboxBgColor(! chkboxBgColor)
                              }}
                              inputProps={{ 'aria-label': 'controlled' }}                                                                         
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='px-2 mb-8'>
                      <div 
                        className='rounded-end me-n1'
                        style={{
                            height: "36px",
                        }}
                      >
                        
                        <FormGroup>
                          <FormControlLabel 
                            control={
                              <Checkbox                           
                                checked={isMarqueeText}
                                className='tooltip-bottom'
                                data-tooltip-content='Convert to a scrolling text'                                
                                onChange={()=>{
                                    // handleChangeBgColor("", {
                                    //     hex: "",
                                    //     hsl: "",
                                    //     hsv: "",
                                    //     rgb: "",
                                    //     hex8: ""
                                    // })
                                    if (! isMarqueeText){
                                      setIsHTML(false)
                                    }
                                    setIsMarqueeText(! isMarqueeText)
                                    
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}                                                                         
                              />
                            } 
                            label="Scrolling text" 
                          />   
                        </FormGroup>
                      </div>
                    </div>
                    <div className='row px-5 mb-4'>                    
                      <TextField
                        {...register("text")}
                        className={`fw-bold text-primary`}
                        fullWidth
                        multiline
                        // rows={1}
                        disabled = {selectedTxtStyle === ""}
                        label="Input Text"
                        InputLabelProps={{ 
                          shrink: true,                        
                          className: fontFam? "font-"+ fontFam.toLowerCase().replace(' ','-'): "",                        
                        }}
                        inputProps={{ 
                          // inputComponent: TextareaAutosize,
                          // rows: 5,

                          // sx:{
                          //     "& fieldset": { border: 'none', borderRadius: '50px' },
                          // },
                          style: { 
                              lineHeight: "normal",
                              maxHeight: "225px",
                              overflow: "auto",
                              // textTransform: "uppercase",
                              // borderRadius: "0", 
                              fontWeight: getValues("fontweight"),
                              fontStyle: getValues("fontitalic"),
                              // textDecoration: getValues("fontdecoration")
                              color: ""+ getValues("fontcolor"),
                              backgroundColor: ""+ getValues("backgroundcolor"),
                              fontSize: parseInt(""+getValues("size"))/2
                          } 
                        }}                      
                        // value={text}
                        // onChange={handleChange}
                      />                  
                    </div>
                  </div>
                }
                {/* WYSIWYG editor for HTML text */}
                {/* {selectedTxtStyle !== "" && isHTML &&
                  <TextEditor className='' />
                } */}

              </div>            
            </div>  
            <div className={`modal-footer pt-3 pb-0 px-0`}>
              <div className='me-5 mb-5'>
                <button type='button' className='btn btn-secondary pt-2 pb-3 me-3'
                  data-bs-dismiss="modal"
                >
                  <i className='fa fa-times me-1'></i>
                  Cancel
                </button>
                <button type='submit' className='btn btn-primary pt-2 pb-3'
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={isSubmitting || !isValid}
                  // disabled={isSubmitting || !isValid || (customErrs !== null)}
                >
                  <i className='fa fa-save me-1'></i>
                  Apply
                </button>
              </div>
            </div>        
          </div>
        </form>
        }
      </div>
    </div>
  )
}

export { ModalTextEditor }
