import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { pexelsApi } from './apiPexelsSlice';
import { InitialState } from './pexelsModels';

const initialState: InitialState = {
    selectedContentIds: [],
}

const pexelsSlice = createSlice({
    name: 'pexels',
    initialState,
    reducers:{    
        // [pexelsApi.reducerPath]: pexelsApi.reducer,        
        setSelectedContentIds(state, action: PayloadAction<string[]>){ 
            const arrIds: string[] = action.payload           
            state.selectedContentIds = arrIds
        },
    },
    

    
})


export default pexelsSlice.reducer
export const {
    setSelectedContentIds,

    
}  = pexelsSlice.actions
