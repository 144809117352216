import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { Group } from './groupModels';
import { getAuthModel } from '../../auth'

const API_URL : string | undefined  = process.env.REACT_APP_API_URL
// const API_URL : string | undefined  = process.env.REACT_APP_API_URL_DEV

// interface ListResponse<T> {
//     page: number
//     per_page: number
//     total: number
//     total_pages: number
//     data: T[]
// }

export const groupsApi = createApi({
    reducerPath: "groupsApi",
    baseQuery: fetchBaseQuery({         
        baseUrl: `${API_URL}`, 
        prepareHeaders: (headers, { getState }) =>{
            const auth = getAuthModel()            
            headers.set('authorization', `Bearer ${ auth?.access_token }`)            
            return headers
        }
    }),
    tagTypes: ['Group', 'Device', 'AccountLicensePackage'],

    // global configuration for the api
    //----------------------------------------
    // keepUnusedDataFor: 30,
    refetchOnMountOrArgChange: 30,    
    // refetchOnFocus: true,
    // refetchOnReconnect: true,

    endpoints: (builder) => ({
        // getGroups : builder.query<Group[], number>({
        //     query: () => "groups",
        //     providesTags: ['Group', 'Device'],
        //     keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        // }),   
        getAllGroups : builder.query<Group[], number>({
            query: () => "groups?_embed=devices",
            providesTags: ['Group', 'Device', 'AccountLicensePackage'],
            keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        }),  
                
        // getAllGroups : builder.query<Group[], number>({
        //     query: () => "groups",
        //     providesTags: ['Group'],
        //     keepUnusedDataFor: 5,   // local configuration for an individual endpoint, overriding the api setting            
        // }),        
        getGroup : builder.query<Group[], string>({
            // query: (group) => `groups/?name=${group}`,
            // query: (id) => `groups/${id}`,
            query: (id) => `groups/?id=${id}`,
             providesTags: ['Group'],
            // providesTags: (result, error, id) => [{ type: 'Group', id }],
        }),  
        /**
         * @param $mode 
         * @desc 
         *      0=> get only device groups that have no content scheduled or expired 
         *      1=> get only device groups that have current content or future content scheduled. 
         */
        getGroupsByScheduleMode : builder.query<Group[], number>({
            query: (mode) => `groups/schedule/${mode}`,
            providesTags: ['Group']
        }), 

        getFilterGroups : builder.query<Group[], string>({
            query: (filter) => `groups/${filter}`,
            providesTags: ['Group']
        }),
        // listGroups: builder.query<Group[], number | void>({
        //     query: (page = 1, limit = 2) => `groups?_page=${page}&_limit=${limit}`,
        //     providesTags: ['Group']
        // }),
        // listGroups: builder.query<ListResponse<Group>, number | void>({
        //     query: (page = 1, limit = 2) => `groups?_page=${page}&_limit=${limit}`,
        //     providesTags: ['Group']
        //     // providesTags: (result, error, page) =>
        //     // result
        //     // ? [
        //     //     // Provides a tag for each post in the current page,
        //     //     // as well as the 'PARTIAL-LIST' tag.
        //     //     ...result.data.map(({ id }) => ({ type: 'Group' as const, id })),
        //     //         { type: 'Group', id: 'PARTIAL-LIST' }, ]
        //     // : [{ type: 'Group', id: 'PARTIAL-LIST' }],
        // }),

        // ----------------------------------------------------

        addGroup: builder.mutation<void, Group>({
            query: (group) => ({
                url: "groups",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: group
            }),
            invalidatesTags: ['Group']
        }),
        updateGroup: builder.mutation<void, Group>({
            query: ({id, ...rest}) => ({
                url: `groups/${id}`,
                method: "PUT",
                body: rest
            }),
            invalidatesTags: ['Group']            
        }),
        deleteGroup: builder.mutation<void, string>({
            query: (id) => ({
                url: `groups/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['Group']
        }),

        //------------------------------------------------------
        // addDeviceToGroup: builder.mutation<void, Group>({
        //     query: ({id, ...rest}) => ({
        //         url: `groups/${id}/`,
        //         method: "PUT",
        //         body: rest
        //     }),
        //     invalidatesTags: ['Group']
        // }),
        // addDeviceToGroup: builder.mutation<void, Device>({
        //     // query: (idGr, {id, ...rest}) => ({
        //     //     url: `groups/${idGr}/`,
        //     //     method: "PUT",
        //     //     body: rest
        //     // }),
        //     // invalidatesTags: ['Group']

        //     query: (dev: Device) => ({
        //         url: `groups/5/Devices`,
        //         method: "POST",
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json'
        //         },
        //         body: dev
        //     }),
        //     invalidatesTags: ['Group', 'Device']
        // }),

    })
})

export const { 
    // useGetGroupsQuery, 
    useGetAllGroupsQuery,         
    useGetGroupQuery, 
    useGetFilterGroupsQuery,
    useGetGroupsByScheduleModeQuery,
    // useListGroupsQuery,
    useAddGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    // useAddDeviceToGroupMutation,    
} = groupsApi